import React, { useEffect, useMemo, useRef, useState } from 'react';
import { ToastContainer, Flip, toast } from 'react-toastify';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import secureLocalStorage from 'react-secure-storage';
import { useIdleTimer } from 'react-idle-timer';
import { useDispatch } from 'react-redux';
import moment from 'moment';

// COMPONENT IMPORTS
import AppRoutes from './routes';
import { socket } from './config/Socket';
import { localObjectClear } from './utils/system';
import BrandDetailsNotFound from './components/BrandDetailsNotFound';
import InactivityLogoutModal from './components/InactivityLogoutModal';
import default_favicon from './assets/images/icons/default-favicon.png';
import { clearPersisterStorage, getDomainName, roles } from './utils/common';

// API
import { getBrandDetails, useBrandDetails } from './slices/brandingSlice';
import { authUserLogout, useAccessUser, useUserID } from './slices/authSlice';

const INACTIVITY_TIMER = process.env.REACT_APP_INACTIVITY_TIMEOUT
  ? process.env.REACT_APP_INACTIVITY_TIMEOUT * 1000 * 60
  : 1000 * 60 * 15;
const PROMPT_TIMER = process.env.REACT_APP_PROMPT_DISPLAY_TIMEOUT
  ? process.env.REACT_APP_PROMPT_DISPLAY_TIMEOUT * 60
  : 60 * 2;

var pjson = require('../package.json');
const version = process.env.REACT_APP_VERSION + '-' + pjson.commit_id;
console.log('App version & commit id:', version);

/* ============================== APP ============================== */
function App() {
  const isSocketInitialized = useRef(false);
  const userID = useUserID();
  const token = secureLocalStorage.getItem('token');
  const idRef = useRef()
  const [countdown, setCountdown] = useState(INACTIVITY_TIMER); // 2 mins

  const brandDetails = useBrandDetails();
  const dispatch = useDispatch();
  const accessUser = useAccessUser();
  const renderRoutes = useMemo(() => {
    const router = createBrowserRouter(AppRoutes);
    return <RouterProvider router={router} />;
  }, []);
  const handlePageOnAdminAccess = () => {
    const broadcastChannel = new BroadcastChannel('logOut_from_same_tabs');
    broadcastChannel.postMessage('tab_logout');
  };

  // Manage Inactivity time
  const { getRemainingTime, activate, isLastActiveTab } = useIdleTimer({
    // onIdle,
    // onActive,
    // onAction,
    timeout: INACTIVITY_TIMER, // use for total in activity timeout
    syncTimers: 200,
    startOnMount: true,
    throttle: 10000,
    // onPrompt: () => handleInactivityTimeout(),
    promptBeforeIdle: 1000 * PROMPT_TIMER, // timeout - promptBeforeIdle -> call onPrompt
    crossTab: true,
    events: [
      'mousemove',
      'keydown',
      'wheel',
      'touchstart',
      'touchmove'
      // 'visibilitychange'
    ]
  });

  // brand details get
  const brandDetailsGet = async (domain) => {
    try {
      let res = await dispatch(getBrandDetails(domain)).unwrap();
    } catch (error) {
      // setLoading(false);
    }
  };

  useEffect(() => {
    const handleUpdateBrandDetails = () => {
      brandDetailsGet(getDomainName())
    }
    socket.on("custom_branding", handleUpdateBrandDetails)
    return () => {
      socket.off("custom_branding", handleUpdateBrandDetails)
    }
  }, [])

  const handleStay = () => {
    activate();
    // setShowModal(false);
  };

  const handleLogoutUser = () => {
    if (accessUser) {
      socket.emit('exitAccess', {
        user_id: accessUser?.accessUserID,
        access_user_id: userID
      });
    }
    dispatch(authUserLogout());
    clearPersisterStorage(dispatch);
    window.location.reload();
    localObjectClear();
  };

  useEffect(() => {
    activate();
  }, [window.location.pathname]);
  useEffect(() => {
    idRef.current = userID
  }, [userID])
  useEffect(() => {
    if (idRef.current && token && accessUser) {
      setTimeout(() => {
        socket.emit('addAccess', {
          user_id: accessUser?.accessUserID,
          access_user_id: idRef.current,
        });
      }, 500);
    }
  }, [idRef])
  useEffect(() => {
    brandDetailsGet(getDomainName());

    const handleLoad = () => {
      // Manage tab close session
      if (token) {
        const lastTimeStamp = window.localStorage.getItem('lastTimeStamp');

        const duration = moment.duration(moment().diff(lastTimeStamp));
        const durationInMinutes = duration.asMinutes();

        const tabCloseTimeoutLimit = process.env.REACT_APP_TAB_CLOSE_TIMEOUT_LIMIT || 5;

        if (
          durationInMinutes &&
          tabCloseTimeoutLimit < durationInMinutes &&
          !window.location.pathname.startsWith('/individual/quickbooks/')
        ) {
          handleLogoutUser();
        }
      } else {
        window.localStorage.setItem('lastTimeStamp', '');
      }
    };
    // const onFocus = () => {
    //   if (userID && token && accessUser) {
    //     socket.emit('addAccess', {
    //       user_id: accessUser?.accessUserID,
    //       access_user_id: userID
    //     });
    //   }
    // };
    const handleTabClose = (event) => {
      if (idRef.current && token && accessUser) {
        socket.emit('exitAccess', {
          exit: "exit from me",
          user_id: accessUser?.accessUserID,
          access_user_id: idRef.current
        });
      }
      // Manage tab close session
      window.localStorage.setItem('lastTimeStamp', moment());
    };
    window.addEventListener('load', handleLoad);
    window.addEventListener('unload', handleTabClose);

    const interval = setInterval(() => {
      setCountdown(Math.ceil(getRemainingTime() / 1000));
    }, 1000);

    return () => {
      window.removeEventListener('unload', handleTabClose);
      // window.removeEventListener('focus', onFocus);

      clearInterval(interval);
      // window.removeEventListener("blur", onBlur);
    };
  }, []);

  useEffect(() => {
    const updateSocketResponseHandler = (res) => { };
    const exitAccessResponseHandler = (res) => { };
    const addAccessResponseHandler = (res) => { };

    const checkAccessResponseHandler = (response) => {
      if (response?.code == 500) {
        dispatch(authUserLogout());
        localStorage.clear();
        handlePageOnAdminAccess();
        toast.success(response?.message);
      }
    };
    if (userID && !isSocketInitialized.current && !window.localStorage.getItem('business_id')) {
      socket.emit('updateSocket', { user_id: accessUser ? accessUser.accessUserID : userID });
      socket.on('updateSocketResponse', updateSocketResponseHandler);
      socket.on('exitAccessResponse', exitAccessResponseHandler);
      socket.on('addAccessResponse', addAccessResponseHandler);
      socket.on('checkAccessResponse', checkAccessResponseHandler);
      isSocketInitialized.current = true;
    }
    const logoutAccessWlResponseHandler = (response) => { };
    socket.on('logoutAccessWlResponse', logoutAccessWlResponseHandler);
    return () => {
      socket.off('updateSocketResponse', updateSocketResponseHandler);
      socket.off('exitAccessResponse', exitAccessResponseHandler);
      socket.off('addAccessResponse', addAccessResponseHandler);
      socket.off('checkAccessResponse', checkAccessResponseHandler);
      socket.off('logoutAccessWlResponse', logoutAccessWlResponseHandler);
    };
  }, [userID]);
  useEffect(() => {
    if (userID && token && accessUser) {
      if (
        accessUser.roleID != roles.master_admin_financial_advisor.roleID &&
        accessUser.roleID != roles.admin_financial_advisor.roleID
      ) {
        socket.emit('checkAccess', {
          user_id: accessUser?.accessUserID,
          access_user_id: userID
        });
      }
    }
  }, []);

  useEffect(() => {
    if (Object.keys(brandDetails).length > 0) {
      document.documentElement.style.setProperty(
        '--primary-main',
        brandDetails.primary_color || '#0024d4'
      );
      const favicon = document.querySelector("link[rel='icon']") || document.createElement('link');
      favicon.href =
        brandDetails && brandDetails.small_logo_s3_url
          ? brandDetails.small_logo_s3_url
          : default_favicon;
      favicon.rel = 'icon';
      document.head.appendChild(favicon);
      document.title = brandDetails ? brandDetails.firm_name : 'Client Portal';
    }
  }, [brandDetails]);

  const brand_data = Object.keys(brandDetails).length > 0 ? brandDetails : null;
  const primaryColor = brand_data ? brand_data.primary_color : '#0024d4';
  document.documentElement.style.setProperty('--primary-main', primaryColor);
  document.title = brand_data ? brand_data.firm_name : 'Client Portal';
  const favicon = document.querySelector("link[rel='icon']") || document.createElement('link');
  favicon.href = brand_data ? brand_data.small_logo_s3_url : default_favicon;
  favicon.rel = 'icon';
  document.head.appendChild(favicon);

  const ExcludeInactivityRoutes = [
    'sign-in',
    'sign-up',
    'create-password',
    'mfa-setup',
    'verification-pin',
    'forgot-password',
    'authenticator-setup'
  ];

  return (
    <React.Fragment>
      {Object.keys(brandDetails).length > 0 ? (
        <React.Fragment>{renderRoutes}</React.Fragment>
      ) : (
        <BrandDetailsNotFound />
      )}
      {/* Toast Message */}
      <div className="cs-toast">
        <ToastContainer
          theme="light"
          position="top-right"
          autoClose={3000}
          pauseOnFocusLoss={false}
          newestOnTop
          hideProgressBar={true}
          transition={Flip}
        />
      </div>
      {!!!ExcludeInactivityRoutes.find(
        (route) => window.location.pathname.includes(route) || window.location.pathname === '/'
      ) &&
        isLastActiveTab() &&
        countdown <= PROMPT_TIMER && (
          <InactivityLogoutModal
            remainingSeconds={countdown}
            onStay={handleStay}
            handleLogoutUser={handleLogoutUser}
          />
        )}
    </React.Fragment>
  );
}

export default App;
