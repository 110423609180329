import React, { useEffect, useRef, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useOutletContext } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

// COMPONENT IMPORTS
import SkeletonFileManagement from '../../components/Skeleton/SkeletonFileManagement';
import SkeletonArchiveTrash from '../../components/Skeleton/SkeletonArchiveTrash';
import CapsyncLoader from '../../../components/CapsyncLoader';

import FileManagementHeaderSection from '../HeaderSection/FileManagementHeaderSection';
import FileManagementDeleteModal from '../FileManagementDeleteModal';
import DownloadConfirmationModal from '../DownloadConfirmationModal';
import UpgradeStorage from '../FooterSection/UpgradeStorage';
import UnsupportedFileModal from '../UnsupportedFileModal';
import FileFolderMoveModal from '../FileFolderMoveModal';
import MoveAlertModal from '../MoveAlertModal';
import FilePreview from '../FilePreview';
import LoaderModal from '../LoaderModal';
import GridView from '../GridView';
import ListView from '../ListView';
import {
  ArchiveSortingDetails,
  deleteMsg,
  fileManagementSortingData,
  loadingMessage,
  scannedFileElem,
  useFileManagementClickOutside
} from '../FileManagementCommonElem';

import { fileDownload, getFileType } from '../../../utils/common';

// HOOKS IMPORTS
import useDebounce from '../../../utils/Hooks/useDebounce';

// API
import { useUser } from '../../../slices/authSlice';
import { downloadItem } from '../../../slices/downloadSlice';
import {
  deleteFileToTrash,
  getArchiveList,
  scanDownloadFile,
  searchArchiveItem,
  useArchiveListData,
  useStorageDetails
} from '../../../slices/fileManagementSlice';

/* ============================== ARCHIVE ============================== */
const Archive = () => {
  /* REDUX DATA */
  const user = useUser();
  const archiveListData = useArchiveListData();
  const storageDetails = useStorageDetails();

  const dispatch = useDispatch();

  /* USE REFS */
  const fmListRef = useRef(null);
  const layoutRowRef = useRef(null);
  const documentsRef = useRef(null);

  /* USE STATES */
  const [listData, setListData] = useState([]);
  const [openDropdownId, setOpenDropdownId] = useState(null);
  const [selectedRecords, setSelectedRecords] = useState([]);
  const [filter, setFilter] = useState({ title: 'All', days: 60 });
  const [lastSelected, setLastSelected] = useState(null);
  const [selectedId, setSelectedId] = useState(null);
  const [fileStorageModal, setFileStorageModal] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deleteRecord, setDeleteRecord] = useState([]);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [sortingHeader, setSortingHeader] = useState({ ...ArchiveSortingDetails });
  const [loading, setLoading] = useState(true);
  const [moveModalOpen, setMoveModalOpen] = useState(null);
  const [moveSelectedContent, setMoveSelectedContent] = useState([]);
  const [openDuplicateModalOpen, setOpenDuplicateModalOpen] = useState(false);
  const [isFilePreviewModalOpen, setIsFilePreviewModalOpen] = useState(false);
  const [unsupportedFileModalOpen, setUnsupportedFileModalOpen] = useState(false);
  const [filePreviewDetails, setFilePreviewDetails] = useState(null);
  const [existingMoveFiles, setExistingMoveFiles] = useState([]);
  const [scannedFileDetails, setScannedFileDetails] = useState({ ...scannedFileElem });
  const [isGridView, setIsGridView] = useState(
    JSON.parse(localStorage.getItem('wlp-file-management-view-type'))
  );

  // FETCH ARCHIVE DETAILS
  const fetchArchiveList = async () => {
    setLoading(true);
    try {
      const payload = {
        user_id: user.id,
        lastDays: filter.days
      };
      await dispatch(getArchiveList(payload)).unwrap();
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log('error', error.message);
    }
  };

  useEffect(() => {
    const newData = fileManagementSortingData('Archive', [], null, archiveListData, sortingHeader);
    documentsRef.current = newData;
    setListData(newData);
  }, [archiveListData, sortingHeader]);

  useEffect(() => {
    fetchArchiveList();
  }, [filter]);

  /* OUT SIDE CLICK HANDLE */
  const handleOutsideClick = () => {
    setSelectedId(null);
    setSelectedRecords([]);
    setOpenDropdownId(null);
    setLastSelected(null);
  };

  useFileManagementClickOutside(layoutRowRef, fmListRef, documentsRef, handleOutsideClick);

  // SEARCH
  const handleSearch = useDebounce(async (value) => {
    try {
      let payload = {
        user_id: user.id,
        keyword: value
      };
      await dispatch(searchArchiveItem(payload)).unwrap();
    } catch (error) {
      console.log('error', error.message);
    }
  }, 500);

  // MOVE
  const handleMove = (data) => {
    setMoveModalOpen(true);
    setMoveSelectedContent([...data]);
  };

  // DOUBLE CLICK
  const handleDoubleClick = (data) => {
    if (data.attachment) {
      const fileType = getFileType(data.name);
      setFilePreviewDetails(data);
      if (data.size <= process.env.REACT_APP_FILE_PREVIEW_SIZE_ALLOWED && fileType !== 'unknown') {
        setIsFilePreviewModalOpen(true);
      } else {
        setUnsupportedFileModalOpen(true);
      }
    }
  };

  // SCAN
  const handleScanDownloadFile = async (ids = []) => {
    try {
      const response = await dispatch(scanDownloadFile({ ids })).unwrap();
      return response?.data;
    } catch (error) {
      console.error('Error during scan download:', error);
    }
  };

  // DOWNLOAD & PROTECTED DOWNLOAD
  const handleFileDownload = async (data, is_protected = false) => {
    if (storageDetails?.used_space > storageDetails?.total_space) {
      setFileStorageModal(true);
      return;
    }

    const IdsArray = data.map((val) => val.id);
    const scanResponse = await handleScanDownloadFile(IdsArray);

    if (scanResponse?.pending_files?.length > 0) {
      toast.info('Scanning process in progress. Please try again later.');
      setSelectedRecords([]);
      return;
    }

    if (data.length === 1 && data[0].attachment && !is_protected) {
      if (scanResponse?.infected_files?.length > 0 || scanResponse?.not_scan_files?.length > 0) {
        setScannedFileDetails({
          modalOpen: true,
          isBigFile: scanResponse.not_scan_files?.length > 0,
          details: data
        });
      } else {
        fileDownload(data[0]);
      }
      return;
    }

    const infectedFileNames = scanResponse?.infected_files?.map((obj) => obj.name).join(', ') || '';
    const isSingleAttachment = data.length === 1 && !data[0].attachment;

    if (scanResponse?.infected_files?.length > 0) {
      if (isSingleAttachment) {
        toast.error(`${infectedFileNames} This file cannot be downloaded.`);
        setSelectedRecords([]);
        return;
      } else {
        toast.error(`${infectedFileNames} These files cannot be downloaded.`);
        IdsArray = IdsArray.filter(
          (id) => !scanResponse.infected_files.some((obj) => obj.id === id)
        );
      }
    }

    if (IdsArray.length === 0) {
      setSelectedRecords([]);
      return;
    }

    try {
      const payload = { user_id: user.id, id: IdsArray, is_protected: is_protected };
      await dispatch(downloadItem(payload)).unwrap();
    } catch (error) {
      console.error('Error during file download:', error.message);
    }
  };

  // DELETE
  const handleDelete = async () => {
    setDeleteLoading(true);
    setDeleteModalOpen(false);
    let IdsArray = [];
    deleteRecord.map((val) => IdsArray.push(val.id));
    try {
      let payload = {
        ids: [...IdsArray]
      };
      const response = await dispatch(deleteFileToTrash(payload)).unwrap();
      if (response.status) {
        toast.success(deleteMsg(deleteRecord));
        fetchArchiveList();
      }
      setDeleteRecord([]);
      setDeleteLoading(false);
    } catch (error) {
      console.log('error', error.message);
      setDeleteModalOpen(false);
      setDeleteRecord([]);
      setDeleteLoading(false);
    }
  };

  const [isLoader] = useOutletContext();

  return (
    <React.Fragment>
      {isLoader ? (
        listData && listData.length === 0 ? (
          <SkeletonArchiveTrash />
        ) : (
          <SkeletonFileManagement
            isGridView={isGridView}
            breadcrumbDetails={[]}
            moduleName="Archive"
          />
        )
      ) : (
        <section className="individual-main-section main-section file-management-section archive-page">
          <Container fluid>
            <Row>
              <Col lg={12}>
                <FileManagementHeaderSection
                  moduleName="Archive"
                  breadcrumbDetails={[]}
                  isGridView={isGridView}
                  setIsGridView={setIsGridView}
                  setSelectedId={setSelectedId}
                  setSelectedRecords={setSelectedRecords}
                  layoutRowRef={layoutRowRef}
                  selectedRecords={selectedRecords}
                  fetchList={fetchArchiveList}
                  handleSearch={handleSearch}
                  handleDelete={() => {
                    setDeleteRecord(selectedRecords);
                    setDeleteModalOpen(true);
                  }}
                  handleFileDownload={() => handleFileDownload(selectedRecords, false)}
                  handleProtectedFileDownload={() => handleFileDownload(selectedRecords, true)}
                  handleMove={() => handleMove(selectedRecords)}
                />
              </Col>
              {loading ? (
                <CapsyncLoader />
              ) : (
                <Col lg={12}>
                  {isGridView ? (
                    <GridView
                      moduleName="Archive"
                      data={listData}
                      selectedRecords={selectedRecords}
                      setSelectedRecords={setSelectedRecords}
                      openDropdownId={openDropdownId}
                      setOpenDropdownId={setOpenDropdownId}
                      setLastSelected={setLastSelected}
                      lastSelected={lastSelected}
                      isGridView={isGridView}
                      sortingHeader={sortingHeader}
                      setSortingHeader={setSortingHeader}
                      filter={filter}
                      setFilter={setFilter}
                      handleDelete={(value) => {
                        setDeleteRecord(value);
                        setDeleteModalOpen(true);
                      }}
                      handleFileDownload={(data) => handleFileDownload(data, false)}
                      handleProtectedFileDownload={(data) => handleFileDownload(data, true)}
                      handleMove={(data) => handleMove(data)}
                      handleDoubleClick={handleDoubleClick}
                    />
                  ) : (
                    <ListView
                      moduleName="Archive"
                      data={listData}
                      isGridView={isGridView}
                      selectedRecords={selectedRecords}
                      setSelectedRecords={setSelectedRecords}
                      sortingHeader={sortingHeader}
                      fmListRef={fmListRef}
                      setSortingHeader={setSortingHeader}
                      selectedId={selectedId}
                      setSelectedId={setSelectedId}
                      setLastSelected={setLastSelected}
                      lastSelected={lastSelected}
                      filter={filter}
                      setFilter={setFilter}
                      handleDelete={(value) => {
                        setDeleteRecord(value);
                        setDeleteModalOpen(true);
                      }}
                      handleFileDownload={(data) => handleFileDownload(data, false)}
                      handleProtectedFileDownload={(data) => handleFileDownload(data, true)}
                      handleMove={(data) => handleMove(data)}
                      handleDoubleClick={handleDoubleClick}
                    />
                  )}
                </Col>
              )}
              <Col lg={12}>
                {!loading && (
                  <UpgradeStorage
                    setFileStorageModal={setFileStorageModal}
                    fileStorageModal={fileStorageModal}
                  />
                )}
              </Col>
            </Row>
          </Container>
        </section>
      )}
      {deleteModalOpen && (
        <FileManagementDeleteModal
          moduleName="Archive"
          showModal={deleteModalOpen}
          deleteRecord={deleteRecord}
          onCancelClick={() => {
            setDeleteModalOpen(false);
            setDeleteRecord([]);
          }}
          onConfirmDelete={() => handleDelete()}
        />
      )}
      {deleteLoading && (
        <LoaderModal loading={deleteLoading} message={loadingMessage(deleteRecord, 'delete')} />
      )}
      {moveModalOpen && (
        <FileFolderMoveModal
          moveModalOpen={moveModalOpen}
          moveSelectedContent={moveSelectedContent}
          setOpenDuplicateModalOpen={setOpenDuplicateModalOpen}
          setExistingMoveFiles={setExistingMoveFiles}
          closeModal={(callListApi = true) => {
            callListApi && fetchArchiveList();
            setMoveModalOpen(false);
            setMoveSelectedContent([]);
          }}
        />
      )}
      {openDuplicateModalOpen && (
        <MoveAlertModal
          showModal={openDuplicateModalOpen}
          existingMoveFiles={existingMoveFiles}
          onCancelClick={() => {
            setOpenDuplicateModalOpen(false);
            setExistingMoveFiles([]);
          }}
        />
      )}
      {isFilePreviewModalOpen && (
        <FilePreview
          showModal={isFilePreviewModalOpen}
          fileDetails={filePreviewDetails}
          onCancelClick={() => {
            setIsFilePreviewModalOpen(false);
            setFilePreviewDetails(null);
          }}
        />
      )}
      {unsupportedFileModalOpen && (
        <UnsupportedFileModal
          showModal={unsupportedFileModalOpen}
          onCancelClick={() => {
            setUnsupportedFileModalOpen(false);
            setFilePreviewDetails(null);
          }}
          onDownloadClick={() => {
            fileDownload(filePreviewDetails);
            setUnsupportedFileModalOpen(false);
            setFilePreviewDetails(null);
          }}
        />
      )}
      {scannedFileDetails?.modalOpen && (
        <DownloadConfirmationModal
          scannedFileDetails={scannedFileDetails}
          onCancelClick={() => {
            setSelectedRecords([]);
            setScannedFileDetails({ ...scannedFileElem });
          }}
        />
      )}
    </React.Fragment>
  );
};

export default Archive;
