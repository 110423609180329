import React, { useEffect, useState } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { Offcanvas, Button, Badge, OverlayTrigger, Tooltip } from 'react-bootstrap';

// COMPONENT IMPORTS
import CapsyncIcon from '../../components/CapsyncIcon';
import { getInitialFullName } from '../../utils/common';
import CapsyncToolTip from '../../components/CapsyncToolTip';

// API
import {
  accountAccessApproveDecline,
  completedSignatureList,
  pendingSignatureList
} from '../../slices/requestSlice';
import { useAccessUser } from '../../slices/authSlice';

/* ============================== OFF CANVAS ============================== */
const OffCanvas = ({
  show,
  setShow,
  setShowCancelRequestModal,
  accordionItemDetails,
  canvasDetails,
  entireObjDetails,
  rowData,
  tab
}) => {
  const { user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const [permissionVal, setPermissionVal] = useState(1);
  const accessUser = useAccessUser();
  const [isLoading, setIsLoading] = useState(false);
  const isFirstUser = canvasDetails?.signature_users
    ? canvasDetails.signature_users[0].is_first_user
    : canvasDetails.is_first_user;

  const signatureStatusAccordionUser = (status) => {
    switch (status) {
      case 1:
        return 'PENDING';
      case 2:
        return 'PENDING';
      case 3:
        return 'COMPLETED';
      case 4:
        return 'REJECTED';
      case 5:
        return 'REJECTED';
      default:
        return 'Unknown';
    }
  };
  const signatureStatusSingleUser = (status) => {
    switch (status) {
      case 1:
        return 'PENDING';
      case 2:
        return 'COMPLETED';
      case 3:
        return 'REJECTED';
      default:
        return 'Unknown';
    }
  };
  useEffect(() => {
    if (canvasDetails.permission_type === 'view') {
      canvasDetails.id && checkPermissionValue(1);
    } else {
      canvasDetails.id && checkPermissionValue(2);
    }
  }, [canvasDetails.permission_type]);

  const handleCancelRequest = () => {
    setShow(false);
    setShowCancelRequestModal(true);
  };

  const checkPermissionValue = async (params) => {
    setPermissionVal(params);
    const payload = {
      permission_type: params === 1 ? 'view' : 'standard'
    };
    const response = await dispatch(
      accountAccessApproveDecline({ pfsId: canvasDetails.id, payload })
    ).unwrap();
    if (response.code === 200) {
      const data = {
        user_id: user && user.id
      };
      await dispatch(pendingSignatureList(data)).unwrap();
      await dispatch(completedSignatureList(data)).unwrap();
    }
  };

  const processRequest = async ({ pfsId, payload }) => {
    setIsLoading(true);
    const response = await dispatch(
      accountAccessApproveDecline({
        pfsId,
        payload
      })
    ).unwrap();
    if (response.code === 200) {
      const data = {
        user_id: user && user.id
      };
      await dispatch(pendingSignatureList(data)).unwrap();
      await dispatch(completedSignatureList(data)).unwrap();
      setShow(false);
    }
    setIsLoading(false);
  };

  const approveRequest = async () => {
    const payload = {
      permission_type: permissionVal === 1 ? 'view' : 'standard',
      is_accepted: true
    };
    processRequest({ pfsId: canvasDetails.id, payload });
  };

  const declineRequest = async () => {
    const payload = {
      permission_type: permissionVal === 1 ? 'view' : 'standard',
      is_accepted: false
    };
    processRequest({ pfsId: canvasDetails.id, payload });
  };

  const deleteUser = async () => {
    const payload = {
      is_deleted: true
    };
    processRequest({ pfsId: canvasDetails.id, payload });
  };

  return (
    <Offcanvas show={show} className="cs-offcanvas" placement="end" onHide={() => setShow(false)}>
      <Offcanvas.Header>
        <div className="img-container">
          <div className="offcanvas-avatar">
            {canvasDetails.type !== 'accountAccess' ? (
              <div className="cs-avatar cs-avatar-text">
                {canvasDetails?.signature_users
                  ? getInitialFullName(
                      canvasDetails?.signature_users[0].first_name +
                        ' ' +
                        canvasDetails?.signature_users[0].last_name
                    )
                  : getInitialFullName(canvasDetails.first_name + ' ' + canvasDetails.last_name)}
              </div>
            ) : (
              <div className="cs-avatar cs-avatar-text">
                {getInitialFullName(canvasDetails.display_name)}
              </div>
            )}
          </div>
          <span onClick={() => setShow(false)}>
            <CapsyncIcon title="close-outlined" size="18" />
          </span>
        </div>
        <div className="header-information">
          {canvasDetails.type !== 'accountAccess' ? (
            <div className="name-information">
              <span className="cs-semi-bold-h3">
                {canvasDetails?.signature_users
                  ? canvasDetails?.signature_users
                      .map((name) => name.first_name + ' ' + name.last_name)
                      .join(', ')
                  : canvasDetails.first_name + ' ' + canvasDetails.last_name}{' '}
              </span>
            </div>
          ) : (
            <div className="name-information">
              <span className="cs-semi-bold-h3">{canvasDetails?.display_name}</span>
            </div>
          )}

          <div className="designation-information">
            <span className="cs-regular-sub-heading-m cs-neutral-60">{'Individual'}</span>
          </div>
        </div>
      </Offcanvas.Header>
      <div className="mob-scroller">
        <Offcanvas.Body>
          <div className="userInfo">
            <div className="userInfoSection">
              {canvasDetails.type !== 'accountAccess' ? (
                <div className="top-information-container">
                  <div className="body-top-information">
                    <div className="cs-medium-sub-heading-s">Sender email </div>
                    <p className="cs-regular-body-text-l cs-ellipsis">
                      {(() => {
                        const emails =
                          (canvasDetails?.signature_users ?? [])
                            .map((user) => user.email)
                            .join(', ') ||
                          canvasDetails?.email ||
                          '-';

                        return emails.length > 25 ? (
                          <CapsyncToolTip
                            trigger="click"
                            Child={emails}
                            placement="top"
                            message={emails}
                            type="text"
                          />
                        ) : (
                          emails
                        );
                      })()}
                    </p>
                  </div>
                  <div className="body-top-information">
                    <div className="cs-medium-sub-heading-s">Sender phone number</div>
                    <p className="cs-regular-body-text-l">
                      {canvasDetails?.signature_users
                        ? canvasDetails?.signature_users.map((obj) => obj.phone)
                        : canvasDetails.phone || '-'}
                    </p>
                  </div>

                  {canvasDetails?.is_first_user === true && (
                    <div className="body-top-information">
                      <div className="cs-medium-sub-heading-s">Cosigner Email</div>
                      <p className="cs-regular-body-text-l cs-ellipsis">
                        {accordionItemDetails?.signature_users &&
                        accordionItemDetails?.signature_users?.length > 0 ? (
                          accordionItemDetails?.signature_users[1].email?.length > 25 ? (
                            <CapsyncToolTip
                              trigger="click"
                              Child={accordionItemDetails?.signature_users[1].email}
                              placement={'top'}
                              message={accordionItemDetails?.signature_users[1].email}
                              type="text"
                            />
                          ) : (
                            accordionItemDetails?.signature_users[1].email
                          )
                        ) : (
                          '-'
                        )}
                      </p>
                    </div>
                  )}
                  {canvasDetails?.is_first_user === true && (
                    <div className="body-top-information">
                      <div className="cs-medium-sub-heading-s">Cosigner Name</div>
                      <p className="cs-regular-body-text-l">
                        {accordionItemDetails?.signature_users &&
                          accordionItemDetails?.signature_users[1].first_name +
                            ' ' +
                            accordionItemDetails?.signature_users[1].last_name}
                      </p>
                    </div>
                  )}
                </div>
              ) : (
                <div className="top-information-container">
                  <div className="body-top-information">
                    <div className="cs-medium-sub-heading-s">Email </div>
                    <p className="cs-regular-body-text-l cs-ellipsis">
                      {canvasDetails?.email ? (
                        canvasDetails?.email?.length > 25 ? (
                          <CapsyncToolTip
                            trigger="click"
                            Child={canvasDetails?.email}
                            placement={'top'}
                            message={canvasDetails?.email}
                            type="text"
                          />
                        ) : (
                          canvasDetails?.email
                        )
                      ) : (
                        '-'
                      )}
                    </p>
                  </div>
                  <div className="body-top-information">
                    <div className="cs-medium-sub-heading-s">Phone No</div>
                    <p className="cs-regular-body-text-l">
                      {canvasDetails?.phone_no ? canvasDetails?.phone_no : '-'}
                    </p>
                  </div>
                </div>
              )}

              {canvasDetails.type !== 'accountAccess' && (
                <div className="top-information-container">
                  <div className="body-top-information">
                    <div className="cs-medium-sub-heading-s">Recipient name</div>
                    <p className="cs-regular-body-text-l">
                      {canvasDetails?.signature_users
                        ? canvasDetails?.receiver_first_name +
                          ' ' +
                          canvasDetails?.receiver_last_name
                        : accordionItemDetails?.receiver_first_name +
                          ' ' +
                          accordionItemDetails?.receiver_last_name}
                    </p>
                  </div>
                  <div className="body-top-information">
                    <div className="cs-medium-sub-heading-s">Recipient email</div>
                    <p className="cs-regular-body-text-l cs-ellipsis">
                      {canvasDetails?.signature_users || accordionItemDetails?.receiver_email
                        ? (() => {
                            const email = canvasDetails?.signature_users
                              ? canvasDetails?.receiver_email
                              : accordionItemDetails?.receiver_email;
                            if (email?.length > 25) {
                              return (
                                <CapsyncToolTip
                                  trigger="click"
                                  Child={email}
                                  placement="top"
                                  message={email}
                                  type="text"
                                />
                              );
                            }
                            return email;
                          })()
                        : '-'}
                    </p>
                  </div>
                  <div className="body-top-information">
                    <div className="cs-medium-sub-heading-s">Notes</div>
                    <p className="cs-regular-body-text-l">
                      {canvasDetails?.signature_users
                        ? canvasDetails?.receiver_notes || '-'
                        : accordionItemDetails?.receiver_notes || '-'}
                    </p>
                  </div>
                </div>
              )}
              {canvasDetails.type !== 'accountAccess' && (
                <div className="top-information-container">
                  <div className="body-top-information">
                    <div className="cs-medium-sub-heading-s">Reason for request</div>
                    <p className="cs-regular-body-text-l">Signature request</p>
                  </div>
                  <div className="body-top-information">
                    <div className="cs-medium-sub-heading-s">Request status</div>
                    <p className="cs-regular-body-text-l">
                      {canvasDetails?.signature_users ? (
                        <Badge
                          className={`cs-badge cs-regular-body-text-s ${canvasDetails?.signature_status === 1 ? 'cs-warning' : canvasDetails?.signature_status === 2 ? 'cs-warning' : canvasDetails?.signature_status === 3 ? 'cs-success' : 'cs-danger'}`}>
                          {signatureStatusAccordionUser(canvasDetails?.signature_status)}
                        </Badge>
                      ) : (
                        <Badge
                          className={`cs-badge cs-regular-body-text-s ${canvasDetails?.status === 1 ? 'cs-warning' : canvasDetails?.status === 2 ? 'cs-success' : 'cs-danger'}`}>
                          {signatureStatusSingleUser(canvasDetails?.status)}
                        </Badge>
                      )}
                    </p>
                  </div>
                  <div className="body-top-information">
                    <div className="cs-medium-sub-heading-s">Date of request</div>
                    <p className="cs-regular-body-text-l">
                      {canvasDetails?.signature_users
                        ? moment(canvasDetails.createdAt).format('MM/DD/YYYY')
                        : moment(accordionItemDetails.createdAt).format('MM/DD/YYYY')}
                    </p>
                  </div>
                  <div className="body-top-information">
                    <div className="cs-medium-sub-heading-s">Date of completion</div>
                    <p className="cs-regular-body-text-l">
                      {canvasDetails?.signature_users && canvasDetails?.signature_date
                        ? moment(canvasDetails.signature_date).format('MM/DD/YYYY')
                        : accordionItemDetails.signature_date
                          ? moment(accordionItemDetails.signature_date).format('MM/DD/YYYY')
                          : '-'}
                    </p>
                  </div>
                </div>
              )}
              {canvasDetails.type === 'accountAccess' && (
                <div className="top-information-container">
                  <div className="body-top-information">
                    <div className="cs-medium-sub-heading-s">Request for</div>
                    <p className="cs-regular-body-text-l">Account access request</p>
                  </div>
                  <div className="body-top-information">
                    <div className="cs-medium-sub-heading-s">Status</div>
                    <p className="cs-regular-body-text-l">
                      <Badge
                        className={`cs-badge cs-regular-body-text-s ${canvasDetails?.status === '1' ? 'cs-warning' : canvasDetails?.status === '2' ? 'cs-success' : 'cs-danger'}`}>
                        {canvasDetails?.status === '1'
                          ? 'Pending'
                          : canvasDetails?.status === '2'
                            ? 'Completed'
                            : 'Rejected'}
                      </Badge>
                    </p>
                  </div>
                  <div className="body-top-information">
                    <div className="cs-medium-sub-heading-s">Request Date</div>
                    <p className="cs-regular-body-text-l">
                      {moment(canvasDetails.request_date).format('MM/DD/YYYY')}
                    </p>
                  </div>

                  {/* -------------------------------- As discussed with Jay sir Prem Bhai and Meet i have commented this below code.------------------------------------- */}

                  {/* <div className="body-top-information">
                    <div className="cs-medium-sub-heading-s">Complete Date</div>
                    <p className="cs-regular-body-text-l">
                      {canvasDetails.request_date !== null
                        ? moment(canvasDetails.request_date).format('MM/DD/YYYY')
                        : '-'}
                    </p>
                  </div> */}
                  {!accessUser && (
                    <div className="body-top-information">
                      <p className="cs-medium-sub-heading-s cs-neutral-60">Permissions</p>

                      <div className="d-flex">
                        <OverlayTrigger
                          key={'top'}
                          placement={'top'}
                          overlay={
                            <Tooltip
                              id={`tooltip-top`}
                              className="cs-tooltip cs-regular-body-text-m">
                              Viewing privilege only, No editing privileges.
                            </Tooltip>
                          }>
                          <div
                            className="cs-radio-btn cursor-pointer"
                            onClick={() => checkPermissionValue(1)}>
                            <input
                              className="radio-button"
                              type="radio"
                              label="1"
                              name="access_permission"
                              checked={permissionVal === 1}
                              onClick={() => checkPermissionValue(1)}
                            />
                            <span className="cs-light-body-text-l cs-neutral-100">View Only</span>
                          </div>
                        </OverlayTrigger>

                        <OverlayTrigger
                          key={'top'}
                          placement={'top'}
                          overlay={
                            <Tooltip
                              id={`tooltip-top`}
                              className="cs-tooltip cs-regular-body-text-m">
                              Viewing and limited editing privileges.
                            </Tooltip>
                          }>
                          <div
                            className="cs-radio-btn cursor-pointer"
                            onClick={() => checkPermissionValue(2)}>
                            <input
                              className="radio-button"
                              type="radio"
                              name="access_permission"
                              label="2"
                              checked={permissionVal === 2}
                              onClick={() => checkPermissionValue(2)}
                            />
                            <span className="cs-light-body-text-l cs-neutral-100">Standard</span>
                          </div>
                        </OverlayTrigger>
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </Offcanvas.Body>
        {canvasDetails.type === 'accountAccess' && !accessUser && (
          <React.Fragment>
            {tab === 'pending' && (
              <div className="cs-btn-group">
                <Button
                  className="cs-btn-success md-btn cs-regular-h5"
                  onClick={approveRequest}
                  disabled={isLoading}>
                  Approve
                </Button>
                <Button
                  className="cs-btn-danger md-btn cs-regular-h5"
                  onClick={declineRequest}
                  disabled={isLoading}>
                  Decline
                </Button>
              </div>
            )}
            {!accessUser && tab === 'completed' && (
              <div className="cs-btn-group">
                <Button
                  className="cs-btn-danger md-btn cs-regular-h5"
                  onClick={deleteUser}
                  disabled={isLoading}>
                  Delete User
                </Button>
              </div>
            )}
          </React.Fragment>
        )}
        {canvasDetails.type !== 'accountAccess' &&
          ((entireObjDetails.signature_status === 1 && isFirstUser === true) ||
            (entireObjDetails.signature_status === 2 && isFirstUser === false)) && (
            <Button
              className={`md-btn cs-regular-h5 canvas-cancel-btn ${canvasDetails.type === 'signatureTo' ? 'cs-btn-secondary' : 'cs-btn-danger'}`}
              onClick={handleCancelRequest}>
              {`${canvasDetails.type === 'signatureTo' ? 'Cancel' : 'Decline'}`}
            </Button>
          )}
      </div>
    </Offcanvas>
  );
};

OffCanvas.propTypes = {
  show: PropTypes.bool,
  setShow: PropTypes.func,
  setShowCancelRequestModal: PropTypes.func,
  accordionItemDetails: PropTypes.object,
  canvasDetails: PropTypes.object,
  entireObjDetails: PropTypes.object,
  rowData: PropTypes.object,
  tab: PropTypes.string
};

export default OffCanvas;
