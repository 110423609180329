import React from 'react';
import { isMobile } from 'react-device-detect';
import { Table } from 'react-bootstrap';
import PropTypes from 'prop-types';

// COMPONENT IMPORTS
import CapsyncIcon from '../../components/CapsyncIcon';
import {
  checkSorting,
  handleKeyDown,
  headerConfig,
  onRowSingleClick
} from './FileManagementCommonElem';
import ActionIcons from './ActionIcons';
import { fileSizeCalc, getFileIcon } from '../../utils/common';
import SortingAndFilteringHeader from './SortingAndFilteringHeader';

/* ============================== LIST VIEW ============================== */
const ListView = (props) => {
  const {
    data = [],
    selectedRecords = [],
    fmListRef,
    handleMove,
    moduleName = 'My Documents',
    breadcrumbDetails = [],
    parentId,
    sortingHeader,
    setSortingHeader,
    selectedId,
    setSelectedId,
    setSelectedRecords,
    setLastSelected,
    lastSelected,
    handleDoubleClick,
    isGridView,
    filter,
    setFilter,
    handleDelete,
    handleFileDownload,
    handleProtectedFileDownload,
    handleArchive,
    handleRenameItem
  } = props;

  const onShortChange = (value) => {
    if (!checkSorting(moduleName, breadcrumbDetails, parentId)) {
      return;
    }

    setSortingHeader((prevSortingHeader) => {
      const newSortingHeader = {};

      Object.keys(prevSortingHeader).forEach((key) => {
        if (key === value) {
          newSortingHeader[key] = {
            ...prevSortingHeader[key],
            showIcon: true,
            sortOrder: prevSortingHeader[key].showIcon
              ? prevSortingHeader[key].sortOrder === 'asc'
                ? 'desc'
                : 'asc'
              : 'asc'
          };
        } else {
          newSortingHeader[key] = {
            ...prevSortingHeader[key],
            showIcon: false
          };
        }
      });
      return newSortingHeader;
    });
  };

  const config = headerConfig[moduleName];

  const checkSelectedRecord = (id) => selectedRecords.some((e) => e.id === id);

  return (
    <React.Fragment>
      <SortingAndFilteringHeader
        isGridView={isGridView}
        moduleName={moduleName}
        breadcrumbDetails={breadcrumbDetails}
        parentId={parentId}
        sortingHeader={sortingHeader}
        setSortingHeader={setSortingHeader}
        filter={filter}
        setFilter={setFilter}
      />
      {data && data.length > 0 ? (
        <div
          className="cs-list-view"
          onKeyDownCapture={(e) => handleKeyDown(e) && setSelectedRecords(data)}
          tabIndex="0"
          ref={fmListRef}>
          <div className="fm-list">
            <Table>
              <thead>
                <tr>
                  <th
                    width="700"
                    className="cs-regular-sub-heading-m cursor-pointer"
                    onClick={() => onShortChange('name')}>
                    Name
                    {sortingHeader.name.showIcon &&
                      checkSorting(moduleName, breadcrumbDetails, parentId) && (
                        <span className="cs-neutral-100">
                          <CapsyncIcon
                            title={
                              sortingHeader.name.sortOrder === 'asc'
                                ? 'arrow-up-outlined'
                                : 'arrow-down-outlined'
                            }
                            size="14"
                          />
                        </span>
                      )}
                  </th>
                  {isMobile && <th className=""></th>}
                  {moduleName === 'My Documents' && (
                    <th
                      className="cs-regular-sub-heading-m cursor-pointer"
                      onClick={() => onShortChange('updated_by')}>
                      Modified By
                      {sortingHeader.updated_by.showIcon &&
                        checkSorting(moduleName, breadcrumbDetails, parentId) && (
                          <span className="cs-neutral-100">
                            <CapsyncIcon
                              title={
                                sortingHeader.updated_by.sortOrder === 'asc'
                                  ? 'arrow-up-outlined'
                                  : 'arrow-down-outlined'
                              }
                              size="14"
                            />
                          </span>
                        )}
                    </th>
                  )}
                  <th
                    className="cs-regular-sub-heading-m cursor-pointer"
                    onClick={() => onShortChange(config.key)}>
                    {config.text}
                    {sortingHeader[config.key]?.showIcon &&
                      checkSorting(moduleName, breadcrumbDetails, parentId) && (
                        <span className="cs-neutral-100">
                          <CapsyncIcon
                            title={
                              sortingHeader[config.key].sortOrder === 'asc'
                                ? 'arrow-up-outlined'
                                : 'arrow-down-outlined'
                            }
                            size="14"
                          />
                        </span>
                      )}
                  </th>
                  <th className="cs-regular-sub-heading-m">File Size </th>
                  {!isMobile && <th className=""></th>}
                </tr>
              </thead>
              <tbody>
                {data.map((val, index) => {
                  const extension = val?.name?.split(/\.([^.]+)$/)?.[1]?.toLowerCase();
                  const icon = getFileIcon(extension);
                  return (
                    <tr
                      key={index}
                      onClick={(event) => {
                        isMobile
                          ? handleDoubleClick(val)
                          : onRowSingleClick(
                              event,
                              val,
                              setSelectedRecords,
                              data,
                              lastSelected,
                              setLastSelected
                            );
                      }}
                      onDoubleClick={() => !isMobile && handleDoubleClick(val)}
                      className={checkSelectedRecord(val.id) ? 'selected' : ''}
                      id={'grid-' + index}>
                      <td className="cs-regular-sub-heading-m">
                        <div className="fm-name">
                          {val.attachment ? (
                            <span>{icon}</span> // Replace with actual icon or component
                          ) : (
                            <span className="cs-neutral-60">
                              <CapsyncIcon title="folder-icon-filled" size="22" />
                            </span>
                          )}
                          <span title={val.name}>{val.name}</span>
                        </div>
                      </td>
                      {isMobile && (
                        <td className="cs-light-body-text-l">
                          <ActionIcons
                            value={val}
                            parentId={parentId}
                            handleMove={handleMove}
                            moduleName={moduleName}
                            selectedRecords={selectedRecords}
                            selectedId={selectedId}
                            setSelectedId={setSelectedId}
                            handleDelete={handleDelete}
                            handleFileDownload={handleFileDownload}
                            handleProtectedFileDownload={handleProtectedFileDownload}
                            handleArchive={handleArchive}
                            handleRenameItem={handleRenameItem}
                          />
                        </td>
                      )}
                      {moduleName === 'My Documents' && (
                        <td className="cs-light-body-text-l">{val.updated_by || '-'}</td>
                      )}
                      <td className="cs-light-body-text-l">
                        {val.updatedAt
                          ? new Date(val.updatedAt).toLocaleDateString('en-US', {
                              year: 'numeric',
                              month: '2-digit',
                              day: '2-digit'
                            })
                          : '-'}
                      </td>
                      <td className="cs-light-body-text-l">{fileSizeCalc(val?.size)}</td>
                      {!isMobile && (
                        <td
                          className="cs-light-body-text-l"
                          onMouseLeave={() => setSelectedId(null)}>
                          <ActionIcons
                            value={val}
                            parentId={parentId}
                            handleMove={handleMove}
                            moduleName={moduleName}
                            selectedRecords={selectedRecords}
                            selectedId={selectedId}
                            setSelectedId={setSelectedId}
                            handleDelete={handleDelete}
                            handleFileDownload={handleFileDownload}
                            handleProtectedFileDownload={handleProtectedFileDownload}
                            handleArchive={handleArchive}
                            handleRenameItem={handleRenameItem}
                          />
                        </td>
                      )}
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>
        </div>
      ) : (
        <div className="manage-file-container">
          <label className="cs-upload-files cs-regular-h4">
            We don't have any active records to display
          </label>
        </div>
      )}
    </React.Fragment>
  );
};

// PROPS TYPE
ListView.propTypes = {
  fmListRef: PropTypes.any,
  data: PropTypes.array,
  selectedRecords: PropTypes.array,
  moduleName: PropTypes.string,
  breadcrumbDetails: PropTypes.array,
  parentId: PropTypes.any,
  sortingHeader: PropTypes.object,
  setSortingHeader: PropTypes.any,
  isGridView: PropTypes.bool,
  setLastSelected: PropTypes.any,
  lastSelected: PropTypes.any,
  setSelectedRecords: PropTypes.any,
  selectedId: PropTypes.any,
  setSelectedId: PropTypes.any,
  handleDoubleClick: PropTypes.func,
  handleMove: PropTypes.func,
  filter: PropTypes.object,
  setFilter: PropTypes.any,
  handleDelete: PropTypes.func,
  handleFileDownload: PropTypes.func,
  handleProtectedFileDownload: PropTypes.func,
  handleArchive: PropTypes.func,
  handleRenameItem: PropTypes.func
};

export default ListView;
