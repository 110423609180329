import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { toast } from 'react-toastify';
import secureLocalStorage from 'react-secure-storage';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

// CSS IMPORTS
import '../assets/css/dashboard.css';
import './components/Skeleton/skeleton.css';

// COMPONENT IMPORTS
import { clearPersisterStorage, roles } from '../utils/common';
import { decrypt, localObjectClear } from '../utils/system';
import { socket } from '../config/Socket';
import Header from '../pages/components/Header';
import SideBar from './components/SideBar/SideBar';
import { VIEW_ONLY_PATHS } from '../constants/sidebar';
import UploadStatus from './FileManagement/UploadStatus';
import AddAccountModal from './components/AddAccountModal';
import DownloadStatus from './FileManagement/DownloadStatus';
import SkeletonNavbar from './components/Skeleton/SkeletonNavbar';
import SkeletonSidebar from './components/Skeleton/SkeletonSidebar';
import UpgradeStorageModal from './FileManagement/UpgradeStorageModal';
import CompleteProfile from '../components/userProfile/CompleteProfile';
import LogoutConfirmationModal from '../components/LogoutConfirmationModal';

// API
import {
  accessUserDetails,
  authUserLogout,
  clientViewByIndividual,
  clientViewByProfessionalUser,
  useAccessUser,
  useToUserPermission,
  useUser,
  useUserID
} from '../slices/authSlice';
import {
  getAssetsDashboard,
  getLiabilitiesDashboard,
  getMainDashboard,
  resetDashboardSlice,
  useMainDashboard
} from '../slices/dashboardSlice';
import {
  setFileUploaded,
  setProgressUploadDetails,
  showUploadProgress,
  useUploadProgress
} from '../slices/commonSlice';
import { getRequestList } from '../slices/accreditationSlice';
import { getStorageDetails } from '../slices/fileManagementSlice';
import { setActiveNav, toggleSidebar } from '../slices/sidebarSlice';
import { getCollaborationListing } from '../slices/collaborationSlice';
import { completedSignatureList, pendingSignatureList } from '../slices/requestSlice';

/* ============================== MAIN LAYOUT ============================== */
const Layout = () => {
  const dispatch = useDispatch();
  const navigation = useNavigate();

  const user = useUser();
  const userID = useUserID();
  const { brandDetails } = useSelector((state) => state.branding);
  const uploadProgress = useUploadProgress();
  const isSidebarOpen = useSelector((state) => state.sidebar.isSidebarOpen);
  const mainDashboard = useMainDashboard();
  const areAssetsAndLiabilitiesZero =
    mainDashboard?.assets_all_total === 0 && mainDashboard?.liability_all_total === 0;
  const isProfileCompletionModalOpen =
    user && (user?.user_personal_details === null || user?.is_initial_setup_done === false);
  const { pathname } = useLocation();
  const userPermission = useToUserPermission();
  const accessUser = useAccessUser();

  const [setThumbnailMsg] = useState(''); // eslint-disable-line
  const [isAccountModelOpen, setIsAccountModelOpen] = useState(false);
  const [isLoader, setIsLoader] = useState(true);
  const [isRedirectLoader, setIsRedirectLoader] = useState(true);
  const [fileStorageModal, setFileStorageModal] = useState(false);
  const [showLogoutModal, setShowLogoutModal] = useState(false);

  const pfsAccessId = userPermission?.accessUserID || null;
  // const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

  // FETCH STORAGE
  const fetchStorageDetails = async () => {
    try {
      const payload = { user_id: user?.id };
      await dispatch(getStorageDetails(payload)).unwrap();
    } catch (error) {
      console.log('error', error.message);
    }
  };

  const getAccreditationList = async () => {
    try {
      const data = {
        user_id: user.id
      };
      dispatch(getRequestList(data));
    } catch (error) {
      console.log('error', error);
    }
  };

  const switchUserFun = async (item) => {
    const id = item?.user_id;
    const permission = item?.permission_type;

    try {
      if (accessUser) {
        socket.emit('exitAccess', {
          user_id: accessUser?.accessUserID,
          access_user_id: userID,
        });
      }

      socket.on('exitAccessResponse', (response) => {
        if (response) {
          socket.emit('addAccess', {
            user_id: accessUser?.accessUserID,
            access_user_id: id,
          });
        }
      });

      const token = secureLocalStorage.getItem('token');

      const payload = {
        token,
        clientId: id,
        professionalUserId:
          accessUser?.roleID == roles.master_admin_financial_advisor.roleID &&
          accessUser?.accessUserID
            ? accessUser?.accessUserID
            : userID
      };

      const accessBy = {
        accessUserID: Number(decrypt(localStorage.getItem('id'))),
        roleName: roles.individual.name,
        roleID: roles.individual.roleID,
        toUserPermission: permission,
        loginUserDetails: user ? user : null,
        is_dashboard_available:
          accessUser?.is_dashboard_available === false
            ? accessUser?.is_dashboard_available
            : user?.is_dashboard_available
      };

      if (accessUser?.roleID != '5') {
        dispatch(accessUserDetails(accessBy));
      }

      const userResponse =
        accessUser?.roleID == '5'
          ? await dispatch(clientViewByProfessionalUser(payload)).unwrap()
          : await dispatch(clientViewByIndividual(payload)).unwrap();

      if (userResponse?.status) {
        dispatch(resetDashboardSlice());
        setTimeout(() => {
          setIsRedirectLoader(false);
          window.location.href = '/dashboard';
        }, 500);
      }
    } catch (error) {
      console.log('error :>> ', error);
      setIsRedirectLoader(false);
    }
  };

  useEffect(() => {
    const pathNameArray = pathname.split('/');
    dispatch(setActiveNav(pathNameArray));
    if (userPermission?.permission_type === 'view') {
      const pathnames = pathname?.split('/');
      const viewOnlyPathNames = [...VIEW_ONLY_PATHS];
      if (!accessUser?.is_dashboard_available) {
        viewOnlyPathNames?.push('my-settings');
      }
      if (viewOnlyPathNames.includes(pathnames[1])) {
        return;
      } else {
        navigation('/dashboard');
      }
    }
  }, [pathname]);

  useEffect(() => {
    if (areAssetsAndLiabilitiesZero) setIsAccountModelOpen(true);
  }, [mainDashboard]);

  useEffect(() => {
    dispatch(getMainDashboard(user?.id));
    dispatch(getAssetsDashboard({ userId: user?.id, orderId: 0 }));
    dispatch(getLiabilitiesDashboard({ userId: user?.id, orderId: 0 }));
    fetchStorageDetails();

    const handleBeforeUnload = () => {
      window.localStorage.removeItem('business_id');
    };

    // Add the event listener for beforeunload
    window.addEventListener('beforeunload', handleBeforeUnload);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  useEffect(() => {
    socket.on('uploadProgress', (progress) => {
      if (progress.code === 200 && progress.message === 'success' && progress.isDelete === true) {
        // getUserStorageFunction();
        if (progress.duplicate_file && progress.duplicate_file.length === 0) {
          dispatch(setFileUploaded(Math.random()));
          fetchStorageDetails();
          toast.success('Your file has been uploaded successfully.');
        } else if (progress.duplicate_file && progress.duplicate_file.length > 0) {
          dispatch(setFileUploaded(Math.random()));
          toast.error("Files with names that already exist can't be fetched.");
        }
      } else if (progress.code === 500 && progress.message === 'Please increase the storage') {
        setFileStorageModal(true);
      } else if (progress.code === 500) {
        toast.error(progress.message);
      }
      dispatch(showUploadProgress(progress));
      dispatch(
        setProgressUploadDetails({
          id: progress.id,
          size: progress.totalSize,
          isDelete: progress.isDelete
        })
      );
    });

    socket.on('thumbnailResponse', (message) => {
      setThumbnailMsg(message);
      dispatch(setFileUploaded(Math.random()));
    });

    socket.on('quickbooks_redirect', (msg) => {
      if (window.localStorage.getItem('quickbooks_redirect')) {
        navigation(
          `/assets/business/reports/${window.localStorage.getItem('quickbooks_redirect')}`
        );
      }
    });

    socket.on('signature_status', (msg) => {
      fetchSignatureRequest();
    });
    socket.on('verified_user', (msg) => {
      fetchCollaborationList();
    });
    socket.on('accreditation_webhook_status_change', (msg) => {
      getAccreditationList();
    });
    // socket.on('redirect_dashboard', (msg) => {
    //   dispatch(authUserLogout());
    //   navigation('/');
    //   localObjectClear();
    //   setTimeout(() => {
    //     toast.error('Account Access Removed. Please contact to Firm Advisor.');
    //   }, 1000);
    // });
    return () => {
      socket.off('uploadProgress');
      socket.off('thumbnailResponse');
      socket.off('signature_status');
      socket.off('verified_user');
      socket.off('quickbooks_redirect');
      socket.off('accreditation_webhook_status_change');
      // socket.off('redirect_dashboard');
    };
  }, []);

  useEffect(() => {
    // check the User has dashboard or not

    if (
      !isProfileCompletionModalOpen &&
      !user?.is_dashboard_available &&
      !pfsAccessId &&
      !accessUser?.accessUserID
    ) {
      setIsRedirectLoader(true);
      switchUserFun(user?.professional_user_access[0]);
    } else {
      setIsRedirectLoader(false);
    }
  }, [isProfileCompletionModalOpen]);

  useEffect(() => {
    if (uploadProgress.data.length > 0) {
      const unloadCallback = (event) => {
        const confirmationMessage = 'Are you sure you want to leave this page?';
        event.returnValue = confirmationMessage; // Standard-compliant browsers
        return confirmationMessage; // Required for legacy browsers
      };

      window.addEventListener('beforeunload', unloadCallback);
      return () => window.removeEventListener('beforeunload', unloadCallback);
    }
  }, [uploadProgress.data.length]);

  useEffect(() => {
    if (brandDetails && (mainDashboard?.assets_all_total || mainDashboard?.liability_all_total)) {
      setIsLoader(false);
    }
  }, [brandDetails, mainDashboard]);

  const fetchSignatureRequest = async () => {
    const data = {
      user_id: user && user.id
    };
    await dispatch(pendingSignatureList(data)).unwrap();
    await dispatch(completedSignatureList(data)).unwrap();
  };

  const fetchCollaborationList = async () => {
    const paginationData = {
      page: 1,
      limit: 10
    };
    await dispatch(getCollaborationListing({ userID, ...paginationData })).unwrap();
  };

  const handlePageOnAdminAccess = () => {
    const broadcastChannel = new BroadcastChannel('logOut_from_same_tabs');
    broadcastChannel.postMessage('tab_logout');
  };

  const handleLogoutUser = () => {
    setShowLogoutModal(false);
    setIsAccountModelOpen(false);
    if (accessUser) {
      socket.emit('exitAccess', {
        user_id: accessUser?.accessUserID,
        access_user_id: userID
      });
    }
    dispatch(authUserLogout());
    clearPersisterStorage(dispatch);
    handlePageOnAdminAccess();
    navigation('/');
    localObjectClear();
  };

  return (
    <div className="cs-main-layout">
      <span
        className={`sidebar-layout-backdrop${isSidebarOpen ? ' isOpen' : ' isClose'}`}
        onClick={() => isSidebarOpen && dispatch(toggleSidebar())}></span>
      {/* <SideBar onAccountOpenModelOpen={() => setIsAccountModelOpen(true)} /> */}
      {isLoader ||
      isRedirectLoader ||
      isProfileCompletionModalOpen ||
      areAssetsAndLiabilitiesZero ? (
        <div className="for-des">
          <SkeletonSidebar />
        </div>
      ) : (
        <SideBar onAccountOpenModelOpen={() => setIsAccountModelOpen(true)} />
      )}
      <div className="cs-contentarea">
        {isLoader ||
        isRedirectLoader ||
        isProfileCompletionModalOpen ||
        areAssetsAndLiabilitiesZero ? (
          <SkeletonNavbar />
        ) : (
          <Header onAccountOpenModelOpen={() => setIsAccountModelOpen(true)} />
        )}
        {/* <Header onAccountOpenModelOpen={() => setIsAccountModelOpen(true)} /> */}
        <Outlet
          context={[
            isLoader ||
              isRedirectLoader ||
              isProfileCompletionModalOpen ||
              areAssetsAndLiabilitiesZero
          ]}
        />
      </div>
      {isProfileCompletionModalOpen && <CompleteProfile />}
      {isAccountModelOpen && !isProfileCompletionModalOpen && (
        <AddAccountModal
          setIsAccountModelOpen={setIsAccountModelOpen}
          isAddAccountFlowClosable={!areAssetsAndLiabilitiesZero}
          setShowLogoutModal={setShowLogoutModal}
        />
      )}
      {ReactDOM.createPortal(<UploadStatus />, document.getElementById('upload-progress'))}
      {ReactDOM.createPortal(<DownloadStatus />, document.getElementById('download-progress'))}
      {fileStorageModal && (
        <UpgradeStorageModal
          showModal={fileStorageModal}
          onCancelClick={() => setFileStorageModal(false)}
        />
      )}
      {showLogoutModal && (
        <LogoutConfirmationModal
          handleLogoutUser={handleLogoutUser}
          handleStayOnCapsync={() => setShowLogoutModal(false)}
          showLogoutModal={showLogoutModal}
        />
      )}
    </div>
  );
};

export default Layout;
