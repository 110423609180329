import React, { useRef } from 'react';
import { Dropdown } from 'react-bootstrap';
import PropTypes from 'prop-types';

// COMPONENT IMPORTS
import CapsyncIcon from '../../components/CapsyncIcon';
import { ArchiveFilterOptions, checkSorting, TrashFilterOptions } from './FileManagementCommonElem';

/* ============================== SORT & FILTER HEADER ============================== */
const SortingAndFilteringHeader = (props) => {
  const {
    isGridView,
    breadcrumbDetails = [],
    parentId = null,
    moduleName = 'My Documents',
    setSortingHeader,
    sortingHeader,
    filter,
    setFilter
  } = props;

  /* USE REFS */
  const mobileMenuRef = useRef(null);

  const onShortChange = (value) => {
    if (!checkSorting(moduleName, breadcrumbDetails, parentId)) {
      return;
    }

    setSortingHeader((prevSortingHeader) => {
      const newSortingHeader = {};

      Object.keys(prevSortingHeader).forEach((key) => {
        if (key === value) {
          newSortingHeader[key] = {
            ...prevSortingHeader[key],
            showIcon: true,
            sortOrder: prevSortingHeader[key].showIcon
              ? prevSortingHeader[key].sortOrder === 'asc'
                ? 'desc'
                : 'asc'
              : 'asc'
          };
        } else {
          newSortingHeader[key] = {
            ...prevSortingHeader[key],
            showIcon: false
          };
        }
      });

      return newSortingHeader;
    });
  };

  const sortingDetails = () => {
    const sortingArray = Object.values(sortingHeader);
    const label = sortingArray.find((val) => val.showIcon);
    return label;
  };

  const FilterOptions = moduleName === 'Archive' ? ArchiveFilterOptions : TrashFilterOptions;

  return (
    <React.Fragment>
      <div
        className={
          (moduleName !== 'My Documents' || isGridView) &&
          'cs-trash-dropdown cs-common-add-dropdown'
        }
        ref={mobileMenuRef}>
        {moduleName !== 'My Documents' && (
          <Dropdown>
            <Dropdown.Toggle id="dropdown-basic">
              <span>{filter.title}</span>
              <span className="cs-neutral-100">
                <CapsyncIcon title="chevron-down-outlined" size="12" />
              </span>
            </Dropdown.Toggle>

            <Dropdown.Menu>
              {FilterOptions.map((item, index) => (
                <Dropdown.Item
                  key={index}
                  onClick={() => setFilter({ title: item.title, days: item.days })}>
                  {item.title}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        )}
        {isGridView && checkSorting(moduleName, breadcrumbDetails, parentId) && (
          <div className="sorting-dropdown">
            <span onClick={() => onShortChange(sortingDetails().key)} className="sorting-icons">
              <span className="cs-neutral-80">
                <CapsyncIcon
                  title={
                    sortingDetails().sortOrder === 'asc'
                      ? 'arrow-up-outlined'
                      : 'arrow-down-outlined'
                  }
                  size="18"
                />
              </span>
            </span>
            <Dropdown>
              <Dropdown.Toggle id="dropdown-basic">
                <span>{sortingDetails().title}</span>
                <span className="cs-neutral-100">
                  <CapsyncIcon title="chevron-down-outlined" size="12" />
                </span>
              </Dropdown.Toggle>

              <Dropdown.Menu>
                {Object.entries(sortingHeader).map((item, index) => {
                  return (
                    <Dropdown.Item
                      key={index}
                      onClick={() => onShortChange(item[1].key)}
                      className={item[1].showIcon ? 'cs-selected-dropdown' : 'drop-item-icon'}>
                      {item[1].title}
                    </Dropdown.Item>
                  );
                })}
              </Dropdown.Menu>
            </Dropdown>
          </div>
        )}
      </div>
      {moduleName !== 'My Documents' && (
        <span className="cs-label cs-regular-h4">{filter.title}</span>
      )}
    </React.Fragment>
  );
};

// PROPS TYPE
SortingAndFilteringHeader.propTypes = {
  isGridView: PropTypes.bool,
  moduleName: PropTypes.string,
  breadcrumbDetails: PropTypes.array,
  parentId: PropTypes.any,
  sortingHeader: PropTypes.object,
  setSortingHeader: PropTypes.any,
  filter: PropTypes.object,
  setFilter: PropTypes.any
};

export default SortingAndFilteringHeader;
