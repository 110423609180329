import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Form, Button } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { useFormik } from 'formik';
import PropTypes from 'prop-types';

// COMPONENT IMPORTS
import Modal from '../../components/Modal';
import { MFA_TYPES } from '../../constants/system';
import ConfirmEditProfile from './ConfirmEditProfile';
import { TOAST_MESSAGE } from '../../constants/message';
import AddressSearch from '../../components/AddressSearch';
import InputBox from '../../components/FormFields/InputBox';
import MfaOptionModal from '../../components/MfaOptionModal';
import validationObj from '../../validations/validationSchema';
import PhoneInputField from '../../components/FormFields/PhoneInputField';
import MFAVerificationModal from '../../components/MFASetup/MFAVerificationModal';

// API
import {
  generateMFAOTP,
  getLoginUserDetails,
  getUserDetails,
  updateUserDetails,
  useAccessUser
} from '../../slices/authSlice';

/* ============================== EDIT PROFILE PAGE ============================== */
const EditProfile = ({ setEditProfileOpen, editProfileOpen, isMySettings = false }) => {
  const dispatch = useDispatch();

  const accessUser = useAccessUser();
  const { user } = useSelector((state) => state.auth);
  const address = JSON.parse(user?.user_personal_details?.address ?? '{}');

  const [mfaType, setMfaType] = useState('');
  const [mfaVerificationModal, setMfaVerificationModal] = useState(false);
  const [mfaOptionModal, setMfaOptionModal] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [initialValues, setInitialValues] = useState({
    first_name: '',
    firm_name: '',
    last_name: '',
    email: '',
    crd_number: '',
    street_name: '',
    phone_no: '',
    city: '',
    state: '',
    zip_code: '',
    country: 'USA'
  });
  const [addressObj, setAddressObj] = useState({
    line1: '',
    line2: '',
    city: '',
    state: '',
    country: '',
    postal_code: ''
  });

  useEffect(() => {
    if (isMySettings) {
      const addressDetails =
        accessUser?.loginUserDetails &&
        JSON.parse(accessUser?.loginUserDetails?.user_personal_details?.address);
      setInitialValues({
        first_name: accessUser?.loginUserDetails?.first_name,
        firm_name: accessUser?.loginUserDetails?.firm?.firm_name,
        last_name: accessUser?.loginUserDetails?.last_name,
        email: accessUser?.loginUserDetails?.email,
        phone_no: accessUser?.loginUserDetails?.phone_no,
        crd_number: accessUser?.loginUserDetails?.firm?.crd_number,
        street_name: `${addressDetails?.line1}, ${addressDetails?.state}, ${addressDetails?.city}, ${addressDetails?.country}, ${addressDetails?.postal_code}`,
        city: addressDetails?.city,
        state: addressDetails?.state,
        country: addressDetails?.country,
        zip_code: addressDetails?.postal_code
      });
      setAddressObj(addressDetails);
    } else {
      setInitialValues({
        first_name: user?.first_name,
        firm_name: user?.firm?.firm_name,
        last_name: user?.last_name,
        email: user?.email,
        phone_no: user?.phone_no,
        crd_number: user?.firm?.crd_number,
        street_name: address?.line1,
        city: address?.city,
        state: address?.state,
        country: address?.country,
        zip_code: address?.postal_code
      });
      setAddressObj(address);
    }
  }, [user, accessUser, isMySettings]);

  const updateUser = async (values) => {
    const payload = {
      user_id: isMySettings ? accessUser?.loginUserDetails?.id : user?.id,
      first_name: values?.first_name,
      last_name: values?.last_name,
      phone_no: values?.phone_no,
      address: addressObj
    };
    try {
      const response = await dispatch(updateUserDetails(payload)).unwrap();
      if (response?.status) {
        if (isMySettings) {
          await dispatch(getLoginUserDetails(accessUser?.loginUserDetails?.id)).unwrap();
        } else {
          await dispatch(getUserDetails(user?.id)).unwrap();
        }
        setEditProfileOpen(false);
        toast.success(TOAST_MESSAGE.EDIT_PROFILE_SUCCESS);
      }
    } catch (error) {
      console.log('error :>> ', error);
    }
  };

  const handleCancel = () => {
    setEditProfileOpen(false);
    resetForm();
  };

  const {
    values,
    errors,
    touched,
    status,
    handleChange,
    handleSubmit,
    setFieldTouched,
    setFieldValue,
    setStatus,
    resetForm,
    submitCount,
    isSubmitting
  } = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: validationObj.EditProfileValidationSchema,
    onSubmit: async (values) => {
      const currentPhoneNo = isMySettings ? accessUser?.loginUserDetails?.phone_no : user?.phone_no;
      if (currentPhoneNo !== values?.phone_no) {
        setEditProfileOpen(false);
        setShowConfirmModal(true);
      } else {
        updateUser(values);
      }
    }
  });

  const handleMfaModalClose = () => {
    setMfaOptionModal(false);
  };

  const handleMFAOption = () => {
    if (mfaType === MFA_TYPES.EMAIL) {
      dispatch(
        generateMFAOTP({
          mfaType: MFA_TYPES.EMAIL,
          payload: { user_id: isMySettings ? accessUser?.loginUserDetails?.id : user?.id }
        })
      );
    } else if (mfaType == MFA_TYPES.PHONE) {
      dispatch(
        generateMFAOTP({
          mfaType: MFA_TYPES.PHONE,
          payload: { user_id: isMySettings ? accessUser?.loginUserDetails?.id : user?.id }
        })
      );
    }
    handleMfaModalClose();
    setMfaVerificationModal(true);
  };

  const handleMFAVerifyClose = () => {
    setMfaVerificationModal(false);
  };

  const handleSuccess = () => {
    resetForm();
    updateUser(values);
  };

  useEffect(() => {
    if (editProfileOpen) resetForm();
  }, [editProfileOpen]);

  const body = (
    <Form onSubmit={handleSubmit} className="personal-inform-form">
      <Row>
        <Col lg={6}>
          <InputBox
            label="First name"
            name="first_name"
            placeholder="Enter first name"
            values={values}
            errors={errors}
            touched={touched}
            disabled={false}
            onChange={handleChange}
          />
        </Col>
        <Col lg={6}>
          <InputBox
            label="Last name"
            name="last_name"
            placeholder="Enter Last name"
            values={values}
            errors={errors}
            touched={touched}
            disabled={false}
            onChange={handleChange}
          />
        </Col>
      </Row>
      <Row>
        <Col lg={6}>
          <InputBox
            label="Email"
            name="email"
            placeholder="Enter email address"
            values={values}
            errors={errors}
            touched={touched}
            disabled={true}
          />
        </Col>
        <Col lg={6}>
          <PhoneInputField
            label={'Phone Number'}
            name={'phone_no'}
            values={values}
            setFieldValue={setFieldValue}
            errors={errors}
            submitCount={submitCount}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <AddressSearch
            errors={errors}
            touched={touched}
            values={values}
            handleChange={handleChange}
            setFieldValue={setFieldValue}
            setAddressObj={setAddressObj}
            setStatus={setStatus}
            status={status}
            addressObj={addressObj}
            setFieldTouched={setFieldTouched}
          />
        </Col>
      </Row>
      <div className="cs-modal-btn">
        <Button
          className="cs-btn-secondary md-btn cs-regular-h5"
          type="button"
          onClick={handleCancel}>
          Cancel
        </Button>

        <Button
          type="submit"
          className="cs-btn-primary md-btn cs-regular-h5"
          disabled={
            !Object.keys(initialValues).some(
              (field) => (values[field] || '') !== (initialValues[field] || '')
            ) || isSubmitting
          }>
          Update
        </Button>
      </div>
    </Form>
  );

  return (
    <React.Fragment>
      <Modal title={'Edit Profile'} show={editProfileOpen} body={body} isCloseButton={false} />
      <MfaOptionModal
        title={'PFS password'}
        modalState={mfaOptionModal}
        handleOnCancel={handleMfaModalClose}
        handleOnSave={handleMFAOption}
        subHeader={'Choose your preferred authentication method'}
        callback={setMfaType}
        selectedOption={mfaType}
        isMySettings={isMySettings}
      />
      <MFAVerificationModal
        modalState={mfaVerificationModal}
        handleModalClose={handleMFAVerifyClose}
        mfaType={mfaType}
        handleSuccess={handleSuccess}
        successToastMessage={''}
        isMySettings={isMySettings}
      />
      <ConfirmEditProfile
        setMfaOptionModal={setMfaOptionModal}
        showConfirmModal={showConfirmModal}
        setShowConfirmModal={setShowConfirmModal}
      />
    </React.Fragment>
  );
};

// PROPS TYPE
EditProfile.propTypes = {
  setEditProfileOpen: PropTypes.func,
  editProfileOpen: PropTypes.bool,
  isMySettings: PropTypes.bool
};

export default EditProfile;
