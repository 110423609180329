import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

// COMPONENT IMPORTS
import CapsyncIcon from '../../../components/CapsyncIcon';

// API
import { toggleSidebar } from '../../../slices/sidebarSlice';

/* ============================== SIDEBAR ITEMS ============================== */
const SideBarItem = ({ item }) => {
  const { key, title, path, iconOutlined, iconFilled, hasNotificationCount, keyName } = item;

  const dispatch = useDispatch();

  const { pendingSignatureRequest } = useSelector((state) => state.request);
  const { activeNav } = useSelector((state) => state.sidebar);

  const pendingToSignatureRequest =
    pendingSignatureRequest && pendingSignatureRequest.signatureToList;
  const pendingFromSignatureRequest =
    pendingSignatureRequest && pendingSignatureRequest.signatureFromList;
  const pendingAccountAccessRequest =
    pendingSignatureRequest && pendingSignatureRequest.accountAccessList;
  const totalRequest =
    pendingToSignatureRequest &&
    pendingFromSignatureRequest &&
    pendingAccountAccessRequest &&
    pendingToSignatureRequest.length +
      pendingFromSignatureRequest.length +
      pendingAccountAccessRequest.length;

  return (
    <li className="cs-nav-item nav-item" key={key}>
      <Link
        to={path}
        onClick={() => window.innerWidth < 992 && dispatch(toggleSidebar())}
        className={`cs-side-nav cs-regular-sub-heading-m cs-neutral-80 ${activeNav.includes(keyName) ? 'active' : ''}`}>
        <span className={`${iconOutlined} cs-icon`}>
          <CapsyncIcon
            title={`${activeNav.includes(keyName) ? iconFilled : iconOutlined}`}
            size="22"
          />
        </span>
        <span className="cs-nav-text">{title} </span>
        {hasNotificationCount && totalRequest > 0 && (
          <span className="cs-notification-count">
            <span className="cs-nav-text">{totalRequest}</span>
          </span>
        )}
      </Link>
    </li>
  );
};

// PROPS TYPE
SideBarItem.propTypes = {
  item: PropTypes.shape({
    key: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    path: PropTypes.string.isRequired,
    iconOutlined: PropTypes.string.isRequired,
    iconFilled: PropTypes.string.isRequired,
    hasNotificationCount: PropTypes.bool,
    keyName: PropTypes.string.isRequired
  }).isRequired
};

export default SideBarItem;
