import React from 'react';
import PropTypes from 'prop-types';
import { Image } from 'react-bootstrap';

// COMPONENT IMPORTS
import Modal from '../../components/Modal';
import { useBrandDetails } from '../../slices/brandingSlice';
import CommonSpinner from '../../components/CommonSpinner';

/* ============================== LOADER MODAL ============================== */
const LoaderModal = (props) => {
  const brandDetails = useBrandDetails();

  const { loading, message = '' } = props;

  const body = (
    <div className="delete-modal-content">
      {brandDetails?.is_use_load_image ? (
        <Image
          src={brandDetails.small_logo_s3_url}
          className="spin-animation"
          alt="Capsync Loader"
          width="120"
          height="120"
        />
      ) : (
        <CommonSpinner size={45} classParent="cs-primary" />
      )}
      <p className="cs-regular-body-text-m cs-neutral-90">
        This may take a few moments. Please wait...
      </p>
    </div>
  );

  return (
    <Modal
      title={message}
      show={loading}
      isCloseButton={false}
      className="cs-md-modal"
      body={body}
    />
  );
};

// API
LoaderModal.propTypes = {
  loading: PropTypes.bool,
  message: PropTypes.string
};

export default LoaderModal;
