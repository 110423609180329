import React, { useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useOutletContext } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import Slider from 'react-slick';

// COMPONENT IMPORTS
import Donut from '../components/Donut';
import LineChart from '../components/LineChart';
import DataTable from '../components/DataTable';
import commonNames from '../../utils/commonNames';
import { assetsRouts } from '../../utils/routesName';
import { monthWiseQuarter } from '../../utils/common';
import CapsyncToolTip from '../../components/CapsyncToolTip';
import PieChartSkeleton from '../components/Skeleton/PieChartSkeleton';
import CapsyncSectionHeader from '../../components/CapsyncSectionHeader';
import LineChartSkeleton from '../components/Skeleton/LineChartSkeleton';
import SkeletonCategoryTable from '../components/Skeleton/SkeletonCategoryTable';
import SkeletonCapsyncHeader from '../components/Skeleton/SkeletonCapsyncHeader';
import { getAssetsDashboard, useAssetsDashboard } from '../../slices/dashboardSlice';

// API
import { useUserID } from '../../slices/authSlice';
import { getMasterCategory } from '../../slices/categorySlice';

/* ============================== ASSETS ============================== */
const Assets = () => {
  const dispatch = useDispatch();
  const allAssets = useAssetsDashboard();

  const chatData = allAssets?.chart_data;
  let q1Total = 0;
  let q2Total = 0;
  let q3Total = 0;
  let q4Total = 0;

  if (chatData) {
    Object.values(chatData.assetsQ1).forEach((item) => (q1Total += item));
    Object.values(chatData.assetsQ2).forEach((item) => (q2Total += item));
    Object.values(chatData.assetsQ3).forEach((item) => (q3Total += item));
    Object.values(chatData.assetsQ4).forEach((item) => (q4Total += item));
  }

  const chartTotalData = {
    assetsQ1: q1Total,
    assetsQ2: q2Total,
    assetsQ3: q3Total,
    assetsQ4: q4Total
  };

  const userId = useUserID();
  const assetsData = assetsRouts.map((item) => ({
    ...item,
    value: allAssets?.assetsData?.[item.key],
    date_updated: allAssets?.plaid_date_updated?.[item.key]
  }));

  const tableHeader = [
    { name: 'Assets Name', width: '840' },
    { name: 'Total Value', width: '380' },
    { name: 'Date Updated', width: '260' },
    { name: '', width: '104' }
  ];
  const donutData = assetsData?.filter((items, index) => items?.value != null && items?.value != 0);
  const asstesDonutHeader = donutData?.map((val) => {
    return val?.category_name;
  });

  const assetsFillColors = assetsRouts
    ?.filter((val) => allAssets?.assetsData?.[val.key] > 0)
    .map((val) => val.fill);

  const labelColors = assetsRouts
    ?.filter((val) => allAssets?.assetsData?.[val.key] > 0)
    .map((val) => val.labelColor);

  const asstesDonutValue = donutData?.map((val) => {
    return val?.value;
  });
  const totalAssets = Object.values(allAssets?.assetsData || {}).reduce(
    (sum, value) => sum + value,
    0
  );
  const getMasterCategoriesFun = async () => {
    await dispatch(getMasterCategory()).unwrap();
    const payload = {
      userId,
      orderId: 0
    };
    await dispatch(getAssetsDashboard(payload)).unwrap();
  };

  useEffect(() => {
    getMasterCategoriesFun();
  }, []);

  const legendFooter = monthWiseQuarter();

  const series = [
    {
      name: 'Assets',
      data: [
        {
          x: legendFooter[0],
          y: chartTotalData?.assetsQ1
        },
        {
          x: legendFooter[1],
          y: chartTotalData?.assetsQ2
        },
        {
          x: legendFooter[2],
          y: chartTotalData?.assetsQ3
        },
        {
          x: legendFooter[3],
          y: chartTotalData?.assetsQ4
        }
      ]
    }
  ];

  const chart_carousel = {
    dots: true,
    infinite: false,
    speed: 500,
    draggable: true,
    slidesToShow: 1,
    slidesToScroll: 1
  };
  const [isLoader] = useOutletContext();
  return (
    <section className="main-section">
      <Container fluid>
        {isLoader ? (
          <React.Fragment>
            <SkeletonCapsyncHeader />
            <div className="for-des line-chart-skeleton">
              <div className="chart-container">
                <Row>
                  <Col lg={4}>
                    <div className="pie-chart-container skeleton-pie-graph">
                      <PieChartSkeleton />
                    </div>
                  </Col>
                  <Col lg={8}>
                    <div className="line-chart-container skeleton-line-graph">
                      <LineChartSkeleton />
                    </div>
                  </Col>
                </Row>
              </div>
            </div>

            <div className="for-mob line-chart-skeleton">
              <div className="chart-container">
                <Row>
                  <Col lg={6}>
                    <div className="pie-chart-container skeleton-pie-graph">
                      <PieChartSkeleton />
                    </div>
                  </Col>
                </Row>
              </div>
            </div>

            <div className="for-des">
              <SkeletonCategoryTable tableData={assetsData} />
            </div>
            <div className="for-mob">
              <SkeletonCategoryTable tableData={assetsData} />
            </div>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <div className="cs-section-topbar">
              <CapsyncSectionHeader pageTitle={commonNames.Assets} />
            </div>
            <div className="for-des">
              <div className="chart-container">
                <Row>
                  <Col lg={4}>
                    <div className="donut-chart-box">
                      <div className="chart-heading">
                        <h2 className="cs-semi-bold-h2">Assets Allocation</h2>
                      </div>
                      {asstesDonutValue && asstesDonutValue.length === 0 ? (
                        <span className="cs-common-no-record cs-light-body-text-l cs-neutral-100">
                          There are no records to display
                        </span>
                      ) : (
                        <Donut
                          header={asstesDonutHeader}
                          value={asstesDonutValue}
                          label="Assets"
                          total={totalAssets}
                          fillColors={assetsFillColors}
                          labelColors={labelColors}
                        />
                      )}
                    </div>
                  </Col>
                  <Col lg={8}>
                    <div className="line-chart-container">
                      <div className="chart-heading">
                        <h2 className="cs-semi-bold-h2">
                          Summary
                          <span>&nbsp;</span>
                          <CapsyncToolTip
                            Child={'info-outlined'}
                            placement={window.innerWidth >= 991 ? 'right' : 'top'}
                            message={
                              'Only accounts with transaction data are included in the chart.'
                            }
                            type="icon"
                            size="20"
                          />
                        </h2>
                        <span className="cs-regular-body-text-m cs-neutral-70">
                          Last 12 months data as of last sync
                        </span>
                      </div>
                      <div className="graph-line-chart-main-container">
                        <LineChart
                          label="Assets"
                          linechartData={chartTotalData}
                          seriesArray={series}
                        />
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>

            <div className="for-mob cs-common-carousel">
              <Slider {...chart_carousel}>
                <Col lg={6}>
                  <div className="donut-chart-box">
                    <div className="chart-heading">
                      <h2 className="cs-semi-bold-h2">Assets Allocation</h2>
                    </div>
                    {asstesDonutValue && asstesDonutValue.length === 0 ? (
                      <span className="cs-common-no-record cs-light-body-text-l cs-neutral-100">
                        There are no records to display
                      </span>
                    ) : (
                      <Donut
                        header={asstesDonutHeader}
                        value={asstesDonutValue}
                        label="Assets"
                        total={totalAssets}
                        fillColors={assetsFillColors}
                        labelColors={labelColors}
                      />
                    )}
                  </div>
                </Col>
                <Col lg={6}>
                  <div className="line-chart-container">
                    <div className="chart-heading">
                      <h2 className="cs-semi-bold-h2">
                        Summary
                        <span>&nbsp;</span>
                        <CapsyncToolTip
                          Child={'info-outlined'}
                          placement={window.innerWidth >= 991 ? 'right' : 'top'}
                          message={'Only accounts with transaction data are included in the chart.'}
                          type="icon"
                          size="20"
                        />
                      </h2>
                      <span className="cs-regular-body-text-m cs-neutral-70">
                        Last 12 months data as of last sync
                      </span>
                    </div>
                    <div className="graph-line-chart-main-container">
                      <LineChart
                        label="Assets"
                        linechartData={chartTotalData}
                        seriesArray={series}
                      />
                    </div>
                  </div>
                </Col>
              </Slider>
            </div>

            <div>
              <Row>
                <Col lg={12}>
                  <DataTable
                    label="Assets"
                    total={totalAssets}
                    col={tableHeader}
                    row={assetsData}
                    footerLabel="Total Assets"
                  />
                </Col>
              </Row>
            </div>
          </React.Fragment>
        )}
      </Container>
    </section>
  );
};

export default Assets;
