import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Button, Image } from 'react-bootstrap';

// COMPONENT IMPORTS
import Modal from '../../../components/Modal';
import { handlePlaidAccountUpdate } from './PlaidCommonFunctions';
import CommonSpinner from '../../../components/CommonSpinner';

// API
import { useUserID } from '../../../slices/authSlice';
import { useBrandDetails } from '../../../slices/brandingSlice';
import { handlePlaidToken, useInstituteData, useLoader } from '../../../slices/plaidSlice';

/* ============================== PLAID UPDATE CONFIRMATION ============================== */
const PlaidUpdateConfirmationModal = ({
  setAddAccountStep,
  setIsAccountModelOpen,
  isOpen,
  btnClick,
  updateModeRef
}) => {
  const dispatch = useDispatch();

  const instituteData = useInstituteData();
  const brandDetails = useBrandDetails();
  const userID = useUserID();
  const loader = useLoader();

  return (
    <Modal
      show={true}
      title="Are you sure?"
      isCloseButton={false}
      handleClose={() => {
        setAddAccountStep('');
        setIsAccountModelOpen(false);
      }}
      isModalVisible={isOpen}
      className="cs-s-modal"
      body={
        <div className={`text-center plaid-callApi-prog-bar`}>
          <div className="plaid-head">
            <div className="log-out-popup">
              <div className="cs-bank-images">
                <div className="cs-common-logo">
                  <Image
                    src={
                      (brandDetails && brandDetails.small_logo_s3_url) ||
                      require('../../../assets/images/brand/cap-icon-logo.svg').default
                    }
                    alt="loadergif"
                    className="plaid-img-bank plaid-logo-circle"
                  />
                </div>
                <Image
                  src={require('../../../assets/images/other/update_sync.svg').default}
                  alt="loadergif"
                  className="plaid-arrow-width"
                />
                <div className="cs-common-logo">
                  {instituteData?.ins_id && (
                    <Image
                      src={require(
                        `../../../assets/images/institution_logo/${instituteData.ins_logo}`
                      )}
                      alt="loadergif"
                      className="plaid-arrow-width"
                    />
                  )}
                </div>
              </div>
              <p className="cs-light-body-text-m cs-neutral-90">
                You are attempting to link a new, or remove an existing account from your{' '}
                {instituteData?.ins_name} account.
              </p>
            </div>
            <div className="cs-modal-btn cs-center-btn">
              <Button
                className="cs-btn-secondary md-btn cs-regular-h5"
                onClick={() => {
                  dispatch(handlePlaidToken(''));
                  setAddAccountStep('');
                  setIsAccountModelOpen(false);
                }}
                disabled={loader.updateContinueLoader}>
                Cancel
              </Button>
              <Button
                onClick={() =>
                  handlePlaidAccountUpdate(
                    dispatch,
                    instituteData.ins_id,
                    userID,
                    btnClick,
                    updateModeRef
                  )
                }
                className="cs-btn-primary md-btn cs-regular-h5"
                disabled={loader.updateContinueLoader}>
                <span>Continue</span>
                {loader && loader['updateContinueLoader'] && (
                  <CommonSpinner
                    classParent={`${loader && loader['updateContinueLoader'] ? ' cs-neutral-10 m-auto' : ' d-none'}`}
                    size="16"
                  />
                )}
              </Button>
            </div>
          </div>
        </div>
      }
    />
  );
};

// PROPS TYPE
PlaidUpdateConfirmationModal.propTypes = {
  setAddAccountStep: PropTypes.func,
  isOpen: PropTypes.bool,
  setIsAccountModelOpen: PropTypes.func,
  btnClick: PropTypes.func,
  updateModeRef: PropTypes.func
};

export default PlaidUpdateConfirmationModal;
