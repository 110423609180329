let initialValuesObj = {};

/* ============================== SUB METHODS INITIAL VALUES ============================== */
export const initialValues = (methodID) => {
  switch (methodID) {
    case 1:
      initialValuesObj = {
        legal_name: '',
        terms_1: false,
        upload_drop_box_1: [],
        upload_drop_box_2: [],
        terms_2: false,
        income_amount: '',
        notes: ''
      };
      break;

    case 2:
      initialValuesObj = {
        legal_name: '',
        upload_drop_box_1: [],
        officers_investment_or_deal_name: '',
        notes: ''
      };
      break;

    case 3:
      initialValuesObj = {
        legal_name: '',
        upload_drop_box_1: [],
        certify: false,
        notes: ''
      };
      break;

    case 4:
      initialValuesObj = {
        legal_name: '',
        upload_drop_box_1: [],
        notes: ''
      };
      break;

    case 5:
      initialValuesObj = {
        legal_name: '',
        upload_drop_box_1: [],
        upload_drop_box_2: [],
        notes: ''
      };
      break;

    case 6:
      initialValuesObj = {
        legal_name: '',
        upload_drop_box_1: [],
        notes: ''
      };
      break;

    case 7:
      initialValuesObj = {
        legal_name: '',
        upload_drop_box_1: [],
        upload_drop_box_2: [],
        notes: ''
      };
      break;

    case 8:
      initialValuesObj = {
        legal_name: '',
        upload_drop_box_1: [],
        upload_drop_box_2: [],
        notes: ''
      };
      break;

    case 9:
      initialValuesObj = {
        legal_name: '',
        upload_drop_box_1: [],
        upload_drop_box_2: [],
        upload_drop_box_3: [],
        notes: ''
      };
      break;

    case 10:
      initialValuesObj = {
        legal_name: '',
        upload_drop_box_1: [],
        upload_drop_box_2: [],
        notes: ''
      };
      break;

    case 13:
      initialValuesObj = {
        legal_name: '',
        upload_drop_box_1: [],
        notes: ''
      };
      break;

    case 14:
      initialValuesObj = {
        legal_name: '',
        upload_drop_box_1: [],
        notes: ''
      };
      break;

    case 15:
      initialValuesObj = {
        legal_name: '',
        upload_drop_box_1: [],
        notes: ''
      };
      break;

    case 16:
      initialValuesObj = {
        legal_name: '',
        upload_drop_box_1: [],
        notes: ''
      };
      break;

    case 17:
      initialValuesObj = {
        legal_name: '',
        upload_drop_box_3: [],
        terms_1: false,
        upload_drop_box_1: [],
        upload_drop_box_2: [],
        terms_2: false,
        income_amount: '',
        notes: ''
      };
      break;

    case 18:
      initialValuesObj = {
        legal_name: '',
        upload_drop_box_2: [],
        upload_drop_box_1: [],
        officers_investment_or_deal_name: '',
        notes: ''
      };
      break;

    case 19:
      initialValuesObj = {
        legal_name: '',
        upload_drop_box_2: [],
        upload_drop_box_1: [],
        certify: false,
        notes: ''
      };
      break;

    case 21:
      initialValuesObj = {
        legal_name: '',
        upload_drop_box_1: [],
        notes: ''
      };
      break;

    case 23:
      initialValuesObj = {
        legal_name: '',
        upload_drop_box_1: [],
        upload_drop_box_2: [],
        notes: ''
      };
      break;

    case 24:
      initialValuesObj = {
        legal_name: '',
        upload_drop_box_1: [],
        upload_drop_box_2: [],
        upload_drop_box_3: [],
        notes: ''
      };
      break;

    case 27:
      initialValuesObj = {
        legal_name: '',
        investor_type: 'Select option',
        investor_meet_requirement: false,
        upload_drop_box_1: [],
        upload_drop_box_2: [],
        notes: ''
      };
      break;

    case 28:
      initialValuesObj = {
        legal_name: '',
        investor_type: '',
        upload_drop_box_1: [],
        certify: false,
        company_checkbox: false,
        liability: false,
        notes: ''
      };
      break;

    case 29:
      initialValuesObj = {
        legal_name: '',
        investor_type: '',
        terms: false,
        upload_drop_box_1: [],
        upload_drop_box_2: [],
        notes: ''
      };
      break;

    case 30:
      initialValuesObj = {
        legal_name: '',
        upload_drop_box_1: [],
        upload_drop_box_2: [],
        notes: ''
      };
      break;

    case 31:
      initialValuesObj = {
        legal_name: '',
        upload_drop_box_1: [],
        upload_drop_box_2: [],
        notes: ''
      };
      break;

    case 32:
      initialValuesObj = {
        legal_name: '',
        upload_drop_box_1: [],
        upload_drop_box_2: [],
        notes: ''
      };
      break;

    case 33:
      initialValuesObj = {
        legal_name: '',
        certify: false,
        upload_drop_box_1: [],
        upload_drop_box_2: [],
        notes: ''
      };
      break;

    case 34:
      initialValuesObj = {
        legal_name: '',
        certify: false,
        upload_drop_box_1: [],
        upload_drop_box_2: [],
        notes: ''
      };
      break;

    case 35:
      initialValuesObj = {
        legal_name: '',
        investor_type: '',
        certify: false,
        upload_drop_box_1: [],
        upload_drop_box_2: [],
        upload_drop_box_3: [],
        notes: ''
      };
      break;

    case 36:
      initialValuesObj = {
        legal_name: '',
        upload_drop_box_1: [],
        upload_drop_box_2: [],
        upload_drop_box_3: [],
        notes: ''
      };
      break;

    case 37:
      initialValuesObj = {
        legal_name: '',
        name: '',
        crd_number: '',
        firm_name: '',
        upload_drop_box_1: [],
        notes: ''
      };
      break;

    case 38:
      initialValuesObj = {
        legal_name: '',
        upload_drop_box_1: [],
        aknowledgeable_employees_investment_or_deal_name: '',
        notes: ''
      };
      break;

    case 39:
      initialValuesObj = {
        legal_name: '',
        upload_drop_box_1: [],
        upload_drop_box_2: [],
        notes: ''
      };
      break;

    case 40:
      initialValuesObj = {
        legal_name: '',
        upload_drop_box_1: [],
        notes: ''
      };
      break;

    case 41:
      initialValuesObj = {
        legal_name: '',
        upload_drop_box_2: [],
        upload_drop_box_1: [],
        aknowledgeable_employees_investment_or_deal_name: '',
        notes: ''
      };
      break;

    case 42:
      initialValuesObj = {
        legal_name: '',
        upload_drop_box_2: [],
        name: '',
        crd_number: '',
        firm_name: '',
        upload_drop_box_1: [],
        notes: ''
      };
      break;

    case 'fileUpload':
      initialValuesObj = {
        pfs_link: '',
        data_obj: [],
        attachments: []
      };
      break;

    case 'additionalDocument':
      initialValuesObj = {
        data_obj: [],
        attachments: [],
        notes: ''
      };
      break;

    default:
      break;
  }
  return { initialValuesObj };
};
