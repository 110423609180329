import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

// COMPONENT IMPORTS
import Modal from '../../components/Modal';
import { fileDownload, fileSizeCalc } from '../../utils/common';
import CapsyncIcon from '../../components/CapsyncIcon';
import { scannedFileElem } from './FileManagementCommonElem';

// API
import { categoryFilesDownload } from '../../slices/categorySlice';

/* ============================== DOWNLOAD CONFIRMATION MODAL ============================== */
const DownloadConfirmationModal = (props) => {
  const { scannedFileDetails = { ...scannedFileElem }, onCancelClick } = props;

  const dispatch = useDispatch();

  const categoryFileDownload = async () => {
    try {
      const fileData = {
        media_id: [scannedFileDetails?.details?.[0].id]
      };
      const response = await dispatch(categoryFilesDownload(fileData)).unwrap();
      fileDownload({
        name: scannedFileDetails?.details?.[0]?.name,
        attachment: response.fileUrl
      });
    } catch (error) {
      console.log('error');
    }
  };

  const onSubmit = () => {
    if (scannedFileDetails) {
      categoryFileDownload();
      onCancelClick();
    } else {
      fileDownload(scannedFileDetails?.details?.[0]);
      onCancelClick();
    }
  };

  const body = (
    <div className="delete-modal-content cs-text-center">
      <span className="icon cs-icon">
        <CapsyncIcon title="error-validation" size="100" />
      </span>
      {scannedFileDetails?.isBigFile ? (
        <p className="cs-light-body-text-m cs-neutral-90">
          <strong>"{scannedFileDetails?.details?.[0].name}"</strong> (
          {fileSizeCalc(scannedFileDetails?.details?.[0].size)}) exceeds the maximum file size that
          we can scan. This file might harm your computer, so only download this file if you
          understand the risks.
        </p>
      ) : (
        <React.Fragment>
          <p className="cs-light-body-text-m cs-neutral-90">
            Are you sure you want to download this item?
          </p>
          <p className="cs-light-body-text-m cs-neutral-90">
            <strong>"{scannedFileDetails?.details?.[0].name}"</strong> is infected with a virus.
          </p>
        </React.Fragment>
      )}
    </div>
  );

  return (
    <Modal
      title={scannedFileDetails.isBigFile ? "Can't scan file for viruses" : 'Are you sure?'}
      saveButtonLabel={scannedFileDetails.isBigFile ? 'Download anyway' : 'Download'}
      cancelButtonLabel="Cancel"
      show={scannedFileDetails.modalOpen}
      className="cs-md-modal payment-success"
      body={body}
      isCloseButton={false}
      handleOnSave={() => onSubmit()}
      handleOnCancel={onCancelClick}
      modalFooterClass="cs-modal-btn modal-footer"
    />
  );
};

// PROPS TYPE
DownloadConfirmationModal.propTypes = {
  onCancelClick: PropTypes.func,
  scannedFileDetails: PropTypes.any
};

export default DownloadConfirmationModal;
