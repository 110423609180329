import React from 'react';
import { Col } from 'react-bootstrap';

// COMPONENT IMPORTS
import Image from '../components/Image';
import defaultAuthImg1 from '../assets/images/default-auth-image-1.svg';

// API
import { useBrandDetails } from '../slices/brandingSlice';

/* ============================== AUTHENTICATION BANNER PAGE ============================== */
const AuthBanner = () => {
  const brandDetails = useBrandDetails();

  const getWelcomeBannerImage = () => {
    if (brandDetails && Object.keys(brandDetails).length > 0) {
      if (brandDetails.welcome_img_s3_url) {
        return brandDetails.welcome_img_s3_url;
      } else {
        const templateType = brandDetails.welcome_template_type;
        return (
          templateType != '2' && require(`../assets/images/default-auth-image-${templateType}.svg`)
        );
      }
    } else {
      return defaultAuthImg1;
    }
  };

  return (
    <Col lg={6} className="auth-banner">
      <div className="cs-left-section">
        {Object.keys(brandDetails).length > 0 && brandDetails.is_welcome_message_enabled && (
          <div className="cs-content-box cs-regular-sub-heading-m cs-neutral-100">
            <h1 className="cs-semi-bold-h1 cs-primary text-capitalize">
              {brandDetails.welcome_message || 'Your New Immersive Digital Finance Hub.'}
            </h1>
            <p>
              {brandDetails.welcome_message_description ||
                'Sync your real-time digital Personal Financial Statement with CapSync.'}
            </p>
          </div>
        )}
        <div className="auth-image">
          <Image src={getWelcomeBannerImage()} alt="welcome-image" />
        </div>
      </div>
    </Col>
  );
};

export default AuthBanner;
