import { combineReducers, configureStore } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import {
  FLUSH,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
  REHYDRATE,
  persistReducer,
  persistStore
} from 'redux-persist';

// SLICES IMPORTS
import authSlice from '../slices/authSlice';
import sidebarSlice from '../slices/sidebarSlice';
import brandingSlice from '../slices/brandingSlice';
import systemSlice from '../slices/systemSlice';
import dashboardSlice from '../slices/dashboardSlice';
import categorySlice from '../slices/categorySlice';
import auditLogsSlice from '../slices/auditLogsSlice';
import accreditationSlice from '../slices/accreditationSlice';
import notificationSlice from '../slices/notificationSlice';
import requestSlice from '../slices/requestSlice';
import collaborationSlice from '../slices/collaborationSlice';
import plaidSlice from '../slices/plaidSlice';
import fileManagementSlice from '../slices/fileManagementSlice';
import arraySlice from '../slices/arraySlice';
import commonSlice from '../slices/commonSlice';
import downloadSlice from '../slices/downloadSlice';
const persistConfig = {
  key: 'root',
  storage,
  blacklist: [
    'sidebar',
    'system',
    'auditLogs',
    'notification',
    'collaboration',
    'array',
    'common',
    'download',
    'dashboard'
  ]
};

const rootReducer = combineReducers({
  auth: authSlice,
  sidebar: sidebarSlice,
  system: systemSlice,
  branding: brandingSlice,
  dashboard: dashboardSlice,
  category: categorySlice,
  auditLogs: auditLogsSlice,
  accreditation: accreditationSlice,
  notification: notificationSlice,
  request: requestSlice,
  collaboration: collaborationSlice,
  plaid: plaidSlice,
  fileManagement: fileManagementSlice,
  array: arraySlice,
  common: commonSlice,
  download: downloadSlice
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  devTools: true,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
      }
    })
});

export const persistor = persistStore(store);
