import React, { useEffect, useState } from 'react';
import { ProgressBar, Modal, Image } from 'react-bootstrap';

// COMPONENT IMPORTS
import CapFavicon from '../../../assets/images/brand/cap-icon-logo.svg';
import CommonSpinner from '../../CommonSpinner';

// API
import { useBrandDetails } from '../../../slices/brandingSlice';

/* ============================== PAYMENT PROGRESSBAR ============================== */
const PaymentProgressBar = () => {
  const [prog, setProg] = useState(0);

  const brandDetails = useBrandDetails();

  useEffect(() => {
    const timer = setInterval(() => {
      setProg((prev) => prev + 1);
    }, 800);

    return () => clearInterval(timer);
  }, []);

  return (
    <Modal show={true} onHide={false} centered className="cs-common-modal-overlay">
      <Modal.Body className="cs-sign-modal">
        <div className="text-center api-prog-bar sign-loader-area">
          <div className="text-center">
            {brandDetails?.is_use_load_image ? (
              <Image
                src={(brandDetails && brandDetails.small_logo_s3_url) || CapFavicon}
                className="w-25 spin-animation"
              />
            ) : (
              <CommonSpinner size={45} classParent="cs-primary" />
            )}
          </div>
          <div>
            <strong className="sign-prog-bar-heading cs-regular-sub-heading-m cs-neutral-100">
              Please Wait
            </strong>
          </div>
          <div className="cs-progress-bar">
            <ProgressBar now={(1 - 1 / prog) * 100} className="sign-load-bar" />
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default PaymentProgressBar;
