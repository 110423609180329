import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';

/* ============================== CHECKBOX ============================== */
const CheckBox = ({
  name,
  label = '',
  additionalClass = '',
  values,
  handleChange,
  className = '',
  disabled = false,
  errors,
  touched,
  fieldPrimaryLabel
}) => {
  return (
    <Form.Group className={`cs-form-group cs-regular-body-text-l ${additionalClass}`}>
      <Form.Check
        type="checkbox"
        id={`${name}-checkbox`}
        feedbackType="invalid"
        checked={values[name]}
        onChange={handleChange}
        className={`cs-form-check-box ${className}`}
        aria-label={name}
        name={name}
        label={
          fieldPrimaryLabel
            ? fieldPrimaryLabel
            : label && <span className="cs-light-body-text-m cursor-pointer d-flex">{label}</span>
        }
        disabled={disabled}
      />
      {errors && touched && errors[name] && touched[name] && (
        <span className="form-error-msg cs-light-body-text-s cs-danger cs-relative">
          {errors[name]}
        </span>
      )}
    </Form.Group>
  );
};

// PROPS TYPES
CheckBox.propTypes = {
  name: PropTypes.string,
  values: PropTypes.object,
  className: PropTypes.string,
  errors: PropTypes.object,
  touched: PropTypes.object,
  handleChange: PropTypes.func,
  label: PropTypes.string,
  additionalClass: PropTypes.string,
  disabled: PropTypes.bool,
  fieldPrimaryLabel: PropTypes.element
};

export default CheckBox;
