import React from 'react';
import PropTypes from 'prop-types';
import { Image } from 'react-bootstrap';

// COMPONENT IMPORTS
import Modal from '../../components/Modal';
import ErrorValidation from '../../assets/images/other/error-validation.svg';

// API
import { useBrandDetails } from '../../slices/brandingSlice';

/* ============================== UPGRADE STORAGE MODAL ============================== */
const UpgradeStorageModal = (props) => {
  const { showModal, onCancelClick } = props;

  const brandDetails = useBrandDetails();

  const body = (
    <div className="success-head">
      <span>
        <Image src={ErrorValidation} alt="Error Validation" width={100} height={100} />
      </span>
      <p className="cs-neutral-80 cs-regular-h5">
        {`You have exceeded the storage limit for your free plan. Delete files to continue using your
        ${brandDetails.firm_name} cloud.`}
      </p>
    </div>
  );

  return (
    <Modal
      title="Storage Limit"
      saveButtonLabel="Continue"
      show={showModal}
      isCloseButton={false}
      className="cs-md-modal payment-success"
      body={body}
      handleOnSave={onCancelClick}
      modalFooterClass="cs-modal-btn modal-footer"
    />
  );
};

// PROPS TYPE
UpgradeStorageModal.propTypes = {
  showModal: PropTypes.bool,
  onCancelClick: PropTypes.func
};

export default UpgradeStorageModal;
