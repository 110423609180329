import React, { useEffect, useState } from 'react';
import PhoneInput from 'react-phone-number-input';
import { Col, Row, Form } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import * as yup from 'yup';

// COMPONENT IMPORTS
import Avatar from './Avatar';
import Modal from '../Modal';
import { socket } from '../../config/Socket';
import AddressSearch from '../AddressSearch';
import InputBox from '../FormFields/InputBox';
import { localObjectClear } from '../../utils/system';
import { clearPersisterStorage } from '../../utils/common';
import LogoutConfirmationModal from '../LogoutConfirmationModal';
import UpdateAvatar from '../../pages/components/UserProfile/UpdateAvatar';
import { cityField, streetNameField, zipCodeField } from '../../validations';

// API
import {
  authUserLogout,
  getUserDetails,
  updateUserDetails,
  useAccessUser,
  useUser
} from '../../slices/authSlice';

/* ============================== COMPLETE PROFILE PAGE ============================== */
const CompleteProfile = () => {
  const dispatch = useDispatch();
  const navigation = useNavigate();

  const user = useUser();
  const accessUser = useAccessUser();

  // const { savedAvatar } = useSelector((state) => state.auth);
  const [isAvatarModalOpen, setIsAvatarModalOpen] = useState(false);
  const [addressObj, setAddressObj] = useState({
    line1: '',
    line2: '',
    city: '',
    state: '',
    country: '',
    postal_code: ''
  });
  const [isAddressChange, setIsAddressChange] = useState(false);
  const [selectedAvatar, setSelectedAvatar] = useState(null);
  const [showLogoutModal, setShowLogoutModal] = useState(false);

  const selectedAvatarFromDb =
    (user && user.user_personal_details && user.user_personal_details.profile_image_url) || null;

  const initialAddress = {
    street_name: '',
    city: '',
    state: '',
    zip_code: ''
  };

  const manualAddressValidationSchema = yup.object({
    street_name: streetNameField(),
    city: cityField(),
    zip_code: zipCodeField()
  });

  const trimedSrc =
    user &&
    user?.user_personal_details &&
    user?.user_personal_details?.profile_image_url &&
    user?.user_personal_details?.profile_image_url.replace(
      /(https:\/\/wl-universal-(local|dev|stage)\.s3\.amazonaws\.com|https:\/\/s3\.amazonaws\.com\/universal-clientportalx\.com)/,
      ''
    );

  const {
    values,
    errors,
    touched,
    handleChange,
    handleSubmit,
    setFieldValue,
    setStatus,
    setValues,
    status,
    setFieldTouched,
    submitCount
  } = useFormik({
    initialValues: initialAddress,
    validationSchema: manualAddressValidationSchema,
    onSubmit: async (values) => {
      if (
        !!isAddressChange &&
        !!status &&
        (!!status.street_name || !!status.city || !!status.state || !!status.zip_code)
      ) {
        return true;
      }
      const userDetails = {};
      userDetails.address = addressObj;
      userDetails.user_id = user.id;
      try {
        const response = await dispatch(updateUserDetails(userDetails)).unwrap();
        if (response?.status) {
          await dispatch(getUserDetails(`${user?.id}`)).unwrap();
        }
      } catch (e) {
        console.log('error: >> ', e);
      }
    }
  });

  const handlePhoneChange = (event) => {
    setFieldValue('phone_no', event);
  };
  const handleAvatarSelected = (title) => {
    setSelectedAvatar(title);
  };

  useEffect(() => {
    setValues({
      email: user?.email,
      first_name: user?.first_name,
      last_name: user?.last_name
    });
  }, []);

  const handlePageOnAdminAccess = () => {
    const broadcastChannel = new BroadcastChannel('logOut_from_same_tabs');
    broadcastChannel.postMessage('tab_logout');
  };
  const handleLogoutUser = () => {
    setShowLogoutModal(false);
    if (accessUser) {
      socket.emit('exitAccess', {
        user_id: accessUser?.accessUserID,
        access_user_id: user.id
      });
    }
    dispatch(authUserLogout());
    clearPersisterStorage(dispatch);
    handlePageOnAdminAccess();
    navigation('/');
    localObjectClear();
  };

  const bodyElement = () => {
    return (
      <Form onSubmit={handleSubmit} className="cs-complete-profile">
        <h4 className="cs-medium-sub-heading-m cs-neutral-100 text-center">
          Add your address to complete your profile.
        </h4>
        {/* <Avatar isEditable={true} className="cs-avatar-lg" setIsAvatarModalOpen={setIsAvatarModalOpen} src={savedAvatar} /> */}

        <Avatar
          isEditable={true}
          className={`cs-avatar-lg ${trimedSrc === '/users/avatar/avatar_6.png' || user?.user_personal_details?.profile_image_url === null || user?.user_personal_details === null ? 'cs-avatar-text' : 'cs-avatar-img'}`}
          setIsAvatarModalOpen={setIsAvatarModalOpen}
          src={`${user?.user_personal_details?.profile_image_url}`}
        />
        <Row>
          <Col xs={6} md={6} sm={6}>
            <InputBox
              label="First name"
              name="first_name"
              placeholder="Enter first name"
              values={values}
              errors={errors}
              touched={touched}
              onChange={handleChange}
              disabled
            />
          </Col>
          <Col xs={6} md={6} sm={6}>
            <InputBox
              label="Last name"
              name="last_name"
              placeholder="Enter last name"
              values={values}
              errors={errors}
              touched={touched}
              onChange={handleChange}
              disabled
            />
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={6}>
            <InputBox
              label="Email"
              name="email"
              type="email"
              placeholder="Enter email"
              values={values}
              errors={errors}
              touched={touched}
              onChange={handleChange}
              disabled
            />
          </Col>
          <Col xs={12} md={6}>
            <Form.Group className="cs-form-group cs-disabled">
              <Form.Label>Phone number</Form.Label>
              <PhoneInput
                limitMaxLength={15}
                international
                countryCallingCodeEditable={false}
                placeholder="Phone Number"
                name="phone_no"
                withCountryCallingCode={false}
                value={user && user.phone_no}
                className="form-control"
                onChange={handlePhoneChange}
                defaultCountry="US"
                disabled={true}
                countries={['US']}
                onPaste={(e) => {
                  e.preventDefault();
                  return false;
                }}
                onCopy={(e) => {
                  e.preventDefault();
                  return false;
                }}
              />
              {errors.phone_no && touched.phone_no ? (
                <span className="form-error-msg cs-light-body-text-s cs-danger">
                  {errors.phone_no}
                </span>
              ) : null}
            </Form.Group>
          </Col>
        </Row>
        <AddressSearch
          errors={errors}
          touched={touched}
          values={values}
          handleChange={handleChange}
          setFieldValue={setFieldValue}
          setAddressObj={setAddressObj}
          setStatus={setStatus}
          status={status}
          addressObj={addressObj}
          setIsAddressChange={setIsAddressChange}
          isCountryField={false}
          setFieldTouched={setFieldTouched}
          submitCount={submitCount}
        />
        {/* <Button variant="primary" className="cs-btn-primary md-btn cs-regular-h5" type="submit">
            Continue
          </Button> */}
      </Form>
    );
  };

  return (
    <React.Fragment>
      <Modal
        show={true}
        isCloseButton={true}
        className="cs-lg-modal intial-complete-profile"
        title="Complete Profile"
        body={bodyElement()}
        saveButtonLabel="Continue"
        handleOnSave={handleSubmit}
        handleClose={() => setShowLogoutModal(true)}
      />
      {isAvatarModalOpen && (
        <UpdateAvatar
          isAvatarModalOpen={isAvatarModalOpen}
          setIsAvatarModalOpen={setIsAvatarModalOpen}
          handleAvatarSelected={handleAvatarSelected}
          selectedAvatar={selectedAvatar}
          setSelectedAvatar={setSelectedAvatar}
          selectedAvatarFromDb={selectedAvatarFromDb}
          isCompleteModal={true}
        />
      )}
      <LogoutConfirmationModal
        handleLogoutUser={handleLogoutUser}
        handleStayOnCapsync={() => setShowLogoutModal(false)}
        showLogoutModal={showLogoutModal}
      />
    </React.Fragment>
  );
};

export default CompleteProfile;
