import React from 'react';
import ReactApexChart from 'react-apexcharts';
import PropTypes from 'prop-types';

/* ============================== DONUT CHART ============================== */
const Donut = ({
  header,
  value,
  label,
  total,
  height = 410,
  width = 410,
  fillColors,
  labelColors = ['#FFFFFF', '#FFFFFF'],
  position = 'bottom'
}) => {
  const series = value ? value : [22410693, 44895093];
  // Compute labels with values for Amortization
  const formattedLabels =
    label === 'Amortization'
      ? header.map((label, index) => {
          const labelValue = series[index];
          return `${label} $ ${labelValue.toLocaleString()}`;
        })
      : header;
  const options = {
    chart: {
      type: 'donut',
      height: height,
      width: width,
      redrawOnParentResize: true,
      responsive: true
    },
    states: {
      hover: {
        filter: {
          type: 'none'
        }
      },
      active: {
        filter: {
          type: 'none'
        }
      }
    },
    dataLabels: {
      style: {
        fontSize: '14px',
        fontFamily: 'Readex Pro',
        fontWeight: 'bold',
        colors: labelColors
      }
    },
    labels:
      formattedLabels &&
      (formattedLabels ? formattedLabels : ['Total Assets', 'Total Liabilities']),
    colors: fillColors,
    responsive: [
      {
        // Max-width: 1790px
        breakpoint: 1900,
        options: {
          chart: {
            width: label === 'Amortization' ? 400 : '100%',
            height: label === 'Amortization' ? 400 : 400
          },

          legend: {
            position: position
          },
          plotOptions: {
            pie: {
              donut: {
                labels: {
                  value: {
                    offsetY: 0
                  },
                  name: {
                    offsetY: 0,
                    formatter: (w) => {
                      const name = w.length > 20 ? w.slice(0, 18) + '...' : w;
                      return name;
                    }
                  }
                }
              }
            }
          }
        }
      },
      // Max-width: 1700px
      {
        breakpoint: 1700,
        options: {
          chart: {
            width: label === 'Amortization' ? 400 : '100%',
            height: label === 'Amortization' ? 400 : 400
          },

          legend: {
            position: position
          },
          plotOptions: {
            pie: {
              donut: {
                labels: {
                  value: {
                    offsetY: 0
                  },
                  name: {
                    offsetY: 0,
                    formatter: (w) => {
                      const name = w.length > 20 ? w.slice(0, 18) + '...' : w;
                      return name;
                    }
                  }
                }
              }
            }
          }
        }
      },
      // Max-width: 1500px
      {
        breakpoint: 1500,
        options: {
          chart: {
            width: label === 'Amortization' ? 300 : '100%',
            height: label === 'Amortization' ? 300 : 380
          },

          legend: {
            position: label === 'Amortization' ? 'bottom' : position
          },
          plotOptions: {
            pie: {
              donut: {
                labels: {
                  value: {
                    offsetY: 0
                  },
                  name: {
                    offsetY: 0,
                    formatter: (w) => {
                      const name = w.length > 20 ? w.slice(0, 18) + '...' : w;
                      return name;
                    }
                  }
                }
              }
            }
          }
        }
      },
      // Max-width: 1450px
      {
        breakpoint: 1450,
        options: {
          chart: {
            width: label === 'Amortization' ? 300 : '100%',
            height: label === 'Amortization' ? 300 : 450
          },

          legend: {
            position: label === 'Amortization' ? 'bottom' : position
          },
          plotOptions: {
            pie: {
              donut: {
                labels: {
                  value: {
                    offsetY: 0
                  },
                  name: {
                    offsetY: 0,
                    formatter: (w) => {
                      const name = w.length > 20 ? w.slice(0, 18) + '...' : w;
                      return name;
                    }
                  }
                }
              }
            }
          }
        }
      },
      // Max-width: 1200px
      {
        breakpoint: 1200,
        options: {
          chart: {
            width: label === 'Amortization' ? 350 : '100%',
            height: label === 'Amortization' ? 300 : 400
          },
          legend: {
            position: label === 'Amortization' ? 'bottom' : position
          },
          plotOptions: {
            pie: {
              donut: {
                labels: {
                  value: {
                    // offsetY: -5,
                  }
                }
              }
            }
          }
        }
      },
      // Max-width: 991px
      {
        breakpoint: 991,
        options: {
          chart: {
            width: label === 'Amortization' ? 300 : '100%',
            height: label === 'Amortization' ? 300 : 400
          },
          legend: {
            position: label === 'Amortization' ? 'bottom' : position
          },
          plotOptions: {
            pie: {
              donut: {
                labels: {
                  value: {
                    // offsetY: -5,
                  }
                }
              }
            }
          }
        }
      },

      // Max-width: 576px
      {
        breakpoint: 575,
        options: {
          chart: {
            width: label === 'Amortization' ? 300 : '100%',
            height: label === 'Amortization' ? 300 : 400
          },
          legend: {
            position: label === 'Amortization' ? 'bottom' : position
            // formatter: (w) => {
            //   const name = w.length > 20 ? w.slice(0, 10) + "..." : w
            //   return name;
            // },
          },
          plotOptions: {
            pie: {
              donut: {
                labels: {
                  value: {
                    // offsetY: -10,
                  }
                }
              }
            }
          }
        }
      }
    ],
    plotOptions: {
      pie: {
        donut: {
          size: '50%',
          labels: {
            show: true,
            name: {
              show: true,
              showAlways: true,
              fontSize: '18px',
              fontWeight: 400,
              color: '#0A0A0A',
              formatter: (w) => {
                const name = w.length > 20 ? w.slice(0, 18) + '...' : w;
                return name;
              },
              offsetY: 0
              // offsetY: nameOffsetY,
            },
            value: {
              show: true,
              showAlways: true,
              fontWeight: 600,
              fontSize: '20px',
              formatter: (w) => {
                return '$ ' + Math.round(parseFloat(w)).toLocaleString(0);
              },
              offsetY: 0
              // offsetY: valueOffsetY,
            },
            total: {
              show: true,
              showAlways: true,
              label:
                label === 'Amortization'
                  ? 'Total Paid'
                  : label === 'Portfolio'
                    ? 'Total Portfolio'
                    : label === 'Assets'
                      ? 'Total Assets'
                      : label === 'Liabilities'
                        ? 'Total Liabilities'
                        : 'Net Worth',
              formatter: (w) => {
                const [assets, liabilities] = w.globals.seriesTotals;
                const relevantLabels = ['Liabilities', 'Assets', 'Amortization', 'Portfolio'];

                const formatNumber = (number) => {
                  return Number.isInteger(number)
                    ? number.toLocaleString()
                    : number.toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                      });
                };

                if (total && relevantLabels.includes(label)) {
                  return `$ ${formatNumber(total)}`;
                }

                return `$ ${formatNumber(assets - liabilities)}`;
              }
            }
          }
        }
      }
    },

    legend: {
      position: position,
      verticalAlign: 'center',
      onItemClick: {
        toggleDataSeries: label === 'Amortization' ? false : true
      },
      onItemHover: {
        highlightDataSeries: label === 'Amortization' ? false : true
      },
      formatter: function (seriesName, opts) {
        // const name = seriesName.length > 30 ? seriesName.slice(0, 18) + '...' : seriesName;
        // const sum = opts.w.config.series.reduce((a, b) => a + b, 0);
        // const percent = (opts.w.config.series[opts.seriesIndex] / sum) * 100;
        // return `<span
        //     placement='top'
        //     class='chart-tooltip'
        //     data=''
        //     data-title='${seriesName} (${percent.toFixed(1)}%)'
        //   >${name}</span>`;
        if (label === 'Amortization') {
          const parts = seriesName.split(' $ ');
          return `<span>${parts[0]}</span> <span class="amort-pie-value">$ ${parts[1]}</span>`;
        } else {
          const name = seriesName.length > 30 ? seriesName.slice(0, 18) + '...' : seriesName;
          const sum = opts.w.config.series.reduce((a, b) => a + b, 0);
          const percent = (opts.w.config.series[opts.seriesIndex] / sum) * 100;
          return `<span
        placement='top'
        class='chart-tooltip'
        data=''
        data-title='${seriesName} (${percent.toFixed(1)}%)'
      >${name}</span>`;
        }
      },
      markers: {
        size: 4,
        shape: 'circle'
      }
    },

    tooltip: {
      enabled: true,
      custom: ({ seriesIndex, w, series, dataPointIndex }) => {
        let total = 0;
        for (const x of series) {
          total += x;
        }
        const selected = series[seriesIndex];
        if (label === 'Amortization') {
          return `<span style="
                    background-color:${w.config.colors[seriesIndex]}; 
                    padding:6px;
                    color: ${labelColors[seriesIndex]};
                    box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.12);
                    font-family: Readex Pro;
                    font-size: 10px;
                    font-weight: 300;
                    line-height: 16px;
                    ">
                    ${w.config.labels[seriesIndex]} (${((selected / total) * 100).toFixed(1)}%)
                  </span>`;
        }

        return `<span style="
                    background-color:${w.config.colors[seriesIndex]}; 
                    padding:6px;
                    color: ${labelColors[seriesIndex]};
                    box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.12);
                    font-family: Readex Pro;
                    font-size: 10px;
                    font-weight: 300;
                    line-height: 16px;
                    ">
                    ${w.config.labels[seriesIndex]}: $ ${
                      Number.isInteger(series[seriesIndex])
                        ? series[seriesIndex].toLocaleString()
                        : series[seriesIndex].toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2
                          })
                    } (${((selected / total) * 100).toFixed(1)}%)
                  </span>`;
      }
    }
  };

  return (
    <div className="common-donut-chart">
      <ReactApexChart
        options={options}
        series={series}
        type="donut"
        height={height}
        position={position}
      />
    </div>
  );
};

// PROPS TYPE
Donut.propTypes = {
  header: PropTypes.any,
  value: PropTypes.any,
  label: PropTypes.any,
  total: PropTypes.any,
  width: PropTypes.string,
  height: PropTypes.string,
  position: PropTypes.string,
  fillColors: PropTypes.arrayOf(PropTypes.string),
  labelColors: PropTypes.arrayOf(PropTypes.string)
};

export default Donut;
