import AWS from 'aws-sdk';

AWS.config.update({
  accessKeyId: process.env.REACT_APP_S3_ACCESS_KEY,
  secretAccessKey: process.env.REACT_APP_S3_SECRET_ACCESS_KEY,
  region: process.env.REACT_APP_S3_REGION,
  httpOptions: {
    timeout: 900 * 1000,
    connectTimeout: 900 * 1000
  }
});

export default AWS;
