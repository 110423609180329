import React from 'react';
import PropTypes from 'prop-types';
import { Image } from 'react-bootstrap';

// COMPONENT IMPORTS
import Modal from '../../components/Modal';
import ErrorValidation from '../../assets/images/other/error-validation.svg';

/* ============================== MOVE ALERT MODAL ============================== */
const MoveAlertModal = (props) => {
  const { showModal, onCancelClick, existingMoveFiles } = props;

  const body = (
    <div className="success-head">
      <span>
        <Image src={ErrorValidation} alt="Error Validation" width={100} height={100} />
      </span>
      <p className="cs-light-body-text-m cs-neutral-90">
        {existingMoveFiles.length > 1 ? 'Some of the items' : existingMoveFiles[0].name} already
        exist in this location.
      </p>
      <p className="cs-light-body-text-m cs-neutral-90">
        please change the {existingMoveFiles.length > 1 ? 'Files ' : 'File '}name before moving into
        this folder.
      </p>
    </div>
  );

  return (
    <Modal
      title={`${existingMoveFiles.length > 1 ? 'Files ' : 'File '}name exist`}
      saveButtonLabel="Continue"
      show={showModal}
      isCloseButton={false}
      className="cs-md-modal payment-success"
      body={body}
      handleOnSave={onCancelClick}
      modalFooterClass="cs-modal-btn modal-footer"
    />
  );
};

// PROPS TYPE
MoveAlertModal.propTypes = {
  existingMoveFiles: PropTypes.array,
  showModal: PropTypes.bool,
  onCancelClick: PropTypes.func
};

export default MoveAlertModal;
