import React from 'react';
import PropTypes from 'prop-types';

// COMPONENT IMPORTS
import CapsyncIcon from '../../../components/CapsyncIcon';

/* ============================== TAB CARD COMPONENT ============================== */
const TabCardComponent = (props) => {
  const { className, img, description, title, onClick } = props;
  return (
    <div className={className} onClick={onClick}>
      <div className="card-icon">
        <span>
          <CapsyncIcon title={img} size="32" />
        </span>
      </div>
      <div className="card-item">
        <span className="cs-regular-h4 cs-neutral-90">{description}</span>
        <h3 className="cs-semi-bold-h2 cs-neutral-100">{title}</h3>
      </div>
    </div>
  );
};

// PROPS TYPE
TabCardComponent.propTypes = {
  className: PropTypes.string,
  img: PropTypes.string,
  description: PropTypes.string,
  title: PropTypes.string,
  onClick: PropTypes.func
};

export default TabCardComponent;
