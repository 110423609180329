import React, { useState, useEffect } from 'react';
import { Button, Image, Modal } from 'react-bootstrap';
import PropTypes from 'prop-types';

// COMPONENT IMPORTS
import ErrorValidation from '../../../assets/images/other/error-validation.svg';
import CapFavicon from '../../../assets/images/brand/cap-icon-logo.svg';

// API
import { useBrandDetails } from '../../../slices/brandingSlice';

/* ============================== PAYMENT FAILED ============================== */
const PaymentFailed = (props) => {
  const { showPaymentFailed, failureReason, setShowPaymentFailed } = props;

  const [showLoader, setShowLoader] = useState(false);

  const brandDetails = useBrandDetails();

  useEffect(() => {
    setShowLoader(false);
  });

  const cancel = () => {
    setShowLoader(true);
    setShowPaymentFailed(false);
  };

  return (
    <Modal
      show={showPaymentFailed}
      onHide={() => !showPaymentFailed}
      centered
      size="md"
      backdrop="static"
      aria-labelledby="contained-modal-title-vcenter"
      dialogClassName="cs-md-modal payment-success">
      {showLoader ? (
        <div>
          <Image
            width="40"
            src={(brandDetails && brandDetails.small_logo_s3_url) || CapFavicon}
            className="capsync-logo cs-icon-logo"
            alt="brand-logo-small"
            classNameMain="cs-icon-logo"
          />
        </div>
      ) : (
        <React.Fragment>
          <Modal.Header>
            <Modal.Title className="cs-semi-bold-h3">Payment Failed!</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="success-head">
              <span>
                <Image src={ErrorValidation} alt="Error Validation" width={100} height={100} />
              </span>
              <p className="cs-light-body-text-m cs-neutral-90">{failureReason}</p>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button className="cs-btn-secondary lg-btn cs-regular-h5" onClick={() => cancel()}>
              Cancel
            </Button>
          </Modal.Footer>
        </React.Fragment>
      )}
    </Modal>
  );
};

// PROPS TYPE
PaymentFailed.propTypes = {
  setShowPaymentFailed: PropTypes.func,
  failureReason: PropTypes.string,
  showPaymentFailed: PropTypes.bool
};

export default PaymentFailed;
