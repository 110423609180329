import React from 'react';
import Skeleton from 'react-loading-skeleton';

/* ============================== SKELETON NAVBAR ============================== */
const SkeletonNavbar = () => {
  return (
    <div className="cs-header cs-skeleton-header">
      <div className="cs-nav-skeleton">
        <div className="skeleton-navbar">
          <span className="item-1">
            <Skeleton width={20} />
          </span>
          <span className="item-2">
            <Skeleton width={60} />
          </span>
        </div>
        <div className="skeletion-menu wm-skeleton-menu">
          <div className="menu-3">
            <div className="menu-item item-1">
              <Skeleton />
            </div>
            <div className="menu-item">
              <Skeleton />
            </div>
          </div>
          <div className="menu-4 for-des">
            <Skeleton circle width={40} height={40} />
          </div>
          <div className="menu-4 for-mob">
            <Skeleton circle width={30} height={30} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SkeletonNavbar;
