import React from 'react';
import PropTypes from 'prop-types';

// COMPONENT IMPORTS
import DeleteModal from '../../components/DeleteModal';

/* ============================== FILE MANAGEMENT DELETE MODAL ============================== */
const FileManagementDeleteModal = (props) => {
  const { showModal, onCancelClick, onConfirmDelete, deleteRecord = [], moduleName } = props;

  const deleteMsg = () => {
    const isMultiple = deleteRecord.length > 1;
    const isFile = deleteRecord.every((val) => val.attachment !== null);
    const isFolder = deleteRecord.every((val) => val.attachment === null);

    const action = moduleName === 'Trash' ? 'permanently delete' : 'delete';
    const itemType = isFile ? 'File' : isFolder ? 'Folder' : 'Item';

    const itemTypePlural = isFile ? 'Files' : isFolder ? 'Folders' : 'Items';

    const message = isMultiple
      ? `Are you sure you want to ${action} these ${itemTypePlural}? ${moduleName === 'Trash' ? 'This action cannot be undone and the items cannot be restored.' : 'You can restore them later from the trash.'} Please confirm if you wish to proceed.`
      : `Are you sure you want to ${action} this ${itemType}? ${moduleName === 'Trash' ? 'This action cannot be undone and the item cannot be restored.' : 'You can restore it later from the trash.'} Please confirm if you wish to proceed.`;

    return message;
  };

  return (
    <DeleteModal
      deleteMsg={{ title: deleteMsg() }}
      show={showModal}
      handleDeleteData={onConfirmDelete}
      handleCloseModel={onCancelClick}
    />
  );
};

// PROPS TYPE
FileManagementDeleteModal.propTypes = {
  showModal: PropTypes.bool,
  deleteRecord: PropTypes.array,
  moduleName: PropTypes.string,
  onCancelClick: PropTypes.func,
  onConfirmDelete: PropTypes.func
};

export default FileManagementDeleteModal;
