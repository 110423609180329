/* eslint-disable no-useless-escape */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Form } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { useFormik } from 'formik';
import * as yup from 'yup';

// COMPONENT IMPORTS
import Modal from '../../components/Modal';
import InputBox from '../../components/FormFields/InputBox';
import { folderNameField } from '../../validations';

// API
import { createFolder, fileFolderRename } from '../../slices/fileManagementSlice';
import { useUser } from '../../slices/authSlice';
import { useProgressUploadDetails } from '../../slices/commonSlice';

/* ============================== CREATE FOLDER ============================== */
const CreateFolder = (props) => {
  const {
    showModal,
    onCancelClick,
    parentId,
    renameItemDetails = {
      isRenameItem: false,
      itemDetails: null,
      listData: []
    }
  } = props;

  const { isRenameItem, itemDetails, listData } = renameItemDetails;

  const progressUploadDetails = useProgressUploadDetails();
  const dispatch = useDispatch();
  const user = useUser();

  const [loading, setLoading] = useState(false);

  const getExistingNames = (listData, progressUploadDetails) => {
    const listNames = listData
      .filter((item) => item.parent_id === parentId)
      .map((item) => item.name);

    const progressNames = progressUploadDetails
      .filter((item) => item.parentId === parentId)
      .flatMap((item) => item.files);
    return new Set([...listNames, ...progressNames]);
  };

  const removeExtension = (filename) => {
    return filename.replace(/\.[^/.]+$/, '');
  };

  const getExtension = (filename) => {
    const match = filename.match(/\.[^.]+$/);
    return match ? match[0].slice(1) : '';
  };

  const validationSchema = yup.object().shape({
    name: folderNameField().test('unique', 'A file with the same name already exists.', (value) => {
      if (!isRenameItem) {
        return true;
      }

      if (!itemDetails.attachment) {
        return true;
      }

      const existingNames = getExistingNames(listData, progressUploadDetails);

      const currentName = itemDetails.attachment
        ? removeExtension(itemDetails.name)
        : itemDetails.name;

      const nameWithExtension = itemDetails.attachment
        ? `${value}${itemDetails.name.match(/\.[^/.]+$/) || ''}`
        : value;

      if (value === currentName) {
        return true;
      }

      return !existingNames.has(nameWithExtension);
    })
  });

  const onSubmit = async (values) => {
    setLoading(true);
    if (isRenameItem) {
      try {
        const payload = {
          file_management_id: itemDetails?.id,
          is_category_rename: !!itemDetails?.category_id && !!itemDetails?.category_primary_id,
          name: itemDetails?.attachment
            ? `${values?.name}.${getExtension(itemDetails?.name)}`
            : values?.name
        };
        const response = await dispatch(fileFolderRename(payload)).unwrap();
        if (response?.status) {
          toast.success(
            itemDetails?.attachment
              ? 'File has been renamed successfully.'
              : 'Folder has been renamed successfully.'
          );
          handleOnCancel(true);
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log('error :>> ', error?.message);
      }
    } else {
      try {
        const userID = user?.id.toString();
        const parentID = parentId?.toString();
        const payload = {
          user_id: userID,
          parent_id: parentID,
          name: values?.name
        };
        const response = await dispatch(createFolder(payload)).unwrap();
        if (response?.status) {
          toast.success('Folder created successfully.');
          handleOnCancel(true);
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log('error :>> ', error?.message);
      }
    }
  };

  const initialName =
    isRenameItem && itemDetails?.attachment
      ? removeExtension(itemDetails?.name)
      : itemDetails?.name;

  const { values, errors, touched, handleSubmit, handleChange, resetForm } = useFormik({
    initialValues: {
      name: isRenameItem ? initialName : ''
    },
    enableReinitialize: true,
    validationSchema,
    onSubmit
  });

  const handleOnCancel = (isApiCall = false) => {
    onCancelClick(isApiCall);
    resetForm();
  };

  const body = (
    <div>
      <Form onSubmit={handleSubmit} id="create-folder">
        <InputBox
          name="name"
          label={
            isRenameItem && itemDetails?.attachment
              ? 'Enter name of the file'
              : 'Enter name of the folder'
          }
          values={values}
          errors={errors}
          touched={touched}
          onChange={handleChange}
          showError
        />
      </Form>
    </div>
  );

  return (
    <Modal
      title={isRenameItem ? 'Rename' : 'New folder'}
      form="create-folder"
      cancelButtonLabel="Cancel"
      saveButtonLabel={isRenameItem ? 'Rename' : 'Create'}
      show={showModal}
      isCloseButton={false}
      className="cs-md-modal file-common-modal"
      body={body}
      handleOnCancel={() => handleOnCancel(false)}
      modalFooterClass="cs-modal-btn modal-footer"
      disabled={!values.name || errors.name || loading}
      loading={loading}
    />
  );
};

// PROPS TYPE
CreateFolder.propTypes = {
  showModal: PropTypes.bool,
  onCancelClick: PropTypes.func,
  parentId: PropTypes.number,
  renameItemDetails: PropTypes.any
};

export default CreateFolder;
