import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  Button,
  Col,
  Form,
  Row,
  Container,
  Modal as BootstrapModal,
  Dropdown
} from 'react-bootstrap';

// COMPONENT IMPORTS
import Modal from '../../../components/Modal';
import FieldsHTML from '../common/FieldsHTML';
import CapsyncIcon from '../../../components/CapsyncIcon';
import DashboardTable from '../../dashboard/DashboardTable';
import ContingentLiability from '../../dashboard/ContingentLiability';
import { assetsRouts, liabilitiesRouts } from '../../../utils/routesName';
import SharePFSCategoryTable from '../../SharePFSModals/SharePFSCategoryTable';
import { handleAccreditationModals, handleOpenSignModal } from './SubMethodsBasicFunctions';

// API
import { getAllAssetData, getAllLiabilityData } from '../../../slices/categorySlice';
import { getCategoryMediaList, setLoader } from '../../../slices/accreditationSlice';
import { useAssetsDashboard, useLiabilitieDashboard } from '../../../slices/dashboardSlice';

/* ============================== SUB METHODS UPLOAD MODAL ============================== */
export const UploadFileModal = ({
  openModalState,
  openModalFunc,
  formik,
  fieldJSON,
  handleFileUploadPopup,
  loading,
  methodFormik,
  dropboxID
}) => {
  const { pfsLink } = useSelector((state) => state.accreditation);
  return (
    <Modal
      show={openModalState}
      onHide={() => openModalFunc(false)}
      parentClassName=""
      className={'cs-md-modal manage-files'}
      isCloseButton={false}
      title={'Select Files'}
      body={
        <Form onSubmit={formik.handleSubmit}>
          <Row>
            <FieldsHTML
              fieldJSON={fieldJSON}
              formik={formik}
              methodFormik={methodFormik}
              dropboxID={dropboxID}
            />
          </Row>
        </Form>
      }
      cancelButtonLabel={'Cancel'}
      handleOnCancel={() => handleFileUploadPopup(false)}
      saveButtonLabel={'Continue'}
      handleOnSave={formik.handleSubmit}
      disabled={
        !pfsLink || (pfsLink && (!pfsLink.link || pfsLink.link === '' || pfsLink.link === null))
      }
      loading={loading}
    />
  );
};

// PROPS TYPE
UploadFileModal.propTypes = {
  openModalState: PropTypes.bool,
  openModalFunc: PropTypes.func,
  formik: PropTypes.func,
  handleFileUploadPopup: PropTypes.func,
  fieldJSON: PropTypes.array,
  loading: PropTypes.bool,
  methodFormik: PropTypes.array,
  dropboxID: PropTypes.string
};

/* ============================== SUB METHODS PFS LINK MODAL ============================== */
export const PFSLinkModal = ({ show, visitorID }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.auth);
  const { rowID } = useSelector((state) => state.accreditation);
  const radioName = [
    { name: 'Summary', value: '1' },
    { name: 'Worksheet', value: '2' }
  ];
  const [radioValue, setRadioValue] = useState({
    num: '1',
    name: 'Summary'
  });
  const [allAssetData, setAllAssetData] = useState({});
  const [allLiabilitiesData, setAllLiabilitiesData] = useState({});
  const address =
    user && user.user_personal_details && JSON.parse(user?.user_personal_details?.address);
  const allAssets = useAssetsDashboard();
  const allLiabilitie = useLiabilitieDashboard();
  const assets = assetsRouts.map((item) => ({
    ...item,
    value: allAssets?.assetsData?.[item.key] ?? item.value
  }));

  const liabilitie = liabilitiesRouts.map((item) => ({
    ...item,
    value: allLiabilitie?.liabilityData?.[item.key] ?? item.value
  }));

  const totalAssets = Object.values(allAssets?.assetsData || {}).reduce(
    (sum, value) => sum + value,
    0
  );
  const totalLiabilities = Object.values(allLiabilitie?.liabilityData || {}).reduce(
    (sum, value) => sum + value,
    0
  );

  const netWorth = Number(totalAssets) - Number(totalLiabilities);

  const fetchAssetsData = async () => {
    const payload = { userId: user?.id };
    const response = await dispatch(getAllAssetData(payload)).unwrap();
    if (response.code === 200) {
      setAllAssetData(response?.data);
    }
  };
  const fetchLiabilitiesData = async () => {
    const payload = { userId: user?.id };
    const response = await dispatch(getAllLiabilityData(payload)).unwrap();
    if (response.code === 200) {
      setAllLiabilitiesData(response?.data);
    }
  };
  useEffect(() => {
    fetchAssetsData();
    fetchLiabilitiesData();
  }, [user, radioValue]);

  return (
    <BootstrapModal show={show} centered className="share-pfs-modal">
      <BootstrapModal.Header>
        <div className="pfs-preview-modal">
          <div className="buttons">
            <div className="for-des">
              <div className="cs-tabs cs-primary">
                {radioName.map((tabs, idx) => (
                  <Button
                    className={`cs-stroke-default-tab cs-regular-h5 cs-tabs-height ${
                      radioValue.num === tabs.value ? 'cs-stroke-active' : ''
                    }`}
                    key={idx}
                    id={`radio-${idx}`}
                    type="radio"
                    variant={idx % 2 ? 'manage-user-individual' : 'manage-user-wealth-manager'}
                    name="radioName"
                    value={tabs.value}
                    checked={radioValue.num === tabs.value}
                    onClick={() => setRadioValue({ num: tabs.value, name: tabs.name })}>
                    {tabs.name}
                  </Button>
                ))}
              </div>
            </div>
            <div className="for-mob">
              <div className="pfs-preview-dropdown cs-common-add-dropdown">
                <Dropdown>
                  <Dropdown.Toggle className="cs-semi-bold-h5">
                    {radioValue.name}
                    <CapsyncIcon title="chevron-down-outlined" size="16" />
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="cs-pfs-menu cs-scroller">
                    {radioName.map((tabs, idx) => {
                      return (
                        <Dropdown.Item
                          key={idx}
                          className={`cs-regular-h5 ${radioValue.num === tabs.value ? 'selected' : ''}`}
                          onClick={() => setRadioValue({ num: tabs.value, name: tabs.name })}>
                          {tabs.name}
                        </Dropdown.Item>
                      );
                    })}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
          </div>
        </div>
      </BootstrapModal.Header>
      <div className="pfs-modal-scroll dashboard-main-section cs-scroller">
        <BootstrapModal.Body className="pfs-preview-modal">
          {radioValue.num === '1' ? (
            <section className="pfs-container">
              <Container fluid>
                <div className="pfs-header">
                  <Row>
                    <Col lg={6}>
                      <div className="view-pfs-modal cs-regular-sub-heading-m">
                        <p>Name:&nbsp;</p> {user?.display_name}
                      </div>
                    </Col>
                    <Col lg={6}>
                      <div className="view-pfs-modal cs-regular-sub-heading-m">
                        <p>Email:</p> &nbsp;{user?.email}
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={6}>
                      <div className="view-pfs-modal cs-regular-sub-heading-m">
                        <p>Address:&nbsp;</p>{' '}
                        {`${address?.line1}, ${address?.state}, ${address?.city}, ${address?.country}, ${address?.postal_code}`}
                      </div>
                    </Col>
                    <Col lg={6}>
                      <div className="view-pfs-modal cs-regular-sub-heading-m">
                        <p>Phone:&nbsp;</p> {user?.phone_no}
                      </div>
                    </Col>
                  </Row>
                </div>

                <div className="asset-liability-container">
                  <Row>
                    <Col lg={6}>
                      <div className="pfs-common-box finance-box">
                        <DashboardTable
                          name="Assets"
                          data={assets}
                          total={totalAssets}
                          isSharePFS={true}
                        />

                        <div className="for-des">
                          <div className="total-networth">
                            <h3 className="net-worth-heading cs-semi-bold-h3 cs-neutral-100">
                              Net Worth
                            </h3>
                            <div className="dollar-value-gap net-worth-value cs-semi-bold-h3 cs-neutral-100">
                              <span>$&nbsp;</span>
                              <span>{netWorth.toLocaleString()}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                    <Col lg={6}>
                      <div className="pfs-common-box">
                        <DashboardTable
                          name="Liabilities"
                          data={liabilitie}
                          total={totalLiabilities}
                          isSharePFS={true}
                        />
                        <ContingentLiability isSharePFS={true} />
                      </div>
                    </Col>
                  </Row>
                </div>

                <div className="pfs-footer cs-regular-sub-heading-m cs-neutral-70">
                  <p>
                    I authorize Lender and/or assigns to make inquiries as necessary to verify the
                    accuracy of the statements made and to determine my creditworthiness. I certify
                    the above and the statements contained in the attachments are true and accurate
                    as of the stated date(s). These statements are made for the purpose of either
                    obtaining loan or guaranteeing a loan. I understand FALSE statements may result
                    in forfeiture of benefits and possible prosecution by the U.S. Attorney General
                    (Reference 18 U.S.C. 1001).
                  </p>
                  <div className="pfs-signature-container">
                    <Row>
                      <Col lg={6}>
                        <div className="pfs-signature-box pfs-name-block">
                          <span className="cs-regular-sub-heading-m cs-neutral-100">Name</span>
                          <span className="cs-light-body-text-l cs-neutral-100">
                            {user?.display_name}
                          </span>
                        </div>
                        <div className="pfs-signature-bottom">
                          <div className="pfs-signature-box pfs-signature-block">
                            <span className="cs-regular-sub-heading-m cs-neutral-100">
                              Signature
                            </span>
                            <span className="cs-signature-text cs-neutral-100"></span>
                          </div>

                          <div className="pfs-signature-box pfs-date-block">
                            <span className="cs-regular-sub-heading-m cs-neutral-100">Date</span>
                            <span className="cs-light-body-text-l cs-neutral-100">
                              {moment(new Date()).format('MM/DD/YYYY')}
                            </span>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>
              </Container>
            </section>
          ) : radioValue.num === '2' ? (
            <section className="pfs-container">
              <div className="assets-main-section">
                <Container fluid>
                  <Row>
                    <Col>
                      <h2 className="pfs-h2-title cs-semi-bold-h1 cs-neutral-100">
                        Assets
                        <Link
                          to={`/assets`}
                          className="custom-table-element"
                          onClick={() => {
                            handleAccreditationModals(dispatch, 'pfsLink', false);
                            setRadioValue({ num: '1', name: 'Summary' });
                          }}>
                          <span className="cs-asset icon">
                            <CapsyncIcon title="redirect-outlined" size="28" />
                          </span>
                        </Link>
                      </h2>
                      <div className="pfs-assets-table">
                        {assetsRouts.map((category, index) => (
                          <React.Fragment key={index}>
                            <div className="pfs-table-container">
                              <Row>
                                <Col lg={12}>
                                  <div className="pfs-common-title">
                                    <h4 className="cs-regular-h4 cs-neutral-100">
                                      {category.category_name}
                                    </h4>
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col lg={12}>
                                  <SharePFSCategoryTable
                                    data={allAssetData[category.key]}
                                    columns={category.tabArray}
                                    parent={category.parent}
                                    category={category}
                                  />
                                </Col>
                              </Row>
                            </div>
                          </React.Fragment>
                        ))}
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <h2 className="pfs-h2-title cs-semi-bold-h1 cs-neutral-100">
                        Liabilities
                        <Link
                          to={`/liabilities`}
                          className="custom-table-element"
                          onClick={() => {
                            handleAccreditationModals(dispatch, 'pfsLink', false);
                            setRadioValue({ num: '1', name: 'Summary' });
                          }}>
                          <span className="cs-liability icon">
                            <CapsyncIcon title="redirect-outlined" size="28" />
                          </span>
                        </Link>
                      </h2>
                      <div className="pfs-liabilities-table">
                        {liabilitiesRouts.map((category, index) => (
                          <React.Fragment key={index}>
                            <div className="pfs-table-container">
                              <Row>
                                <Col lg={12}>
                                  <div className="pfs-common-title">
                                    <h4 className="cs-regular-h4 cs-neutral-100">
                                      {category.category_name}
                                    </h4>
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col lg={12}>
                                  <SharePFSCategoryTable
                                    data={allLiabilitiesData[category.key]}
                                    columns={category.tabArray}
                                    parent={category.parent}
                                    category={category}
                                  />
                                </Col>
                              </Row>
                            </div>
                          </React.Fragment>
                        ))}
                      </div>
                    </Col>
                  </Row>
                </Container>
              </div>
            </section>
          ) : null}
        </BootstrapModal.Body>
      </div>
      <BootstrapModal.Footer>
        <div className="cs-modal-btn">
          <Button
            className="cs-btn-secondary cs-regular-h5 lg-btn"
            type="button"
            onClick={() => {
              handleAccreditationModals(dispatch, 'pfsLink', false);
              setRadioValue({ num: '1', name: 'Summary' });
            }}>
            Cancel
          </Button>
          <Button
            className="cs-btn-primary cs-regular-h5 lg-btn"
            type="button"
            onClick={() => handleOpenSignModal(dispatch, user, rowID, navigate)}>
            {visitorID > 0 ? 'Send for Signature' : 'Sign'}
          </Button>
        </div>
      </BootstrapModal.Footer>
    </BootstrapModal>
  );
};

// PROPS TYPE
PFSLinkModal.propTypes = {
  show: PropTypes.bool,
  visitorID: PropTypes.number
};

/* ============================== SUB METHODS ATTACHMENT MODAL ============================== */
export const AttachmentModal = ({ show, formik }) => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const { modal, attachments, loader } = useSelector((state) => state.accreditation);
  const [attachmentsList, setAttachmentsList] = useState({ asstes: [], liabilities: [] });
  const [tempAttachments, setTempAttachments] = useState([]);
  const [selectedIds, setSelectedIds] = useState([]);
  const assetsOrder = [
    'Cash on Hand & In Banks',
    'Savings Accounts',
    'IRA or Other Retirement Account',
    'Accounts & Notes Receivable',
    'Life Insurance',
    'Non-Retirement Brokerage Accounts (Stocks & Bonds)',
    'Real Estate',
    'Automobiles',
    'Businesses',
    'Other Personal Property',
    'Other Assets'
  ];
  const liblityOrder = [
    'Accounts Payable',
    'Notes Payable to Banks and Others',
    'Installment Account (Auto)',
    'Installment Account (Other)',
    'Loan(s) Against Life Insurance',
    'Mortgages on Real Estate',
    'Unpaid Taxes',
    'Other Liabilities'
  ];

  useEffect(() => {
    if (Object.keys('modal').length > 0 && modal.attachmentDocument) {
      getList();
      const arrayData = [...formik.values['data_obj']];
      if (selectedIds.length === 0) {
        setTempAttachments([]);
      } else {
        setTempAttachments(arrayData);
      }
    }
  }, [modal.attachmentDocument]);

  const getList = () => {
    dispatch(getCategoryMediaList(user.id))
      .unwrap()
      .then((response) => {
        if (response.code === 200) {
          setAttachmentsList({
            asstes: response.data.assets,
            liabilities: response.data.liabilities
          });
        } else {
          console.log('response', response);
        }
      })
      .catch((err) => {
        console.log('err', err);
      });
  };

  const handleCancel = () => {
    setSelectedIds([]);
    setAttachmentsList({ asstes: [], liabilities: [] });
    setTempAttachments([]);
    handleAccreditationModals(dispatch, 'attachmentDocument', false);
    if (selectedIds.length === 0) {
      setTempAttachments([]);
      formik.setValues({
        ...formik.values,
        data_obj: []
      });
    }
  };

  const handleRowClick = (id, mediadata) => {
    const isSelected = tempAttachments.some((item) => item.id === id);
    if (isSelected) {
      setTempAttachments((prev) => prev.filter((item) => item.id !== id));
    } else {
      setTempAttachments((prev) => [...prev, { ...mediadata }]);
    }
  };
  const handleCheckboxClick = (id, liabilities) => {
    if (submitLoader) return;
    setSelectedIds((prevSelectedIds) => {
      const isSelected = prevSelectedIds.includes(id);
      if (isSelected) {
        return prevSelectedIds.filter((itemId) => itemId !== id);
      } else {
        return [...prevSelectedIds, id];
      }
    });
    handleRowClick(liabilities.id, liabilities);
  };

  const handleSubmitButtonClick = () => {
    dispatch(setLoader({ attachmentSubmit: true }));
    setAttachmentsList({ asstes: [], liabilities: [] });
    setTempAttachments([]);
    formik.setFieldValue('data_obj', tempAttachments);
    handleAccreditationModals(dispatch, 'attachmentDocument', false);
    dispatch(setLoader({ attachmentSubmit: false }));
    setSelectedIds([]);
  };

  const submitLoader = loader.attachmentSubmit;

  return (
    <Modal
      show={show}
      onHide={() => handleCancel()}
      parentClassName="cs-common-modal-overlay"
      backdropClass="overlap-none"
      className={'cs-md-modal attached-doc-popup'}
      isCloseButton={false}
      hasBackButton={true}
      handleBackButtonClick={handleCancel}
      title={'Select Documents'}
      size={'md'}
      body={
        <div className="selected-documents-box">
          <Row>
            <Col lg={12}>
              <div className="text-center cs-regular-sub-heading-s cs-neutral-90">
                <h4 className="cs-semi-bold-h4 cs-neutral-100">
                  Please select the supporting documents you wish to submit for review.
                </h4>
              </div>
            </Col>
          </Row>
          <Form>
            <Row>
              <Col lg={12}>
                <div className="selected-category-list cs-scroller">
                  <div className="cs-accoutns-list assets-accoutns-list">
                    <div className="finance-data-container">
                      <span className="finance-heading heading-asset cs-medium-sub-heading-m cs-neutral-100">
                        Assets
                      </span>
                    </div>
                    {attachmentsList.asstes && Object.keys(attachmentsList.asstes).length === 0 && (
                      <div className="cs-common-no-record">
                        {' '}
                        <span className="cs-light-sub-heading-m cs-neutral-100">
                          {' '}
                          There is no documents attached in assets.
                        </span>
                      </div>
                    )}
                    {attachmentsList.asstes &&
                      assetsOrder.map((key) => {
                        return (
                          <div key={key} className="category-list">
                            {attachmentsList.asstes[key] && (
                              <h5 className="cs-medium-sub-heading-m cs-neutral-100">{key}</h5>
                            )}
                            {attachmentsList.asstes[key] &&
                              attachmentsList.asstes[key].map((asset, index) => {
                                return (
                                  <div
                                    key={index}
                                    className={
                                      submitLoader
                                        ? 'selected-doc-list cs-disabled'
                                        : !attachments.some(
                                              (item) =>
                                                item.attachment === asset.attachment ||
                                                item.file_name === asset.attachment
                                            )
                                          ? 'selected-doc-list'
                                          : 'selected-doc-list cs-disabled'
                                    }
                                    onClick={() =>
                                      !attachments.some(
                                        (item) =>
                                          item.attachment === asset.attachment ||
                                          item.file_name === asset.attachment
                                      ) &&
                                      !submitLoader &&
                                      handleCheckboxClick(asset.id, asset)
                                    }>
                                    <div className="category-title">
                                      <span className="cs-neutral-70">
                                        <CapsyncIcon title="files-outlined" size="18" />
                                      </span>
                                      <span className="cs-regular-sub-heading-s cs-neutral-100">
                                        {asset.original_file_name &&
                                        asset.original_file_name.length > 30
                                          ? asset.original_file_name.substring(0, 30) + '...'
                                          : asset.original_file_name}
                                      </span>
                                    </div>

                                    <Form.Check
                                      className="cs-form-check-box cs-regular-sub-heading-s cs-neutral-100"
                                      type="checkbox"
                                      disabled={
                                        submitLoader
                                          ? submitLoader
                                          : attachments.some(
                                              (item) =>
                                                item.attachment === asset.attachment ||
                                                item.file_name === asset.attachment
                                            )
                                      }
                                      checked={
                                        tempAttachments.some((item) => item.id === asset.id) ||
                                        attachments.some(
                                          (item) =>
                                            item.attachment === asset.attachment ||
                                            item.file_name === asset.attachment
                                        )
                                      }
                                    />
                                  </div>
                                );
                              })}
                          </div>
                        );
                      })}
                  </div>
                  <div className="cs-accoutns-list liability-accoutns-list">
                    <div className="finance-data-container">
                      <span className="finance-heading heading-liability cs-medium-sub-heading-m cs-neutral-100">
                        Liabilities
                      </span>
                    </div>
                    {attachmentsList.liabilities &&
                      Object.keys(attachmentsList.liabilities).length === 0 && (
                        <div className="cs-common-no-record">
                          {' '}
                          <span className="cs-light-sub-heading-m cs-neutral-100">
                            {' '}
                            There is no documents attached in Liabilities.
                          </span>
                        </div>
                      )}
                    {attachmentsList.liabilities &&
                      liblityOrder.map((key) => {
                        return (
                          <div key={key} className="category-list">
                            {attachmentsList.liabilities[key] && (
                              <h5 className="cs-medium-sub-heading-m cs-neutral-100">{key}</h5>
                            )}

                            {attachmentsList.liabilities[key] &&
                              attachmentsList.liabilities[key].map((liabilities, index) => {
                                return (
                                  <div
                                    key={index}
                                    className={
                                      submitLoader
                                        ? 'selected-doc-list cs-disabled'
                                        : !attachments.some(
                                              (item) =>
                                                item.attachment === liabilities.attachment ||
                                                item.file_name === liabilities.attachment
                                            )
                                          ? 'selected-doc-list'
                                          : 'selected-doc-list cs-disabled'
                                    }
                                    onClick={() =>
                                      !attachments.some(
                                        (item) =>
                                          item.attachment === liabilities.attachment ||
                                          item.file_name === liabilities.attachment
                                      ) &&
                                      !submitLoader &&
                                      handleCheckboxClick(liabilities.id, liabilities)
                                    }>
                                    <div className="category-title">
                                      <span className="cs-neutral-70">
                                        <CapsyncIcon title="files-outlined" size="18" />
                                      </span>
                                      <span className="cs-regular-sub-heading-s cs-neutral-100">
                                        {liabilities.original_file_name &&
                                        liabilities.original_file_name.length > 30
                                          ? liabilities.original_file_name.substring(0, 30) + '...'
                                          : liabilities.original_file_name}
                                      </span>
                                    </div>
                                    <Form.Check
                                      className="cs-form-check-box cs-regular-sub-heading-s cs-neutral-100"
                                      type="checkbox"
                                      disabled={
                                        submitLoader
                                          ? submitLoader
                                          : attachments.some(
                                              (item) =>
                                                item.attachment === liabilities.attachment ||
                                                item.file_name === liabilities.attachment
                                            )
                                      }
                                      checked={
                                        tempAttachments.some(
                                          (item) => item.id === liabilities.id
                                        ) ||
                                        attachments.some(
                                          (item) =>
                                            item.attachment === liabilities.attachment ||
                                            item.file_name === liabilities.attachment
                                        )
                                      }
                                    />
                                  </div>
                                );
                              })}
                          </div>
                        );
                      })}
                  </div>
                </div>
              </Col>
            </Row>
          </Form>
        </div>
      }
      saveButtonLabel={'Save'}
      handleOnSave={() => handleSubmitButtonClick()}
      cancelButtonLabel={'Cancel'}
      disabled={selectedIds.length === 0}
      handleOnCancel={handleCancel}
      loading={loader && loader['attachmentSubmit']}
    />
  );
};

// PROPS TYPE
AttachmentModal.propTypes = {
  show: PropTypes.bool,
  formik: PropTypes.func
};

/* ============================== SUB METHODS CANCEL CONFIRMATION MODAL ============================== */
export const CancelConfirmationModal = ({ show }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { rowID } = useSelector((state) => state.accreditation);

  const handleSaveBtnClick = () => {
    handleAccreditationModals(dispatch, 'methodCancelConfirmationModal', false);
    navigate(`/verifyinvestor/verification-type`);
  };
  const handlecancelBtnClick = () => {
    navigate(`/verifyinvestor`);
    handleAccreditationModals(dispatch, 'methodCancelConfirmationModal', false);
  };
  const handleverificationType = () => {
    if (rowID === 'qualifiedpurchaser') {
      return 'a qualified purchaser';
    } else if (rowID === 'qualifiedclient') {
      return 'a qualified client';
    } else {
      return 'an accredited investor';
    }
  };

  return (
    <Modal
      show={show}
      onHide={() => handleAccreditationModals(dispatch, 'methodCancelConfirmationModal', false)}
      parentClassName=""
      className={'cs-md-modal confirmation-modal'}
      isCloseButton={false}
      title={'Confirmation'}
      size={'md'}
      body={
        <div className="delete-modal-content">
          <p className="cs-medium-sub-heading-m cs-neutral-100 text-center">
            Do not cancel if you need more time to finish this request. If you do not complete the
            verification within 45 days of the original request, we will assume that you have
            cancelled this verification request and report that we were unable to verify you. Please
            contact support before your request expires for on extension.
          </p>
          <p className="cs-regular-body-text-m cs-neutral-90 text-center">
            Please confirm that you are not {`${handleverificationType()}`} investor or wish to
            cancel this verification request. If you are not {`${handleverificationType()}`} or wish
            to cancel this verification request, the verification process will be completed with the
            result that you are not verified {`${handleverificationType()}`}.
          </p>
        </div>
      }
      cancelButtonLabel={'I am not verified or wish to cancel'}
      handleOnCancel={() => handlecancelBtnClick()}
      saveButtonLabel={'I do not wish to cancel, continue'}
      handleOnSave={handleSaveBtnClick}
      btnSize="xl-btn"
      modalFixedClass={false}
    />
  );
};

// PROPS TYPE
CancelConfirmationModal.propTypes = {
  show: PropTypes.bool
};

/* ============================== SUB METHODS ADDITIONAL DOC MODAL ============================== */
export const AdditionalDocumentModal = ({ show, formJSON, formik, loading }) => {
  const dispatch = useDispatch();

  return (
    <Modal
      show={show}
      onHide={() => handleAccreditationModals(dispatch, 'additionalDocumentModal', false)}
      parentClassName=""
      className={'cs-md-modal accredited-upload-modal'}
      isCloseButton={false}
      title={'Upload Documents'}
      size={'md'}
      body={
        <div className="accredited-content-box">
          <Row>
            <Col lg={12} className="row-spacing">
              <p className="cs-medium-sub-heading-s cs-neutral-100 ">
                You need to upload a Bank statement, Assets report, Tax documents to continue
                verification.
              </p>
            </Col>
          </Row>
          <Form onSubmit={formik.handleSubmit}>
            <Row>
              <FieldsHTML fieldJSON={formJSON} formik={formik} />
            </Row>
          </Form>
        </div>
      }
      cancelButtonLabel={'Cancel'}
      handleOnCancel={() => {
        formik.resetForm();
        handleAccreditationModals(dispatch, 'additionalDocumentModal', false);
      }}
      saveButtonLabel={'Submit'}
      handleOnSave={formik.handleSubmit}
      disabled={formik.values.attachments.length === 0 && formik.values.data_obj.length === 0}
      loading={loading}
    />
  );
};

// PROPS TYPE
AdditionalDocumentModal.propTypes = {
  show: PropTypes.bool,
  formJSON: PropTypes.array,
  formik: PropTypes.func,
  loading: PropTypes.bool
};
