import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Row, Col } from 'react-bootstrap';
import { toast } from 'react-toastify';
import OtpInput from 'react-otp-input';
import { useFormik } from 'formik';
import PropTypes from 'prop-types';

// COMPONENT IMPORTS
import Modal from '../Modal';
import CapsyncIcon from '../CapsyncIcon';
import { otpValidationSchema } from '../../validations';
import { TOAST_MESSAGE } from '../../constants/message';
import { MFA_TYPE_DATA, MFA_TYPES } from '../../constants/system';

// API
import { generateMFAOTP, useAccessUser, verifyMFAOTP } from '../../slices/authSlice';
import { setMFA } from '../../slices/mfaSlice';

/* ============================== MFA VERIFICATION MODAL ============================== */
const MFAVerificationModal = (props) => {
  const {
    modalState,
    handleModalClose,
    mfaType,
    otherFileFlag,
    handleSuccess,
    successToastMessage = TOAST_MESSAGE.MFA_AUTHENTICATED_SUCCESS,
    isMySettings = false
  } = props;

  const dispatch = useDispatch();

  const accessUser = useAccessUser();
  const { user, mfa } = useSelector((state) => state.auth);

  const [modalData, setModalData] = useState({
    title: '',
    description: '',
    hasReceiverDetails: false
  });
  const [showResend, setShowResend] = useState(false);

  useEffect(() => {
    /** reset MFA error message for the first time */
    dispatch(setMFA({ ...mfa, message: '', status: null, code: '' }));
    if (!modalState) setShowResend(false);
  }, [modalState]);

  useEffect(() => {
    const MfaData = mfaType && MFA_TYPE_DATA.get(mfaType);
    setModalData(MfaData);
    if (mfa?.code === 200) {
      otherFileFlag == false
        ? dispatch(setMFA({ ...mfa, pfsVerified: true }))
        : dispatch(setMFA({ ...mfa, otherPfsVerified: true }));
      handleModalClose();
      resetForm();
      handleSuccess && handleSuccess();
    } else {
      const message = mfa?.message;
      setFieldError('otp', message);
    }
  }, [mfaType, mfa?.message]);

  const forEmailOrPhone = mfaType === MFA_TYPES.EMAIL || mfaType === MFA_TYPES.PHONE;
  const showToast = (res) =>
    successToastMessage && res.code === 200 && toast.success(successToastMessage);

  // Formik

  const initialValues = {
    otp: ''
  };

  const { handleSubmit, values, setFieldValue, errors, setFieldError, resetForm } = useFormik({
    initialValues: initialValues,
    validationSchema: otpValidationSchema,
    onSubmit: async (values) => {
      const otp = values.otp;
      const payload = {
        user_id: isMySettings ? accessUser?.loginUserDetails?.id : user.id
      };

      dispatch(setMFA({ ...mfa, message: '', status: null, code: '' }));

      if (!forEmailOrPhone) {
        payload.google_otp = otp;
        await dispatch(verifyMFAOTP({ mfaType: MFA_TYPES.AUTHENTICATOR, payload }))
          .unwrap()
          .then((res) => showToast(res));
      } else if (mfaType === MFA_TYPES.EMAIL) {
        payload.email_otp = otp;
        await dispatch(verifyMFAOTP({ mfaType: MFA_TYPES.EMAIL, payload }))
          .unwrap()
          .then((res) => showToast(res));
      } else if (mfaType === MFA_TYPES.PHONE) {
        payload.phone_otp = otp;
        await dispatch(verifyMFAOTP({ mfaType: MFA_TYPES.PHONE, payload }))
          .unwrap()
          .then((res) => showToast(res));
      }
    }
  });

  const handleOTPChange = (value) => {
    setFieldValue('otp', value);
    setShowResend(false);
  };

  const handleResend = () => {
    dispatch(
      generateMFAOTP({
        mfaType: mfaType,
        payload: { user_id: isMySettings ? accessUser?.loginUserDetails?.id : user.id }
      })
    );
    setShowResend(true);
    resetForm();
  };

  const handleCancel = () => {
    handleModalClose();
    resetForm();
  };

  const bodyElem = () => {
    return (
      <div className="auth-right">
        <div className="formArea">
          <div className="text-center">
            {mfaType === MFA_TYPES.EMAIL && (
              <p className="cs-neutral-80 cs-regular-h5">
                {modalData?.description}{' '}
                <span className="cs-primary">
                  {isMySettings ? accessUser?.loginUserDetails?.email : user.email}
                </span>
              </p>
            )}

            {mfaType === MFA_TYPES.PHONE && (
              <p className="cs-neutral-80 cs-regular-h5">
                {modalData?.description}{' '}
                <span className="cs-primary">
                  {isMySettings ? accessUser?.loginUserDetails?.phone_no : user.phone_no}
                </span>
              </p>
            )}

            {mfaType === MFA_TYPES.AUTHENTICATOR && (
              <p className="cs-neutral-80 cs-regular-h5">{modalData?.description}</p>
            )}

            <div className="cs-auth-form align-items-center cs-verification-pin">
              <div className="cs-otp">
                <Form>
                  <Row>
                    <Col lg={12}>
                      <OtpInput
                        value={values.otp}
                        onChange={handleOTPChange}
                        numInputs={6}
                        inputStyle="otp-txt"
                        containerStyle="otp-layout"
                        name="otp"
                        renderInput={(props) => <input {...props} />}
                      />
                      {!showResend && errors.otp && (
                        <span className="cs-light-body-text-m cs-danger">{errors.otp}</span>
                      )}

                      {forEmailOrPhone && (
                        <h5 className="cs-regular-h5">
                          <span className="auth-text-color-resend">
                            {' '}
                            Didn't receive the code?{' '}
                            <span className="cs-primary cursor-pointer" onClick={handleResend}>
                              Resend
                            </span>{' '}
                          </span>
                        </h5>
                      )}
                      {forEmailOrPhone && showResend && (
                        <div className="cs-msg cs-regular-body-text-s cs-success">
                          <span className="icon">
                            <CapsyncIcon title="verification-outlined" size="18" />
                          </span>
                          <span className="cs-msg cs-regular-body-text-m cs-success">
                            {`We just sent you ${mfaType === MFA_TYPES.EMAIL ? 'an email' : 'a text'}. Enter the 6-digit code.`}
                          </span>
                        </div>
                      )}
                      {/* {!forEmailOrPhone && (
                        <div className="cs-light-body-text-m">
                          MFA code can be located in your Authenticator app installed on your
                          smartphone.
                        </div>
                      )} */}
                    </Col>
                  </Row>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  return (
    <div>
      <Modal
        title={modalData?.title}
        show={modalState}
        handleClose={handleModalClose}
        body={bodyElem()}
        isCloseButton={false}
        className="cs-md-modal"
        saveButtonLabel={'Verify account'}
        cancelButtonLabel={'Cancel'}
        handleOnSave={handleSubmit}
        handleOnCancel={handleCancel}
      />
    </div>
  );
};

// PROPS TYPE
MFAVerificationModal.propTypes = {
  modalState: PropTypes.bool.isRequired,
  handleModalClose: PropTypes.func.isRequired,
  mfaType: PropTypes.string.isRequired,
  otherFileFlag: PropTypes.bool,
  handleSuccess: PropTypes.func,
  successToastMessage: PropTypes.string,
  isMySettings: PropTypes.bool
};

export default MFAVerificationModal;
