import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';

// COMPONENT IMPORTS
import DeleteModal from '../../../components/DeleteModal';
import { TOAST_MESSAGE } from '../../../constants/message';

// API
import { updateOrRemoveCollaborator } from '../../../slices/collaborationSlice';

/* ============================== COLLABORATION DELETE MODAL ============================== */
const CollabDeleteModal = ({ show, setShow, item, fetchCollaborators, setActiveRowIndex }) => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const handleDeleteData = async () => {
    const id = item?.id;
    const data = {
      is_deleted: true
    };

    setLoading(true);
    try {
      await dispatch(updateOrRemoveCollaborator({ data, id })).unwrap();
      toast.success(TOAST_MESSAGE.RECORD_DELETE_SUCCESS);
      setActiveRowIndex(-1);
      fetchCollaborators();
    } catch {
      toast.error(TOAST_MESSAGE.RECORD_DELETE_FAILED);
    } finally {
      setShow(false);
      setLoading(false);
    }
  };

  return (
    <DeleteModal
      show={show}
      handleCloseModel={() => setShow(false)}
      handleDeleteData={handleDeleteData}
      loader={loading}
    />
  );
};

// PROPS TYPE
CollabDeleteModal.propTypes = {
  show: PropTypes.bool,
  setShow: PropTypes.func,
  item: PropTypes.object,
  fetchCollaborators: PropTypes.func,
  setActiveRowIndex: PropTypes.func
};

export default CollabDeleteModal;
