import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Container, Row } from 'react-bootstrap';
import { Outlet, useNavigate } from 'react-router-dom';

// COMPONENT IMPORTS
import Image from '../components/Image';
import AuthBanner from '../auth/AuthBanner';

// API
import { useBrandDetails } from '../slices/brandingSlice';
import { handleSyncAccount } from '../slices/plaidSlice';
import { useUserID } from '../slices/authSlice';

/* ============================== AUTH LAYOUT ============================== */
const AuthLayout = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const brandDetails = useBrandDetails();
  const user_id = useUserID();
  const { user, isAuthenticated } = useSelector((state) => state.auth);

  useEffect(() => {
    if (isAuthenticated) {
      navigate('/dashboard');
      dispatch(handleSyncAccount({ user_id: user_id }));
    } else if (user?.id && isAuthenticated === false) {
      navigate('/mfa-setup');
    }
  }, [isAuthenticated, navigate, user_id]);

  return (
    <section
      className={`auth-section signin-section sign-in-template${brandDetails.welcome_template_type}`}>
      <Container fluid>
        <Row className="align-items-center">
          {brandDetails.welcome_template_type !== 2 && <AuthBanner />}
          <Col lg={6} className="auth-form">
            <div className="cs-right-section">
              <div className="cs-auth-image">
                <Image
                  width="132"
                  src={brandDetails && brandDetails.primary_logo_s3_url}
                  alt="brand-logo"
                  className="cs-logo"
                />
              </div>
              <Outlet />
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default AuthLayout;
