import React from 'react';
import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';

// COMPONENT IMPORTS
import CapsyncIcon from '../CapsyncIcon';
import UploadFileList from './UploadFileList';
import { validateDuplicateFileName } from '../../config/validateDuplicateFileName';

/* ============================== CATEGORY FILE UPLOAD ============================== */
const CategoryFileUpload = ({
  errors,
  values,
  setFieldError,
  setFieldValue,
  name,
  storageError = ''
}) => {
  const files = values[name];
  const errorMessage = errors[name];
  const setFiles = (data) => setFieldValue(name, data);
  const setErrorMessage = (err) => setFieldError(name, err);

  const onDropAccepted = (newFiles) => {
    if (validateDuplicateFileName(newFiles, files, setErrorMessage)) {
      const currentFiles = [...newFiles, ...files];
      setFiles(currentFiles);
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      'image/jpeg': ['.jpeg', '.jpg'],
      'image/png': ['.png'],
      'application/pdf': ['.pdf']
    },
    onDropAccepted
  });

  return (
    <div className="cs-upload-box category-upload">
      <label className="cs-regular-sub-heading-s cs-neutral-100 form-label">Upload Files</label>
      <div {...getRootProps()} className="cs-upload-container">
        <input {...getInputProps()} name={name} />
        <span className="cs-primary upload-icon">
          <CapsyncIcon title="upload-files-filled" size="28" />
        </span>
        <p className="cs-regular-sub-heading-m cs-neutral-90">
          <span className="cs-primary">Browse</span>&nbsp;or drag file here
        </p>
        <p className="cs-regular-sub-heading-xs cs-neutral-70">
          Supports PDF, PNG, JPG and JPEG format
        </p>
      </div>
      {errorMessage && (
        <span className="form-error-msg cs-light-body-text-s cs-danger">{errorMessage}</span>
      )}
      {storageError && (
        <span className="form-error-msg cs-light-body-text-s cs-danger">{storageError}</span>
      )}
      <UploadFileList files={files} setFiles={setFiles} />
    </div>
  );
};

// PROPS TYPE
CategoryFileUpload.propTypes = {
  values: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  setFieldError: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  storageError: PropTypes.string
};

export default CategoryFileUpload;
