import React from 'react';
import PropTypes from 'prop-types';

// COMPONENT IMPORTS
import CapsyncIcon from './CapsyncIcon';

/* ============================== SPINNER ============================== */
const CommonSpinner = ({ classParent, size = 20 }) => {
  return (
    <span className={`cs-common-spinner ${classParent}`}>
      <CapsyncIcon title="loading-outlined" size={size} />
    </span>
  );
};

// PROPS TYPE
CommonSpinner.propTypes = {
  classParent: PropTypes.any,
  size: PropTypes.any
};

export default CommonSpinner;
