import React, { useEffect, useState } from 'react';
import { Button, Col, Container, Dropdown, Modal, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import moment from 'moment';

// COMPONENT IMPORTS
import ExternalAttachments from './ExternalAttachments';
import CapsyncIcon from '../../../components/CapsyncIcon';
import DashboardTable from '../../dashboard/DashboardTable';
import SharePFSCategoryTable from '../SharePFSCategoryTable';
import ContingentLiability from '../../dashboard/ContingentLiability';
import { assetsRouts, liabilitiesRouts } from '../../../utils/routesName';

// API
import { useAssetsDashboard, useLiabilitieDashboard } from '../../../slices/dashboardSlice';
import { getAllAssetData, getAllLiabilityData } from '../../../slices/categorySlice';
import { useUser } from '../../../slices/authSlice';

// CSS IMPORTS
import '../sharePFSModal.css';

/* ============================== SHARE PFS MAIN MODAL ============================== */
const SharePFSSecondMainModal = (props) => {
  const {
    setShowPFSSecondModal,
    showPFSSecondModal,
    setShowPFSThirdModal,
    sharePFSFirstModalSchema,
    setRadioValue,
    radioValue,
    uploadedFiles,
    handleFileChange,
    handleDocumentNameChange,
    documentName,
    handleUploadAttachment,
    inputRef,
    duplicateFileError,
    setUploadedFiles,
    setComments,
    comments,
    documentError,
    selectedFiles
  } = props;
  const radioName = [
    { name: 'Summary', value: '1' },
    { name: 'Worksheet', value: '2' },
    { name: 'Attachments', value: '3' }
  ];
  const { accessUser } = useSelector((state) => state.auth);
  const [allAssetData, setAllAssetData] = useState({});
  const [allLiabilitiesData, setAllLiabilitiesData] = useState({});

  const user = useUser();
  const address =
    user && user.user_personal_details && JSON.parse(user?.user_personal_details?.address);
  const allAssets = useAssetsDashboard();
  const allLiabilitie = useLiabilitieDashboard();
  const dispatch = useDispatch();
  const assets = assetsRouts.map((item) => ({
    ...item,
    value: allAssets?.assetsData?.[item.key] ?? item.value
  }));

  const liabilitie = liabilitiesRouts.map((item) => ({
    ...item,
    value: allLiabilitie?.liabilityData?.[item.key] ?? item.value
  }));

  const totalAssets = Object.values(allAssets?.assetsData || {}).reduce(
    (sum, value) => sum + value,
    0
  );
  const totalLiabilities = Object.values(allLiabilitie?.liabilityData || {}).reduce(
    (sum, value) => sum + value,
    0
  );

  const netWorth = Number(totalAssets) - Number(totalLiabilities);
  const handleSecondPFSModal = () => {
    setShowPFSSecondModal(false);
    setShowPFSThirdModal(true);
  };
  const handlePFSSecondCloseModal = () => {
    setShowPFSSecondModal(false);
    sharePFSFirstModalSchema.resetForm();
    setRadioValue({ num: '1', name: 'PFS Summary' });
  };
  const checkFetchRadio = (params, name) => {
    setRadioValue({ name: name, num: params });
  };

  const fetchAssetsData = async () => {
    const payload = { userId: user?.id };
    const response = await dispatch(getAllAssetData(payload)).unwrap();
    if (response.code === 200) {
      setAllAssetData(response?.data);
    }
  };
  const fetchLiabilitiesData = async () => {
    const payload = { userId: user?.id };
    const response = await dispatch(getAllLiabilityData(payload)).unwrap();
    if (response.code === 200) {
      setAllLiabilitiesData(response?.data);
    }
  };
  useEffect(() => {
    fetchAssetsData();
    fetchLiabilitiesData();
  }, [user, radioValue]);

  const storePfstab = (params) => {
    setRadioValue({
      num: radioName.filter((x) => x.value === params)[0].value,
      name: radioName.filter((x) => x.value === params)[0].name
    });
  };

  return (
    <Modal
      show={showPFSSecondModal}
      centered
      aria-labelledby="contained-modal-title-vcenter"
      className="share-pfs-modal">
      <Modal.Header>
        <div className="pfs-preview-modal">
          <div className="buttons">
            <div className="for-des">
              <div className="cs-tabs cs-primary">
                {radioName.map((radioName, idx) => (
                  <Button
                    className={`cs-stroke-default-tab cs-regular-h5 cs-tabs-height ${
                      radioValue.num === radioName.value ? 'cs-stroke-active' : ''
                    }`}
                    key={idx}
                    id={`radio-${idx}`}
                    type="radio"
                    variant={idx % 2 ? 'manage-user-individual' : 'manage-user-wealth-manager'}
                    name="radioName"
                    value={radioName.value}
                    checked={radioValue.num === radioName.value}
                    onClick={(e) => checkFetchRadio(e.currentTarget.value)}>
                    {radioName.name}
                  </Button>
                ))}
              </div>
            </div>
            <div className="for-mob">
              <div className="pfs-preview-dropdown cs-common-add-dropdown">
                <Dropdown>
                  <Dropdown.Toggle className="cs-semi-bold-h5">
                    {radioValue.name}
                    <CapsyncIcon title="chevron-down-outlined" size="16" />
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="cs-pfs-menu cs-scroller">
                    {radioName.map((radioName, idx) => {
                      return (
                        <Dropdown.Item
                          key={idx}
                          className={`cs-regular-h5 ${radioValue.num === radioName.value ? 'selected' : ''}`}
                          onClick={(e) => storePfstab(radioName.value)}>
                          {radioName.name}
                        </Dropdown.Item>
                      );
                    })}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
          </div>
        </div>
      </Modal.Header>
      <div className="pfs-modal-scroll dashboard-main-section cs-scroller">
        <Modal.Body className="pfs-preview-modal">
          {radioValue.num === '1' ? (
            <section className="pfs-container">
              <Container fluid>
                <div className="pfs-header">
                  <Row>
                    <Col lg={6}>
                      <div className="view-pfs-modal cs-regular-sub-heading-m">
                        <p>Name:&nbsp;</p> {user?.display_name}
                      </div>
                    </Col>
                    <Col lg={6}>
                      <div className="view-pfs-modal cs-regular-sub-heading-m">
                        <p>Email:</p> &nbsp;{user?.email}
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={6}>
                      <div className="view-pfs-modal cs-regular-sub-heading-m">
                        <p>Address:&nbsp;</p>
                        {`${address?.line1}, ${address?.state}, ${address?.city}, ${address?.country}, ${address?.postal_code}`}
                      </div>
                    </Col>
                    <Col lg={6}>
                      <div className="view-pfs-modal cs-regular-sub-heading-m">
                        <p>Phone:&nbsp;</p> {user?.phone_no}
                      </div>
                    </Col>
                  </Row>
                </div>

                <div className="asset-liability-container">
                  <Row>
                    <Col lg={6}>
                      <div className="pfs-common-box finance-box">
                        <DashboardTable
                          name="Assets"
                          data={assets}
                          total={totalAssets}
                          isSharePFS={true}
                        />

                        <div className="for-des">
                          <div className="total-networth">
                            <h3 className="net-worth-heading cs-semi-bold-h3 cs-neutral-100">
                              Net Worth
                            </h3>
                            <div className="dollar-value-gap net-worth-value cs-semi-bold-h3 cs-neutral-100">
                              <span>$&nbsp;</span>
                              <span>{netWorth.toLocaleString()}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                    <Col lg={6}>
                      <div className="pfs-common-box">
                        <DashboardTable
                          name="Liabilities"
                          data={liabilitie}
                          total={totalLiabilities}
                          isSharePFS={true}
                        />
                        <ContingentLiability isSharePFS={true} />
                      </div>
                      <div className="for-mob">
                        <div className="total-networth">
                          <h3 className="net-worth-heading cs-semi-bold-h3 cs-neutral-100">
                            Net Worth
                          </h3>
                          <div className="dollar-value-gap net-worth-value cs-semi-bold-h3 cs-neutral-100">
                            <span>$&nbsp;</span>
                            <span>{netWorth.toLocaleString()}</span>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>

                <div className="pfs-footer cs-regular-sub-heading-m cs-neutral-70">
                  <p>
                    I authorize Lender and/or assigns to make inquiries as necessary to verify the
                    accuracy of the statements made and to determine my creditworthiness. I certify
                    the above and the statements contained in the attachments are true and accurate
                    as of the stated date(s). These statements are made for the purpose of either
                    obtaining loan or guaranteeing a loan. I understand FALSE statements may result
                    in forfeiture of benefits and possible prosecution by the U.S. Attorney General
                    (Reference 18 U.S.C. 1001).
                  </p>
                  <div className="pfs-signature-container">
                    <Row>
                      <Col lg={6}>
                        <div className="pfs-signature-box pfs-name-block">
                          <span className="cs-regular-sub-heading-m cs-neutral-100">Name</span>
                          <span className="cs-light-body-text-l cs-neutral-100">
                            {user?.display_name}
                          </span>
                        </div>
                        <div className="pfs-signature-bottom">
                          <div className="pfs-signature-box pfs-signature-block">
                            <span className="cs-regular-sub-heading-m cs-neutral-100">
                              Signature
                            </span>
                            <span className="cs-signature-text cs-neutral-100"></span>
                          </div>

                          <div className="pfs-signature-box pfs-date-block">
                            <span className="cs-regular-sub-heading-m cs-neutral-100">Date</span>
                            <span className="cs-light-body-text-l cs-neutral-100">
                              {moment(new Date()).format('MM/DD/YYYY')}
                            </span>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>
              </Container>
            </section>
          ) : radioValue.num === '2' ? (
            <section className="pfs-container">
              <div className="assets-main-section">
                <Container fluid>
                  <Row>
                    <Col>
                      <h2 className="pfs-h2-title cs-semi-bold-h1 cs-neutral-100">
                        Assets
                        <Link
                          to={`/assets`}
                          className="custom-table-element"
                          onClick={handlePFSSecondCloseModal}>
                          <span className="cs-asset icon">
                            <CapsyncIcon title="redirect-outlined" size="28" />
                          </span>
                        </Link>
                      </h2>
                      <div className="pfs-assets-table">
                        {assetsRouts.map((category, index) => (
                          <React.Fragment key={index}>
                            <div className="pfs-table-container">
                              <Row>
                                <Col lg={12}>
                                  <div className="pfs-common-title">
                                    <h4 className="cs-regular-h4 cs-neutral-100">
                                      {category.category_name}
                                    </h4>
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col lg={12}>
                                  <SharePFSCategoryTable
                                    data={allAssetData[category.key]}
                                    columns={category.tabArray}
                                    parent={category.parent}
                                    category={category}
                                  />
                                </Col>
                              </Row>
                            </div>
                          </React.Fragment>
                        ))}
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <h2 className="pfs-h2-title cs-semi-bold-h1 cs-neutral-100">
                        Liabilities
                        <Link
                          to={`/liabilities`}
                          className="custom-table-element"
                          onClick={handlePFSSecondCloseModal}>
                          <span className="cs-liability icon">
                            <CapsyncIcon title="redirect-outlined" size="28" />
                          </span>
                        </Link>
                      </h2>
                      <div className="pfs-liabilities-table">
                        {liabilitiesRouts.map((category, index) => (
                          <React.Fragment key={index}>
                            <div className="pfs-table-container">
                              <Row>
                                <Col lg={12}>
                                  <div className="pfs-common-title">
                                    <h4 className="cs-regular-h4 cs-neutral-100">
                                      {category.category_name}
                                    </h4>
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col lg={12}>
                                  <SharePFSCategoryTable
                                    data={allLiabilitiesData[category.key]}
                                    columns={category.tabArray}
                                    parent={category.parent}
                                    category={category}
                                  />
                                </Col>
                              </Row>
                            </div>
                          </React.Fragment>
                        ))}
                      </div>
                    </Col>
                  </Row>
                </Container>
              </div>
            </section>
          ) : (
            <ExternalAttachments
              handleFileChange={handleFileChange}
              handleDocumentNameChange={handleDocumentNameChange}
              uploadedFiles={uploadedFiles}
              documentName={documentName}
              handleUploadAttachment={handleUploadAttachment}
              inputRef={inputRef}
              duplicateFileError={duplicateFileError}
              setUploadedFiles={setUploadedFiles}
              setComments={setComments}
              comments={comments}
              documentError={documentError}
              selectedFiles={selectedFiles}
            />
          )}
        </Modal.Body>
      </div>

      <Modal.Footer>
        <div className="cs-modal-btn wm-req-shareicon-viewpdf">
          <Button
            className="cs-btn-secondary cs-regular-h5 lg-btn"
            type="button"
            onClick={handlePFSSecondCloseModal}>
            Cancel
          </Button>
          <Button
            className="cs-btn-primary cs-regular-h5 lg-btn"
            type="button"
            onClick={handleSecondPFSModal}>
            {accessUser && accessUser.accessUserID !== null ? 'Send for Signature' : 'Sign'}
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

// PROPS TYPE
SharePFSSecondMainModal.propTypes = {
  showPFSSecondModal: PropTypes.bool,
  setShowPFSSecondModal: PropTypes.func,
  setShowPFSThirdModal: PropTypes.func,
  sharePFSFirstModalSchema: PropTypes.object,
  radioValue: PropTypes.object,
  setRadioValue: PropTypes.func,
  handleFileChange: PropTypes.func,
  handleDocumentNameChange: PropTypes.func,
  uploadedFiles: PropTypes.array,
  documentName: PropTypes.string,
  handleUploadAttachment: PropTypes.func,
  inputRef: PropTypes.object,
  duplicateFileError: PropTypes.string,
  setUploadedFiles: PropTypes.func,
  setComments: PropTypes.func,
  comments: PropTypes.string,
  documentError: PropTypes.string,
  selectedFiles: PropTypes.array
};

export default SharePFSSecondMainModal;
