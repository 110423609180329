import React, { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

// COMPONENT IMPORTS
import { socket } from '../../config/Socket';

// API
import { showUploadProgress, useProgressUploadDetails } from '../../slices/commonSlice';
import { useAccessUser, useUser } from '../../slices/authSlice';
import { useStorageDetails } from '../../slices/fileManagementSlice';

/* ============================== GOOGLE DRIVE UPLOAD ============================== */
const GoogleDriveUpload = (props) => {
  const { googleDriveUploadRef, parentId, currentFolderDetails } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const storageDetails = useStorageDetails();
  const progressUploadDetails = useProgressUploadDetails();
  const user = useUser();
  const accessUser = useAccessUser();

  const [accessToken, setAccessToken] = useState(null);
  const [pickerInitiated, setPickerInitiated] = useState(false);
  const [gisInitiated, setGisInitiated] = useState(false);

  useEffect(() => {
    const script1 = document.createElement('script');
    const script2 = document.createElement('script');

    script1.src = 'https://apis.google.com/js/api.js';
    script1.async = true;
    script1.defer = true;
    script1.onload = gapiLoaded;

    script2.src = 'https://accounts.google.com/gsi/client';
    script2.async = true;
    script2.defer = true;
    script2.onload = gisLoaded;

    document.body.appendChild(script1);
    document.body.appendChild(script2);

    return () => {
      document.body.removeChild(script1);
      document.body.removeChild(script2);
    };
  }, []);

  const gapiLoaded = () => {
    window.gapi.load('client:picker', initializePicker);
  };

  const initializePicker = async () => {
    await window.gapi.client.load('https://www.googleapis.com/discovery/v1/apis/drive/v3/rest');
    setPickerInitiated(true);
    maybeEnableButtons();
  };

  const gisLoaded = () => {
    window.google.accounts.oauth2.initTokenClient({
      client_id: process.env.REACT_APP_GOOGLE_DRIVE_CLIENT_ID,
      scope: 'https://www.googleapis.com/auth/drive',
      callback: '' // defined later
    });
    setGisInitiated(true);
    maybeEnableButtons();
  };

  const maybeEnableButtons = () => {
    if (pickerInitiated && gisInitiated) {
      document.getElementById('authorize_button').style.visibility = 'visible';
    }
  };
  let newAccessToken;

  const googleDrive = async () => {
    if (!accessToken) {
      const tokenClient = window.google.accounts.oauth2.initTokenClient({
        client_id: process.env.REACT_APP_GOOGLE_DRIVE_CLIENT_ID,
        scope: 'https://www.googleapis.com/auth/drive',
        callback: async (response) => {
          if (response.error !== undefined) {
            navigate(`/individual`);
            return;
          }

          newAccessToken = response.access_token;

          setAccessToken(newAccessToken);

          await createPicker(newAccessToken);
        }
      });
      tokenClient.requestAccessToken({ prompt: accessToken ? '' : 'consent' });
    } else {
      const tokenClient = window.google.accounts.oauth2.initTokenClient({
        client_id: process.env.REACT_APP_GOOGLE_DRIVE_CLIENT_ID,
        scope: 'https://www.googleapis.com/auth/drive',
        callback: async (response) => {
          if (response.error !== undefined) {
            navigate(`/individual`);
            return;
          }

          newAccessToken = response.access_token;

          setAccessToken(newAccessToken);

          await createPicker(newAccessToken);
        }
      });
      tokenClient.requestAccessToken({ prompt: '' });
    }
  };

  const createPicker = (newAccessToken) => {
    const docsView = new window.google.picker.DocsView(window.google.picker.ViewId.DOCS);
    if (currentFolderDetails?.category_id && currentFolderDetails?.row_id) {
      docsView.setMimeTypes('image/png,image/jpeg,image/jpg,application/pdf');
    } else {
      // docsView.setMimeTypes("image/png,image/jpeg,image/jpg");
      docsView.setMimeTypes(
        'application/x-tar,application/gzip,audio/mpeg,audio/mp4,audio/wav,audio/ogg,image/tiff,text/css,application/x-php,application/javascript,' +
          'image/webp,video/3gpp,video/3gpp2,video/x-ms-asf,video/x-msvideo,video/divx,video/mpeg,video/vnd.rn-realvideo,application/zip,application/x-rar-compressed,' +
          'text/x-java-source,text/x-perl,text/x-python,application/vnd.wap.wml,application/vnd.wap.xhtml+xml,application/xml,image/bmp,image/gif,image/jpeg,image/png,' +
          'application/vnd.oasis.opendocument.text,application/rtf,image/svg+xml,application/x-tex,text/plain,text/basic,text/x-c,text/x-c++src,text/x-h,text/x-csharp,' +
          'application/x-hwp,text/html,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-powerpoint,' +
          'application/vnd.openxmlformats-officedocument.presentationml.presentation,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,' +
          'application/vnd.oasis.opendocument.presentation,application/vnd.oasis.opendocument.spreadsheet,' +
          'image/png,image/jpeg,image/jpg,application/pdf,application/postscript,text/csv,application/epub+zip,application/vnd.google-earth.kml+xml,application/vnd.google-earth.kmz,application/gpx+xml'
      );
      docsView.setIncludeFolders(true); // Include folders in the view
    }
    docsView.setSelectFolderEnabled(true); // Allow folder selection

    const picker = new window.google.picker.PickerBuilder()
      .enableFeature(window.google.picker.Feature.NAV_HIDDEN)
      .enableFeature(window.google.picker.Feature.MULTISELECT_ENABLED)
      .setDeveloperKey(process.env.REACT_APP_GOOGLE_DRIVE_API_KEY)
      .setAppId(process.env.REACT_APP_GOOGLE_DRIVE_APP_ID)
      .setOAuthToken(newAccessToken)
      .addView(docsView) // Add the docs view with folders included
      .addView(new window.google.picker.DocsUploadView())
      .setCallback(pickerCallback)
      .build();

    picker.setVisible(true);
  };

  const pickerCallback = async (data) => {
    const file_unique_Id = [];
    const uId = Date.now();
    // const fileNames = [];
    let total_size = 0;
    if (data.action === window.google.picker.Action.PICKED) {
      data[window.google.picker.Response.DOCUMENTS].forEach((document) => {
        const fileId = document[window.google.picker.Document.ID];
        const fileName = document.name;
        const mimeType = document[window.google.picker.Document.MIME_TYPE];
        // fileNames.push(fileName);
        total_size += document.sizeBytes;
        file_unique_Id.push({
          fileId: fileId,
          name: fileName,
          mimeType: mimeType
        });
      });
      const file_size_allowed = process.env.REACT_APP_FILE_UPLOAD_SIZE_ALLOWED_FILE_MANAGEMENT;
      const inProgressData = progressUploadDetails.length
        ? progressUploadDetails.reduce((acc, item) => acc + item.size, 0)
        : 0;
      if (total_size <= file_size_allowed) {
        const value = {
          files: file_unique_Id,
          accessToken: newAccessToken,
          userId: user.id,
          parentId: parentId,
          categoryId: currentFolderDetails.category_id || undefined,
          categoryPrimaryId: currentFolderDetails.row_id || undefined,
          isCategoryUpload: currentFolderDetails.category_id && currentFolderDetails.row_id,
          progressId: uId,
          // files: [...fileNames],
          loginUserId: accessUser?.accessUserID || user.id,
          loginUserRole: accessUser?.roleID || user.user_role.id,
          maxSpace: storageDetails?.total_space,
          usedSpace: storageDetails?.used_space + inProgressData,
          checkDoubleSpace: currentFolderDetails.check_double_space,
          uploadType: 'google_drive'
        };
        try {
          socket.emit('driveUpload', value);
        } catch (error) {
          setTimeout(() => {
            dispatch(showUploadProgress({ id: uId, isDelete: true }));
          }, 1000);
          toast.error(error.message);
          console.error('File upload failed:', error);
        }
      } else {
        toast.error('File size greater than ' + file_size_allowed / 1048576 + ' mb not allowed');
      }
    }
  };
  return <Button ref={googleDriveUploadRef} className="d-none" onClick={() => googleDrive()} />;
};

// PROPS TYPE
GoogleDriveUpload.propTypes = {
  googleDriveUploadRef: PropTypes.any,
  parentId: PropTypes.any,
  currentFolderDetails: PropTypes.object
};

export default GoogleDriveUpload;
