import React from 'react';
import PropTypes from 'prop-types';
import { Dropdown, Form } from 'react-bootstrap';

// COMPONENT IMPORTS
import CapsyncIcon from '../../components/CapsyncIcon';

/* ============================== IND PFS ============================== */
const IndividualPFS = (props) => {
  const { selectValue, setSelectValue } = props;
  const individualJointArray = [
    { name: 'Individual', key: '1' },
    { name: 'Joint', key: '2' }
  ];
  return (
    <div>
      <Form.Group className="cs-form-group">
        <Dropdown
          onSelect={(key) => setSelectValue(key)}
          className="cs-form-dropdown last-form-field">
          <Dropdown.Toggle
            variant="primary"
            id="dropdown-basic"
            className="indi-sharepfs-dropdown main-filter cs-regular-sub-heading-s">
            {selectValue === ''
              ? 'Individual or Joint'
              : selectValue === '1'
                ? 'Individual'
                : 'Joint'}
            <span className="cs-neutral-90 cs-dropdown-icon">
              <CapsyncIcon title="chevron-down-outlined" size="18" />
            </span>
          </Dropdown.Toggle>
          <Dropdown.Menu className="cs-scroller">
            {individualJointArray.map((itr, index) => {
              return (
                <React.Fragment key={index}>
                  <Dropdown.Item
                    className={`${selectValue == itr.key ? 'selected-nav-item' : ''} cs-regular-sub-heading-s`}
                    eventKey={itr.key}>
                    {itr.name}
                  </Dropdown.Item>
                </React.Fragment>
              );
            })}
          </Dropdown.Menu>
        </Dropdown>
      </Form.Group>
    </div>
  );
};

// PROPS TYPE
IndividualPFS.propTypes = {
  selectValue: PropTypes.string,
  setSelectValue: PropTypes.func
};

export default IndividualPFS;
