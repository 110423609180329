import React from 'react';
import PropTypes from 'prop-types';
import CapsyncIcon from '../CapsyncIcon';

/* ============================== FILE LIST ============================== */
const UploadFileList = ({ files, setFiles }) => {
  const removeFile = (deletedFile) => {
    const filteredArray = files.filter((file) => file.name != deletedFile);
    setFiles(filteredArray);
  };

  return (
    <aside className="category-attachments-files">
      <div className="attachment-files cs-files">
        {files &&
          files.map((file, index) => {
            return (
              <div className="cs-selected-files" key={index}>
                <div className="cs-fileicon cs-neutral-100">
                  <span className="cs-icon cs-neutral-10">
                    <CapsyncIcon title="file-icon-outlined" size="16" />
                  </span>
                </div>
                <div className="cs-files-names">
                  <span className="download-filename cs-regular-sub-heading-s cs-neutral-100">
                    {file.name}
                  </span>
                </div>
                <div className="cs-file-icon icons" onClick={() => removeFile(file.name)}>
                  <span className="cs-icon-badge-danger cs-danger cursor-pointer">
                    <CapsyncIcon title="delete-outlined" size="16" />
                  </span>
                </div>
              </div>
            );
          })}
      </div>
    </aside>
  );
};

// PROPS TYPE
UploadFileList.propTypes = {
  files: PropTypes.array,
  setFiles: PropTypes.func
};

export default UploadFileList;
