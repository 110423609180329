let title = '';

/* ============================== SUB METHODS TITLES ============================== */
export const subMethodsPageTitle = (type) => {
  switch (type) {
    case 1:
      title = 'Income';
      break;
    case 2:
      title = 'Issuer Insider';
      break;
    case 3:
      title = 'Net Worth';
      break;
    case 4:
      title = 'Bank';
      break;
    case 5:
      title = "Gov't Employee plan $5M+";
      break;
    case 6:
      title = 'Private BDC';
      break;
    case 7:
      title = 'Assets $5M+';
      break;
    case 8:
      title = 'Trust Assets';
      break;
    case 9:
      title = 'All Owners Accredited';
      break;
    case 10:
      title = 'ERISA Plan';
      break;
    case 13:
      title = '3rd Party Letter';
      break;
    case 14:
      title = '3rd Party Letter';
      break;
    case 15:
      title = 'Special Situation';
      break;
    case 16:
      title = 'Special Situation';
      break;
    case 17:
      title = 'Income';
      break;
    case 18:
      title = 'Issuer Insider';
      break;
    case 19:
      title = 'Net Worth';
      break;
    case 20:
      title = '3rd Party Letter';
      break;
    case 21:
      title = 'Special Situation';
      break;
    case 22:
      title = '3rd Party Letter';
      break;
    case 23:
      title = 'Trust Assets';
      break;
    case 24:
      title = 'All Owners Accredited';
      break;
    case 25:
      title = 'Special Situation';
      break;
    case 27:
      title = '$1M+ Management Assets';
      break;
    case 28:
      title = 'Net Worth';
      break;
    case 29:
      title = 'QP';
      break;
    case 30:
      title = 'IA';
      break;
    case 31:
      title = '12 months IA Employee';
      break;
    case 32:
      title = '$5M+ Investments Individual';
      break;
    case 33:
      title = '$5M+ Investments Entity';
      break;
    case 34:
      title = 'QP Trust';
      break;
    case 35:
      title = '$25M+ Investments Management';
      break;
    case 36:
      title = 'Entity with all owners QP';
      break;
    case 37:
      title = 'License Holders';
      break;
    case 38:
      title = 'Knowledgeable Employees';
      break;
    case 39:
      title = 'Family Offices';
      break;
    case 40:
      title = '$5M investments';
      break;
    case 41:
      title = 'Knowledgeable Employees';
      break;
    case 42:
      title = 'License Holders';
      break;
  }
  return {
    title
  };
};
