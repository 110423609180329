import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';
import DatePicker from 'react-date-picker';

// COMPONENT IMPORTS
import { addTimePeriod } from '../../utils/common';
import CapsyncIcon from '../CapsyncIcon';

/* ============================== MATURITY DATE ============================== */
const MaturityDate = ({ values, setFieldValue, errors }) => {
  return (
    <Form.Group className="cs-form-group cs-disabled">
      <Form.Label>Maturity Date</Form.Label>
      <DatePicker
        dayPlaceholder="DD"
        monthPlaceholder="MM"
        yearPlaceholder="YYYY"
        name="maturity_date"
        value={
          values.date_loan && values.loan_period && values.frequency
            ? addTimePeriod(values.date_loan, values.loan_period, values.frequency)
            : ''
        }
        onChange={(e) => {
          setFieldValue('maturity_date', e);
        }}
        disabled={true}
        clearIcon={null}
        className={
          values.date_updated
            ? 'cs-input-field-active select-date-range cs-date-picker-calender'
            : 'cs-date-picker-calender'
        }
        calendarIcon={
          <span className="cs-neutral-90">
            <CapsyncIcon title="calender-outlined" size="18" />
          </span>
        }
        format="MM/dd/y"
      />
      {errors.maturity_date ? (
        <span className="form-error-msg default-light-body-text-s cs-danger">
          {errors.maturity_date}
        </span>
      ) : null}
    </Form.Group>
  );
};

// PROPS TYPE
MaturityDate.propTypes = {
  values: PropTypes.object,
  errors: PropTypes.object,
  setFieldValue: PropTypes.func
};

export default MaturityDate;
