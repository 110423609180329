import React from 'react';
import { ProgressBar } from 'react-bootstrap';
import PropTypes from 'prop-types';

// COMPONENTS IMPORT
import UpgradeStorageModal from '../UpgradeStorageModal';

// API
import { useStorageDetails } from '../../../slices/fileManagementSlice';

/* ============================== UPGRADE STORAGE ============================== */
const UpgradeStorage = (props) => {
  const { fileStorageModal, setFileStorageModal } = props;

  const storageDetails = useStorageDetails();

  return (
    <React.Fragment>
      <div className="cs-storage">
        <div className="storage-info">
          <div className="storage-value">
            <span className="cs-neutral-100 cs-regular-sub-heading-xs">
              {(storageDetails?.used_space / 1073741824).toFixed(2)} Used /
            </span>
            <span className="cs-neutral-70 cs-regular-sub-heading-xs">
              &nbsp; {(storageDetails?.total_space / 1073741824).toFixed(2)} GB
            </span>
          </div>
        </div>
        <div className="cs-progress-bar">
          <ProgressBar
            now={
              storageDetails?.total_space && storageDetails?.used_space
                ? (100 * (storageDetails?.used_space / 1073741824)) /
                  (storageDetails?.total_space / 1073741824)
                : 0
            }
          />
        </div>
      </div>
      <UpgradeStorageModal
        showModal={fileStorageModal}
        onCancelClick={() => setFileStorageModal(false)}
      />
    </React.Fragment>
  );
};

// PROPS TYPE
UpgradeStorage.propTypes = {
  fileStorageModal: PropTypes.bool,
  setFileStorageModal: PropTypes.any
};

export default UpgradeStorage;
