import React from 'react';
import PropTypes from 'prop-types';
import { Image } from 'react-bootstrap';

// COMPONENT IMPORTS
import Modal from '../../components/Modal';
import ErrorValidation from '../../assets/images/other/error-validation.svg';

/* ============================== UNSUPPORTED FILE MODAL ============================== */
const UnsupportedFileModal = (props) => {
  const { showModal, onCancelClick, onDownloadClick } = props;

  const body = (
    <div className="delete-modal-content">
      <Image src={ErrorValidation} alt="Error Validation" width={100} height={100} />
      <p className="cs-light-body-text-m cs-neutral-90">No Preview Available</p>
    </div>
  );

  return (
    <Modal
      title="Unsupported files"
      cancelButtonLabel="Cancel"
      saveButtonLabel="Download"
      show={showModal}
      isCloseButton={false}
      className="cs-md-modal cs-delete-modal"
      body={body}
      handleOnCancel={onCancelClick}
      handleOnSave={onDownloadClick}
      modalFooterClass="cs-modal-btn modal-footer"
    />
  );
};

// PROPS TYPE
UnsupportedFileModal.propTypes = {
  showModal: PropTypes.bool,
  onCancelClick: PropTypes.func,
  onDownloadClick: PropTypes.func
};

export default UnsupportedFileModal;
