import React from 'react';
import PropTypes from 'prop-types';
import { Button, Row, Col, Form } from 'react-bootstrap';

// COMPONENT IMPORTS
import Modal from '../../components/Modal';
import InputBox from '../../components/FormFields/InputBox';

// API
import { useToUserPermission } from '../../slices/authSlice';

/* ============================== SHARE PFS FIRST MODAL ============================== */
const SharePFSFirstModal = (props) => {
  const {
    showFirstModal,
    setShowFirstModal,
    sharePFSFirstModalSchema,
    handleCheckboxChange,
    isCheckedWealth
  } = props;
  // const pfsAccessId = 1
  const userPermission = useToUserPermission();
  const pfsAccessId = userPermission?.accessUserID || null;

  const bodyElement = () => {
    const handleCancelPFSFirstModal = () => {
      setShowFirstModal(false);
      sharePFSFirstModalSchema.resetForm();
    };

    return (
      <Form onSubmit={sharePFSFirstModalSchema.handleSubmit} className="personal-inform-form">
        <Row>
          <Col md={6}>
            <InputBox
              label="First name"
              name="first_name"
              values={sharePFSFirstModalSchema.values}
              errors={sharePFSFirstModalSchema.errors}
              touched={sharePFSFirstModalSchema.touched}
              disabled={false}
              onChange={sharePFSFirstModalSchema.handleChange}
            />
          </Col>
          <Col md={6}>
            <InputBox
              label="Last name"
              name="last_name"
              values={sharePFSFirstModalSchema.values}
              errors={sharePFSFirstModalSchema.errors}
              touched={sharePFSFirstModalSchema.touched}
              disabled={sharePFSFirstModalSchema.false}
              onChange={sharePFSFirstModalSchema.handleChange}
            />
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <InputBox
              label="Email"
              name="email"
              values={sharePFSFirstModalSchema.values}
              errors={sharePFSFirstModalSchema.errors}
              touched={sharePFSFirstModalSchema.touched}
              disabled={false}
              onChange={sharePFSFirstModalSchema.handleChange}
            />
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <Form.Group className="cs-form-group last-email-field">
              <Form.Label>Notes</Form.Label>
              <textarea
                autoComplete="off"
                type="text"
                placeholder="Optional"
                name="notes"
                value={sharePFSFirstModalSchema.values.notes}
                className={
                  sharePFSFirstModalSchema.values.notes
                    ? 'cs-input-field-active cs-textarea'
                    : 'cs-textarea'
                }
                onChange={sharePFSFirstModalSchema.handleChange}
              />
            </Form.Group>
          </Col>
        </Row>
        {pfsAccessId !== null ? (
          <div className="signature-return-box">
            <Form.Check
              type="checkbox"
              checked={isCheckedWealth}
              onChange={handleCheckboxChange}
              className="cs-form-check-box"
              id="signaturereturnbox"
              label={
                <span
                  htmlFor="signaturereturnbox"
                  className="cs-regular-body-text-m cs-neutral-90"
                  onClick={() => isCheckedWealth}>
                  Return to Me
                </span>
              }
            />
          </div>
        ) : (
          ''
        )}

        <div className="cs-modal-btn">
          <Button
            className="cs-btn-secondary md-btn cs-regular-h5"
            type="button"
            onClick={handleCancelPFSFirstModal}>
            Cancel
          </Button>

          <Button type="submit" className="cs-btn-primary md-btn cs-regular-h5">
            Review & send
          </Button>
        </div>
      </Form>
    );
  };

  return (
    <Modal
      show={showFirstModal}
      isCloseButton={false}
      className="cs-md-modal share-pfs-first-modal"
      title="Share PFS"
      body={bodyElement()}
    />
  );
};

// PROPS TYPE
SharePFSFirstModal.propTypes = {
  showFirstModal: PropTypes.bool,
  setShowFirstModal: PropTypes.func,
  sharePFSFirstModalSchema: PropTypes.object,
  handleCheckboxChange: PropTypes.func,
  isCheckedWealth: PropTypes.bool
};

export default SharePFSFirstModal;
