export const handleOwnershipValue = (event) => {
  const typedKey = event.key;
  const currentValue = event.target.value;
  // const pastedValue = event.clipboardData.getData('text/plain');
  let pastedValue;
  if (event.clipboardData) {
    pastedValue = event.clipboardData.getData('text/plain');
  } else if (window.clipboardData) {
    pastedValue = window.clipboardData.getData('Text');
  }
  if (pastedValue && pastedValue.startsWith('-')) {
    event.preventDefault();
    return;
  }
  const newValue = currentValue + pastedValue;

  if (!isNaN(newValue) && (parseFloat(newValue) > 100 || parseFloat(newValue) === 0)) {
    event.preventDefault();
  }

  if (
    (!isNaN(typedKey) && parseFloat(currentValue + typedKey) > 100) ||
    (typedKey === '0' && (currentValue === '' || currentValue === '0')) ||
    (typedKey === '.' &&
      (currentValue === '' || currentValue === '0' || currentValue.endsWith('.')))
  ) {
    event.preventDefault();
  }
};
