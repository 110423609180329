import React, { useRef, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';

// COMPONENT IMPORTS
import FileUpload from './FileUpload';
import { CropAvatar } from './CropAvatar';
import Modal from '../../../components/Modal';
import { AVATAR_LIST } from '../../../constants/config';
import CapsyncIcon from '../../../components/CapsyncIcon';
import { TOAST_MESSAGE } from '../../../constants/message';
import Avatar from '../../../components/userProfile/Avatar';

// API
import {
  getLoginUserDetails,
  getUserDetails,
  useAccessUser,
  useUserID,
  userChangeAvatar,
  userChangeProfileImage
} from '../../../slices/authSlice';

const defaultAllowedTypes = ['image/jpeg', 'image/png', 'image/jpg'];
const defaultMaxFileSize = 5 * 1024 * 1024; // 5MB

const validateFile = (file) => {
  if (file.size > defaultMaxFileSize) {
    return { status: false, message: 'This file exceeds maximum file size limit (5MB)' };
  }

  if (!defaultAllowedTypes.includes(file.type)) {
    return { status: false, message: 'Format not supported' };
  }

  return { status: true, message: '' };
};

/* ============================== UPDATE AVATAR ============================== */
const UpdateAvatar = (props) => {
  const {
    isAvatarModalOpen,
    setIsAvatarModalOpen,
    handleAvatarSelected,
    selectedAvatar,
    setSelectedAvatar,
    selectedAvatarFromDb,
    isCompleteModal = false,
    isMySettings = false
  } = props;
  const dispatch = useDispatch();

  const userId = useUserID();
  const accessUser = useAccessUser();

  const { user } = useSelector((state) => state.auth);

  const cropImgRef = useRef(null);

  const [errorMessage, setErrorMessage] = useState('');
  const [isCropImageVisible, setIsCropImageVisible] = useState(false);
  const [imageLoading, setImageLoading] = useState(false);
  const [uploadedImage, setUploadedImage] = useState('');
  const [uploadedImageName, setUploadedImageName] = useState('');
  const [initials, setInitials] = useState('');
  const [disabled, setDisabled] = useState(null);

  const trimmedSelectorAvatar =
    typeof selectedAvatar === 'string'
      ? selectedAvatar &&
        selectedAvatar?.replace(
          /(https:\/\/wl-universal-(local|dev|stage)\.s3\.amazonaws\.com|https:\/\/s3\.amazonaws\.com\/universal-clientportalx\.com)/,
          ''
        )
      : '';
  const trimmedSelectedAvatarFromDb =
    typeof selectedAvatarFromDb === 'string'
      ? selectedAvatarFromDb &&
        selectedAvatarFromDb?.replace(
          /(https:\/\/wl-universal-(local|dev|stage)\.s3\.amazonaws\.com|https:\/\/s3\.amazonaws\.com\/universal-clientportalx\.com)/,
          ''
        )
      : '';

  const onHandleFileUpload = (files) => {
    setErrorMessage('');
    const isValidFile = validateFile(files[0]);
    if (!isValidFile.status) {
      setErrorMessage(isValidFile.message);
      return false;
    }
    setUploadedImage(URL.createObjectURL(files[0]));
    setUploadedImageName(files[0]?.name);
    setIsCropImageVisible(true);
    setDisabled(false);
  };

  useEffect(() => {
    const name = user.display_name;
    if (name) {
      const nameArray = name.split(' ');
      const firstName = nameArray[0];
      const lastName = nameArray[nameArray.length - 1];
      const initials = firstName.charAt(0) + lastName.charAt(0);
      setInitials(initials.toUpperCase());
    }
    setDisabled(true);
  }, [user]);

  useEffect(() => {
    // if (isAvatarModalOpen) setSelectedAvatar(selectedAvatarFromDb);
    // else setIsCropImageVisible(false);
    if (!isAvatarModalOpen) setIsCropImageVisible(false);
  }, [isAvatarModalOpen]);

  const bodyElement = () => {
    if (isCropImageVisible) {
      return <CropAvatar img={uploadedImage} imgRef={cropImgRef} />;
    }

    const handleAvatarChange = (data) => {
      handleAvatarSelected(data);
      if (selectedAvatarFromDb === data) {
        setDisabled(true);
      } else {
        setDisabled(false);
      }
    };

    return (
      <React.Fragment>
        <FileUpload
          onHandleFileUpload={onHandleFileUpload}
          multiple={false}
          errorMessage={errorMessage}
          setErrorMessage={setErrorMessage}
          allowedTypes={['image/jpeg', 'image/png', 'image/jpg']}
          isAvatarModalOpen={isAvatarModalOpen}
        />
        <p className="cs-regular-body-text-m cs-neutral-60">
          Supports PNG, JPG and JPEG format, allowing images up to 5 MB in size.
        </p>
        <div className="cs-avatar-container">
          <h5 className="cs-regular-body-text-m cs-neutral-100">Select avatar</h5>
          <div className="cs-avatar-row">
            <div
              className={`profile ${'/users/avatar/avatar_6.png' === trimmedSelectorAvatar || ('/users/avatar/avatar_6.png' === trimmedSelectedAvatarFromDb && selectedAvatar === null) || (selectedAvatar === null && trimmedSelectedAvatarFromDb === '') ? 'selected-avatar' : ''}`}
              onClick={() => handleAvatarChange('/users/avatar/avatar_6.png')}>
              <div className="cs-avatar cs-avatar-text cs-avatar-xs">
                <span>{initials}</span>
              </div>
              <span className="cs-primary">
                <CapsyncIcon title="success-filled" size="18" />
              </span>
            </div>

            {AVATAR_LIST.map((data, index) => (
              <div
                className={`profile ${data.replace(/(https:\/\/wl-universal-(local|dev|stage)\.s3\.amazonaws\.com|https:\/\/s3\.amazonaws\.com\/universal-clientportalx\.com)/, '') === trimmedSelectorAvatar || (data.replace(/(https:\/\/wl-universal-(local|dev|stage)\.s3\.amazonaws\.com|https:\/\/s3\.amazonaws\.com\/universal-clientportalx\.com)/, '') === trimmedSelectedAvatarFromDb && selectedAvatar === null) ? 'selected-avatar' : ''}`}
                onClick={() => handleAvatarChange(data)}
                key={index}>
                {!data && (
                  <div className="cs-avatar cs-avatar-xs">
                    <div className="profile-loader cs-primary">
                      <span className="cs-common-spinner">
                        <CapsyncIcon title="loading-outlined" size="22" />
                      </span>
                    </div>
                  </div>
                )}

                <Avatar
                  isEditable={false}
                  src={data}
                  className="cs-avatar-xs cs-avatar-img"
                  isCompleteModal={isCompleteModal}
                />
                <span className="cs-primary">
                  <CapsyncIcon title="success-filled" size="18" />
                </span>
              </div>
            ))}
          </div>
        </div>
      </React.Fragment>
    );
  };

  const handleSaveAvatar = async (acceptedFile) => {
    const cropper = cropImgRef.current;
    const canvas = cropper?.getCanvas();
    const imageURL = canvas?.toDataURL();
    if (cropImgRef && cropImgRef.current) {
      // dispatch(setSavedAvatar(croppedImage));
      setImageLoading(true);
      const response = await dispatch(
        userChangeProfileImage({
          user_id: isMySettings ? accessUser?.loginUserDetails?.id : user.id,
          image_data: imageURL,
          image_name: uploadedImageName
        })
      ).unwrap();
      if (response.code === 200) {
        if (isMySettings) {
          dispatch(getLoginUserDetails(accessUser?.loginUserDetails?.id));
        } else {
          dispatch(getUserDetails(userId));
        }
        toast.success(TOAST_MESSAGE.PROFILE_IMAGE_SUCCESS);
        setSelectedAvatar(null);
        setImageLoading(false);
      }
    } else {
      const relativeAvatarPath =
        selectedAvatar === '/users/avatar/avatar_6.png'
          ? 'users/avatar/avatar_6.png'
          : selectedAvatar.replace(
              /(https:\/\/wl-universal-(local|dev|stage)\.s3\.amazonaws\.com\/|https:\/\/s3\.amazonaws\.com\/universal-clientportalx\.com\/)/,
              ''
            );

      // dispatch(setSavedAvatar(selectedAvatar));
      // dispatch(
      //   // setSavedAvatar(
      //   //   user && user.user_personal_details && user.user_personal_details.profile_image_url
      //   // )
      //   setSavedAvatar(
      //     user &&
      //       user.user_personal_details &&
      //       `https://${user.user_personal_details.profile_image_url}`
      //   )
      // );
      dispatch(
        userChangeAvatar({
          user_id: isMySettings ? accessUser?.loginUserDetails?.id : user.id,
          avatar_name: relativeAvatarPath
        })
      )
        .unwrap()
        .then((response) => {
          if (response.code === 200) {
            if (isMySettings) {
              dispatch(getLoginUserDetails(accessUser?.loginUserDetails?.id));
            } else {
              dispatch(getUserDetails(userId));
            }
            toast.success(TOAST_MESSAGE.PROFILE_IMAGE_SUCCESS);
            setSelectedAvatar(null);
          }
        })
        .catch((err) => {
          console.log('err', err);
        });
    }
    setIsAvatarModalOpen(false);
  };

  const handleCloseAvatar = () => {
    setIsAvatarModalOpen(false);
    setSelectedAvatar(null);
    setDisabled(true);
  };

  return (
    <Modal
      show={isAvatarModalOpen}
      isCloseButton={false}
      parentClassName="cs-common-modal-overlay"
      className="cs-md-modal cs-profile-update"
      handleClose={() => console.log('called')}
      title={
        isCropImageVisible && isCropImageVisible ? 'Crop Profile Picture' : 'Edit profile picture'
      }
      body={bodyElement()}
      loading={imageLoading}
      saveButtonLabel="Save"
      cancelButtonLabel="Cancel"
      handleOnCancel={handleCloseAvatar}
      handleOnSave={handleSaveAvatar}
      disabled={disabled}
    />
  );
};

// PROPS TYPE
UpdateAvatar.propTypes = {
  isAvatarModalOpen: PropTypes.bool,
  setIsAvatarModalOpen: PropTypes.func,
  handleAvatarSelected: PropTypes.func,
  selectedAvatar: PropTypes.string,
  setSelectedAvatar: PropTypes.func,
  selectedAvatarFromDb: PropTypes.string,
  isCompleteModal: PropTypes.bool,
  isMySettings: PropTypes.bool
};

export default UpdateAvatar;
