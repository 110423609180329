import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Col, Row, Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

// COMPONENT IMPORTS
import { MFA_OPTIONS_DATA, MFA_VERIFICATION_DATA, MFA_TYPES } from '../constants/system';
import useRemoveServerError from '../components/hooks/useRemoveServerError';
import MFAOption from '../components/MFASetup/MFAOptios';
import { localObjectClear } from '../utils/system';
import AuthHead from './AuthHead';

// HOOKS IMPORTS
import useAuthRedirection from '../utils/Hooks/useAuthRedirection';

// API
import { authUserLogout, generateMFAOTP } from '../slices/authSlice';
import { setMFA } from '../slices/mfaSlice';

/* ============================== MFA SETUP PAGE ============================== */
const MFASetUp = (props) => {
  const navigation = useNavigate();
  const dispatch = useDispatch();

  const { user } = useSelector((state) => state.auth);

  useRemoveServerError();
  useAuthRedirection();

  const [selectedType, setSelectedType] = useState(null);

  const handleRedirect = async (type) => {
    dispatch(setMFA({ type: type }));
    if (type === MFA_TYPES.AUTHENTICATOR && user && user.users_mfa_info.mfa_google === false) {
      await dispatch(
        generateMFAOTP({ mfaType: MFA_TYPES.AUTHENTICATOR, payload: { user_id: user && user.id } })
      );
      await navigation('/authenticator-setup');
    } else if (type === MFA_TYPES.AUTHENTICATOR) {
      await navigation('/verification-pin');
    } else if (type === MFA_TYPES.EMAIL) {
      await dispatch(
        generateMFAOTP({ mfaType: MFA_TYPES.EMAIL, payload: { user_id: user && user.id } })
      );
      await navigation('/verification-pin');
    } else if (type === MFA_TYPES.PHONE) {
      await dispatch(
        generateMFAOTP({ mfaType: MFA_TYPES.PHONE, payload: { user_id: user && user.id } })
      );
      await navigation('/verification-pin');
    }
  };

  const handleLogoutUser = () => {
    dispatch(authUserLogout());
    navigation('/');
    localObjectClear();
  };

  const MFA_OPTIONS_ARRAY =
    user.first_sign_in_steps === 1 ? MFA_VERIFICATION_DATA : MFA_OPTIONS_DATA;

  return (
    <React.Fragment>
      <AuthHead
        title="Multi-factor authentication"
        description="Choose your preferred authentication method"
      />
      <div className="cs-auth-form">
        <div className="mfa-row">
          <Row>
            {MFA_OPTIONS_ARRAY.map((option, index) => (
              <MFAOption
                key={index}
                {...option}
                callback={handleRedirect}
                selectedType={selectedType}
                setSelectedType={setSelectedType}
                delay={100}
              />
            ))}
          </Row>
          <Row>
            <Col>
              <span className="back-to-sign-in">
                <Button className="cs-btn-tertiary lg-btn cs-regular-h5" onClick={handleLogoutUser}>
                  Back to sign in
                </Button>
              </span>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};

export default MFASetUp;
