import React from 'react';
import PropTypes from 'prop-types';
import { Button, Form, Row, Col } from 'react-bootstrap';

// COMPONENT IMPORTS
import Modal from '../../components/Modal';
import InputBox from '../../components/FormFields/InputBox';

/* ============================== SHARE COMPLETED PFS MODAL ============================== */
const ShareCompletedPFSModal = (props) => {
  const { showShareModal, setShowShareModal, sharePFSModalSchema } = props;

  const bodyElement = () => {
    const handleCancelPFSModal = () => {
      setShowShareModal(false);
      sharePFSModalSchema.resetForm();
    };

    return (
      <Form onSubmit={sharePFSModalSchema.handleSubmit} className="personal-inform-form">
        <Row>
          <Col md={6}>
            <InputBox
              label="First name"
              name="first_name"
              values={sharePFSModalSchema.values}
              errors={sharePFSModalSchema.errors}
              touched={sharePFSModalSchema.touched}
              disabled={false}
              onChange={sharePFSModalSchema.handleChange}
            />
          </Col>
          <Col md={6}>
            <InputBox
              label="Last name"
              name="last_name"
              values={sharePFSModalSchema.values}
              errors={sharePFSModalSchema.errors}
              touched={sharePFSModalSchema.touched}
              disabled={sharePFSModalSchema.false}
              onChange={sharePFSModalSchema.handleChange}
            />
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <InputBox
              label="Email"
              name="email"
              values={sharePFSModalSchema.values}
              errors={sharePFSModalSchema.errors}
              touched={sharePFSModalSchema.touched}
              disabled={false}
              onChange={sharePFSModalSchema.handleChange}
            />
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <Form.Group className="cs-form-group last-email-field">
              <Form.Label>Notes</Form.Label>
              <textarea
                autoComplete="off"
                type="text"
                placeholder="Optional"
                name="notes"
                value={sharePFSModalSchema.values.notes}
                className={
                  sharePFSModalSchema.values.notes
                    ? 'cs-input-field-active cs-textarea'
                    : 'cs-textarea'
                }
                onChange={sharePFSModalSchema.handleChange}
              />
            </Form.Group>
          </Col>
        </Row>

        <div className="cs-modal-btn">
          <Button
            className="cs-btn-secondary md-btn cs-regular-h5"
            type="button"
            onClick={handleCancelPFSModal}>
            Cancel
          </Button>

          <Button type="submit" className="cs-btn-primary md-btn cs-regular-h5">
            Review & send
          </Button>
        </div>
      </Form>
    );
  };

  return (
    <Modal
      show={showShareModal}
      isCloseButton={false}
      className="cs-md-modal"
      title="Share PFS"
      body={bodyElement()}
    />
  );
};

// PROPS TYPE
ShareCompletedPFSModal.propTypes = {
  showShareModal: PropTypes.bool,
  setShowShareModal: PropTypes.func,
  sharePFSModalSchema: PropTypes.object
};

export default ShareCompletedPFSModal;
