import { createSlice } from '@reduxjs/toolkit';

// INITIAL STATE
const initialState = {
  isSidebarOpen: window.innerWidth < 992 ? false : true,
  activeNav: '/dashboard'
};

/* ============================== SIDEBAR SLICE ============================== */
const sidebarSlice = createSlice({
  name: 'sidebar',
  initialState,
  reducers: {
    resetSidebarSlice: () => initialState,
    setActiveNav: (state, { payload }) => {
      state.activeNav = payload;
    },
    toggleSidebar: (state, { payload }) => {
      state.isSidebarOpen = payload !== undefined ? payload : !state.isSidebarOpen;
    }
  },
  extraReducers: (builder) => {}
});

export const { toggleSidebar, setActiveNav, resetSidebarSlice } = sidebarSlice.actions;
export const { reducer } = sidebarSlice;
export default sidebarSlice.reducer;
