import { createSlice } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import { useMemo } from 'react';

// INITIAL STATE
const initialState = {
  progressUploadDetails: [],
  uploadProgress: { totalFilesCount: 0, data: [] },
  downloadProgress: { totalFilesCount: 0, data: [] },
  fileUploaded: false
};

/* ============================== COMMON SLICE ============================== */
export const commonSlice = createSlice({
  name: 'common',
  initialState,
  reducers: {
    setProgressUploadDetails: (state, action) => {
      const { progressUploadDetails } = state;
      const { payload } = action;

      if (payload.isDelete) {
        const updatedData = progressUploadDetails?.filter((item) => item.id !== payload.id);
        state.progressUploadDetails = updatedData;
      } else {
        const existingIndex = progressUploadDetails?.findIndex((item) => item.id === payload.id);
        if (existingIndex !== -1) {
          const updatedData = [...progressUploadDetails];
          updatedData[existingIndex] = payload;
          state.progressUploadDetails = updatedData;
        } else {
          state.progressUploadDetails = [...progressUploadDetails, payload];
        }
      }
    },
    showUploadProgress: (state, action) => {
      const { uploadProgress } = state;
      const { payload } = action;

      if (payload.isDelete) {
        const updatedData = uploadProgress.data.filter((item) => item.id !== payload.id);
        const totalFilesCount = updatedData.reduce(
          (total, item) => total + item?.files?.length,

          0
        );

        state.uploadProgress = {
          ...uploadProgress,
          data: updatedData,
          totalFilesCount: totalFilesCount
        };
      } else {
        const existingIndex = uploadProgress.data.findIndex((item) => item?.id === payload?.id);

        const updatedUploadProgressData =
          existingIndex !== -1
            ? uploadProgress.data.map((item, index) =>
                index === existingIndex ? { ...item, progress: payload.progress } : item
              )
            : [...uploadProgress.data, payload];

        const totalFilesCount = updatedUploadProgressData.reduce(
          (total, item) => total + item?.files?.length,
          0
        );

        state.uploadProgress = {
          ...uploadProgress,
          data: updatedUploadProgressData,
          totalFilesCount: totalFilesCount
        };
      }
    },
    showDownloadProgress: (state, action) => {
      const { downloadProgress } = state;
      const { payload } = action;

      if (payload.isDelete) {
        const updatedData = downloadProgress.data.filter((item) => item.id !== payload.id);
        const totalFilesCount = updatedData.reduce(
          (total, item) => total + item.files.length,

          0
        );

        state.downloadProgress = {
          ...downloadProgress,
          data: updatedData,
          totalFilesCount: totalFilesCount
        };
      } else {
        const existingIndex = downloadProgress.data.findIndex((item) => item.id === payload.id);

        const updatedDownloadProgressData =
          existingIndex !== -1
            ? downloadProgress.data.map((item, index) =>
                index === existingIndex ? { ...item, progress: payload.progress } : item
              )
            : [...downloadProgress.data, payload];

        const totalFilesCount = updatedDownloadProgressData.reduce(
          (total, item) => total + item.files.length,
          0
        );

        state.downloadProgress = {
          ...downloadProgress,
          data: updatedDownloadProgressData,
          totalFilesCount: totalFilesCount
        };
      }
    },
    setFileUploaded: (state, action) => {
      state.fileUploaded = action.payload;
    }
  }
});

export const {
  setProgressUploadDetails,
  showUploadProgress,
  setFileUploaded,
  showDownloadProgress
} = commonSlice.actions;
export default commonSlice.reducer;

export const selectUploadProgress = (state) => state.common.uploadProgress;
export const useUploadProgress = () => {
  const uploadProgress = useSelector(selectUploadProgress);
  return useMemo(() => uploadProgress, [uploadProgress]);
};

export const selectDownloadProgress = (state) => state.common.downloadProgress;
export const useDownloadProgress = () => {
  const downloadProgress = useSelector(selectDownloadProgress);
  return useMemo(() => downloadProgress, [downloadProgress]);
};

export const selectProgressUploadDetails = (state) => state.common.progressUploadDetails;
export const useProgressUploadDetails = () => {
  const progressUploadDetails = useSelector(selectProgressUploadDetails);
  return useMemo(() => progressUploadDetails, [progressUploadDetails]);
};

export const selectFileUploaded = (state) => state.common.fileUploaded;

export const useFileUploaded = () => {
  const fileUploaded = useSelector(selectFileUploaded);
  return useMemo(() => fileUploaded, [fileUploaded]);
};
