import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Form, Row } from 'react-bootstrap';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import moment from 'moment';

// COMPONENT IMPORTS
import Modal from '../../../components/Modal';
import { TOAST_MESSAGE } from '../../../constants/message';
import InputBox from '../../../components/FormFields/InputBox';
import validationObj from '../../../validations/validationSchema';
import TextAreaBox from '../../../components/FormFields/TextAreaBox';
import FormDropdown from '../../../components/FormFields/FormDropdown';
import DatePickerBox from '../../../components/FormFields/DatePickerBox';
import InputNumberField from '../../../components/FormFields/InputNumberField';
import SaveORCancelButtons from '../../../components/Buttons/SaveORCancelButtons';
import CategoryFileUpload from '../../../components/FormFields/CategoryFileUpload';
import { preventNegativeNumbers, preventSpecialCharacters } from '../../../utils/common';

// HOOKS IMPORTS
import useTotalBalance from '../../../utils/Hooks/useTotalBalance';

// API
import {
  categoryAddRecords,
  categoryUpdateRecords,
  getAssetsCategory,
  useCategoryDetail
} from '../../../slices/categorySlice';
import { getAssetsDashboard, getMainDashboard } from '../../../slices/dashboardSlice';
import { useProgressUploadDetails } from '../../../slices/commonSlice';
import { useStorageDetails } from '../../../slices/fileManagementSlice';

/* ============================== OTHER RETIREMENT ============================== */
const OtherRetirement = ({ isOpen = false, handleCancel, handleCategoryFormSubmit, record }) => {
  const dispatch = useDispatch();

  const { user } = useSelector((state) => state.auth);
  const categories = useCategoryDetail();
  const progressUploadDetails = useProgressUploadDetails();
  const storageDetails = useStorageDetails();
  const categoryId = categories?.category_id;

  const [isOpenModal, setIsOpenModal] = useState(isOpen);
  const [loading, setLoading] = useState(false);
  const retireType = [
    'Individual Retirement Account (IRA)',
    'Roth IRA',
    '401(k) Plan',
    'SIMPLE 401(k) Plan',
    '403(b) Plan',
    'SIMPLE IRA Plan (Savings Incentive Match Plans for Employees)',
    'SEP Plan (Simplified Employee Pension)',
    'SARSEP Plan (Salary Reduction Simplified Employee Pension)',
    'Payroll Deduction IRA',
    'Profit-Sharing Plan',
    'Defined Benefit Plan',
    'Money Purchase Plan',
    'Employee Stock Ownership Plan (ESOPs)',
    'Governmental Plan',
    '457 Plan',
    'Other'
  ];

  const [initialValues, setInitialValues] = useState({
    user_id: user?.id,
    institution: '',
    balance: '',
    loan_on_balance: '0',
    date_updated: moment().format(),
    type: 'Individual Retirement Account (IRA)',
    notes: '',
    attachments: []
  });
  useEffect(() => {
    if (record) {
      setInitialValues({
        user_id: record?.user_id,
        institution: record?.institution,
        balance: record?.balance && record?.balance.toLocaleString('en-US'),
        loan_on_balance: record?.loan_on_balance && record?.loan_on_balance.toLocaleString('en-US'),
        type: record?.type,
        date_updated: new Date(moment(record?.date_updated).format('MM/DD/YYYY')),
        notes: record?.notes == null ? '' : record?.notes
      });
    }
  }, [record]);
  const {
    values,
    handleSubmit,
    handleChange,
    setFieldValue,
    errors,
    touched,
    setFieldError,
    resetForm
  } = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: validationObj.assetRetirementAccountValidationSchema,
    onSubmit: async (values) => {
      const formData = new FormData();
      const uploadedData = storageDetails?.used_space;
      const inProgressData = progressUploadDetails.length
        ? progressUploadDetails.reduce((acc, item) => acc + item.size, 0)
        : 0;

      const { attachments, ...dataObj } = values;
      !record && attachments.forEach((file) => formData.append('attachments', file));
      formData.append('data_obj', JSON.stringify(dataObj));
      formData.append('usedSpace', uploadedData + inProgressData);

      const data = {
        category: 10,
        mainCategory: 'assets',
        formData: formData
      };
      setLoading(true);
      if (!record) {
        dispatch(categoryAddRecords(data))
          .unwrap()
          .then(async (response) => {
            if (response.code === 500) {
              setFieldError('attachments', response?.message);
            }
            if (response.code === 200) {
              setIsOpenModal(false);
              handleCategoryFormSubmit();
              dispatch(getMainDashboard(user?.id));
              const payload = { categoryId: categoryId, userId: user?.id };
              categories.parent === 'assets' &&
                (await dispatch(getAssetsCategory(payload)).unwrap());
              await dispatch(getAssetsDashboard({ userId: user?.id, orderId: 0 })).unwrap();
            }
          })
          .catch((err) => {})
          .finally(() => {
            setLoading(false);
          });
      } else {
        dispatch(
          categoryUpdateRecords({
            category: 10,
            mainCategory: 'assets',
            rowId: record && record?.id,
            values
          })
        )
          .unwrap()
          .then(async (response) => {
            if (response.code === 200) {
              setIsOpenModal(false);
              handleCategoryFormSubmit();
              toast.success(TOAST_MESSAGE.RECORD_UPDATE_SUCCESS);
              const payload = { categoryId: categoryId, userId: user?.id };
              categories.parent === 'assets' &&
                (await dispatch(getAssetsCategory(payload)).unwrap());
              await dispatch(getMainDashboard(user?.id)).unwrap();
            }
          })
          .catch((err) => {})
          .finally(() => {
            setLoading(false);
          });
      }
    }
  });

  useTotalBalance({ values, setFieldValue });
  const body = (
    <Form onSubmit={handleSubmit} encType="multipart/form-data">
      <Row>
        <Col lg={12} sm={12}>
          <InputBox
            label={'Institution'}
            name={'institution'}
            values={values}
            onChange={handleChange}
            errors={errors}
            touched={touched}
            type={'text'}
            onKeyDown={preventSpecialCharacters}
            disabled={record && record.is_manual === false}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={6}>
          <div className={'cs-common-add-dropdown'}>
            <FormDropdown
              values={values}
              name={'type'}
              list={retireType}
              setFieldValue={setFieldValue}
              label={'Type'}
              disabled={record && record.is_manual === false}
            />
          </div>
        </Col>
        <Col xs={12} md={6}>
          <InputNumberField
            label="Balance"
            name="balance"
            values={values}
            errors={errors}
            handleChange={handleChange}
            touched={touched}
            customActiveClass="dollar-symbol text-md-end form-control"
            onPaste={preventNegativeNumbers}
            thousandSeparator={true}
            disabled={record && record.is_manual === false}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={6}>
          <InputNumberField
            label="Loan on Balance"
            name="loan_on_balance"
            values={values}
            errors={errors}
            handleChange={handleChange}
            touched={touched}
            customActiveClass="dollar-symbol text-md-end form-control"
            onPaste={preventNegativeNumbers}
            thousandSeparator={true}
          />
        </Col>
        <Col xs={12} md={6}>
          <InputNumberField
            label="Total Balance"
            name="total_balance"
            values={values}
            errors={errors}
            handleChange={handleChange}
            touched={touched}
            customActiveClass="dollar-symbol text-md-end form-control"
            onPaste={preventNegativeNumbers}
            thousandSeparator={true}
            disabled={true}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={6}>
          <DatePickerBox
            label={'Date Updated'}
            errors={errors}
            name={'date_updated'}
            values={values}
            setFieldValue={setFieldValue}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={12}>
          <TextAreaBox
            handleChange={handleChange}
            name={'notes'}
            label={'Notes'}
            values={values}
            errors={errors}
            onChange={handleChange}
            placeholder={'Optional'}
          />
        </Col>
      </Row>

      {!record && (
        <CategoryFileUpload
          name={'attachments'}
          values={values}
          errors={errors}
          setFieldError={setFieldError}
          setFieldValue={setFieldValue}
        />
      )}

      <SaveORCancelButtons handleCancel={handleCancel} resetForm={resetForm} loading={loading} />
    </Form>
  );

  return (
    <Modal
      show={isOpenModal}
      isCloseButton={false}
      title={'IRA or Other Retirement Account'}
      body={body}
    />
  );
};

// PROPS TYPE
OtherRetirement.propTypes = {
  isOpen: PropTypes.bool,
  handleCancel: PropTypes.func,
  handleCategoryFormSubmit: PropTypes.func,
  record: PropTypes.object
};

export default OtherRetirement;
