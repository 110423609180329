import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';
import NumberFormat from 'react-currency-format';

/* ============================== INPUT NUMBER ============================== */
const InputNumberField = ({
  label,
  name,
  values,
  handleChange,
  errors,
  touched,
  optional = false,
  customActiveClass = '',
  maxLength = 12,
  disabled = false,
  placeholder,
  loanValidation = false,
  loanAmountError,
  setLoanAmountError,
  customActiveClassApr,
  ...props
}) => {
  // const dollarSymbolLabels = ['Payment', 'Monthly Payment', 'Loan on Balance'];
  // const hasDollarSymbol = dollarSymbolLabels.includes(label);

  const shouldAddDollarSymbol =
    values[name] &&
    values[name] !== '0' &&
    !['ownership', 'apr_percentage', 'annual_rate', 'loan_period'].includes(name);
  const isFieldActive = values[name] || values[name] === 0;
  const isAprActive = values[name] !== '0' && name === 'apr_percentage';
  const handleChangeWithValidation = (e) => {
    handleChange(e);

    // Perform your custom validation for loan_amount
    if (loanValidation === true) {
      const cashValue = Number(values.cash_value.replace(/,/g, ''));
      const loanAmount = Number(e.target.value.replace(/,/g, ''));

      if (loanAmount >= cashValue && e.target.value.length > 0) {
        setLoanAmountError('The loan amount should not be greater or equal to cash value.');
      } else {
        setLoanAmountError('');
      }
    }
  };
  return (
    <Form.Group className={`cs-form-group ${disabled && 'cs-disabled'}`}>
      {label && <Form.Label>{label}</Form.Label>}
      <NumberFormat
        type="text"
        autoComplete="off"
        placeholder={optional ? 'Optional' : placeholder || ''}
        name={name}
        maxLength={maxLength}
        value={values[name]}
        onChange={handleChangeWithValidation}
        className={`form-control 
                  ${shouldAddDollarSymbol ? 'dollar-symbol' : ''} 
                  ${isFieldActive ? `cs-input-field-active ${customActiveClass}` : ''} 
                  ${isAprActive ? customActiveClassApr : ''}`}
        allowNegative={false}
        decimalScale={2}
        displayType={'input'}
        disabled={disabled}
        {...props}
      />
      {errors[name] && touched[name] && (
        <span className="form-error-msg cs-light-body-text-s cs-danger">{errors[name]}</span>
      )}
      {loanAmountError && (
        <span className="form-error-msg default-light-body-text-s cs-danger">
          {loanAmountError}
        </span>
      )}
    </Form.Group>
  );
};

// PROPS TYPE
InputNumberField.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  values: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  errors: PropTypes.object,
  touched: PropTypes.object,
  optional: PropTypes.bool,
  customActiveClass: PropTypes.string,
  maxLength: PropTypes.number,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  loanValidation: PropTypes.bool,
  setLoanAmountError: PropTypes.func,
  loanAmountError: PropTypes.string,
  customActiveClassApr: PropTypes.string
};

export default InputNumberField;
