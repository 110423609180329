import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Form, FormLabel } from 'react-bootstrap';

// COMPONENT IMPORTS
import CapsyncIcon from '../CapsyncIcon';

/* ============================== INPUT ============================== */
const InputBox = ({
  className,
  label,
  name,
  values,
  errors,
  touched,
  onChange,
  onFocus,
  placeholder = '',
  type = 'text',
  disabled = false,
  status,
  onBlur,
  isSignUp = false,
  maxVal,
  inputWidth,
  required,
  fieldLabelWithTag,
  submitCount,
  showError = false,
  ...props
}) => {
  const [isPasswordShow, setIsPasswordShow] = useState(false);

  const onHandleTogglePasswordVisibility = () => {
    setIsPasswordShow(!isPasswordShow);
  };
  return (
    <Form.Group
      className={`cs-form-group ${disabled && 'cs-disabled'} ${isSignUp && 'last-password-field'} ${className}`}>
      {fieldLabelWithTag && fieldLabelWithTag}
      {props.methodField && props.fieldPrimaryLabel && (
        <FormLabel>
          <span className="cs-regular-sub-heading-m cs-neutral-90">{props.fieldPrimaryLabel}</span>{' '}
          {props.highlightedInLineLabel && (
            <span className="cs-regular-sub-heading-m cs-danger">
              {props.highlightedInLineLabel}
            </span>
          )}
          {required && <span className="cs-danger">*</span>}
        </FormLabel>
      )}
      {label && <FormLabel>{label}</FormLabel>}
      {inputWidth ? (
        <div className={inputWidth}>
          <Form.Control
            className={`${values[name] && 'cs-input-field-active'}`}
            type={isPasswordShow ? 'text' : type}
            name={name}
            value={values[name]}
            placeholder={placeholder}
            onChange={onChange}
            onBlur={onBlur}
            autoComplete="off"
            disabled={disabled}
            onFocus={onFocus}
            max={maxVal}
            {...props}
          />
        </div>
      ) : (
        <Form.Control
          className={`${values[name] && 'cs-input-field-active'}`}
          type={isPasswordShow ? 'text' : type}
          name={name}
          value={values[name]}
          placeholder={placeholder}
          onChange={onChange}
          onBlur={onBlur}
          autoComplete="off"
          disabled={disabled}
          onFocus={onFocus}
          max={maxVal}
          {...props}
        />
      )}
      {!isSignUp && errors[name] && touched[name] ? (
        <span className="form-error-msg cs-light-body-text-s cs-danger">{errors[name]}</span>
      ) : !isSignUp && showError && errors[name] ? (
        <span className="form-error-msg cs-light-body-text-s cs-danger">{errors[name]}</span>
      ) : submitCount && submitCount > 0 && !values[name] ? (
        <span className="form-error-msg cs-light-body-text-s cs-danger">{errors[name]}</span>
      ) : null}

      {status && status[name] && !errors[name] && (
        <span className="form-error-msg cs-light-body-text-s cs-danger"> {status[name]} </span>
      )}

      {type === 'password' && (
        <span
          className="input-field-icon cursor-pointer cs-neutral-80"
          onClick={onHandleTogglePasswordVisibility}
          onMouseDown={(e) => e.preventDefault()}>
          <CapsyncIcon
            title={isPasswordShow ? 'show-password-outlined' : 'hide-password-outlined'}
            size="18"
          />
        </span>
      )}
    </Form.Group>
  );
};

// PROPS TYPE
InputBox.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string,
  values: PropTypes.object,
  errors: PropTypes.object,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  placeholder: PropTypes.string,
  type: PropTypes.oneOf(['text', 'email', 'password']),
  label: PropTypes.string,
  touched: PropTypes.object,
  disabled: PropTypes.bool,
  status: PropTypes.object,
  isSignUp: PropTypes.bool,
  methodField: PropTypes.bool,
  fieldPrimaryLabel: PropTypes.string,
  highlightedInLineLabel: PropTypes.string,
  maxVal: PropTypes.string,
  inputWidth: PropTypes.string,
  required: PropTypes.bool,
  fieldLabelWithTag: PropTypes.object,
  submitCount: PropTypes.number,
  showError: PropTypes.bool
};

export default InputBox;
