import React from 'react';
import { Link } from 'react-router-dom';
import NumberFormat from 'react-currency-format';
import { useSelector } from 'react-redux';

// COMPONENT IMPORTS
import CapsyncToolTip from '../../../components/CapsyncToolTip';

let formJSON = [];

/* ============================== SUB METHODS FROM ============================== */
export const subMethodFormJSON = (methodID, labelstate, setLabelState, error, value, loader) => {
  const { calculatedNetWorth } = useSelector((state) => state.dashboard);
  const legalNameDesc = {
    contentType: 'description',
    content: (
      <div className="accreditation-common-tooltip">
        <p>
          <div className="cs-regular-body-text-l cs-neutral-70">
            Please enter or confirm the legal name of the person or entity being verified. The name
            entered here will be referenced in the verification letter and generally should match
            how you intend to invest.
          </div>
          <div className="cs-regular-body-text-l cs-danger">
            Do not enter your own personal name if you are investing as a joint couple, trust, or
            entity. WARNING: Under certain cases, there will be a $35 charge to change the legal
            name post-review.
          </div>
        </p>
        <CapsyncToolTip
          Child={<u className="cs-regular-body-text-l cs-neutral-90">See here for examples.</u>}
          placement={window.innerWidth <= 767 ? 'top' : 'right'}
          width="468px"
          message={
            <div>
              If investing as:
              <ul className="text-start">
                <li>An individual, enter your first and last name (e.g. John Doe).</li>
                <li>
                  A married couple, enter the first and last names of each person (e.g. John Doe
                  &amp; Jane Doe).
                </li>
                <li>
                  A trust, enter the full legal name of the trust (e.g. Smith Living Trust or John
                  Doe, Trustee for Doe Living Trust).
                </li>
                <li>
                  A company, enter the full legal name of the company name (e.g. ABC Company, LLC or
                  XYZ Company, Inc.)
                </li>
                <li>An IRA that is an LLC, enter the full legal name of the LLC.</li>
                <li>An IRA that is not a LLC, enter the full legal name of the IRA.</li>
              </ul>
            </div>
          }
          type="text"
        />
      </div>
    ),
    fieldVisiblity: true
  };

  switch (methodID) {
    case 1:
      formJSON = [
        {
          contentType: 'description',
          content: (
            <div className="row-spacing">
              <p className="cs-regular-body-text-l cs-neutral-70">
                A natural person with income exceeding $200,000 in each of the two most recent years
                or joint income with that person's spouse or{' '}
                <CapsyncToolTip
                  Child={<u>spousal equivalent</u>}
                  placement={'top'}
                  message={
                    'A cohabitant occupying a relationship generally equivalent to that of a spouse.'
                  }
                  type="text"
                />{' '}
                exceeding $300,000 for those years and has a reasonable expectation of reaching the
                same income level in the current year.
              </p>
            </div>
          ),
          fieldVisiblity: true
        },
        {
          contentType: 'divider',
          content: <div className="cs-divider"></div>,
          fieldVisiblity: true
        },
        legalNameDesc,
        {
          contentType: 'form',
          methodField: true,
          field: 'legal_name',
          fieldID: 'legal_name',
          fieldPrimaryLabel: 'Legal Name',
          highlightedInLineLabel: 'Please click in the box below to edit the name as needed.',
          disabled: loader,
          fieldType: 'input-text',
          fieldSizeLG: 5,
          fieldVisiblity: true
        },
        {
          contentType: 'divider',
          content: <div className="cs-divider"></div>,
          fieldVisiblity: true
        },
        {
          contentType: 'description',
          content: (
            <div className="row-spacing">
              <p className="cs-regular-body-text-l cs-neutral-70">
                In order to verify your accreditation we need you to upload evidence. Please upload
                the appropriate files below and black-out any sensitive information.
              </p>
            </div>
          ),
          fieldVisiblity: true
        },
        {
          contentType: 'description',
          content: (
            <div className="row-spacing">
              <p className="cs-regular-body-text-l cs-danger">
                Upon a successful verification, the expiration date of the verification letter will
                be based on the evidence provided to reviewers, e.g. if a 3rd party letter or a
                monthly statement is 2 months old at the time of submission for review the
                verification letter will expire in 1 month.
              </p>
            </div>
          ),
          fieldVisiblity: true
        },
        {
          contentType: 'description',
          content: (
            <div className="row-spacing">
              <p className="cs-regular-body-text-l cs-neutral-90">
                <span className="cs-neutral-90">
                  Upload the past two years' W-2, K-1, 1099 tax forms or the past two years' tax
                  returns (just the first two pages will do).
                </span>{' '}
                <span className="cs-neutral-70">
                  If you don't have these forms, you can upload other evidence below.
                </span>
              </p>
            </div>
          ),
          fieldVisiblity: true
        },
        {
          contentType: 'form',
          methodField: true,
          field: 'terms_1',
          fieldID: 'terms_1',
          fieldPrimaryLabel: (
            <span className="cs-regular-sub-heading-m cs-neutral-90">
              The investor is presenting information showing joint income with a spouse or spousal
              equivalent.
            </span>
          ),
          disabled: loader,
          fieldType: 'input-checkbox',
          additionalClass: 'checkbox-spacing',
          fieldSizeLG: 12,
          fieldVisiblity: true
        },
        {
          contentType: 'form',
          methodField: true,
          field: 'upload_drop_box_1',
          fieldID: 'upload_drop_box_1',
          disabled: loader,
          fieldType: 'input-uploadfile',
          fieldSizeLG: 12,
          fieldVisiblity: true,
          dropBoxId: 1
        },
        {
          contentType: 'form',
          methodField: true,
          field: 'upload_drop_box_2',
          fieldID: 'upload_drop_box_2',
          fieldPrimaryLabel: 'Upload other evidence:',
          disabled: loader,
          fieldType: 'input-uploadfile',
          fieldSizeLG: 12,
          fieldVisiblity: true,
          dropBoxId: 2
        },
        {
          contentType: 'form',
          methodField: true,
          field: 'terms_2',
          fieldID: 'terms_2',
          fieldPrimaryLabel: (
            <span className="cs-regular-sub-heading-m cs-neutral-90">
              I (or my spouse/spousal equivalent and I, as applicable) have a reasonable expectation
              of reaching the income level necessary to qualify as an accredited investor during the
              current year.
            </span>
          ),
          disabled: loader,
          fieldType: 'input-checkbox',
          additionalClass: 'checkbox-spacing',
          fieldSizeLG: 12,
          fieldVisiblity: true
        },
        {
          contentType: 'description',
          content: (
            <div className="row-spacing">
              <div className="cs-regular-body-text-l cs-neutral-90">
                Please enter the amount below if your previous year's income evidence is not yet
                available:
              </div>
              <div className="cs-regular-body-text-l cs-neutral-70">
                An Internal Revenue Service form that reports my(our) income for the recently
                completed year is not available. The amount of income I(we) received for the
                previous year is
              </div>
            </div>
          ),
          fieldVisiblity: true
        },
        {
          contentType: 'form',
          methodField: true,
          field: 'income_amount',
          fieldID: 'income_amount',
          disabled: loader,
          fieldType: 'input-number',
          fieldPlaceholder: '$ 0.00 USD',
          fieldSizeLG: 5,
          fieldVisiblity: true,
          maxVal: 8
        },
        {
          contentType: 'description',
          content: (
            <div className="row-spacing">
              <div className="cs-regular-body-text-l cs-neutral-90">
                Type or edit a note, comment, or response to the reviewer here (Optional):
              </div>
              <div className="cs-regular-body-text-l cs-neutral-70">
                <i>
                  Kindly note that for audit/compliance reasons, all communication between yourself
                  and your reviewing attorney can only be done by entering any questions or comments
                  in the box below. Note that this is optional.
                </i>
              </div>
            </div>
          ),
          fieldVisiblity: true
        },
        {
          contentType: 'form',
          methodField: true,
          field: 'notes',
          fieldID: 'notes',
          disabled: loader,
          fieldType: 'input-textarea',
          fieldPlaceholder: 'Enter your Note',
          fieldSizeLG: 12,
          fieldVisiblity: true
        }
      ];
      break;

    case 2:
      formJSON = [
        {
          contentType: 'description',
          content: (
            <div className="row-spacing">
              <p className="cs-regular-body-text-l cs-neutral-70">
                A director, executive officer, or general partner of the issuer of the securities
                being offered or sold, or a director, executive officer, or general partner of a
                general partner of that issuer.
              </p>
            </div>
          ),
          fieldVisiblity: true
        },
        {
          contentType: 'divider',
          content: <div className="cs-divider"></div>,
          fieldVisiblity: true
        },
        legalNameDesc,
        {
          contentType: 'form',
          methodField: true,
          field: 'legal_name',
          fieldID: 'legal_name',
          fieldPrimaryLabel: 'Legal Name',
          highlightedInLineLabel: 'Please click in the box below to edit the name as needed.',
          disabled: loader,
          fieldType: 'input-text',
          fieldSizeLG: 5,
          fieldVisiblity: true
        },
        {
          contentType: 'divider',
          content: <div className="cs-divider"></div>,
          fieldVisiblity: true
        },
        {
          contentType: 'description',
          content: (
            <div className="row-spacing">
              <p className="cs-regular-body-text-l cs-neutral-70">
                In order to verify your accreditation we need you to upload evidence. Please upload
                the appropriate files below and black-out any sensitive information.
              </p>
            </div>
          ),
          fieldVisiblity: true
        },
        {
          contentType: 'description',
          content: (
            <div className="row-spacing">
              <p className="cs-medium-sub-heading-m cs-danger">
                Upon a successful verification, the expiration date of the verification letter will
                be based on the evidence provided to reviewers, e.g. if a 3rd party letter or a
                monthly statement is 2 months old at the time of submission for review the
                verification letter will expire in 1 month.
              </p>
            </div>
          ),
          fieldVisiblity: true
        },
        {
          contentType: 'form',
          methodField: true,
          field: 'upload_drop_box_1',
          fieldID: 'upload_drop_box_1',
          fieldLabelWithTag: (
            <span className="cs-regular-sub-heading-m cs-neutral-90">
              Upload evidence of position claimed:{' '}
              <span className="cs-regular-sub-heading-m cs-neutral-70 cs-eg">
                &nbsp;(e.g. Incumbency Certificate or Officer's Certificate no more than 90 days
                old) — Sample Officer's Certificate{' '}
                <Link
                  className="cs-neutral-100"
                  to={
                    'https://s3.amazonaws.com/static-clientportalx.com/Verify-Investor-files/OfficerCertificate-including-KE-and-FO.pdf'
                  }
                  target="_blank">
                  Here
                </Link>
              </span>
            </span>
          ),
          disabled: loader,
          fieldType: 'input-uploadfile',
          fieldSizeLG: 12,
          fieldVisiblity: true,
          dropBoxId: 1
        },
        {
          contentType: 'form',
          methodField: true,
          field: 'officers_investment_or_deal_name',
          fieldID: 'officers_investment_or_deal_name',
          disabled: loader,
          fieldType: 'input-text',
          fieldPrimaryLabel:
            'Please enter the investment name or deal name of the company of which you are an officer. (Note the name you entered will appear on your accreditation letter and the letter will be solely for the purpose of this investment.)',
          fieldSizeLG: 12,
          fieldVisiblity: true,
          inputWidth: 'col-lg-5',
          maxVal: 50
        },
        {
          contentType: 'description',
          content: (
            <div className="row-spacing">
              <span className="cs-regular-body-text-l cs-neutral-90 ">
                Type or edit a note, comment, or response to the reviewer here (Optional):
              </span>
              <p className="cs-regular-body-text-l cs-neutral-70">
                <i>
                  Kindly note that for audit/compliance reasons, all communication between yourself
                  and your reviewing attorney can only be done by entering any questions or comments
                  in the box below. Note that this is optional.
                </i>
              </p>
            </div>
          ),
          fieldVisiblity: true
        },
        {
          contentType: 'form',
          methodField: true,
          field: 'notes',
          fieldID: 'notes',
          disabled: loader,
          fieldType: 'input-textarea',
          fieldPlaceholder: 'Enter your Note',
          fieldSizeLG: 12,
          fieldVisiblity: true
        }
      ];
      break;

    case 3:
      formJSON = [
        {
          contentType: 'description',
          content: (
            <div className="row-spacing">
              <p className="cs-regular-body-text-l cs-neutral-70">
                Any natural person whose individual net worth, or joint net worth with that person's
                spouse or{' '}
                <CapsyncToolTip
                  Child={<u>spousal equivalent</u>}
                  placement={'top'}
                  message={
                    'A cohabitant occupying a relationship generally equivalent to that of a spouse.'
                  }
                  type="text"
                />
                , exceeds $1,000,000 USD.
              </p>
            </div>
          ),
          fieldVisiblity: true
        },
        {
          contentType: 'description',
          content: (
            <div className="cs-regular-body-text-l">
              <p className="cs-regular-body-text-l cs-neutral-70 method-spacing">
                For purposes of calculating net worth:
              </p>
              <p className="cs-regular-body-text-l cs-neutral-70">
                Joint net worth can be the aggregate net worth of the investor and spouse or spousal
                equivalent; assets need not be held jointly to be included in the calculation.
                Reliance on the joint net worth standard of this method does not require that the
                securities be purchased jointly.
              </p>
              <ul className="cs-neutral-90">
                <li className="cs-regular-body-text-l cs-neutral-70">
                  The person's primary residence shall not be included as an asset;
                </li>
                <li className="cs-regular-body-text-l cs-neutral-70">
                  Indebtedness that is secured by the person's primary residence, up to the
                  estimated fair market value of the primary residence at the time of the sale of
                  securities, shall not be included as a liability (except that if the amount of
                  such indebtedness outstanding at the time of sale of securities exceeds the amount
                  outstanding 60 days before such time, other than as a result of the acquisition of
                  the primary residence, the amount of such excess shall be included as a
                  liability); and
                </li>
                <li className="cs-regular-body-text-l cs-neutral-70">
                  Indebtedness that is secured by the person's primary residence in excess of the
                  estimated fair market value of the primary residence at the time of the sale of
                  securities shall be included as a liability.
                </li>
              </ul>
            </div>
          ),
          fieldVisiblity: true
        },
        {
          contentType: 'divider',
          content: <div className="cs-divider"></div>,
          fieldVisiblity: true
        },
        legalNameDesc,
        {
          contentType: 'form',
          methodField: true,
          field: 'legal_name',
          fieldID: 'legal_name',
          fieldPrimaryLabel: 'Legal Name',
          highlightedInLineLabel: 'Please click in the box below to edit the name as needed.',
          disabled: loader,
          fieldType: 'input-text',
          fieldPlaceholder: 'Name',
          fieldSizeLG: 5,
          fieldVisiblity: true
        },
        {
          contentType: 'divider',
          content: <div className="cs-divider"></div>,
          fieldVisiblity: true
        },
        {
          contentType: 'description',
          content: (
            <div className="row-spacing">
              <p className="cs-regular-body-text-l cs-neutral-70">
                In order to verify your accreditation we need you to upload evidence. Please upload
                the appropriate files below and black-out any sensitive information.
              </p>
            </div>
          ),
          fieldVisiblity: true
        },
        {
          contentType: 'description',
          content: (
            <div className="row-spacing">
              <p className="cs-regular-body-text-l cs-neutral-70">
                All evidence (statements/certificates, etc.) must be no older than 90 days with
                limited exceptions (for example, older documents evidencing ownership, tax returns,
                etc.).
              </p>
            </div>
          ),
          fieldVisiblity: true
        },
        {
          contentType: 'description',
          content: (
            <div className="row-spacing">
              <p className="cs-regular-body-text-xl cs-neutral-90">
                Individual Net Worth Assessment
              </p>
            </div>
          ),
          fieldVisiblity: true
        },
        {
          contentType: 'divider',
          content: <div className="cs-divider"></div>,
          fieldVisiblity: true
        },
        {
          contentType: 'description',
          content: (
            <div>
              <p className="cs-regular-body-text-l cs-neutral-90 method-spacing">
                Upload Recent Credit Report
              </p>
              <p className="cs-regular-body-text-l cs-neutral-90 method-spacing">
                <CapsyncToolTip
                  width="640px"
                  Child={
                    <span className="cs-regular-body-text-l cs-neutral-70">
                      Why do I need this?{' '}
                    </span>
                  }
                  placement={'right'}
                  type="text"
                  message={
                    <div>
                      <p>
                        {' '}
                        Kindly note that investors must provide their credit report for their net
                        worth verification according to Rule 506C from Regulation D on the SEC
                        website as written below (see link here for a full description of the law).
                        If this is not provided, then, unfortunately, under US federal laws, our
                        reviewing attorneys may not be able to verify you as an accredited investor
                        through this verification method.
                      </p>{' '}
                      <br />
                      <p>
                        {' '}
                        "(B) In regard to whether the purchaser is an accredited investor on the
                        basis of net worth, reviewing one or more of the following types of
                        documentation dated within the prior three months and obtaining a written
                        representation from the purchaser that all liabilities necessary to make a
                        determination of net worth have been
                      </p>
                    </div>
                  }
                />
                <span className="cs-regular-body-text-l cs-danger">
                  This does not affect your credit score or security freezes on your credit.
                </span>
              </p>
              <p className="cs-regular-body-text-l cs-neutral-70 method-spacing">
                You may obtain your credit report from one of the following options:
              </p>
              <ol className="cs-regular-body-text-l cs-neutral-70">
                <li className="method-spacing">
                  Order one free credit report once a year from any of the credit reporting bureaus
                  from{' '}
                  <Link to="https://www.annualcreditreport.com" target="_blank">
                    <u className="cs-neutral-90">https://www.annualcreditreport.com</u>
                  </Link>
                  . On that site, we recommend requesting your report from either Equifax or
                  TransUnion where you can download a PDF copy.
                </li>
                <li>
                  Order credit report from{' '}
                  <Link
                    to="https://connect.experian.com/register/personal.html?c=ptnr-vrfyinv&sg=bs&m=lk&a=verify-investor"
                    target="_blank">
                    <u className="cs-neutral-90">Experian Connect℠</u>
                  </Link>
                  .
                </li>
              </ol>
            </div>
          ),
          fieldVisiblity: true
        },
        {
          contentType: 'form',
          methodField: true,
          field: 'upload_drop_box_1',
          fieldID: 'upload_drop_box_1',
          fieldPrimaryLabel: 'Upload your credit report below from options 1 or 2',
          disabled: loader,
          fieldType: 'input-uploadfile',
          fieldSizeLG: 12,
          fieldVisiblity: true,
          dropBoxId: 1
        },
        {
          contentType: 'description',
          content: (
            <div className="cs-regular-body-text-l">
              <p className="cs-neutral-70 method-spacing">
                <u>
                  If a US credit report exists for you, you must provide it. Most people that live
                  in the US or have assets in the US will have a US credit report. If you do not
                  have a US credit report, upload a credit report equivalent from your own country.
                  If verifying together with a spouse or spousal equivalent, both individuals must
                  provide a credit report.
                </u>
              </p>
            </div>
          ),
          fieldVisiblity: true
        },
        {
          contentType: 'form',
          methodField: true,
          field: 'certify',
          fieldID: 'certify',
          fieldPrimaryLabel: (
            <span className="cs-regular-sub-heading-m cs-neutral-90">
              I certify that no credit report is available.
            </span>
          ),
          disabled: loader,
          fieldType: 'input-checkbox',
          additionalClass: 'checkbox-spacing',
          fieldSizeLG: 12,
          fieldVisiblity: true
        },
        {
          contentType: 'divider',
          content: <div className="cs-divider"></div>,
          fieldVisiblity: true
        },
        {
          contentType: 'description',
          content: (
            <div className="row-spacing">
              <div className="net-worth-asset-libility">
                <p className="cs-regular-body-text-xl cs-neutral-90">
                  Claimed Net Worth: $
                  <NumberFormat
                    value={calculatedNetWorth.toFixed(2)}
                    displayType={'text'}
                    thousandSeparator={true}
                  />{' '}
                  <span>USD</span>
                  <span className="net-worth-info-icon">
                    <CapsyncToolTip
                      Child={'info-outlined'}
                      width="398px"
                      placement={'bottom'}
                      type="icon"
                      size="18"
                      message={'Please Refer to your portfolio for more details on net worth.'}
                    />
                  </span>
                  {error.netWorthError && error.netWorthError !== '' && (
                    <p className="cs-regular-sub-heading-xs cs-danger">{error.netWorthError}</p>
                  )}
                </p>
                <p className="cs-regular-body-text-xl cs-neutral-90">
                  Claimed Net Worth (for Verification): $
                  <NumberFormat
                    value={calculatedNetWorth.toFixed(2)}
                    displayType={'text'}
                    thousandSeparator={true}
                  />{' '}
                  <span>USD</span>
                  <span className="net-worth-info-icon">
                    <CapsyncToolTip
                      Child={'info-outlined'}
                      placement={'bottom'}
                      width="398px"
                      type="icon"
                      size="18"
                      message={'Please Refer to your portfolio for more details on net worth.'}
                    />
                  </span>
                </p>
              </div>
            </div>
          ),
          fieldVisiblity: true
        },
        {
          contentType: 'description',
          content: (
            <div className="cs-regular-body-text-l">
              <span className="cs-neutral-90">
                Type or edit a note, comment, or response to the reviewer here (Optional):
              </span>
              <p className="row-spacing cs-neutral-70">
                <i>
                  Kindly note that for audit/compliance reasons, all communication between yourself
                  and your reviewing attorney can only be done by entering any questions or comments
                  in the box below. Note that this is optional.
                </i>
              </p>
            </div>
          ),
          fieldVisiblity: true
        },
        {
          contentType: 'form',
          methodField: true,
          field: 'notes',
          fieldID: 'notes',
          disabled: loader,
          fieldType: 'input-textarea',
          fieldPlaceholder: 'Enter your Note',
          fieldSizeLG: 12,
          fieldVisiblity: true
        }
      ];
      break;

    case 4:
      formJSON = [
        {
          contentType: 'description',
          content: (
            <div className="row-spacing">
              <p className="cs-regular-body-text-l cs-neutral-70">
                Any bank as defined in section 3(a)(2) of the Securities Act of 1933 (the “Act”), or
                any savings and loan association or other institution as defined in section
                3(a)(5)(A) of the Act whether acting in its individual or fiduciary capacity; any
                broker or dealer registered pursuant to section 15 of the Securities Exchange Act of
                1934; any investment adviser registered pursuant to section 203 of the Investment
                Advisers Act of 1940 or registered pursuant to the laws of a state; any investment
                adviser relying on the exemption from registering with the Commission under section
                203(l) or (m) of the Investment Advisers Act of 1940; any insurance company as
                defined in section 2(13) of the Act; any investment company registered under the
                Investment Company Act of 1940 or a business development company as defined in
                section 2(a)(48) of that act; any Small Business Investment Company licensed by the
                U.S. Small Business Administration under section 301(c) or (d) of the Small Business
                Investment Act of 1958; any Rural Business Investment Company as defined in section
                384A of the Consolidated Farm and Rural Development Act; any plan established and
                maintained by a state, its political subdivisions, or any agency or instrumentality
                of a state or its political subdivisions, for the benefit of its employees, if such
                plan has total assets in excess of $5,000,000; any employee benefit plan within the
                meaning of the Employee Retirement Income Security Act of 1974 if the investment
                decision is made by a plan fiduciary, as defined in section 3(21) of such act, which
                is either a bank, savings and loan association, insurance company, or registered
                investment adviser, or if the employee benefit plan has total assets in excess of
                $5,000,000 or, if a self-directed plan, with investment decisions made solely by
                persons that are accredited investors.
              </p>
            </div>
          ),
          fieldVisiblity: true
        },
        {
          contentType: 'divider',
          content: <div className="cs-divider"></div>,
          fieldVisiblity: true
        },
        legalNameDesc,
        {
          contentType: 'form',
          methodField: true,
          field: 'legal_name',
          fieldID: 'legal_name',
          fieldPrimaryLabel: 'Legal Name',
          highlightedInLineLabel:
            'Changes may not be made upon completion. Please click in the box below to edit the name as needed.',
          disabled: loader,
          fieldType: 'input-text',
          fieldPlaceholder: 'Name',
          fieldSizeLG: 5,
          fieldVisiblity: true
        },
        {
          contentType: 'divider',
          content: <div className="cs-divider"></div>,
          fieldVisiblity: true
        },
        {
          contentType: 'description',
          content: (
            <div className="row-spacing">
              <p className="cs-regular-body-text-l cs-neutral-90">
                In order to verify your accreditation we need you to upload evidence. Please upload
                the appropriate files below and black-out any sensitive information.
              </p>
            </div>
          ),
          fieldVisiblity: true
        },
        {
          contentType: 'description',
          content: (
            <div className="cs-regular-body-text-l">
              <p className="cs-danger method-spacing">
                Upon a successful verification, the expiration date of the verification letter will
                be based on the evidence provided to reviewers, e.g. if a 3rd party letter or a
                monthly statement is 2 months old at the time of submission for review the
                verification letter will expire in 1 month.
              </p>
            </div>
          ),
          fieldVisiblity: true
        },
        {
          contentType: 'form',
          methodField: true,
          field: 'upload_drop_box_1',
          fieldID: 'upload_drop_box_1',
          fieldPrimaryLabel: 'Upload evidence of entity status being claimed:',
          disabled: loader,
          fieldType: 'input-uploadfile',
          fieldSizeLG: 12,
          fieldVisiblity: true,
          dropBoxId: 1
        },
        {
          contentType: 'description',
          content: (
            <div className="cs-regular-body-text-l">
              <p className="cs-neutral-90">
                Type or edit a note, comment, or response to the reviewer here (Optional):
              </p>
              <p className="row-spacing cs-neutral-70">
                <i>
                  Kindly note that for audit/compliance reasons, all communication between yourself
                  and your reviewing attorney can only be done by entering any questions or comments
                  in the box below. Note that this is optional.
                </i>
              </p>
            </div>
          ),
          fieldVisiblity: true
        },
        {
          contentType: 'form',
          methodField: true,
          field: 'notes',
          fieldID: 'notes',
          disabled: loader,
          fieldType: 'input-textarea',
          fieldPlaceholder: 'Enter your Note',
          fieldSizeLG: 12,
          fieldVisiblity: true
        }
      ];
      break;

    case 5:
      formJSON = [
        {
          contentType: 'description',
          content: (
            <div className="row-spacing">
              <p className="cs-regular-body-text-l cs-neutral-70">
                A plan established and maintained by a state, its political subdivisions, or any
                agency or instrumentality of a state or its political subdivisions, for the benefit
                of its employees, if such plan has total assets in excess of $5,000,000.
              </p>
            </div>
          ),
          fieldVisiblity: true
        },
        {
          contentType: 'divider',
          content: <div className="cs-divider"></div>,
          fieldVisiblity: true
        },
        legalNameDesc,
        {
          contentType: 'form',
          methodField: true,
          field: 'legal_name',
          fieldID: 'legal_name',
          fieldPrimaryLabel: 'Legal Name',
          highlightedInLineLabel:
            'Changes may not be made upon completion. Please click in the box below to edit the name as needed.',
          disabled: loader,
          fieldType: 'input-text',
          fieldPlaceholder: 'Name',
          fieldSizeLG: 5,
          fieldVisiblity: true
        },
        {
          contentType: 'divider',
          content: <div className="cs-divider"></div>,
          fieldVisiblity: true
        },
        {
          contentType: 'description',
          content: (
            <div className="row-spacing">
              <p className="cs-regular-body-text-l cs-neutral-90">
                In order to verify your accreditation we need you to upload evidence. Please upload
                the appropriate files below and black-out any sensitive information.
              </p>
            </div>
          ),
          fieldVisiblity: true
        },
        {
          contentType: 'description',
          content: (
            <div className="cs-regular-body-text-l">
              <p className="cs-danger method-spacing">
                Upon a successful verification, the expiration date of the verification letter will
                be based on the evidence provided to reviewers, e.g. if a 3rd party letter or a
                monthly statement is 2 months old at the time of submission for review the
                verification letter will expire in 1 month.
              </p>
            </div>
          ),
          fieldVisiblity: true
        },
        {
          contentType: 'form',
          methodField: true,
          field: 'upload_drop_box_1',
          fieldID: 'upload_drop_box_1',
          fieldPrimaryLabel:
            'Upload a statement that is no later than 90 days old showing your assets in excess of $5,000,000 USD:',
          disabled: loader,
          fieldType: 'input-uploadfile',
          fieldSizeLG: 12,
          fieldVisiblity: true,
          dropBoxId: 1
        },
        {
          contentType: 'form',
          methodField: true,
          field: 'upload_drop_box_2',
          fieldID: 'upload_drop_box_2',
          fieldPrimaryLabel: 'Upload other evidence:',
          disabled: loader,
          fieldType: 'input-uploadfile',
          fieldSizeLG: 12,
          fieldVisiblity: true,
          dropBoxId: 2
        },
        {
          contentType: 'description',
          content: (
            <div className="cs-regular-body-text-l">
              <p className="cs-neutral-90">
                Type or edit a note, comment, or response to the reviewer here (Optional):
              </p>
              <p className="row-spacing cs-neutral-70">
                <i>
                  Kindly note that for audit/compliance reasons, all communication between yourself
                  and your reviewing attorney can only be done by entering any questions or comments
                  in the box below. Note that this is optional. Thank you.
                </i>
              </p>
            </div>
          ),
          fieldVisiblity: true
        },
        {
          contentType: 'form',
          methodField: true,
          field: 'notes',
          fieldID: 'notes',
          disabled: loader,
          fieldType: 'input-textarea',
          fieldPlaceholder: 'Enter your Note',
          fieldSizeLG: 12,
          fieldVisiblity: true
        }
      ];
      break;

    case 6:
      formJSON = [
        {
          contentType: 'description',
          content: (
            <div className="row-spacing">
              <p className="cs-regular-body-text-l cs-neutral-70">
                A private business development company as defined in section 202(a)(22) of the
                Investment Advisers Act of 1940.
              </p>
            </div>
          ),
          fieldVisiblity: true
        },
        {
          contentType: 'divider',
          content: <div className="cs-divider"></div>,
          fieldVisiblity: true
        },
        legalNameDesc,
        {
          contentType: 'form',
          methodField: true,
          field: 'legal_name',
          fieldID: 'legal_name',
          fieldPrimaryLabel: 'Legal Name',
          highlightedInLineLabel:
            'Changes may not be made upon completion. Please click in the box below to edit the name as needed.',
          disabled: loader,
          fieldType: 'input-text',
          fieldPlaceholder: 'Name',
          fieldSizeLG: 5,
          fieldVisiblity: true
        },
        {
          contentType: 'divider',
          content: <div className="cs-divider"></div>,
          fieldVisiblity: true
        },
        {
          contentType: 'description',
          content: (
            <div className="row-spacing">
              <p className="cs-regular-body-text-l cs-neutral-90">
                In order to verify your accreditation we need you to upload evidence. Please upload
                the appropriate files below and black-out any sensitive information.
              </p>
            </div>
          ),
          fieldVisiblity: true
        },
        {
          contentType: 'description',
          content: (
            <div className="cs-regular-body-text-l">
              <p className="cs-danger method-spacing">
                Upon a successful verification, the expiration date of the verification letter will
                be based on the evidence provided to reviewers, e.g. if a 3rd party letter or a
                monthly statement is 2 months old at the time of submission for review the
                verification letter will expire in 1 month.
              </p>
            </div>
          ),
          fieldVisiblity: true
        },
        {
          contentType: 'form',
          methodField: true,
          field: 'upload_drop_box_1',
          fieldID: 'upload_drop_box_1',
          fieldPrimaryLabel: 'Upload evidence of entity status being claimed:',
          disabled: loader,
          fieldType: 'input-uploadfile',
          fieldSizeLG: 12,
          fieldVisiblity: true,
          dropBoxId: 1
        },
        {
          contentType: 'description',
          content: (
            <div className="cs-regular-body-text-l">
              <p className="cs-neutral-90">
                Type or edit a note, comment, or response to the reviewer here (Optional):
              </p>
              <p className="row-spacing cs-neutral-70">
                <i>
                  Kindly note that for audit/compliance reasons, all communication between yourself
                  and your reviewing attorney can only be done by entering any questions or comments
                  in the box below. Note that this is optional.
                </i>
              </p>
            </div>
          ),
          fieldVisiblity: true
        },
        {
          contentType: 'form',
          methodField: true,
          field: 'notes',
          fieldID: 'notes',
          disabled: loader,
          fieldType: 'input-textarea',
          fieldPlaceholder: 'Enter your Note',
          fieldSizeLG: 12,
          fieldVisiblity: true
        }
      ];
      break;

    case 7:
      formJSON = [
        {
          contentType: 'description',
          content: (
            <div className="row-spacing">
              <p className="cs-regular-body-text-l cs-neutral-70">
                Any organization described in section 501(c)(3) of the Internal Revenue Code,
                corporation, Massachusetts or similar business trust, partnership, or limited
                liability company, not formed for the specific purpose of acquiring the securities
                offered, with total assets in excess of $5,000,000. Most entities/companies choose
                this if they have over $5,000,000 in assets.
              </p>
            </div>
          ),
          fieldVisiblity: true
        },
        {
          contentType: 'divider',
          content: <div className="cs-divider"></div>,
          fieldVisiblity: true
        },
        legalNameDesc,
        {
          contentType: 'form',
          methodField: true,
          field: 'legal_name',
          fieldID: 'legal_name',
          fieldPrimaryLabel: 'Legal Name',
          highlightedInLineLabel:
            'Changes may not be made upon completion. Please click in the box below to edit the name as needed.',
          disabled: loader,
          fieldType: 'input-text',
          fieldPlaceholder: 'Name',
          fieldSizeLG: 5,
          fieldVisiblity: true
        },
        {
          contentType: 'divider',
          content: <div className="cs-divider"></div>,
          fieldVisiblity: true
        },
        {
          contentType: 'description',
          content: (
            <div className="row-spacing">
              <p className="cs-regular-body-text-l cs-neutral-90">
                In order to verify your accreditation we need you to upload evidence. Please upload
                the appropriate files below and black-out any sensitive information.
              </p>
            </div>
          ),
          fieldVisiblity: true
        },
        {
          contentType: 'description',
          content: (
            <div className="cs-regular-body-text-l">
              <p className="cs-danger method-spacing">
                Upon a successful verification, the expiration date of the verification letter will
                be based on the evidence provided to reviewers, e.g. if a 3rd party letter or a
                monthly statement is 2 months old at the time of submission for review the
                verification letter will expire in 1 month.
              </p>
            </div>
          ),
          fieldVisiblity: true
        },
        {
          contentType: 'form',
          methodField: true,
          field: 'upload_drop_box_1',
          fieldID: 'upload_drop_box_1',
          fieldPrimaryLabel:
            'Upload a statement that is no later than 90 days old showing your assets in excess of $5,000,000 USD:',
          disabled: loader,
          fieldType: 'input-uploadfile',
          fieldSizeLG: 12,
          fieldVisiblity: true,
          dropBoxId: 1
        },
        {
          contentType: 'form',
          methodField: true,
          field: 'upload_drop_box_2',
          fieldID: 'upload_drop_box_2',
          fieldPrimaryLabel: 'Upload other evidence:',
          disabled: loader,
          fieldType: 'input-uploadfile',
          fieldSizeLG: 12,
          fieldVisiblity: true,
          dropBoxId: 2
        },
        {
          contentType: 'description',
          content: (
            <div className="cs-regular-body-text-l">
              <p className="cs-neutral-90">
                Type or edit a note, comment, or response to the reviewer here (Optional):
              </p>
              <p className="row-spacing cs-neutral-70">
                <i>
                  Kindly note that for audit/compliance reasons, all communication between yourself
                  and your reviewing attorney can only be done by entering any questions or comments
                  in the box below. Note that this is optional. Thank you.
                </i>
              </p>
            </div>
          ),
          fieldVisiblity: true
        },
        {
          contentType: 'form',
          methodField: true,
          field: 'notes',
          fieldID: 'notes',
          disabled: loader,
          fieldType: 'input-textarea',
          fieldPlaceholder: 'Enter your Note',
          fieldSizeLG: 12,
          fieldVisiblity: true
        }
      ];
      break;

    case 8:
      formJSON = [
        {
          contentType: 'description',
          content: (
            <div className="row-spacing">
              <p className="cs-regular-body-text-l cs-neutral-70">
                A trust, with total assets in excess of $5,000,000, not formed for the specific
                purpose of acquiring the securities offered, whose purchase is directed by a person
                who either alone or with his purchaser representative(s) has such knowledge and
                experience in financial and business matters that he is capable of evaluating the
                merits and risks of the prospective investment.
              </p>
            </div>
          ),
          fieldVisiblity: true
        },
        {
          contentType: 'divider',
          content: <div className="cs-divider"></div>,
          fieldVisiblity: true
        },
        legalNameDesc,
        {
          contentType: 'form',
          methodField: true,
          field: 'legal_name',
          fieldID: 'legal_name',
          fieldPrimaryLabel: 'Legal Name',
          highlightedInLineLabel:
            'Changes may not be made upon completion. Please click in the box below to edit the name as needed.',
          disabled: loader,
          fieldType: 'input-text',
          fieldPlaceholder: 'Name',
          fieldSizeLG: 5,
          fieldVisiblity: true
        },
        {
          contentType: 'divider',
          content: <div className="cs-divider"></div>,
          fieldVisiblity: true
        },
        {
          contentType: 'description',
          content: (
            <div className="row-spacing">
              <p className="cs-regular-body-text-l cs-neutral-90">
                In order to verify your accreditation we need you to upload evidence. Please upload
                the appropriate files below and black-out any sensitive information.
              </p>
            </div>
          ),
          fieldVisiblity: true
        },
        {
          contentType: 'description',
          content: (
            <div className="cs-regular-body-text-l">
              <p className="cs-danger method-spacing">
                Upon a successful verification, the expiration date of the verification letter will
                be based on the evidence provided to reviewers, e.g. if a 3rd party letter or a
                monthly statement is 2 months old at the time of submission for review the
                verification letter will expire in 1 month.
              </p>
            </div>
          ),
          fieldVisiblity: true
        },
        {
          contentType: 'form',
          methodField: true,
          field: 'upload_drop_box_1',
          fieldID: 'upload_drop_box_1',
          fieldPrimaryLabel:
            'Upload a statement that is no later than 90 days old showing your assets in excess of $5,000,000 USD:',
          disabled: loader,
          fieldType: 'input-uploadfile',
          fieldSizeLG: 12,
          fieldVisiblity: true,
          dropBoxId: 1
        },
        {
          contentType: 'form',
          methodField: true,
          field: 'upload_drop_box_2',
          fieldID: 'upload_drop_box_2',
          fieldPrimaryLabel: 'Upload other evidence:',
          disabled: loader,
          fieldType: 'input-uploadfile',
          fieldSizeLG: 12,
          fieldVisiblity: true,
          dropBoxId: 2
        },
        {
          contentType: 'description',
          content: (
            <div className="cs-regular-body-text-l">
              <p className="cs-neutral-90">
                Type or edit a note, comment, or response to the reviewer here (Optional):
              </p>
              <p className="row-spacing cs-neutral-70">
                <i>
                  Kindly note that for audit/compliance reasons, all communication between yourself
                  and your reviewing attorney can only be done by entering any questions or comments
                  in the box below. Note that this is optional. Thank you.
                </i>
              </p>
            </div>
          ),
          fieldVisiblity: true
        },
        {
          contentType: 'form',
          methodField: true,
          field: 'notes',
          fieldID: 'notes',
          disabled: loader,
          fieldType: 'input-textarea',
          fieldPlaceholder: 'Enter your Note',
          fieldSizeLG: 12,
          fieldVisiblity: true
        }
      ];
      break;

    case 9:
      formJSON = [
        {
          contentType: 'description',
          content: (
            <div className="row-spacing">
              <p className="cs-regular-body-text-l cs-neutral-70">
                An entity in which all the equity owners are accredited investors.
              </p>
            </div>
          ),
          fieldVisiblity: true
        },
        {
          contentType: 'divider',
          content: <div className="cs-divider"></div>,
          fieldVisiblity: true
        },
        legalNameDesc,
        {
          contentType: 'form',
          methodField: true,
          field: 'legal_name',
          fieldID: 'legal_name',
          fieldPrimaryLabel: 'Legal Name',
          highlightedInLineLabel:
            'Changes may not be made upon completion. Please click in the box below to edit the name as needed.',
          disabled: loader,
          fieldType: 'input-text',
          fieldPlaceholder: 'Name',
          fieldSizeLG: 5,
          fieldVisiblity: true
        },
        {
          contentType: 'divider',
          content: <div className="cs-divider"></div>,
          fieldVisiblity: true
        },
        {
          contentType: 'form',
          methodField: true,
          field: 'upload_drop_box_1',
          fieldID: 'upload_drop_box_1',
          fieldPrimaryLabel:
            'Please upload evidence of who all the equity owners of the entity are (by uploading your entity documentation or this',
          linkInLineLabel: 'Officer’s Certificate).',
          linkOfLabel:
            'https://s3.amazonaws.com/static-clientportalx.com/Verify-Investor-files/All-Owners-Accredited-Officer-Certificate.pdf',
          linkInNewTab: true,
          disabled: loader,
          fieldType: 'input-uploadfile',
          fieldSizeLG: 12,
          fieldVisiblity: true,
          dropBoxId: 1
        },
        {
          contentType: 'description',
          content: (
            <div className="row-spacing">
              <p className="cs-regular-body-text-l">
                <span className="cs-neutral-70">
                  In order to verify your accreditation we need you to upload evidence. Please
                  upload the appropriate files below and black-out any sensitive information.
                </span>
                <span className="cs-danger">
                  {' '}
                  To obtain verification letters for each equity owner, please order additional
                  verification requests for each equity owner, or if an issuer is paying for the
                  verifications, please ask them to place additional verification requests for each
                  equity owner. The issuer may need the email address for each equity investor to
                  place the verification requests.
                </span>
                <span className="cs-neutral-70">
                  {' '}
                  Note that multiple verification requests can be sent to the same email address,
                  but different equity owners often prefer to receive their verification request at
                  their own email address for privacy purposes.
                </span>
              </p>
            </div>
          ),
          fieldVisiblity: true
        },
        {
          contentType: 'form',
          methodField: true,
          field: 'upload_drop_box_2',
          fieldID: 'upload_drop_box_2',
          fieldPrimaryLabel: 'Upload verification letters for each equity owner:',
          disabled: loader,
          fieldType: 'input-uploadfile',
          fieldSizeLG: 12,
          fieldVisiblity: true,
          dropBoxId: 2
        },
        {
          contentType: 'form',
          methodField: true,
          field: 'upload_drop_box_3',
          fieldID: 'upload_drop_box_3',
          fieldPrimaryLabel: 'Upload other evidence:',
          disabled: loader,
          fieldType: 'input-uploadfile',
          fieldSizeLG: 12,
          fieldVisiblity: true,
          dropBoxId: 3
        },
        {
          contentType: 'description',
          content: (
            <div className="cs-regular-body-text-l">
              <p className="row-spacing cs-neutral-90">
                Type or edit a note, comment, or response to the reviewer here (Optional):
              </p>
              <p className="row-spacing cs-neutral-70">
                <i>
                  Kindly note that for audit/compliance reasons, all communication between yourself
                  and your reviewing attorney can only be done by entering any questions or comments
                  in the box below. Note that this is optional. Thank you.
                </i>
              </p>
            </div>
          ),
          fieldVisiblity: true
        },
        {
          contentType: 'form',
          methodField: true,
          field: 'notes',
          fieldID: 'notes',
          disabled: loader,
          fieldType: 'input-textarea',
          fieldPlaceholder: 'Enter your Note',
          fieldSizeLG: 12,
          fieldVisiblity: true
        }
      ];
      break;

    case 10:
      formJSON = [
        {
          contentType: 'description',
          content: (
            <div className="row-spacing">
              <p className="cs-regular-body-text-l">
                <span className="cs-neutral-70">
                  Any employee benefit plan within the meaning of the Employee Retirement Income
                  Security Act of 1974
                </span>
                <span className="cs-neutral-90"> AND</span>
              </p>
            </div>
          ),
          fieldVisiblity: true
        },
        {
          contentType: 'description',
          content: (
            <div className="row-spacing">
              <p className="cs-regular-body-text-l">
                <span className="cs-neutral-70">
                  if the investment decision is made by a plan fiduciary, as defined in section
                  3(21) of such act, which is either a bank, savings and loan association, insurance
                  company, or registered investment adviser,
                </span>
              </p>
            </div>
          ),
          fieldVisiblity: true
        },
        {
          contentType: 'description',
          content: (
            <div className="row-spacing">
              <p className="cs-regular-body-text-l">
                <span className="cs-neutral-70">
                  OR if the employee benefit plan has total assets in excess of $5,000,000,
                  <b> OR</b>
                </span>
              </p>
            </div>
          ),
          fieldVisiblity: true
        },
        {
          contentType: 'description',
          content: (
            <div className="row-spacing">
              <p className="cs-regular-body-text-l cs-neutral-70">
                if a self-directed plan, with investment decisions made solely by persons that are
                accredited investors.
              </p>
            </div>
          ),
          fieldVisiblity: true
        },
        {
          contentType: 'divider',
          content: <div className="cs-divider"></div>,
          fieldVisiblity: true
        },
        legalNameDesc,
        {
          contentType: 'form',
          methodField: true,
          field: 'legal_name',
          fieldID: 'legal_name',
          fieldPrimaryLabel: 'Legal Name',
          highlightedInLineLabel:
            'Changes may not be made upon completion. Please click in the box below to edit the name as needed.',
          disabled: loader,
          fieldType: 'input-text',
          fieldPlaceholder: 'Name',
          fieldSizeLG: 5,
          fieldVisiblity: true
        },
        {
          contentType: 'divider',
          content: <div className="cs-divider"></div>,
          fieldVisiblity: true
        },
        {
          contentType: 'form',
          methodField: true,
          field: 'upload_drop_box_1',
          fieldID: 'upload_drop_box_1',
          fieldPrimaryLabel:
            labelstate === 0
              ? `Upload a statement that is no later than 90 days old showing your assets in excess of $5,000,000 USD:`
              : labelstate === 1
                ? `Upload evidence that shows investment decisions are made by a Plan Fiduciary as described in the definition of “accredited investor” :`
                : `Upload Officer's Certificate that certifies that all investment decisions are made solely by persons that are accredited investors :`,
          disabled: loader,
          fieldSecondaryLabel:
            labelstate === 1 || labelstate === 2 ? `— Sample Officer’s Certificate` : ``,
          linkInLineLabel: labelstate === 1 || labelstate === 2 ? ` Here` : ``,
          linkOfLabel:
            labelstate === 1
              ? `https://s3.amazonaws.com/static-clientportalx.com/Verify-Investor-files/OfficerCertificate-E72.pdf`
              : labelstate === 2
                ? `https://s3.amazonaws.com/static-clientportalx.com/Verify-Investor-files/OfficerCertificate-E73.pdf`
                : ``,
          linkInNewTab: true,
          fieldType: 'input-uploadfile',
          fieldSizeLG: 12,
          fieldVisiblity: true,
          dropBoxId: 1
        },
        {
          contentType: 'form',
          methodField: true,
          field: 'upload_drop_box_2',
          fieldID: 'upload_drop_box_2',
          fieldPrimaryLabel: 'Upload other evidence:',
          disabled: loader,
          fieldType: 'input-uploadfile',
          fieldSizeLG: 12,
          fieldVisiblity: true,
          dropBoxId: 2
        },
        {
          contentType: 'description',
          content: (
            <div className="cs-regular-body-text-l">
              <p className="cs-neutral-90 method-spacing">
                <span className="cs-neutral-70">Don’t have these forms? Choose </span>
                <span
                  className="cs-neutral-100 cursor-pointer"
                  onClick={() => setLabelState(labelstate === 0 ? 1 : 0)}>
                  <u>
                    {labelstate === 1 || labelstate === 2 ? `Plan Statement` : `Plan Fiduciary`}
                  </u>
                </span>
                <span className=" cs-neutral-70"> or </span>
                <span
                  className="cs-neutral-100 cursor-pointer"
                  onClick={() => setLabelState(labelstate !== 2 ? 2 : 1)}>
                  <u>{labelstate === 2 ? `Plan Fiduciary.` : `Officer’s Certificate.`}</u>
                </span>
              </p>
            </div>
          ),
          fieldVisiblity: true
        },
        {
          contentType: 'description',
          content: (
            <div className="cs-regular-body-text-l">
              <p className="cs-neutral-90">
                Type or edit a note, comment, or response to the reviewer here (Optional):
              </p>
              <p className="row-spacing cs-neutral-70">
                <i>
                  Kindly note that for audit/compliance reasons, all communication between yourself
                  and your reviewing attorney can only be done by entering any questions or comments
                  in the box below. Note that this is optional. Thank you.
                </i>
              </p>
            </div>
          ),
          fieldVisiblity: true
        },
        {
          contentType: 'form',
          methodField: true,
          field: 'notes',
          fieldID: 'notes',
          disabled: loader,
          fieldType: 'input-textarea',
          fieldPlaceholder: 'Enter your Note',
          fieldSizeLG: 12,
          fieldVisiblity: true
        }
      ];
      break;

    case 13:
      formJSON = [
        {
          contentType: 'description',
          content: (
            <div className="row-spacing">
              <p className="cs-regular-body-text-l cs-neutral-70">
                The investor has or can get a new letter, which is dated within the last three
                months, from a registered broker-dealer, an SEC-registered investment adviser, a
                licensed attorney, or a certified public accountant certifying the investor as an
                accredited investor.
              </p>
            </div>
          ),
          fieldVisiblity: true
        },
        {
          contentType: 'divider',
          content: <div className="cs-divider"></div>,
          fieldVisiblity: true
        },
        legalNameDesc,
        {
          contentType: 'form',
          methodField: true,
          field: 'legal_name',
          fieldID: 'legal_name',
          fieldPrimaryLabel: 'Legal Name',
          highlightedInLineLabel: 'Please click in the box below to edit the name as needed.',
          disabled: loader,
          fieldType: 'input-text',
          fieldPlaceholder: 'Name',
          fieldSizeLG: 5,
          fieldVisiblity: true
        },
        {
          contentType: 'divider',
          content: <div className="cs-divider"></div>,
          fieldVisiblity: true
        },
        {
          contentType: 'description',
          content: (
            <div className="row-spacing">
              <p className="cs-regular-body-text-l cs-neutral-70">
                In order to verify your accreditation we need you to upload evidence. Please upload
                the appropriate files below and black-out any sensitive information.
              </p>
            </div>
          ),
          fieldVisiblity: true
        },
        {
          contentType: 'description',
          content: (
            <div className="row-spacing">
              <p className="cs-regular-body-text-l cs-danger">
                Upon a successful verification, the expiration date of the verification letter will
                be based on the evidence provided to reviewers, e.g. if a 3rd party letter or a
                monthly statement is 2 months old at the time of submission for review the
                verification letter will expire in 1 month.
              </p>
            </div>
          ),
          fieldVisiblity: true
        },
        {
          contentType: 'description',
          content: (
            <div className="cs-regular-body-text-l">
              <p className="cs-danger">
                Kindly please note that the 3rd party professional letters must meet the following
                criteria:
              </p>
              <ul className="cs-neutral-90">
                <li className="row-spacing">
                  Name- The named Investor on the form should be exactly as the name entered in the
                  “Legal Name” being verified box on the verification application. This should
                  generally match the investor name on the subscription agreement (e.g.- the
                  individual investor’s name, name on the Trust document, or the Operating
                  Agreement, etc.).
                </li>
                <li className="row-spacing">
                  Date- The date on the letter must be no older than 90 days.
                </li>
                <li className="row-spacing">
                  Signer Information- The letter cannot be self-certified and must be completed by
                  any of the following 3rd party professionals: a licensed attorney, CPA, registered
                  broker-dealer, or SEC-registered investment adviser. There must be sufficient
                  information to identify the signer. It must be exact enough to allow the reviewer
                  the ability to make a positive identification, based on their name and licensing
                  information provided. Please note that for audit/compliance reasons, If the signer
                  is doing so on behalf of a company that has a CRD, they should include their
                  individual name and the name of the entity they are signing on behalf of. However,
                  if the individual signing the form is doing so on behalf of a company that has a
                  CRD, then they do not need to be registered or licensed.
                </li>
                <li className="row-spacing">
                  Language- The letter must contain language affirmatively stating that the investor
                  has been verified as an accredited investor (e.g.- I verify this Investor is an
                  “accredited investor”, as defined in Rule 501 of Regulation D of the Securities
                  Act of 1933.) Additionally, no language that expresses limitation of reviewer use
                  or disclaiming of responsibility of the verification can be made in the letter.
                </li>
              </ul>
            </div>
          ),
          fieldVisiblity: true
        },
        {
          contentType: 'description',
          content: (
            <div className="row-spacing">
              <p className="cs-regular-body-text-l cs-neutral-90">
                Upload a signed letter from a{' '}
                <span className="cs-danger">registered broker-dealer</span>, an&nbsp;
                <span className="cs-danger">SEC-registered investment adviser</span>, a{' '}
                <span className="cs-danger">licensed attorney</span>, or a&nbsp;
                <span className="cs-danger">certified public accountant</span> showing that they
                have already verified you as an accredited investor within the last three months:
              </p>
            </div>
          ),
          fieldVisiblity: true
        },
        {
          contentType: 'description',
          content: (
            <div className="row-spacing">
              <p className="cs-regular-sub-heading-m cs-danger ">
                IF YOUR LETTER IS NOT COMPLETED BY ANY OF THE NOTED PROFESSIONALS, PLEASE USE THE
                NET WORTH VERIFICATION METHOD
              </p>
            </div>
          ),
          fieldVisiblity: true
        },
        {
          contentType: 'form',
          methodField: true,
          field: 'upload_drop_box_1',
          fieldID: 'upload_drop_box_1',
          fieldPrimaryLabel: 'Sample Letter',
          linkInLineLabel: 'Here',
          linkOfLabel:
            'https://s3.amazonaws.com/static-clientportalx.com/Verify-Investor-files/Sample-3rd-Party-Verification-Letter.pdf',
          linkInNewTab: true,
          disabled: loader,
          fieldType: 'input-uploadfile',
          fieldSizeLG: 12,
          fieldVisiblity: true,
          dropBoxId: 1
        },
        {
          contentType: 'description',
          content: (
            <div className="row-spacing">
              <span className="cs-regular-body-text-l cs-neutral-90">
                Type or edit a note, comment, or response to the reviewer here (Optional):
              </span>
              <p className="cs-regular-body-text-l cs-neutral-70">
                <i>
                  Kindly note that for audit/compliance reasons, all communication between yourself
                  and your reviewing attorney can only be done by entering any questions or comments
                  in the box below. Note that this is optional.
                </i>
              </p>
            </div>
          ),
          fieldVisiblity: true
        },
        {
          contentType: 'form',
          methodField: true,
          field: 'notes',
          fieldID: 'notes',
          disabled: loader,
          fieldType: 'input-textarea',
          fieldPlaceholder: 'Enter your Note',
          fieldSizeLG: 12,
          fieldVisiblity: true
        }
      ];
      break;

    case 14:
      formJSON = [
        {
          contentType: 'description',
          content: (
            <div className="row-spacing">
              <p className="cs-regular-body-text-l cs-neutral-70">
                The investor already has or can get a new letter, which is dated within the last
                three months, from a registered broker-dealer, an SEC-registered investment adviser,
                a licensed attorney, or a certified public accountant certifying the investor as an
                accredited investor.
              </p>
            </div>
          ),
          fieldVisiblity: true
        },
        {
          contentType: 'divider',
          content: <div className="cs-divider"></div>,
          fieldVisiblity: true
        },
        legalNameDesc,
        {
          contentType: 'form',
          methodField: true,
          field: 'legal_name',
          fieldID: 'legal_name',
          fieldPrimaryLabel: 'Legal Name',
          highlightedInLineLabel:
            'Changes may not be made upon completion. Please click in the box below to edit the name as needed.',
          disabled: loader,
          fieldType: 'input-text',
          fieldPlaceholder: 'Name',
          fieldSizeLG: 5,
          fieldVisiblity: true
        },
        {
          contentType: 'divider',
          content: <div className="cs-divider"></div>,
          fieldVisiblity: true
        },
        {
          contentType: 'description',
          content: (
            <div className="row-spacing">
              <p className="cs-regular-body-text-l cs-neutral-70">
                In order to verify your accreditation we need you to upload evidence. Please upload
                the appropriate files below and black-out any sensitive information.
              </p>
            </div>
          ),
          fieldVisiblity: true
        },
        {
          contentType: 'description',
          content: (
            <div className="row-spacing">
              <p className="cs-regular-body-text-l cs-danger">
                Upon a successful verification, the expiration date of the verification letter will
                be based on the evidence provided to reviewers, e.g. if a 3rd party letter or a
                monthly statement is 2 months old at the time of submission for review the
                verification letter will expire in 1 month.
              </p>
            </div>
          ),
          fieldVisiblity: true
        },
        {
          contentType: 'description',
          content: (
            <div className="row-spacing">
              <p className="cs-regular-body-text-l cs-neutral-90">
                Upload a signed letter from a{' '}
                <span className="cs-danger">registered broker-dealer</span>, an&nbsp;
                <span className="cs-danger">SEC-registered investment adviser</span>, a{' '}
                <span className="cs-danger">licensed attorney</span>, or a&nbsp;
                <span className="cs-danger">certified public accountant</span> showing that they
                have already verified you as an accredited investor within the last three months:
              </p>
            </div>
          ),
          fieldVisiblity: true
        },
        {
          contentType: 'description',
          content: (
            <div className="row-spacing">
              <p className="cs-regular-body-text-l cs-danger">
                IF YOUR LETTER IS NOT COMPLETED BY ANY OF THE NOTED PROFESSIONALS, PLEASE USE THE
                NET WORTH VERIFICATION METHOD
              </p>
            </div>
          ),
          fieldVisiblity: true
        },
        {
          contentType: 'form',
          methodField: true,
          field: 'upload_drop_box_1',
          fieldID: 'upload_drop_box_1',
          fieldLabelWithTag: (
            <span className="cs-regular-sub-heading-m cs-neutral-90">
              Sample Letter{' '}
              <Link
                className="cs-link cs-neutral-100"
                to={
                  'https://s3.amazonaws.com/static-clientportalx.com/Verify-Investor-files/Sample-3rd-Party-Verification-Letter.pdf'
                }
                target="_blank">
                Here
              </Link>
            </span>
          ),
          disabled: loader,
          fieldType: 'input-uploadfile',
          fieldSizeLG: 12,
          fieldVisiblity: true,
          dropBoxId: 1
        },
        {
          contentType: 'description',
          content: (
            <div className="row-spacing">
              <span className="cs-regular-body-text-l cs-neutral-90">
                Type or edit a note, comment, or response to the reviewer here (Optional):
              </span>
              <p className="cs-regular-body-text-l cs-neutral-70">
                <i>
                  Kindly note that for audit/compliance reasons, all communication between yourself
                  and your reviewing attorney can only be done by entering any questions or comments
                  in the box below. Note that this is optional.
                </i>
              </p>
            </div>
          ),
          fieldVisiblity: true
        },
        {
          contentType: 'form',
          methodField: true,
          field: 'notes',
          fieldID: 'notes',
          disabled: loader,
          fieldType: 'input-textarea',
          fieldPlaceholder: 'Enter your Note',
          fieldSizeLG: 12,
          fieldVisiblity: true
        }
      ];
      break;

    case 15:
      formJSON = [
        {
          contentType: 'description',
          content: (
            <div className="row-spacing">
              <p className="cs-regular-body-text-l cs-neutral-70">
                A "Special Situation" which is designed to be a catch-all category for situations
                where an investor believes that it is so abundantly clear that they are an
                accredited investor that they don't need to provide the standard evidence that the
                regulations normally require for a proper verification.{' '}
                <span className="cs-neutral-90">
                  Please note that it doesn't allow deviation from the legal definition of
                  accredited investor; it only allows additional flexibility on how to prove it.
                </span>{' '}
                This &nbsp;
                <Link
                  to="https://www.investor.gov/introduction-investing/general-resources/news-alerts/alerts-bulletins/investor-bulletins/updated-3"
                  target="_blank"
                  className="vi-link-color ">
                  SEC bulletin
                </Link>
                &nbsp; does a decent job describing most of the accredited investor categories.{' '}
                <b>
                  Please contact{' '}
                  <Link target="_blank" to="mailto:accreditation@capsync.com">
                    accreditation@capsync.com
                  </Link>{' '}
                  before you select this method of verification.
                </b>
              </p>
            </div>
          ),
          fieldVisiblity: true
        },
        {
          contentType: 'divider',
          content: <div className="cs-divider"></div>,
          fieldVisiblity: true
        },
        legalNameDesc,
        {
          contentType: 'form',
          methodField: true,
          field: 'legal_name',
          fieldID: 'legal_name',
          fieldPrimaryLabel: 'Legal Name',
          highlightedInLineLabel:
            'Changes may not be made upon completion. Please click in the box below to edit the name as needed.',
          disabled: loader,
          fieldType: 'input-text',
          fieldPlaceholder: 'Name',
          fieldSizeLG: 5,
          fieldVisiblity: true
        },
        {
          contentType: 'divider',
          content: <div className="cs-divider"></div>,
          fieldVisiblity: true
        },
        {
          contentType: 'form',
          methodField: true,
          field: 'upload_drop_box_1',
          fieldID: 'upload_drop_box_1',
          fieldPrimaryLabel:
            'Please explain why you believe an attorney can reasonably verify you and upload supporting evidence as appropriate. If you have any questions or need help, please email ',
          linkInLineLabel: 'accreditation@capsync.com.',
          linkInNewTab: true,
          linkOfLabel: 'mailto:accreditation@capsync.com',
          disabled: loader,
          fieldType: 'input-uploadfile',
          fieldSizeLG: 12,
          fieldVisiblity: true,
          dropBoxId: 1
        },
        {
          contentType: 'description',
          content: (
            <div className="row-spacing">
              <span className="cs-regular-body-text-l cs-neutral-90">
                Type or edit a note, comment, or response to the reviewer here (Optional):
              </span>
              <p className="cs-regular-body-text-l cs-neutral-70">
                <i>
                  Kindly note that for audit/compliance reasons, all communication between yourself
                  and your reviewing attorney can only be done by entering any questions or comments
                  in the box below. Note that this is optional.
                </i>
              </p>
            </div>
          ),
          fieldVisiblity: true
        },
        {
          contentType: 'form',
          methodField: true,
          field: 'notes',
          fieldID: 'notes',
          disabled: loader,
          fieldType: 'input-textarea',
          fieldPlaceholder: 'Enter your Note',
          fieldSizeLG: 12,
          fieldVisiblity: true
        }
      ];
      break;

    case 16:
      formJSON = [
        {
          contentType: 'description',
          content: (
            <div className="row-spacing">
              <p className="cs-regular-body-text-l cs-neutral-70">
                A "Special Situation" which is designed to be a catch-all category for situations
                where an investor believes that it is so abundantly clear that they are an
                accredited investor that they don't need to provide the standard evidence that the
                regulations normally require for a proper verification.{' '}
                <span className="cs-neutral-90">
                  Please note that it doesn't allow deviation from the legal definition of
                  accredited investor; it only allows additional flexibility on how to prove it.
                </span>{' '}
                This &nbsp;
                <Link
                  to="https://www.investor.gov/introduction-investing/general-resources/news-alerts/alerts-bulletins/investor-bulletins/updated-3"
                  target="_blank"
                  className="vi-link-color ">
                  SEC bulletin
                </Link>
                &nbsp; does a decent job describing most of the accredited investor categories.{' '}
                <b>
                  Please contact{' '}
                  <Link target="_blank" to="mailto:accreditation@capsync.com">
                    accreditation@capsync.com
                  </Link>{' '}
                  before you select this method of verification.
                </b>
              </p>
            </div>
          ),
          fieldVisiblity: true
        },
        {
          contentType: 'divider',
          content: <div className="cs-divider"></div>,
          fieldVisiblity: true
        },
        legalNameDesc,
        {
          contentType: 'form',
          methodField: true,
          field: 'legal_name',
          fieldID: 'legal_name',
          fieldPrimaryLabel: 'Legal Name',
          highlightedInLineLabel:
            'Changes may not be made upon completion. Please click in the box below to edit the name as needed.',
          disabled: loader,
          fieldType: 'input-text',
          fieldPlaceholder: 'Name',
          fieldSizeLG: 5,
          fieldVisiblity: true
        },
        {
          contentType: 'divider',
          content: <div className="cs-divider"></div>,
          fieldVisiblity: true
        },
        {
          contentType: 'form',
          methodField: true,
          field: 'upload_drop_box_1',
          fieldID: 'upload_drop_box_1',
          fieldPrimaryLabel:
            'Please explain why you believe an attorney can reasonably verify you and upload supporting evidence as appropriate. If you have any questions or need help, please email ',
          linkInLineLabel: 'accreditation@capsync.com.',
          linkInNewTab: true,
          linkOfLabel: 'mailto:accreditation@capsync.com',
          disabled: loader,
          fieldType: 'input-uploadfile',
          fieldSizeLG: 12,
          fieldVisiblity: true,
          dropBoxId: 1
        },
        {
          contentType: 'description',
          content: (
            <div className="row-spacing">
              <span className="cs-regular-body-text-l cs-neutral-90">
                Type or edit a note, comment, or response to the reviewer here (Optional):
              </span>
              <p className="cs-regular-body-text-l cs-neutral-70">
                <i>
                  Kindly note that for audit/compliance reasons, all communication between yourself
                  and your reviewing attorney can only be done by entering any questions or comments
                  in the box below. Note that this is optional.
                </i>
              </p>
            </div>
          ),
          fieldVisiblity: true
        },
        {
          contentType: 'form',
          methodField: true,
          field: 'notes',
          fieldID: 'notes',
          disabled: loader,
          fieldType: 'input-textarea',
          fieldPlaceholder: 'Enter your Note',
          fieldSizeLG: 12,
          fieldVisiblity: true
        }
      ];
      break;

    case 17:
      formJSON = [
        {
          contentType: 'description',
          content: (
            <div className="row-spacing">
              <p className="cs-regular-body-text-l cs-neutral-70">
                A natural person with income exceeding $200,000 in each of the two most recent years
                or joint income with that person's spouse or{' '}
                <CapsyncToolTip
                  Child={<u>spousal equivalent</u>}
                  placement={'top'}
                  message={
                    'A cohabitant occupying a relationship generally equivalent to that of a spouse.'
                  }
                  type="text"
                />{' '}
                exceeding $300,000 for those years and has a reasonable expectation of reaching the
                same income level in the current year.
              </p>
            </div>
          ),
          fieldVisiblity: true
        },
        {
          contentType: 'divider',
          content: <div className="cs-divider"></div>,
          fieldVisiblity: true
        },
        legalNameDesc,
        {
          contentType: 'form',
          methodField: true,
          field: 'legal_name',
          fieldID: 'legal_name',
          fieldPrimaryLabel: 'Legal Name',
          highlightedInLineLabel: 'Please click in the box below to edit the name as needed.',
          disabled: loader,
          fieldType: 'input-text',
          fieldSizeLG: 5,
          fieldVisiblity: true
        },
        {
          contentType: 'divider',
          content: <div className="cs-divider"></div>,
          fieldVisiblity: true
        },
        {
          contentType: 'description',
          content: (
            <div className="row-spacing">
              <p className="cs-regular-body-text-l cs-neutral-70">
                In order to verify your accreditation we need you to upload evidence. Please upload
                the appropriate files below and black-out any sensitive information.
              </p>
            </div>
          ),
          fieldVisiblity: true
        },
        {
          contentType: 'description',
          content: (
            <div className="row-spacing">
              <p className="cs-regular-body-text-l cs-danger">
                Upon a successful verification, the expiration date of the verification letter will
                be based on the evidence provided to reviewers, e.g. if a 3rd party letter or a
                monthly statement is 2 months old at the time of submission for review the
                verification letter will expire in 1 month.
              </p>
            </div>
          ),
          fieldVisiblity: true
        },
        {
          contentType: 'form',
          methodField: true,
          field: 'upload_drop_box_3',
          fieldID: 'upload_drop_box_3',
          fieldLabelWithTag: (
            <span className="cs-regular-sub-heading-m cs-neutral-90">
              Please upload evidence of who all the equity owners of the trust are (by uploading
              your trust documentation or this{' '}
              <Link
                className="cs-neutral-100"
                to="https://s3.amazonaws.com/static-clientportalx.com/Verify-Investor-files/All-Owners-Accredited-Officer-Certificate.pdf"
                target="_blank">
                <b>Officer’s Certificate</b>
              </Link>{' '}
              along with a document to show ownership percentages). In a revocable trust, typically,
              the grantors are the equity owners. In an irrevocable trust, determination of the
              equity owners can be complicated. Please contact{' '}
              <Link target="_blank" to="mailto:accreditation@capsync.com">
                accreditation@capsync.com
              </Link>{' '}
              for assistance.
            </span>
          ),
          disabled: loader,
          fieldType: 'input-uploadfile',
          fieldSizeLG: 12,
          fieldVisiblity: true,
          dropBoxId: 3
        },
        {
          contentType: 'description',
          content: (
            <div className="row-spacing">
              <p className="cs-regular-body-text-l cs-neutral-90">
                <span className="cs-neutral-90">
                  Upload the past two years' W-2, K-1, 1099 tax forms or the past two years' tax
                  returns (just the first two pages will do).
                </span>{' '}
                <span className="cs-neutral-70">
                  If you don't have these forms, you can upload other evidence below.
                </span>
              </p>
            </div>
          ),
          fieldVisiblity: true
        },
        {
          contentType: 'form',
          methodField: true,
          field: 'terms_1',
          fieldID: 'terms_1',
          fieldPrimaryLabel: (
            <span className="cs-regular-sub-heading-m cs-neutral-90">
              The investor is presenting information showing joint income with a spouse or spousal
              equivalent.
            </span>
          ),
          disabled: loader,
          fieldType: 'input-checkbox',
          additionalClass: 'checkbox-spacing',
          fieldSizeLG: 12,
          fieldVisiblity: true
        },
        {
          contentType: 'form',
          methodField: true,
          field: 'upload_drop_box_1',
          fieldID: 'upload_drop_box_1',
          disabled: loader,
          fieldType: 'input-uploadfile',
          fieldSizeLG: 12,
          fieldVisiblity: true,
          dropBoxId: 1
        },
        {
          contentType: 'form',
          methodField: true,
          field: 'upload_drop_box_2',
          fieldID: 'upload_drop_box_2',
          fieldPrimaryLabel: 'Upload other evidence:',
          disabled: loader,
          fieldType: 'input-uploadfile',
          fieldSizeLG: 12,
          fieldVisiblity: true,
          dropBoxId: 2
        },
        {
          contentType: 'form',
          methodField: true,
          field: 'terms_2',
          fieldID: 'terms_2',
          fieldPrimaryLabel: (
            <span className="cs-regular-sub-heading-m cs-neutral-90">
              I (or my spouse/spousal equivalent and I, as applicable) have a reasonable expectation
              of reaching the income level necessary to qualify as an accredited investor during the
              current year.
            </span>
          ),
          disabled: loader,
          fieldType: 'input-checkbox',
          additionalClass: 'checkbox-spacing',
          fieldSizeLG: 12,
          fieldVisiblity: true
        },
        {
          contentType: 'description',
          content: (
            <div className="row-spacing">
              <div className="cs-regular-body-text-l cs-neutral-90">
                Please enter the amount below if your previous year's income evidence is not yet
                available:
              </div>
              <div className="cs-regular-body-text-l cs-neutral-70">
                An Internal Revenue Service form that reports my(our) income for the recently
                completed year is not available. The amount of income I(we) received for the
                previous year is
              </div>
            </div>
          ),
          fieldVisiblity: true
        },
        {
          contentType: 'form',
          methodField: true,
          field: 'income_amount',
          fieldID: 'income_amount',
          disabled: loader,
          fieldType: 'input-number',
          fieldPlaceholder: '$ 0.00 USD',
          fieldSizeLG: 5,
          fieldVisiblity: true,
          maxVal: 8
        },
        {
          contentType: 'description',
          content: (
            <div className="row-spacing">
              <div className="cs-regular-body-text-l cs-neutral-90">
                Type or edit a note, comment, or response to the reviewer here (Optional):
              </div>
              <div className="cs-regular-body-text-l cs-neutral-70">
                <i>
                  Kindly note that for audit/compliance reasons, all communication between yourself
                  and your reviewing attorney can only be done by entering any questions or comments
                  in the box below. Note that this is optional.
                </i>
              </div>
            </div>
          ),
          fieldVisiblity: true
        },
        {
          contentType: 'form',
          methodField: true,
          field: 'notes',
          fieldID: 'notes',
          disabled: loader,
          fieldType: 'input-textarea',
          fieldPlaceholder: 'Enter your Note',
          fieldSizeLG: 12,
          fieldVisiblity: true
        }
      ];
      break;

    case 18:
      formJSON = [
        {
          contentType: 'description',
          content: (
            <div className="row-spacing">
              <p className="cs-regular-body-text-l cs-neutral-70">
                A director, executive officer, or general partner of the issuer of the securities
                being offered or sold, or a director, executive officer, or general partner of a
                general partner of that issuer.
              </p>
            </div>
          ),
          fieldVisiblity: true
        },
        {
          contentType: 'divider',
          content: <div className="cs-divider"></div>,
          fieldVisiblity: true
        },
        legalNameDesc,
        {
          contentType: 'form',
          methodField: true,
          field: 'legal_name',
          fieldID: 'legal_name',
          fieldPrimaryLabel: 'Legal Name',
          highlightedInLineLabel: 'Please click in the box below to edit the name as needed.',
          disabled: loader,
          fieldType: 'input-text',
          fieldSizeLG: 5,
          fieldVisiblity: true
        },
        {
          contentType: 'divider',
          content: <div className="cs-divider"></div>,
          fieldVisiblity: true
        },
        {
          contentType: 'description',
          content: (
            <div className="row-spacing">
              <p className="cs-regular-body-text-l cs-neutral-70">
                In order to verify your accreditation we need you to upload evidence. Please upload
                the appropriate files below and black-out any sensitive information.
              </p>
            </div>
          ),
          fieldVisiblity: true
        },
        {
          contentType: 'description',
          content: (
            <div className="row-spacing">
              <p className="cs-medium-sub-heading-m cs-danger">
                Upon a successful verification, the expiration date of the verification letter will
                be based on the evidence provided to reviewers, e.g. if a 3rd party letter or a
                monthly statement is 2 months old at the time of submission for review the
                verification letter will expire in 1 month.
              </p>
            </div>
          ),
          fieldVisiblity: true
        },
        {
          contentType: 'form',
          methodField: true,
          field: 'upload_drop_box_2',
          fieldID: 'upload_drop_box_2',
          fieldLabelWithTag: (
            <span className="cs-regular-sub-heading-m cs-neutral-90">
              Please upload evidence of who all the equity owners of the trust are (by uploading
              your trust documentation or this{' '}
              <Link
                className="cs-neutral-100"
                to="https://s3.amazonaws.com/static-clientportalx.com/Verify-Investor-files/All-Owners-Accredited-Officer-Certificate.pdf"
                target="_blank">
                <b>Officer’s Certificate</b>
              </Link>{' '}
              along with a document to show ownership percentages). In a revocable trust, typically,
              the grantors are the equity owners. In an irrevocable trust, determination of the
              equity owners can be complicated. Please contact{' '}
              <Link target="_blank" to="mailto:accreditation@capsync.com">
                accreditation@capsync.com
              </Link>{' '}
              for assistance.
            </span>
          ),
          disabled: loader,
          fieldType: 'input-uploadfile',
          fieldSizeLG: 12,
          fieldVisiblity: true,
          dropBoxId: 2
        },
        {
          contentType: 'form',
          methodField: true,
          field: 'upload_drop_box_1',
          fieldID: 'upload_drop_box_1',
          fieldLabelWithTag: (
            <span className="cs-regular-sub-heading-m cs-neutral-90">
              Upload evidence of position claimed:{' '}
              <span className="cs-regular-sub-heading-m cs-neutral-70 cs-eg">
                &nbsp;(e.g. Incumbency Certificate or Officer's Certificate no more than 90 days
                old) — Sample Officer's Certificate{' '}
                <Link
                  className="cs-neutral-100"
                  to={
                    'https://s3.amazonaws.com/static-clientportalx.com/Verify-Investor-files/OfficerCertificate-including-KE-and-FO.pdf'
                  }
                  target="_blank">
                  Here
                </Link>
              </span>
            </span>
          ),
          disabled: loader,
          fieldType: 'input-uploadfile',
          fieldSizeLG: 12,
          fieldVisiblity: true,
          dropBoxId: 1
        },
        {
          contentType: 'form',
          methodField: true,
          field: 'officers_investment_or_deal_name',
          fieldID: 'officers_investment_or_deal_name',
          disabled: loader,
          fieldType: 'input-text',
          fieldPrimaryLabel:
            'Please enter the investment name or deal name of the company of which you are an officer. (Note the name you entered will appear on your accreditation letter and the letter will be solely for the purpose of this investment.)',
          fieldSizeLG: 12,
          fieldVisiblity: true,
          inputWidth: 'col-lg-5',
          maxVal: 50
        },
        {
          contentType: 'description',
          content: (
            <div className="row-spacing">
              <span className="cs-regular-body-text-l cs-neutral-90">
                Type or edit a note, comment, or response to the reviewer here (Optional):
              </span>
              <p className="cs-regular-body-text-l cs-neutral-70">
                <i>
                  Kindly note that for audit/compliance reasons, all communication between yourself
                  and your reviewing attorney can only be done by entering any questions or comments
                  in the box below. Note that this is optional.
                </i>
              </p>
            </div>
          ),
          fieldVisiblity: true
        },
        {
          contentType: 'form',
          methodField: true,
          field: 'notes',
          fieldID: 'notes',
          disabled: loader,
          fieldType: 'input-textarea',
          fieldPlaceholder: 'Enter your Note',
          fieldSizeLG: 12,
          fieldVisiblity: true
        }
      ];
      break;

    case 19:
      formJSON = [
        {
          contentType: 'description',
          content: (
            <div className="row-spacing">
              <p className="cs-regular-body-text-l cs-neutral-70">
                Any natural person whose individual net worth, or joint net worth with that person's
                spouse or{' '}
                <CapsyncToolTip
                  Child={<u>spousal equivalent</u>}
                  placement={'top'}
                  message={
                    'A cohabitant occupying a relationship generally equivalent to that of a spouse.'
                  }
                  type="text"
                />
                , exceeds $1,000,000 USD.
              </p>
            </div>
          ),
          fieldVisiblity: true
        },
        {
          contentType: 'description',
          content: (
            <div className="cs-regular-body-text-l">
              <p className="cs-regular-body-text-l cs-neutral-70 method-spacing">
                For purposes of calculating net worth:
              </p>
              <p className="cs-regular-body-text-l cs-neutral-70">
                Joint net worth can be the aggregate net worth of the investor and spouse or spousal
                equivalent; assets need not be held jointly to be included in the calculation.
                Reliance on the joint net worth standard of this method does not require that the
                securities be purchased jointly.
              </p>
              <ul className="cs-neutral-90">
                <li className="cs-regular-body-text-l cs-neutral-70">
                  The person's primary residence shall not be included as an asset;
                </li>
                <li className="cs-regular-body-text-l cs-neutral-70">
                  Indebtedness that is secured by the person's primary residence, up to the
                  estimated fair market value of the primary residence at the time of the sale of
                  securities, shall not be included as a liability (except that if the amount of
                  such indebtedness outstanding at the time of sale of securities exceeds the amount
                  outstanding 60 days before such time, other than as a result of the acquisition of
                  the primary residence, the amount of such excess shall be included as a
                  liability); and
                </li>
                <li className="cs-regular-body-text-l cs-neutral-70">
                  Indebtedness that is secured by the person's primary residence in excess of the
                  estimated fair market value of the primary residence at the time of the sale of
                  securities shall be included as a liability.
                </li>
              </ul>
            </div>
          ),
          fieldVisiblity: true
        },
        {
          contentType: 'divider',
          content: <div className="cs-divider"></div>,
          fieldVisiblity: true
        },
        legalNameDesc,
        {
          contentType: 'form',
          methodField: true,
          field: 'legal_name',
          fieldID: 'legal_name',
          fieldPrimaryLabel: 'Legal Name',
          highlightedInLineLabel: 'Please click in the box below to edit the name as needed.',
          disabled: loader,
          fieldType: 'input-text',
          fieldPlaceholder: 'Name',
          fieldSizeLG: 5,
          fieldVisiblity: true
        },
        {
          contentType: 'divider',
          content: <div className="cs-divider"></div>,
          fieldVisiblity: true
        },
        {
          contentType: 'description',
          content: (
            <div className="row-spacing">
              <p className="cs-regular-body-text-l cs-neutral-70">
                In order to verify your accreditation we need you to upload evidence. Please upload
                the appropriate files below and black-out any sensitive information.
              </p>
            </div>
          ),
          fieldVisiblity: true
        },
        {
          contentType: 'description',
          content: (
            <div className="row-spacing">
              <p className="cs-regular-body-text-l cs-neutral-70">
                All evidence (statements/certificates, etc.) must be no older than 90 days with
                limited exceptions (for example, older documents evidencing ownership, tax returns,
                etc.).
              </p>
            </div>
          ),
          fieldVisiblity: true
        },
        {
          contentType: 'form',
          methodField: true,
          field: 'upload_drop_box_2',
          fieldID: 'upload_drop_box_2',
          fieldLabelWithTag: (
            <span className="cs-regular-sub-heading-m cs-neutral-90">
              Please upload evidence of who all the equity owners of the trust are (by uploading
              your trust documentation or this{' '}
              <Link
                className="cs-neutral-100"
                to="https://s3.amazonaws.com/static-clientportalx.com/Verify-Investor-files/All-Owners-Accredited-Officer-Certificate.pdf"
                target="_blank">
                <b>Officer’s Certificate</b>
              </Link>{' '}
              along with a document to show ownership percentages). In a revocable trust, typically,
              the grantors are the equity owners.
            </span>
          ),
          disabled: loader,
          fieldType: 'input-uploadfile',
          fieldSizeLG: 12,
          fieldVisiblity: true,
          dropBoxId: 2
        },
        {
          contentType: 'description',
          content: (
            <div className="row-spacing">
              <p className="cs-regular-body-text-xl cs-neutral-90">
                Individual Net Worth Assessment
              </p>
            </div>
          ),
          fieldVisiblity: true
        },
        {
          contentType: 'divider',
          content: <div className="cs-divider"></div>,
          fieldVisiblity: true
        },
        {
          contentType: 'description',
          content: (
            <div>
              <p className="cs-regular-body-text-l cs-neutral-90 method-spacing">
                Upload Recent Credit Report
              </p>
              <p className="cs-regular-body-text-l cs-neutral-90 method-spacing">
                <CapsyncToolTip
                  width="640px"
                  Child={
                    <span className="cs-regular-body-text-l cs-neutral-70">
                      Why do I need this?{' '}
                    </span>
                  }
                  placement={'right'}
                  type="text"
                  message={
                    <div>
                      <p>
                        {' '}
                        Kindly note that investors must provide their credit report for their net
                        worth verification according to Rule 506C from Regulation D on the SEC
                        website as written below (see link here for a full description of the law).
                        If this is not provided, then, unfortunately, under US federal laws, our
                        reviewing attorneys may not be able to verify you as an accredited investor
                        through this verification method.
                      </p>{' '}
                      <br />
                      <p>
                        {' '}
                        "(B) In regard to whether the purchaser is an accredited investor on the
                        basis of net worth, reviewing one or more of the following types of
                        documentation dated within the prior three months and obtaining a written
                        representation from the purchaser that all liabilities necessary to make a
                        determination of net worth have been
                      </p>
                    </div>
                  }
                />
                <span className="cs-regular-body-text-l cs-danger">
                  This does not affect your credit score or security freezes on your credit.
                </span>
              </p>
              <p className="cs-regular-body-text-l cs-neutral-70 method-spacing">
                You may obtain your credit report from one of the following options:
              </p>
              <ol className="cs-regular-body-text-l cs-neutral-70">
                <li className="method-spacing">
                  Order one free credit report once a year from any of the credit reporting bureaus
                  from{' '}
                  <Link to="https://www.annualcreditreport.com" target="_blank">
                    <u className="cs-neutral-90">https://www.annualcreditreport.com</u>
                  </Link>
                  . On that site, we recommend requesting your report from either Equifax or
                  TransUnion where you can download a PDF copy.
                </li>
                <li>
                  Order credit report from{' '}
                  <Link
                    to="https://connect.experian.com/register/personal.html?c=ptnr-vrfyinv&sg=bs&m=lk&a=verify-investor"
                    target="_blank">
                    <u className="cs-neutral-90">Experian Connect℠</u>
                  </Link>
                  .
                </li>
              </ol>
            </div>
          ),
          fieldVisiblity: true
        },
        {
          contentType: 'form',
          methodField: true,
          field: 'upload_drop_box_1',
          fieldID: 'upload_drop_box_1',
          fieldPrimaryLabel: 'Upload your credit report below from options 1 or 2',
          disabled: loader,
          fieldType: 'input-uploadfile',
          fieldSizeLG: 12,
          fieldVisiblity: true,
          dropBoxId: 1
        },
        {
          contentType: 'description',
          content: (
            <div className="cs-regular-body-text-l">
              <p className="cs-neutral-70 method-spacing">
                <u>
                  If a US credit report exists for you, you must provide it. Most people that live
                  in the US or have assets in the US will have a US credit report. If you do not
                  have a US credit report, upload a credit report equivalent from your own country.
                  If verifying together with a spouse or spousal equivalent, both individuals must
                  provide a credit report.
                </u>
              </p>
            </div>
          ),
          fieldVisiblity: true
        },
        {
          contentType: 'form',
          methodField: true,
          field: 'certify',
          fieldID: 'certify',
          fieldPrimaryLabel: (
            <span className="cs-regular-sub-heading-m cs-neutral-90">
              I certify that no credit report is available.
            </span>
          ),
          disabled: loader,
          fieldType: 'input-checkbox',
          additionalClass: 'checkbox-spacing',
          fieldSizeLG: 12,
          fieldVisiblity: true
        },
        {
          contentType: 'divider',
          content: <div className="cs-divider"></div>,
          fieldVisiblity: true
        },
        {
          contentType: 'description',
          content: (
            <div className="row-spacing">
              <div className="net-worth-asset-libility">
                <p className="cs-regular-body-text-xl cs-neutral-90">
                  Claimed Net Worth: $
                  <NumberFormat
                    value={calculatedNetWorth.toFixed(2)}
                    displayType={'text'}
                    thousandSeparator={true}
                  />{' '}
                  <span>USD</span>
                  <span className="net-worth-info-icon">
                    <CapsyncToolTip
                      Child={'info-outlined'}
                      width="398px"
                      placement={'bottom'}
                      type="icon"
                      size="18"
                      message={'Please Refer to your portfolio for more details on net worth.'}
                    />
                  </span>
                  {error.netWorthError && error.netWorthError !== '' && (
                    <p className="cs-regular-sub-heading-xs cs-danger">{error.netWorthError}</p>
                  )}
                </p>
                <p className="cs-regular-body-text-xl cs-neutral-90">
                  Claimed Net Worth (for Verification): $
                  <NumberFormat
                    value={calculatedNetWorth.toFixed(2)}
                    displayType={'text'}
                    thousandSeparator={true}
                  />{' '}
                  <span>USD</span>
                  <span className="net-worth-info-icon">
                    <CapsyncToolTip
                      Child={'info-outlined'}
                      placement={'bottom'}
                      width="398px"
                      type="icon"
                      size="18"
                      message={'Please Refer to your portfolio for more details on net worth.'}
                    />
                  </span>
                </p>
              </div>
            </div>
          ),
          fieldVisiblity: true
        },
        {
          contentType: 'description',
          content: (
            <div className="cs-regular-body-text-l">
              <span className="cs-neutral-90">
                Type or edit a note, comment, or response to the reviewer here (Optional):
              </span>
              <p className="row-spacing cs-neutral-70">
                <i>
                  Kindly note that for audit/compliance reasons, all communication between yourself
                  and your reviewing attorney can only be done by entering any questions or comments
                  in the box below. Note that this is optional.
                </i>
              </p>
            </div>
          ),
          fieldVisiblity: true
        },
        {
          contentType: 'form',
          methodField: true,
          field: 'notes',
          fieldID: 'notes',
          disabled: loader,
          fieldType: 'input-textarea',
          fieldPlaceholder: 'Enter your Note',
          fieldSizeLG: 12,
          fieldVisiblity: true
        }
      ];
      break;

    case 21:
      formJSON = [
        {
          contentType: 'description',
          content: (
            <div className="row-spacing">
              <p className="cs-regular-body-text-l">
                <span className="cs-neutral-70">
                  I have a special situation and believe that I can be reasonably verified as an
                  accredited investor using principles based methods.
                </span>
                <span className="cs-neutral-90">
                  Please contact
                  <Link href="mailto=accreditation@capsync.com">
                    accreditation@capsync.com
                  </Link>{' '}
                  before you select this method of verification.
                </span>
              </p>
            </div>
          ),
          fieldVisiblity: true
        },
        {
          contentType: 'divider',
          content: <div className="cs-divider"></div>,
          fieldVisiblity: true
        },
        legalNameDesc,
        {
          contentType: 'form',
          methodField: true,
          field: 'legal_name',
          fieldID: 'legal_name',
          fieldPrimaryLabel: 'Legal Name',
          highlightedInLineLabel:
            'Changes may not be made upon completion. Please click in the box below to edit the name as needed.',
          disabled: loader,
          fieldType: 'input-text',
          fieldPlaceholder: 'Name',
          fieldSizeLG: 5,
          fieldVisiblity: true
        },
        {
          contentType: 'divider',
          content: <div className="cs-divider"></div>,
          fieldVisiblity: true
        },
        {
          contentType: 'description',
          content: (
            <div className="row-spacing">
              <p className="cs-regular-body-text-l cs-neutral-70">
                In order to verify your accreditation we need you to upload evidence. Please upload
                the appropriate files below and black-out any sensitive information.
              </p>
            </div>
          ),
          fieldVisiblity: true
        },
        {
          contentType: 'description',
          content: (
            <div className="row-spacing">
              <p className="cs-regular-body-text-l cs-danger">
                Upon a successful verification, the expiration date of the verification letter will
                be based on the evidence provided to reviewers, e.g. if a 3rd party letter or a
                monthly statement is 2 months old at the time of submission for review the
                verification letter will expire in 1 month.
              </p>
            </div>
          ),
          fieldVisiblity: true
        },
        {
          contentType: 'form',
          methodField: true,
          field: 'upload_drop_box_1',
          fieldID: 'upload_drop_box_1',
          fieldLabelWithTag: (
            <React.Fragment>
              <span className="cs-regular-sub-heading-m cs-neutral-90">
                Please explain why you believe an attorney can reasonably verify you and upload
                supporting evidence as appropriate. If you have any questions or need help, please
                email{' '}
              </span>
              <Link href="mailto=accreditation@capsync.com">accreditation@capsync.com</Link>
            </React.Fragment>
          ),
          disabled: loader,
          fieldType: 'input-uploadfile',
          fieldSizeLG: 12,
          fieldVisiblity: true,
          dropBoxId: 1
        },
        {
          contentType: 'description',
          content: (
            <div className="row-spacing">
              <span className="cs-regular-body-text-l cs-neutral-90">
                Type or edit a note, comment, or response to the reviewer here (Optional):
              </span>
              <p className="cs-regular-body-text-l cs-neutral-70">
                <i>
                  Kindly note that for audit/compliance reasons, all communication between yourself
                  and your reviewing attorney can only be done by entering any questions or comments
                  in the box below. Note that this is optional.
                </i>
              </p>
            </div>
          ),
          fieldVisiblity: true
        },
        {
          contentType: 'form',
          methodField: true,
          field: 'notes',
          fieldID: 'notes',
          disabled: loader,
          fieldType: 'input-textarea',
          fieldPlaceholder: 'Enter your Note',
          fieldSizeLG: 12,
          fieldVisiblity: true
        }
      ];
      break;

    case 23:
      formJSON = [
        {
          contentType: 'description',
          content: (
            <div className="row-spacing">
              <p className="cs-regular-body-text-l cs-neutral-70">
                Is Investor a trust, with total assets in excess of $5,000,000 USD, not formed for
                the specific purpose of acquiring the securities offered, whose purchase is directed
                by a person who either alone or with his purchaser representative(s) has such
                knowledge and experience in financial and business matters that he is capable of
                evaluating the merits and risks of the prospective investment.
              </p>
            </div>
          ),
          fieldVisiblity: true
        },
        {
          contentType: 'divider',
          content: <div className="cs-divider"></div>,
          fieldVisiblity: true
        },
        legalNameDesc,
        {
          contentType: 'form',
          methodField: true,
          field: 'legal_name',
          fieldID: 'legal_name',
          fieldPrimaryLabel: 'Legal Name',
          highlightedInLineLabel:
            'Changes may not be made upon completion. Please click in the box below to edit the name as needed.',
          disabled: loader,
          fieldType: 'input-text',
          fieldPlaceholder: 'Name',
          fieldSizeLG: 5,
          fieldVisiblity: true
        },
        {
          contentType: 'divider',
          content: <div className="cs-divider"></div>,
          fieldVisiblity: true
        },
        {
          contentType: 'description',
          content: (
            <div className="row-spacing">
              <p className="cs-regular-body-text-l cs-neutral-70">
                In order to verify your accreditation we need you to upload evidence. Please upload
                the appropriate files below and black-out any sensitive information.
              </p>
            </div>
          ),
          fieldVisiblity: true
        },
        {
          contentType: 'description',
          content: (
            <div className="cs-regular-body-text-l">
              <p className="row-spacing cs-danger">
                Upon a successful verification, the expiration date of the verification letter will
                be based on the evidence provided to reviewers, e.g. if a 3rd party letter or a
                monthly statement is 2 months old at the time of submission for review the
                verification letter will expire in 1 month.
              </p>
            </div>
          ),
          fieldVisiblity: true
        },
        {
          contentType: 'form',
          methodField: true,
          field: 'upload_drop_box_1',
          fieldID: 'upload_drop_box_1',
          fieldPrimaryLabel:
            'Upload a statement that is no later than 90 days old showing your assets in excess of $5,000,000 USD:',
          disabled: loader,
          fieldType: 'input-uploadfile',
          fieldSizeLG: 12,
          fieldVisiblity: true,
          dropBoxId: 1
        },
        {
          contentType: 'form',
          methodField: true,
          field: 'upload_drop_box_2',
          fieldID: 'upload_drop_box_2',
          fieldPrimaryLabel: 'Upload other evidence:',
          disabled: loader,
          fieldType: 'input-uploadfile',
          fieldSizeLG: 12,
          fieldVisiblity: true,
          dropBoxId: 2
        },
        {
          contentType: 'description',
          content: (
            <div className="row-spacing">
              <span className="cs-regular-body-text-l cs-neutral-90">
                Type or edit a note, comment, or response to the reviewer here (Optional):
              </span>
              <p className="cs-regular-body-text-l cs-neutral-70">
                <i>
                  Kindly note that for audit/compliance reasons, all communication between yourself
                  and your reviewing attorney can only be done by entering any questions or comments
                  in the box below. Note that this is optional.
                </i>
              </p>
            </div>
          ),
          fieldVisiblity: true
        },
        {
          contentType: 'form',
          methodField: true,
          field: 'notes',
          fieldID: 'notes',
          disabled: loader,
          fieldType: 'input-textarea',
          fieldPlaceholder: 'Enter your Note',
          fieldSizeLG: 12,
          fieldVisiblity: true
        }
      ];
      break;

    case 24:
      formJSON = [
        {
          contentType: 'description',
          content: (
            <div className="row-spacing">
              <p className="cs-regular-body-text-l cs-neutral-70">
                An entity in which all the equity owners are accredited investors.
              </p>
            </div>
          ),
          fieldVisiblity: true
        },
        {
          contentType: 'divider',
          content: <div className="cs-divider"></div>,
          fieldVisiblity: true
        },
        legalNameDesc,
        {
          contentType: 'form',
          methodField: true,
          field: 'legal_name',
          fieldID: 'legal_name',
          fieldPrimaryLabel: 'Legal Name',
          highlightedInLineLabel:
            'Changes may not be made upon completion. Please click in the box below to edit the name as needed.',
          disabled: loader,
          fieldType: 'input-text',
          fieldPlaceholder: 'Name',
          fieldSizeLG: 5,
          fieldVisiblity: true
        },
        {
          contentType: 'divider',
          content: <div className="cs-divider"></div>,
          fieldVisiblity: true
        },
        {
          contentType: 'form',
          methodField: true,
          field: 'upload_drop_box_1',
          fieldID: 'upload_drop_box_1',
          fieldLabelWithTag: (
            <React.Fragment>
              <span className="cs-regular-sub-heading-m cs-neutral-90">
                Please upload evidence of who all the equity owners of the trust are (by uploading
                your trust documentation or this{' '}
              </span>
              <Link
                to="https://s3.amazonaws.com/static-clientportalx.com/Verify-Investor-files/All-Owners-Accredited-Officer-Certificate.pdf"
                target="_blank"
                className="vi-link-color cursor-pointer">
                <b className="cs-neutral-100">Officer’s Certificate</b>
              </Link>
              <span className="cs-regular-sub-heading-m cs-neutral-90">
                &nbsp; along with a document to show ownership percentages). In a revocable trust,
                typically, the grantors are the equity owners. In an irrevocable trust,
                determination of the equity owners can be complicated. Please contact{' '}
              </span>
              <Link href="mailto=accreditation@capsync.com">accreditation@capsync.com</Link>
              <span className="cs-regular-sub-heading-m cs-neutral-90">&nbsp; for assistance.</span>
            </React.Fragment>
          ),
          disabled: loader,
          fieldType: 'input-uploadfile',
          fieldSizeLG: 12,
          fieldVisiblity: true,
          dropBoxId: 1
        },
        {
          contentType: 'divider',
          content: <div className="cs-divider"></div>,
          fieldVisiblity: true
        },
        {
          contentType: 'description',
          content: (
            <div className="row-spacing">
              <p className="cs-regular-body-text-l">
                <span className="cs-neutral-70">
                  Please upload a verification letter for each equity owner which shows that such
                  equity owner is an accredited investor.{' '}
                </span>
                <span className="cs-danger">
                  To obtain verification letters for each equity owner, please order additional
                  verification requests for each equity owner, or if an issuer is paying for the
                  verifications, please ask them to place additional verification requests for each
                  equity owner. The issuer may need the email address for each equity investor to
                  place the verification requests.{' '}
                </span>
                <span className="cs-neutral-70">
                  Note that multiple verification requests can be sent to the same email address,
                  but different equity owners often prefer to receive their verification request at
                  their own email address for privacy purposes.
                </span>
              </p>
            </div>
          ),
          fieldVisiblity: true
        },
        {
          contentType: 'form',
          methodField: true,
          field: 'upload_drop_box_2',
          fieldID: 'upload_drop_box_2',
          fieldPrimaryLabel: 'Upload verification letters for each equity owner:',
          disabled: loader,
          fieldType: 'input-uploadfile',
          fieldSizeLG: 12,
          fieldVisiblity: true,
          dropBoxId: 2
        },
        {
          contentType: 'form',
          methodField: true,
          field: 'upload_drop_box_3',
          fieldID: 'upload_drop_box_3',
          fieldPrimaryLabel: 'Upload other evidence:',
          disabled: loader,
          fieldType: 'input-uploadfile',
          fieldSizeLG: 12,
          fieldVisiblity: true,
          dropBoxId: 3
        },
        {
          contentType: 'description',
          content: (
            <div className="row-spacing">
              <span className="cs-regular-body-text-l cs-neutral-90">
                Type or edit a note, comment, or response to the reviewer here (Optional):
              </span>
              <p className="cs-regular-body-text-l cs-neutral-70">
                <i>
                  Kindly note that for audit/compliance reasons, all communication between yourself
                  and your reviewing attorney can only be done by entering any questions or comments
                  in the box below. Note that this is optional.
                </i>
              </p>
            </div>
          ),
          fieldVisiblity: true
        },
        {
          contentType: 'form',
          methodField: true,
          field: 'notes',
          fieldID: 'notes',
          disabled: loader,
          fieldType: 'input-textarea',
          fieldPlaceholder: 'Enter your Note',
          fieldSizeLG: 12,
          fieldVisiblity: true
        }
      ];
      break;

    case 27:
      formJSON = [
        {
          contentType: 'description',
          content: (
            <div className="row-spacing">
              <p className="cs-regular-body-text-l cs-neutral-70">
                A natural person who, or a company (including trusts, partnerships, and limited
                liability companies) that, immediately after entering into the contract has at least
                $1,000,000 USD under the management of the investment adviser.
              </p>
            </div>
          ),
          fieldVisiblity: true
        },
        {
          contentType: 'divider',
          content: <div className="cs-divider"></div>,
          fieldVisiblity: true
        },
        legalNameDesc,
        {
          contentType: 'form',
          methodField: true,
          field: 'legal_name',
          fieldID: 'legal_name',
          fieldPrimaryLabel: 'Legal Name',
          highlightedInLineLabel: 'Please click in the box below to edit the name as needed.',
          disabled: loader,
          fieldType: 'input-text',
          fieldPlaceholder: 'Name',
          fieldSizeLG: 5,
          fieldVisiblity: true
        },
        {
          contentType: 'divider',
          content: <div className="cs-divider"></div>,
          fieldVisiblity: true
        },
        {
          contentType: 'description',
          content: (
            <div className="row-spacing">
              <p className="cs-regular-body-text-l cs-neutral-70">
                In order to verify your accreditation we need you to upload evidence. Please upload
                the appropriate files below and black-out any sensitive information.
              </p>
            </div>
          ),
          fieldVisiblity: true
        },
        {
          contentType: 'form',
          methodField: true,
          field: 'investor_type',
          fieldID: 'investor_type',
          fieldLabel: 'Are you investing as an individual or company?',
          disabled: loader,
          fieldType: 'dropdown',
          fieldPlaceholder: 'Select option',
          options: [
            {
              label: 'Individual',
              value: 'Individual'
            },
            {
              label: 'Company',
              value: 'Company'
            }
          ],
          fieldSizeLG: 5,
          fieldOptionsLabelKey: 'label',
          fieldOptionValueKey: 'label',
          fieldVisiblity: true
        },
        {
          contentType: 'form',
          methodField: true,
          field: 'investor_meet_requirement',
          fieldID: 'investor_meet_requirement',
          fieldPrimaryLabel: (
            <span className="cs-regular-sub-heading-s cs-neutral-90">
              Investor is not an investment company and is not an entity that would be an investment
              company but for the exemption offered under Section 3(c)(1) of the Investment Company
              Act.
            </span>
          ),
          disabled: loader,
          fieldType: 'input-checkbox',
          additionalClass: 'checkbox-spacing',
          fieldSizeLG: 12,
          fieldVisiblity: false,
          fieldVisibilityCondition:
            value['investor_type'] && value['investor_type'] === 'Company' ? true : false
        },
        {
          contentType: 'form',
          methodField: true,
          field: 'upload_drop_box_1',
          fieldID: 'upload_drop_box_1',
          fieldPrimaryLabel:
            'Upload evidence that the investor currently has or will have at least $1 million USD assets under management of the adviser once the investor’s subscription is accepted:',
          disabled: loader,
          fieldType: 'input-uploadfile',
          fieldSizeLG: 12,
          fieldVisiblity: true,
          dropBoxId: 1
        },
        {
          contentType: 'form',
          methodField: true,
          field: 'upload_drop_box_2',
          fieldID: 'upload_drop_box_2',
          fieldPrimaryLabel: 'Upload other evidence (optional):',
          disabled: loader,
          fieldType: 'input-uploadfile',
          fieldSizeLG: 12,
          fieldVisiblity: true,
          dropBoxId: 2
        },
        {
          contentType: 'description',
          content: (
            <div className="row-spacing">
              <span className="cs-regular-body-text-l cs-neutral-90">
                Type or edit a note, comment, or response to the reviewer here (Optional):
              </span>
              <p className="cs-regular-body-text-l cs-neutral-70">
                <i>
                  Kindly note that for audit/compliance reasons, all communication between yourself
                  and your reviewing attorney can only be done by entering any questions or comments
                  in the box below. Note that this is optional.
                </i>
              </p>
            </div>
          ),
          fieldVisiblity: true
        },
        {
          contentType: 'form',
          methodField: true,
          field: 'notes',
          fieldID: 'notes',
          disabled: loader,
          fieldType: 'input-textarea',
          fieldPlaceholder: 'Enter your Note',
          fieldSizeLG: 12,
          fieldVisiblity: true
        }
      ];
      break;

    case 28:
      formJSON = [
        {
          contentType: 'description',
          content: (
            <div className="row-spacing">
              <p className="cs-regular-body-text-l cs-neutral-70">
                A natural person who, or a company (including trusts, partnerships, and limited
                liability companies) that, at the time of completing this form has a net worth
                (together, in the case of a natural person, with assets held jointly with a spouse)
                of more than $2,100,000 USD.
              </p>
            </div>
          ),
          fieldVisiblity: true
        },
        {
          contentType: 'divider',
          content: <div className="cs-divider"></div>,
          fieldVisiblity: true
        },
        legalNameDesc,
        {
          contentType: 'form',
          methodField: true,
          field: 'legal_name',
          fieldID: 'legal_name',
          fieldPrimaryLabel: 'Legal Name',
          highlightedInLineLabel: 'Please click in the box below to edit the name as needed.',
          disabled: loader,
          fieldType: 'input-text',
          fieldPlaceholder: 'Name',
          fieldSizeLG: 5,
          fieldVisiblity: true
        },
        {
          contentType: 'divider',
          content: <div className="cs-divider"></div>,
          fieldVisiblity: true
        },
        {
          contentType: 'form',
          methodField: true,
          field: 'investor_type',
          fieldID: 'investor_type',
          fieldLabel: 'Are you investing as an individual or company?',
          disabled: loader,
          fieldType: 'dropdown',
          fieldPlaceholder: 'Select option',
          options: [
            {
              label: 'Individual',
              value: 'Individual'
            },
            {
              label: 'Company',
              value: 'Company'
            }
          ],
          fieldSizeLG: 5,
          fieldOptionsLabelKey: 'label',
          fieldOptionValueKey: 'label',
          fieldVisiblity: true
        },
        {
          contentType: 'description',
          content: (
            <div className="row-spacing">
              <p className="cs-regular-body-text-l cs-neutral-70">
                In order to verify your accreditation we need you to upload evidence. Please upload
                the appropriate files below and black-out any sensitive information.
              </p>
            </div>
          ),
          fieldVisiblity: false,
          fieldVisibilityCondition: value['investor_type'] !== 'Company' ? true : false
        },
        {
          contentType: 'description',
          content: (
            <div className="row-spacing">
              <p className="cs-regular-body-text-xl cs-neutral-90">
                Individual Net Worth Assessment
              </p>
            </div>
          ),
          fieldVisiblity: false,
          fieldVisibilityCondition: value['investor_type'] !== 'Company' ? true : false
        },
        {
          contentType: 'divider',
          content: <div className="cs-divider"></div>,
          fieldVisiblity: false,
          fieldVisibilityCondition: value['investor_type'] !== 'Company' ? true : false
        },
        {
          contentType: 'description',
          content: (
            <div className="row-spacing">
              <div className="upload-credit-report">
                <p className="cs-regular-body-text-l cs-neutral-90">Upload Recent Credit Report</p>
                <p className="cs-regular-body-text-l cs-neutral-90">
                  <CapsyncToolTip
                    width="640px"
                    Child={
                      <span className="cs-regular-body-text-l cs-neutral-70">
                        <u>Why do I need this?</u>{' '}
                      </span>
                    }
                    placement={'right'}
                    type="text"
                    message={
                      <div>
                        <p>
                          {' '}
                          Kindly note that investors must provide their credit report for their net
                          worth verification according to Rule 506C from Regulation D on the SEC
                          website as written below (see link here for a full description of the
                          law). If this is not provided, then, unfortunately, under US federal laws,
                          our reviewing attorneys may not be able to verify you as an accredited
                          investor through this verification method.
                        </p>{' '}
                        <br />
                        <p>
                          {' '}
                          "(B) In regard to whether the purchaser is an accredited investor on the
                          basis of net worth, reviewing one or more of the following types of
                          documentation dated within the prior three months and obtaining a written
                          representation from the purchaser that all liabilities necessary to make a
                          determination of net worth have been
                        </p>
                      </div>
                    }
                  />
                  <span className="cs-regular-body-text-l cs-danger">
                    This does not affect your credit score or security freezes on your credit.
                  </span>
                </p>
                <p className="cs-regular-body-text-l cs-neutral-70">
                  You may obtain your credit report from one of the following options:
                </p>
                <ol className="cs-regular-body-text-l cs-neutral-70">
                  <li>
                    Order one free credit report once a year from any of the credit reporting
                    bureaus from{' '}
                    <Link to="https://www.annualcreditreport.com" target="_blank">
                      <u className="cs-neutral-90">https://www.annualcreditreport.com</u>
                    </Link>
                    . On that site, we recommend requesting your report from either Equifax or
                    TransUnion where you can download a PDF copy.
                  </li>
                  <li>
                    Order credit report from{' '}
                    <Link
                      to="https://connect.experian.com/register/personal.html?c=ptnr-vrfyinv&sg=bs&m=lk&a=verify-investor"
                      target="_blank">
                      <u className="cs-neutral-90">Experian Connect℠</u>
                    </Link>
                    .
                  </li>
                </ol>
              </div>
            </div>
          ),
          fieldVisiblity: false,
          fieldVisibilityCondition: value['investor_type'] !== 'Company' ? true : false
        },
        {
          contentType: 'form',
          methodField: true,
          field: 'company_checkbox',
          fieldID: 'company_checkbox',
          fieldPrimaryLabel: (
            <span className="cs-regular-sub-heading-m cs-neutral-90">
              Investor is not an investment company and is not an entity that would be an investment
              company but for the exemption offered under Section 3(c)(1) of the Investment Company
              Act.
            </span>
          ),
          disabled: loader,
          fieldType: 'input-checkbox',
          additionalClass: 'checkbox-spacing',
          fieldSizeLG: 12,
          fieldVisiblity: false,
          fieldVisibilityCondition:
            value['investor_type'] && value['investor_type'] === 'Company' ? true : false
        },
        {
          contentType: 'description',
          content: (
            <div className="row-spacing">
              <p className="cs-regular-body-text-l cs-neutral-70">
                In order to verify your accreditation we need you to upload evidence. Please upload
                the appropriate files below and black-out any sensitive information.
              </p>
            </div>
          ),
          fieldVisiblity: false,
          fieldVisibilityCondition:
            value['investor_type'] && value['investor_type'] === 'Company' ? true : false
        },
        {
          contentType: 'description',
          content: (
            <div className="row-spacing">
              <p className="cs-regular-body-text-xl cs-neutral-90">Net Worth Assessment</p>
            </div>
          ),
          fieldVisiblity: false,
          fieldVisibilityCondition:
            value['investor_type'] && value['investor_type'] === 'Company' ? true : false
        },
        {
          contentType: 'divider',
          content: <div className="cs-divider"></div>,
          fieldVisiblity: false,
          fieldVisibilityCondition:
            value['investor_type'] && value['investor_type'] === 'Company' ? true : false
        },
        {
          contentType: 'form',
          methodField: true,
          field: 'upload_drop_box_1',
          fieldID: 'upload_drop_box_1',
          fieldPrimaryLabel:
            value['investor_type'] && value['investor_type'] === 'Company'
              ? 'Please upload the appropriate files below'
              : 'Upload your credit report below from options 1 or 2',
          disabled: loader,
          fieldType: 'input-uploadfile',
          fieldSizeLG: 12,
          dropBoxId: 1,
          fieldVisiblity: true
        },
        {
          contentType: 'description',
          content: (
            <div className="row-spacing cs-regular-body-text-l">
              <p className="cs-neutral-70">
                <u>
                  If a US credit report exists for you, you must provide it. Most people that live
                  in the US or have assets in the US will have a US credit report. If you do not
                  have a US credit report, upload a credit report equivalent from your own country.
                  If verifying together with a spouse or spousal equivalent, both individuals must
                  provide a credit report.
                </u>
              </p>
            </div>
          ),
          fieldVisiblity: false,
          fieldVisibilityCondition: value['investor_type'] !== 'Company' ? true : false
        },
        {
          contentType: 'form',
          methodField: true,
          field: 'certify',
          fieldID: 'certify',
          fieldPrimaryLabel: (
            <span className="cs-regular-sub-heading-m cs-neutral-90">
              I certify that no credit report is available.
            </span>
          ),
          disabled: loader,
          fieldType: 'input-checkbox',
          additionalClass: 'checkbox-spacing',
          fieldSizeLG: 12,
          fieldVisiblity: false,
          fieldVisibilityCondition: value['investor_type'] !== 'Company' ? true : false
        },
        {
          contentType: 'form',
          methodField: true,
          field: 'liability',
          fieldID: 'liability',
          fieldPrimaryLabel: (
            <span className="cs-regular-sub-heading-m cs-neutral-90">
              All outstanding liabilities of the entity have been disclosed.
            </span>
          ),
          disabled: loader,
          fieldType: 'input-checkbox',
          additionalClass: 'checkbox-spacing',
          fieldSizeLG: 12,
          fieldVisiblity: false,
          fieldVisibilityCondition:
            value['investor_type'] && value['investor_type'] === 'Company' ? true : false
        },
        {
          contentType: 'divider',
          content: <div className="cs-divider"></div>,
          fieldVisiblity: true
        },
        {
          contentType: 'description',
          content: (
            <div className="row-spacing">
              <div className="net-worth-asset-libility">
                <p className="cs-regular-body-text-xl cs-neutral-90">
                  Net Worth: $
                  <NumberFormat
                    value={calculatedNetWorth.toFixed(2)}
                    displayType={'text'}
                    thousandSeparator={true}
                  />{' '}
                  <span>USD</span>
                  <span className="net-worth-info-icon">
                    <CapsyncToolTip
                      Child={'info-outlined'}
                      width="398px"
                      placement={'bottom'}
                      type="icon"
                      size="18"
                      message={'Please Refer to your portfolio for more details on net worth.'}
                    />
                  </span>
                  {error.netWorthError && error.netWorthError !== '' && (
                    <p className="cs-regular-sub-heading-xs cs-danger">{error.netWorthError}</p>
                  )}
                </p>
                <p className="cs-regular-body-text-xl cs-neutral-90">
                  Net Worth (for Verification): $
                  <NumberFormat
                    value={calculatedNetWorth.toFixed(2)}
                    displayType={'text'}
                    thousandSeparator={true}
                  />{' '}
                  <span>USD</span>
                  <span className="net-worth-info-icon">
                    <CapsyncToolTip
                      Child={'info-outlined'}
                      placement={'bottom'}
                      width="398px"
                      type="icon"
                      size="18"
                      message={'Please Refer to your portfolio for more details on net worth.'}
                    />
                  </span>
                </p>
              </div>
            </div>
          ),
          fieldVisiblity: true
        },
        {
          contentType: 'description',
          content: (
            <div className="row-spacing">
              <span className="cs-regular-body-text-l cs-neutral-90">
                Type or edit a note, comment, or response to the reviewer here (Optional):
              </span>
              <p className="cs-regular-body-text-l cs-neutral-70">
                <i>
                  Kindly note that for audit/compliance reasons, all communication between yourself
                  and your reviewing attorney can only be done by entering any questions or comments
                  in the box below. Note that this is optional.
                </i>
              </p>
            </div>
          ),
          fieldVisiblity: true
        },
        {
          contentType: 'form',
          methodField: true,
          field: 'notes',
          fieldID: 'notes',
          disabled: loader,
          fieldType: 'input-textarea',
          fieldPlaceholder: 'Enter your Note',
          fieldSizeLG: 12,
          fieldVisiblity: true
        }
      ];
      break;

    case 29:
      formJSON = [
        {
          contentType: 'description',
          content: (
            <div className="row-spacing">
              <p className="cs-regular-body-text-l cs-neutral-70">
                A natural person who, or a company (including trusts, partnerships, and limited
                liability companies) that, at the time of completing this form is a qualified
                purchaser as defined in section 2(a)(51)(A) of the Investment Company Act of 1940
                (15 U.S.C. 80a-2(a)(51)(A)) at the time of completing this form.
              </p>
            </div>
          ),
          fieldVisiblity: true
        },
        {
          contentType: 'divider',
          content: <div className="cs-divider"></div>,
          fieldVisiblity: true
        },
        legalNameDesc,
        {
          contentType: 'form',
          methodField: true,
          field: 'legal_name',
          fieldID: 'legal_name',
          fieldPrimaryLabel: 'Legal Name',
          highlightedInLineLabel: 'Please click in the box below to edit the name as needed.',
          disabled: loader,
          fieldType: 'input-text',
          fieldPlaceholder: 'Name',
          fieldSizeLG: 5,
          fieldVisiblity: true
        },
        {
          contentType: 'divider',
          content: <div className="cs-divider"></div>,
          fieldVisiblity: true
        },
        {
          contentType: 'description',
          content: (
            <div className="row-spacing">
              <p className="cs-regular-body-text-l cs-neutral-70">
                In order to verify your accreditation we need you to upload evidence. Please upload
                the appropriate files below and black-out any sensitive information.
              </p>
            </div>
          ),
          fieldVisiblity: true
        },
        {
          contentType: 'form',
          methodField: true,
          field: 'investor_type',
          fieldID: 'investor_type',
          fieldLabel: 'Are you investing as an individual or company?',
          disabled: loader,
          fieldType: 'dropdown',
          fieldPlaceholder: 'Select option',
          options: [
            {
              label: 'Individual',
              value: 'Individual'
            },
            {
              label: 'Company',
              value: 'Company'
            }
          ],
          fieldSizeLG: 5,
          fieldOptionsLabelKey: 'label',
          fieldOptionValueKey: 'label',
          fieldVisiblity: true
        },
        {
          contentType: 'form',
          methodField: true,
          field: 'terms',
          fieldID: 'terms',
          fieldPrimaryLabel: (
            <span className="cs-regular-sub-heading-s cs-neutral-90">
              Investor is not an investment company and is not an entity that would be an investment
              company but for the exemption offered under Section 3(c)(1) of the Investment Company
              Act.
            </span>
          ),
          disabled: loader,
          fieldType: 'input-checkbox',
          additionalClass: 'checkbox-spacing',
          fieldSizeLG: 12,
          fieldVisiblity: false,
          fieldVisibilityCondition:
            value['investor_type'] && value['investor_type'] === 'Company' ? true : false
        },
        {
          contentType: 'form',
          methodField: true,
          field: 'upload_drop_box_1',
          fieldID: 'upload_drop_box_1',
          fieldLabelWithTag: (
            <span className="cs-regular-sub-heading-m cs-neutral-90">
              Upload Qualified Purchaser Certificate:{' '}
              <span className="cs-regular-sub-heading-m cs-neutral-70 cs-eg">
                If you don’t have this, you may ask your issuer to issue new qualified purchaser
                verification request(s) for you.
              </span>
            </span>
          ),
          disabled: false,
          fieldType: 'input-uploadfile',
          fieldSizeLG: 12,
          fieldVisiblity: true,
          dropBoxId: 1
        },
        {
          contentType: 'form',
          methodField: true,
          field: 'upload_drop_box_2',
          fieldID: 'upload_drop_box_2',
          fieldPrimaryLabel: 'Upload other evidence (optional):',
          disabled: loader,
          fieldType: 'input-uploadfile',
          fieldSizeLG: 12,
          fieldVisiblity: true,
          dropBoxId: 2
        },
        {
          contentType: 'description',
          content: (
            <div className="row-spacing">
              <span className="cs-regular-body-text-l cs-neutral-90">
                Type or edit a note, comment, or response to the reviewer here (Optional):
              </span>
              <p className="cs-regular-body-text-l cs-neutral-70">
                <i>
                  Kindly note that for audit/compliance reasons, all communication between yourself
                  and your reviewing attorney can only be done by entering any questions or comments
                  in the box below. Note that this is optional.
                </i>
              </p>
            </div>
          ),
          fieldVisiblity: true
        },
        {
          contentType: 'form',
          methodField: true,
          field: 'notes',
          fieldID: 'notes',
          disabled: loader,
          fieldType: 'input-textarea',
          fieldPlaceholder: 'Enter your Note',
          fieldSizeLG: 12,
          fieldVisiblity: true
        }
      ];
      break;

    case 30:
      formJSON = [
        {
          contentType: 'description',
          content: (
            <div className="row-spacing">
              <p className="cs-regular-body-text-l cs-neutral-70">
                A natural person who at the time of completing this form is an executive officer,
                director, trustee, general partner, or person serving in a similar capacity, of the
                investment adviser.
              </p>
            </div>
          ),
          fieldVisiblity: true
        },
        {
          contentType: 'divider',
          content: <div className="cs-divider"></div>,
          fieldVisiblity: true
        },
        legalNameDesc,
        {
          contentType: 'form',
          methodField: true,
          field: 'legal_name',
          fieldID: 'legal_name',
          fieldPrimaryLabel: 'Legal Name',
          highlightedInLineLabel: 'Please click in the box below to edit the name as needed.',
          disabled: loader,
          fieldType: 'input-text',
          fieldPlaceholder: 'Name',
          fieldSizeLG: 5,
          fieldVisiblity: true
        },
        {
          contentType: 'divider',
          content: <div className="cs-divider"></div>,
          fieldVisiblity: true
        },
        {
          contentType: 'description',
          content: (
            <div className="row-spacing">
              <p className="cs-regular-body-text-l cs-neutral-70">
                In order to verify your accreditation we need you to upload evidence. Please upload
                the appropriate files below and black-out any sensitive information.
              </p>
            </div>
          ),
          fieldVisiblity: true
        },
        {
          contentType: 'form',
          methodField: true,
          field: 'upload_drop_box_1',
          fieldID: 'upload_drop_box_1',
          fieldPrimaryLabel: 'Upload evidence of such person’s qualifying position:',
          disabled: loader,
          fieldType: 'input-uploadfile',
          fieldSizeLG: 12,
          fieldVisiblity: true,
          dropBoxId: 1
        },
        {
          contentType: 'form',
          methodField: true,
          field: 'upload_drop_box_2',
          fieldID: 'upload_drop_box_2',
          fieldPrimaryLabel: 'Upload other evidence (optional):',
          disabled: loader,
          fieldType: 'input-uploadfile',
          fieldSizeLG: 12,
          fieldVisiblity: true,
          dropBoxId: 2
        },
        {
          contentType: 'description',
          content: (
            <div className="row-spacing">
              <span className="cs-regular-body-text-l cs-neutral-90">
                Type or edit a note, comment, or response to the reviewer here (Optional):
              </span>
              <p className="cs-regular-body-text-l cs-neutral-70">
                <i>
                  Kindly note that for audit/compliance reasons, all communication between yourself
                  and your reviewing attorney can only be done by entering any questions or comments
                  in the box below. Note that this is optional.
                </i>
              </p>
            </div>
          ),
          fieldVisiblity: true
        },
        {
          contentType: 'form',
          methodField: true,
          field: 'notes',
          fieldID: 'notes',
          disabled: loader,
          fieldType: 'input-textarea',
          fieldPlaceholder: 'Enter your Note',
          fieldSizeLG: 12,
          fieldVisiblity: true
        }
      ];
      break;

    case 31:
      formJSON = [
        {
          contentType: 'description',
          content: (
            <div className="row-spacing">
              <p className="cs-regular-body-text-l cs-neutral-70">
                A natural person who at the time of completing this form is an employee of the
                investment adviser (other than an employee performing solely clerical, secretarial
                or administrative functions with regard to the investment adviser) who, in
                connection with his or her regular functions or duties, participates in the
                investment activities of such investment adviser, provided that such employee has
                been performing such functions and duties for or on behalf of the investment
                adviser, or substantially similar functions or duties for or on behalf of another
                company for at least 12 months.
              </p>
            </div>
          ),
          fieldVisiblity: true
        },
        {
          contentType: 'divider',
          content: <div className="cs-divider"></div>,
          fieldVisiblity: true
        },
        legalNameDesc,
        {
          contentType: 'form',
          methodField: true,
          field: 'legal_name',
          fieldID: 'legal_name',
          fieldPrimaryLabel: 'Legal Name',
          highlightedInLineLabel: 'Please click in the box below to edit the name as needed.',
          disabled: loader,
          fieldType: 'input-text',
          fieldPlaceholder: 'Name',
          fieldSizeLG: 5,
          fieldVisiblity: true
        },
        {
          contentType: 'divider',
          content: <div className="cs-divider"></div>,
          fieldVisiblity: true
        },
        {
          contentType: 'description',
          content: (
            <div className="row-spacing">
              <p className="cs-regular-body-text-l cs-neutral-70">
                In order to verify your accreditation we need you to upload evidence. Please upload
                the appropriate files below and black-out any sensitive information.
              </p>
            </div>
          ),
          fieldVisiblity: true
        },
        {
          contentType: 'form',
          methodField: true,
          field: 'upload_drop_box_1',
          fieldID: 'upload_drop_box_1',
          fieldPrimaryLabel:
            'Upload paystub, or letter from the employer(s) certifying that the investment adviser has been with them for at least 12 months:',
          disabled: loader,
          fieldType: 'input-uploadfile',
          fieldSizeLG: 12,
          fieldVisiblity: true,
          dropBoxId: 1
        },
        {
          contentType: 'form',
          methodField: true,
          field: 'upload_drop_box_2',
          fieldID: 'upload_drop_box_2',
          fieldPrimaryLabel: 'Upload other evidence (optional):',
          disabled: loader,
          fieldType: 'input-uploadfile',
          fieldSizeLG: 12,
          fieldVisiblity: true,
          dropBoxId: 2
        },
        {
          contentType: 'description',
          content: (
            <div className="row-spacing">
              <span className="cs-regular-body-text-l cs-neutral-90">
                Type or edit a note, comment, or response to the reviewer here (Optional):
              </span>
              <p className="cs-regular-body-text-l cs-neutral-70">
                <i>
                  Kindly note that for audit/compliance reasons, all communication between yourself
                  and your reviewing attorney can only be done by entering any questions or comments
                  in the box below. Note that this is optional.
                </i>
              </p>
            </div>
          ),
          fieldVisiblity: true
        },
        {
          contentType: 'form',
          methodField: true,
          field: 'notes',
          fieldID: 'notes',
          disabled: loader,
          fieldType: 'input-textarea',
          fieldPlaceholder: 'Enter your Note',
          fieldSizeLG: 12,
          fieldVisiblity: true
        }
      ];
      break;

    case 32:
      formJSON = [
        {
          contentType: 'description',
          content: (
            <div className="row-spacing">
              <p className="cs-regular-body-text-l cs-neutral-70">
                (i) A natural person who owns (either individually or jointly with such person’s
                spouse) not less than $5,000,000 USD in Net Investments.
              </p>
            </div>
          ),
          fieldVisiblity: true
        },
        {
          contentType: 'divider',
          content: <div className="cs-divider"></div>,
          fieldVisiblity: true
        },
        legalNameDesc,
        {
          contentType: 'form',
          methodField: true,
          field: 'legal_name',
          fieldID: 'legal_name',
          fieldPrimaryLabel: 'Legal Name',
          highlightedInLineLabel: 'Please click in the box below to edit the name as needed.',
          disabled: loader,
          fieldType: 'input-text',
          fieldPlaceholder: 'Name',
          fieldSizeLG: 5,
          fieldVisiblity: true
        },
        {
          contentType: 'divider',
          content: <div className="cs-divider"></div>,
          fieldVisiblity: true
        },
        {
          contentType: 'description',
          content: (
            <div className="row-spacing">
              <p className="cs-regular-body-text-l cs-neutral-70">
                In order to verify your accreditation we need you to upload evidence. Please upload
                the appropriate files below and black-out any sensitive information.
              </p>
            </div>
          ),
          fieldVisiblity: true
        },
        {
          contentType: 'description',
          content: (
            <div className="row-spacing">
              <p className="cs-regular-body-text-l">
                <Link
                  to={
                    'https://s3.amazonaws.com/static-clientportalx.com/Verify-Investor-files/Net-investments.pdf'
                  }
                  target="_blank"
                  className="vi-link-color">
                  <u className="cs-neutral-100">
                    Examples of Net Investments and how these are calculated.
                  </u>
                </Link>
              </p>
            </div>
          ),
          fieldVisiblity: true
        },
        {
          contentType: 'form',
          methodField: true,
          field: 'upload_drop_box_1',
          fieldID: 'upload_drop_box_1',
          fieldPrimaryLabel:
            'Upload evidence of ownership of at least $5,000,000 USD in Net Investments:',
          disabled: loader,
          fieldType: 'input-uploadfile',
          fieldSizeLG: 12,
          fieldVisiblity: true,
          dropBoxId: 1
        },
        {
          contentType: 'form',
          methodField: true,
          field: 'upload_drop_box_2',
          fieldID: 'upload_drop_box_2',
          fieldPrimaryLabel: 'Upload other evidence (optional):',
          disabled: loader,
          fieldType: 'input-uploadfile',
          fieldSizeLG: 12,
          fieldVisiblity: true,
          dropBoxId: 2
        },
        {
          contentType: 'description',
          content: (
            <div className="row-spacing">
              <span className="cs-regular-body-text-l cs-neutral-90">
                Type or edit a note, comment, or response to the reviewer here (Optional):
              </span>
              <p className="cs-regular-body-text-l cs-neutral-70">
                <i>
                  Kindly note that for audit/compliance reasons, all communication between yourself
                  and your reviewing attorney can only be done by entering any questions or comments
                  in the box below. Note that this is optional.
                </i>
              </p>
            </div>
          ),
          fieldVisiblity: true
        },
        {
          contentType: 'form',
          methodField: true,
          field: 'notes',
          fieldID: 'notes',
          disabled: loader,
          fieldType: 'input-textarea',
          fieldPlaceholder: 'Enter your Note',
          fieldSizeLG: 12,
          fieldVisiblity: true
        }
      ];
      break;

    case 33:
      formJSON = [
        {
          contentType: 'description',
          content: (
            <div className="row-spacing">
              <p className="cs-regular-body-text-l cs-neutral-70">
                (ii) Any company that owns not less than $5,000,000 USD in Net Investments and that
                is owned directly or indirectly by or for 2 or more natural persons who are related
                as siblings or spouse (including former spouses), or direct lineal descendants by
                birth or adoption, spouses of such persons, the estates of such persons, or
                foundations, charitable organizations, or trusts established by or for the benefit
                of such persons.
              </p>
            </div>
          ),
          fieldVisiblity: true
        },
        {
          contentType: 'divider',
          content: <div className="cs-divider"></div>,
          fieldVisiblity: true
        },
        legalNameDesc,
        {
          contentType: 'form',
          methodField: true,
          field: 'legal_name',
          fieldID: 'legal_name',
          fieldPrimaryLabel: 'Legal Name',
          highlightedInLineLabel: 'Please click in the box below to edit the name as needed.',
          disabled: loader,
          fieldType: 'input-text',
          fieldPlaceholder: 'Name',
          fieldSizeLG: 5,
          fieldVisiblity: true
        },
        {
          contentType: 'divider',
          content: <div className="cs-divider"></div>,
          fieldVisiblity: true
        },
        {
          contentType: 'description',
          content: (
            <div className="row-spacing">
              <p className="cs-regular-body-text-l cs-neutral-70">
                In order to verify your accreditation we need you to upload evidence. Please upload
                the appropriate files below and black-out any sensitive information.
              </p>
            </div>
          ),
          fieldVisiblity: true
        },
        {
          contentType: 'description',
          content: (
            <div className="row-spacing">
              <p className="cs-regular-body-text-l">
                <Link
                  to={
                    'https://s3.amazonaws.com/static-clientportalx.com/Verify-Investor-files/Net-investments.pdf'
                  }
                  target="_blank"
                  className="vi-link-color">
                  <u className="cs-neutral-90">
                    Examples of Net Investments and how these are calculated.
                  </u>
                </Link>
              </p>
            </div>
          ),
          fieldVisiblity: true
        },
        {
          contentType: 'form',
          methodField: true,
          field: 'certify',
          fieldID: 'certify',
          fieldPrimaryLabel: (
            <span className="cs-regular-sub-heading-m cs-neutral-90">
              I certify, represent and warrant that, with respect to the company making the
              investment:
            </span>
          ),
          disabled: loader,
          fieldType: 'input-checkbox',
          additionalClass: 'checkbox-spacing',
          fieldSizeLG: 12,
          fieldVisiblity: true
        },
        {
          contentType: 'description',
          content: (
            <div className="cs-regular-body-text-l">
              <ul className="cs-neutral-90">
                <li>it was not formed for the purpose of investing in the investment;</li>
                <li>
                  it has not and will not invest more than 40% of its total assets in the
                  investment; and
                </li>
                <li>
                  no investor in or beneficiary of the company can independently decide whether or
                  not to participate in any particular investment made by the company.
                </li>
              </ul>
            </div>
          ),
          fieldVisiblity: true
        },
        {
          contentType: 'form',
          methodField: true,
          field: 'upload_drop_box_1',
          fieldID: 'upload_drop_box_1',
          fieldPrimaryLabel: 'Upload evidence of at least $5,000,000 USD in Net Investments:',
          disabled: loader,
          fieldType: 'input-uploadfile',
          fieldSizeLG: 12,
          fieldVisiblity: true,
          dropBoxId: 1
        },
        {
          contentType: 'form',
          methodField: true,
          field: 'upload_drop_box_2',
          fieldID: 'upload_drop_box_2',
          fieldPrimaryLabel: 'Upload other evidence (optional):',
          disabled: loader,
          fieldType: 'input-uploadfile',
          fieldSizeLG: 12,
          fieldVisiblity: true,
          dropBoxId: 2
        },
        {
          contentType: 'description',
          content: (
            <div className="row-spacing">
              <span className="cs-regular-body-text-l cs-neutral-90">
                Type or edit a note, comment, or response to the reviewer here (Optional):
              </span>
              <p className="cs-regular-body-text-l cs-neutral-70">
                <i>
                  Kindly note that for audit/compliance reasons, all communication between yourself
                  and your reviewing attorney can only be done by entering any questions or comments
                  in the box below. Note that this is optional.
                </i>
              </p>
            </div>
          ),
          fieldVisiblity: true
        },
        {
          contentType: 'form',
          methodField: true,
          field: 'notes',
          fieldID: 'notes',
          disabled: loader,
          fieldType: 'input-textarea',
          fieldPlaceholder: 'Enter your Note',
          fieldSizeLG: 12,
          fieldVisiblity: true
        }
      ];
      break;

    case 34:
      formJSON = [
        {
          contentType: 'description',
          content: (
            <div className="row-spacing">
              <p className="cs-regular-body-text-l cs-neutral-70">
                (iii) Any trust that is not covered by clause (ii), as to which the trustee or other
                person authorized to make decisions with respect to the trust, and each settlor or
                other person who has contributed assets to the trust, is a qualified purchaser under
                clause (i), (ii), (iv), or (v).
              </p>
            </div>
          ),
          fieldVisiblity: true
        },
        {
          contentType: 'divider',
          content: <div className="cs-divider"></div>,
          fieldVisiblity: true
        },
        legalNameDesc,
        {
          contentType: 'form',
          methodField: true,
          field: 'legal_name',
          fieldID: 'legal_name',
          fieldPrimaryLabel: 'Legal Name',
          highlightedInLineLabel: 'Please click in the box below to edit the name as needed.',
          disabled: loader,
          fieldType: 'input-text',
          fieldPlaceholder: 'Name',
          fieldSizeLG: 5,
          fieldVisiblity: true
        },
        {
          contentType: 'divider',
          content: <div className="cs-divider"></div>,
          fieldVisiblity: true
        },
        {
          contentType: 'description',
          content: (
            <div className="row-spacing">
              <p className="cs-regular-body-text-l cs-neutral-70">
                In order to verify your accreditation we need you to upload evidence. Please upload
                the appropriate files below and black-out any sensitive information.
              </p>
            </div>
          ),
          fieldVisiblity: true
        },
        {
          contentType: 'form',
          methodField: true,
          field: 'certify',
          fieldID: 'certify',
          fieldPrimaryLabel: (
            <span className="cs-regular-sub-heading-m cs-neutral-90">
              I certify, represent and warrant that, with respect to the trust:
            </span>
          ),
          disabled: loader,
          fieldType: 'input-checkbox',
          additionalClass: 'checkbox-spacing',
          fieldSizeLG: 12,
          fieldVisiblity: true
        },
        {
          contentType: 'description',
          content: (
            <div className="cs-regular-body-text-l">
              <ul className="cs-neutral-90">
                <li>it was not formed for the purpose of investing in the investment;</li>
                <li>
                  it has not and will not invest more than 40% of its total assets in the
                  investment; and
                </li>
                <li>
                  no investor in or beneficiary of the trust can independently decide whether or not
                  to participate in any particular investment made by the trust.
                </li>
              </ul>
            </div>
          ),
          fieldVisiblity: true
        },
        {
          contentType: 'form',
          methodField: true,
          field: 'upload_drop_box_1',
          fieldID: 'upload_drop_box_1',
          fieldPrimaryLabel:
            'Upload Qualified Purchaser certificates for each trustee, settlor and the authorized person. If you don’t have this, you may ask your issuer to issue new qualified purchaser verification request(s) for you:',
          disabled: loader,
          fieldType: 'input-uploadfile',
          fieldSizeLG: 12,
          fieldVisiblity: true,
          dropBoxId: 1
        },
        {
          contentType: 'form',
          methodField: true,
          field: 'upload_drop_box_2',
          fieldID: 'upload_drop_box_2',
          fieldPrimaryLabel: 'Upload other evidence (optional):',
          disabled: loader,
          fieldType: 'input-uploadfile',
          fieldSizeLG: 12,
          fieldVisiblity: true,
          dropBoxId: 2
        },
        {
          contentType: 'description',
          content: (
            <div className="row-spacing">
              <span className="cs-regular-body-text-l cs-neutral-90">
                Type or edit a note, comment, or response to the reviewer here (Optional):
              </span>
              <p className="cs-regular-body-text-l cs-neutral-70">
                <i>
                  Kindly note that for audit/compliance reasons, all communication between yourself
                  and your reviewing attorney can only be done by entering any questions or comments
                  in the box below. Note that this is optional.
                </i>
              </p>
            </div>
          ),
          fieldVisiblity: true
        },
        {
          contentType: 'form',
          methodField: true,
          field: 'notes',
          fieldID: 'notes',
          disabled: loader,
          fieldType: 'input-textarea',
          fieldPlaceholder: 'Enter your Note',
          fieldSizeLG: 12,
          fieldVisiblity: true
        }
      ];
      break;

    case 35:
      formJSON = [
        {
          contentType: 'description',
          content: (
            <div className="row-spacing">
              <p className="cs-regular-body-text-l cs-neutral-70">
                (iv) A natural person or company (in the case of an entity, not formed for the
                specific purpose of investing in the investment), acting for its own account or the
                accounts of other qualified purchasers, who in the aggregate owns and invests on a
                discretionary basis, not less than $25,000,000 USD in Net Investments.
              </p>
            </div>
          ),
          fieldVisiblity: true
        },
        {
          contentType: 'divider',
          content: <div className="cs-divider"></div>,
          fieldVisiblity: true
        },
        legalNameDesc,
        {
          contentType: 'form',
          methodField: true,
          field: 'legal_name',
          fieldID: 'legal_name',
          fieldPrimaryLabel: 'Legal Name',
          highlightedInLineLabel: 'Please click in the box below to edit the name as needed.',
          disabled: loader,
          fieldType: 'input-text',
          fieldPlaceholder: 'Name',
          fieldSizeLG: 5,
          fieldVisiblity: true
        },
        {
          contentType: 'divider',
          content: <div className="cs-divider"></div>,
          fieldVisiblity: true
        },
        {
          contentType: 'description',
          content: (
            <div className="row-spacing">
              <p className="cs-regular-body-text-l cs-neutral-70">
                In order to verify your accreditation we need you to upload evidence. Please upload
                the appropriate files below and black-out any sensitive information.
              </p>
            </div>
          ),
          fieldVisiblity: true
        },
        {
          contentType: 'description',
          content: (
            <div className="row-spacing">
              <p className="cs-regular-body-text-l">
                <Link
                  to={
                    'https://s3.amazonaws.com/static-clientportalx.com/Verify-Investor-files/Net-investments.pdf'
                  }
                  target="_blank"
                  className="vi-link-color">
                  <u className="cs-neutral-100">
                    Examples of Net Investments and how these are calculated.
                  </u>
                </Link>
              </p>
            </div>
          ),
          fieldVisiblity: true
        },
        {
          contentType: 'form',
          methodField: true,
          field: 'investor_type',
          fieldID: 'investor_type',
          fieldLabel: 'Are you investing as an individual or company?',
          disabled: loader,
          fieldType: 'dropdown',
          fieldPlaceholder: 'Select option',
          options: [
            {
              label: 'Individual',
              value: 'Individual'
            },
            {
              label: 'Company',
              value: 'Company'
            }
          ],
          fieldSizeLG: 5,
          fieldOptionsLabelKey: 'label',
          fieldOptionValueKey: 'label',
          fieldVisiblity: true
        },
        {
          contentType: 'form',
          methodField: true,
          field: 'certify',
          fieldID: 'certify',
          fieldPrimaryLabel: (
            <span className="cs-regular-sub-heading-m cs-neutral-90">
              I certify, represent and warrant that, with respect to the company making the
              investment:
            </span>
          ),
          disabled: loader,
          fieldType: 'input-checkbox',
          additionalClass: 'checkbox-spacing',
          fieldSizeLG: 12,
          fieldVisiblity: false,
          fieldVisibilityCondition:
            value['investor_type'] && value['investor_type'] === 'Company' ? true : false
        },
        {
          contentType: 'description',
          content: (
            <div className="cs-regular-body-text-l">
              <ul className="cs-neutral-90 cs-top-margin-none">
                <li>it was not formed for the purpose of investing in the investment;</li>
                <li>
                  it has not and will not invest more than 40% of its total assets in the
                  investment; and
                </li>
                <li>
                  no investor in or beneficiary of the company can independently decide whether or
                  not to participate in any particular investment made by the company.
                </li>
              </ul>
            </div>
          ),
          fieldVisiblity: false,
          fieldVisibilityCondition:
            value['investor_type'] && value['investor_type'] === 'Company' ? true : false
        },
        {
          contentType: 'form',
          methodField: true,
          field: 'upload_drop_box_1',
          fieldID: 'upload_drop_box_1',
          fieldPrimaryLabel: 'Upload evidence of at least $25,000,000 USD in Net Investments:',
          disabled: loader,
          fieldType: 'input-uploadfile',
          fieldSizeLG: 12,
          fieldVisiblity: true,
          dropBoxId: 1
        },
        {
          contentType: 'form',
          methodField: true,
          field: 'upload_drop_box_2',
          fieldID: 'upload_drop_box_2',
          fieldPrimaryLabel: 'Upload proof of Investment management (optional):',
          disabled: loader,
          fieldType: 'input-uploadfile',
          fieldSizeLG: 12,
          fieldVisiblity: true,
          dropBoxId: 2
        },
        {
          contentType: 'form',
          methodField: true,
          field: 'upload_drop_box_3',
          fieldID: 'upload_drop_box_3',
          fieldPrimaryLabel: 'Upload other evidence (optional):',
          disabled: loader,
          fieldType: 'input-uploadfile',
          fieldSizeLG: 12,
          fieldVisiblity: true,
          dropBoxId: 3
        },
        {
          contentType: 'description',
          content: (
            <div className="row-spacing">
              <span className="cs-regular-body-text-l cs-neutral-90">
                Type or edit a note, comment, or response to the reviewer here (Optional):
              </span>
              <p className="cs-regular-body-text-l cs-neutral-70">
                <i>
                  Kindly note that for audit/compliance reasons, all communication between yourself
                  and your reviewing attorney can only be done by entering any questions or comments
                  in the box below. Note that this is optional.
                </i>
              </p>
            </div>
          ),
          fieldVisiblity: true
        },
        {
          contentType: 'form',
          methodField: true,
          field: 'notes',
          fieldID: 'notes',
          disabled: loader,
          fieldType: 'input-textarea',
          fieldPlaceholder: 'Enter your Note',
          fieldSizeLG: 12,
          fieldVisiblity: true
        }
      ];
      break;

    case 36:
      formJSON = [
        {
          contentType: 'description',
          content: (
            <div className="row-spacing">
              <p className="cs-regular-body-text-l cs-neutral-70">
                (v) An entity in which all of the beneficial owners of the entity’s securities are
                “qualified purchasers” under clause (i), (ii), (iii), or (iv).
              </p>
            </div>
          ),
          fieldVisiblity: true
        },
        {
          contentType: 'divider',
          content: <div className="cs-divider"></div>,
          fieldVisiblity: true
        },
        legalNameDesc,
        {
          contentType: 'form',
          methodField: true,
          field: 'legal_name',
          fieldID: 'legal_name',
          fieldPrimaryLabel: 'Legal Name',
          highlightedInLineLabel: 'Please click in the box below to edit the name as needed.',
          disabled: loader,
          fieldType: 'input-text',
          fieldPlaceholder: 'Name',
          fieldSizeLG: 5,
          fieldVisiblity: true
        },
        {
          contentType: 'divider',
          content: <div className="cs-divider"></div>,
          fieldVisiblity: true
        },
        {
          contentType: 'description',
          content: (
            <div className="row-spacing">
              <p className="cs-regular-body-text-l cs-neutral-70">
                In order to verify your accreditation we need you to upload evidence. Please upload
                the appropriate files below and black-out any sensitive information.
              </p>
            </div>
          ),
          fieldVisiblity: true
        },
        {
          contentType: 'form',
          methodField: true,
          field: 'upload_drop_box_1',
          fieldID: 'upload_drop_box_1',
          fieldPrimaryLabel:
            'Upload documents evidencing the identity of each beneficial owner of the entity:',
          disabled: loader,
          fieldType: 'input-uploadfile',
          fieldSizeLG: 12,
          fieldVisiblity: true,
          dropBoxId: 1
        },
        {
          contentType: 'form',
          methodField: true,
          field: 'upload_drop_box_2',
          fieldID: 'upload_drop_box_2',
          fieldPrimaryLabel:
            'Upload Qualified Purchaser verification letter for each beneficial owner of the investing entity. If you don’t have this, you may ask your issuer to issue new qualified purchaser verification request(s) for you:',
          disabled: loader,
          fieldType: 'input-uploadfile',
          fieldSizeLG: 12,
          fieldVisiblity: true,
          dropBoxId: 2
        },
        {
          contentType: 'form',
          methodField: true,
          field: 'upload_drop_box_3',
          fieldID: 'upload_drop_box_3',
          fieldPrimaryLabel: 'Upload other evidence (optional):',
          disabled: loader,
          fieldType: 'input-uploadfile',
          fieldSizeLG: 12,
          fieldVisiblity: true,
          dropBoxId: 3
        },
        {
          contentType: 'description',
          content: (
            <div className="row-spacing">
              <span className="cs-regular-body-text-l cs-neutral-90">
                Type or edit a note, comment, or response to the reviewer here (Optional):
              </span>
              <p className="cs-regular-body-text-l cs-neutral-70">
                <i>
                  Kindly note that for audit/compliance reasons, all communication between yourself
                  and your reviewing attorney can only be done by entering any questions or comments
                  in the box below. Note that this is optional.
                </i>
              </p>
            </div>
          ),
          fieldVisiblity: true
        },
        {
          contentType: 'form',
          methodField: true,
          field: 'notes',
          fieldID: 'notes',
          disabled: loader,
          fieldType: 'input-textarea',
          fieldPlaceholder: 'Enter your Note',
          fieldSizeLG: 12,
          fieldVisiblity: true
        }
      ];
      break;

    case 37:
      formJSON = [
        {
          contentType: 'description',
          content: (
            <div className="row-spacing">
              <p className="cs-regular-body-text-l cs-neutral-70">
                A natural person holding General Securities Representative (Series 7), Private
                Securities Offerings Representative (Series 82), or Licensed Investment Adviser
                Representative (Series 65) registration in good standing.
              </p>
            </div>
          ),
          fieldVisiblity: true
        },
        {
          contentType: 'divider',
          content: <div className="cs-divider"></div>,
          fieldVisiblity: true
        },
        legalNameDesc,
        {
          contentType: 'form',
          methodField: true,
          field: 'legal_name',
          fieldID: 'legal_name',
          fieldPrimaryLabel: 'Legal Name',
          highlightedInLineLabel: 'Please click in the box below to edit the name as needed.',
          disabled: loader,
          fieldType: 'input-text',
          fieldPlaceholder: 'Name',
          fieldSizeLG: 5,
          fieldVisiblity: true
        },
        {
          contentType: 'divider',
          content: <div className="cs-divider"></div>,
          fieldVisiblity: true
        },
        {
          contentType: 'description',
          content: (
            <div className="row-spacing">
              <p className="cs-regular-body-text-l cs-neutral-70">
                Enter your name as it appears on your license, your individual CRD#, and the name of
                the firm you are licensed through, if applicable.
              </p>
            </div>
          ),
          fieldVisiblity: true
        },
        {
          contentType: 'form',
          methodField: true,
          field: 'name',
          fieldID: 'name',
          fieldLabelWithTag: <span className="cs-regular-sub-heading-m cs-neutral-100">Name</span>,
          disabled: loader,
          fieldType: 'input-text',
          fieldPlaceholder: 'Name',
          fieldSizeLG: 5,
          fieldVisiblity: true,
          maxVal: 50
        },
        {
          contentType: 'form',
          methodField: true,
          field: 'crd_number',
          fieldID: 'crd_number',
          fieldLabelWithTag: (
            <span className="cs-regular-sub-heading-m cs-neutral-100">
              Individual CRD Number<span className="cs-danger">*</span>
            </span>
          ),
          disabled: loader,
          fieldType: 'input-text',
          fieldPlaceholder: 'CRD #',
          fieldSizeLG: 5,
          required: true,
          fieldVisiblity: true
        },
        {
          contentType: 'form',
          methodField: true,
          field: 'firm_name',
          fieldID: 'firm_name',
          fieldLabelWithTag: (
            <span className="cs-regular-sub-heading-m cs-neutral-100">
              Firm Name (required for series 7 or 82)
            </span>
          ),
          disabled: loader,
          fieldType: 'input-text',
          fieldPlaceholder: 'Firm Name',
          fieldSizeLG: 5,
          fieldVisiblity: true
        },
        {
          contentType: 'form',
          methodField: true,
          field: 'upload_drop_box_1',
          fieldID: 'upload_drop_box_1',
          fieldPrimaryLabel: 'Upload other evidence (Optional):',
          disabled: loader,
          fieldType: 'input-uploadfile',
          fieldSizeLG: 12,
          fieldVisiblity: true,
          dropBoxId: 1
        },
        {
          contentType: 'description',
          content: (
            <div className="row-spacing">
              <span className="cs-regular-body-text-l cs-neutral-90">
                Type or edit a note, comment, or response to the reviewer here (Optional):
              </span>
              <p className="cs-regular-body-text-l cs-neutral-70">
                <i>
                  Kindly note that for audit/compliance reasons, all communication between yourself
                  and your reviewing attorney can only be done by entering any questions or comments
                  in the box below. Note that this is optional.
                </i>
              </p>
            </div>
          ),
          fieldVisiblity: true
        },
        {
          contentType: 'form',
          methodField: true,
          field: 'notes',
          fieldID: 'notes',
          disabled: loader,
          fieldType: 'input-textarea',
          fieldPlaceholder: 'Enter your Note',
          fieldSizeLG: 12,
          fieldVisiblity: true
        }
      ];
      break;

    case 38:
      formJSON = [
        {
          contentType: 'description',
          content: (
            <div className="row-spacing">
              <p className="cs-regular-body-text-l cs-neutral-70">
                “Knowledgeable Employees” of a private fund, but only for investments in that fund.
              </p>
            </div>
          ),
          fieldVisiblity: true
        },
        {
          contentType: 'divider',
          content: <div className="cs-divider"></div>,
          fieldVisiblity: true
        },
        legalNameDesc,
        {
          contentType: 'form',
          methodField: true,
          field: 'legal_name',
          fieldID: 'legal_name',
          fieldPrimaryLabel: 'Legal Name',
          highlightedInLineLabel: 'Please click in the box below to edit the name as needed.',
          disabled: loader,
          fieldType: 'input-text',
          fieldPlaceholder: 'Name',
          fieldSizeLG: 5,
          fieldVisiblity: true
        },
        {
          contentType: 'divider',
          content: <div className="cs-divider"></div>,
          fieldVisiblity: true
        },
        {
          contentType: 'description',
          content: (
            <div className="row-spacing">
              <p className="cs-regular-body-text-l cs-neutral-70">
                Any natural person who is a “knowledgeable employee,” as defined in rule 3c-5(a)(4)
                under the Investment Company Act of 1940 (17 CFR 270.3c-5(a)(4)), of the issuer of
                the securities being offered or sold where the issuer would be an investment
                company, as defined in section 3 of such act, but for the exclusion provided by
                either section 3(c)(1) or section 3(c)(7) of such act;
              </p>
            </div>
          ),
          fieldVisiblity: true
        },
        {
          contentType: 'form',
          methodField: true,
          field: 'upload_drop_box_1',
          fieldID: 'upload_drop_box_1',
          fieldLabelWithTag: (
            <span className="cs-regular-sub-heading-m cs-neutral-90">
              Upload evidence of position claimed:{' '}
              <span className="cs-regular-sub-heading-m cs-neutral-70 cs-eg">
                (e.g. Incumbency Certificate or Officer's Certificate no more than 90 days old) —
                Sample Officer's Certificate{' '}
                <Link
                  className="cs-neutral-100"
                  to={
                    'https://s3.amazonaws.com/static-clientportalx.com/Verify-Investor-files/OfficerCertificate-including-KE-and-FO.pdf'
                  }
                  target="_blank">
                  Here
                </Link>
              </span>
            </span>
          ),
          disabled: loader,
          fieldType: 'input-uploadfile',
          fieldSizeLG: 12,
          fieldVisiblity: true,
          dropBoxId: 1
        },
        {
          contentType: 'form',
          methodField: true,
          field: 'aknowledgeable_employees_investment_or_deal_name',
          fieldID: 'aknowledgeable_employees_investment_or_deal_name',
          fieldPrimaryLabel:
            'Please enter the investment name or deal name of the company of which you are a knowledgeable employee. (Note the name you entered will appear on your accreditation letter and the letter will be solely for the purpose of this investment.)',
          disabled: loader,
          fieldType: 'input-text',
          fieldPlaceholder: 'Name',
          fieldSizeLG: 12,
          inputWidth: 'col-lg-6',
          fieldVisiblity: true
        },
        {
          contentType: 'description',
          content: (
            <div className="row-spacing">
              <span className="cs-regular-body-text-l cs-neutral-90">
                Type or edit a note, comment, or response to the reviewer here (Optional):
              </span>
              <p className="cs-regular-body-text-l cs-neutral-70">
                <i>
                  Kindly note that for audit/compliance reasons, all communication between yourself
                  and your reviewing attorney can only be done by entering any questions or comments
                  in the box below. Note that this is optional.
                </i>
              </p>
            </div>
          ),
          fieldVisiblity: true
        },
        {
          contentType: 'form',
          methodField: true,
          field: 'notes',
          fieldID: 'notes',
          disabled: loader,
          fieldType: 'input-textarea',
          fieldPlaceholder: 'Enter your Note',
          fieldSizeLG: 12,
          fieldVisiblity: true
        }
      ];
      break;

    case 39:
      formJSON = [
        {
          contentType: 'description',
          content: (
            <div className="row-spacing">
              <p className="cs-regular-body-text-l cs-neutral-70">
                "Family Offices" as defined in rule 202(a)(11)(G)-1 under the Investment Advisers
                Act of 1940 (17 CFR 275.202(a)(11)(G)-1) meeting the requirements below, and any
                “family client” of such family office whose prospective investment in the issuer is
                directed by such family office pursuant to paragraph (iii) below:
              </p>
            </div>
          ),
          fieldVisiblity: true
        },
        {
          contentType: 'description',
          content: (
            <div className="row-spacing">
              <p className="cs-regular-body-text-l cs-neutral-70">
                (i) With assets under management in excess of $5,000,000,
              </p>
            </div>
          ),
          fieldVisiblity: true
        },
        {
          contentType: 'description',
          content: (
            <div className="row-spacing">
              <p className="cs-regular-body-text-l cs-neutral-70">
                (ii) That is not formed for the specific purpose of acquiring the securities
                offered, and
              </p>
            </div>
          ),
          fieldVisiblity: true
        },
        {
          contentType: 'description',
          content: (
            <div className="row-spacing">
              <p className="cs-regular-body-text-l cs-neutral-70">
                (iii) Whose prospective investment is directed by a person who has such knowledge
                and experience in financial and business matters that such family office is capable
                of evaluating the merits and risks of the prospective investment
              </p>
            </div>
          ),
          fieldVisiblity: true
        },
        {
          contentType: 'divider',
          content: <div className="cs-divider"></div>,
          fieldVisiblity: true
        },
        legalNameDesc,
        {
          contentType: 'form',
          methodField: true,
          field: 'legal_name',
          fieldID: 'legal_name',
          fieldPrimaryLabel: 'Legal Name',
          highlightedInLineLabel: 'Please click in the box below to edit the name as needed.',
          disabled: loader,
          fieldType: 'input-text',
          fieldPlaceholder: 'Name',
          fieldSizeLG: 5,
          fieldVisiblity: true
        },
        {
          contentType: 'divider',
          content: <div className="cs-divider"></div>,
          fieldVisiblity: true
        },
        {
          contentType: 'description',
          content: (
            <div className="row-spacing">
              <p className="cs-regular-body-text-l cs-neutral-70">
                In order to verify your accreditation we need you to upload evidence. Please upload
                the appropriate files below and black-out any sensitive information.
              </p>
            </div>
          ),
          fieldVisiblity: true
        },
        {
          contentType: 'description',
          content: (
            <div className="row-spacing">
              <p className="cs-regular-body-text-l cs-danger">
                Upon a successful verification, the expiration date of the verification letter will
                be based on the evidence provided to reviewers, e.g. if a 3rd party letter or a
                monthly statement is 2 months old at the time of submission for review the
                verification letter will expire in 1 month.
              </p>
            </div>
          ),
          fieldVisiblity: true
        },
        {
          contentType: 'form',
          methodField: true,
          field: 'upload_drop_box_1',
          fieldID: 'upload_drop_box_1',
          fieldPrimaryLabel:
            'Upload a statement that is no later than 90 days old showing family office assets in excess of $5,000,000 USD:',
          disabled: loader,
          fieldType: 'input-uploadfile',
          fieldSizeLG: 12,
          fieldVisiblity: true,
          dropBoxId: 1
        },
        {
          contentType: 'form',
          methodField: true,
          field: 'upload_drop_box_2',
          fieldID: 'upload_drop_box_2',
          fieldLabelWithTag: (
            <p className="cs-neutral-70 method-spacing">
              If applicable, upload evidence that the investor is a “family client”. (e.g.
              Incumbency Certificate or Officer's Certificate no more than 90 days old) — Sample
              Officer's Certificate{' '}
              <Link
                className="cs-link cs-neutral-100"
                to={
                  'https://s3.amazonaws.com/static-clientportalx.com/Verify-Investor-files/OfficerCertificate-including-KE-and-FO.pdf'
                }
                target="_blank">
                Here
              </Link>
            </p>
          ),
          disabled: loader,
          fieldType: 'input-uploadfile',
          fieldSizeLG: 12,
          fieldVisiblity: true,
          dropBoxId: 2
        },
        {
          contentType: 'description',
          content: (
            <div className="row-spacing">
              <span className="cs-regular-body-text-l cs-neutral-90">
                Type or edit a note, comment, or response to the reviewer here (Optional):
              </span>
              <p className="cs-regular-body-text-l cs-neutral-70">
                <i>
                  Kindly note that for audit/compliance reasons, all communication between yourself
                  and your reviewing attorney can only be done by entering any questions or comments
                  in the box below. Note that this is optional.
                </i>
              </p>
            </div>
          ),
          fieldVisiblity: true
        },
        {
          contentType: 'form',
          methodField: true,
          field: 'notes',
          fieldID: 'notes',
          disabled: loader,
          fieldType: 'input-textarea',
          fieldPlaceholder: 'Enter your Note',
          fieldSizeLG: 12,
          fieldVisiblity: true
        }
      ];
      break;

    case 40:
      formJSON = [
        {
          contentType: 'description',
          content: (
            <div className="row-spacing">
              <p className="cs-regular-body-text-l cs-neutral-70">
                Any entity, including Indian tribes, governmental bodies, funds, and entities
                organized under the laws of foreign countries, of a type not listed in the above
                methods, not formed for the specific purpose of acquiring the securities offered,
                owning investments in excess of $5,000,000.
              </p>
            </div>
          ),
          fieldVisiblity: true
        },
        {
          contentType: 'divider',
          content: <div className="cs-divider"></div>,
          fieldVisiblity: true
        },
        legalNameDesc,
        {
          contentType: 'form',
          methodField: true,
          field: 'legal_name',
          fieldID: 'legal_name',
          fieldPrimaryLabel: 'Legal Name',
          highlightedInLineLabel: 'Please click in the box below to edit the name as needed.',
          disabled: loader,
          fieldType: 'input-text',
          fieldPlaceholder: 'Name',
          fieldSizeLG: 5,
          fieldVisiblity: true
        },
        {
          contentType: 'divider',
          content: <div className="cs-divider"></div>,
          fieldVisiblity: true
        },
        {
          contentType: 'description',
          content: (
            <div className="row-spacing">
              <p className="cs-regular-body-text-l cs-neutral-70">
                In order to verify your accreditation we need you to upload evidence. Please upload
                the appropriate files below and black-out any sensitive information.
              </p>
            </div>
          ),
          fieldVisiblity: true
        },
        {
          contentType: 'description',
          content: (
            <div className="row-spacing">
              <p className="cs-regular-body-text-l cs-danger">
                Upon a successful verification, the expiration date of the verification letter will
                be based on the evidence provided to reviewers, e.g. if a 3rd party letter or a
                monthly statement is 2 months old at the time of submission for review the
                verification letter will expire in 1 month.
              </p>
            </div>
          ),
          fieldVisiblity: true
        },
        {
          contentType: 'form',
          methodField: true,
          field: 'upload_drop_box_1',
          fieldID: 'upload_drop_box_1',
          fieldPrimaryLabel:
            'Please upload evidence that is no older than 90 days old to show the entity’s investments exceeds $5 million USD (statement etc.) :',
          disabled: loader,
          fieldType: 'input-uploadfile',
          fieldSizeLG: 12,
          fieldVisiblity: true,
          dropBoxId: 1
        },
        {
          contentType: 'description',
          content: (
            <div className="row-spacing">
              <span className="cs-regular-body-text-l cs-neutral-90">
                Type or edit a note, comment, or response to the reviewer here (Optional):
              </span>
              <p className="cs-regular-body-text-l cs-neutral-70">
                <i>
                  Kindly note that for audit/compliance reasons, all communication between yourself
                  and your reviewing attorney can only be done by entering any questions or comments
                  in the box below. Note that this is optional.
                </i>
              </p>
            </div>
          ),
          fieldVisiblity: true
        },
        {
          contentType: 'form',
          methodField: true,
          field: 'notes',
          fieldID: 'notes',
          disabled: loader,
          fieldType: 'input-textarea',
          fieldPlaceholder: 'Enter your Note',
          fieldSizeLG: 12,
          fieldVisiblity: true
        }
      ];
      break;

    case 41:
      formJSON = [
        {
          contentType: 'description',
          content: (
            <div className="row-spacing">
              <p className="cs-regular-body-text-l cs-neutral-70">
                “Knowledgeable Employees” of a private fund, but only for investments in that fund.
              </p>
            </div>
          ),
          fieldVisiblity: true
        },
        {
          contentType: 'divider',
          content: <div className="cs-divider"></div>,
          fieldVisiblity: true
        },
        legalNameDesc,
        {
          contentType: 'form',
          methodField: true,
          field: 'legal_name',
          fieldID: 'legal_name',
          fieldPrimaryLabel: 'Legal Name',
          highlightedInLineLabel: 'Please click in the box below to edit the name as needed.',
          disabled: loader,
          fieldType: 'input-text',
          fieldPlaceholder: 'Name',
          fieldSizeLG: 5,
          fieldVisiblity: true
        },
        {
          contentType: 'divider',
          content: <div className="cs-divider"></div>,
          fieldVisiblity: true
        },
        {
          contentType: 'form',
          methodField: true,
          field: 'upload_drop_box_2',
          fieldID: 'upload_drop_box_2',
          fieldLabelWithTag: (
            <span className="cs-regular-sub-heading-m cs-neutral-90">
              Please upload evidence of who all the equity owners of the trust are (by uploading
              your trust documentation or this{' '}
              <Link
                className="cs-neutral-100"
                to="https://s3.amazonaws.com/static-clientportalx.com/Verify-Investor-files/All-Owners-Accredited-Officer-Certificate.pdf"
                target="_blank">
                <b>Officer’s Certificate</b>
              </Link>{' '}
              along with a document to show ownership percentages). In a revocable trust, typically,
              the grantors are the equity owners.
            </span>
          ),
          disabled: loader,
          fieldType: 'input-uploadfile',
          fieldSizeLG: 12,
          fieldVisiblity: true,
          dropBoxId: 2
        },
        {
          contentType: 'description',
          content: (
            <div className="row-spacing">
              <p className="cs-regular-body-text-l cs-neutral-70">
                Any natural person who is a “knowledgeable employee,” as defined in rule 3c-5(a)(4)
                under the Investment Company Act of 1940 (17 CFR 270.3c-5(a)(4)), of the issuer of
                the securities being offered or sold where the issuer would be an investment
                company, as defined in section 3 of such act, but for the exclusion provided by
                either section 3(c)(1) or section 3(c)(7) of such act;
              </p>
            </div>
          ),
          fieldVisiblity: true
        },
        {
          contentType: 'form',
          methodField: true,
          field: 'upload_drop_box_1',
          fieldID: 'upload_drop_box_1',
          fieldLabelWithTag: (
            <span className="cs-regular-sub-heading-m cs-neutral-90">
              Upload evidence of position claimed:{' '}
              <span className="cs-regular-sub-heading-m cs-neutral-70 cs-eg">
                (e.g. Incumbency Certificate or Officer's Certificate no more than 90 days old) —
                Sample Officer's Certificate{' '}
                <Link
                  className="cs-neutral-100"
                  to={
                    'https://s3.amazonaws.com/static-clientportalx.com/Verify-Investor-files/OfficerCertificate-including-KE-and-FO.pdf'
                  }
                  target="_blank">
                  Here
                </Link>
              </span>
            </span>
          ),
          disabled: loader,
          fieldType: 'input-uploadfile',
          fieldSizeLG: 12,
          fieldVisiblity: true,
          dropBoxId: 1
        },
        {
          contentType: 'form',
          methodField: true,
          field: 'aknowledgeable_employees_investment_or_deal_name',
          fieldID: 'aknowledgeable_employees_investment_or_deal_name',
          fieldPrimaryLabel:
            'Please enter the investment name or deal name of the company of which you are a knowledgeable employee. (Note the name you entered will appear on your accreditation letter and the letter will be solely for the purpose of this investment.)',
          disabled: loader,
          fieldType: 'input-text',
          fieldPlaceholder: 'Name',
          fieldSizeLG: 12,
          inputWidth: 'col-lg-6',
          fieldVisiblity: true
        },
        {
          contentType: 'description',
          content: (
            <div className="row-spacing">
              <span className="cs-regular-body-text-l cs-neutral-90">
                Type or edit a note, comment, or response to the reviewer here (Optional):
              </span>
              <p className="cs-regular-body-text-l cs-neutral-70">
                <i>
                  Kindly note that for audit/compliance reasons, all communication between yourself
                  and your reviewing attorney can only be done by entering any questions or comments
                  in the box below. Note that this is optional.
                </i>
              </p>
            </div>
          ),
          fieldVisiblity: true
        },
        {
          contentType: 'form',
          methodField: true,
          field: 'notes',
          fieldID: 'notes',
          disabled: loader,
          fieldType: 'input-textarea',
          fieldPlaceholder: 'Enter your Note',
          fieldSizeLG: 12,
          fieldVisiblity: true
        }
      ];
      break;

    case 42:
      formJSON = [
        {
          contentType: 'description',
          content: (
            <div className="row-spacing">
              <p className="cs-regular-body-text-l cs-neutral-70">
                A natural person holding General Securities Representative (Series 7), Private
                Securities Offerings Representative (Series 82), or Licensed Investment Adviser
                Representative (Series 65) registration in good standing.
              </p>
            </div>
          ),
          fieldVisiblity: true
        },
        {
          contentType: 'divider',
          content: <div className="cs-divider"></div>,
          fieldVisiblity: true
        },
        legalNameDesc,
        {
          contentType: 'form',
          methodField: true,
          field: 'legal_name',
          fieldID: 'legal_name',
          fieldPrimaryLabel: 'Legal Name',
          highlightedInLineLabel: 'Please click in the box below to edit the name as needed.',
          disabled: loader,
          fieldType: 'input-text',
          fieldPlaceholder: 'Name',
          fieldSizeLG: 5,
          fieldVisiblity: true
        },
        {
          contentType: 'divider',
          content: <div className="cs-divider"></div>,
          fieldVisiblity: true
        },
        {
          contentType: 'form',
          methodField: true,
          field: 'upload_drop_box_2',
          fieldID: 'upload_drop_box_2',
          fieldLabelWithTag: (
            <span className="cs-regular-sub-heading-m cs-neutral-90">
              Please upload evidence of who all the equity owners of the trust are (by uploading
              your trust documentation or this{' '}
              <Link
                className="cs-neutral-100"
                to="https://s3.amazonaws.com/static-clientportalx.com/Verify-Investor-files/All-Owners-Accredited-Officer-Certificate.pdf"
                target="_blank">
                <b>Officer’s Certificate</b>
              </Link>{' '}
              along with a document to show ownership percentages). In a revocable trust, typically,
              the grantors are the equity owners.
            </span>
          ),
          disabled: loader,
          fieldType: 'input-uploadfile',
          fieldSizeLG: 12,
          fieldVisiblity: true,
          dropBoxId: 2
        },
        {
          contentType: 'description',
          content: (
            <div className="row-spacing">
              <p className="cs-regular-body-text-l cs-neutral-70">
                Enter your name as it appears on your license, your individual CRD#, and the name of
                the firm you are licensed through, if applicable.
              </p>
            </div>
          ),
          fieldVisiblity: true
        },
        {
          contentType: 'form',
          methodField: true,
          field: 'name',
          fieldID: 'name',
          fieldLabelWithTag: <span className="cs-regular-sub-heading-m cs-neutral-100">Name</span>,
          disabled: loader,
          fieldType: 'input-text',
          fieldPlaceholder: 'Name',
          fieldSizeLG: 5,
          fieldVisiblity: true,
          maxVal: 50
        },
        {
          contentType: 'form',
          methodField: true,
          field: 'crd_number',
          fieldID: 'crd_number',
          fieldLabelWithTag: (
            <span className="cs-regular-sub-heading-m cs-neutral-100">
              Individual CRD Number<span className="cs-danger">*</span>
            </span>
          ),
          disabled: loader,
          fieldType: 'input-text',
          fieldPlaceholder: 'CRD #',
          fieldSizeLG: 5,
          required: true,
          fieldVisiblity: true
        },
        {
          contentType: 'form',
          methodField: true,
          field: 'firm_name',
          fieldID: 'firm_name',
          fieldLabelWithTag: (
            <span className="cs-regular-sub-heading-m cs-neutral-100">
              Firm Name (required for series 7 or 82)
            </span>
          ),
          disabled: loader,
          fieldType: 'input-text',
          fieldPlaceholder: 'Firm Name',
          fieldSizeLG: 5,
          fieldVisiblity: true
        },
        {
          contentType: 'description',
          content: (
            <div className="row-spacing">
              <p className="cs-regular-body-text-l cs-neutral-90">
                If verification is for an ENTITY or TRUST owned by multiple individuals who are not
                all licensed, please select another verification method. If both individuals are
                licensed, please enter the information for the second license in the box at the
                bottom of the page.
              </p>
            </div>
          ),
          fieldVisiblity: true
        },
        {
          contentType: 'form',
          methodField: true,
          field: 'upload_drop_box_1',
          fieldID: 'upload_drop_box_1',
          fieldPrimaryLabel: 'Upload other evidence (Optional):',
          disabled: loader,
          fieldType: 'input-uploadfile',
          fieldSizeLG: 12,
          fieldVisiblity: true,
          dropBoxId: 1
        },
        {
          contentType: 'description',
          content: (
            <div className="row-spacing">
              <span className="cs-regular-body-text-l cs-neutral-90">
                Type or edit a note, comment, or response to the reviewer here (Optional):
              </span>
              <p className="cs-regular-body-text-l cs-neutral-70">
                <i>
                  Kindly note that for audit/compliance reasons, all communication between yourself
                  and your reviewing attorney can only be done by entering any questions or comments
                  in the box below. Note that this is optional.
                </i>
              </p>
            </div>
          ),
          fieldVisiblity: true
        },
        {
          contentType: 'form',
          methodField: true,
          field: 'notes',
          fieldID: 'notes',
          disabled: loader,
          fieldType: 'input-textarea',
          fieldPlaceholder: 'Enter your Note',
          fieldSizeLG: 12,
          fieldVisiblity: true
        }
      ];
      break;

    default:
      formJSON = [];
      break;
  }

  return {
    formJSON
  };
};
