import React from 'react';
import { Image, Container, Row, Col, Button } from 'react-bootstrap';

/* ============================== BRAND DETAILS NOT FOUND PAGE ============================== */
const BrandDetailsNotFound = () => {
  return (
    <Container>
      <div className="page404">
        <Row>
          <Col lg={12}>
            <div className="error-container">
              <div className="error-image">
                <Image
                  src={require('../assets/images/brand-details-not-found-image.png')}
                  className="pagenotfound"
                />
              </div>
              <div className="error-content">
                <span className="back-to-sign-in">
                  <Button
                    onClick={() => window.location.reload()}
                    className="cs-btn-tertiary lg-btn cs-regular-h5">
                    Refresh
                  </Button>
                </span>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </Container>
  );
};

export default BrandDetailsNotFound;
