import React, { useEffect, useState } from 'react';
import { Col, Row, Form, Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useFormik } from 'formik';
import * as yup from 'yup';

// COMPONENT IMPORTS
import useRemoveServerError from '../components/hooks/useRemoveServerError';
import { confirmPasswordField, passwordField } from '../validations';
import InputBox from '../components/FormFields/InputBox';
import CapsyncIcon from '../components/CapsyncIcon';
import CONFIG from '../constants/config';
import AuthHead from './AuthHead';

// API
import { userChangePassword, resetForm } from '../slices/authSlice';

/* ============================== CREATE PASSWORD PAGE ============================== */
const CreatePassword = (props) => {
  useRemoveServerError();
  const dispatch = useDispatch();

  const { token } = useParams();
  const { message, status } = useSelector((state) => state.auth);

  const [formError, setFormError] = useState(''); // Custom Error after form submit from API

  const { values, errors, touched, handleChange, handleSubmit } = useFormik({
    initialValues: {
      password: '',
      confirm_password: ''
    },
    validationSchema: yup.object().shape({
      password: passwordField(),
      confirm_password: confirmPasswordField()
    }),
    onSubmit: (values) => {
      values.reset_password_token = token;
      dispatch(userChangePassword(values));
    }
  });
  useEffect(() => {
    status === CONFIG.STATUS.ERROR && setFormError(message);
  }, [status]);

  useEffect(() => {
    if (formError !== '') {
      setFormError('');
      dispatch(resetForm());
    }
  }, [values]);

  return (
    <React.Fragment>
      <AuthHead title="Sign in" />
      <div className="cs-auth-form">
        <Form onSubmit={handleSubmit}>
          <Row>
            <Col lg={12}>
              <InputBox
                disabled
                type="email"
                name="email"
                values={values}
                errors={errors}
                touched={touched}
                onChange={handleChange}
                placeholder="Enter email address"
              />
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              <InputBox
                name="password"
                type="password"
                placeholder="Password"
                values={values}
                errors={errors}
                touched={touched}
                onChange={handleChange}
                isSignUp
              />
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              <InputBox
                name="confirm_password"
                type="password"
                placeholder="Confirm password"
                values={values}
                errors={errors}
                touched={touched}
                onChange={handleChange}
              />
            </Col>
          </Row>

          {message && (
            <span
              className={`cs-light-body-text-l ${status === CONFIG.STATUS.SUCCESS ? 'cs-success' : 'cs-danger'}`}>
              {status === CONFIG.STATUS.SUCCESS && (
                <CapsyncIcon title="verification-outlined" size="16" />
              )}
              {formError}
            </span>
          )}
          <div className="auth-footer">
            <Button type="submit" className="cs-btn-primary lg-btn cs-regular-h5">
              Sign in
            </Button>
          </div>
        </Form>
      </div>
    </React.Fragment>
  );
};

export default CreatePassword;
