import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useOutletContext } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import Slider from 'react-slick';

// COMPONENT IMPORTS
import Donut from '../components/Donut';
import { socket } from '../../config/Socket';
import DashboardTable from './DashboardTable';
import LineChart from '../components/LineChart';
import { monthWiseQuarter } from '../../utils/common';
import CapsyncIcon from '../../components/CapsyncIcon';
import ContingentLiability from './ContingentLiability';
import CommonSpinner from '../../components/CommonSpinner';
import CapsyncToolTip from '../../components/CapsyncToolTip';
import CardsSkeleton from '../components/Skeleton/CardsSkeleton';
import PieChartSkeleton from '../components/Skeleton/PieChartSkeleton';
import { assetsRouts, liabilitiesRouts } from '../../utils/routesName';
import LineChartSkeleton from '../components/Skeleton/LineChartSkeleton';
import SkeletonFinanceTable from '../components/Skeleton/SkeletonFinanceTable';

// API
import {
  handleAccountsData,
  handleLastSync,
  handleLastSyncData,
  useLastSyncData
} from '../../slices/plaidSlice';
import {
  getAssetsDashboard,
  getLiabilitiesDashboard,
  getMainDashboard,
  useAssetsDashboard,
  useLiabilitieDashboard
} from '../../slices/dashboardSlice';
import { useUser, useUserID } from '../../slices/authSlice';
import { fetchAllPlaidAccountsData } from '../../services/plaid.service';
import SkeletonCapsyncHeader from '../components/Skeleton/SkeletonCapsyncHeader';

// CSS IMPORTS
import '../components/Skeleton/skeleton.css';

/* ============================== DASHBOARD ============================== */
const Dashboard = () => {
  const user = useUser();
  const dispatch = useDispatch();

  const allAssets = useAssetsDashboard();
  const allLiabilities = useLiabilitieDashboard();
  const lastSyncData = useLastSyncData();
  const [isPlaidDataSynced, setIsPlaidDataSynced] = useState(false);
  // const dashBoardData = useMainDashboard();

  const { calculatedNetWorth } = useSelector((state) => state.dashboard);

  const userId = useUserID();
  const assets = assetsRouts.map((item) => ({
    ...item,
    value: allAssets?.assetsData?.[item.key] ?? item.value
  }));

  const asChatData = allAssets?.chart_data;
  let asq1Total = 0;
  let asq2Total = 0;
  let asq3Total = 0;
  let asq4Total = 0;

  if (asChatData) {
    Object.values(asChatData.assetsQ1).forEach((item) => (asq1Total += item));
    Object.values(asChatData.assetsQ2).forEach((item) => (asq2Total += item));
    Object.values(asChatData.assetsQ3).forEach((item) => (asq3Total += item));
    Object.values(asChatData.assetsQ4).forEach((item) => (asq4Total += item));
  }
  const lbChatData = allLiabilities?.chart_data;
  let lbq1Total = 0;
  let lbq2Total = 0;
  let lbq3Total = 0;
  let lbq4Total = 0;
  if (lbChatData) {
    Object.values(lbChatData.assetsQ1).forEach((item) => (lbq1Total += item));
    Object.values(lbChatData.assetsQ2).forEach((item) => (lbq2Total += item));
    Object.values(lbChatData.assetsQ3).forEach((item) => (lbq3Total += item));
    Object.values(lbChatData.assetsQ4).forEach((item) => (lbq4Total += item));
  }
  const assetsChartTotalData = {
    assetsQ1: asq1Total,
    assetsQ2: asq2Total,
    assetsQ3: asq3Total,
    assetsQ4: asq4Total
  };
  const liabilityChartTotalData = {
    assetsQ1: lbq1Total,
    assetsQ2: lbq2Total,
    assetsQ3: lbq3Total,
    assetsQ4: lbq4Total
  };

  const dashboardPieChartFill = ['#3D9C73', '#E85B5B'];

  const liabilitie = liabilitiesRouts.map((item) => ({
    ...item,
    value: allLiabilities?.liabilityData?.[item.key] ?? item.value
  }));
  const donutHeader = ['Total Assets', 'Total Liabilities'];

  const fetchDashboardDetails = async () => {
    const payload = {
      userId,
      orderId: 0
    };
    await dispatch(getAssetsDashboard(payload)).unwrap();
    await dispatch(getLiabilitiesDashboard(payload)).unwrap();
    await dispatch(getMainDashboard(userId)).unwrap();
  };
  const totalAssets = Object.values(allAssets?.assetsData || {}).reduce(
    (sum, value) => sum + value,
    0
  );
  const totalLiabilities = Object.values(allLiabilities?.liabilityData || {}).reduce(
    (sum, value) => sum + value,
    0
  );
  const donutValue = [totalAssets, totalLiabilities];
  const netWorth = calculatedNetWorth || 0;

  useEffect(() => {
    fetchDashboardDetails();
    getPlaidAccountsData();
    getLastSyncData();
  }, []);

  const getPlaidAccountsData = async () => {
    dispatch(handleAccountsData([]));
    const res = await fetchAllPlaidAccountsData(user.id);
    if (res.code === 200) {
      dispatch(handleAccountsData(res.data));
    }
  };

  const getLastSyncData = async () => {
    dispatch(handleLastSyncData(user.id))
      .unwrap()
      .then((resp) => {
        if (resp.code === 200) {
          dispatch(handleLastSync(resp.data));
        } else {
          dispatch(handleLastSync({}));
        }
      });
  };

  useEffect(() => {
    socket.on('all_accounts_synced', (resp) => {
      getPlaidAccountsData();
      fetchDashboardDetails();
      setIsPlaidDataSynced(true);
      getLastSyncData();
    });

    return () => {
      socket.off('all_accounts_synced');
    };
  }, []);

  useEffect(() => {
    if (isPlaidDataSynced) {
      setTimeout(() => {
        setIsPlaidDataSynced(false);
      }, 2000);
    }
  }, [isPlaidDataSynced]);

  const legendFooter = monthWiseQuarter();
  const series = [
    {
      name: 'Assets',
      data: [
        {
          x: legendFooter[0],
          y: assetsChartTotalData?.assetsQ1
        },
        {
          x: legendFooter[1],
          y: assetsChartTotalData?.assetsQ2
        },
        {
          x: legendFooter[2],
          y: assetsChartTotalData?.assetsQ3
        },
        {
          x: legendFooter[3],
          y: assetsChartTotalData?.assetsQ4
        }
      ]
    },
    {
      name: 'Liabilities',
      data: [
        {
          x: legendFooter[0],
          y: liabilityChartTotalData?.assetsQ1
        },
        {
          x: legendFooter[1],
          y: liabilityChartTotalData?.assetsQ2
        },
        {
          x: legendFooter[2],
          y: liabilityChartTotalData?.assetsQ3
        },
        {
          x: legendFooter[3],
          y: liabilityChartTotalData?.assetsQ4
        }
      ]
    },
    {
      name: 'Net Worth',
      data: [
        {
          x: legendFooter[0],
          y: assetsChartTotalData?.assetsQ1 - liabilityChartTotalData?.assetsQ1
        },
        {
          x: legendFooter[1],
          y: assetsChartTotalData?.assetsQ2 - liabilityChartTotalData?.assetsQ2
        },
        {
          x: legendFooter[2],
          y: assetsChartTotalData?.assetsQ3 - liabilityChartTotalData?.assetsQ3
        },
        {
          x: legendFooter[3],
          y: assetsChartTotalData?.assetsQ4 - liabilityChartTotalData?.assetsQ4
        }
      ]
    }
  ];

  const chart_carousel = {
    dots: true,
    infinite: false,
    speed: 500,
    draggable: true,
    slidesToShow: 1,
    slidesToScroll: 1
  };
  const [isLoader] = useOutletContext();

  return (
    <section className="main-section dashboard-main-section">
      <Container fluid>
        {isLoader ? (
          <SkeletonCapsyncHeader />
        ) : (
          <div className="cs-section-topbar">
            <div className="cs-dashboard-title cs-title">
              <h1 className="cs-semi-bold-h1 cs-neutral-100">
                {user.first_time_login > 1 ? 'Welcome Back,' : 'Welcome,'}
                {` ${user?.display_name}!`}
              </h1>
              {lastSyncData && lastSyncData?.lastSyncData ? (
                <span className="cs-regular-body-text-m cs-neutral-80">
                  {`Last sync: ${lastSyncData.lastSyncData}`}
                </span>
              ) : (
                ''
              )}
            </div>
          </div>
        )}
        {isLoader ? (
          <CardsSkeleton />
        ) : (
          <div className="category-cards">
            <Row>
              <Col lg={4} md={4}>
                <div className="category-card">
                  <div className="card cs-asset">
                    <div className="card-icon">
                      <span className="cs-icon">
                        <CapsyncIcon title="cs-asset-icon" size="46" />
                      </span>
                    </div>
                    <Link to={`/assets`} className="card-item">
                      <span className="cs-regular-h4 cs-neutral-90">Assets</span>
                      <h3 className="cs-semi-bold-h2 cs-neutral-100">
                        $&nbsp;
                        <span>{`${totalAssets?.toLocaleString()}`}</span>
                      </h3>
                    </Link>
                    {/* dashboard loader */}
                    {isPlaidDataSynced && (
                      <CommonSpinner classParent={' cs-neutral-10 m-auto'} size="30" />
                    )}
                  </div>
                </div>
              </Col>
              <Col lg={4} md={4}>
                <div className="category-card">
                  <div className="card cs-liability">
                    <div className="card-icon">
                      <span className="cs-icon">
                        <CapsyncIcon title="cs-liability-icon" size="46" />
                      </span>
                    </div>
                    <Link to={`/liabilities`} className="card-item">
                      <span className="cs-regular-h4 cs-neutral-90">Liabilities</span>
                      <h3 className="cs-semi-bold-h2 cs-neutral-100">
                        $&nbsp;
                        <span>{`${totalLiabilities?.toLocaleString()}`}</span>
                      </h3>
                    </Link>
                    {/* dashboard loader */}
                    {isPlaidDataSynced && (
                      <CommonSpinner classParent={' cs-neutral-10 m-auto'} size="30" />
                    )}
                  </div>
                </div>
              </Col>
              <Col lg={4} md={4}>
                <div className="category-card">
                  <div className="card cs-primary">
                    <div className="card-icon">
                      <span className="cs-icon">
                        <CapsyncIcon title="cs-networth-icon" size="46" />
                      </span>
                    </div>
                    <div className="card-item">
                      <span className="cs-regular-h4 cs-neutral-90">Net Worth</span>
                      <h3 className="cs-semi-bold-h2 cs-neutral-100">
                        $&nbsp;
                        <span>{`${netWorth?.toLocaleString()}`}</span>
                      </h3>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        )}
        {isLoader ? (
          <div className="for-des">
            <div className="chart-container">
              <Row>
                <Col lg={4}>
                  <div className="pie-chart-container skeleton-pie-graph">
                    <PieChartSkeleton />
                  </div>
                </Col>
                <Col lg={8}>
                  <div className="line-chart-container skeleton-line-graph">
                    <LineChartSkeleton />
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        ) : (
          <div className="for-des">
            <div className="chart-container">
              <Row>
                <Col lg={4}>
                  <div className="donut-chart-box">
                    <div className="chart-heading">
                      <h2 className="cs-semi-bold-h2">Net Worth Allocation</h2>
                    </div>
                    {donutValue && donutValue.length === 0 ? (
                      <span className="cs-common-no-record cs-light-body-text-l cs-neutral-100">
                        No data found
                      </span>
                    ) : (
                      <Donut
                        label="DashBoard"
                        header={donutHeader}
                        value={donutValue}
                        fillColors={dashboardPieChartFill}
                      />
                    )}
                  </div>
                </Col>
                <Col lg={8}>
                  <div className="line-chart-container">
                    <div className="chart-heading">
                      <h2 className="cs-semi-bold-h2">
                        Financial Summary
                        <span>&nbsp;</span>
                        <CapsyncToolTip
                          Child={'info-outlined'}
                          placement={window.innerWidth >= 991 ? 'right' : 'top'}
                          message={'Only accounts with transaction data are included in the chart.'}
                          type="icon"
                          size="20"
                        />
                      </h2>
                      <span className="cs-regular-body-text-m cs-neutral-70">
                        Last 12 months data as of last sync
                      </span>
                    </div>
                    <div className="graph-line-chart-main-container">
                      <LineChart
                        label="Dashboard"
                        seriesArray={series}
                        offsetY={'-8'}
                        offsetX={'0'}
                        forDashboard={true}
                      />
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        )}

        {isLoader ? (
          <div className="for-mob line-chart-skeleton">
            <div className="chart-container">
              <Row>
                <Col lg={6}>
                  <div className="pie-chart-container skeleton-pie-graph">
                    <PieChartSkeleton />
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        ) : (
          <div className="for-mob cs-common-carousel">
            <Slider {...chart_carousel}>
              <Col lg={6}>
                <div className="donut-chart-box">
                  <div className="chart-heading">
                    <h2 className="cs-semi-bold-h2">Net Worth Allocation</h2>
                  </div>
                  {donutValue && donutValue.length === 0 ? (
                    <span className="cs-common-no-record cs-light-body-text-l cs-neutral-100">
                      No data found
                    </span>
                  ) : (
                    <Donut
                      label="DashBoard"
                      header={donutHeader}
                      value={donutValue}
                      fillColors={dashboardPieChartFill}
                    />
                  )}
                </div>
              </Col>
              <Col lg={6}>
                <div className="line-chart-container">
                  <div className="chart-heading">
                    <h2 className="cs-semi-bold-h2">
                      Financial Summary
                      <span>&nbsp;</span>
                      <CapsyncToolTip
                        Child={'info-outlined'}
                        placement={window.innerWidth >= 991 ? 'right' : 'top'}
                        message={'Only accounts with transaction data are included in the chart.'}
                        type="icon"
                        size="20"
                      />
                    </h2>
                    <span className="cs-regular-body-text-m cs-neutral-70">
                      Last 12 months data as of last sync
                    </span>
                  </div>
                  <div className="graph-line-chart-main-container">
                    <LineChart
                      label="Dashboard"
                      seriesArray={series}
                      offsetY={'-8'}
                      offsetX={'0'}
                      forDashboard={true}
                    />
                  </div>
                </div>
              </Col>
            </Slider>
          </div>
        )}

        {isLoader ? (
          <div className="asset-liability-container">
            <Row>
              <Col lg={6}>
                <div className="finance-box">
                  <SkeletonFinanceTable type="Assets" data={assets} />
                  <div className="for-des">
                    <div className="skeleton-total-networth">
                      <h3 className="net-worth-heading default-semi-bold-h3 cs-neutral-100">
                        <Skeleton width={150} />
                      </h3>
                      <div className="dollar-value-gap net-worth-value default-semi-bold-h3 cs-neutral-100">
                        <Skeleton width={150} />
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
              <Col lg={6}>
                <SkeletonFinanceTable type="Liabilities" data={liabilitie} />
              </Col>
            </Row>
          </div>
        ) : (
          <div className="asset-liability-container">
            <Row>
              <Col lg={6}>
                <div className="finance-box">
                  <DashboardTable name="Assets" data={assets} total={totalAssets} />

                  <div className="for-des">
                    <div className="total-networth">
                      <h3 className="net-worth-heading cs-semi-bold-h3 cs-neutral-100">
                        Net Worth
                      </h3>
                      <div className="dollar-value-gap net-worth-value cs-semi-bold-h3 cs-neutral-100">
                        <span>$&nbsp;</span>
                        <span>{netWorth.toLocaleString()}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
              <Col lg={6}>
                <DashboardTable name="Liabilities" data={liabilitie} total={totalLiabilities} />
                <ContingentLiability />
                <div className="for-mob">
                  <div className="total-networth">
                    <h3 className="net-worth-heading cs-semi-bold-h3 cs-neutral-100">Net Worth</h3>
                    <div className="dollar-value-gap net-worth-value cs-semi-bold-h3 cs-neutral-100">
                      <span>$&nbsp;</span>
                      <span>{netWorth.toLocaleString()}</span>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        )}
      </Container>
    </section>
  );
};

export default Dashboard;
