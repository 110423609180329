import React, { useEffect, useState } from 'react';
import { Button, Container, Row } from 'react-bootstrap';
import { useOutletContext } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

// COMPONENT IMPORTS
import CommonTabs from './CommonTabs';
import Pending from './status/Pending';
import Expired from './status/Expired';
import Completed from './status/Completed';
import CapsyncIcon from '../../components/CapsyncIcon';
import NewVerificationModal from './Modals/NewVerificationModal';
import SkeletonAccreditation from '../components/Skeleton/SkeletonAccreditation';

// API
import { getRequestList, setLoader, setPFSLink } from '../../slices/accreditationSlice';

/* ============================== ACCREDITATION ============================== */
const AccreditationIndex = () => {
  const dispatch = useDispatch();

  const { data, loader } = useSelector((state) => state.accreditation);
  const { user } = useSelector((state) => state.auth);

  const [status, setStatus] = useState('pending');
  const [isListingLoader, setIsListingLoader] = useState(false);
  const [isModelOpen, setIsModelOpen] = useState({
    newRequestVerification: false
  });

  useEffect(() => {
    dispatch(setPFSLink({ link: '', dropBoxID: 0 }));
    getList();
  }, []);

  const handleOpenModel = (key) => {
    if (key === 'newRequestVerification') {
      setIsModelOpen({
        ...isModelOpen,
        [key]: true
      });
    }
  };

  const handleCloseModel = (key) => {
    if (key === 'newRequestVerification') {
      return setIsModelOpen({
        ...isModelOpen,
        [key]: false
      });
    }
    setIsModelOpen({
      ...isModelOpen,
      [key]: false
    });
  };

  const getList = () => {
    const data = {
      user_id: user.id
    };
    dispatch(getRequestList(data))
      .unwrap()
      .then(() => {
        dispatch(setLoader({ listingLoading: false }));
      });
  };

  const handleTabChange = (param) => {
    if (param === status) return false;
    setStatus(param);
    setIsListingLoader(true);
  };
  const [isLoader] = useOutletContext();

  return (
    <React.Fragment>
      <section className="individual-main-section main-section accreditation-main-section">
        <Container fluid>
          <Row>
            {isLoader ? (
              <SkeletonAccreditation />
            ) : (
              <React.Fragment>
                <div className="cs-section-topbar financial-advisor-topbar">
                  <div
                    className={`client-topbar${
                      data &&
                      data.pending &&
                      data.pending.length === 0 &&
                      data.completed &&
                      data.completed.length === 0 &&
                      data.expired &&
                      data.expired.length === 0 &&
                      ' cs-no-data'
                    }`}>
                    <div className="cs-dashboard-title">
                      <h1 className="cs-semi-bold-h1 cs-neutral-100">Investor Accreditation</h1>
                    </div>
                    {data &&
                      data.pending &&
                      data.pending.length === 0 &&
                      data.completed &&
                      data.completed.length === 0 &&
                      data.expired &&
                      data.expired.length === 0 && (
                        <Button
                          onClick={() => handleOpenModel('newRequestVerification')}
                          disabled={loader && loader.listingLoading}
                          className="cs-btn-icon-primary cs-regular-h5">
                          <span className="cs-neutral-10 cs-icon">
                            <CapsyncIcon title="add-filled" size="18" />
                          </span>
                          <span className="cs-nav-text">Request New Verification</span>
                        </Button>
                      )}
                  </div>
                  {data &&
                  data.pending &&
                  data.pending.length === 0 &&
                  data.completed &&
                  data.completed.length === 0 &&
                  data.expired &&
                  data.expired.length === 0 ? (
                    <div className="individual-table">
                      <span className="cs-common-no-record cs-light-body-text-l cs-neutral-100">
                        There are no records to display
                      </span>
                    </div>
                  ) : (
                    <div className="cs-tabs-flex">
                      <CommonTabs
                        handleTabChange={(param) => handleTabChange(param)}
                        status={status}
                      />
                      {status === 'pending' && (
                        <Button
                          onClick={() => handleOpenModel('newRequestVerification')}
                          disabled={loader && loader.listingLoading}
                          className="cs-btn-icon-primary cs-regular-h5">
                          <span className="cs-neutral-10 cs-icon">
                            <CapsyncIcon title="add-filled" size="18" />
                          </span>
                          <span className="cs-nav-text">Request New Verification</span>
                        </Button>
                      )}
                    </div>
                  )}
                </div>
                <div className="cs-custom-table">
                  {data &&
                    ((data.pending && data.pending.length > 0) ||
                      (data.completed && data.completed.length > 0) ||
                      (data.expired && data.expired.length > 0)) &&
                    (status === 'pending' ? (
                      <Pending
                        isModelOpen={isModelOpen}
                        setIsModelOpen={setIsModelOpen}
                        status={status}
                        isListingLoader={isListingLoader}
                      />
                    ) : status === 'completed' ? (
                      <Completed status={status} />
                    ) : (
                      <Expired status={status} />
                    ))}
                </div>
              </React.Fragment>
            )}
          </Row>
        </Container>
      </section>
      <NewVerificationModal
        handleCloseModel={() => handleCloseModel('newRequestVerification')}
        show={isModelOpen.newRequestVerification}
      />
    </React.Fragment>
  );
};
export default AccreditationIndex;
