import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Col, Container, Row } from 'react-bootstrap';
import { useDispatch } from 'react-redux';

// COMPONENT IMPORTS
import TabCardComponent from '../components/Array/TabCardComponent';
import CapsyncLoader from '../../components/CapsyncLoader';
import CreateArrayUser from '../components/Array/CreateArrayUser';

// API
import {
  getArrayDetails,
  setIdpLoadedScript,
  updateArrayData,
  updateArrayToken,
  useArrayDetails,
  useArrayId,
  useIdpLoadedScript
} from '../../slices/arraySlice';
import { useUser } from '../../slices/authSlice';

/* ============================== IDENTITY PROTECT ============================== */
const IdentityProtect = () => {
  const dispatch = useDispatch();

  const user = useUser();
  const arrayDetails = useArrayDetails();
  const isIdpLoadedScript = useIdpLoadedScript();
  const arrayId = useArrayId();

  /* USE STATES */
  const [previewTabName, setPreviewTabName] = useState('identity-dashboard');
  const [alertCount, setAlertCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [reFetchToken, setReFetchToken] = useState(false);

  const tabArray = [
    {
      name: 'identity-dashboard',
      title: 'Dashboard',
      description: 'Review your accounts',
      icon: 'dashboard-outlined'
    },
    {
      name: 'identity-protection-services',
      title: 'Protection services',
      description: 'Protect your identity',
      icon: 'protection-outlined'
    },
    {
      name: 'identity-alerts',
      title: 'Identity alerts',
      description: `${alertCount} new alerts`,
      icon: 'identity-alerts-outlined'
    },
    {
      name: 'identity-monitored-info',
      title: 'Monitored info',
      description: 'Personal information',
      icon: 'monitored-outlined'
    },
    {
      name: 'identity-financial-accounts',
      title: 'Financial accounts',
      description: 'Monitor your accounts',
      icon: 'bank-outlined'
    }
  ];

  useEffect(() => {
    window.addEventListener('array-event', function arrayEvent(arrayEvent) {
      const { tagName, event, metadata } = arrayEvent.detail;
      if (tagName === 'array-authentication-kba' && event === 'success') {
        dispatch(
          updateArrayData({
            array_id: arrayDetails.array_id,
            array_token: metadata['user-token'] || ''
          })
        );
      } else if (event === 'logout') {
        setLoading(true);
        setReFetchToken(true);
      }
    });
  }, []);

  useEffect(() => {
    if (reFetchToken) {
      dispatch(updateArrayData({ array_id: arrayDetails.array_id, array_token: null }));
      setLoading(false);
    }
  }, [reFetchToken]);

  useEffect(() => {
    if (!arrayDetails.array_token) {
      return;
    }
    const res = dispatch(
      updateArrayToken({
        user_id: user.id,
        array_token: arrayDetails.array_token
      })
    ).unwrap();

    if (res && res.code === 200) {
      dispatch(updateArrayData({ array_id: res.data.array_id, array_token: res.data.array_token }));
    }
  }, [arrayDetails]);

  const getAlertCount = async () => {
    try {
      const res = await axios.get(process.env.REACT_APP_ARRAY_ALERT_URL, {
        params: {
          userId: arrayDetails.array_id,
          count: 100,
          offset: 0,
          bureau: 'exp'
        },
        headers: { 'x-array-user-token': arrayDetails.array_token }
      });

      if (res && res.data) {
        setAlertCount(res.data.userAlerts.length || 0);
      }
    } catch (error) {
      console.log('error', error.message);
    }
  };

  useEffect(() => {
    if (arrayDetails.array_id && arrayDetails.array_token) {
      getAlertCount();
    }
  }, [arrayDetails]);

  const fetchArrayDetails = async () => {
    try {
      setLoading(true);
      await dispatch(getArrayDetails(user.id)).unwrap();
      setLoading(false);
    } catch (error) {
      console.log('error', error.message);
    }
  };

  useEffect(() => {
    fetchArrayDetails();
  }, []);

  useEffect(() => {
    if (loading || !arrayId) {
      return;
    }
    const crdElem = document.getElementById('wlp-identity-protect');
    if (!crdElem) {
      return;
    }
    const loadScript = (src) => {
      const script = document.createElement('script');
      script.src = src;
      crdElem.appendChild(script);
      return script;
    };
    if (!arrayDetails.array_token) {
      loadScript(
        `https://embed.array.io/cms/array-web-component.js?appKey=${process.env.REACT_APP_ARRAY_KEY}`
      );
      loadScript(
        `https://embed.array.io/cms/array-authentication-kba.js?appKey=${process.env.REACT_APP_ARRAY_KEY}`
      );
      dispatch(setIdpLoadedScript(false));
    } else if (isIdpLoadedScript === false) {
      loadScript(
        `https://embed.array.io/cms/array-web-component.js?appKey=${process.env.REACT_APP_ARRAY_KEY}`
      );
      loadScript(
        `https://embed.array.io/cms/array-identity-protect.js?appKey=${process.env.REACT_APP_ARRAY_KEY}`
      );
      dispatch(setIdpLoadedScript(true));
    }
  }, [arrayDetails, loading]);

  const getSelectedTabName = () => {
    const tabName = tabArray.filter((val) => val.name === previewTabName);
    return tabName.length ? tabName[0].title : '';
  };

  const renderReport = (identity_protect_name) => {
    switch (identity_protect_name) {
      case 'identity-dashboard':
        return (
          <array-identity-protect
            appKey={process.env.REACT_APP_ARRAY_KEY}
            userToken={arrayDetails.array_token}
            sandbox={process.env.REACT_APP_MODE !== 'PROD'}
            useTabTextAsPageHeading="true"
            creditreportlink="/credit-manager"
            identityprotectlink="/identity-protect"
            startPage="dashboard"
            hidePageNavigation="true"
            autoEnrollmentCode="false"
          />
        );

      case 'identity-protection-services':
        return (
          <array-identity-protect
            appKey={process.env.REACT_APP_ARRAY_KEY}
            userToken={arrayDetails.array_token}
            sandbox={process.env.REACT_APP_MODE !== 'PROD'}
            useTabTextAsPageHeading="true"
            creditreportlink="/credit-manager"
            identityprotectlink="/identity-protect"
            startPage="protectionServices"
            hidePageNavigation="true"
            autoEnrollmentCode="false"
          />
        );

      case 'identity-alerts':
        return (
          <array-identity-protect
            appKey={process.env.REACT_APP_ARRAY_KEY}
            userToken={arrayDetails.array_token}
            sandbox={process.env.REACT_APP_MODE !== 'PROD'}
            useTabTextAsPageHeading="true"
            creditreportlink="/credit-manager"
            identityprotectlink="/identity-protect"
            startPage="alerts"
            hidePageNavigation="true"
            autoEnrollmentCode="false"
          />
        );

      case 'identity-monitored-info':
        return (
          <array-identity-protect
            appKey={process.env.REACT_APP_ARRAY_KEY}
            userToken={arrayDetails.array_token}
            sandbox={process.env.REACT_APP_MODE !== 'PROD'}
            useTabTextAsPageHeading="true"
            creditreportlink="/credit-manager"
            identityprotectlink="/identity-protect"
            startPage="monitoredInformation"
            hidePageNavigation="true"
            autoEnrollmentCode="false"
          />
        );

      case 'identity-financial-accounts':
        return (
          <array-identity-protect
            appKey={process.env.REACT_APP_ARRAY_KEY}
            userToken={arrayDetails.array_token}
            sandbox={process.env.REACT_APP_MODE !== 'PROD'}
            useTabTextAsPageHeading="true"
            creditreportlink="/credit-manager"
            identityprotectlink="/identity-protect"
            startPage="financialAccounts"
            hidePageNavigation="true"
            autoEnrollmentCode="false"
          />
        );
      default:
        return;
    }
  };

  return (
    <section
      className="individual-main-section main-section credit-manager-details-main-section"
      id="wlp-identity-protect">
      <Container fluid>
        <Row>
          <Col lg={12}>
            {loading ? (
              <CapsyncLoader />
            ) : !arrayDetails.array_id && !arrayDetails.array_token ? (
              <CreateArrayUser />
            ) : !arrayDetails.array_token && arrayDetails.array_id ? (
              <div className="container-fluid">
                <array-authentication-kba
                  appKey={process.env.REACT_APP_ARRAY_KEY}
                  sandbox={process.env.REACT_APP_MODE !== 'PROD'}
                  userId={arrayDetails.array_id}
                  showResultPages="false"
                />
              </div>
            ) : (
              <div className="container-fluid">
                <div className="cs-section-topbar">
                  <div className="cs-title">
                    <h1 className="cs-semi-bold-h1 cs-neutral-100">{getSelectedTabName()}</h1>
                  </div>
                </div>
                <div className="cs-grid cs-array-row">
                  {tabArray.map((val, idx) => {
                    return (
                      <div className="category-card cs-qbo-card" key={idx}>
                        <TabCardComponent
                          onClick={() => {
                            setPreviewTabName(val.name);
                          }}
                          className={
                            val.name === previewTabName
                              ? 'cs-array-card card active-card'
                              : 'card cs-array-card'
                          }
                          description={val.description}
                          title={val.title}
                          img={val.icon}
                          selected={val.name === previewTabName}
                        />
                      </div>
                    );
                  })}
                </div>
                {renderReport(previewTabName)}
              </div>
            )}
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default IdentityProtect;
