import * as React from 'react';

// COMPONENT IMPORTS
import MasterAdminRedirect from '../auth/MasterAdminRedirect';
import NoAccess from '../auth/NoAccess';

/* ============================== USER REDIRECT ============================== */
const RedirectUser = {
  path: 'redirect',
  children: [
    {
      path: 'master-admin/:token/:professionalUserId/:clientId/:roleId',
      element: <MasterAdminRedirect />
    },
    {
      path: 'no-access',
      element: <NoAccess />
    }
  ]
};

export default RedirectUser;
