import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { FixedCropper, CircleStencil } from 'react-advanced-cropper';

// CSS IMPORTS
import 'react-advanced-cropper/dist/style.css';
import 'react-advanced-cropper/dist/themes/corners.css';

/* ============================== CROP AVATAR ============================== */
export const CropAvatar = ({ img, imgRef }) => {
  useEffect(() => {
    if (imgRef.current) {
      imgRef.current.getImage();
    }
  }, [img]);

  return (
    <div className={`favicon-Avatar`}>
      <FixedCropper
        ref={imgRef}
        src={img}
        stencilSize={{
          width: 320,
          height: 90
        }}
        stencilComponent={CircleStencil}
        imageRestriction="stencil"
        stencilProps={{
          aspectRatio: 1,
          handlers: false,
          lines: true,
          movable: false,
          resizable: false
        }}
      />
    </div>
  );
};

// PROPS TYPE
CropAvatar.propTypes = {
  img: PropTypes.string.isRequired,
  imgRef: PropTypes.any
};
