import React from 'react';
import { Navigate } from 'react-router-dom';

// COMPONENT IMPORTS
import SignIn from '../auth/SignIn';
import SignUp from '../auth/SignUp';
import MFASetUp from '../auth/MFASetUp';
import AuthLayout from '../pages/AuthLayout';
import GoogleSetupQR from '../auth/GoogleSetupQR';
import ResetPassword from '../auth/ResetPassword';
import CreatePassword from '../auth/CreatePassword';
import ForgotPassword from '../auth/ForgotPassword';
import VerificationPin from '../auth/VerificationPin';
// import ProtectedRoutes from './ProtectedRoutes';

/* ============================== AUTH ROUTES ============================== */
const AuthRoutes = {
  path: '/',
  element: <AuthLayout />,
  children: [
    {
      path: '/',
      element: <SignIn />
    },
    {
      path: 'sign-in',
      element: <Navigate to="/" replace />
    },
    {
      path: 'sign-up/:token',
      element: <SignUp />
    },
    {
      path: 'create-password/:token',
      element: <CreatePassword />
    },
    {
      path: 'mfa-setup',
      element: <MFASetUp />
    },
    {
      path: 'verification-pin',
      element: <VerificationPin />
    },
    {
      path: 'forgot-password',
      element: <ForgotPassword />
    },
    {
      path: 'forgot-password/:resetPasswordToken',
      element: <ResetPassword />
    },
    {
      path: 'authenticator-setup',
      element: <GoogleSetupQR />
    }
  ]
};

export default AuthRoutes;
