import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dropdown, Image } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';

// COMPONENT IMPORTS
import { dateFormat } from '../../../utils/common';
import { additionalDocumentJSON } from '../ModalJSON';
import CustomTable from '../../components/CustomTable';
import CapsyncIcon from '../../../components/CapsyncIcon';
import DeleteModal from '../../../components/DeleteModal';
import StaticPagination from '../common/StaticPagination';
import CommonSpinner from '../../../components/CommonSpinner';
import CapsyncToolTip from '../../../components/CapsyncToolTip';
import { initialValues } from '../SubMethods/SubMethodsInitialValues';
import CapFavicon from '../../../assets/images/brand/cap-icon-logo.svg';
import { validationSchema } from '../SubMethods/SubMethodsValidationSchema';
import { handleAccreditationModals } from '../SubMethods/SubMethodsBasicFunctions';
import { AdditionalDocumentModal, AttachmentModal } from '../SubMethods/SubMethodsFormModal';

// API
import {
  getRequestList,
  resetModal,
  setLoader,
  setMethodName,
  setRowID,
  setSubMethod,
  uploadRequestedMedia,
  verificationRequestDelete
} from '../../../slices/accreditationSlice';
import { useBrandDetails } from '../../../slices/brandingSlice';

/* ============================== PENDING STATUS ============================== */
const Pending = ({ isListingLoader }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const brandDetails = useBrandDetails();
  const { user, accessUser } = useSelector((state) => state.auth);
  const { data = [], loader, modal } = useSelector((state) => state.accreditation);

  const [rowStartIdx, setRowStartIdx] = useState(0);
  const paginationDropdownItems = [10, 15, 20, 25, 30];
  const [rowsPerPage, setRowsPerPage] = useState(paginationDropdownItems[0]);
  const [dropdownDisplay, setDropdownDisplay] = useState(rowsPerPage);
  const [uniqueRowId, setUniqueRowId] = useState();
  const [deleteStatus, setDeleteStatus] = useState({
    show: false,
    message: '',
    title: '',
    custom: ''
  });

  const handleUpdateDocument = (uniqueRowId) => {
    setUniqueRowId(uniqueRowId);
    handleAccreditationModals(dispatch, 'additionalDocumentModal', true);
  };

  const continueVerification = (
    verificationType,
    uniqueCode,
    verificationMethodId,
    isRequestSent,
    isFinalCallDone
  ) => {
    const loaderKey = 'continueVerification' + uniqueCode;
    dispatch(setLoader({ [loaderKey]: true }));
    if (verificationMethodId === null || verificationMethodId === 0) {
      if (verificationType === 'Accredited Investor') {
        setTimeout(() => {
          dispatch(setMethodName('individuals'));
          dispatch(setRowID(uniqueCode));
          dispatch(setLoader({ [loaderKey]: false }));
          navigate(`/verifyinvestor/verification-type`);
        }, 1000);
      } else if (verificationType === 'Qualified Client') {
        setTimeout(() => {
          dispatch(setMethodName('qualifiedclient'));
          dispatch(setRowID(uniqueCode));
          dispatch(setLoader({ [loaderKey]: false }));
          navigate(`/verifyinvestor/verification-type`);
        }, 1000);
      } else if (verificationType === 'Qualified Purchaser') {
        setTimeout(() => {
          dispatch(setMethodName('qualifiedpurchaser'));
          dispatch(setRowID(uniqueCode));
          dispatch(setLoader({ [loaderKey]: false }));
          navigate(`/verifyinvestor/verification-type`);
        }, 1000);
      }
    } else if (!isRequestSent && !isFinalCallDone) {
      if (verificationType === 'Accredited Investor') {
        setTimeout(() => {
          dispatch(setMethodName('individuals'));
          dispatch(setRowID(uniqueCode));
          dispatch(setSubMethod(verificationMethodId));
          dispatch(setLoader({ [loaderKey]: false }));
          navigate(`/verifyinvestor/verification-type/method`);
        }, 1000);
      } else if (verificationType === 'Qualified Client') {
        setTimeout(() => {
          dispatch(setMethodName('qualifiedclient'));
          dispatch(setRowID(uniqueCode));
          dispatch(setSubMethod(verificationMethodId));
          dispatch(setLoader({ [loaderKey]: false }));
          navigate(`/verifyinvestor/verification-type/method`);
        }, 1000);
      } else if (verificationType === 'Qualified Purchaser') {
        setTimeout(() => {
          dispatch(setMethodName('qualifiedpurchaser'));
          dispatch(setRowID(uniqueCode));
          dispatch(setSubMethod(verificationMethodId));
          dispatch(setLoader({ [loaderKey]: false }));
          navigate(`/verifyinvestor/verification-type/method`);
        }, 1000);
      }
    } else if (!isFinalCallDone) {
      // dispatch(verify_request_and_send({ unique_rowId: uniqueCode }))
      //   .unwrap()
      //   .then((response) => {
      //     verify_investor_pendingData();
      //   })
      //   .catch((err) => {
      //     console.log(err);
      //   });
    }
  };

  const capsyncDeleteMessage = (showDeleteModal, msg, title, func) => {
    if (!showDeleteModal) {
      setDeleteStatus({
        ...deleteStatus,
        show: false
      });
    } else {
      setDeleteStatus({
        show: showDeleteModal,
        message: msg,
        title: title,
        custom: func
      });
    }
  };

  const handleDelete = async (recId) => {
    dispatch(setLoader({ deleteBtnLoader: true }));
    dispatch(verificationRequestDelete({ id: recId, user_id: user.id }))
      .unwrap()
      .then((resp) => {
        if (resp.code === 200) {
          getList();
          capsyncDeleteMessage(false, '', '', '');
        }
        dispatch(setLoader({ deleteBtnLoader: false }));
        toast.success('Your record has been deleted successfully.');
      })
      .catch((err) => {
        console.log(err);
        dispatch(setLoader({ deleteBtnLoader: false }));
      });
  };

  const deletePendingRow = (id) => {
    capsyncDeleteMessage(true, 'Delete', '', () => handleDelete(id));
  };

  const COLUMNS = [
    {
      label: 'Legal name',
      width: '263',
      visible: true,
      renderCell: (item) => item.legal_name
    },
    {
      label: 'Requested',
      width: '220',
      visible: true,
      renderCell: (item) => dateFormat(item.createdAt)
    },
    {
      label: 'Type',
      width: '300',
      visible: true,
      renderCell: (item) => item.verification_type
    },
    {
      label: 'Status',
      width: '270',
      visible: true,
      renderCell: (item) => (
        <div className="cs-progress-indicator">
          <div
            className={
              item.signature_hello_id !== '' &&
              item.signature_hello_id !== null &&
              item.is_request_sent === false
                ? 'cs-warning cs-indicator'
                : item.is_request_sent === true
                  ? 'cs-success cs-indicator'
                  : 'cs-indicator cs-neutral-50 '
            }>
            <span>
              <CapsyncToolTip
                Child={'information-outlined'}
                placement={'top'}
                message={
                  item.signature_hello_id !== '' &&
                  item.signature_hello_id !== null &&
                  item.sign_pfs_link === null &&
                  item.is_request_sent === false
                    ? 'Signature request sent'
                    : item.sign_pfs_link !== null && item.is_request_sent === false
                      ? 'Signature request completed'
                      : item.is_request_sent === true
                        ? 'Request sent successfully'
                        : 'Waiting for information'
                }
                type="icon"
                size="22"
              />
            </span>
            <span className="cs-indicator-line"></span>
          </div>
          <div
            className={
              item.is_request_sent === true
                ? 'cs-success cs-indicator'
                : 'cs-indicator cs-neutral-50 '
            }>
            <span>
              <CapsyncToolTip
                Child={'review-outlined'}
                placement={'top'}
                message={'Waiting for review'}
                type="icon"
                size="22"
              />
            </span>
            <span className="cs-indicator-line"></span>
          </div>
          {item.is_request_locked === false &&
          item.is_request_sent === true &&
          item.request_status === 4 ? (
            <div className="cs-indicator cs-warning">
              <span>
                <CapsyncToolTip
                  Child={'error-outlined'}
                  placement={'top'}
                  size="26"
                  message={'Additional document Required'}
                  type="icon"
                />
              </span>
              <span className="cs-indicator-line"></span>
            </div>
          ) : (
            ' '
          )}
          <div
            className={
              item.is_request_sent === true
                ? 'cs-success cs-indicator'
                : 'cs-indicator cs-neutral-50 '
            }>
            <span className="cs-neutral-50">
              <CapsyncToolTip
                Child={'verification-outlined'}
                placement={'top'}
                message={'Waiting for verification'}
                type="icon"
                size="22"
              />
            </span>
          </div>
        </div>
      )
    },
    {
      label: 'Reference',
      width: '240',
      visible: true,
      renderCell: (item) =>
        item.reference && item.reference !== '' && item.reference !== null ? (
          item.reference.length > 25 ? (
            <CapsyncToolTip
              Child={
                item.reference.length > 25 ? item.reference.slice(0, 25) + '...' : item.reference
              }
              placement={'top'}
              message={item.reference}
              type="text"
            />
          ) : (
            item.reference
          )
        ) : (
          '-'
        )
    },
    {
      label: 'Actions',
      width: '220',
      visible: true,
      renderCell: (item) => (
        <div className="cs-common-add-dropdown accreditation-action">
          <Dropdown>
            <Dropdown.Toggle variant="link">
              <span className="cs-table-menu-icon icon cs-neutral-80">
                {loader && loader['continueVerification' + item.unique_id] ? (
                  <CommonSpinner
                    classParent={`icon${loader && loader['continueVerification' + item.unique_id] ? ' cs-primary m-auto' : ' d-none'}`}
                    size="20"
                  />
                ) : (
                  <CapsyncIcon title="option-horizontal-filled" size="18" />
                )}
              </span>
            </Dropdown.Toggle>
            <div className="accreditation-menu">
              <Dropdown.Menu>
                {item.request_status === 1 && item.is_request_sent === true ? (
                  <React.Fragment>
                    <Dropdown.Item className="cs-disabled cs-neutral-90">
                      <CapsyncIcon title="chevron-right-outlined" size="22" />
                      <span className="cs-neutral-90">Continue Verification</span>
                    </Dropdown.Item>
                    <Dropdown.Item className="cs-disabled cs-neutral-90">
                      <CapsyncIcon title="delete-outlined" size="22" />
                      <span className="cs-neutral-90">Delete verification</span>
                    </Dropdown.Item>
                  </React.Fragment>
                ) : item.is_request_locked === false &&
                  item.is_request_sent === true &&
                  item.request_status === 4 &&
                  (((!accessUser || !accessUser.accessUserID || accessUser.accessUserID === null) &&
                    item.visitors_id !== 0) ||
                    (accessUser &&
                      accessUser.accessUserID &&
                      accessUser.accessUserID !== null &&
                      item.visitors_id !== accessUser.accessUserID)) ? (
                  <React.Fragment>
                    <Dropdown.Item className="cs-disabled cs-neutral-90">
                      <CapsyncIcon title="chevron-right-outlined" size="22" />
                      <span className="cs-neutral-90">Continue Verification</span>
                    </Dropdown.Item>
                    <Dropdown.Item className="cs-disabled cs-neutral-90">
                      <CapsyncIcon title="delete-outlined" size="22" />
                      <span className="cs-neutral-90">Delete verification</span>
                    </Dropdown.Item>
                    <Dropdown.Item className="cs-disabled cs-neutral-90">
                      <CapsyncIcon title="files-outlined" size="22" />
                      <span className="cs-neutral-90">Upload Document</span>
                    </Dropdown.Item>
                  </React.Fragment>
                ) : item.is_request_locked === false &&
                  item.is_request_sent === true &&
                  item.request_status === 4 &&
                  accessUser &&
                  accessUser.accessUserID &&
                  accessUser.accessUserID !== null &&
                  accessUser.accessUserID === item.visitors_id ? (
                  <React.Fragment>
                    <Dropdown.Item className="cs-disabled cs-neutral-90">
                      <CapsyncIcon title="chevron-right-outlined" size="22" />
                      <span className="cs-neutral-90">Continue Verification</span>
                    </Dropdown.Item>
                    <Dropdown.Item className="cs-disabled cs-neutral-90">
                      <CapsyncIcon title="delete-outlined" size="22" />
                      <span className="cs-neutral-90">Delete verification</span>
                    </Dropdown.Item>
                    <Dropdown.Item
                      className="cs-neutral-90"
                      onClick={() => handleUpdateDocument(item.unique_id)}>
                      <CapsyncIcon title="files-outlined" size="22" />
                      <span className="cs-neutral-90">Upload Document</span>
                    </Dropdown.Item>
                  </React.Fragment>
                ) : item.is_request_locked === false &&
                  item.is_request_sent === true &&
                  item.request_status === 4 ? (
                  <React.Fragment>
                    <Dropdown.Item className="cs-disabled cs-neutral-90">
                      <CapsyncIcon title="chevron-right-outlined" size="22" />
                      <span className="cs-neutral-90">Continue Verification</span>
                    </Dropdown.Item>
                    <Dropdown.Item className="cs-disabled cs-neutral-90">
                      <CapsyncIcon title="delete-outlined" size="22" />
                      <span className="cs-neutral-90">Delete verification</span>
                    </Dropdown.Item>
                    <Dropdown.Item
                      className="cs-neutral-90"
                      onClick={() => handleUpdateDocument(item.unique_id)}>
                      <CapsyncIcon title="files-outlined" size="22" />
                      <span className="cs-neutral-90">Upload Document</span>
                    </Dropdown.Item>
                  </React.Fragment>
                ) : (!accessUser || !accessUser.accessUserID || accessUser.accessUserID === null) &&
                  item.visitors_id !== 0 ? (
                  <React.Fragment>
                    <Dropdown.Item className="cs-disabled cs-neutral-90">
                      <CapsyncIcon title="chevron-right-outlined" size="22" />
                      <span className="cs-neutral-90">Continue Verification</span>
                    </Dropdown.Item>
                    <Dropdown.Item className="cs-disabled cs-neutral-90">
                      <CapsyncIcon title="delete-outlined" size="22" />
                      <span className="cs-neutral-90">Delete verification</span>
                    </Dropdown.Item>
                  </React.Fragment>
                ) : accessUser &&
                  accessUser.accessUserID &&
                  accessUser.accessUserID !== null &&
                  accessUser.accessUserID !== item.visitors_id ? (
                  <React.Fragment>
                    <Dropdown.Item className="cs-disabled cs-neutral-90">
                      <CapsyncIcon title="chevron-right-outlined" size="22" />
                      <span className="cs-neutral-90">Continue Verification</span>
                    </Dropdown.Item>

                    <Dropdown.Item className="cs-disabled cs-neutral-90">
                      <CapsyncIcon title="delete-outlined" size="22" />
                      <span className="cs-neutral-90">Delete verification</span>
                    </Dropdown.Item>
                  </React.Fragment>
                ) : accessUser &&
                  accessUser.accessUserID &&
                  accessUser.accessUserID !== null &&
                  accessUser.accessUserID === item.visitors_id ? (
                  <React.Fragment>
                    <Dropdown.Item
                      className="cs-neutral-90"
                      onClick={() =>
                        continueVerification(
                          item.verification_type,
                          item.unique_id,
                          item.method_id,
                          item.is_request_sent,
                          item.is_request_locked
                        )
                      }>
                      <CapsyncIcon title="chevron-right-outlined" size="22" />
                      <span className="cs-neutral-90">Continue Verification</span>
                    </Dropdown.Item>
                    <Dropdown.Item
                      className="cs-neutral-90"
                      onClick={() => {
                        deletePendingRow(item.id);
                      }}>
                      <CapsyncIcon title="delete-outlined" size="22" />
                      <span className="cs-neutral-90">Delete verification</span>
                    </Dropdown.Item>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <Dropdown.Item
                      className="cs-neutral-90"
                      onClick={() =>
                        continueVerification(
                          item.verification_type,
                          item.unique_id,
                          item.method_id,
                          item.is_request_sent,
                          item.is_request_locked
                        )
                      }>
                      <CapsyncIcon title="chevron-right-outlined" size="22" />
                      <span className="cs-neutral-90">Continue Verification</span>
                    </Dropdown.Item>
                    <Dropdown.Item
                      className="cs-neutral-90"
                      onClick={() => {
                        deletePendingRow(item.id);
                      }}>
                      <CapsyncIcon title="delete-outlined" size="22" />
                      <span className="cs-neutral-90">Delete verification</span>
                    </Dropdown.Item>
                  </React.Fragment>
                )}
              </Dropdown.Menu>
            </div>
          </Dropdown>
        </div>
      )
    }
  ];

  useEffect(() => {
    dispatch(setLoader({ listingLoading: true }));
    dispatch(resetModal());
  }, []);

  useEffect(() => {
    if (isListingLoader) {
      getList();
    }
  }, [isListingLoader]);

  const getList = () => {
    const data = {
      user_id: user.id
    };
    dispatch(getRequestList(data))
      .unwrap()
      .then(() => {
        dispatch(setLoader({ listingLoading: false }));
      });
  };

  const additionalDocumentFormik = useFormik({
    initialValues: initialValues('additionalDocument').initialValuesObj,
    validationSchema: validationSchema('additionalDocument').validations,
    onSubmit: (values, { resetForm }) => {
      dispatch(setLoader({ additionalDocumentModalLoader: true }));
      if (values['data_obj'].length > 0 || values['attachments'].length > 0) {
        const formData = new FormData();
        formData.append('data_obj', JSON.stringify(values['data_obj']));
        const all_details = {
          unique_id: uniqueRowId,
          user_id: user.id,
          notes: values['notes']
        };

        if (values['attachments'].length > 0) {
          for (let i = 0; i < values['attachments'].length; i++) {
            formData.append('attachments', values['attachments'][i]);
          }
        }
        // if (values['data_obj'].length >= 1) {
        //   formData.append('attached_documents', JSON.stringify(values['data_obj']));
        // }
        formData.append('users_data', JSON.stringify(all_details));
        dispatch(uploadRequestedMedia(formData))
          .unwrap()
          .then((response) => {
            if (response.code === 200) {
              toast.success(response.message);
              handleAccreditationModals(dispatch, 'additionalDocumentModal', false);
              resetForm();
              getList();
            }
            if (response.code === 500) {
              toast.error(response.message);
              resetForm();
              handleAccreditationModals(dispatch, 'additionalDocumentModal', false);
            }
          })
          .catch((err) => {
            console.log('err', err);
          });
      } else {
        toast.error('Please select files to submit to the database.');
      }
      dispatch(setLoader({ additionalDocumentModalLoader: false }));
    }
  });

  return (
    <React.Fragment>
      {loader && loader.listingLoading ? (
        <div className="text-center api-prog-bar sign-loader-area">
          <div className="text-center">
            {brandDetails?.is_use_load_image ? (
              <Image
                src={(brandDetails && brandDetails.small_logo_s3_url) || CapFavicon}
                className="spin-animation"
                width={120}
                height={120}
              />
            ) : (
              <CommonSpinner size={45} classParent="cs-primary" />
            )}
          </div>
        </div>
      ) : (
        <div className="individual-table">
          <CustomTable
            columns={COLUMNS}
            data={data.pending.slice(rowStartIdx, rowStartIdx + rowsPerPage)}
            isPaginate={false}
            totalRecords={data.length || 0}
            fromIndividual={false}
          />
          {data.pending.length > 0 && (
            <StaticPagination
              dropdownDisplay={dropdownDisplay}
              paginationDropdownItems={paginationDropdownItems}
              rowStartIdx={rowStartIdx}
              rowsPerPage={rowsPerPage}
              dataLength={data.pending.length}
              setRowsPerPage={setRowsPerPage}
              setRowStartIdx={setRowStartIdx}
              setDropdownDisplay={setDropdownDisplay}
            />
          )}
        </div>
      )}
      <DeleteModal
        show={deleteStatus.show}
        handleCloseModel={() => capsyncDeleteMessage(false, '', '', '')}
        handleDeleteData={() => deleteStatus.custom()}
        loader={loader && loader.deleteBtnLoader}
      />
      <AdditionalDocumentModal
        show={modal && modal.additionalDocumentModal}
        formJSON={additionalDocumentJSON(loader && loader['additionalDocumentModalLoader'])}
        formik={additionalDocumentFormik}
        loading={loader && loader['additionalDocumentModalLoader']}
      />
      {modal && (
        <AttachmentModal show={modal.attachmentDocument} formik={additionalDocumentFormik} />
      )}
    </React.Fragment>
  );
};

// PROPS TYPE
Pending.propTypes = {
  isListingLoader: PropTypes.string
};

export default Pending;
