import React from 'react';
import PropTypes from 'prop-types';

// COMPONENT IMPORTS
import CapsyncIcon from './CapsyncIcon';
import Modal from './Modal';

/* ============================== LOGOUT CONFIRMATION MODAL ============================== */
const LogoutConfirmationModal = ({ showLogoutModal, handleStayOnCapsync, handleLogoutUser }) => {
  const logoutModalBody = () => (
    <div className="cs-text-center logout-modal">
      <span className="icon cs-icon">
        <CapsyncIcon title="error-validation" size="100" />
      </span>
      <p className="cs-light-body-text-m cs-neutral-90">Are you sure you want to log out?</p>
    </div>
  );

  return (
    <Modal
      show={showLogoutModal}
      title={'Confirm log out'}
      className={'cs-md-modal'}
      parentClassName={'cs-common-modal-overlay'}
      isCloseButton={false}
      body={logoutModalBody()}
      cancelButtonLabel="Log out"
      saveButtonLabel="Stay Here"
      handleOnSave={handleStayOnCapsync}
      handleOnCancel={handleLogoutUser}
      deleteButtonText="Stay Here"
      modalFooterClass="cs-center-btn"
    />
  );
};

// PROPS TYPE
LogoutConfirmationModal.propTypes = {
  showLogoutModal: PropTypes.bool,
  handleStayOnCapsync: PropTypes.func,
  handleLogoutUser: PropTypes.func
};

export default LogoutConfirmationModal;
