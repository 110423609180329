import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import { useMemo } from 'react';

// COMPONENT IMPORTS
import axios from '../config/api';

// BRANDING API CALL
export const getBrandDetails = createAsyncThunk('auth/firm_brand', async (domain) => {
  return await axios.get(`firm_brand/get_brand_details_by_domain/${domain}`);
});

// INITIAL STATE
const initialState = {
  brandDetails: {},
  isPageLoading: true
};

/* ============================== BRANDING SLICE ============================== */
const brandingSlice = createSlice({
  name: 'branding',
  initialState,
  reducers: {
    clearBrandingDetails: (state) => {
      state = initialState;
    },
    setIsPageLoading: (state, action) => {
      state.isPageLoading = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getBrandDetails.fulfilled, (state, { payload }) => {
        const { status, data } = payload;
        state.brandDetails = status ? data : {};
      })
      .addCase(getBrandDetails.rejected, (state) => {
        state.brandDetails = {};
      });
  }
});
export const { clearBrandingDetails, setIsPageLoading } = brandingSlice.actions;
export default brandingSlice.reducer;

const selectBrandDetails = (state) => state.branding.brandDetails;
export const useBrandDetails = () => {
  const brandDetails = useSelector(selectBrandDetails);
  return useMemo(() => brandDetails, [brandDetails]);
};
