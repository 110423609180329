import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { PaymentElement } from '@stripe/react-stripe-js';
import { useElements, useStripe } from '@stripe/react-stripe-js';
import { Button, Modal } from 'react-bootstrap';

// COMPONENT IMPORTS
import CapsyncIcon from '../../CapsyncIcon';

/* ============================== STRIPE CHECKOUT FORM ============================== */
const StripeCheckOutForm = (props) => {
  const { setShowStripeForm, setPaymentLoader } = props;

  const stripe = useStripe();
  const elements = useElements();

  const [loading, setLoading] = useState(false);
  const [isStripeLoading, setIsStripeLoading] = useState(true);
  const [stripeErrorMessage, setStripeErrorMessage] = useState('');

  const CARD_ELEMENT_OPTIONS = {
    fields: {
      billingDetails: {
        address: {
          country: 'never'
        }
      }
    },
    style: {
      base: {
        color: '#999',
        fontWeight: 500,
        fontSize: '16px',
        fontSmoothing: 'antialiased'
      },
      invalid: {
        iconColor: '#ffc7ee',
        color: '#ffc7ee'
      }
    }
  };

  const handleCardNumberChange = (event) => {
    setStripeErrorMessage(event.error ? event.error.message : '');
  };

  const closeModal = () => {
    setStripeErrorMessage('');
    setShowStripeForm(false);
  };

  const handleSubmit = async (e) => {
    setLoading(true);
    try {
      stripe
        .confirmPayment({
          elements,
          redirect: 'if_required',
          confirmParams: {
            payment_method_data: {
              billing_details: {
                address: {
                  country: 'US'
                }
              }
            },
            // Return URL where the customer should be redirected after the PaymentIntent is confirmed.
            return_url: 'https://example.com'
          }
        })
        .then((result) => {
          if (result.error) {
            setLoading(false);
            console.log('confirmPayment result error:-', result.error);
            if (result.error.type != 'validation_error') {
              setPaymentLoader(true);
              setShowStripeForm(false);
              setStripeErrorMessage(result.error.message);
            }
          } else {
            setLoading(true);
            setPaymentLoader(true);
            setShowStripeForm(false);
          }
        })
        .catch((error) => {
          setLoading(false);
          console.log('confirmPayment error:-', error);
        });
    } catch (err) {
      console.log('err 11', err);
      setStripeErrorMessage('Something wrong');
    }
  };

  // HANDLED STRIPE JS NOT LOADING ERROR
  useEffect(() => {
    if (elements) {
      const paymentElement = elements && elements.getElement('payment');
      if (paymentElement) {
        paymentElement.on('loaderror', function (element) {
          if (element.error.message) {
            setTimeout(() => {
              setStripeErrorMessage(element.error.message);
              setIsStripeLoading(false);
            }, 2000);
          }
        });
      }
    }
  }, [elements]);

  return (
    <Modal
      className="cs-common-modal-overlay stripe-form"
      show={true}
      style={{ zIndex: '99999999' }}
      centered
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      dialogClassName="cs-md-modal">
      <Modal.Header>
        <span className="cs-neutral-70 cs-previous-btn" onClick={closeModal}>
          <CapsyncIcon title="chevron-left-outlined" size="24px" alt="LeftIcon" />
        </span>
        <Modal.Title className="cs-semi-bold-h3 card-modal-head">Enter card details</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="cs-form-group">
          <div className="cs-stripe-spinner">
            {isStripeLoading && (
              <span className="cs-common-spinner cs-primary">
                <CapsyncIcon title="loading-outlined" size="30" />
              </span>
            )}
          </div>
          <PaymentElement
            options={CARD_ELEMENT_OPTIONS}
            onChange={handleCardNumberChange}
            onReady={() => setIsStripeLoading(false)}
          />
          {stripeErrorMessage && (
            <span className="form-error-msg cs-light-body-text-s cs-danger">
              {stripeErrorMessage}
            </span>
          )}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="cs-modal-btn">
          <Button className="cs-btn-secondary md-btn cs-regular-h5" onClick={closeModal}>
            Cancel
          </Button>
          <Button onClick={() => handleSubmit()} className="cs-btn-primary md-btn cs-regular-h5">
            Continue
            {loading && (
              <span className="cs-common-spinner cs-neutral-40">
                <CapsyncIcon title="loading-outlined" size="18" />
              </span>
            )}
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

// PROPS TYPE
StripeCheckOutForm.propTypes = {
  setShowStripeForm: PropTypes.bool,
  setPaymentLoader: PropTypes.bool
};

export default StripeCheckOutForm;
