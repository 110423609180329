import React, { useState } from 'react';
import { Button, Image, Modal } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

// COMPONENT IMPORTS
import CommonSpinner from '../../CommonSpinner';
import CapFavicon from '../../../assets/images/brand/cap-icon-logo.svg';
import successValidation from '../../../assets/images/other/payment-successful-icon.svg';

// API
import { useBrandDetails } from '../../../slices/brandingSlice';

/* ============================== PAYMENT SUCCESS ============================== */
const PaymentSuccessfull = (props) => {
  const { showPaymentSuccessfull } = props;

  const navigate = useNavigate();

  const [showLoader, setShowLoader] = useState(false);

  const brandDetails = useBrandDetails();

  const continueToDashboard = () => {
    setShowLoader(true);

    setTimeout(() => {
      navigate('/verifyinvestor');
      setShowLoader(false);
    }, 2000);
  };

  return (
    <Modal
      show={showPaymentSuccessfull}
      onHide={() => !showPaymentSuccessfull}
      centered
      size="md"
      backdrop="static"
      aria-labelledby="contained-modal-title-vcenter"
      dialogClassName="cs-md-modal payment-success">
      {showLoader ? (
        <div className="cs-text-center">
          {brandDetails?.is_use_load_image ? (
            <Image
              src={(brandDetails && brandDetails.small_logo_s3_url) || CapFavicon}
              className="w-25 spin-animation"
            />
          ) : (
            <CommonSpinner size={45} classParent="cs-primary" />
          )}
        </div>
      ) : (
        <React.Fragment>
          <Modal.Header>
            <Modal.Title className="cs-semi-bold-h3">Payment Successful!</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="success-head">
              <span>
                <Image src={successValidation} alt="success Validation" width={100} height={100} />
              </span>
              <p className="cs-light-body-text-m cs-neutral-90">
                Congratulations! You are on your way to becoming a Verified Investor. You'll receive
                notice of verification in 1-2 business days. In the case of a successful
                verification.
              </p>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              className="cs-btn-primary xl-btn cs-regular-h5"
              onClick={() => continueToDashboard()}>
              Continue to dashboard
            </Button>
          </Modal.Footer>
        </React.Fragment>
      )}
    </Modal>
  );
};

// PROPS TYPE
PaymentSuccessfull.propTypes = {
  showPaymentSuccessfull: PropTypes.bool
};

export default PaymentSuccessfull;
