import React from 'react';
import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import QuickBooksLayout from '../pages/QuickBooksLayout';

const ProtectedQuickBooksRoutes = () => {
  const { isAuthenticated } = useSelector((state) => state.auth);
  return isAuthenticated ? <QuickBooksLayout /> : <Navigate to="/" />;
};

export default ProtectedQuickBooksRoutes;
