import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';

/* ============================== AMORTIZATION CHECKBOX ============================== */
const AmortizationCheckbox = ({ isChecked, setIsChecked }) => {
  return (
    <Form className="cs-form-group amortization-schedule-box">
      <Form.Check
        id="amortizationAdd"
        type="checkbox"
        aria-label="amortization"
        checked={isChecked}
        onChange={(e) => {
          setIsChecked(!isChecked);
        }}
        className="cs-form-check-box"
        label={
          <span htmlFor="amortizationAdd" className="cursor-pointer cs-regular-h5 cs-primary">
            Create Amortization Schedule
          </span>
        }
      />
    </Form>
  );
};

// PROPS TYPE
AmortizationCheckbox.propTypes = {
  isChecked: PropTypes.bool,
  setIsChecked: PropTypes.func
};

export default AmortizationCheckbox;
