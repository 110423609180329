import React from 'react';
import Skeleton from 'react-loading-skeleton';
import { Table } from 'react-bootstrap';
import PropTypes from 'prop-types';

/* ============================== SKELETON SETTINGS TABLE ============================== */
const SkeletonSettingsTable = ({ tableData }) => {
  return (
    <React.Fragment>
      <div className="skeleton-table for-des">
        <Table className="cs-table skeleton-table">
          <thead className="cs-thead">
            <tr>
              {Array(6)
                .fill(0)
                .map((item, index) => (
                  <th className="default-medium-sub-heading-m" key={index}>
                    <Skeleton width={200} />
                  </th>
                ))}
            </tr>
          </thead>
          <tbody className="cs-tbody default-regular-sub-heading-m">
            {tableData.map((category, index) => {
              return (
                <tr key={index}>
                  <td>
                    <Skeleton width={200} />
                  </td>
                  <td>
                    <Skeleton width={200} />
                  </td>
                  <td>
                    <Skeleton width={150} />
                  </td>
                  <td>
                    <Skeleton width={150} />
                  </td>
                  <td>
                    <Skeleton width={150} />
                  </td>
                  <td>
                    <Skeleton width={150} />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
      <div className="skeleton-table for-mob">
        <Table className="cs-table skeleton-table">
          <thead className="cs-thead">
            <tr>
              {Array(6)
                .fill(0)
                .map((item, index) => (
                  <th className="default-medium-sub-heading-m" key={index}>
                    <Skeleton width={50} />
                  </th>
                ))}
            </tr>
          </thead>
          <tbody className="cs-tbody default-regular-sub-heading-m">
            {tableData.map((category, index) => {
              return (
                <tr key={index}>
                  {Array(6)
                    .fill(0)
                    .map((item, index) => (
                      <td key={index}>
                        <Skeleton width={50} />
                      </td>
                    ))}
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
    </React.Fragment>
  );
};

// PROPS TYPE
SkeletonSettingsTable.propTypes = {
  tableData: PropTypes.array
};

export default SkeletonSettingsTable;
