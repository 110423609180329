/* eslint-disable no-useless-escape */
import React, { useState } from 'react';
import { Col, Row, Form } from 'react-bootstrap';
import { useDropzone } from 'react-dropzone';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

// COMPONENT IMPORTS
import Modal from '../../components/Modal';
import CapsyncToolTip from '../../components/CapsyncToolTip';
import CapsyncIcon from '../../components/CapsyncIcon';
import {
  handleS3Upload,
  // isValidFileName,
  replaceLastOccurrence,
  supportedExtensions
} from '../../utils/common';

// API
import {
  setProgressUploadDetails,
  showUploadProgress,
  useProgressUploadDetails
} from '../../slices/commonSlice';
import { useUser } from '../../slices/authSlice';
import {
  categoriesFileUpload,
  uploadOtherFilesFolder,
  useFilesFoldersData,
  useStorageDetails
} from '../../slices/fileManagementSlice';

/* ============================== FILE UPLOAD ============================== */
const FileUpload = (props) => {
  const {
    showModal,
    parentId,
    selectedFiles,
    setSelectedFiles,
    currentFolderDetails,
    categoryDetails,
    setFileStorageModal,
    setFetchLoading,
    setRemainingFiles,
    setExistingFiles,
    setExistingModal,
    onCancelClick
  } = props;

  const dispatch = useDispatch();

  const progressUploadDetails = useProgressUploadDetails();
  const storageDetails = useStorageDetails();
  const filesData = useFilesFoldersData();
  const user = useUser();

  const [loading, setLoading] = useState(false);
  const [showDeleteSpinner, setShowDeleteSpinner] = useState(false);
  const [duplicateFileError, setDuplicateFileError] = useState(null);

  const validateDuplicateFileName = (newFiles, existingFiles) => {
    let totalNewFileSize = 0;
    let totalExistingFileSize = 0;
    let errorDetected = false;

    // Calculate total size of new files
    for (const newFile of newFiles) {
      if (newFile.size === 0) {
        setDuplicateFileError('This file contains no data');
        errorDetected = true;
        break;
      }
      // ---------------------- To allow special characters ---------------------- //

      // if (!isValidFileName(newFile.name)) {
      //   setDuplicateFileError('Some special or ASCII characters are not allowed in file name');
      //   errorDetected = true;
      //   break;
      // }

      // ------------------------------------ //
      totalNewFileSize += newFile.size;

      for (const existingFile of existingFiles) {
        const existingFileNameParts = existingFile.file_name
          ? existingFile.file_name.split('/')
          : existingFile.name.split('/');
        const otherAttachmentText = existingFileNameParts[1];

        if (
          (newFile.name && newFile.name === existingFile.name) ||
          (newFile.attachment && newFile.attachment === existingFile.file_name) ||
          (otherAttachmentText === 'other_attachments' &&
            newFile.name === existingFile.original_file_name)
        ) {
          setDuplicateFileError('A file with this name already exists');
          errorDetected = true;
          break;
        }
      }

      if (errorDetected) {
        break;
      }
    }

    // Calculate total size of existing files
    if (!errorDetected) {
      for (const existingFile of existingFiles) {
        totalExistingFileSize += existingFile.size;
      }
    }

    // Check total file size against the limit
    const totalFileSize = totalNewFileSize + totalExistingFileSize;
    const maxSizeAllowed =
      process.env.REACT_APP_FILE_UPLOAD_SIZE_ALLOWED_FILE_MANAGEMENT || 209715200; // Default to 200MB if env variable is not set
    const maxSizeBytes = parseInt(maxSizeAllowed);

    if (!errorDetected && totalFileSize > maxSizeBytes) {
      const maxSizeMB = maxSizeBytes / (1024 * 1024);
      setDuplicateFileError(`Total file size greater than ${maxSizeMB} MB not allowed`);
      errorDetected = true;
    }

    if (!errorDetected) {
      setDuplicateFileError('');
    }

    return !errorDetected;
  };

  let isFileRejected = false;

  const onDropAccepted = (uploadedFiles) => {
    if (!isFileRejected && validateDuplicateFileName(uploadedFiles, selectedFiles)) {
      setDuplicateFileError(null);
      setSelectedFiles([...uploadedFiles, ...selectedFiles]);
    } else {
      isFileRejected = false;
    }
  };

  const onDropRejected = () => {
    isFileRejected = true;
    setDuplicateFileError('Format not supported');
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept:
      currentFolderDetails.row_id !== null
        ? {
            'image/jpeg': ['.jpeg', '.jpg'],
            'image/png': ['.png'],
            'application/pdf': ['.pdf']
          }
        : {
            'image/*': [...supportedExtensions]
          },
    onDropAccepted,
    onDropRejected,
    disabled: loading || showDeleteSpinner
  });

  const removeFile = (name) => {
    setDuplicateFileError(null);
    setShowDeleteSpinner(true);
    const data = [...selectedFiles];
    const i = data.findIndex((x) => x.name === name);
    data.splice(i, 1);
    setSelectedFiles(data);
    setShowDeleteSpinner(false);
  };

  const collectAllFileNames = (fileArr) => {
    const allFileNames = new Set([
      ...filesData.map((file) => file.name.trim()),
      ...fileArr.map((file) => file.name.trim())
    ]);

    // Include file names from progressUploadDetails
    progressUploadDetails.forEach((detail) => {
      if (detail.parentId === parentId) {
        detail.files.forEach((file) => {
          allFileNames.add(file.trim());
        });
      }
    });

    return allFileNames;
  };

  const handleFileSave = async () => {
    // let header = await headerAddToken();
    const uId = Date.now();
    setDuplicateFileError(null);
    setLoading(true);
    try {
      const emptyFileArr = [];
      const fileNames = [];
      selectedFiles.forEach((file) => {
        emptyFileArr.push(file);
      });

      let totalFileSize = selectedFiles && selectedFiles.reduce((acc, item) => acc + item.size, 0);

      if (currentFolderDetails.check_double_space) {
        totalFileSize = totalFileSize * 2;
      }

      const uploadedData = storageDetails?.used_space;
      const maxLimitData = storageDetails?.total_space;
      const inProgressData = progressUploadDetails.length
        ? progressUploadDetails.reduce((acc, item) => acc + item.size, 0)
        : 0;

      if (uploadedData + totalFileSize + inProgressData > maxLimitData) {
        setFileStorageModal(true);
        setSelectedFiles([]);
        onCancelClick(false);
        setLoading(false);
        return;
      }

      const existingFileNames = new Set(filesData.map((value) => value.name));

      const existingFile = selectedFiles.filter((items) => {
        return existingFileNames.has(items.name);
      });

      const remainingFile = selectedFiles.filter((items) => {
        return !existingFileNames.has(items.name);
      });

      let response;
      if (currentFolderDetails.row_id !== null && currentFolderDetails.category_id !== null) {
        if (existingFile.length > 0) {
          setDuplicateFileError(
            'Please note that there are files already existing. Kindly remove these files.'
          );
          setLoading(false);
          return;
        }
        const formData = new FormData();
        formData.append('user_id', user?.id);
        formData.append('row_id', currentFolderDetails.row_id);
        // formData.append('used_space', storageDetails?.used_space);
        for (let i = 0; i < emptyFileArr.length; i++) {
          const file = emptyFileArr[i];
          formData.append('attachments', file);
          fileNames.push(file.name);
        }
        onCancelClick(false);
        setSelectedFiles([]);
        setLoading(false);
        dispatch(
          setProgressUploadDetails({
            id: uId,
            files: [...fileNames],
            parentId: parentId,
            size: totalFileSize,
            isDelete: false
          })
        );
        const payload = {
          formData: formData,
          categoryId: categoryDetails.id,
          categoryName: categoryDetails.category,
          id: uId,
          files: [...fileNames]
        };
        response = await dispatch(categoriesFileUpload(payload)).unwrap();
        dispatch(setProgressUploadDetails({ id: uId, isDelete: true }));
        if (response && response.code === 200) {
          //   await dispatch(get_user_storage({ id })).unwrap();
          setTimeout(() => {
            dispatch(
              showUploadProgress({
                files: [...fileNames],
                progress: Math.floor(100),
                id: uId,
                isDelete: false
              })
            );
          }, 1000);
          setTimeout(() => {
            toast.success('Your file has been uploaded successfully.');
            dispatch(showUploadProgress({ id: uId, isDelete: true }));
            onCancelClick();
          }, 1500);
          setSelectedFiles([]);
          setLoading(false);
        } else {
          setTimeout(() => {
            dispatch(showUploadProgress({ id: uId, isDelete: true }));
          }, 1000);
          onCancelClick(false);
          setSelectedFiles([]);
          setLoading(false);
          setDuplicateFileError(response.message);
          toast.error(response.message);
        }
      } else {
        if (existingFile.length > 0) {
          onCancelClick(false);
          setExistingFiles(existingFile);
          setRemainingFiles(remainingFile);
          setExistingModal(true);
          onCancelClick(false);
          setSelectedFiles([]);
          setLoading(false);
          return;
        }
        let fileArray = [];
        if (progressUploadDetails.length > 0) {
          const allFileNames = collectAllFileNames(emptyFileArr);
          const existing = [];
          const remaining = [];

          const checkNameIncludes = () => {
            emptyFileArr.forEach((file) => {
              const exists = progressUploadDetails.some(
                (obj) => obj.parentId === parentId && obj.files.includes(file.name)
              );
              if (exists) {
                existing.push(file);
              } else {
                remaining.push(file);
              }
            });
          };

          checkNameIncludes();

          if (existing.length > 0) {
            const newFiles = existing.map((items) => {
              let fileName = items.name.trim();
              const extension = fileName.split(/\.(?=[^\.]+$)/)[1];
              fileName = fileName.split(/\.(?=[^\.]+$)/)[0];

              const match = fileName.match(/ \((\d+)\)(?!.* \(\d+\))/);
              let newStr;

              if (match) {
                const number = parseInt(match[1]);
                let newCount = number + 1;
                do {
                  newStr = replaceLastOccurrence(fileName, `(${number})`, `(${newCount})`);
                  newCount++;
                } while (allFileNames.has(`${newStr}.${extension}`));
              } else {
                newStr = `${fileName} (1)`;
                if (allFileNames.has(`${newStr}.${extension}`)) {
                  const match = newStr.match(/ \((\d+)\)(?!.* \(\d+\))/);
                  let number = parseInt(match[1]);
                  let newCount = number + 1;
                  do {
                    newStr = replaceLastOccurrence(newStr, `(${number})`, `(${newCount})`);
                    number++;
                    newCount++;
                  } while (allFileNames.has(`${newStr}.${extension}`));
                }
              }

              newStr = `${newStr}.${extension}`;

              allFileNames.add(newStr);

              return new File([items], newStr, {
                type: items.type,
                lastModified: items.lastModified
              });
            });

            fileArray = [...newFiles, ...remaining];

            for (let i = 0; i < [...newFiles, ...remaining].length; i++) {
              const file = [...newFiles, ...remaining][i];
              fileNames.push(file.name);
            }
          } else {
            for (let i = 0; i < emptyFileArr.length; i++) {
              const file = emptyFileArr[i];
              fileNames.push(file.name);
            }

            fileArray = [...emptyFileArr];
          }
        } else {
          for (let i = 0; i < emptyFileArr.length; i++) {
            const file = emptyFileArr[i];
            fileNames.push(file.name);
          }

          fileArray = [...emptyFileArr];
        }

        dispatch(
          setProgressUploadDetails({
            id: uId,
            files: [...fileNames],
            parentId: parentId,
            size: totalFileSize,
            isDelete: false
          })
        );

        onCancelClick(false);
        onCancelClick(false);
        setSelectedFiles([]);
        setLoading(false);

        const { successfulUploads } = await handleS3Upload(fileArray, user?.id, dispatch);
        setFetchLoading(true);
        const data = successfulUploads.map((val) => {
          return {
            name: val.file.name,
            original_file_name: val.file.name,
            size: val.file.size,
            attachment: val.result.Key
          };
        });
        const payload = {
          parent_id: parentId?.toString(),
          data,
          user_id: user?.id?.toString()
        };
        const res = await dispatch(uploadOtherFilesFolder({ payload, endPoint: 'files' })).unwrap();

        dispatch(
          setProgressUploadDetails({
            id: uId,
            isDelete: true
          })
        );

        if (res && res.code === 200) {
          //   await dispatch(get_user_storage({ id })).unwrap();
          onCancelClick();
          toast.success('Your file has been uploaded successfully.');
          setSelectedFiles([]);
          setLoading(false);
        } else {
          onCancelClick(false);
          setFetchLoading(false);
          setLoading(false);
          setDuplicateFileError(res.message);
          toast.error(res.message);
          setSelectedFiles([]);
          setLoading(false);
        }
      }
    } catch (error) {
      console.log('error', error);

      onCancelClick(false);
      setLoading(false);
      setFetchLoading(false);
      setSelectedFiles([]);

      dispatch(
        setProgressUploadDetails({
          id: uId,
          isDelete: true
        })
      );

      setTimeout(() => {
        dispatch(showUploadProgress({ id: uId, isDelete: true }));
      }, 1000);
    }
  };

  const handleOnCancel = () => {
    onCancelClick(false);
    setSelectedFiles([]);
    setDuplicateFileError(null);
  };

  const body = (
    <React.Fragment>
      <Row>
        <Col>
          <Form.Label className="cs-regular-sub-heading-s cs-neutral-100">Upload files</Form.Label>
        </Col>
      </Row>
      <div className="manage-profile-upload">
        <Row>
          <Col>
            <div className="manage-file-container">
              <div
                {...getRootProps({})}
                className={`cs-upload-files cursor-pointer ${
                  (loading || showDeleteSpinner) && 'cs-neutral-60 disabled'
                }`}>
                <input
                  {...getInputProps()}
                  name="attachments"
                  disabled={loading || showDeleteSpinner}
                />
                <span className="cs-primary">
                  <CapsyncIcon title="upload-files-filled" size="28" />
                </span>
                <p className="cs-regular-sub-heading-m">
                  <span>Browse</span>&nbsp;or drag file here
                </p>
                {!!currentFolderDetails.row_id && (
                  <p className="cs-regular-sub-heading-xs">Support PDF, PNG, JPG and JPEG files</p>
                )}
              </div>
              <span className="form-error-msg cs-light-sub-heading-s cs-danger">
                {duplicateFileError}
              </span>
            </div>
          </Col>
        </Row>
        <div className="cs-files">
          <div className="cs-scroller">
            {selectedFiles[0] !== '' &&
              selectedFiles.map((iter, idx) => {
                const isLong = iter.name.length > 25;
                const truncatedEmail = isLong ? `${iter.name.substring(0, 25)}...` : iter.name;
                return (
                  <div className="cs-selected-files" key={idx}>
                    <div className="cs-fileicon cs-neutral-60">
                      <CapsyncToolTip
                        Child={'file-icon-outlined'}
                        placement={'top'}
                        type="icon"
                        message={iter.name.replaceAll('_', ' ')}
                      />
                    </div>
                    <div className="cs-files-names">
                      <span className="download-filename cs-regular-sub-heading-s cs-neutral-100">
                        {truncatedEmail}
                      </span>
                      <span className="download-filename cs-light-sub-heading-s cs-last-date cs-neutral-80">
                        {iter.size / 1000} Kb
                      </span>
                    </div>

                    <div className="cs-file-icon icons">
                      <span
                        className="cs-icon-badge-danger cs-danger"
                        onClick={() => removeFile(iter.name)}>
                        <CapsyncIcon title="delete-outlined" size="18" />
                      </span>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    </React.Fragment>
  );

  return (
    <Modal
      title="Upload files"
      cancelButtonLabel="Cancel"
      saveButtonLabel="Save"
      show={showModal}
      isCloseButton={false}
      className="cs-md-modal manage-files file-common-modal"
      body={body}
      handleClose={() => handleOnCancel()}
      handleOnCancel={() => handleOnCancel()}
      handleOnSave={() => handleFileSave()}
      modalFooterClass="cs-modal-btn modal-footer"
      disabled={selectedFiles.length === 0 || loading}
    />
  );
};

// PROPS TYPE
FileUpload.propTypes = {
  showModal: PropTypes.bool,
  onCancelClick: PropTypes.func,
  parentId: PropTypes.number,
  selectedFiles: PropTypes.array,
  setSelectedFiles: PropTypes.any,
  setFetchLoading: PropTypes.any,
  setFileStorageModal: PropTypes.any,
  setExistingFiles: PropTypes.any,
  setRemainingFiles: PropTypes.any,
  currentFolderDetails: PropTypes.object,
  categoryDetails: PropTypes.object,
  setExistingModal: PropTypes.any
};

export default FileUpload;
