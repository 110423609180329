import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Table } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

// COMPONENT IMPORTS
import CapsyncIcon from '../../components/CapsyncIcon';
import CapsyncToolTip from '../../components/CapsyncToolTip';

// API
import { setCategoryDetails } from '../../slices/categorySlice';

/* ============================== DATA TABLE ============================== */
const DataTable = ({ row, col, label, total, footerLabel }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  return (
    <div className="summary-table-container">
      <Table className="cs-table">
        <thead className="cs-thead">
          <tr>
            {col?.map((items) => {
              return (
                <React.Fragment key={items.name}>
                  <th className="cs-medium-sub-heading-l" width={items.width}>
                    {items.name}
                    <span>&nbsp;</span>
                    {items.name === 'Date Updated' && (
                      <CapsyncToolTip
                        Child={'info-outlined'}
                        placement={'top'}
                        message={'This date may not reflect manual accounts.'}
                        type="icon"
                        size="20"
                      />
                    )}
                  </th>
                </React.Fragment>
              );
            })}
          </tr>
        </thead>
        <tbody className="cs-tbody cs-neutral-90">
          {row?.map((items, index) => {
            return (
              <React.Fragment key={index}>
                <tr
                  className="cursor-pointer"
                  onClick={() => {
                    navigate(items?.path);
                    dispatch(setCategoryDetails(items));
                  }}>
                  <td>
                    <div className="per-month-gap">{items?.category_name}</div>
                  </td>
                  <td>
                    <div className="dollar-value-gap">
                      <span>$&nbsp;</span>
                      <span className="dash-bal-padding">
                        {items?.value && typeof items.value === 'number'
                          ? items?.value?.toLocaleString()
                          : '0.00'}
                      </span>
                    </div>
                  </td>
                  <td>
                    <span>
                      {items?.date_updated ? moment(items?.date_updated).format('MM/DD/YYYY') : '-'}
                    </span>
                  </td>
                  <td className="go-next-page-div">
                    <CapsyncIcon title="chevron-right-outlined" size="16" />
                  </td>
                </tr>
              </React.Fragment>
            );
          })}
          {/* tfoot start */}
          <tr
            className={`${label && (label == 'Assets' ? ' cs-tfoot-assets' : 'cs-tfoot-liability')}  cs-medium-sub-heading-l`}>
            <td> {footerLabel} </td>
            <td>
              <div className="dollar-value-gap">
                <span>$&nbsp;</span>
                <span className="dash-bal-padding">{total?.toLocaleString()}</span>
              </div>
            </td>
            <td></td>
            <td></td>
          </tr>
          {/* tfoot end */}
        </tbody>
      </Table>
    </div>
  );
};

// PROPS TYPE
DataTable.propTypes = {
  row: PropTypes.array.isRequired,
  col: PropTypes.array.isRequired,
  label: PropTypes.any,
  total: PropTypes.any,
  footerLabel: PropTypes.string
};

export default DataTable;
