import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { socket } from '../../../config/Socket';
import { quickBooksDataAdd } from '../../../slices/quickbookSlice';

const QuickBooksFetchData = () => {
  const dispatch = useDispatch();
  const { user, authUser } = useSelector((state) => state.auth);
  const { accesstoken, realmid, refresh_token } = useParams();

  const [qboMessage, setQboMessage] = useState(
    'Please wait while we fetch your data from QuickBooks.'
  );

  const logUserRole = authUser && authUser.user_role ? authUser.user_role : authUser;
  const id = user && user.id;
  // if (accessUser && accessUser.accessUserID !== null) {
  //   id = user && user.id;
  // } else {
  //   id = user && user.id;
  // }

  useEffect(() => {
    quickBooksData();
  }, []);

  const quickBooksData = () => {
    dispatch(
      quickBooksDataAdd({
        user_id: id,
        realmid: realmid,
        accesstoken: accesstoken,
        refresh_token: refresh_token,
        business_id: Number(window.localStorage.getItem('business_id'))
      })
    )
      .unwrap()
      .then((response) => {
        setQboMessage('Please wait while we fetch your data from QuickBooks.');
        window.localStorage.setItem(
          'quickbooks_redirect',
          window.localStorage.getItem('business_id')
        );
        socket.emit('quickbooks', {
          business_id: window.localStorage.getItem('business_id'),
          user_id: id,
          logUserRole
        });
        window.localStorage.removeItem('business_id');
        setTimeout(() => {
          window.close();
        }, 1000);
      })
      .catch((err) => {
        setQboMessage('Something went wrong.');
        window.localStorage.setItem(
          'quickbooks_redirect',
          window.localStorage.getItem('business_id')
        );
        socket.emit('quickbooks', {
          business_id: window.localStorage.getItem('business_id'),
          user_id: id,
          logUserRole
        });
        window.localStorage.removeItem('business_id');
        window.close();
      });
  };

  return <div>{qboMessage}</div>;
};

export default QuickBooksFetchData;
