const VALIDATION_MESSAGE = {
  WHITESPACE: 'Whitespace is not allowed',
  NAME_REQUIRED: 'Enter name',
  FIRST_NAME_REQUIRED: 'Enter first name',
  LAST_NAME_REQUIRED: 'Enter last name',
  EMAIL_REQUIRED: 'Enter email address',
  EMAIL_WITHOUT_SPACE: 'Email cannot contain spaces',
  PHONE_REQUIRED: 'Enter phone number',
  PASSWORD_REQUIRED: 'Enter password',
  INCORRECT_PASSWORD: 'Incorrect password',
  VALID_PASSWORD: 'Enter a valid password',
  CONFIRM_PASSWORD_REQUIRED: 'Confirm password',
  COMPANY_NAME_REQUIRED: 'Please enter company name',
  CRD_NUMBER_REQUIRED: 'Enter CRD number',
  NO_SPACE: 'No spaces allowed',
  INVALID_EMAIL: 'Enter a valid email address',
  NEW_PASSWORD_MATCH: 'New password cannot be same as old password.',
  USE_SYMBOL: 'Use a symbol',
  USE_NUMBER: 'Use a number',
  USER_LOWERCASE: 'Use a lowercase letter',
  USER_UPPERCASE: 'Use an uppercase letter',
  INVALID_CITY: 'Enter a valid city',
  REQUIRED_TWO_CHARACTERS: 'Required at least 2 characters',
  REQUIRED_EIGHT_OR_MORE_CHARACTERS: 'Use 8 or more characters',
  REQUIRED_TWENTY_CHARACTERS: 'Not allowed more than 20 characters',
  REQUIRED_FORTY_CHARACTERS: 'Maximum 40 characters allowed',
  PASSWORD_NOT_MORE_TWENTY_CHARACTERS: 'Password cannot exceed 20 characters',
  EMAIL_NOT_UPPERCASE: 'Email address should not contain capital letters',
  REQUIRED_THIRTY_CHARACTERS_BEFORE_AT_SIGN: 'Cannot accept more than 30 characters before @',
  REQUIRED_ONE_CHARACTERS_BEFORE_AT_SIGN: 'Enter at least 1 character before @',
  PASSWORD_NOT_MATCH: 'Passwords do not match',
  FILE_ONLY_IMAGE_ALLOWED: 'Only JPEG, JPG, or PNG format accepted.',
  FILE_ONLY_SINGLE_IMAGE_ALLOWED: 'Only single image upload allowed.',
  FILE_SIZE_LIMIT_EXCEED: 'File greater than 5 MB not accepted.',
  INVALID_PHONE_NUMBER: 'Enter a valid phone number',
  REQUIRED_MAX_TEN_CHARACTERS: 'Phone number should be 10 digits',
  REQUIRED_THIRTY_CHARACTERS: 'Not allowed more than 30 characters',
  STREET_NAME_REQUIRED: 'Enter street address',
  INVALID_STREET: 'Enter a valid address',
  MUST_HAVE_STREET_NUMBER: 'Address must have street number',
  INVALID_ROUTE: 'Enter a valid address',
  MUST_HAVE_ROUTE_DETAILS: 'Address must have route details',
  CITY_REQUIRED: 'Enter city',
  SPECIAL_CHARACTERS_AND_NUMBER_NOT_ALLOWED: 'Special characters and numbers are not allowed',
  ZIP_CODE_REQUIRED: 'Enter ZIP code',
  INVALID_ZIP_CODE: 'Enter a valid ZIP code',
  ONLY_DIGITS: 'Must be only digits',
  MUST_EXACTLY_FIVE_DIGITS: 'Must be exactly 5 digits',
  DETAILS_REQUIRED: 'Enter details',
  MUST_BE_FIFTY_CHARACTERS: 'Must be at least 50 characters long',
  TWO_THOUSAND_FIFTY_FIVE_CHARACTERS_ONLY: 'Must not exceeded 255 characters.',
  SELECT_ADVISOR: 'Select Registered Advisor',
  WEBSITE_REQUIRED: 'Enter website',
  CORRECT_URL: 'Enter correct url!',
  BUSINESS_TYPE_REQUIRED: 'Select business type',
  EIN_NUMBER_REQUIRED: 'Enter EIN number',
  EIN_NUMBER_FORMAT: 'It should be in "xx-xxxxxxx" format',
  MORE_THEN_NINE_DIGITS: 'Must be more than 9 digits',
  FUTURE_DATE_NOT_ALLOWED: 'Future date can not be accepted',
  NUMBER_OF_CLIENTS_REQUIRED: 'Select number of clients',
  TYPE_OF_CLIENT_REQUIRED: 'Select type of clients',
  INDUSTRY_REQUIRED: 'Select Industry',
  DOMAIN_NAME_REQUIRED: 'Enter domain name',
  CORRECT_DOMAIN: 'Enter correct domain!',
  OTP_IS_REQUIRED: 'Enter code',
  INVALID_OTP: 'Enter a valid code',
  OPT_MIN_LENGTH_SIX: 'Verification code must be 6 characters',
  INTERNAL_SERVER_ERROR: 'Internal server error!',
  LEGAL_NAME_REQUIRED: 'Enter legal name',
  VERIFICATION_TYPE_REQUIRED: 'Please select verification type',
  WELCOME_MSG_NOT_MORE_SIXTY_CHARACTERS: 'Message cannot exceed 60 characters',
  WELCOME_MSG_TEXT_AREA_NOT_MORE_FIVE_HUNDRED_CHARACTERS: 'Message cannot exceed 500 characters',
  WELCOME_MSG_REQUIRED: 'Please enter welcome message',
  VALID_PHONE_NUMBER: 'Please enter a valid phone number',
  CITY_NOT_MATCH: 'City entered does not match the Zip code entered',
  STATE_NOT_MATCH: 'State entered does not match the Zip code entered',
  ATTACHMENT_IS_REQUIRED: 'Attachment is required.',
  AT_LEAST_ONE_ATTACHMENTS_IS_REQUIRED:
    'Select the attached document or upload the required files.',
  FIRST_TERM_REQUIRED:
    'The law governing this type of verification requires the investor to have a reasonable expectation of the same income level in the current year.',
  SECOND_TERM_REQUIRED:
    'The law governing this type of verification requires the investor to have a reasonable expectation of the same income level in the current year.',
  OFFICERS_INVESTMENT_OR_DEAL_IS_REQUIRED:
    'Enter the investment name or deal name of the company of which you are an officer.',
  INVESTOR_TYPE_IS_REQUIRED:
    'Select an option whether you are investing as an individual or a company.',
  INVESTOR_MEET_IS_REQUIRED:
    'Select an option whether you are investing as an individual or a company.'
  // // DATE_REQUIRED: 'Please select updated date'
};

const TOAST_MESSAGE = {
  SIGN_UP_SUCCESS: 'User signed Up successfully',
  USER_ADDED_SUCCESS: 'User added successfully',
  FILE_CHANGE_PASSWORD_SUCCESS: 'Password updated successfully',
  RECORD_UPDATE_SUCCESS: 'Your records have been updated successfully.',
  RECORD_DELETE_SUCCESS: 'Your record has been deleted successfully.',
  FILE_UPLOAD_SUCCESS: 'Your file has been uploaded successfully.',
  FILE_DELETE_SUCCESS: 'Your file has been deleted successfully.',
  FILE_DOWNLOAD_SUCCESS: 'Your file has been downloaded successfully.',
  FILES_DOWNLOAD_SUCCESS: 'Your files have been downloaded successfully.',
  REQUEST_SENT_SUCCESS: 'Request sent successfully',
  EDIT_PROFILE_SUCCESS: 'Your details have been updated successfully.',
  MFA_AUTHENTICATED_SUCCESS: 'MFA authenticated successfully',
  MULTI_FACTOR_AUTHENTICATION_UPDATE_SUCCESS:
    'Your multi-factor authentication method has been updated successfully.',
  LINK_ASSET_SUCCESS: 'You have linked this asset successfully.',
  LINK_LIABILITY_SUCCESS: 'You have linked this liability successfully.',
  UNLINK_ASSET_SUCCESS: 'You have unlinked this asset successfully.',
  UNLINK_LIABILITY_SUCCESS: 'You have unlinked this liability successfully.',
  RESET_PASSWORD_SUCCESS: 'Your password has been updated successfully.',
  USER_INVITE_SUCCESS: 'Invitation Sent successfully.',
  USER_INVITE_FAILED: 'Invitation Unsuccessful',
  SIGNATURE_DOWNLOAD_SUCCESS: 'Signature downloaded successfully',
  RECORD_DELETE_FAILED: 'Error while deleting record',
  SOMETHING_WENT_WRONG: 'Something went wrong',
  PERMISSION_CHANGE_SUCCESS: 'Permission Changed Successfully',
  USER_RESEND_INVITE_SUCCESS: 'Invitation Resend successfully',
  FORGOT_PASSWORD_SUCCESS: 'We have emailed you a password reset link.',
  PROFILE_IMAGE_SUCCESS: 'Your profile image has been updated successfully.'
};

const OTHER_MESSAGES = {
  MANUAL_ENTRY: 'This is a manual entry.'
};
export { VALIDATION_MESSAGE, TOAST_MESSAGE, OTHER_MESSAGES };
