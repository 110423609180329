import React from 'react';
import Skeleton from 'react-loading-skeleton';

// COMPONENT IMPORTS
import SkeletonCategoryTable from './SkeletonCategoryTable';

/* ============================== SKELETON ACCREDITATION ============================== */
const SkeletonAccreditation = () => {
  return (
    <div className="cs-section-topbar">
      <div className="cs-title">
        <h2 className="default-semi-bold-h1 cs-neutral-100">
          <Skeleton width={200} />
        </h2>
      </div>
      <div className="skeleton-accreditation-container for-des">
        <div className="skeleton-tabs-container">
          <h2 className="skeleton-tab-1">
            <Skeleton width={100} />
          </h2>
          <h2 className="skeleton-tab-2">
            <Skeleton width={100} />
          </h2>
          <h2 className="skeleton-tab-2">
            <Skeleton width={100} />
          </h2>
        </div>
        <div>
          <Skeleton width={150} height={30} />
        </div>
      </div>
      <div className="skeleton-manage-container for-mob">
        <div>
          <Skeleton width="100%" height={30} />
        </div>
        <div className="skeleton-tabs-container">
          <h2 className="skeleton-tab-1">
            <Skeleton width={93} />
          </h2>
          <h2 className="skeleton-tab-2">
            <Skeleton width={93} />
          </h2>
          <h2 className="skeleton-tab-2">
            <Skeleton width={93} />
          </h2>
        </div>
      </div>
      <SkeletonCategoryTable tableData={[1, 2, 3]} />
    </div>
  );
};

export default SkeletonAccreditation;
