import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useOutletContext } from 'react-router-dom';
import moment from 'moment';

// COMPONENT IMPORTS
import SkeletonAuditlog from '../../components/Skeleton/SkeletonAuditlog';
import CustomTable from '../../components/CustomTable';
import { stringShorten } from '../../../utils/common';
import AuditLogsTabs from '../../../components/Tabs';

// HOOKS IMPORTS
import useDebounce from '../../../utils/Hooks/useDebounce';

// API
import { getAuditLogs } from '../../../slices/auditLogsSlice';
import { useAccessUser, useUserID } from '../../../slices/authSlice';

/* ============================== AUDIT LOGS PAGE ============================== */
const AuditLogs = () => {
  let totalRecord;
  const dispatch = useDispatch();

  const userId = useUserID();
  const accessUser = useAccessUser();

  const { pathname } = useLocation();
  const isMySettings = pathname?.split('/')?.[1] === 'my-settings';

  const { user } = useSelector((state) => state.auth);
  const { individual, collaborator } = useSelector((state) => state.auditLogs.auditLogs);
  const { individualPagination, collaboratorPagination } = useSelector(
    (state) => state.auditLogs.auditLogs
  );

  const tabName = [
    { name: 'Individual', value: 'individual' },
    { name: 'Professional User', value: 'professional_user' }
  ];

  const [tab, setTab] = useState(tabName[0].value);
  const [search, setSearch] = useState('');
  const [paginationData, setPaginationData] = useState({});

  const isIndividualTab = tab === 'individual';
  const hasAllRecords = paginationData?.limit === 'all';

  const startSearch = useDebounce(() => {
    const payload = {
      userId: isMySettings ? accessUser?.loginUserDetails?.id : userId,
      roleId: isMySettings ? accessUser?.loginUserDetails?.user_role?.id : user && user.role_id,
      ...paginationData,
      search
    };
    dispatch(getAuditLogs(payload));
  }, 1000);

  useEffect(() => {
    startSearch();
  }, [paginationData, search]);

  useEffect(() => {
    setPaginationData({
      page: 1,
      limit: 10
    });
  }, [tab]);

  if (isIndividualTab) {
    totalRecord = hasAllRecords ? individual?.length : individualPagination?.itemCount;
  } else {
    totalRecord = hasAllRecords ? collaborator?.length : collaboratorPagination?.itemCount;
  }

  const COLUMNS = [
    {
      label: 'Date',
      renderCell: (item) => {
        const date = new Date(item.createdAt);
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const year = date.getFullYear();

        return <span>{`${month}/${day}/${year}`}</span>;
      },
      width: 254
    },
    {
      label: 'Time',
      renderCell: (item) => {
        return <span>{moment(item.createdAt).format('HH:mm')}</span>;
      },
      width: 254
    },
    {
      label: 'User Name',
      renderCell: (item) => <span>{item.firstName + ' ' + item.lastName}</span>,
      width: 254
    },
    { label: 'Categories', renderCell: (item) => item.module_name, width: 254 },
    { label: 'Sub Categories', renderCell: (item) => item.sub_module_name, width: 254 },
    { label: 'Events', renderCell: (item) => stringShorten(item.title, 30), width: 254 }
  ];
  const [isLoader] = useOutletContext();

  return (
    <div className="cs-setting-page cs-auditlog-sec">
      {isLoader ? (
        <SkeletonAuditlog />
      ) : (
        <CustomTable
          key={tab}
          columns={COLUMNS}
          totalRecords={totalRecord}
          data={tab === 'individual' ? individual : collaborator}
          isPaginate={true}
          onPaginationValueChange={setPaginationData}
          hasSearchBar={true}
          search={search}
          setSearch={setSearch}
          columnVisible={false}
          CustomHeaderComponent={<AuditLogsTabs tabName={tabName} tab={tab} setTab={setTab} />}
        />
      )}
    </div>
  );
};

export default AuditLogs;
