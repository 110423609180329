import React, { useCallback, useEffect, useState } from 'react';
import { Col, Row, Form } from 'react-bootstrap';
import { useDropzone } from 'react-dropzone';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import moment from 'moment';

// COMPONENT IMPORTS
import Modal from './Modal';
import CapsyncIcon from './CapsyncIcon';
import CapsyncToolTip from './CapsyncToolTip';
// import { isValidFileName } from '../utils/common';
import { TOAST_MESSAGE } from '../constants/message';

// API
import {
  categoryAllFilesDownload,
  categoryFilesDelete,
  categoryFilesDownload,
  categoryFilesGet,
  categoryFilesUpdate,
  useCategoryDetail
} from '../slices/categorySlice';
import { useProgressUploadDetails } from '../slices/commonSlice';
import { getStorageDetails, useStorageDetails } from '../slices/fileManagementSlice';

/* ============================== MANAGE FILE ============================== */
const ManageFile = ({
  userId,
  currentItemId,
  setOpenManageFile,
  setScannedFileDetails,
  setFileStorageModal
}) => {
  const dispatch = useDispatch();

  const categories = useCategoryDetail();
  const progressUploadDetails = useProgressUploadDetails();
  const storageDetails = useStorageDetails();
  const categoryId = categories?.category_id;
  const categoryName = categories?.parent === 'liabilities' ? 'liability' : 'assets';

  const [storeFiles, setStoreFiles] = useState([]);
  const [isLoadFiles, setIsLoadFiles] = useState(false);
  const [duplicateFileError, setDuplicateFileError] = useState('');

  const handleDownloadAllCategoryFiles = () => {
    setIsLoadFiles(true);

    let IdsArray = storeFiles.map((val) => val.id);

    const hasUnScannedFiles = storeFiles.some((val) => val.is_scan_file === 0);
    const blockedFiles = storeFiles.filter((val) => val.is_scan_file === 2);
    const infectedFileNames = blockedFiles.map((file) => file.name).join(', ') || '';

    if (hasUnScannedFiles) {
      toast.info('Scanning process in progress, please try again later.');
      setIsLoadFiles(false);
      return;
    }

    if (blockedFiles.length > 0) {
      toast.error(`${infectedFileNames} These files cannot be downloaded.`);
      IdsArray = IdsArray.filter((id) => !blockedFiles.some((file) => file.id === id));
    }

    if (IdsArray.length === 0) {
      setIsLoadFiles(false);
      return;
    }

    const fileData = {
      media_id: [...IdsArray]
    };

    dispatch(categoryAllFilesDownload(fileData))
      .unwrap()
      .then((response) => {
        if (response.code === 200) {
          downloadZip(response.zipContent);
          toast.success(TOAST_MESSAGE.FILES_DOWNLOAD_SUCCESS);
        } else {
          toast.error('Failed to download files.');
        }
        setIsLoadFiles(false);
      })
      .catch((err) => {
        console.error('Download error:', err);
        toast.error('An error occurred while downloading files.');
        setIsLoadFiles(false);
      });
  };
  const fetchStorageDetails = async () => {
    try {
      const payload = { user_id: userId };
      await dispatch(getStorageDetails(payload)).unwrap();
    } catch (error) {
      console.log('error', error.message);
    }
  };
  // Helper function to download the zip file
  const downloadZip = (zipContent) => {
    const link = document.createElement('a');
    link.href = URL.createObjectURL(zipContent);
    link.setAttribute('download', 'images.zip'); // Set the filename for download
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const onDropAccepted = useCallback(
    (acceptedFiles) => {
      setDuplicateFileError('');
      if (!validateDuplicateFileName(acceptedFiles, storeFiles)) {
        acceptedFiles.length = 0;
        return;
      }

      setIsLoadFiles(true);
      const emptyFileArr = [];
      acceptedFiles.forEach((file) => {
        emptyFileArr.push(file);
      });
      const formData = new FormData();

      const uploadedData = storageDetails?.used_space;
      const inProgressData = progressUploadDetails.length
        ? progressUploadDetails.reduce((acc, item) => acc + item.size, 0)
        : 0;

      formData.append('user_id', userId);
      formData.append('row_id', currentItemId);
      formData.append('usedSpace', inProgressData + uploadedData);
      for (let i = 0; i < emptyFileArr.length; i++) {
        formData.append('attachments', emptyFileArr[i]);
      }
      const newData = {
        formData: formData,
        categoryId: categoryId,
        categoryName: categoryName
        // category: category,
        // main_category: "assets",
      };

      dispatch(categoryFilesUpdate(newData))
        .unwrap()
        .then((response) => {
          if (response.code === 500) {
            acceptedFiles.length = 0;
            setIsLoadFiles(false);
            setFileStorageModal(true);
            setOpenManageFile(false);
          }
          if (response.code === 200) {
            fetchStorageDetails();
            setIsLoadFiles(false);
            acceptedFiles.length = 0;
            handleGetCategoryFiles();
            toast.success(TOAST_MESSAGE.FILE_UPLOAD_SUCCESS);
          }
        })
        .catch((err) => {
          setIsLoadFiles(false);
        });
    },
    [storeFiles]
  );
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    disabled: isLoadFiles,
    onDropAccepted,
    accept: {
      'image/jpeg': ['.jpeg', '.jpg'],
      'image/png': ['.png'],
      'application/pdf': ['.pdf']
    }
  });

  const validateDuplicateFileName = (newFiles, originalFiles) => {
    const maxFileSize = process.env.REACT_APP_FILE_UPLOAD_SIZE_ALLOWED_FILE_MANAGEMENT;
    const supportedExtensions = ['.pdf', '.png', '.jpg', '.jpeg'];
    const originalFileNames = originalFiles.map((file) => file.name);
    let totalNewFileSize = 0;

    for (const newFile of newFiles) {
      // ---------------------- To allow special characters ---------------------- //

      // if (!isValidFileName(newFile.name)) {
      //   setDuplicateFileError('Some special or ASCII characters are not allowed in file name');
      //   return false;
      // }

      // ------------------------------------ //
      if (originalFileNames.includes(newFile.name)) {
        setDuplicateFileError('A file with this name already exists');
        return false;
      }
      // Check for maximum file size
      if (newFile.size && newFile.size > maxFileSize) {
        setDuplicateFileError(
          `This file exceeds maximum file size limit (${maxFileSize / 1048576} MB)`
        );
        return false;
      }
      // check file extension
      const fileExtension = newFile.name.slice(((newFile.name.lastIndexOf('.') - 1) >>> 0) + 2);
      const notAllowedExtensionHandling =
        fileExtension === '' || fileExtension === null || fileExtension === undefined;
      if (
        notAllowedExtensionHandling ||
        !supportedExtensions.includes(`.${fileExtension.toLowerCase()}`)
      ) {
        setDuplicateFileError('Format not supported');
        return false;
      }
      totalNewFileSize += newFile.size;
    }
    if (totalNewFileSize > maxFileSize) {
      setDuplicateFileError(
        `This file exceeds maximum file size limit (${maxFileSize / 1048576} MB)`
      );
      return false;
    }
    const uploadedData = storageDetails?.used_space;
    const maxLimitData = storageDetails?.total_space;
    const inProgressData = progressUploadDetails.length
      ? progressUploadDetails.reduce((acc, item) => acc + item.size, 0)
      : 0;
    if (uploadedData + totalNewFileSize + inProgressData > maxLimitData) {
      setFileStorageModal(true);
      setOpenManageFile(false);
      return;
    }
    setDuplicateFileError('');
    return true;
  };

  const handleGetCategoryFiles = () => {
    const fileData = {
      row_id: currentItemId,
      user_id: userId,
      categoryId: categoryId,
      categoryName: categoryName
    };
    dispatch(categoryFilesGet(fileData))
      .unwrap()
      .then((response) => {
        const storeDetails = Array.isArray(response?.data) ? response?.data : [];
        setStoreFiles(storeDetails);
      })
      .catch((err) => {});
  };
  useEffect(() => {
    handleGetCategoryFiles();
  }, []);

  const bodyElement = () => {
    const showStarNumber = (params) => {
      let newContent = '';
      newContent += params;
      if (newContent.length > 20) {
        return newContent.substring(0, 20) + '...';
      } else {
        return newContent;
      }
    };

    const handleDownloadCategoryFiles = (item) => {
      if (item.is_scan_file === 0) {
        toast.info('Scanning process in progress. Please try again later.');
        return;
      } else if (item.is_scan_file === 2 || item.is_scan_file === 3) {
        setScannedFileDetails({
          modalOpen: true,
          isBigFile: item.is_scan_file === 3,
          details: [item],
          isCategory: true
        });
        setOpenManageFile(false);
        return;
      } else {
        const fileData = {
          media_id: [item.id]
        };
        setIsLoadFiles(true);
        dispatch(categoryFilesDownload(fileData))
          .unwrap()
          .then((response) => {
            if (response.code === 200) {
              const fileUrl = response.fileUrl;
              downloadFile(fileUrl);
              setIsLoadFiles(false);
              toast.success(TOAST_MESSAGE.FILE_DOWNLOAD_SUCCESS);
            }
          })
          .catch((err) => {
            setIsLoadFiles(false);
          });
      }
    };

    // Helper function to download the file
    const downloadFile = (fileUrl) => {
      const link = document.createElement('a');
      link.href = fileUrl;
      link.setAttribute('download', ''); // Optional: Specify a filename
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    };

    const handleDeleteCategoryFile = (id) => {
      setIsLoadFiles(true);
      dispatch(
        categoryFilesDelete({
          media_id: id,
          user_id: userId
        })
      )
        .unwrap()
        .then((response) => {
          if (response.code === 200) {
            setIsLoadFiles(false);
            setStoreFiles((prevFiles) => prevFiles.filter((file) => file.id !== id));
            toast.success(TOAST_MESSAGE.FILE_DELETE_SUCCESS);
          }
        })
        .catch((err) => {
          setIsLoadFiles(false);
        });
    };

    return (
      <React.Fragment>
        <Row>
          <Col>
            <Form.Label className="cs-regular-sub-heading-xs cs-neutral-100">
              Upload supportive documents
            </Form.Label>
          </Col>
        </Row>
        <div className="manage-profile-upload">
          <Row>
            <Col>
              <div className="manage-file-container">
                <div
                  {...getRootProps({})}
                  className={`cs-upload-container cursor-pointer ${isLoadFiles && 'cs-neutral-60 disabled'}`}>
                  <input {...getInputProps()} name="attachments" />
                  <span className="cs-primary">
                    <CapsyncIcon title="upload-files-filled" size="28" />
                  </span>
                  <p className="cs-regular-sub-heading-m">
                    <span>Browse</span>&nbsp;or drag file here
                  </p>
                  <p className="cs-regular-sub-heading-xs">Support PDF, PNG, JPG and JPEG files</p>
                </div>
                <span className="form-error-msg cs-light-body-text-s cs-danger">
                  {duplicateFileError}
                </span>
              </div>
            </Col>
          </Row>
          <div className="cs-files">
            <div className="cs-scroller">
              {acceptedFiles.map((file, index) => {
                return (
                  <div className="cs-selected-files" key={index}>
                    <div className="cs-fileicon cs-neutral-60">
                      <CapsyncToolTip
                        Child={'file-icon-outlined'}
                        placement={'top'}
                        type="icon"
                        message={showStarNumber(file.path)}
                      />
                    </div>
                    <div className="cs-files-names">
                      <span className="download-filename cs-regular-sub-heading-s cs-neutral-100">
                        {showStarNumber(file.path)}
                      </span>
                      <span className="download-filename cs-light-body-text-m cs-last-date cs-neutral-80">
                        {file.size / 1000} Kb
                      </span>
                    </div>

                    {isLoadFiles === true && (
                      <span className="cs-common-spinner cs-primary">
                        <CapsyncIcon title="loading-outlined" size="22" />
                      </span>
                    )}
                  </div>
                );
              })}
              {storeFiles &&
                storeFiles[0] !== '' &&
                storeFiles.map((iter) => {
                  return (
                    <div className="cs-selected-files" key={iter.id}>
                      <div className="cs-fileicon cs-neutral-60">
                        <CapsyncToolTip
                          Child={'file-icon-outlined'}
                          placement={'top'}
                          type="icon"
                          message={(iter.original_file_name ?? '').replaceAll('_', ' ')}
                        />
                      </div>
                      <div className="cs-files-names">
                        <span className="download-filename cs-regular-sub-heading-s cs-neutral-100">
                          {showStarNumber(iter.original_file_name)}
                        </span>
                        <span className="download-filename cs-light-body-text-m cs-last-date cs-neutral-80">
                          Last updated on {moment(iter.createdAt).format('MM/DD/YYYY')}
                        </span>
                      </div>

                      <div className="cs-file-icon icons">
                        <span
                          className="cs-icon-badge-neutral-20 cs-neutral-80"
                          onClick={() => handleDownloadCategoryFiles(iter)}>
                          <CapsyncIcon title="download-outlined" size="18" />
                        </span>
                        <span
                          className="cs-icon-badge-danger cs-danger"
                          onClick={() => handleDeleteCategoryFile(iter.id)}>
                          <CapsyncIcon title="delete-outlined" size="18" />
                        </span>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  };

  return (
    <Modal
      show={true}
      isCloseButton={false}
      className="cs-md-modal manage-files"
      handleClose={() => console.log('called')}
      title="Manage files"
      body={bodyElement()}
      saveButtonLabel="Download all"
      handleOnSave={handleDownloadAllCategoryFiles}
      cancelButtonLabel="Cancel"
      handleOnCancel={() => setOpenManageFile(false)}
      disabled={(acceptedFiles.length === 0 && storeFiles.length === 0) || isLoadFiles}
    />
  );
};

// PROPS TYPE
ManageFile.propTypes = {
  userId: PropTypes.any,
  currentItemId: PropTypes.any,
  setOpenManageFile: PropTypes.any,
  setScannedFileDetails: PropTypes.any,
  setFileStorageModal: PropTypes.any
};

export default ManageFile;
