import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';

/* ============================== COMMON TABS ============================== */
const CommonTabs = (props) => {
  const { handleTabChange, status } = props;

  return (
    <div className="cs-tabs">
      <Button
        onClick={() => handleTabChange('pending')}
        className={
          status === 'pending'
            ? 'cs-stroke-default-tab cs-stroke-active cs-regular-h5 '
            : 'cs-stroke-default-tab cs-regular-h5 '
        }>
        Pending
      </Button>
      <Button
        onClick={() => handleTabChange('completed')}
        className={
          status === 'completed'
            ? 'cs-stroke-default-tab cs-stroke-active cs-regular-h5'
            : 'cs-stroke-default-tab cs-regular-h5'
        }>
        Completed
      </Button>
      <Button
        onClick={() => handleTabChange('expired')}
        className={
          status === 'expired'
            ? 'cs-stroke-default-tab cs-stroke-active cs-regular-h5'
            : 'cs-stroke-default-tab cs-regular-h5'
        }>
        Expired
      </Button>
    </div>
  );
};

// PROPS TYPE
CommonTabs.propTypes = {
  handleTabChange: PropTypes.func,
  status: PropTypes.string
};

export default CommonTabs;
