import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';

// COMPONENT IMPORTS
import CapsyncIcon from './CapsyncIcon';

/* ============================== SEARCH BAR ============================== */
const Searchbar = ({ placeholder, search, setSearch }) => {
  return (
    <div className="cs-search-bar">
      <Form.Group className="cs-form-group">
        <Form.Control
          type="text"
          placeholder={placeholder}
          value={search}
          onChange={(event) => setSearch(event.target.value)}
          className={search && 'cs-input-field-active'}
        />
        <span className="input-field-icon cs-neutral-70">
          <CapsyncIcon title="search-outlined" size="18" />
        </span>
      </Form.Group>
    </div>
  );
};

// PROPS TYPE
Searchbar.propTypes = {
  placeholder: PropTypes.string.isRequired,
  search: PropTypes.string.isRequired,
  setSearch: PropTypes.func.isRequired
};

export default Searchbar;
