import React from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';

// COMPONENT IMPORTS
import Modal from './Modal';
import { TOAST_MESSAGE } from '../constants/message';
import CapsyncIcon from './CapsyncIcon';

// API
import {
  getAssetsCategory,
  getLiabilitiesCategory,
  unLinkAutomobileInstallmentAccountAuto,
  unLinkRealEstateMortgageOnRealEstate,
  useCategoryDetail
} from '../slices/categorySlice';
import { useUserID } from '../slices/authSlice';

/* ============================== UNLINK MODAL ============================== */
const UnlinkModal = ({
  showUnlinkModal,
  setShowUnlinkModal,
  fieldName,
  categoryRowId,
  updateSubCategory
}) => {
  const dispatch = useDispatch();

  const userId = useUserID();
  const categories = useCategoryDetail();
  const categoryId = categories?.category_id;

  const bodyElement = () => {
    return (
      <div className="delete-modal-content">
        <span className="cs-icon">
          <CapsyncIcon title="error-validation" size="100" />
        </span>
        <p className="cs-regular-sub-heading-s cs-neutral-90">
          {`You are unlinking this liability from the ${fieldName}, do you really
                        want to unlink?`}
        </p>
      </div>
    );
  };
  const handleUnLinkCategory = () => {
    let payload = {
      user_id: userId
    };
    if (categories.parent === 'assets') {
      if (updateSubCategory === 'installmentsauto') {
        payload = {
          ...payload,
          automobile_id: categoryRowId.id
        };
      } else if (updateSubCategory === 'mortgagesonrealestate') {
        payload = {
          ...payload,
          real_estate_id: categoryRowId.id
        };
      }
    } else if (categories.parent === 'liabilities') {
      if (updateSubCategory === 'automobile') {
        payload = {
          ...payload,
          installment_account_auto_id: categoryRowId.id
        };
      } else if (updateSubCategory === 'realEstate') {
        payload = {
          ...payload,
          mortgages_on_real_estate_id: categoryRowId.id
        };
      }
    }

    if (updateSubCategory === 'installmentsauto' || updateSubCategory === 'automobile') {
      dispatch(unLinkAutomobileInstallmentAccountAuto(payload))
        .unwrap()
        .then(async (response) => {
          if (response.code === 200) {
            setShowUnlinkModal(false);
            const payload = { categoryId: categoryId, userId: userId };
            if (categories.parent === 'assets') {
              await dispatch(getAssetsCategory(payload)).unwrap();
              toast.success(TOAST_MESSAGE.UNLINK_ASSET_SUCCESS);
            } else {
              await dispatch(getLiabilitiesCategory(payload)).unwrap();
              toast.success(TOAST_MESSAGE.UNLINK_LIABILITY_SUCCESS);
            }
          }
        })
        .catch((err) => {});
    } else {
      dispatch(unLinkRealEstateMortgageOnRealEstate(payload))
        .unwrap()
        .then(async (response) => {
          if (response.code === 200) {
            setShowUnlinkModal(false);
            const payload = { categoryId: categoryId, userId: userId };
            if (categories.parent === 'assets') {
              await dispatch(getAssetsCategory(payload)).unwrap();
              toast.success(TOAST_MESSAGE.UNLINK_ASSET_SUCCESS);
            } else {
              await dispatch(getLiabilitiesCategory(payload)).unwrap();
              toast.success(TOAST_MESSAGE.UNLINK_LIABILITY_SUCCESS);
            }
          }
        })
        .catch((err) => {});
    }
  };

  return (
    <Modal
      show={showUnlinkModal}
      isCloseButton={false}
      className="cs-md-modal"
      title={`Are you sure?`}
      body={bodyElement()}
      saveButtonLabel=" Yes, Unlink"
      handleOnSave={() => handleUnLinkCategory()}
      cancelButtonLabel="Cancel"
      handleOnCancel={() => setShowUnlinkModal(false)}
      modalFooterClass="cs-center-btn"
    />
  );
};

// PROPS TYPE
UnlinkModal.propTypes = {
  showUnlinkModal: PropTypes.bool,
  setShowUnlinkModal: PropTypes.func,
  fieldName: PropTypes.string,
  categoryRowId: PropTypes.object,
  updateSubCategory: PropTypes.string
};

export default UnlinkModal;
