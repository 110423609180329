import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

// COMPONENT IMPORTS
import { setRemoveMessage } from '../../slices/authSlice';

/* ============================== SERVER ERROR REMOVE ============================== */
const useRemoveServerError = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(setRemoveMessage());
    };
  }, []);
  return {};
};

export default useRemoveServerError;
