import React from 'react';
import PropTypes from 'prop-types';

// COMPONENT IMPORTS
import Modal from '../../../components/Modal';
import CapsyncIcon from '../../../components/CapsyncIcon';

/* ============================== CONFIRM EDIT MODAL ============================== */
const ConfirmEditModal = (props) => {
  const { showModal, onUpdateMarketValue } = props;

  const bodyElement = () => {
    return (
      <div className="delete-modal-content">
        <span className="cs-icon">
          <CapsyncIcon title="error-validation" size="100" />
        </span>
        <p className="cs-regular-sub-heading-s cs-neutral-90">
          Are you sure want to edit the market value? We recommend using the best selling price
          based on present conditions.
        </p>
      </div>
    );
  };

  return (
    <Modal
      show={showModal}
      isCloseButton={false}
      className="cs-md-modal cs-common-modal-overlay"
      title="Confirm Edit"
      body={bodyElement()}
      saveButtonLabel="Keep Standard value"
      handleOnSave={() => onUpdateMarketValue(false)}
      cancelButtonLabel="Update"
      handleOnCancel={() => onUpdateMarketValue(true)}
      modalFooterClass="cs-center-btn"
    />
  );
};

// PROPS TYPE
ConfirmEditModal.propTypes = {
  showModal: PropTypes.bool,
  onUpdateMarketValue: PropTypes.func
};

export default ConfirmEditModal;
