import React, { useState } from 'react';
import { Image as BootstrapImage } from 'react-bootstrap';
import PropTypes from 'prop-types';

// COMPONENT IMPORTS
import CommonSpinner from './CommonSpinner';

/* ============================== IMAGE ============================== */
const Image = (props) => {
  const { src, alt, className, width, classNameMain = '' } = props;
  const [loading, setLoading] = useState(true);
  return (
    <div className={`image-box ${classNameMain}`}>
      <CommonSpinner classParent={`${loading ? 'cs-primary m-auto' : 'd-none'}`} size="24" />
      <BootstrapImage
        width={width || undefined}
        src={src}
        alt={alt}
        className={`${className} ${loading ? 'hidden' : 'fade-in'}`}
        onLoad={() => setLoading(false)}
        draggable={false}
      />
    </div>
  );
};

// PROPS TYPE
Image.propTypes = {
  src: PropTypes.string,
  alt: PropTypes.string.isRequired,
  className: PropTypes.string,
  width: PropTypes.string,
  classNameMain: PropTypes.string
};

export default Image;
