import React from 'react';
import PropTypes from 'prop-types';

// COMPONENT IMPORTS
import CapsyncIcon from '../../../components/CapsyncIcon';

/* ============================== FM BREADCRUMB ============================== */
const FileManagementBreadcrumb = ({ breadcrumbDetails, onClick }) => {
  return (
    <React.Fragment>
      {breadcrumbDetails?.slice(0, 3).map((items, idx, array) => {
        if (array.length - 1 === idx) {
          return false;
        }
        return (
          <React.Fragment key={idx}>
            <span
              onClick={() => onClick(items)}
              key={items}
              title={items.title}
              className="cs-light-sub-heading-s cs-neutral-80 cursor-pointer">
              {items.title}
            </span>
            {idx < 2 && (
              <span className="cs-neutral-70">
                <CapsyncIcon title="chevron-right-outlined" size="12" />
              </span>
            )}
          </React.Fragment>
        );
      })}
      {breadcrumbDetails?.length > 4 && (
        <React.Fragment>
          <span className="cs-light-sub-heading-s cs-neutral-80">...</span>
          <span className="cs-neutral-70">
            <CapsyncIcon title="chevron-right-outlined" size="12" />
          </span>
        </React.Fragment>
      )}
      {breadcrumbDetails?.length > 3 && (
        <React.Fragment>
          <span
            onClick={() => onClick(breadcrumbDetails[breadcrumbDetails.length - 2])}
            className="cs-light-sub-heading-s cs-neutral-80 cursor-pointer"
            title={breadcrumbDetails[breadcrumbDetails.length - 2].title}>
            {breadcrumbDetails[breadcrumbDetails.length - 2].title}
          </span>
          <span className="cs-neutral-70">
            <CapsyncIcon title="chevron-right-outlined" size="12" />
          </span>
        </React.Fragment>
      )}
      <span
        title={breadcrumbDetails[breadcrumbDetails?.length - 1]?.title}
        className="cs-light-sub-heading-s cs-neutral-100">
        {breadcrumbDetails[breadcrumbDetails?.length - 1]?.title}
      </span>
    </React.Fragment>
  );
};

// PROPS TYPE
FileManagementBreadcrumb.propTypes = {
  breadcrumbDetails: PropTypes.array,
  onClick: PropTypes.func
};

export default FileManagementBreadcrumb;
