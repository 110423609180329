import * as yup from 'yup';
import 'yup-phone-lite';

// COMPONENT IMPORTS
import { uniValidation } from '../constants/uniValidation';
import {
  annualRateField,
  aprPercentageField,
  balanceDueField,
  balanceDueFieldManual,
  balanceField,
  beneficiaryField,
  cashValueField,
  collateralField,
  companyNameField,
  creditorNameFieldManual,
  dateField,
  dateLoanField,
  debtorNameField,
  descriptionField,
  emailField,
  institutionNameField,
  loanAmountField,
  loanAmountFieldAsset,
  loanAmountFieldManual,
  loanPeriodField,
  loanTypeField,
  marketValueField,
  ownershipField,
  paymentField,
  phoneField,
  propertyNameField,
  lastNameField,
  firstNameField,
  streetNameField,
  cityField,
  zipCodeField
} from '.';
import { VALIDATION_MESSAGE } from '../constants/message';

const assetCashOnHandValidationSchema = yup.object().shape({
  institution: institutionNameField(),
  balance: balanceField(),
  ownership: ownershipField(),
  date_updated: dateField()
});
const assetSavingAccountValidationSchema = yup.object().shape({
  institution: institutionNameField(),
  balance: balanceField(),
  ownership: ownershipField(),
  date_updated: dateField()
});
const assetRetirementAccountValidationSchema = yup.object().shape({
  institution: institutionNameField(),
  balance: balanceField(),
  loan_on_balance: yup
    .string()
    .test(
      'loan_on_balance',
      'Loan balance must be lower than the account balance',
      (value, context) => {
        if (value === null || value === undefined || value === '') {
          return true;
        }
        return (
          context.parent.balance &&
          context.parent.loan_on_balance &&
          Number(context.parent.balance.replace(/,/g, '')) > Number(value.replace(/,/g, ''))
        );
      }
    ),
  total: yup
    .string()
    .test('non-zero', 'Total Balance must be greater than zero', (value) => Number(value) !== 0),
  date_updated: dateField()
});
const assetNonretirementBrokeragevalidationSchema = yup.object().shape({
  institution: institutionNameField(),
  balance: balanceField(),
  ownership: ownershipField(),
  date_updated: dateField()
});
const assetOtherAssetsValidationSchema = yup.object().shape({
  description: descriptionField(),
  asset_type: yup.string().max(100, 'Maximum 100 characters allowed').required('Enter asset type'),
  market_value: marketValueField(),
  date_updated: dateField()
});

const assetBusinessValidationSchema = yup.object().shape({
  entity_name: yup
    .string()
    .max(100, uniValidation.assets_and_liabilities.entity_name.max)
    .required(uniValidation.assets_and_liabilities.entity_name.require),
  industry: yup.string().max(40, uniValidation.assets_and_liabilities.industry.max),
  balance: marketValueField(),
  ownership: ownershipField(),
  date_updated: dateField()
});
const assetPersonalPropertyValidationSchema = yup.object().shape({
  description: descriptionField(),
  property_name: propertyNameField(),
  market_value: marketValueField()
});
const assetLifeinsuranceValidationSchema = yup.object().shape({
  company: institutionNameField(),
  email: emailField({ isRequired: false }),
  phone_number: phoneField({ isRequired: false }),
  beneficiary: beneficiaryField(),
  cash_value: cashValueField(),
  loan_amount: loanAmountFieldAsset()
});
const assetAccountNotesReceivableValidationSchema = yup.object().shape({
  property_name: debtorNameField(),
  loan_type: yup
    .string()
    .matches(/^\S.*$/, 'Whitespace is not allowed')
    .max(40, uniValidation.assets_and_liabilities.loan_type.max),
  debtor_email: emailField({ isRequired: false }),
  debtor_phonenumber: phoneField({ isRequired: false }),
  loan_amount: loanAmountFieldManual(),
  balance_due: yup
    .string()
    .required(uniValidation.assets_and_liabilities.balance_due.require)
    .test(
      'non-zero',
      uniValidation.assets_and_liabilities.balance_due.nonzero,
      (value) => Number(value.replace(/,/g, '')) !== 0
    )
    .test(
      'Is positive?',
      uniValidation.assets_and_liabilities.balance_due.positive,
      (value) => value && !(Number(value.replace(/,/g, '')) < 0)
    )
    .test('checkDecimal', uniValidation.assets_and_liabilities.balance_due.max, (value) =>
      /^(\d{1,3}(,\d{3})*(\.\d{0,2})?)?$/.test(value)
    ),
  loan_period: loanPeriodField(),
  annual_rate: annualRateField(),
  date_updated: dateField()
});

const liabilityAccountPayableValidationSchema = yup.object().shape({
  creditor_name: yup
    .string()
    .max(40, uniValidation.assets_and_liabilities.creditor_name.max)
    .required(uniValidation.assets_and_liabilities.creditor_name.require),
  creditor_email: emailField({ isRequired: false }),
  apr_percentage: aprPercentageField(),
  minimum_payment: yup
    .string()
    .test('is-valid-decimal', 'Maximum two digits are allowed after the decimal point', (value) => {
      if (value === undefined || value === null) {
        return true;
      }
      return /^(\d{1,3}(,\d{3})*(\.\d{0,2})?)?$/.test(value);
    }),
  balance_due: balanceDueField(),
  date_updated: dateField(),
  creditor_phonenumber: phoneField({ isRequired: false })
});

const liabilityNotesPayableValidationSchema = yup.object().shape({
  creditor_email: emailField({ isRequired: false }),
  creditor_name: creditorNameFieldManual(),
  loan_amount: loanAmountFieldManual(),
  date_loan: dateLoanField(),
  balance_due: balanceDueFieldManual(),
  loan_period: loanPeriodField(),
  annual_rate: annualRateField(),
  payment: paymentField(),
  date_updated: yup.date(),
  creditor_phonenumber: phoneField({ isRequired: false })
});

const liabilityInstallmentAutoValidationSchema = yup.object().shape({
  creditor_email: emailField({ isRequired: false }),
  creditor_phonenumber: phoneField({ isRequired: false }),
  creditor_name: creditorNameFieldManual(),
  loan_amount: loanAmountFieldManual(),
  balance_due: balanceDueFieldManual(),
  loan_period: loanPeriodField(),
  annual_rate: annualRateField(),
  payment: paymentField(),
  date_updated: yup.date(),
  loan_type: loanTypeField()
});

const loanOnLifeinsuranceValidationSchema = yup.object().shape({
  company: companyNameField(),
  email: emailField({ isRequired: false }),
  phone_number: phoneField({ isRequired: false }),
  beneficiary: beneficiaryField(),
  cash_value: cashValueField(),
  loan_amount: loanAmountField()
});

const otherLiabilitiesValidationSchema = yup.object().shape({
  description: descriptionField(),
  balance_due: balanceDueField(),
  date_updated: yup.date().required('Please select updated date')
});

const liabilityUnpaidTexesValidationSchema = yup.object().shape({
  description: descriptionField(),
  balance_due: balanceDueField(),
  date_updated: yup.date().required('Please select updated date')
});

const liabilityRealEstateMortgageValidationSchema = yup.object().shape({
  creditor_name: creditorNameFieldManual(),
  street_name: yup
    .string()
    .required(uniValidation.street_name.require)
    .matches(/(?=.*?\d)/, uniValidation.street_name.valid)
    .matches(/[A-Za-z]{2}/, uniValidation.street_name.valid),
  city: yup.string().required(uniValidation.city.require).min(2, uniValidation.city.valid),
  state: yup
    .string()
    .matches(/^\S(?:.*\S)?$/, 'No spaces allowed')
    .min(2, 'Required at least 2 characters'),
  zip_code: yup
    .string()
    .required(uniValidation.zip_code.require)
    .matches(/^[0-9]+$/, uniValidation.zip_code.valid)
    .min(5, uniValidation.zip_code.valid)
    .max(5, uniValidation.zip_code.valid),
  creditor_email: emailField({ isRequired: false }),
  creditor_phonenumber: phoneField({ isRequired: false }),
  loan_amount: yup
    .string()
    .required(uniValidation.assets_and_liabilities.loan_amount.require)
    .test('non-zero', uniValidation.assets_and_liabilities.loan_amount.greater, (value) => {
      const numericValue = Number(value.replace(/,/g, ''));
      return numericValue !== 0;
    }),
  date_loan: yup.date().required('Please select Loan date'),
  loan_period: loanPeriodField(),
  annual_rate: annualRateField(),
  ownership: ownershipField(),
  payment: paymentField(),
  mortgage_balance: yup.string().when('is_manual', {
    is: (is_manual) => !!is_manual || !is_manual,
    then: (schema) =>
      schema
        .required('Enter mortgage balance')
        .test(
          'Is positive?',
          'Mortgage Balance must be a positive number',
          (value) => value && !(Number(value.replace(/,/g, '')) < 0)
        )
        .test(
          'non-zero',
          'Mortgage balance must be greater than zero',
          (value) => Number(value.replace(/,/g, '')) !== 0
        )
        .test(
          'balanceDue',
          'Mortgage balance must be lower than the loan amount',
          (value, context) =>
            context.parent.loan_amount &&
            value &&
            Number(context.parent.loan_amount.replace(/,/g, '')) >= Number(value.replace(/,/g, ''))
        ),
    otherwise: (schema) => schema
  }),
  date_updated: yup.date(),
  loan_type: loanTypeField(),
  collateral: collateralField()
});

export const EditProfileValidationSchema = yup.object().shape({
  first_name: firstNameField(),
  last_name: lastNameField(),
  email: emailField(),
  phone_no: phoneField(),
  street_name: streetNameField(),
  city: cityField(),
  zip_code: zipCodeField()
});

const fileCurrPassField = () => yup.string().required(VALIDATION_MESSAGE.PASSWORD_REQUIRED);
// .matches(/^\S*$/, VALIDATION_MESSAGE.INCORRECT_PASSWORD)
// .matches(/[0-9]/, VALIDATION_MESSAGE.INCORRECT_PASSWORD)
// .matches(/[a-z]/, VALIDATION_MESSAGE.INCORRECT_PASSWORD)
// .matches(/[A-Z]/, VALIDATION_MESSAGE.INCORRECT_PASSWORD)
// .matches(/^(?=.*(_|[^\w])).+$/,VALIDATION_MESSAGE.INCORRECT_PASSWORD)
// .min(8,VALIDATION_MESSAGE.REQUIRED_EIGHT_OR_MORE_CHARACTERS)
// .max(20, VALIDATION_MESSAGE.PASSWORD_NOT_MORE_TWENTY_CHARACTERS)

const fileNewPassField = () =>
  yup
    .string()
    .required(VALIDATION_MESSAGE.PASSWORD_REQUIRED)
    .matches(/^\S*$/, VALIDATION_MESSAGE.VALID_PASSWORD)
    .matches(/[0-9]/, VALIDATION_MESSAGE.VALID_PASSWORD)
    .matches(/[a-z]/, VALIDATION_MESSAGE.VALID_PASSWORD)
    .matches(/[A-Z]/, VALIDATION_MESSAGE.VALID_PASSWORD)
    .matches(/[^A-Za-z0-9]/, VALIDATION_MESSAGE.VALID_PASSWORD)
    .matches(/^(?=.*(_|[^\w])).+$/, VALIDATION_MESSAGE.VALID_PASSWORD)
    .notOneOf([yup.ref('current_password')], VALIDATION_MESSAGE.NEW_PASSWORD_MATCH)
    .min(8, VALIDATION_MESSAGE.VALID_PASSWORD)
    .max(20, VALIDATION_MESSAGE.VALID_PASSWORD);

const fileConfirmPassField = () =>
  yup
    .string()
    .matches(/^\S*$/, VALIDATION_MESSAGE.VALID_PASSWORD)
    .required(VALIDATION_MESSAGE.CONFIRM_PASSWORD_REQUIRED)
    .oneOf([yup.ref('new_password'), null], VALIDATION_MESSAGE.PASSWORD_NOT_MATCH)
    .min(8, VALIDATION_MESSAGE.VALID_PASSWORD)
    .max(20, VALIDATION_MESSAGE.VALID_PASSWORD);

const addUserValidationSchema = () =>
  yup.object().shape({
    first_name: firstNameField(),
    last_name: lastNameField(),
    email: emailField(),
    permission_type: yup
      .string()
      .oneOf(['view', 'standard'], 'Permission type must be either "view" or "standard"')
      .required('Permission type is required')
  });

const validationObj = {
  assetCashOnHandValidationSchema: assetCashOnHandValidationSchema,
  assetSavingAccountValidationSchema: assetSavingAccountValidationSchema,
  assetRetirementAccountValidationSchema: assetRetirementAccountValidationSchema,
  assetNonretirementBrokeragevalidationSchema: assetNonretirementBrokeragevalidationSchema,
  assetOtherAssetsValidationSchema: assetOtherAssetsValidationSchema,
  assetBusinessValidationSchema: assetBusinessValidationSchema,
  assetPersonalPropertyValidationSchema: assetPersonalPropertyValidationSchema,
  assetLifeinsuranceValidationSchema: assetLifeinsuranceValidationSchema,
  assetAccountNotesReceivableValidationSchema: assetAccountNotesReceivableValidationSchema,
  liabilityAccountPayableValidationSchema,
  liabilityNotesPayableValidationSchema,
  liabilityInstallmentAutoValidationSchema,
  liabilityInstallmentOtherValidationSchema: liabilityNotesPayableValidationSchema,
  loanOnLifeinsuranceValidationSchema,
  otherLiabilitiesValidationSchema,
  liabilityUnpaidTexesValidationSchema,
  liabilityRealEstateMortgageValidationSchema,
  EditProfileValidationSchema,
  fileCurrPassField,
  fileNewPassField,
  fileConfirmPassField,
  addUserValidationSchema
};

export default validationObj;
