import * as CryptoJS from 'crypto-js';
import secureLocalStorage from 'react-secure-storage';
import Cookies from 'universal-cookie';

const getValidAddress = (placesData) => {
  const address = { line1: '', line2: '', city: '', state: '', country: '', postal_code: '' };
  const isValid = {
    street_number: false,
    city: false
  };
  placesData.forEach((component) => {
    if (component.types.includes('street_number')) {
      address['line1'] = component.short_name;
      isValid.street_number = true;
    }
    if (component.types.includes('route')) {
      address['line1'] += ' ' + component.short_name;
    }
    if (component.types.includes('subpremise')) {
      address['line2'] = component.short_name;
    }
    if (component.types.includes('locality')) {
      address['city'] = component.short_name;
      isValid.city = true;
    } else if (component.types.includes('sublocality') && !address['city']) {
      address['city'] = component.short_name;
      isValid.city = true;
    }
    if (component.types.includes('administrative_area_level_1')) {
      address['state'] = component.long_name;
    }
    if (component.types.includes('administrative_area_level_1')) {
      address['state_code'] = component.short_name;
    }
    if (component.types.includes('country')) {
      address['country'] = component.short_name;
    }
    if (component.types.includes('postal_code')) {
      address['postal_code'] = component.short_name;
    }
  });
  if (!isValid.city) {
    return {
      error: 'Address must have city.',
      address
    };
  } else if (!isValid.street_number) {
    return {
      error: 'Address must have street number.',
      address
    };
  }
  return address;
};

const createDropdownArray = (str) => {
  const array = [];
  str.map((item) => {
    item && array.push({ value: item.replace(/ /g, '_').toLowerCase(), label: item });
  });
  return array;
};

const createDropdownObject = (str) => {
  return (
    str && {
      value: str.replace(/\s+/g, '_').toLowerCase(),
      label: str.charAt(0).toUpperCase() + str.slice(1)
    }
  );
};

const compareObject = (objA, ObjB) => {
  return JSON.stringify(objA) === JSON.stringify(ObjB);
};

const encrypt = (text) => {
  return CryptoJS.AES.encrypt(text, process.env.REACT_APP_SECRET_KEY).toString();
};

const decrypt = (encryptedBase64) => {
  const decrypted = CryptoJS.AES.decrypt(encryptedBase64, process.env.REACT_APP_SECRET_KEY);
  if (decrypted) {
    try {
      const str = decrypted.toString(CryptoJS.enc.Utf8);
      if (str.length > 0) {
        return str;
      } else {
        return 'error 1';
      }
    } catch (e) {
      return 'error 2';
    }
  }
  return 'error 3';
};

export const deviceVerifyCookieStore = (id, deviceVerify) => {
  const cookies = new Cookies();
  if (deviceVerify) {
    cookies.set(`wlpdeviceAuth24Hr-${id}`, `${deviceVerify}`, {
      path: '/',
      expires: new Date(Date.now() + 86400000 * 30)
    });
  }
  return true;
};

const localObjectClear = () => {
  const userData = secureLocalStorage.getItem('token');
  if (userData) {
    const tempPass = localStorage.getItem('tempPass');
    localStorage.clear();
    localStorage.setItem('tempPass', tempPass);
  }
};

export {
  getValidAddress,
  createDropdownObject,
  createDropdownArray,
  compareObject,
  encrypt,
  decrypt,
  localObjectClear
};
