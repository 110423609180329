import React, { useEffect, useState } from 'react';
import { Badge, Button, Image, Offcanvas, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';

// COMPONENT IMPORTS
import CapsyncIcon from '../../../components/CapsyncIcon';
import { TOAST_MESSAGE } from '../../../constants/message';
import CapsyncToolTip from '../../../components/CapsyncToolTip';
import { getInitialFullName, showToastAndReturnStatus } from '../../../utils/common';

// API
import {
  updateOrRemoveCollaborator,
  resendInviteVerification
} from '../../../slices/collaborationSlice';
import { useUser } from '../../../slices/authSlice';

const PERMISSION_TYPE = {
  VIEW_ONLY: 'view',
  STANDARD: 'standard'
};

/* ============================== COLLABORATION OFF CANVAS ============================== */
const CollaborationOffCanvas = ({
  activeRowIndex,
  setActiveRowIndex,
  data,
  tab,
  fetchCollaborators,
  handleDeleteButtonClick,
  hasFullCollaborationAccess,
  userRequestStatus
}) => {
  const dispatch = useDispatch();
  const user = useUser();
  const firmID = user?.firm_id.toString();
  const [activeRowData, setActiveRowData] = useState(null);
  const avatarWithInitials =
    activeRowData &&
    activeRowData?.profile_image_url &&
    activeRowData?.profile_image_url.replace(
      /(https:\/\/wl-universal-(local|dev|stage)\.s3\.amazonaws\.com|https:\/\/s3\.amazonaws\.com\/universal-clientportalx\.com)/,
      ''
    );

  useEffect(() => {
    if (activeRowIndex >= 0) {
      setActiveRowData(data[activeRowIndex]);
    }
  }, [activeRowIndex, data]);

  const handleCloseCanvas = () => setActiveRowIndex(-1);

  const handleChangePermission = async (permissionType) => {
    if (activeRowData?.permission_type == permissionType) return;
    const id = activeRowData.id;
    const data = {
      permission_type: permissionType
    };
    const response = await dispatch(updateOrRemoveCollaborator({ data, id })).unwrap();
    if (
      showToastAndReturnStatus({
        response,
        successMessage: TOAST_MESSAGE.PERMISSION_CHANGE_SUCCESS
      })
    ) {
      handleCloseCanvas();
      fetchCollaborators();
    }
  };

  const handleResendInvitation = async () => {
    const response = await dispatch(
      resendInviteVerification({
        email: activeRowData.email,
        is_team_collaboration: true,
        firm_id: firmID
      })
    ).unwrap();
    showToastAndReturnStatus({
      response,
      successMessage: TOAST_MESSAGE.USER_RESEND_INVITE_SUCCESS
    });
  };

  return (
    <Offcanvas
      key={activeRowData?.id}
      show={activeRowIndex >= 0}
      className="cs-offcanvas"
      placement="end"
      onHide={handleCloseCanvas}>
      <Offcanvas.Header>
        <div className="img-container">
          <div className="offcanvas-avatar">
            {activeRowData &&
            activeRowData?.profile_image_url &&
            avatarWithInitials !== '/users/avatar/avatar_6.png' &&
            avatarWithInitials !== null ? (
              <Image
                width="96"
                height="96"
                src={activeRowData?.profile_image_url}
                className="cs-user-avtar cs-round"
                alt=""
              />
            ) : (
              <div className="cs-avatar cs-avatar-text">
                {getInitialFullName(activeRowData?.display_name)}
              </div>
            )}
          </div>

          <span onClick={handleCloseCanvas}>
            <CapsyncIcon title="close-outlined" size="18" />
          </span>
        </div>
        <div className="header-information">
          <div className="name-information">
            <span className="cs-semi-bold-h3">{activeRowData?.display_name} </span>
          </div>
          <div className="designation-information">
            <span className="cs-regular-sub-heading-m cs-neutral-60">{activeRowData?.role}</span>
          </div>
        </div>
      </Offcanvas.Header>
      <div className="mob-scroller">
        <Offcanvas.Body>
          <div className="userInfo">
            <div className="userInfoSection">
              <div className="top-information-container">
                <div className="body-top-information">
                  <div className="cs-medium-sub-heading-s">Email</div>
                  <p className="cs-regular-body-text-l cs-ellipsis">
                    {activeRowData?.email ? (
                      activeRowData?.email?.length > 25 ? (
                        <CapsyncToolTip
                          trigger="click"
                          Child={activeRowData?.email}
                          placement={'top'}
                          message={activeRowData?.email}
                          type="text"
                        />
                      ) : (
                        activeRowData?.email
                      )
                    ) : (
                      '-'
                    )}
                  </p>
                </div>
                <div className="body-top-information">
                  <div className="cs-medium-sub-heading-s">Phone number</div>
                  <p className="cs-regular-body-text-l">{activeRowData?.phone_no || '-'}</p>
                </div>
                <div className="body-top-information">
                  <div className="cs-medium-sub-heading-s">Date added</div>
                  <p className="cs-regular-body-text-l">
                    {activeRowData?.status === '2'
                      ? moment(activeRowData.request_date).format('L')
                      : '-'}
                  </p>
                </div>
                <div className="body-top-information">
                  <div className="cs-medium-sub-heading-s">Status</div>
                  <p className="cs-regular-body-text-l">
                    <Badge
                      className={`cs-badge cs-regular-body-text-s ${activeRowData?.status === '1' ? 'cs-warning' : activeRowData?.status === '2' ? 'cs-success' : activeRowData?.status === '3' ? 'cs-danger' : ''}`}>
                      {userRequestStatus(activeRowData)}
                    </Badge>
                  </p>
                </div>
                {hasFullCollaborationAccess && tab === 0 ? (
                  <div className="body-top-information">
                    <p className="cs-medium-sub-heading-s cs-neutral-60">Change permission</p>

                    <div className="d-flex">
                      <OverlayTrigger
                        key={'top'}
                        placement={'top'}
                        overlay={
                          <Tooltip id={`tooltip-top`} className="cs-tooltip cs-regular-body-text-m">
                            Viewing privilege only, No editing privileges.
                          </Tooltip>
                        }>
                        <div
                          className="cs-radio-btn cursor-pointer"
                          onClick={() => handleChangePermission(PERMISSION_TYPE.VIEW_ONLY)}>
                          <input
                            className="radio-button"
                            type="radio"
                            label="1"
                            name="access_permission"
                            checked={activeRowData?.permission_type === PERMISSION_TYPE.VIEW_ONLY}
                          />
                          <span className="cs-light-body-text-l cs-neutral-100">View Only</span>
                        </div>
                      </OverlayTrigger>

                      <OverlayTrigger
                        key={'top'}
                        placement={'top'}
                        overlay={
                          <Tooltip id={`tooltip-top`} className="cs-tooltip cs-regular-body-text-m">
                            Viewing and limited editing privileges.
                          </Tooltip>
                        }>
                        <div
                          className="cs-radio-btn cursor-pointer"
                          onClick={() => handleChangePermission(PERMISSION_TYPE.STANDARD)}>
                          <input
                            className="radio-button"
                            type="radio"
                            name="access_permission"
                            label="2"
                            checked={activeRowData?.permission_type === PERMISSION_TYPE.STANDARD}
                          />
                          <span className="cs-light-body-text-l cs-neutral-100">Standard</span>
                        </div>
                      </OverlayTrigger>
                    </div>
                  </div>
                ) : (
                  <div className="body-top-information">
                    <p className="cs-medium-sub-heading-s cs-neutral-60">Permission</p>
                    <p className="cs-regular-body-text-l">
                      {activeRowData?.permission_type?.charAt(0).toUpperCase() +
                        activeRowData?.permission_type?.slice(1)}
                    </p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </Offcanvas.Body>
      </div>

      {hasFullCollaborationAccess && (
        <div className="cs-btn-group">
          {activeRowData?.status !== '2' && (
            <Button
              className="cs-btn-primary md-btn cs-regular-h5"
              onClick={handleResendInvitation}>
              Resend invite
            </Button>
          )}
          <Button
            className="cs-btn-danger md-btn cs-regular-h5 "
            onClick={() => handleDeleteButtonClick(activeRowData)}>
            Delete user
          </Button>
        </div>
      )}
    </Offcanvas>
  );
};

// PROPS TYPE
CollaborationOffCanvas.propTypes = {
  activeRowIndex: PropTypes.bool,
  tab: PropTypes.number,
  setActiveRowIndex: PropTypes.func,
  data: PropTypes.array,
  fetchCollaborators: PropTypes.func,
  handleDeleteButtonClick: PropTypes.func,
  hasFullCollaborationAccess: PropTypes.bool,
  userRequestStatus: PropTypes.func
};

export default CollaborationOffCanvas;
