import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';

// COMPONENT IMPORTS
import CapsyncIcon from '../../../components/CapsyncIcon';

// CSS IMPORTS
import '../sharePFSModal.css';

// API
import { signatureDeleteFiles, signatureGetFiles } from '../../../slices/requestSlice';

/* ============================== SHARE PFS EXTERNAL ATTACHMENTS ============================== */
const ExternalAttachments = (props) => {
  const {
    uploadedFiles,
    handleFileChange,
    handleDocumentNameChange,
    documentName,
    handleUploadAttachment,
    inputRef,
    duplicateFileError,
    setUploadedFiles,
    setComments,
    comments,
    documentError,
    selectedFiles
  } = props;
  const { user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  useEffect(() => {
    const fileData = {
      user_id: user && user.id
    };
    dispatch(signatureGetFiles(fileData))
      .unwrap()
      .then((response) => {
        setUploadedFiles(response.data);
      })
      .catch((err) => {});
  }, [user]);

  const handleDeleteFiles = (id) => {
    const updatedFiles = uploadedFiles && uploadedFiles.filter((file) => file.id !== id);
    setUploadedFiles(updatedFiles);
    dispatch(signatureDeleteFiles({ media_id: id, user_id: user && user.id })).unwrap();
  };
  const handleCommentsChange = (e) => {
    const inputValue = e.target.value;
    setComments(inputValue);
  };

  return (
    <section className="pfs-container">
      <Container fluid>
        <Row>
          <Col lg={12}>
            <h2 className="pfs-h2-title cs-regular-h2 cs-neutral-100">Other Attachments</h2>
          </Col>
        </Row>
        <div className="other-attachment-container">
          <Row>
            <Col lg={6} md={6}>
              <div className="attachment-row">
                <Row>
                  <Col lg={5}>
                    <Form.Group className="cs-form-group">
                      <Form.Label>Document Name</Form.Label>
                      <Form.Control
                        type="text"
                        autoComplete="off"
                        placeholder={'Optional'}
                        name="Document Name"
                        maxLength={40}
                        value={documentName}
                        className={documentName ? 'cs-input-field-active' : ''}
                        onChange={handleDocumentNameChange}
                      />
                      {documentError && (
                        <span className="form-error-msg cs-light-body-text-s cs-danger">
                          {documentError}
                        </span>
                      )}
                    </Form.Group>
                  </Col>
                  <Col lg={5}>
                    <Form.Group className="cs-form-group">
                      <Form.Label>Upload Files</Form.Label>
                      <div className="cs-choose-file">
                        <div
                          className="cs-choose-file-box"
                          title={(selectedFiles && selectedFiles[0]?.name) || 'choose file'}>
                          <Form.Control
                            type="file"
                            accept="image/*"
                            ref={inputRef}
                            onChange={handleFileChange}
                            className={selectedFiles.length > 0 ? 'cs-input-field-active' : ''}
                          />
                          <span className="cs-icon cs-neutral-10">
                            <CapsyncIcon title="upload-outlined" size="18" />
                          </span>
                        </div>
                      </div>
                      <span className="form-error-msg cs-light-body-text-s cs-danger">
                        {duplicateFileError}
                      </span>
                    </Form.Group>
                  </Col>
                  <Col lg={2}>
                    <Button
                      type="submit"
                      className="cs-btn-primary md-btn cs-regular-h5"
                      onClick={handleUploadAttachment}
                      disabled={selectedFiles.length === 0 || duplicateFileError !== ''}>
                      Upload
                    </Button>
                  </Col>
                </Row>

                <Row>
                  <Col lg={12}>
                    <div className="attachment-files cs-files">
                      {uploadedFiles &&
                        uploadedFiles.map((itr, i) => {
                          return (
                            <div className="cs-selected-files" key={i}>
                              <div className="cs-fileicon cs-neutral-100">
                                <span className="cs-icon cs-neutral-10">
                                  <CapsyncIcon title="file-icon-outlined" size="18" />
                                </span>
                              </div>
                              <div className="cs-files-names">
                                <span className="download-filename cs-regular-sub-heading-s cs-neutral-100">
                                  {itr.document_name}
                                </span>
                              </div>
                              <div
                                className="cs-file-icon icons"
                                onClick={() => handleDeleteFiles(itr.id)}>
                                <span className="cs-icon-badge-danger cs-danger cursor-pointer">
                                  <CapsyncIcon title="delete-outlined" size="18" />
                                </span>
                              </div>
                            </div>
                          );
                        })}
                    </div>
                    <div className="attachment-files cs-files"></div>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col lg={6} md={6}>
              <div className="attachment-row attachment-comments-box">
                <Form.Group className="cs-form-group">
                  <Form.Label>Comments</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    placeholder="Optional"
                    className={`cs-textarea ${comments ? 'cs-input-field-active' : ''} `}
                    onChange={handleCommentsChange}
                  />
                </Form.Group>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </section>
  );
};

// PROPS TYPE
ExternalAttachments.propTypes = {
  handleFileChange: PropTypes.func,
  handleDocumentNameChange: PropTypes.func,
  uploadedFiles: PropTypes.array,
  documentName: PropTypes.string,
  handleUploadAttachment: PropTypes.func,
  inputRef: PropTypes.object,
  duplicateFileError: PropTypes.string,
  setUploadedFiles: PropTypes.func,
  setComments: PropTypes.func,
  comments: PropTypes.string,
  documentError: PropTypes.string,
  selectedFiles: PropTypes.array
};

export default ExternalAttachments;
