import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Form, Row } from 'react-bootstrap';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import moment from 'moment';

// COMPONENT IMPORTS
import {
  preventNegativeNumbers,
  preventSpace,
  preventSpecialCharacters
} from '../../../utils/common';
import Modal from '../../../components/Modal';
import { TOAST_MESSAGE } from '../../../constants/message';
import InputBox from '../../../components/FormFields/InputBox';
import validationObj from '../../../validations/validationSchema';
import TextAreaBox from '../../../components/FormFields/TextAreaBox';
import { handleOwnershipValue } from '../../../config/handleOwnership';
import DatePickerBox from '../../../components/FormFields/DatePickerBox';
import PhoneInputField from '../../../components/FormFields/PhoneInputField';
import InputNumberField from '../../../components/FormFields/InputNumberField';
import SaveORCancelButtons from '../../../components/Buttons/SaveORCancelButtons';
import CategoryFileUpload from '../../../components/FormFields/CategoryFileUpload';

// API
import {
  categoryAddRecords,
  categoryUpdateRecords,
  getLiabilitiesCategory,
  useCategoryDetail
} from '../../../slices/categorySlice';
import { getLiabilitiesDashboard, getMainDashboard } from '../../../slices/dashboardSlice';
import { useProgressUploadDetails } from '../../../slices/commonSlice';
import { useStorageDetails } from '../../../slices/fileManagementSlice';

/* ============================== ACCOUNT PAYABLE ============================== */
const AccountsPayable = ({ isOpen = false, handleCancel, handleCategoryFormSubmit, record }) => {
  const dispatch = useDispatch();
  const categories = useCategoryDetail();
  const categoryId = categories?.category_id;
  const progressUploadDetails = useProgressUploadDetails();
  const storageDetails = useStorageDetails();
  const { user } = useSelector((state) => state.auth);

  const [loading, setLoading] = useState(false);
  const [initialValues, setInitialValues] = useState({
    user_id: user?.id,
    creditor_name: '',
    creditor_email: '',
    creditor_phonenumber: '',
    apr_percentage: '',
    minimum_payment: '',
    balance_due: '',
    type: '',
    collateral: '',
    date_updated: moment().format(),
    notes: '',
    attachments: []
  });

  useEffect(() => {
    if (record) {
      setInitialValues({
        user_id: record?.user_id,
        creditor_name: record?.creditor_name,
        creditor_email: record?.creditor_email || '',
        apr_percentage: record?.apr_percentage.toLocaleString('en-US'),
        creditor_phonenumber: record?.creditor_phonenumber || '',
        minimum_payment: record?.minimum_payment.toLocaleString('en-US'),
        type: record?.type,
        collateral: record?.collateral,
        balance_due: record?.balance_due && record?.balance_due.toLocaleString('en-US'),
        date_updated: new Date(moment(record?.date_updated).format('MM/DD/YYYY')),
        notes: record?.notes == null ? '' : record?.notes
      });
    }
  }, [record]);

  const {
    values,
    handleSubmit,
    handleChange,
    setFieldValue,
    errors,
    touched,
    setFieldError,
    resetForm,
    submitCount
  } = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: validationObj.liabilityAccountPayableValidationSchema,
    onSubmit: async (values) => {
      const formData = new FormData();
      const uploadedData = storageDetails?.used_space;
      const inProgressData = progressUploadDetails.length
        ? progressUploadDetails.reduce((acc, item) => acc + item.size, 0)
        : 0;

      const { attachments, ...dataObj } = values;
      !record && attachments.forEach((file) => formData.append('attachments', file));
      formData.append('data_obj', JSON.stringify(dataObj));
      formData.append('usedSpace', uploadedData + inProgressData);
      const data = {
        category: 1,
        mainCategory: 'liability',
        formData: formData
      };

      setLoading(true);
      if (!record) {
        dispatch(categoryAddRecords(data))
          .unwrap()
          .then(async (response) => {
            if (response.code === 500) {
              setFieldError('attachments', response?.message);
            }
            if (response.code === 200) {
              handleCategoryFormSubmit();
              dispatch(getMainDashboard(user?.id));
              const payload = { categoryId: categoryId, userId: user?.id };
              categories.parent === 'liabilities' &&
                (await dispatch(getLiabilitiesCategory(payload)).unwrap());
              await dispatch(getLiabilitiesDashboard({ userId: user?.id, orderId: 0 })).unwrap();
            }
          })
          .catch((err) => {})
          .finally(() => {
            setLoading(false);
          });
      } else {
        dispatch(
          categoryUpdateRecords({
            category: 1,
            mainCategory: 'liability',
            rowId: record && record?.id,
            values
          })
        )
          .unwrap()
          .then(async (response) => {
            if (response.code === 200) {
              handleCategoryFormSubmit();
              toast.success(TOAST_MESSAGE.RECORD_UPDATE_SUCCESS);
              const payload = { categoryId: categoryId, userId: user?.id };
              categories.parent === 'liabilities' &&
                (await dispatch(getLiabilitiesCategory(payload)).unwrap());
              await dispatch(getMainDashboard(user?.id)).unwrap();
            }
          })
          .catch((err) => {})
          .finally(() => {
            setLoading(false);
          });
      }
    }
  });

  const body = (
    <Form onSubmit={handleSubmit} encType="multipart/form-data">
      <Row>
        <Col lg={12} sm={12}>
          <InputBox
            label={'Name of Creditor'}
            name={'creditor_name'}
            values={values}
            onChange={handleChange}
            errors={errors}
            touched={touched}
            type={'text'}
            onKeyDown={preventSpecialCharacters}
            disabled={record && record.is_manual === false}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={6}>
          <InputBox
            label={'Email'}
            name={'creditor_email'}
            values={values}
            onChange={handleChange}
            errors={errors}
            touched={touched}
            optional={true}
            type={'email'}
            onKeyDown={preventSpace}
            placeholder="Optional "
          />
        </Col>
        <Col lg={6} md={6}>
          <PhoneInputField
            label={'Phone Number (Optional)'}
            placeholder={'Optional'}
            name={'creditor_phonenumber'}
            values={values}
            setFieldValue={setFieldValue}
            errors={errors}
            touched={touched}
            submitCount={submitCount}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={6}>
          <InputNumberField
            label="APR (%)"
            name="apr_percentage"
            values={values}
            handleChange={handleChange}
            errors={errors}
            touched={touched}
            customActiveClassApr={'percentage-symbol'}
            optional={true}
            maxLength={5}
            onPaste={preventNegativeNumbers}
            onKeyDown={handleOwnershipValue}
          />
        </Col>
        <Col xs={12} md={6}>
          <InputNumberField
            label="Minimum Payment"
            name="minimum_payment"
            values={values}
            errors={errors}
            handleChange={handleChange}
            touched={touched}
            optional={true}
            onPaste={preventNegativeNumbers}
            thousandSeparator={true}
            customActiveClass="text-md-end form-control"
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={6}>
          <InputNumberField
            label="Balance Due"
            name="balance_due"
            values={values}
            errors={errors}
            handleChange={handleChange}
            touched={touched}
            customActiveClass="dollar-symbol text-md-end form-control"
            onPaste={preventNegativeNumbers}
            thousandSeparator={true}
            disabled={record && record.is_manual === false}
          />
        </Col>
        <Col xs={12} md={6}>
          <DatePickerBox
            label={'Date Updated'}
            errors={errors}
            name={'date_updated'}
            values={values}
            setFieldValue={setFieldValue}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={6}>
          <InputBox
            label={'Type'}
            name={'type'}
            values={values}
            onChange={handleChange}
            errors={errors}
            touched={touched}
            type={'text'}
            placeholder="Optional"
            onKeyDown={preventSpecialCharacters}
          />
        </Col>
        <Col xs={12} md={6}>
          <InputBox
            label={'Collateral'}
            name={'collateral'}
            values={values}
            onChange={handleChange}
            errors={errors}
            touched={touched}
            type={'text'}
            placeholder="Optional"
            onKeyDown={preventSpecialCharacters}
          />
        </Col>
      </Row>
      <React.Fragment>
        <Row>
          <Col xs={12} md={12}>
            <TextAreaBox
              handleChange={handleChange}
              name={'notes'}
              label={'Notes'}
              values={values}
              errors={errors}
              onChange={handleChange}
              placeholder={'Optional'}
            />
          </Col>
        </Row>

        {!record && (
          <CategoryFileUpload
            name={'attachments'}
            values={values}
            errors={errors}
            setFieldError={setFieldError}
            setFieldValue={setFieldValue}
          />
        )}

        <SaveORCancelButtons resetForm={resetForm} handleCancel={handleCancel} loading={loading} />
      </React.Fragment>
    </Form>
  );

  return <Modal show={isOpen} title={'Accounts Payable'} body={body} isCloseButton={false} />;
};

// PROPS TYPE
AccountsPayable.propTypes = {
  isOpen: PropTypes.bool,
  handleCancel: PropTypes.func.isRequired,
  handleCategoryFormSubmit: PropTypes.func,
  record: PropTypes.object
};
export default AccountsPayable;
