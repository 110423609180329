import React from 'react';
import PropTypes from 'prop-types';
import { Button, Dropdown } from 'react-bootstrap';

// COMPONENT IMPORTS
import CapsyncIcon from '../../../components/CapsyncIcon';

/* ============================== PAGINATION ============================== */
const StaticPagination = ({
  dropdownDisplay,
  paginationDropdownItems,
  rowStartIdx,
  rowsPerPage,
  dataLength,
  setRowStartIdx,
  setRowsPerPage,
  setDropdownDisplay
}) => {
  const handlePaginationDropdownClick = (dropdownValue) => {
    setDropdownDisplay(dropdownValue);
    setRowStartIdx(0);
    if (dropdownValue === 'All') {
      setRowsPerPage(dataLength);
      return;
    }
    setRowsPerPage(dropdownValue);
  };
  const handleFirstPageClick = () => {
    setRowStartIdx(0);
    // Assuming your rowsPerPage is already set appropriately
  };

  const handleLastPageClick = () => {
    const totalRows = dataLength;
    const lastPageStartIdx = Math.floor((totalRows - 1) / rowsPerPage) * rowsPerPage;
    setRowStartIdx(lastPageStartIdx);
  };

  const currentNumberOfRows = (rowStartIdx, rowsPerPage, lengthOfCompanies) => {
    if (rowStartIdx + rowsPerPage <= lengthOfCompanies) {
      return rowsPerPage;
    } else {
      return lengthOfCompanies - rowStartIdx;
    }
  };

  return (
    <div className="cs-custom-pagination">
      <div className="cs-common-add-dropdown">
        <span className="cs-light-body-text-m cs-neutral-80">Rows per page:</span>
        <Dropdown>
          <Dropdown.Toggle className="cs-light-body-text-m cs-neutral-80">
            {dropdownDisplay}
            <CapsyncIcon title="chevron-down-outlined" size="12" />
          </Dropdown.Toggle>
          <Dropdown.Menu onSelect={(e) => console.log(e)}>
            {paginationDropdownItems.map((noOfRowsPerPage) => {
              return (
                <Dropdown.Item
                  key={noOfRowsPerPage}
                  className={noOfRowsPerPage === dropdownDisplay && 'cs-selected-dropdown'}
                  onClick={() => handlePaginationDropdownClick(noOfRowsPerPage)}
                  value={noOfRowsPerPage}>
                  {noOfRowsPerPage}
                </Dropdown.Item>
              );
            })}
            <Dropdown.Item
              onClick={() => handlePaginationDropdownClick('All')}
              className={'All' === dropdownDisplay && 'cs-selected-dropdown'}>
              All
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
      <div className="cs-light-body-text-m cs-neutral-80">
        <p>
          {rowStartIdx + 1} -{' '}
          {rowStartIdx + currentNumberOfRows(rowStartIdx, rowsPerPage, dataLength)} of {dataLength}
        </p>
      </div>
      <div className="cs-pagination-arrow">
        <Button
          onClick={() => {
            !rowStartIdx === 0 && handleFirstPageClick();
          }}
          className={rowStartIdx === 0 ? 'cs-disabled cs-neutral-50' : 'cs-neutral-100'}>
          <CapsyncIcon title="chevron-left-skip-outlined" size="18" />
        </Button>
        <Button
          onClick={() => {
            !rowStartIdx === 0 && setRowStartIdx(rowStartIdx - rowsPerPage);
          }}
          className={rowStartIdx === 0 ? 'cs-disabled cs-neutral-50' : 'cs-neutral-100'}>
          <CapsyncIcon title="chevron-left-outlined" size="18" />
        </Button>
        <Button
          onClick={() => {
            rowStartIdx + rowsPerPage < dataLength && setRowStartIdx(rowStartIdx + rowsPerPage);
          }}
          className={
            !(rowStartIdx + rowsPerPage < dataLength)
              ? 'cs-disabled cs-neutral-50'
              : 'cs-neutral-100'
          }>
          <CapsyncIcon title="chevron-right-outlined" size="18" />
        </Button>
        <Button
          onClick={() => {
            rowStartIdx + rowsPerPage < dataLength && handleLastPageClick();
          }}
          className={
            !(rowStartIdx + rowsPerPage < dataLength)
              ? 'cs-disabled cs-neutral-50'
              : 'cs-neutral-100'
          }>
          <CapsyncIcon title="chevron-right-skip-outlined" size="18" />
        </Button>
      </div>
    </div>
  );
};

// PROPS TYPE
StaticPagination.propTypes = {
  dropdownDisplay: PropTypes.string,
  paginationDropdownItems: PropTypes.string,
  rowStartIdx: PropTypes.string,
  rowsPerPage: PropTypes.string,
  dataLength: PropTypes.string,
  setRowStartIdx: PropTypes.string,
  setRowsPerPage: PropTypes.func,
  setDropdownDisplay: PropTypes.func
};

export default StaticPagination;
