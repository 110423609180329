import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import fileDownload from 'js-file-download';
import { toast } from 'react-toastify';
import axios from 'axios';

// COMPONENT IMPORTS
import { showDownloadProgress } from './commonSlice';
import { headerToken } from '../utils/common';
import CONFIG from '../constants/config';

export const downloadItem = createAsyncThunk(
  'file-management/trash_item_download',
  async (payload, thunkAPI) => {
    const uId = Date.now();
    try {
      thunkAPI.dispatch(
        showDownloadProgress({
          files: ['Documents.zip'],
          progress: 0,
          id: uId,
          isDelete: false
        })
      );
      const response = await axios({
        url: `${CONFIG.BASE_URL}file/download`,
        method: 'POST',
        data: { ...payload },
        headers: headerToken().headers,
        responseType: 'blob',
        onDownloadProgress: (progressEvent) => {
          const total = progressEvent.progress * 100;
          thunkAPI.dispatch(
            showDownloadProgress({
              files: ['Documents.zip'],
              progress: Math.floor(total),
              id: uId,
              isDelete: false
            })
          );
        }
      });

      if (response) {
        setTimeout(() => {
          thunkAPI.dispatch(
            showDownloadProgress({
              files: ['Documents.zip'],
              progress: 100,
              id: uId,
              isDelete: false
            })
          );
        }, 500);
        setTimeout(() => {
          thunkAPI.dispatch(showDownloadProgress({ id: uId, isDelete: true }));
          toast.success('Your Document has been downloaded successfully');
          fileDownload(response.data, 'Document.zip');
        }, 1000);
        return { success: true, message: 'File download initiated' };
      }
    } catch (error) {
      setTimeout(() => {
        thunkAPI.dispatch(showDownloadProgress({ id: uId, isDelete: true }));
      }, 1000);
      const errorMessage = error.response ? error.response.data.message : error.message;
      return thunkAPI.rejectWithValue({ error: errorMessage });
    }
  }
);

// INITIAL STATE
const initialState = {};

/* ============================== DOWNLOAD SLICE ============================== */
const downloadSlice = createSlice({
  name: 'download',
  initialState,
  reducers: {
    resetDownloadSlice: () => initialState
  }
});
export const { resetDownloadSlice } = downloadSlice.actions;
export default downloadSlice.reducer;
