import React, { useState, useEffect } from 'react';
import NumberFormat from 'react-currency-format';
import { useDispatch } from 'react-redux';
import { Table } from 'react-bootstrap';
import PropTypes from 'prop-types';

// API
import {
  addUpdateContingentData,
  getMainDashboard,
  useMainDashboard
} from '../../slices/dashboardSlice';
import { useUserID } from '../../slices/authSlice';

/* ============================== CONTINGENT LIABILITY ============================== */
const ContingentLiability = ({ isSharePFS = false }) => {
  const dispatch = useDispatch();
  const userId = useUserID();
  const dashBoardData = useMainDashboard();
  const contingentLiabilities = dashBoardData?.contigent_liability;
  const [contingentValues, setContingentValues] = useState({
    coMakerValue: 0,
    judgmentsValue: 0
  });
  const [isValueEntered, setIsValueEntered] = useState({
    coMaker: false,
    judgments: false
  });
  const checkIsValueEntered = (type) => {
    if (type === 'coMaker') {
      setIsValueEntered({ coMaker: true, judgments: false });
    } else {
      setIsValueEntered({ coMaker: false, judgments: true });
    }
  };
  const changeContingentValues = (event, type) => {
    const newContent = { ...contingentValues };
    if (type === 'judgements') {
      newContent.judgmentsValue = event;
      setContingentValues(newContent);
    } else {
      newContent.coMakerValue = event;
      setContingentValues(newContent);
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter' || event.key === 'Escape') {
      updateContingentData();
    }
  };
  const handleBlur = (e) => {
    updateContingentData();
  };

  const updateContingentData = async () => {
    const payload = {
      id: contingentLiabilities === null ? 0 : contingentLiabilities?.id,
      user_id: userId,
      co_maker_balance: contingentValues.coMakerValue,
      judgments_balance: contingentValues.judgmentsValue
    };
    const response = await dispatch(addUpdateContingentData(payload)).unwrap();
    if (response.code === 200) {
      await dispatch(getMainDashboard(userId)).unwrap();
      setIsValueEntered({ coMaker: false, judgments: false });
    }
  };
  useEffect(() => {
    const newContent = { ...contingentValues };
    if (contingentLiabilities !== null) {
      newContent.judgmentsValue = contingentLiabilities?.judgments_balance;
      newContent.coMakerValue = contingentLiabilities?.co_maker_balance;
      setContingentValues(newContent);
    }
  }, [contingentLiabilities]);
  const handleCheckValueEntered = (value) => {
    if (isSharePFS === false) {
      checkIsValueEntered(value);
    }
  };
  return (
    <div className="finance-contingent-data">
      <div className="contingent-finance-data">
        <div className="heading">
          <span className="cs-semi-bold-h3 cs-neutral-100">Contingent Liabilities</span>
          <span className="cs-light-body-text-m cs-neutral-70">
            Values not inclusive in net worth
          </span>
        </div>
        <Table>
          <thead className="finance-thead contingent-finance-thead">
            <tr>
              <th width="486" className="cs-medium-sub-heading-l">
                Category
              </th>
              <th className="cs-medium-sub-heading-l">Balance</th>
            </tr>
          </thead>
          <tbody className="contingent-tbody cs-light-body-text-l cs-neutral-90">
            <tr>
              <td width="486">As Endorser or Co-Maker</td>
              <td width="180">
                <div className="dollar-value-gap cursor-pointer">
                  <span>$&nbsp;</span>
                  <div onClick={() => handleCheckValueEntered('coMaker')}>
                    {isValueEntered.coMaker === true ? (
                      <NumberFormat
                        className="text-end dash-input-contingency"
                        placeholder=""
                        thousandSeparator={true}
                        onChange={(e) =>
                          changeContingentValues(e.target.value.replace(/,/g, ''), 'maker')
                        }
                        onKeyDown={handleKeyDown}
                        onBlur={handleBlur}
                        value={contingentValues.coMakerValue}
                        maxLength={15}
                      />
                    ) : (
                      <NumberFormat
                        value={Math.round(
                          contingentValues && contingentValues.coMakerValue
                        ).toFixed(0)}
                        displayType={'text'}
                        className="text-end dash-input-contingency"
                        thousandSeparator={true}
                      />
                    )}
                  </div>
                </div>
              </td>
              <td className="go-next-page-div" width="50"></td>
            </tr>
            <tr>
              <td width="486"> Legal Claims & Judgments</td>
              <td width="180">
                <div className="dollar-value-gap cursor-pointer">
                  <span>$&nbsp;</span>
                  <div onClick={() => handleCheckValueEntered('judgments')}>
                    {isValueEntered.judgments === true ? (
                      <NumberFormat
                        placeholder=""
                        className="text-end dash-input-contingency "
                        thousandSeparator={true}
                        name="judgments_balance"
                        onChange={(e) =>
                          changeContingentValues(e.target.value.replace(/,/g, ''), 'judgements')
                        }
                        onKeyDown={handleKeyDown}
                        onBlur={handleBlur}
                        value={contingentValues.judgmentsValue}
                        maxLength={15}
                      />
                    ) : (
                      <NumberFormat
                        value={Math.round(
                          contingentValues && contingentValues.judgmentsValue
                        ).toFixed(0)}
                        displayType={'text'}
                        className="text-end dash-input-contingency"
                        thousandSeparator={true}
                      />
                    )}
                  </div>
                </div>
              </td>
              <td className="go-next-page-div" width="50"></td>
            </tr>
          </tbody>
        </Table>
      </div>
    </div>
  );
};

// PROPS TYPE
ContingentLiability.propTypes = {
  isSharePFS: PropTypes.bool
};

export default ContingentLiability;
