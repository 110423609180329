import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

// COMPONENT IMPORTS
import {
  SIDE_BAR_ITEMS_STANDARD,
  SIDE_BAR_ITEMS_STANDARD_IND_TO_IND,
  SIDE_BAR_ITEMS_VIEW_ONLY
} from '../../../constants/sidebar';
import SideBarItem from './SideBarItem';
import Image from '../../../components/Image';
import SideBarDropdown from './SideBarDropdown';
import PlaidAccountsList from './PlaidAccountsList';
import CapsyncIcon from '../../../components/CapsyncIcon';
import CapsyncLogo from '../../../assets/images/brand/capsync-logo.svg';
import CapFavicon from '../../../assets/images/brand/cap-icon-logo.svg';

// API
import { toggleSidebar } from '../../../slices/sidebarSlice';
import { useBrandDetails } from '../../../slices/brandingSlice';
import { pendingSignatureList } from '../../../slices/requestSlice';
import { useAccessUser, useToUserPermission } from '../../../slices/authSlice';

/* ============================== SIDEBAR ============================== */
const SideBar = ({ onAccountOpenModelOpen }) => {
  const dispatch = useDispatch();

  const accessUser = useAccessUser();
  const brandDetails = useBrandDetails();
  const userPermission = useToUserPermission();

  const { user } = useSelector((state) => state.auth);
  const isSidebarOpen = useSelector((state) => state.sidebar.isSidebarOpen);

  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    if (991 >= width) dispatch(toggleSidebar(false));
    else dispatch(toggleSidebar(true));
  }, [width]);

  useEffect(() => {
    handleSignatureRequest();

    const handleResize = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleToggleSidebar = () => dispatch(toggleSidebar());
  const handleLogoClick = () => {
    if (991 >= width) dispatch(toggleSidebar(false));
  };

  const handleSignatureRequest = async () => {
    const data = {
      user_id: user && user.id
    };
    await dispatch(pendingSignatureList(data)).unwrap();
  };

  return (
    <div className={`cs-sidebar-main-widget ${isSidebarOpen ? 'sidebarOpen' : 'sidebarClose'}`}>
      <div className="cs-mobile-toggle">
        <span
          className="close-outlined cs-menu-toggle cs-icon"
          id="cs-mobile-toggle"
          onClick={handleToggleSidebar}>
          <CapsyncIcon title="close-outlined" size="18" />
        </span>
      </div>
      <div className="cs-dashboard-logo text-center">
        <Link id="capsync-logo" to={'/dashboard'} onClick={handleLogoClick}>
          <Image
            width="132"
            src={(brandDetails && brandDetails.primary_logo_s3_url) || CapsyncLogo}
            alt="brand-logo"
            className="capsync-logo cs-full-logo"
            classNameMain="cs-full-logo"
          />
          <Image
            width="40"
            src={(brandDetails && brandDetails.small_logo_s3_url) || CapFavicon}
            className="capsync-logo cs-icon-logo"
            alt="brand-logo-small"
            classNameMain="cs-icon-logo"
          />
        </Link>
      </div>

      <nav className="cs-sidebar-nav nav">
        {accessUser?.roleName === 'Individual' && userPermission?.permission_type === 'standard'
          ? SIDE_BAR_ITEMS_STANDARD_IND_TO_IND.map((item) => {
              if (item.subItems) {
                return <SideBarDropdown key={item.key} item={item} />;
              } else {
                return <SideBarItem key={item.key} item={item} />;
              }
            })
          : (accessUser?.roleName === 'Individual' &&
                userPermission?.permission_type === 'standard') ||
              !accessUser ||
              accessUser?.roleName === 'Master Admin Financial Advisor'
            ? SIDE_BAR_ITEMS_STANDARD.map((item) => {
                if (item.subItems) {
                  return <SideBarDropdown key={item.key} item={item} />;
                } else {
                  return <SideBarItem key={item.key} item={item} />;
                }
              })
            : SIDE_BAR_ITEMS_VIEW_ONLY.map((item) => {
                if (item.subItems) {
                  return <SideBarDropdown key={item.key} item={item} />;
                } else {
                  return <SideBarItem key={item.key} item={item} />;
                }
              })}
      </nav>
      {userPermission?.permission_type !== 'view' && (
        <div className="cs-sidebar-bottom">
          <PlaidAccountsList
            isSidebarOpen={isSidebarOpen}
            handleToggleSidebar={handleToggleSidebar}
          />
          <Button
            onClick={() => {
              if (window.innerWidth < 992) handleToggleSidebar();
              onAccountOpenModelOpen();
            }}
            type="submit"
            className="cs-btn-icon-primary lg-btn cs-regular-h5">
            <span className="add-filled cs-neutral-10 icon cs-icon">
              <CapsyncIcon title="add-filled" size="18" />
            </span>
            <span className="cs-nav-text">Add Account</span>
          </Button>
        </div>
      )}
    </div>
  );
};

// PROPS TYPE
SideBar.propTypes = {
  onAccountOpenModelOpen: PropTypes.func
};

export default SideBar;
