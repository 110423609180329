import React from 'react';
import Skeleton from 'react-loading-skeleton';
import { Col, Row } from 'react-bootstrap';

// COMPONENT IMPORTS
import PieChartSkeleton from './PieChartSkeleton';
import LineChartSkeleton from './LineChartSkeleton';
import SkeletonSettingsTable from './SkeletonSettingsTable';

// CSS IMPORTS
import '../../components/Skeleton/skeleton.css';

/* ============================== SKELETON STOKES PORTFOLIO ============================== */
const SkeletonStocksPortfolio = () => {
  return (
    <React.Fragment>
      <div className="cs-section-topbar">
        <div className="amortization-skeleton-breadcrumb">
          <div className="skeleton-breadcrumb-1">
            {' '}
            <Skeleton width={50} />
          </div>
          <div className="skeleton-breadcrumb-2">
            {' '}
            <Skeleton width={20} />
          </div>
          <div className="skeleton-breadcrumb-3">
            <Skeleton width={150} />
          </div>
          <div className="skeleton-breadcrumb-4">
            {' '}
            <Skeleton width={20} />
          </div>
          <div className="skeleton-breadcrumb-5">
            <Skeleton width={40} />
          </div>
        </div>
        <div className="cs-title">
          <h1 className="default-semi-bold-h1 cs-neutral-100">
            <Skeleton width={200} />
          </h1>
        </div>
      </div>
      <div className="for-des">
        <div className="chart-container">
          <Row>
            <Col lg={4}>
              <div className="pie-chart-container skeleton-pie-graph">
                <PieChartSkeleton />
              </div>
            </Col>
            <Col lg={8}>
              <div className="line-chart-container skeleton-line-graph">
                <LineChartSkeleton />
              </div>
            </Col>
          </Row>
        </div>
      </div>
      <div className="for-mob line-chart-skeleton">
        <div className="chart-container">
          <Row>
            <Col lg={6}>
              <div className="pie-chart-container skeleton-pie-graph">
                <PieChartSkeleton />
              </div>
            </Col>
          </Row>
        </div>
      </div>
      <Row>
        <Col>
          <div className="for-des">
            <div className="category-manage-row">
              <div className="cs-common-add-dropdown">
                <Skeleton width={200} height={40} />
              </div>
            </div>
          </div>
          <div className="for-mob">
            <div className="category-manage-row">
              <div className="cs-common-add-dropdown skeleton-mob-drag">
                <Skeleton width={40} height={40} />
              </div>
            </div>
          </div>
        </Col>
      </Row>
      <SkeletonSettingsTable tableData={[1, 2, 3, 4, 5, 6]} />
    </React.Fragment>
  );
};

export default SkeletonStocksPortfolio;
