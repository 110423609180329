import React from 'react';
import PropTypes from 'prop-types';

/* ============================== TABS ============================== */
const Tabs = ({ tabName, tab, setTab }) => {
  return (
    <div className="cs-tabs">
      {tabName.map((tabName, id) => {
        return (
          <button
            key={id}
            type="button"
            onClick={() => setTab(tabName.value)}
            className={`cs-stroke-default-tab cs-regular-h5 btn btn-primary${tab === tabName.value ? ' cs-stroke-active' : ''}`}>
            {tabName.name}
          </button>
        );
      })}
    </div>
  );
};

// PROPS TYPE
Tabs.propTypes = {
  tabName: PropTypes.array,
  tab: PropTypes.string,
  setTab: PropTypes.func
};

export default Tabs;
