import React, { useState, useEffect } from 'react';
import { Col, Form, Row, Image } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import NumberFormat from 'react-currency-format';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import moment from 'moment';
import * as yup from 'yup';

// COMPONENT IMPORTS
import {
  cityField,
  marketValueField,
  ownershipField,
  streetNameField,
  zipCodeField
} from '../../../validations';
import Modal from '../../../components/Modal';
import ConfirmEditModal from './ConfirmEditModal';
import CapsyncIcon from '../../../components/CapsyncIcon';
import { TOAST_MESSAGE } from '../../../constants/message';
import AddressSearch from '../../../components/AddressSearch';
import TextAreaBox from '../../../components/FormFields/TextAreaBox';
import { handleOwnershipValue } from '../../../config/handleOwnership';
import DatePickerBox from '../../../components/FormFields/DatePickerBox';
import AtomDataIconOne from '../../../assets/images/other/atom-icon.svg';
import AtomDataIconTwo from '../../../assets/images/other/atom-icon-2.svg';
import InputNumberField from '../../../components/FormFields/InputNumberField';
import { preventNegativeNumbers, setAddressString } from '../../../utils/common';
import SaveORCancelButtons from '../../../components/Buttons/SaveORCancelButtons';
import CategoryFileUpload from '../../../components/FormFields/CategoryFileUpload';

// HOOKS IMPORTS
import useDebounce from '../../../utils/Hooks/useDebounce';
import useShareValue from '../../../utils/Hooks/useShareValue';

// API
import {
  categoryAddRecords,
  categoryUpdateRecords,
  fetchRealEstatePropertyPrice,
  getAssetsCategory,
  useCategoryDetail
} from '../../../slices/categorySlice';
import { getAssetsDashboard, getMainDashboard } from '../../../slices/dashboardSlice';
import { useProgressUploadDetails } from '../../../slices/commonSlice';
import { useStorageDetails } from '../../../slices/fileManagementSlice';

/* ============================== REAL E STATE ============================== */
const RealEstate = (props) => {
  const {
    linkAddressObj = null,
    isOpen = false,
    handleCancel,
    handleCategoryFormSubmit,
    record
  } = props;
  const dispatch = useDispatch();

  const { user } = useSelector((state) => state.auth);
  const progressUploadDetails = useProgressUploadDetails();
  const storageDetails = useStorageDetails();
  const categories = useCategoryDetail();
  const categoryId = categories?.category_id;

  //   const [loading, setLoading] = useState(true);
  const [isOpenModal, setIsOpenModal] = useState(isOpen);
  const [isAttomDataChecked, setIsAttomDataChecked] = useState(true);
  const [isAttomDataLoad, setIsAttomDataLoad] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [attomChange, setAttomChange] = useState(false);
  const [showConfirmEditModal, setShowConfirmEditModal] = useState(false);
  const [addressSelected, setAddressSelected] = useState(false);
  const [attomRealEstateData, setAttomRealEstateData] = useState({
    start_date: null,
    api_details: null,
    avmhistory: null
  });
  const [addressObj, setAddressObj] = useState({
    line1: '',
    line2: '',
    city: '',
    state: '',
    country: '',
    postal_code: ''
  });

  const [isManualAddress, setIsManualAddress] = useState(false); // Is Manual address
  const [isAddressChange, setIsAddressChange] = useState(false);
  const [loading, setLoading] = useState(false);
  const [initialValues, setInitialValues] = useState({
    user_id: user?.id,
    address: '',
    market_value: '',
    share_market_value: '',
    ownership: '',
    annual_income: '',
    purchase_date: moment().format('MM/DD/YYYY'),
    date_updated: moment().format('MM/DD/YYYY'),
    street_name: '',
    zip_code: '',
    city: '',
    notes: '',
    attachments: []
  });
  useEffect(() => {
    try {
      if (record) {
        const attomDataValue = !record?.is_manual ? record?.api_value : record?.market_value;
        setInitialValues({
          user_id: record?.user_id,
          address: record?.address && setAddressString(JSON.parse(record?.address)),
          ownership: record?.ownership && record?.ownership.toLocaleString('en-US'),
          share_market_value:
            record?.share_market_value && record?.share_market_value.toLocaleString('en-US'),
          market_value: attomDataValue,
          annual_income: record?.annual_income,
          notes: record?.notes,
          date_updated: new Date(moment(record?.date_updated).format('MM/DD/YYYY')),
          purchase_date: record?.purchase_date
            ? new Date(moment(record?.purchase_date).format('MM/DD/YYYY'))
            : null,
          city: record?.address && JSON.parse(record?.address).city,
          zip_code: record?.address && JSON.parse(record?.address).postal_code,
          street_name: record?.address && JSON.parse(record?.address).line1
        });
        setAddressObj(JSON.parse(record.address));
        // record.address && setAddress(setAddressString(record.address));
        setIsAttomDataChecked(!record?.is_manual);
        setAddressSelected(true);
        setIsManualAddress(record?.is_manual_address);

        // setAddressSelected(!record.is_manual);
      }
    } catch (error) {
      console.log(error);
    }
  }, [record]);
  useEffect(() => {
    if (linkAddressObj && Object.keys(linkAddressObj).length > 0) {
      //   setAddress(setAddressString(linkAddressObj));
      // setFieldValue('address', setAddressString(linkAddressObj));
      // realEstatePropertyPrice(linkAddressObj);
      setAddressObj(JSON.parse(linkAddressObj));
      setFieldValue('address', setAddressString(JSON.parse(linkAddressObj)));
      setFieldValue('city', JSON.parse(linkAddressObj).city);
      setFieldValue('state', JSON.parse(linkAddressObj).state);
      setFieldValue('street_name', JSON.parse(linkAddressObj).line1);
      setFieldValue('zip_code', JSON.parse(linkAddressObj).postal_code);
      realEstatePropertyPrice(JSON.parse(linkAddressObj));
    }
  }, [linkAddressObj]);

  useEffect(() => {
    if (isAttomDataChecked && attomChange) {
      realEstatePropertyPrice(addressObj);
      setAttomChange(false);
    }
  }, [isAttomDataChecked]);

  useEffect(() => {
    if (
      isAddressChange &&
      addressObj.line1 !== '' &&
      addressObj.state !== '' &&
      addressObj.country !== ''
    ) {
      realEstatePropertyPrice(addressObj);
      setAddressSelected(true);
      setAttomChange(false);
    }
  }, [addressObj]);

  const asset_realEstate_validationSchema = yup.object().shape({
    market_value: isAttomDataChecked === false && marketValueField(),
    ownership: ownershipField(),
    annual_income: yup
      .string()
      .optional()
      .test('checkDecimal', 'Market Value must be greater than zero', (value) => {
        return value && value.indexOf('.') > -1
          ? /^(\d{1,3}(,\d{3})*(\.\d{0,2})?)?$/.test(value)
          : true;
      }),
    purchase_date: yup.date().required('Please select purchase date'),
    date_updated: yup.date().required('Please select updated date'),
    street_name: streetNameField(),
    city: cityField(),
    state: yup
      .string()
      .matches(/^\S(?:.*\S)?$/, 'No spaces allowed')
      .min(2, 'Required at least 2 characters'),
    zip_code: zipCodeField()
  });
  const realEstatePropertyPrice = useDebounce(async (addressObjData) => {
    setIsAttomDataLoad(true);
    dispatch(
      fetchRealEstatePropertyPrice({
        line1: addressObjData.line1,
        city: addressObjData.city,
        state_code: addressObjData.state_code
      })
    )
      .unwrap()
      .then((response) => {
        if (response.code === 200) {
          setIsAttomDataLoad(false);
          setIsAttomDataChecked(true);
          if (response.data.market_value) {
            setErrorMessage('');
            setFieldValue('market_value', response.data.market_value);
            response.data.api_details.property[0].sale.saleTransDate &&
              setFieldValue(
                'purchase_date',
                moment(response.data.api_details.property[0].sale.saleTransDate).format(
                  'MM/DD/YYYY'
                )
              );
            setAttomRealEstateData(response.data);
            // getAttomMarketValue(response.data.property[0].identifier.Id);
          } else {
            setIsAttomDataLoad(false);
            setIsAttomDataChecked(false);
            setFieldValue('market_value', '');
            setFieldValue('ownership', '');
            setErrorMessage(
              'Attom Databases cannot determine a market value for this address. Please enter a manual value.'
            );
          }
        } else if (response.code === 500) {
          setFieldValue('market_value', '');
          setFieldValue('ownership', '');
          setIsAttomDataLoad(false);
          setIsAttomDataChecked(false);
          setErrorMessage(
            'Attom Databases cannot determine a market value for this address. Please enter a manual value.'
          );
        } else {
          setIsAttomDataChecked(false);
        }
      })
      .catch((err) => {
        // setLoading(false);
      });
  }, 500);

  const {
    values,
    handleSubmit,
    setFieldValue,
    status,
    errors,
    touched,
    setStatus,
    setFieldTouched,
    setFieldError,
    resetForm
  } = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: asset_realEstate_validationSchema,
    onSubmit: async (values) => {
      try {
        if (
          isAddressChange &&
          status &&
          (status.street_name || status.city || status.state || status.zip_code)
        ) {
          return;
        }
        //   setLoading(true);
        // record && (values.id = record.id);
        values.user_id = user?.id;
        values.api_provider = isAttomDataChecked;
        values.api_value = isAttomDataChecked ? values.market_value : 0;
        values.is_manual = isAttomDataChecked ? false : true;
        values.market_value = values.market_value.toString();
        values.address = addressObj;
        values.share_market_value = parseFloat(
          (values.market_value.toString().replace(/,/g, '') * values.ownership) / 100
        ).toString();
        values.is_manual_address = isManualAddress;

        delete values.city;
        delete values.street_name;
        delete values.state;
        delete values.zip_code;
        delete values.country;

        const formData = new FormData();
        const uploadedData = storageDetails?.used_space;
        const inProgressData = progressUploadDetails.length
          ? progressUploadDetails.reduce((acc, item) => acc + item.size, 0)
          : 0;

        const realEstateData = { ...attomRealEstateData, ...values };
        const { attachments } = values;

        formData.append('data_obj', JSON.stringify(realEstateData));
        formData.append('usedSpace', uploadedData + inProgressData);
        !record && attachments.forEach((file) => formData.append('attachments', file));
        const data = {
          category: 17,
          mainCategory: 'assets',
          formData: formData
        };
        setLoading(true);
        if (!record) {
          dispatch(categoryAddRecords(data))
            .unwrap()
            .then(async (response) => {
              if (response.code === 500) {
                setFieldError('attachments', response?.message);
              }
              if (response.code === 200) {
                setIsOpenModal(false);
                handleCategoryFormSubmit();
                dispatch(getMainDashboard(user?.id));
                const payload = { categoryId: categoryId, userId: user?.id };
                categories.parent === 'assets' &&
                  (await dispatch(getAssetsCategory(payload)).unwrap());
                await dispatch(getAssetsDashboard({ userId: user?.id, orderId: 0 })).unwrap();
              }
            })
            .catch((err) => {})
            .finally(() => {
              setLoading(false);
            });
        } else {
          dispatch(
            categoryUpdateRecords({
              category: 17,
              mainCategory: 'assets',
              rowId: record && record?.id,
              values
            })
          )
            .unwrap()
            .then(async (response) => {
              if (response.code === 200) {
                setIsOpenModal(false);
                handleCategoryFormSubmit();
                toast.success(TOAST_MESSAGE.RECORD_UPDATE_SUCCESS);
                const payload = { categoryId: categoryId, userId: user?.id };
                categories.parent === 'assets' &&
                  (await dispatch(getAssetsCategory(payload)).unwrap());
                await dispatch(getMainDashboard(user?.id)).unwrap();
              }
            })
            .catch((err) => {})
            .finally(() => {
              setLoading(false);
            });
        }
      } catch (error) {
        console.log(error);
      }
    }
  });
  useEffect(() => {
    if (isAttomDataLoad || addressObj.line1 === '') {
      setErrorMessage('');
    }
  }, [values, isAttomDataLoad]);

  const handleChange = (e) => {
    // setLoading(false);
    if (e.target.name === 'market_value' || e.target.name === 'street_name') {
      setErrorMessage('');
    }
    // setFieldValue(e.target.name, e.target.value);
    if ((e.target.name === 'city' || e.target.name === 'zip_code') && e.target.value == '') {
      setErrorMessage('');
    }
    if (e.target.name === 'ownership' && e.target.value > 100) {
      setFieldValue(e.target.name, '');
    } else {
      setFieldValue(e.target.name, e.target.value);
    }
    // setDuplicateFileError('');
  };

  const handleCheckChange = () => {
    if (values.street_name && values.zip_code.length === 5 && values.city) {
      //   setLoading(false);
      setAttomChange(true);
      if (record && record.id && !record.is_manual && !isAttomDataChecked === false) {
        setShowConfirmEditModal(true);
      } else {
        setIsAttomDataChecked((prevState) => !prevState);
      }
    }
  };

  const handleUpdateMarketValue = (isUpdate) => {
    // on user update market value in edit
    setShowConfirmEditModal(false);
    setIsAttomDataChecked(!isUpdate);
    if (isUpdate === true) {
      // setFieldValue('address', '');
      // setFieldValue('market_value', '');
      // setFieldValue('ownership', '');
      // setFieldValue('share_market_value', '');
      // setFieldValue('annual_income', '');
      // setFieldValue('purchase_date', '');
      setErrorMessage('');
    }
  };

  // useEffect(() => {
  //   if (isAddressChange === true) {
  //     setLoading(false);
  //   }
  // }, [isAddressChange]);
  useShareValue({ values, setFieldValue, fieldName: 'share_market_value' });

  const body = (
    <React.Fragment>
      <div className="realestate-form">
        <Form onSubmit={handleSubmit} encType="multipart/form-data">
          <Row>
            <Col xs={12} md={12}>
              <div className="cs-realestate-form">
                <AddressSearch
                  errors={errors}
                  touched={touched}
                  values={values}
                  handleChange={handleChange}
                  setFieldValue={setFieldValue}
                  setAddressObj={setAddressObj}
                  record={linkAddressObj ? linkAddressObj : record && record.address}
                  setStatus={setStatus}
                  status={status}
                  setFieldTouched={setFieldTouched}
                  addressObj={addressObj}
                  setIsAddressChange={setIsAddressChange}
                  clearAddressOnUnmount={true}
                  label={'Address'}
                />
              </div>
            </Col>
            <Col xs={12} md={12}>
              <div className="attom-data-lpd">
                <div className="bottom-text-box">
                  <span className="checkboxREalestate">
                    <Form.Check
                      type="checkbox"
                      checked={isAttomDataChecked}
                      onChange={handleCheckChange}
                      className="cs-form-check-box"
                      disabled={!addressSelected}
                      name="attomData"
                    />
                    <div className="d-flex">
                      <p
                        className="attom-mt cursor-pointer cs-neutral-90 cs-regular-body-text-m"
                        onClick={handleCheckChange}>
                        Use Attom Data to get the current market value of your property
                      </p>
                      <Image
                        src={AtomDataIconOne}
                        alt="Attomdata"
                        className="Attom-icon-img cursor-pointer"
                        onClick={handleCheckChange}
                        width={80}
                        height={14}
                      />
                    </div>
                  </span>
                </div>

                <p className="cs-light-body-text-m cs-neutral-70 text-start">
                  By selecting this box, you agree to the Attom Data&nbsp;
                  <Link
                    className="cs-primary-main"
                    target="_blank"
                    to="https://www.attomdata.com/terms-of-use/">
                    Terms of Use.
                  </Link>
                  {errorMessage !== '' && (
                    <span className={`form-error-msg cs-light-body-text-s cs-danger `}>
                      {errorMessage}
                    </span>
                  )}
                </p>
              </div>
            </Col>
            <Col xs={12} md={`${isAttomDataChecked ? 12 : 6}`}>
              <div className={`${isAttomDataChecked ? 'market-value-input' : ''}`}>
                <Form.Group
                  className={`${isAttomDataChecked ? 'cs-disabled' : ''} cs-form-group `}
                  data="Market Value">
                  {!isAttomDataChecked && <Form.Label>Market Value</Form.Label>}
                  <div className={isAttomDataChecked ? 'total-value-input' : 'value-input'}>
                    {isAttomDataChecked ? (
                      <NumberFormat
                        thousandSeparator={true}
                        autoComplete="off"
                        placeholder=""
                        name="market_value"
                        onKeyDown={(e) => {
                          if (e.key === '.' && e.target.value === '') {
                            e.preventDefault(); // Prevent typing another dot if there's already one
                          }
                        }}
                        prefix={'$ '}
                        value={
                          !isAttomDataLoad && values.market_value
                            ? values.market_value.toLocaleString(0)
                            : ''
                        }
                        onChange={handleChange}
                        disabled={isAttomDataChecked}
                        maxLength={14}
                        allowNegative={false}
                        decimalScale={2}
                        className={'dollar-symbol text-md-end form-control cs-input-field-active'}
                      />
                    ) : (
                      <NumberFormat
                        thousandSeparator={true}
                        autoComplete="off"
                        placeholder=""
                        name="market_value"
                        onKeyDown={(e) => {
                          if (e.key === '.' && e.target.value === '') {
                            e.preventDefault(); // Prevent typing another dot if there's already one
                          }
                        }}
                        value={values.market_value ? values.market_value.toLocaleString(0) : ''}
                        onChange={handleChange}
                        disabled={isAttomDataChecked}
                        maxLength={14}
                        allowNegative={false}
                        decimalScale={2}
                        className={
                          values.market_value
                            ? 'dollar-symbol text-md-end form-control cs-input-field-active'
                            : 'text-md-end form-control'
                        }
                      />
                    )}
                    {isAttomDataChecked && isAttomDataLoad && (
                      <span className="cs-common-spinner cs-primary">
                        <CapsyncIcon title="loading-outlined" size="22" />
                      </span>
                    )}
                  </div>

                  {isAttomDataChecked && (
                    <div className="market-value-box cs-light-body-text-s cs-neutral-90">
                      Powered by{' '}
                      <Image
                        src={AtomDataIconTwo}
                        alt="Attomdata Logo"
                        className="attom-data-image"
                        width={16}
                        height={16}
                      />{' '}
                      Attomdata
                    </div>
                  )}

                  {!isAttomDataChecked && errors.market_value && touched.market_value && (
                    <span className="form-error-msg cs-light-body-text-s cs-danger">
                      {errors.market_value}
                    </span>
                  )}
                </Form.Group>
              </div>
            </Col>
            <Col xs={12} md={6}>
              <InputNumberField
                label="Ownership (%)"
                name="ownership"
                values={values}
                errors={errors}
                handleChange={handleChange}
                touched={touched}
                onPaste={preventNegativeNumbers}
                thousandSeparator={true}
                maxLength={3}
                onKeyDown={handleOwnershipValue}
                customActiveClass={'percentage-symbol'}
              />
            </Col>
            <Col xs={12} md={6}>
              <InputNumberField
                label="Share Market Value"
                name="share_market_value"
                values={values}
                errors={errors}
                handleChange={handleChange}
                touched={touched}
                customActiveClass="dollar-symbol text-md-end form-control"
                onPaste={preventNegativeNumbers}
                thousandSeparator={true}
                disabled={true}
              />
            </Col>
            <Col xs={12} md={6}>
              <InputNumberField
                label="Annual Income"
                name="annual_income"
                values={values}
                errors={errors}
                handleChange={handleChange}
                touched={touched}
                customActiveClass="text-md-end form-control"
                onPaste={preventNegativeNumbers}
                thousandSeparator={true}
                placeholder={'Optional'}
              />
            </Col>
            <Col xs={12} md={6}>
              <DatePickerBox
                label={'Purchase Date'}
                errors={errors}
                name={'purchase_date'}
                values={values}
                setFieldValue={setFieldValue}
              />
            </Col>
            <Col xs={12} md={6}>
              <DatePickerBox
                label={'Date Updated'}
                errors={errors}
                name={'date_updated'}
                values={values}
                setFieldValue={setFieldValue}
              />
            </Col>
            <Col xs={12} md={12}>
              <TextAreaBox
                handleChange={handleChange}
                name={'notes'}
                label={'Notes'}
                values={values}
                errors={errors}
                onChange={handleChange}
                placeholder={'Optional'}
              />
            </Col>
            {!record && (
              <CategoryFileUpload
                name={'attachments'}
                values={values}
                errors={errors}
                setFieldError={setFieldError}
                setFieldValue={setFieldValue}
              />
            )}

            <SaveORCancelButtons
              handleCancel={handleCancel}
              resetForm={resetForm}
              loading={loading}
            />
          </Row>
        </Form>
      </div>
      <ConfirmEditModal
        showModal={showConfirmEditModal}
        onUpdateMarketValue={handleUpdateMarketValue}
      />
    </React.Fragment>
  );

  return <Modal show={isOpenModal} isCloseButton={false} title={'Real Estate'} body={body} />;
};

// PROPS TYPE
RealEstate.propTypes = {
  linkAddressObj: PropTypes.object,
  isOpen: PropTypes.bool,
  handleCancel: PropTypes.func,
  handleCategoryFormSubmit: PropTypes.func,
  record: PropTypes.object
};

export default RealEstate;
