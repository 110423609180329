// COMPONENT IMPORTS
import axios from '../config/api';

export const generatePublicTokenLink = async () => {
  try {
    return await axios.get(`plaid/create_link_token`);
  } catch (error) {
    return {
      code: 500,
      message: error.message,
      status: false,
      data: []
    };
  }
};

export const fetchInstituteDetails = async (ins_id) => {
  try {
    return await axios.get(`plaid/fetch_ins_details/${ins_id}`);
  } catch (error) {
    return {
      code: 500,
      message: error.message,
      status: false,
      data: []
    };
  }
};

export const addAccounts = async (data) => {
  try {
    return await axios.post(`plaid/add-accounts`, data);
  } catch (error) {
    return {
      code: 500,
      message: error.message,
      status: false,
      data: []
    };
  }
};

export const fetchAllPlaidAccountsData = async (data) => {
  try {
    return await axios.get(`plaid/get-plaid-accounts/${data}`);
  } catch (error) {
    return {
      code: 500,
      message: error.message,
      status: false,
      data: []
    };
  }
};

export const removePlaidInstitutesData = async (body) => {
  try {
    return await axios.post(`plaid/remove-plaid-institute`, body);
  } catch (error) {
    return {
      code: 500,
      message: error.message,
      status: false,
      data: []
    };
  }
};

export const updateAccountData = async (data) => {
  try {
    return await axios.put(`plaid/update-mode`, data);
  } catch (error) {
    return {
      code: 500,
      message: error.message,
      status: false,
      data: []
    };
  }
};
