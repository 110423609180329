import React from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { Modal, Button } from 'react-bootstrap';
import { useDispatch } from 'react-redux';

// API
import { shareCompletedSignature } from '../../slices/requestSlice';

/* ============================== PFS PREVIEW MODAL ============================== */
const PFSPreviewModal = ({
  showPreviewModal,
  setShowPreviewModal,
  sharePFSModalSchema,
  URL,
  setURL,
  rowData
}) => {
  const dispatch = useDispatch();

  const handleClosePFSPreviewModal = () => {
    setShowPreviewModal(false);
    sharePFSModalSchema.resetForm();
    setURL('');
  };

  const handleShareSubmit = () => {
    dispatch(
      shareCompletedSignature({
        first_name: sharePFSModalSchema?.values.first_name,
        last_name: sharePFSModalSchema?.values.last_name,
        email: sharePFSModalSchema?.values.email,
        signature_id:
          rowData && rowData.signature_users && rowData.signature_users[0].signature_id.toString()
      })
    ).then((res) => {
      if (res.payload.code === 200) {
        toast.success(res.payload.message);
        setShowPreviewModal(false);
        sharePFSModalSchema.resetForm();
        setURL('');
      } else {
        toast.error(res.payload.message);
        setURL('');
      }
    });
  };

  return (
    <Modal
      show={showPreviewModal}
      //   sentData={sentData}
      //   link={linkk}
      centered
      aria-labelledby="contained-modal-title-vcenter"
      className="pfs-pdf-modal">
      <Modal.Header>
        <Modal.Title className="cs-semi-bold-h1 cs-neutral-100">PFS Preview</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <div className="pfs-pdf-box wm-req-pdf-container">
          {URL ? (
            <iframe src={URL} width="100%" height="100%" title="PDF Viewer"></iframe>
          ) : (
            <span className="cs-common-no-record cs-light-body-text-l cs-neutral-100">
              Personal Financial Statement Not found
            </span>
          )}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="cs-modal-btn">
          <Button
            onClick={handleClosePFSPreviewModal}
            className="cs-btn-secondary md-btn cs-regular-h5"
            variant="secondary">
            Cancel
          </Button>
          <Button
            className="cs-btn-primary md-btn cs-regular-h5"
            type="button"
            onClick={handleShareSubmit}
            variant="primary"
            disabled={URL === ''}>
            Send
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

// PROPS TYPE
PFSPreviewModal.propTypes = {
  showPreviewModal: PropTypes.bool,
  setShowPreviewModal: PropTypes.func,
  sharePFSModalSchema: PropTypes.obj,
  URL: PropTypes.string,
  setURL: PropTypes.func,
  rowData: PropTypes.object
};

export default PFSPreviewModal;
