import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';

// COMPONENT IMPORTS
import FileManagementBreadcrumb from './HeaderSection/FileManagementBreadcrumb';
import CapsyncIcon from '../../components/CapsyncIcon';
import commonNames from '../../utils/commonNames';
import CapsyncLoader from '../../components/CapsyncLoader';
import { getFileIcon } from '../../utils/common';
import { defaultMoveBreadcrumb } from './FileManagementCommonElem';
import Modal from '../../components/Modal';

// API
import { getMoveList, moveTrashItem } from '../../slices/fileManagementSlice';
import { useUser } from '../../slices/authSlice';

/* ============================== FILE FOLDER MOVE MODAL ============================== */
const FileFolderMoveModal = (props) => {
  const {
    moveModalOpen,
    fileParentId = null,
    moveSelectedContent = [],
    closeModal,
    setOpenDuplicateModalOpen,
    setExistingMoveFiles
  } = props;

  const user = useUser();
  const dispatch = useDispatch();

  /* USE STATES */
  const [loading, setLoading] = useState(false);
  const [moveLoading, setMoveLoading] = useState(false);
  const [data, setData] = useState([]);
  const [breadcrumbDetails, setBreadcrumbDetails] = useState([...defaultMoveBreadcrumb]);
  const [parentId, setParentId] = useState(breadcrumbDetails[0]?.id);

  const fetchDocumentsList = async () => {
    setLoading(true);
    try {
      const payload = { user_id: user?.id, parent_id: parentId };
      const response = await dispatch(getMoveList(payload)).unwrap();
      setData(response?.data);
      setParentId(breadcrumbDetails[breadcrumbDetails.length - 1]?.id);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log('error :>> ', error);
    }
  };

  useEffect(() => {
    fetchDocumentsList();
  }, [parentId]);

  const breadcrumbChange = (items) => {
    if (loading) {
      return;
    }
    setParentId(items.id);
    const data = [...breadcrumbDetails];
    const idx = data.findIndex((val) => val.id === items.id);
    const final_data = data?.slice(0, idx + 1);
    setBreadcrumbDetails(final_data);
  };

  const checkExistFile = () => {
    const filesArray = moveSelectedContent;
    const fileNames = [];
    filesArray.map((val) => fileNames.push(val.name));
    const existingFiles = [];
    // eslint-disable-next-line array-callback-return
    data?.map((obj) => {
      if (fileNames.includes(obj.name) && obj.attachment !== null) {
        existingFiles.push(obj);
      }
    });
    if (existingFiles.length > 0) {
      closeClick(false);
      setMoveLoading(false);
      setExistingMoveFiles(existingFiles);
      setOpenDuplicateModalOpen(true);
      return true;
    }
    return false;
  };

  const onMoveHere = async () => {
    setMoveLoading(true);
    if (checkExistFile()) {
      setMoveLoading(false);
      return false;
    }
    try {
      const IdsArray = [];
      moveSelectedContent.map((val) => IdsArray.push(val.id));
      const payload = {
        user_id: user?.id,
        move_from_arr: [...IdsArray],
        move_to_id: parentId,
        category_id: breadcrumbDetails[breadcrumbDetails.length - 1].category_id || undefined,
        category_primary_id: breadcrumbDetails[breadcrumbDetails.length - 1].row_id || undefined
      };
      const response = await dispatch(moveTrashItem(payload)).unwrap();
      if (response && response.code === 200) {
        toast.success('Moved successfully.');
        closeClick();
      }
      setMoveLoading(false);
    } catch (error) {
      setMoveLoading(false);
      console.log(error.message);
    }
  };

  const getFileExtension = (filename) => {
    return filename?.slice(((filename.lastIndexOf('.') - 1) >>> 0) + 2);
  };

  const checkContent = () => {
    const allowExtensions = ['pdf', 'png', 'jpeg', 'jpg'];
    const filesArray = moveSelectedContent;
    for (const file of filesArray) {
      // Validation 1: Check file extension
      const fileExtension = getFileExtension(file?.name);
      if (!allowExtensions.includes(fileExtension?.toLowerCase())) {
        return false;
      }
      // Validation 2: Check file attachment
      if (!file.attachment) {
        return false;
      }
    }
    return true; // All validations passed
  };

  const checkIsDisable = (val) => {
    if (val.attachment) {
      return true;
    }
    if ([commonNames.Assets, commonNames.Liabilities].includes(val.name) && parentId === 0) {
      return !checkContent();
    }
    return !!moveSelectedContent.find((data) => data?.id === val.id);
  };

  const closeClick = (callListApi = true) => {
    closeModal(callListApi);
    setMoveLoading(false);
    setParentId(null);
    setData([]);
    setBreadcrumbDetails([
      {
        title: 'My Documents',
        id: 0,
        is_movable: false,
        category_id: null,
        row_id: null
      }
    ]);
  };

  const title = (
    <React.Fragment>
      <div className="cs-semi-bold-h3">
        {moveSelectedContent.length > 1 ? 'All' : moveSelectedContent[0]?.name}
      </div>
      {breadcrumbDetails.length > 1 && (
        <div className="cs-breadcrumb">
          <FileManagementBreadcrumb
            breadcrumbDetails={breadcrumbDetails}
            onClick={(val) => {
              breadcrumbChange(val);
            }}
          />
        </div>
      )}
    </React.Fragment>
  );

  const body = (
    <div className="cs-move-container">
      {loading ? (
        <CapsyncLoader />
      ) : data?.length === 0 ? (
        <span className="cs-regular-h4">This folder is empty</span>
      ) : (
        data?.map((val, index) => {
          const extension = val?.name?.split(/\.([^.]+)$/)?.[1]?.toLowerCase();
          const icon = getFileIcon(extension);
          return (
            <div
              className={`cs-move-row ${checkIsDisable(val) ? 'cs-icon-disabled' : ''}`}
              key={index}
              onClick={() => {
                try {
                  if (!loading && checkIsDisable(val)) {
                    return false;
                  }
                  if (!loading && !val.attachment) {
                    setLoading(true);
                    setParentId(val.id);
                    setBreadcrumbDetails((pre) => [
                      ...pre,
                      {
                        title: val.name,
                        id: val.id,
                        is_movable: val.is_movable,
                        category_id: val.category_id,
                        row_id: val.category_primary_id
                      }
                    ]);
                  }
                } catch (error) {
                  console.log('error :>> ', error);
                }
              }}>
              <div className="cs-folder-title">
                {val.attachment ? (
                  <span className="file-icon">{icon}</span>
                ) : (
                  <span className="cs-neutral-80">
                    <CapsyncIcon title="folder-icon-filled" size="22" />
                  </span>
                )}
                <span className="cs-regular-sub-heading-m cs-neutral-100">{val.name}</span>
              </div>
              {!val.attachment && (
                <span className="cs-arrow cs-neutral-80">
                  <CapsyncIcon title="chevron-right-outlined" size="22" />
                </span>
              )}
            </div>
          );
        })
      )}
    </div>
  );

  return (
    <Modal
      title={title}
      handleOnSave={() => onMoveHere()}
      cancelButtonLabel="Cancel"
      saveButtonLabel="Move here"
      show={moveModalOpen}
      parentClassName="manage-files file-management-modal file-common-modal"
      isCloseButton={false}
      className="cs-md-modal"
      body={body}
      handleOnCancel={() => closeClick(false)}
      disabled={
        parentId === 0 ||
        fileParentId === parentId ||
        (breadcrumbDetails.some((val) =>
          [commonNames.Assets, commonNames.Liabilities].includes(val.title)
        ) &&
          breadcrumbDetails.length < 4) ||
        moveLoading
      }
    />
  );
};

// PROPS TYPE
FileFolderMoveModal.propTypes = {
  moveModalOpen: PropTypes.any,
  fileParentId: PropTypes.any,
  moveSelectedContent: PropTypes.any,
  closeModal: PropTypes.any,
  setOpenDuplicateModalOpen: PropTypes.any,
  setExistingMoveFiles: PropTypes.any
};

export default FileFolderMoveModal;
