/* eslint-disable no-useless-escape */
import React from 'react';
import moment from 'moment';
import { toast } from 'react-toastify';
import secureLocalStorage from 'react-secure-storage';

// COMPONENT IMPORTS
import AWS from '../constants/aws-config';
import CapsyncIcon from '../components/CapsyncIcon';
import { TOAST_MESSAGE } from '../constants/message';

// API
import { showUploadProgress } from '../slices/commonSlice';
import { resetFileManagementSlice } from '../slices/fileManagementSlice';
import { resetAccreditationMethodsSlice } from '../slices/accreditationSlice';
import { resetArraySlice } from '../slices/arraySlice';
import { resetAuditLogsSlice } from '../slices/auditLogsSlice';
import { resetAuthSlice } from '../slices/authSlice';
import { resetCategorySlice } from '../slices/categorySlice';
import { resetCollaborationSlice } from '../slices/collaborationSlice';
import { resetDashboardSlice } from '../slices/dashboardSlice';
import { resetMfaSlice } from '../slices/mfaSlice';
import { resetNotificationSlice } from '../slices/notificationSlice';
import { resetPlaidSlice } from '../slices/plaidSlice';
import { resetRequestSlice } from '../slices/requestSlice';
import { resetSidebarSlice } from '../slices/sidebarSlice';
import { resetAddressSlice } from '../slices/systemSlice';
import { resetDownloadSlice } from '../slices/downloadSlice';

const tooltipContent = (params, type) => {
  if (params && params.popper.state) {
    const options = params.popper.state.options.values_data;
    if (options === '') {
      return false;
    }
    if (type === 'spaces') {
      return /^\S*$/.test(options);
    }
    if (type === 'lowercase') {
      return /[a-z]/.test(options);
    }
    if (type === 'uppercase') {
      return /[A-Z]/.test(options);
    }
    if (type === 'number') {
      return /[0-9]/.test(options);
    }
    if (type === 'symbol') {
      return options && /[^A-Za-z0-9]/.test(options);
    }
    if (type === 'more_characters') {
      const store = '' + options;
      return store.length < 8 ? false : true;
    }
    if (type === 'less_characters') {
      const store = '' + options;
      return store.length > 20 ? false : true;
    }
  } else {
    return false;
  }
};

const loanTermType = ['Yearly', 'Monthly'];

const getDomainName = () => {
  const currentUrl = window.location.href;
  const pattern = /^(?:https?:\/\/)?(?:www\.)?([^/?:]+)/;
  const match = currentUrl.match(pattern);
  if (match) {
    const domain = match[1];
    return domain.replace(process.env.REACT_APP_COMMAN_URL, '');
  } else {
    return null;
  }
};

const checkDomainAccept = (email) => {
  // prettier-ignore
  const domainAccept = ['com', 'org', 'io', 'net', 'edu', 'gov', 'ae', 'int', 'dot', 'co', 'in', 'ca', 'fr', 'br', 'de', 'ru', 'it', 'es', 'nl', 'jp', 'ar', 'uk', 'mx', 'id', 'au', 'biz', 'info', 'us', 'ch', 'se', 'no', 'dk', 'fi', 'be', 'at', 'cz', 'pl', 'pt', 'gr', 'hu', 'ro', 'tr', 'sg', 'hk', 'tw', 'vn', 'th', 'ph', 'my', 'kr', 'cn', 'is'];
  const emailRegex =
    /^[a-zA-Z0-9.!#$%&'+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)$/;

  if (!email) {
    return true;
  }
  const lowerCaseEmail = email.toLowerCase();
  if (!emailRegex.test(lowerCaseEmail)) {
    return false;
  }
  const emailDomains = email && lowerCaseEmail.split('.')[lowerCaseEmail.split('.').length - 1];
  return email ? domainAccept.includes(emailDomains) : true;
};

const preventSpecialCharacters = (e) => {
  !/^[a-zA-Z_ ]*$/.test(e.key) && e.preventDefault();
  if (e.key === ' ' && e.target.selectionStart === 0) {
    e.preventDefault();
  }
};

const preventSpace = (e) => e.code === 'Space' && e.preventDefault();

const preventNegativeNumbers = (e) => {
  const clipboardData = e.clipboardData || window.clipboardData;
  const pastedText = clipboardData.getData('Text');

  // Check if the pasted value is negative and prevent it if it is prevent it
  if (parseFloat(pastedText) < 0) {
    e.preventDefault();
  }
};

const addTimePeriod = (date, period, type) => {
  const dur = moment.duration(period, type === 'Monthly' ? 'M' : 'y');
  return moment(date).add(dur.asDays(), 'd');
};

/**
 *
 *
 * @param {number|string} P
 * @param {number} r
 * @param {number} t
 * @param {number} n
 * @return {number}
 */
function calculatePaymentPeriod(P, r, t, n) {
  const principal = P == '' ? 0 : P;
  const interestRate = r / 100;
  const ratePerPeriod = interestRate / n;

  const numerator = 1 - Math.pow(1 + ratePerPeriod, -(n * t));

  const TotalBalance =
    typeof P == 'number' ? principal : P.includes(',') ? principal.replace(/,/g, '') : principal;

  const monthlyPaymentt = (TotalBalance * ratePerPeriod) / numerator;

  const simpleInterest = monthlyPaymentt * (t * n);

  if (P == 0 || r == 0 || t == 0 || n == 0) {
    return 0;
  } else {
    return simpleInterest.toFixed(2);
  }
}
const checkValueType = (val) => {
  if (typeof val == 'string') {
    return Number(val.replace(/,/g, ''));
  } else {
    return val;
  }
};
const fetchDate = (params) => {
  const returnDate = new Date(params);
  return returnDate.getMonth();
};
const fetchDateYear = (params) => {
  const returnDate = new Date(params);
  return returnDate.getFullYear().toString().substring(2, 4);
};
const lineChartMonth = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec'
];
const monthWiseQuarter = () => {
  const threeMonthsAgo = moment().subtract(0, 'months').format('YYYY-MM-DD');
  const threeMonthsAgo2 = moment().subtract(4, 'months').format('YYYY-MM-DD');
  const threeMonthsAgo3 = moment().subtract(8, 'months').format('YYYY-MM-DD');
  const threeMonthsAgo4 = moment().subtract(12, 'months').format('YYYY-MM-DD');

  const legendFooter = [
    lineChartMonth[fetchDate(threeMonthsAgo4)] + " '" + fetchDateYear(threeMonthsAgo4),
    lineChartMonth[fetchDate(threeMonthsAgo3)] + " '" + fetchDateYear(threeMonthsAgo3),
    lineChartMonth[fetchDate(threeMonthsAgo2)] + " '" + fetchDateYear(threeMonthsAgo2),
    lineChartMonth[fetchDate(threeMonthsAgo)] + " '" + fetchDateYear(threeMonthsAgo)
  ];
  return legendFooter;
};
const dateFormat = (dateString) => {
  return moment(dateString, 'YYYY-MM-DD').format('MM/DD/YYYY');
};

const generateString = (length) => {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let result = '';
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};
const selectOptionsArr = (arr) => {
  const options = [];
  arr.map((data) => {
    options.push({ value: data, label: data });
  });
  return options;
};
const d = new Date();
const yearType = [...Array(d.getFullYear())]
  .map((itr, i) => d.getFullYear() - i)
  .filter((year) => year >= 1990 && year <= d.getFullYear());

const setAddressString = (address) => {
  let newAddres = '';
  if (address == null) {
    return '';
  }
  if (address.line1 && address.line1.length !== 0) {
    newAddres += address.line1 + ', ';
  }
  if (address.line2 && address.line2.length !== 0) {
    newAddres += address.line2 + ', ';
  }
  return newAddres + address.city + ', ' + address.state + ' ' + address.country;
};

const calculateAmortization = (Principal, rate, time, num) => {
  const interestRate = rate / 100; // Convert the interest rate from percentage to decimal
  const ratePerPeriod = interestRate / num;
  const numberOfPayments = time;
  const numerator = 1 - Math.pow(1 + ratePerPeriod, -numberOfPayments);
  const monthlyPaymentt = (Principal * ratePerPeriod) / numerator;
  return monthlyPaymentt;
};
const totalBalance = (external_api, type) => {
  const totalExternalApi =
    external_api && external_api.reduce((prev, curr) => prev + curr[type], 0);
  return totalExternalApi;
};
const roles = {
  individual: { name: 'Individual', roleID: '1' },
  standard: { name: 'Standard', roleID: '2' },
  professional: { name: 'Tax Professional', roleID: '3' },
  admin_financial_advisor: { name: 'Admin Financial Advisor', roleID: '4' },
  master_admin_financial_advisor: { name: 'Master Admin Financial Advisor', roleID: '5' }
};

const localStorageClear = () => {
  const webPass = process.env.REACT_APP_WEB_PASSWORD;
  window.localStorage.clear();
  window.localStorage.setItem('tempPass', webPass);
};

const showToastAndReturnStatus = ({
  response,
  successMessage = 'Success',
  failedMessage = TOAST_MESSAGE.SOMETHING_WENT_WRONG
}) => {
  console.log('res---->', response);
  if (response?.code === 200) {
    toast.success(successMessage);
  } else {
    toast.error(failedMessage);
  }
  return response?.code === 200;
};

const getInitialFullName = (name) => {
  if (!name) {
    return '';
  }
  const firstName = name.split(' ')[0];
  const lastName = name.split(' ')[1];
  const initials =
    ((firstName && firstName.charAt(0)) || '') + ((lastName && lastName.charAt(0)) || '');
  return initials.toUpperCase();
};

const fileSizeCalc = (file_size) => {
  const fileSizeInBytes = parseInt(file_size);
  if (isNaN(fileSizeInBytes)) {
    return 0;
  }
  if (fileSizeInBytes >= 1073741824) {
    return (fileSizeInBytes / 1073741824).toFixed(2) + ' GB';
  } else if (fileSizeInBytes >= 1048576) {
    return (fileSizeInBytes / 1048576).toFixed(2) + ' MB';
  } else if (fileSizeInBytes >= 1024) {
    return (fileSizeInBytes / 1024).toFixed(2) + ' KB';
  } else {
    return fileSizeInBytes === 0 ? 0 + ' Bytes' : fileSizeInBytes + ' Bytes';
  }
};

// prettier-ignore
const iconToExtensionsMap = {
  'file-image': ['jpeg', 'png', 'gif', 'bmp', 'jpg', 'webp', 'tiff', 'svg'],
  'file-ai': ['ai'],
  'file-audio': ['mp3', 'wav', 'ogg', 'opus', 'ogv'],
  'file-archive': ['zip', 'rar', 'tar', 'gzip'],
  'file-doc': ['doc'],
  'file-docx': ['docx'],
  'file-postscript': ['ps', 'eps'],
  'file-video': ['webm', 'mpeg4', 'mpeg', 'mp4', '3gpp', 'mov', 'avi', 'mpegps', 'wmv', 'flv', '3gp', 'mkv'],
  'file-photoShop': ['psd', 'css', 'html', 'php', 'c', 'cpp', 'h', 'hpp', 'js', 'java', 'py', 'dxf', 'xlsb', 'xlsm', 'pptm', 'potm', 'ppsm', 'key', 'numbers'],
  'file-pdf': ['pdf'],
  'file-ppt': ['ppt'],
  'file-pptx': ['pptx'],
  'file-ttf': ['ttf'],
  'file-xls': ['xls', 'csv'],
  'file-xlsx': ['xlsx'],
  'file-txt': ['txt'],
};

const getIconForExtension = (extension) => {
  for (const [icon, extensions] of Object.entries(iconToExtensionsMap)) {
    if (extensions.includes(extension)) {
      return icon;
    }
  }
  return 'file-unsupported';
};

const getFileIcon = (extension, fileSize = 22) => {
  const iconClass = getIconForExtension(extension);
  return <CapsyncIcon title={iconClass} size={fileSize} />;
};

const fileTypes = {
  image: ['jpg', 'jpeg', 'gif', 'png', 'webp'],
  pdf: ['pdf'],
  csv: ['csv'],
  txt: ['txt'],
  xlsx: ['xlsx', 'xls'],
  video: ['mp4', 'webm', 'mov', 'avi', 'mkv', 'flv', '3gp', 'asf', 'm4v'],
  audio: ['mp3', 'wav', 'ogg', 'opus', 'ogv'],
  document: ['pptx'],
  docx: ['docx']
};

const getFileType = (fileName) => {
  const extension = fileName.split('.').pop().toLowerCase();

  for (const [type, extensions] of Object.entries(fileTypes)) {
    if (extensions.includes(extension)) {
      return type;
    }
  }

  return 'unknown';
};

const handleS3FolderUpload = async (fileArray, userId, dispatch) => {
  const s3 = new AWS.S3();
  const uploadPromises = [];
  const folderProgress = [];

  try {
    fileArray.forEach((file, index) => {
      const foldername =
        file.webkitRelativePath !== ''
          ? file.webkitRelativePath.split('/')[0]
          : file.path.split('/')[1];

      const existingFolderIndex = folderProgress.findIndex(
        (item) => item.foldername === foldername
      );

      if (existingFolderIndex === -1) {
        folderProgress.push({
          foldername: foldername,
          id: Date.now() + index,
          files: file.size > 0 ? [{ name: file.name, progress: 0 }] : []
        });
        dispatch(
          showUploadProgress({
            files: [foldername], // Only pass the matched foldername in an array
            progress: 0,
            id: Date.now() + index,
            isDelete: false
          })
        );
      } else if (file.size > 0) {
        folderProgress[existingFolderIndex].files.push({
          name: file.name,
          progress: 0
        });
      }
    });
    fileArray.forEach((file) => {
      const foldername =
        file.webkitRelativePath !== ''
          ? file.webkitRelativePath.split('/')[0]
          : file.path.split('/')[1];

      const fileName = file.name.split(/\.(?=[^\.]+$)/)[0];
      const extension = file.name.split(/\.(?=[^\.]+$)/)[1];

      const pathName = `WL-${userId}/Others/_@${fileName}_${Date.now() + '.' + extension}`;

      const params = {
        Bucket: process.env.REACT_APP_S3_BUCKET_NAME,
        Key: pathName,
        Body: file
        // ContentType: file.type,
        // ACL: "public-read", // Set permissions as needed
      };

      const uploadPromise = s3
        .putObject(params)
        .on('httpUploadProgress', (evt) => {
          const folderIndex = folderProgress.findIndex((item) => item.foldername === foldername);

          if (folderIndex !== -1) {
            const fileIndex = folderProgress[folderIndex].files.findIndex(
              (f) => f.name === file.name
            );

            if (fileIndex !== -1) {
              folderProgress[folderIndex].files[fileIndex].progress = parseInt(
                (evt.loaded * 100) / evt.total
              );

              const totalFiles = folderProgress[folderIndex].files.length;
              const totalProgress = folderProgress[folderIndex].files.reduce(
                (acc, curr) => acc + curr.progress,
                0
              );
              const averageProgress = Math.round(totalProgress / totalFiles);

              dispatch(
                showUploadProgress({
                  files: [foldername], // Only pass the matched foldername in an array
                  progress: averageProgress,
                  id: folderProgress[folderIndex].id,
                  isDelete: false
                })
              );
              if (averageProgress === 100) {
                setTimeout(() => {
                  dispatch(
                    showUploadProgress({
                      files: [foldername], // Only pass the matched foldername in an array
                      progress: averageProgress,
                      id: folderProgress[folderIndex].id,
                      isDelete: true
                    })
                  );
                }, 1000);
              }
            } else {
              dispatch(
                showUploadProgress({
                  files: [foldername], // Only pass the matched foldername in an array
                  progress: 100,
                  id: folderProgress[folderIndex].id,
                  isDelete: false
                })
              );
              setTimeout(() => {
                dispatch(
                  showUploadProgress({
                    files: [foldername], // Only pass the matched foldername in an array
                    progress: 100,
                    id: folderProgress[folderIndex].id,
                    isDelete: true
                  })
                );
              }, 1000);
            }
          }
        })
        .promise();

      uploadPromises.push(uploadPromise);
    });

    const uploadResults = await Promise.allSettled(uploadPromises);

    const successfulUploads = [];
    const failedUploads = [];

    uploadResults.forEach((result, index) => {
      if (result.status === 'fulfilled') {
        successfulUploads.push({
          file: fileArray[index],
          result: result.value.$response.request.params // AWS S3 response
        });
      } else {
        failedUploads.push({
          file: fileArray[index],
          reason: result.reason // Error or reason for failure
        });
      }
    });
    return { successfulUploads, failedUploads };
  } catch (error) {
    console.log('Upload error', error);
  }
};

const handleS3Upload = async (fileArray, userId, dispatch) => {
  const s3 = new AWS.S3();
  const uploadPromises = [];

  try {
    fileArray.forEach((file) => {
      if (!file || !file.name || !file.size) {
        console.error('Invalid file object:', file);
        return;
      }

      const fileName = file.name.split(/\.(?=[^\.]+$)/)[0];
      const extension = file.name.split(/\.(?=[^\.]+$)/)[1];

      const pathName = `WL-${userId}/Others/_@${fileName}_${Date.now() + '.' + extension}`;
      const uId = Date.now();

      dispatch(
        showUploadProgress({
          files: [file.name],
          progress: 0,
          id: uId,
          isDelete: false
        })
      );

      const params = {
        Bucket: process.env.REACT_APP_S3_BUCKET_NAME,
        Key: pathName,
        Body: file
        // ContentType: file.type,
        // ACL: 'public-read', // Set permissions as needed
      };

      const uploadPromise = s3
        .putObject(params)
        .on('httpUploadProgress', (evt) => {
          dispatch(
            showUploadProgress({
              files: [file.name],
              progress: parseInt((evt.loaded * 100) / evt.total),
              id: uId,
              isDelete: false
            })
          );
          if (parseInt((evt.loaded * 100) / evt.total) === 100) {
            setTimeout(() => {
              dispatch(
                showUploadProgress({
                  files: [file.name],
                  progress: parseInt((evt.loaded * 100) / evt.total),
                  id: uId,
                  isDelete: true
                })
              );
            }, 1000);
          }
        })
        .promise();

      uploadPromise.catch((error) => {
        dispatch(
          showUploadProgress({
            files: [file.name],
            progress: 100,
            id: uId,
            isDelete: true
          })
        );
      });

      uploadPromises.push(uploadPromise);
    });

    const uploadResults = await Promise.allSettled(uploadPromises);

    const successfulUploads = [];
    const failedUploads = [];

    uploadResults.forEach((result, index) => {
      if (result.status === 'fulfilled') {
        successfulUploads.push({
          file: fileArray[index],
          result: result.value.$response.request.params // AWS S3 response
        });
      } else {
        failedUploads.push({
          file: fileArray[index],
          reason: result.reason // Error or reason for failure
        });
      }
    });

    return { successfulUploads, failedUploads };
  } catch (error) {
    console.log('Upload error', error);
  }
};

// prettier-ignore
const supportedExtensions = ['.pdf', '.ps', '.csv', '.epub', '.kml', '.kmz', '.gpx', '.hwp', '.htm', '.html', '.xls', '.xlsx', '.ppt', '.pptx', '.doc', '.docx', '.odp', '.ods', '.odt', '.rtf', '.svg', '.tex', '.txt', '.bas', '.c', '.cc', '.cpp', '.cxx', '.h', '.hpp', '.cs', '.java', '.pl', '.py', '.wml', '.wap', '.xml', '.bmp', '.gif', '.jpeg', '.png', '.webp', '.3gp', '.3g2', '.asf', '.avi', '.divx', '.m2v', '.m3u', '.m3u8', '.m4v', '.mkv', '.mov', '.mpeg', '.ogv', '.qvt', '.ram', '.rm', '.vob', '.webm', '.wmv', '.xap', '.zip', '.rar', '.tar', '.gzip', '.mp3', '.mp4', '.wav', '.ogg', '.opus', '.tiff', '.css', '.php', '.js', '.xlsb', '.xlsm', '.pptm', '.potm', '.ppsm', '.docm', '.key', '.numbers', '.xps', '.flv', '.dxf', '.ai', '.psd', '.eps', '.jpg', '.mpeg4', '.3gpp', '.mpegps', '.ttf'];

const currencyFormat = (num) => {
  return Number(num)
    .toFixed(2)
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
};

const isValidFileName = (input) => {
  const forbiddenRegex = /^[^\\\x80-\xff{}^%`'"<>\[\]~#|]+$/;
  return forbiddenRegex.test(input);
};

const replaceLastOccurrence = (inputStr, oldVal, newVal) => {
  const lastIndex = inputStr.lastIndexOf(oldVal);

  if (lastIndex === -1) {
    return inputStr;
  } else {
    return (
      inputStr.substring(0, lastIndex) + newVal + inputStr.substring(lastIndex + oldVal.length)
    );
  }
};

const headerToken = () => {
  const token = secureLocalStorage.getItem('token')
    ? secureLocalStorage.getItem('token')
    : undefined;
  return {
    headers: {
      Authorization: `Bearer ${token}`
    }
  };
};

const fileDownload = (file) => {
  const link = document.createElement('a');
  link.href = file.attachment;
  link.download = file.name;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  toast.success('Ready to Download');
};

const quickbooksReportDownload = (file, reportCategory, type) => {
  let reportName = '';
  switch (reportCategory) {
    case 'profit_loss':
      reportName = 'ProfitandLoss';
      break;
    case 'balance_sheet':
      reportName = 'BalanceSheet';
      break;
    case 'cash_flow':
      reportName = 'StatementofCashFlows';
      break;
    case 'trial_balance':
      reportName = 'TrialBalance';
      break;
    default:
      reportName = 'Report';
      break;
  }

  const link = document.createElement('a');
  const url = window.URL.createObjectURL(file);
  link.href = url;
  if (type === 'excel') {
    link.download = `${reportName}.xlsx`;
  } else {
    link.download = `${reportName}.pdf`;
  }
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  window.URL.revokeObjectURL(url);
};

function stringShorten(str, limit) {
  return str.length > limit ? str.slice(0, limit) + '...' : str;
}

const clearPersisterStorage = (dispatch) => {
  dispatch(resetAccreditationMethodsSlice());
  dispatch(resetArraySlice());
  dispatch(resetAuditLogsSlice());
  dispatch(resetAuthSlice());
  dispatch(resetCategorySlice());
  dispatch(resetCollaborationSlice());
  dispatch(resetDashboardSlice());
  dispatch(resetFileManagementSlice());
  dispatch(resetMfaSlice());
  dispatch(resetNotificationSlice());
  dispatch(resetPlaidSlice());
  dispatch(resetRequestSlice());
  dispatch(resetSidebarSlice());
  dispatch(resetAddressSlice());
  dispatch(resetDownloadSlice());
};

export {
  tooltipContent,
  getDomainName,
  monthWiseQuarter,
  checkDomainAccept,
  preventSpace,
  preventNegativeNumbers,
  preventSpecialCharacters,
  loanTermType,
  handleS3FolderUpload,
  supportedExtensions,
  roles,
  getFileIcon,
  dateFormat,
  addTimePeriod,
  generateString,
  calculatePaymentPeriod,
  checkValueType,
  selectOptionsArr,
  yearType,
  setAddressString,
  calculateAmortization,
  totalBalance,
  localStorageClear,
  showToastAndReturnStatus,
  getInitialFullName,
  fileSizeCalc,
  currencyFormat,
  isValidFileName,
  replaceLastOccurrence,
  handleS3Upload,
  clearPersisterStorage,
  headerToken,
  fileDownload,
  getFileType,
  stringShorten,
  quickbooksReportDownload
};
