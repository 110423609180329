import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button, Modal } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

// COMPONENT IMPORTS
import { setMethodName, setRowID } from '../../../slices/accreditationSlice';

/* ============================== CONFIRMATION MODAL ============================== */
const ConfirmationModel = ({ showConfirmation, setShowConfirmation }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const store = location.pathname.split('/');
  const uniqueRowId = store[store.length - 2];
  const verificationType = store[store.length - 3];

  const handleCancel = () => {
    setShowConfirmation(false);
    navigate('/verifyinvestor');
  };

  const handleContinue = () => {
    setShowConfirmation(false);
    if (
      verificationType === 'individuals' ||
      verificationType === 'trusts' ||
      verificationType === 'entities' ||
      verificationType === 'qualifiedclient' ||
      verificationType === 'qualifiedpurchaser'
    ) {
      dispatch(setMethodName(verificationType));
      dispatch(setRowID(uniqueRowId));
      navigate(`/verifyinvestor/verification-type`);
    }
  };
  const handleverificationType = (verificationType, uniqueRowId) => {
    if (verificationType === 'qualifiedpurchaser' || uniqueRowId === 'qualifiedpurchaser') {
      return 'a qualified purchaser';
    } else if (verificationType === 'qualifiedclient' || uniqueRowId === 'qualifiedclient') {
      return 'a qualified client';
    } else {
      return 'an accredited investor';
    }
  };
  return (
    <Modal
      show={showConfirmation}
      onHide={() => setShowConfirmation(!showConfirmation)}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      dialogClassName="cs-md-modal"
      className="confirmation-modal">
      <Modal.Header>
        <Modal.Title className="cs-semi-bold-h3">Confirmation</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="delete-modal-content">
          <p className="cs-medium-sub-heading-m cs-neutral-100 text-center">
            Do not cancel if you need more time to finish this request. If you do not complete the
            verification within 45 days of the original request, we will assume that you have
            cancelled this verification request and report that we were unable to verify you. Please
            contact support before your request expires for on extension.
          </p>
          <p className="cs-regular-body-text-m cs-neutral-90 text-center">
            Please confirm that you are not{' '}
            {`${handleverificationType(verificationType, uniqueRowId)}`} investor or wish to cancel
            this verification request. If you are not{' '}
            {`${handleverificationType(verificationType, uniqueRowId)}`} or wish to cancel this
            verification request, the verification process will be completed with the result that
            you are not verified {`${handleverificationType(verificationType, uniqueRowId)}`}.
          </p>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button className="cs-btn-secondary xl-btn cs-regular-h5" onClick={handleCancel}>
          I am not verified or wish to cancel
        </Button>
        <Button className="cs-btn-primary xl-btn cs-regular-h5" onClick={handleContinue}>
          I do not wish to cancel, continue
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

// PROPS TYPE
ConfirmationModel.propTypes = {
  showConfirmation: PropTypes.bool,
  setShowConfirmation: PropTypes.func
};

export default ConfirmationModel;
