import React, { useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import PropTypes from 'prop-types';

// COMPONENT IMPORTS
import Modal from '../../components/Modal';
import CapsyncIcon from '../../components/CapsyncIcon';

/* ============================== RADIO BUTTON ============================== */
const RadioButton = ({ id, label, checked, onChange, iconTitle }) => (
  <div className={`auth-mfa-item ${checked ? 'active-method' : ''}`} onClick={onChange}>
    <div className="auth-mfa-inner">
      <div className="auth-mfa-options cs-radio-btn">
        <input
          type="radio"
          name="mfOption"
          id={id}
          className="radio-button"
          checked={checked}
          onChange={onChange}
        />
      </div>
      <div className="auth-mfa-options">
        <h4 className="cs-neutral-100 cs-regular-h4">{label}</h4>
      </div>
    </div>
    <div className="cs-neutral-80">
      <CapsyncIcon title={iconTitle} size="26" />
    </div>
  </div>
);

// PROPS TYPE
RadioButton.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  iconTitle: PropTypes.string.isRequired
};

const ReplaceFileModal = ({ showModal, onSubmitCLick, closeModal, filesDetails = [] }) => {
  const [selectedUploadType, setSelectedUploadType] = useState('keep_both');

  const handleRadioChange = (value) => () => setSelectedUploadType(value);

  const body = (
    <Row>
      <Col lg={12}>
        <div className="auth-right">
          <div className="formArea">
            <div className="text-center">
              <p className="cs-neutral-80 cs-regular-h5">
                <span className="cs-text-ellipsis">
                  {filesDetails.length > 1 ? 'Some of the items' : filesDetails[0]?.name}{' '}
                </span>
                already exist in this location. Do you want to replace the existing file with a new
                version or keep both files?
              </p>
            </div>
            <div>
              <RadioButton
                id="keep_both"
                label="Keep both files"
                checked={selectedUploadType === 'keep_both'}
                onChange={handleRadioChange('keep_both')}
                iconTitle="copy-file-management"
              />
              <RadioButton
                id="replace_file"
                label="Replace existing file"
                checked={selectedUploadType === 'replace_file'}
                onChange={handleRadioChange('replace_file')}
                iconTitle="replace-file-management"
              />
            </div>
          </div>
        </div>
      </Col>
    </Row>
  );

  return (
    <Modal
      title="Upload options"
      handleOnSave={() => onSubmitCLick(selectedUploadType)}
      cancelButtonLabel="Cancel"
      saveButtonLabel="Next"
      isCloseButton={false}
      show={showModal}
      parentClassName="manage-files file-management-modal file-common-modal"
      className="cs-md-modal profile-update-popup"
      body={body}
      handleOnCancel={() => {
        closeModal();
        setSelectedUploadType('keep_both');
      }}
    />
  );
};

ReplaceFileModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  onSubmitCLick: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  filesDetails: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired
    })
  ).isRequired
};

export default ReplaceFileModal;
