import React, { useEffect } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

// COMPONENT IMPORTS
import { MFA_TYPES } from '../../constants/system';
import GoogleQRCode from '../../components/GoogleQRCode';

// API
import { generateMFAOTP, googleMFASetup, useAccessUser } from '../../slices/authSlice';

/* ============================== GOOGLE QR MODAL ============================== */
const GoogleQRModal = ({ show, setShow, setShowGooglePinModal, isMySettings = false }) => {
  const dispatch = useDispatch();

  const accessUser = useAccessUser();
  const { user } = useSelector((state) => state.auth);

  const handleClose = () => setShow(false);

  useEffect(() => {
    if (show) {
      dispatch(
        generateMFAOTP({
          mfaType: MFA_TYPES.AUTHENTICATOR,
          payload: { user_id: isMySettings ? accessUser?.loginUserDetails?.id : user?.id }
        })
      );
    }
  }, [show]);

  const handleContinue = async () => {
    await dispatch(
      googleMFASetup({ user_id: isMySettings ? accessUser?.loginUserDetails?.id : user?.id })
    );
    setShow(false);
    setShowGooglePinModal(true);
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      size="md"
      centered
      dialogClassName="cs-md-modal cs-modal-multi-button">
      <Modal.Header>
        <Modal.Title className="cs-semi-bold-h3 cs-neutral-100">
          Authenticator app verification
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="qrcode-modal">
          <h5 className="cs-regular-h5 cs-neutral-100">
            Please scan the barcode below using Google Authenticator.
          </h5>
          <GoogleQRCode width={193} height={193} isMySettings={isMySettings} />
        </div>
      </Modal.Body>
      <Modal.Footer className="cs-modal-btn">
        <Button className="cs-btn-secondary md-btn cs-regular-h5" onClick={handleClose}>
          Cancel
        </Button>
        <Button className="cs-btn-primary md-btn cs-regular-h5" onClick={handleContinue}>
          Continue
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

// PROPS TYPE
GoogleQRModal.propTypes = {
  show: PropTypes.bool.isRequired,
  isMySettings: PropTypes.bool,
  setShow: PropTypes.func.isRequired,
  setShowGooglePinModal: PropTypes.func.isRequired
};

export default GoogleQRModal;
