import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

/* ============================== AUTH REDIRECTION ============================== */
const useAuthRedirection = () => {
  const { user, mfa, token } = useSelector((state) => state.auth);
  const navigate = useNavigate();

  useEffect(() => {
    if (!token) {
      navigate('/');
    } else if ((user && mfa && !!mfa.verified) || (user && !mfa)) {
      navigate('/mfa-setup');
    } else if (user && mfa && mfa.verified && mfa.verified === true) {
      navigate('/dashboard');
    }
  }, [mfa, token, user, navigate]);
};

export default useAuthRedirection;
