import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

// COMPONENT IMPORTS
import Modal from '../../components/Modal';
import { useFileContent } from './FileManagementCommonElem';
import { getFileType } from '../../utils/common';
import CapsyncLoader from '../../components/CapsyncLoader';
import FileTypePreview from './FileTypePreview';

/* ============================== FILE PREVIEW ============================== */
const FilePreview = (props) => {
  const { showModal, onCancelClick, fileDetails } = props;
  const fileType = getFileType(fileDetails.name);
  const details = useFileContent(fileDetails.attachment, fileType);
  const [selectedSheet, setSelectedSheet] = useState(null);

  useEffect(() => {
    setSelectedSheet(details?.sheetNames?.[0]);
  }, [details]);

  const body = details.loading ? (
    <CapsyncLoader loaderSize={45} />
  ) : (
    <div className="cs-preview-body">
      <FileTypePreview
        fileType={fileType}
        fileDetails={fileDetails}
        details={details}
        selectedSheet={selectedSheet}
        onSelectSheet={setSelectedSheet}
      />
    </div>
  );

  return (
    <Modal
      title={fileDetails?.name}
      show={showModal}
      isCloseButton={true}
      className="cs-lg-modal file-preview-modal"
      body={body}
      handleClose={onCancelClick}
      handleCloseMainModal={onCancelClick}
    />
  );
};

FilePreview.propTypes = {
  showModal: PropTypes.bool,
  onCancelClick: PropTypes.func,
  fileDetails: PropTypes.object.isRequired
};

export default FilePreview;
