import React from 'react';
import PropTypes from 'prop-types';
import { Button, Col } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

// COMPONENT IMPORTS
import {
  deletePFSData,
  handleAccreditationModals,
  handleOpenSignModal
} from '../SubMethods/SubMethodsBasicFunctions';
import CapsyncIcon from '../../../components/CapsyncIcon';
import CheckBox from '../../../components/FormFields/CheckBox';
import InputBox from '../../../components/FormFields/InputBox';
import CapsyncToolTip from '../../../components/CapsyncToolTip';
import SelectBox from '../../../components/FormFields/SelectBox';
import FileUpload from '../../../components/FormFields/FileUpload';
import TextAreaBox from '../../../components/FormFields/TextAreaBox';
import CommonDropdown from '../../../components/FormFields/CommonDropdown';
import InputNumberField from '../../../components/FormFields/InputNumberField';
import MethodFileUpload from '../../../components/FormFields/MethodFileUpload';
import FileUploadWithBtn from '../../../components/FormFields/FileUploadWithBtn';

/* ============================== FIELDS HTML ============================== */
const FieldsHTML = (props) => {
  const { fieldJSON, formik, uploadPopupOpen, setDropboxID, methodFormik, dropboxID } = props;

  const dispatch = useDispatch();

  const { user } = useSelector((state) => state.auth);
  const { pfsLink, loader, rowID } = useSelector((state) => state.accreditation);

  return fieldJSON.map((field, index) => {
    if (field.fieldVisiblity || field.fieldVisibilityCondition) {
      if (field.fieldType === 'selectpicker') {
        return (
          <Col lg={field.fieldSizeLG} sm={field.fieldSizeSM} key={index + 'field'}>
            <SelectBox
              label={field.fieldLabel}
              name={field.field}
              placeholder={field.fieldPlaceholder}
              options={field.options}
              disabled={field.disabled}
              errors={formik.errors}
              touched={formik.touched}
              handleChangeSelect={(e) => formik.setFieldValue(field.field, e.value)}
            />
          </Col>
        );
      } else if (field.fieldType === 'input-text') {
        return (
          <Col lg={field.fieldSizeLG} sm={field.fieldSizeSM} key={index + 'field'}>
            <InputBox
              label={field.fieldLabel}
              name={field.field}
              placeholder={field.fieldPlaceholder}
              disabled={field.disabled}
              values={formik.values}
              errors={formik.errors}
              touched={formik.touched}
              onChange={(e) => formik.handleChange(e)}
              inputWidth={field.inputWidth}
              required={field.required}
              fieldLabelWithTag={field.fieldLabelWithTag}
              {...field}
            />
          </Col>
        );
      } else if (field.fieldType === 'dropdown') {
        return (
          <Col lg={field.fieldSizeLG} sm={field.fieldSizeSM} key={index + 'field'}>
            <CommonDropdown
              label={field.fieldLabel}
              placeholder={field.fieldPlaceholder}
              errors={formik.errors}
              name={field.field}
              values={formik.values}
              touched={formik.touched}
              setFieldValue={formik.setFieldValue}
              options={field.options}
              extrLabel={field.fieldExtrLabel}
              optionsLabelKey={field.fieldOptionsLabelKey}
              optionValueKey={field.fieldOptionValueKey}
              optionExtraLabelKey={field.fieldOptionExtraLabelKey}
              disabled={field.disabled}
            />
          </Col>
        );
      } else if (field.fieldType === 'input-uploadfile-with-button') {
        return (
          <Col lg={field.fieldSizeLG} sm={field.fieldSizeSM} key={index + 'field'}>
            <FileUploadWithBtn
              errors={formik.errors}
              values={formik.values}
              touched={formik.touched}
              setFieldError={formik.setFieldError}
              setFieldValue={formik.setFieldValue}
              disabled={field.disabled}
              label={field.fieldLabel}
              name={field.field}
              multiple={field.multiple}
              showFiles={field.showFilesList}
              placeholder={field.fieldPlaceholder}
            />
          </Col>
        );
      } else if (field.fieldType === 'input-uploadfile') {
        if (field.methodField) {
          return (
            <Col lg={field.fieldSizeLG} sm={field.fieldSizeSM} key={index + 'method-field'}>
              <MethodFileUpload
                methodField={field.methodField}
                fieldPrimaryLabel={field.fieldPrimaryLabel}
                fieldSecondaryLabel={field.fieldSecondaryLabel}
                highlightedInLineLabel={field.highlightedInLineLabel}
                linkInLineLabel={field.linkInLineLabel}
                linkOfLabel={field.linkOfLabel}
                linkInNewTab={field.linkInNewTab}
                uploadPopupOpen={uploadPopupOpen}
                setDropboxID={setDropboxID}
                values={formik.values}
                errors={formik.errors}
                touched={formik.touched}
                name={field.field}
                disabled={field.disabled}
                formik={formik}
                {...field}
              />
            </Col>
          );
        } else {
          return (
            <Col lg={field.fieldSizeLG} sm={field.fieldSizeSM} key={index + 'field'}>
              <FileUpload
                name={field.field}
                label={field.fieldLabel}
                disabled={field.disabled}
                showFiles={field.showFilesList}
                allowedTypes={['image/jpeg', 'image/png', 'image/jpg']}
                errors={formik.errors}
                values={formik.values}
                setFieldError={formik.setFieldError}
                setFieldValue={formik.setFieldValue}
                methodFormik={methodFormik}
                dropboxID={dropboxID}
              />
            </Col>
          );
        }
      } else if (field.fieldType === 'input-textarea') {
        return (
          <Col lg={field.fieldSizeLG} sm={field.fieldSizeSM} key={index + 'field'}>
            <TextAreaBox
              label={field.fieldLabel}
              name={field.field}
              placeholder={field.fieldPlaceholder}
              disabled={field.disabled}
              values={formik.values}
              errors={formik.errors}
              touched={formik.touched}
              onChange={(e) => formik.handleChange(e)}
              {...field}
            />
          </Col>
        );
      } else if (field.fieldType === 'and-divider') {
        return (
          <Col lg={field.fieldSizeLG} sm={field.fieldSizeSM} key={index + 'field'}>
            <div className="cs-divider cs-select-divider">
              <span className="cs-regular-body-text-m cs-neutral-50">AND</span>
            </div>
          </Col>
        );
      } else if (field.fieldType === 'or-divider') {
        return (
          <Col lg={field.fieldSizeLG} sm={field.fieldSizeSM} key={index + 'field'}>
            <div className="cs-divider cs-select-divider">
              <span className="cs-regular-body-text-m cs-neutral-50">OR</span>
            </div>
          </Col>
        );
      } else if (field.fieldType === 'dropdown-modal') {
        const submitloader = loader && loader.uploadFileModalLoader;
        return (
          <Col lg={field.fieldSizeLG} sm={field.fieldSizeSM} key={index + 'field'}>
            <div
              onClick={() => {
                (!field.disabled || !submitloader) &&
                  handleAccreditationModals(dispatch, 'attachmentDocument', true);
              }}
              className={`cs-attached-doc${field.disabled || submitloader ? ' cs-disabled' : ''}`}>
              <span className="cs-neutral-60">
                <CapsyncIcon title="file-icon-outlined" size="22" />
              </span>
              <h5
                className={`cs-regular-sub-heading-s cs-neutral-100 cs-attached-title ${(field.disabled || submitloader) && 'cs-disabled'}`}>
                Select Attached documents
                <span className="cs-neutral-60">
                  <CapsyncIcon title="chevron-right-outlined" size="22" />
                </span>
              </h5>
            </div>
            {formik.values[field.field].length > 0 && (
              <p className="cs-regular-body-text-m cs-primary">
                {' '}
                {formik.values[field.field].length === 1
                  ? formik.values[field.field].length + ' Attchment is selected'
                  : formik.values[field.field].length + ' Attachments are selected'}
              </p>
            )}
          </Col>
        );
      } else if (field.fieldType === 'input-with-button') {
        return (
          <Col lg={field.fieldSizeLG} sm={field.fieldSizeSM} key={index + 'field'}>
            {(!pfsLink.link || pfsLink.link === '') && (
              <div className="select-pfs-file-row">
                <div className="select-pfs-box cs-neutral-70 cs-regular-h5">
                  <span>
                    <CapsyncIcon title="file-icon-outlined" size="22" />
                  </span>
                  {pfsLink.link ? `${user.display_name}'s PFS ` : 'Attach PFS*'}
                </div>

                <Button
                  className="cs-btn-primary sm-btn cs-regular-h5"
                  disable={field.disabled}
                  onClick={() => handleAccreditationModals(dispatch, 'pfsLink', true)}>
                  Link PFS
                </Button>
              </div>
            )}
            <div className="cs-files">
              <div className={`cs-scroller${field.disabled ? ' cs-disabled' : ''}`}>
                {pfsLink.link && pfsLink.link !== '' && (
                  <div className="cs-selected-files">
                    <span className="cs-neutral-60 cs-fileicon">
                      <CapsyncIcon title="file-icon-outlined" size="22" />
                    </span>
                    <div className="cs-files-names">
                      <p className="cs-regular-sub-heading-s">
                        <p>{user.display_name}'s PFS </p>
                      </p>
                    </div>
                    <div className="cs-file-icon icons">
                      <span
                        className={`cs-icon-badge-neutral-20 cs-file-icon cs-icons${field.disabled ? ' cs-disabled' : ''}`}
                        onClick={() => {
                          !field.disabled && handleOpenSignModal(dispatch, user, rowID);
                        }}>
                        <CapsyncToolTip
                          Child={'edit-outlined'}
                          placement={'top'}
                          size="18"
                          message={'Edit'}
                          type="icon"
                        />
                      </span>
                      <span
                        className={`cs-icon-badge-danger cs-danger cs-file-icon cs-icons${field.disabled ? ' cs-disabled' : ''}`}
                        onClick={(e) => {
                          !field.disabled && deletePFSData(dispatch, rowID, methodFormik);
                        }}>
                        <CapsyncToolTip
                          Child={'delete-outlined'}
                          placement={'top'}
                          size="18"
                          message={'Delete'}
                          type="icon"
                        />
                      </span>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </Col>
        );
      } else if (field.fieldType === 'input-checkbox') {
        return (
          <Col lg={field.fieldSizeLG} sm={field.fieldSizeSM} key={index + 'field'}>
            <CheckBox
              name={field.field}
              label={field.fieldLabel}
              fieldPrimaryLabel={field.fieldPrimaryLabel}
              values={formik.values}
              disabled={field.disabled}
              errors={formik.errors}
              touched={formik.touched}
              handleChange={(e) => formik.handleChange(e)}
              additionalClass={field.additionalClass}
              {...field}
            />
          </Col>
        );
      } else if (field.fieldType === 'input-number') {
        return (
          <Col lg={field.fieldSizeLG} sm={field.fieldSizeSM} key={index + 'field'}>
            <InputNumberField
              label={field.fieldLabel}
              name={field.field}
              values={formik.values}
              handleChange={(e) => formik.handleChange(e)}
              errors={formik.errors}
              touched={formik.touched}
              placeholder={field.fieldPlaceholder}
              customActiveClass={'income-input'}
              maxLength={field.maxVal}
              disabled={field.disabled}
            />
          </Col>
        );
      }
    }
  });
};

// PROPS TYPE
FieldsHTML.propTypes = {
  fieldJSON: PropTypes.array,
  formik: PropTypes.func,
  uploadPopupOpen: PropTypes.func,
  setDropboxID: PropTypes.func,
  methodFormik: PropTypes.func,
  dropboxID: PropTypes.number
};

export default FieldsHTML;
