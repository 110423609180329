import React from 'react';
import PropTypes from 'prop-types';

// COMPONENT IMPORTS
import Modal from '../../../components/Modal';
import CapsyncIcon from '../../../components/CapsyncIcon';

/* ============================== WARNING MODAL ============================== */
const WarningModalVI = ({ showWarning, onClose, onButtonClick, loading }) => {
  return (
    <Modal
      show={showWarning}
      onHide={onClose}
      size="md"
      className={'cs-md-modal manage-files'}
      isCloseButton={false}
      title={'Warning'}
      body={
        <div className="delete-modal-content">
          <div className="warning-icon cs-warning">
            <CapsyncIcon title="error-validation-filled" size="120" />
          </div>
          <p className="cs-light-body-text-m cs-neutral-90">
            The Special Situation verification method is rarely applicable as it either requires
            that either the individual be notably wealthy, such as being on the Forbes Wealthiest
            individuals list, or for the company being verified to be a publicly-traded company. If
            you believe you meet these criteria, please contact accreditation@capsync.com for
            guidance, if not, then kindly please select another verification method.
          </p>
        </div>
      }
      cancelButtonLabel={'Change Method'}
      handleOnCancel={onClose}
      saveButtonLabel={'Select this Method'}
      handleOnSave={onButtonClick}
      loading={loading}
      modalFooterClass={'cs-center-btn'}
    />
  );
};

// PROPS TYPE
WarningModalVI.propTypes = {
  showWarning: PropTypes.bool,
  onClose: PropTypes.func,
  onButtonClick: PropTypes.func,
  loading: PropTypes.bool
};

export default WarningModalVI;
