import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

// COMPONENT IMPORTS
import Modal from './Modal';
import MFAOption from './MFASetup/MFAOptios';
import { MFA_OPTIONS_PFS_PASSWORD } from '../constants/system';

// API
import { useAccessUser } from '../slices/authSlice';

/* ============================== MFA OPTIONS MODAL ============================== */
const MfaOptionModal = (props) => {
  const {
    title,
    callback,
    selectedOption,
    modalState,
    subHeader,
    handleOnSave,
    handleOnCancel,
    isMySettings = false
  } = props;
  const { user } = useSelector((state) => state.auth);
  const accessUser = useAccessUser();

  const optionList = MFA_OPTIONS_PFS_PASSWORD?.filter((option) => {
    if (
      option.id === 'authenticator' &&
      (isMySettings
        ? accessUser?.loginUserDetails?.users_mfa_info?.mfa_google
        : user?.users_mfa_info?.mfa_google)
    ) {
      return true;
    } else if (
      option.id === 'mobile' &&
      (isMySettings
        ? accessUser?.loginUserDetails?.users_mfa_info?.mfa_phone
        : user?.users_mfa_info?.mfa_phone)
    ) {
      return true;
    } else if (option.id === 'email') {
      return true;
    }
    return false;
  });

  return (
    <Modal
      title={title}
      show={modalState}
      handleClose={handleOnCancel}
      body={
        <div className="auth-right">
          <div className="formArea">
            <div className="text-center">
              <p className="cs-neutral-80 cs-regular-h5">{subHeader}</p>
            </div>
            <div>
              {optionList.map((option, index) => (
                <MFAOption
                  key={index}
                  {...option}
                  callback={callback}
                  selectedType={selectedOption}
                  setSelectedType={callback}
                />
              ))}
            </div>
          </div>
        </div>
      }
      isCloseButton={false}
      disabled={!selectedOption}
      className="cs-md-modal"
      saveButtonLabel={'Next'}
      cancelButtonLabel={'Cancel'}
      handleOnSave={handleOnSave}
      handleOnCancel={handleOnCancel}
    />
  );
};

// PROPS TYPE
MfaOptionModal.propTypes = {
  title: PropTypes.string.isRequired,
  modalState: PropTypes.bool.isRequired,
  handleOnSave: PropTypes.func.isRequired,
  handleOnCancel: PropTypes.func.isRequired,
  selectedOption: PropTypes.string.isRequired,
  callback: PropTypes.func.isRequired,
  subHeader: PropTypes.string,
  isMySettings: PropTypes.bool
};

export default MfaOptionModal;
