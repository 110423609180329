import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Form, Row } from 'react-bootstrap';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import moment from 'moment';

// COMPONENT IMPORTS
import {
  loanTermType,
  preventNegativeNumbers,
  preventSpace,
  preventSpecialCharacters
} from '../../../utils/common';
import Modal from '../../../components/Modal';
import { TOAST_MESSAGE } from '../../../constants/message';
import InputBox from '../../../components/FormFields/InputBox';
import validationObj from '../../../validations/validationSchema';
import TextAreaBox from '../../../components/FormFields/TextAreaBox';
import MaturityDate from '../../../components/FormFields/MaturityDate';
import { handleOwnershipValue } from '../../../config/handleOwnership';
import DatePickerBox from '../../../components/FormFields/DatePickerBox';
import PhoneInputField from '../../../components/FormFields/PhoneInputField';
import DropdownInputBox from '../../../components/FormFields/DropdownInputBox';
import InputNumberField from '../../../components/FormFields/InputNumberField';
import SaveORCancelButtons from '../../../components/Buttons/SaveORCancelButtons';
import CategoryFileUpload from '../../../components/FormFields/CategoryFileUpload';
import AmortizationCheckbox from '../../../components/FormFields/AmortizationCheckbox';

// HOOKS IMPORTS
import usePayment from '../../../utils/Hooks/usePayment';
import useMaturityDate from '../../../utils/Hooks/useMaturityDate';

// API
import {
  categoryAddRecords,
  categoryUpdateRecords,
  getLiabilitiesCategory,
  useCategoryDetail
} from '../../../slices/categorySlice';
import { getLiabilitiesDashboard, getMainDashboard } from '../../../slices/dashboardSlice';
import { useProgressUploadDetails } from '../../../slices/commonSlice';
import { useStorageDetails } from '../../../slices/fileManagementSlice';

/* ============================== INSTALLMENT ACCOUNT OTHER ============================== */
const InstallmentAccountOther = ({
  isOpen = false,
  handleCancel,
  handleCategoryFormSubmit,
  record
}) => {
  const { user } = useSelector((state) => state.auth);
  const progressUploadDetails = useProgressUploadDetails();
  const storageDetails = useStorageDetails();
  const categories = useCategoryDetail();
  const categoryId = categories?.category_id;
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const [initialValues, setInitialValues] = useState({
    user_id: user?.id,
    creditor_name: '',
    creditor_email: '',
    creditor_phonenumber: '',
    loan_amount: '',
    date_loan: moment().format(),
    loan_period: '',
    frequency: loanTermType[0],
    annual_rate: '',
    maturity_date: '',
    payment: '',
    balance_due: '',
    date_updated: moment().format(),
    loan_type: '',
    collateral: '',
    notes: '',
    is_amortization_link_enable: false,
    attachments: [],
    is_manual: true
  });
  useEffect(() => {
    if (record) {
      setInitialValues({
        user_id: record.user_id || '',
        creditor_name: record.creditor_name || '',
        creditor_email: record.creditor_email || '',
        creditor_phonenumber: record.creditor_phonenumber || '',
        loan_amount: (record.loan_amount && record.loan_amount.toLocaleString('en-US')) || '',
        date_loan: record.date_Loan
          ? new Date(moment(record.date_Loan).format('MM/DD/YYYY'))
          : moment().format(),
        annual_rate: (record.annual_rate && record.annual_rate.toLocaleString('en-US')) || '',
        maturity_date: record.maturity_date || '',
        loan_period: (record.loan_period && record.loan_period.toLocaleString('en-US')) || '',
        frequency: record.frequency || '',
        collateral: record.collateral || '',
        balance_due: (record.balance_due && record.balance_due.toLocaleString('en-US')) || '',
        date_updated: record.date_updated
          ? new Date(moment(record.date_updated).format('MM/DD/YYYY'))
          : moment().format(),
        loan_type: record.loan_type || '',
        notes: record.notes == null ? '' : record.notes || '',
        is_manual: record.is_manual || '',
        is_amortization_link_enable: record.is_amortization_link_enable
      });
      // setAmortization(record.is_amortization_link_enable || false);
      // record.creditor_phonenumber && setPhoneNo(record.creditor_phonenumber);
    }
  }, [record]);
  const {
    values,
    handleSubmit,
    handleChange,
    setFieldValue,
    errors,
    touched,
    setFieldError,
    resetForm,
    submitCount
  } = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: validationObj.liabilityInstallmentOtherValidationSchema,
    onSubmit: async (values) => {
      const formData = new FormData();
      const uploadedData = storageDetails?.used_space;
      const inProgressData = progressUploadDetails.length
        ? progressUploadDetails.reduce((acc, item) => acc + item.size, 0)
        : 0;

      delete values.is_manual;
      const { attachments, ...dataObj } = values;
      !record && attachments.forEach((file) => formData.append('attachments', file));
      formData.append('data_obj', JSON.stringify(dataObj));
      formData.append('usedSpace', uploadedData + inProgressData);

      const data = {
        category: 3,
        mainCategory: 'liability',
        formData: formData
      };

      setLoading(true);
      if (!record) {
        dispatch(categoryAddRecords(data))
          .unwrap()
          .then(async (response) => {
            if (response.code === 500) {
              setFieldError('attachments', response?.message);
            }
            if (response.code === 200) {
              handleCategoryFormSubmit();
              dispatch(getMainDashboard(user?.id));
              const payload = { categoryId: categoryId, userId: user?.id };
              categories.parent === 'liabilities' &&
                (await dispatch(getLiabilitiesCategory(payload)).unwrap());
              await dispatch(getLiabilitiesDashboard({ userId: user?.id, orderId: 0 })).unwrap();
            }
          })
          .catch((err) => {})
          .finally(() => {
            setLoading(false);
          });
      } else {
        dispatch(
          categoryUpdateRecords({
            category: 3,
            mainCategory: 'liability',
            rowId: record && record?.id,
            values
          })
        )
          .unwrap()
          .then(async (response) => {
            if (response.code === 200) {
              handleCategoryFormSubmit();
              toast.success(TOAST_MESSAGE.RECORD_UPDATE_SUCCESS);
              const payload = { categoryId: categoryId, userId: user?.id };
              categories.parent === 'liabilities' &&
                (await dispatch(getLiabilitiesCategory(payload)).unwrap());
              await dispatch(getMainDashboard(user?.id)).unwrap();
            }
          })
          .catch((err) => {})
          .finally(() => {
            setLoading(false);
          });
      }
    }
  });

  useMaturityDate({ values, setFieldValue });

  usePayment({ values, setFieldValue });

  const body = (
    <Form onSubmit={handleSubmit} encType="multipart/form-data">
      <Row>
        <Col lg={12} sm={12}>
          <InputBox
            label={'Name of Creditor'}
            name={'creditor_name'}
            values={values}
            onChange={handleChange}
            errors={errors}
            touched={touched}
            type={'text'}
            onKeyDown={preventSpecialCharacters}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={6}>
          <InputBox
            label={'Email'}
            name={'creditor_email'}
            values={values}
            onChange={handleChange}
            errors={errors}
            touched={touched}
            optional={true}
            type={'email'}
            onKeyDown={preventSpace}
            placeholder="Optional "
          />
        </Col>
        <Col lg={6} md={6}>
          <PhoneInputField
            label={'Phone Number (Optional)'}
            placeholder={'Optional'}
            name={'creditor_phonenumber'}
            values={values}
            setFieldValue={setFieldValue}
            errors={errors}
            touched={touched}
            submitCount={submitCount}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={6}>
          <InputNumberField
            label="Loan Amount"
            name="loan_amount"
            values={values}
            handleChange={handleChange}
            errors={errors}
            touched={touched}
            customActiveClass={'dollar-symbol text-md-end form-control'}
            thousandSeparator={true}
          />
        </Col>
        <Col xs={12} md={6}>
          <DatePickerBox
            label={'Loan Date'}
            errors={errors}
            name={'date_loan'}
            values={values}
            onChange={handleChange}
            setFieldValue={setFieldValue}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={6}>
          <DropdownInputBox
            errors={errors}
            label={'Loan Term'}
            handleChange={handleChange}
            maxLength={3}
            name={'loan_period'}
            dropdownName={'frequency'}
            touched={touched}
            values={values}
            setFieldValue={setFieldValue}
          />
        </Col>
        <Col xs={12} md={6}>
          <InputNumberField
            label="Interest Rate (%)"
            name="annual_rate"
            values={values}
            handleChange={handleChange}
            errors={errors}
            touched={touched}
            customActiveClass={'percentage-symbol'}
            maxLength={5}
            onPaste={preventNegativeNumbers}
            onKeyDown={handleOwnershipValue}
          />
        </Col>
      </Row>

      <Row>
        <Col xs={12} md={6}>
          <MaturityDate values={values} setFieldValue={setFieldValue} errors={errors} />
        </Col>
        <Col xs={12} md={6}>
          <InputNumberField
            label="Payment"
            name="payment"
            values={values}
            errors={errors}
            handleChange={handleChange}
            touched={touched}
            customActiveClass="dollar-symbol text-md-end form-control"
            onPaste={preventNegativeNumbers}
            thousandSeparator={true}
            disabled={true}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={6}>
          <InputNumberField
            label="Balance Due"
            name="balance_due"
            values={values}
            errors={errors}
            handleChange={handleChange}
            touched={touched}
            customActiveClass="dollar-symbol text-md-end form-control"
            onPaste={preventNegativeNumbers}
            thousandSeparator={true}
          />
        </Col>
        <Col xs={12} md={6}>
          <DatePickerBox
            label={'Date Updated'}
            errors={errors}
            name={'date_updated'}
            values={values}
            setFieldValue={setFieldValue}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={6}>
          <InputBox
            label={'Type'}
            name={'loan_type'}
            onChange={handleChange}
            values={values}
            errors={errors}
            touched={touched}
            type={'text'}
            placeholder="Optional"
            onKeyDown={preventSpecialCharacters}
          />
        </Col>
        <Col xs={12} md={6}>
          <InputBox
            label={'Collateral'}
            name={'collateral'}
            values={values}
            onChange={handleChange}
            errors={errors}
            touched={touched}
            type={'text'}
            placeholder="Optional"
            onKeyDown={preventSpecialCharacters}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={12}>
          <AmortizationCheckbox
            isChecked={values['is_amortization_link_enable']}
            setIsChecked={(value) => setFieldValue('is_amortization_link_enable', value)}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={12}>
          <TextAreaBox
            handleChange={handleChange}
            name={'notes'}
            label={'Notes'}
            values={values}
            errors={errors}
            onChange={handleChange}
            placeholder={'Optional'}
          />
        </Col>
      </Row>
      {!record && (
        <CategoryFileUpload
          name={'attachments'}
          values={values}
          errors={errors}
          setFieldError={setFieldError}
          setFieldValue={setFieldValue}
        />
      )}

      <SaveORCancelButtons handleCancel={handleCancel} resetForm={resetForm} loading={loading} />
    </Form>
  );

  return (
    <Modal show={isOpen} title={'Installment Account (Other)'} body={body} isCloseButton={false} />
  );
};

// PROPS TYPE
InstallmentAccountOther.propTypes = {
  isOpen: PropTypes.bool,
  handleCancel: PropTypes.func.isRequired,
  handleCategoryFormSubmit: PropTypes.func,
  record: PropTypes.object
};

export default InstallmentAccountOther;
