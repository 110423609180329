import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Form, Row } from 'react-bootstrap';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';

// COMPONENT IMPORTS
import Modal from '../../../components/Modal';
import FieldsHTML from '../common/FieldsHTML';
import { generateString } from '../../../utils/common';
import { newRequestVerificationSchema } from '../../../validations/validations';

// API
import {
  getVerificationPaymentList,
  setLoader,
  setMethodName,
  setRowID,
  verificationRequestAdd
} from '../../../slices/accreditationSlice';

/* ============================== VERIFICATION MODAL ============================== */
const NewVerificationModal = (props) => {
  const { handleCloseModel, show } = props;

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { user } = useSelector((state) => state.auth);
  const { loader } = useSelector((state) => state.accreditation);

  const [recordsArray, setRecordsArray] = useState([]);

  const initialValues = {
    email: user && user.email,
    legal_name: '',
    verification_type: ''
  };

  const randomString = generateString(6);

  const getVerificationPayment = () => {
    dispatch(getVerificationPaymentList())
      .unwrap()
      .then((resp) => {
        if (resp.code === 200) {
          setRecordsArray(resp.data);
        } else {
          setRecordsArray([]);
        }
      })
      .catch((err) => {
        console.log('err', err);
        setRecordsArray([]);
      });
  };

  useEffect(() => {
    if (show) {
      getVerificationPayment();
    }

    return () => {
      setRecordsArray([]);
    };
  }, [show]);

  const fieldJSON = [
    {
      field: 'verification_type',
      fieldLabel: 'Choose Verification Type',
      disabled: loader && loader.addBtnLoader,
      fieldType: 'dropdown',
      options: recordsArray,
      fieldPlaceholder:
        window.innerWidth <= 575
          ? 'Select Accredited Investor, Qua...'
          : window.innerWidth <= 991
            ? 'Select Accredited Investor, Qualified Client, Q...'
            : 'Select Accredited Investor, Qualified Client, Qualified Purchaser',
      fieldSizeLG: 12,
      fieldSizeSM: 12,
      // fieldExtrLabel: '$',
      fieldExtrLabel: '',

      fieldOptionsLabelKey: 'title',
      fieldOptionValueKey: 'title',
      // fieldOptionExtraLabelKey: 'amount',
      fieldOptionExtraLabelKey: '',
      fieldVisiblity: true
    },
    {
      field: 'legal_name',
      fieldLabel: 'Legal name',
      disabled: loader && loader.addBtnLoader,
      fieldType: 'input-text',
      fieldPlaceholder: '',
      fieldSizeLG: 12,
      fieldSizeSM: 12,
      maxValue: '',
      minValue: '',
      fieldVisiblity: true
    },
    {
      field: 'email',
      fieldLabel: 'Email',
      disabled: true,
      fieldType: 'input-text',
      fieldPlaceholder: 'Enter Email',
      fieldSizeLG: 12,
      fieldSizeSM: 12,
      maxValue: '',
      minValue: '',
      fieldVisiblity: true
    },
    {
      field: 'reference',
      fieldLabel: 'Reference',
      disabled: loader && loader.addBtnLoader,
      fieldType: 'input-text',
      fieldPlaceholder: 'Optional',
      fieldSizeLG: 12,
      fieldSizeSM: 12,
      maxValue: '',
      minValue: '',
      fieldVisiblity: true
    }
  ];

  const handleSubmit = (values) => {
    dispatch(setLoader({ addBtnLoader: true }));
    values.user_id = user && user.id;
    values.unique_id = randomString;
    dispatch(verificationRequestAdd(values))
      .unwrap()
      .then((resp) => {
        if (resp.code === 200) {
          handleCloseModel('newRequestVerification');
          formik.resetForm(initialValues);
          if (values.verification_type === 'Accredited Investor') {
            dispatch(setMethodName('individuals'));
            dispatch(setRowID(randomString));
            navigate(`/verifyinvestor/verification-type`);
          } else if (values.verification_type === 'Qualified Purchaser') {
            dispatch(setMethodName('qualifiedpurchaser'));
            dispatch(setRowID(randomString));
            navigate(`/verifyinvestor/verification-type`);
          } else if (values.verification_type === 'Qualified Client') {
            dispatch(setMethodName('qualifiedclient'));
            dispatch(setRowID(randomString));
            navigate(`/verifyinvestor/verification-type`);
          }
        } else {
          toast.error(resp.message);
        }
        dispatch(setLoader({ addBtnLoader: false }));
      })
      .catch((err) => {
        console.log(err);
        dispatch(setLoader({ addBtnLoader: false }));
      });
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: newRequestVerificationSchema,
    onSubmit: (values, { setSubmitting }) => {
      handleSubmit(values, setSubmitting);
    }
  });

  return (
    <Modal
      title={'Request Verification'}
      show={show}
      className="cs-md-modal cs-new-user-modal"
      handleClose={() => {
        formik.resetForm(initialValues);
        handleCloseModel();
      }}
      parentClassName={' request-verification-modal'}
      body={
        <Form onSubmit={formik.handleSubmit}>
          <Row>
            <FieldsHTML fieldJSON={fieldJSON} formik={formik} />
          </Row>
        </Form>
      }
      saveButtonLabel={'Continue'}
      cancelButtonLabel={'Cancel'}
      handleOnSave={formik.handleSubmit}
      handleOnCancel={() => {
        formik.resetForm(initialValues);
        handleCloseModel();
      }}
      isCloseButton={false}
      disabled={!formik.isValid || !formik.dirty}
      loading={loader && loader.addBtnLoader}
    />
  );
};
NewVerificationModal.propTypes = {
  handleCloseModel: PropTypes.func,
  show: PropTypes.bool
};
export default NewVerificationModal;
