import fileDownload from 'js-file-download';

// COMPONENT IMPORTS
import axios from '../../config/api';

/* ============================== TRANSACTION PDF ============================== */
const useTransactionPdf = (customUrl, data, fileName, downloadButton) => {
  axios({
    url: customUrl,
    method: 'POST',
    data: data,
    responseType: 'blob'
  })
    .then((response) => {
      downloadButton();
      fileDownload(response, 'transaction.pdf');
    })
    .catch((err) => {
      console.log(err);
    });
};

export default useTransactionPdf;
