import React from 'react';
import PropTypes from 'prop-types';

/* ============================== CATEGORY TABLE DATA ============================== */
const CategoryTableData = ({ type, data }) => {
  // Render different types of data based on the specified type
  switch (type) {
    case 'string':
      return <span className="common-tooltip-margin">{data}</span>;
    case 'amortization_number':
      return <span>${data}</span>; // Assuming data is a number representing currency
    case 'number':
      return (
        <span>
          {data &&
            `$ ${data.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`}
        </span>
      ); // Assuming data is a number representing currency
    case 'pfsWorksheet':
      return (
        <span>
          {data !== null
            ? `$ ${data.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`
            : '$ 0.00'}
        </span>
      );
    default:
      return null;
  }
};

// PROPS TYPE
CategoryTableData.propTypes = {
  type: PropTypes.string.isRequired,
  data: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object, // If you expect any object types
    PropTypes.oneOf([null]) // To allow null if needed
  ])
};

export default CategoryTableData;
