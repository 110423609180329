import React from 'react';
import PropTypes from 'prop-types';
import { Dropdown, Form } from 'react-bootstrap';

// COMPONENT IMPORTS
import CapsyncIcon from '../CapsyncIcon';

/* ============================== FORM DROPDOWN ============================== */
const FormDropdown = ({ values, name, list, setFieldValue, label = '', disabled = false }) => {
  return (
    <Form.Group className={`cs-form-dropdown ${disabled && 'cs-disabled'}`}>
      <Form.Label>{label}</Form.Label>
      <div className="cs-common-add-dropdown">
        <Dropdown>
          <Dropdown.Toggle disabled={disabled}>
            <span className="cs-dropdown-select-line">{values[name]}</span>
            <span className="cs-neutral-90">
              <CapsyncIcon title="chevron-down-outlined" size="18" />
            </span>
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {list.map((item) => (
              <Dropdown.Item
                key={item}
                onClick={() => {
                  setFieldValue(name, item);
                }}
                className={values[name] === item ? 'dropdown-item-active' : false}>
                {item}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </Form.Group>
  );
};

// PROPS TYPE
FormDropdown.propTypes = {
  label: PropTypes.string,
  values: PropTypes.object,
  name: PropTypes.string,
  list: PropTypes.array,
  setFieldValue: PropTypes.func,
  disabled: PropTypes.bool
};

export default FormDropdown;
