import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

// COMPONENT IMPORTS
import axios from '../config/api';
import CONFIG from '../constants/config';
import axiosFormData from '../config/apiFormData';

// INITIAL STATE
const initialState = {
  status: null,
  data: { pending: [], completed: [], expired: [] },
  message: '',
  initMethodName: '',
  rowID: '',
  subMethod: 0,
  pfsLink: {
    link: '',
    dropBoxID: 0
  },
  investorType: '',
  modal: {},
  loader: {},
  attachments: []
};

export const signatureRequest = createAsyncThunk('accreditation/signatureRequest', async (data) => {
  return await axios.post(`accreditation/signature`, data);
});

// is_accreditation=true,
// signature_request_id = 'aasdasd'
export const getSignedPFSLink = createAsyncThunk('accreditation/getSignedPFSLink', async (data) => {
  return await axios.get(`sign/get_pfs`, {
    params: data
  });
});

export const getCategoryMediaList = createAsyncThunk(
  'accreditation/getCategoryMediaList',
  async (data) => {
    return await axios.get(`accreditation/fetch_media/${data}`);
  }
);

export const getRequestList = createAsyncThunk('accreditation/getRequestList', async (data) => {
  return await axios.get(`accreditation/listing`, {
    params: data
  });
});

export const getVerificationPaymentList = createAsyncThunk(
  'accreditation/getVerificationPaymentList',
  async (data) => {
    return await axios.get(`accreditation/payment_plans`, data);
  }
);

export const verificationRequestAdd = createAsyncThunk(
  'accreditation/verificationRequestAdd',
  async (data) => {
    return await axios.post(`accreditation/add_request`, data);
  }
);

export const verificationRequestDelete = createAsyncThunk(
  'accreditation/verificationRequestDelete',
  async (data) => {
    return await axios.delete(`accreditation/delete_request`, { data });
  }
);

export const getMethodDataByUniqueID = createAsyncThunk(
  'accreditation/getMethodDataByUniqueID',
  async (data) => {
    return await axios.get(`accreditation/find`, {
      params: data
    });
  }
);

export const updateInvestorType = createAsyncThunk(
  'accreditation/updateInvestorType',
  async (data) => {
    return await axios.put(`accreditation/investor_type`, data);
  }
);

export const updateMethodID = createAsyncThunk('accreditation/updateMethodID', async (data) => {
  return await axios.put(`accreditation/method_id`, data);
});

export const uploadMediaInLocal = createAsyncThunk(
  'accreditation/uploadMediaInLocal',
  async (data) => {
    return await axiosFormData.post(`accreditation/upload_media`, data);
  }
);

export const deleteMedia = createAsyncThunk('accreditation/deleteMedia', async (data) => {
  return await axios.delete(`accreditation/delete_media`, {
    params: data
  });
});

export const getMedia = createAsyncThunk('accreditation/getMedia', async (data) => {
  return await axios.get(`accreditation/get_uploaded_media`, {
    params: data
  });
});

export const deletePFS = createAsyncThunk('accreditation/deletePFS', async (data) => {
  return await axios.delete(`accreditation/signature/delete`, {
    params: data
  });
});

export const legalNameCheck = createAsyncThunk('accreditation/legalNameCheck', async (data) => {
  return await axios.post(`accreditation/check_legalname`, data);
});

// export const submitRequest = createAsyncThunk('accreditation/submitRequest', async (data) => {
//   return await axios.post(`accreditation/submit_request_without_pay`, data);
// });
export const newSubmitRequest = createAsyncThunk('accreditation/submitRequest', async (data) => {
  return await axios.post(`accreditation/submit`, data);
});

export const findForCertificate = createAsyncThunk(
  'accreditation/findForCertificate',
  async (data) => {
    return await axios.get(`accreditation/find`, {
      params: data
    });
  }
);

export const downloadApprovedCertificate = createAsyncThunk(
  'download/downloadApprovedCertificate',
  async (data) => {
    return await axios.get(
      `accreditation/download/approved_certificates/${data?.uid || 0}/${data?.response_id || 0}`
    );
  }
);

export const uploadRequestedMedia = createAsyncThunk(
  'download/uploadRequestedMedia',
  async (data) => {
    return await axiosFormData.post(`accreditation/upload_required_media`, data);
  }
);

/* ============================== ACCREDITATION SLICE ============================== */
export const accreditationSlice = createSlice({
  name: 'accreditationMethods',
  initialState,
  reducers: {
    resetAccreditationMethodsSlice: () => initialState,
    setMethodName(state, action) {
      state.initMethodName = action.payload;
    },
    setRowID(state, action) {
      state.rowID = action.payload;
    },
    setSubMethod(state, action) {
      state.subMethod = action.payload;
    },
    setPFSLink(state, action) {
      state.pfsLink = { ...state.pfsLink, ...action.payload };
    },
    handleModal(state, action) {
      state.modal = { ...state.modal, ...action.payload };
    },
    resetModal(state) {
      state.modal = {};
    },
    setLoader(state, action) {
      state.loader = { ...state.loader, ...action.payload };
    },
    resetLoader(state) {
      state.loader = {};
    },
    setAttachments(state, action) {
      state.attachments = action.payload;
    },
    setInvestorType(state, action) {
      state.investorType = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(signatureRequest.rejected, (state, action) => {
        const { status } = action.payload;
        state.status = !status ? CONFIG.STATUS.ERROR : null;
      })
      .addCase(getSignedPFSLink.fulfilled, (state, action) => {
        state.pfsLink.link = action.payload;
      })
      .addCase(getSignedPFSLink.rejected, (state, action) => {
        const { status } = action.payload;
        state.status = !status ? CONFIG.STATUS.ERROR : null;
      })
      .addCase(getCategoryMediaList.rejected, (state, action) => {
        const { status } = action.payload;
        state.status = !status ? CONFIG.STATUS.ERROR : null;
      })
      .addCase(getRequestList.fulfilled, (state, action) => {
        const response = action.payload;
        if (response.code === 200) {
          state.data.pending =
            response.data.filter((d) => d.request_status === 1 || d.request_status === 4) || [];
          state.data.completed =
            response.data.filter((d) => d.request_status === 2 || d.request_status === 3) || [];
          state.data.expired = response.data.filter((d) => d.request_status === 5) || [];
          state.message = response.message;
        } else if (response.code === 500) {
          state.message = response.message;
        } else {
          state.data = { pending: [], completed: [], expired: [] };
          state.message = response.message;
        }
      })
      .addCase(getRequestList.rejected, (state, action) => {
        const { status } = action.payload;
        state.status = !status ? CONFIG.STATUS.ERROR : null;
      })
      .addCase(getVerificationPaymentList.rejected, (state, action) => {
        const { status } = action.payload;
        state.status = !status ? CONFIG.STATUS.ERROR : null;
      })
      .addCase(verificationRequestAdd.rejected, (state, action) => {
        const { status } = action.payload;
        state.status = !status ? CONFIG.STATUS.ERROR : null;
      })
      .addCase(verificationRequestDelete.rejected, (state, action) => {
        const { status } = action.payload;
        state.status = !status ? CONFIG.STATUS.ERROR : null;
      })
      .addCase(getMethodDataByUniqueID.rejected, (state, action) => {
        const { status } = action.payload;
        state.status = !status ? CONFIG.STATUS.ERROR : null;
      })
      .addCase(updateInvestorType.rejected, (state, action) => {
        const { status } = action.payload;
        state.status = !status ? CONFIG.STATUS.ERROR : null;
      })
      .addCase(updateMethodID.rejected, (state, action) => {
        const { status } = action.payload;
        state.status = !status ? CONFIG.STATUS.ERROR : null;
      })
      .addCase(uploadMediaInLocal.rejected, (state, action) => {
        const { status } = action.payload;
        state.status = !status ? CONFIG.STATUS.ERROR : null;
      })
      .addCase(deleteMedia.rejected, (state, action) => {
        const { status } = action.payload;
        state.status = !status ? CONFIG.STATUS.ERROR : null;
      })
      .addCase(getMedia.rejected, (state, action) => {
        const { status } = action.payload;
        state.status = !status ? CONFIG.STATUS.ERROR : null;
      })
      .addCase(deletePFS.rejected, (state, action) => {
        const { status } = action.payload;
        state.status = !status ? CONFIG.STATUS.ERROR : null;
      })
      .addCase(legalNameCheck.rejected, (state, action) => {
        const { status } = action.payload;
        state.status = !status ? CONFIG.STATUS.ERROR : null;
      })
      .addCase(newSubmitRequest.rejected, (state, action) => {
        const { status } = action.payload;
        state.status = !status ? CONFIG.STATUS.ERROR : null;
      })
      .addCase(findForCertificate.rejected, (state, action) => {
        const { status } = action.payload;
        state.status = !status ? CONFIG.STATUS.ERROR : null;
      })
      .addCase(downloadApprovedCertificate.rejected, (state, action) => {
        const { status } = action.payload;
        state.status = !status ? CONFIG.STATUS.ERROR : null;
      })
      .addCase(uploadRequestedMedia.rejected, (state, action) => {
        const { status } = action.payload;
        state.status = !status ? CONFIG.STATUS.ERROR : null;
      });
  }
});

export const {
  setMethodName,
  setRowID,
  setSubMethod,
  setPFSLink,
  handleModal,
  resetModal,
  setLoader,
  resetLoader,
  setAttachments,
  setInvestorType,
  resetAccreditationMethodsSlice
} = accreditationSlice.actions;
export default accreditationSlice.reducer;
