import React, { useEffect, useState } from 'react';
import { Col, Row, Form, Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import OtpInput from 'react-otp-input';
import Cookies from 'universal-cookie';
import { useFormik } from 'formik';

// COMPONENT IMPORTS
import useRemoveServerError from '../components/hooks/useRemoveServerError';
import { MFA_TYPES, MFA_TYPE_DATA } from '../constants/system';
import { VALIDATION_MESSAGE } from '../constants/message';
import { deviceVerifyCookieStore } from '../utils/system';
import { handleSyncAccount } from '../slices/plaidSlice';
import { otpValidationSchema } from '../validations';
import CapsyncIcon from '../components/CapsyncIcon';
import AuthHead from './AuthHead';

// HOOKS IMPORTS
import useAuthRedirection from '../utils/Hooks/useAuthRedirection';

// API
import {
  generateMFAOTP,
  setUserAuthentication,
  useUserID,
  verifyMFAOTP
} from '../slices/authSlice';
import { clearMFA, setMFA } from '../slices/mfaSlice';

/* ============================== VERIFICATION PIN PAGE ============================== */
const VerificationPin = (props) => {
  useRemoveServerError();
  useAuthRedirection();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const userID = useUserID();
  const { user, mfa } = useSelector((state) => state.auth);

  const cookies = new Cookies();

  const [deviceVerify, setDeviceVerify] = useState(false);
  const [data, setData] = useState({ title: '', description: '', hasReceiverDetails: false });
  const [showResend, setShowResend] = useState(false);
  const [errMsg, setErrMsg] = useState(null);

  const forEmailOrPhone = mfa && (mfa?.type === MFA_TYPES.EMAIL || mfa?.type === MFA_TYPES.PHONE);

  useEffect(() => {
    mfa && mfa?.type && setData(MFA_TYPE_DATA.get(mfa?.type));
    if (mfa && mfa?.code === 200) {
      dispatch(setUserAuthentication(true));
      resetForm();
      dispatch(handleSyncAccount({ user_id: userID }));
      navigate('/dashboard');
      dispatch(clearMFA());
      dispatch(setMFA({ verified: true }));
    } else {
      const message = (mfa && mfa?.message) || VALIDATION_MESSAGE.INTERNAL_SERVER_ERROR;
      setFieldError('otp', message);
    }
  }, [mfa && mfa]);

  const initialOTPValue = {
    otp: ''
  };

  const handleOTPChange = (value) => {
    setFieldValue('otp', value);
    setShowResend(false);
  };

  const { handleSubmit, values, setFieldValue, errors, submitCount, setFieldError, resetForm } =
    useFormik({
      initialValues: initialOTPValue,
      validationSchema: otpValidationSchema,
      onSubmit: (values) => {
        setErrMsg(null);
        setShowResend(false);
        const otp = values.otp;
        deviceVerifyCookieStore(userID, deviceVerify);
        const payload = {
          user_id: user.id
        };
        if (!forEmailOrPhone) {
          payload.google_otp = otp;
          dispatch(verifyMFAOTP({ mfaType: MFA_TYPES.AUTHENTICATOR, payload }));
        } else if (mfa?.type === MFA_TYPES.EMAIL) {
          payload.email_otp = otp;
          dispatch(verifyMFAOTP({ mfaType: MFA_TYPES.EMAIL, payload }));
        } else if (mfa?.type === MFA_TYPES.PHONE) {
          payload.phone_otp = otp;
          dispatch(verifyMFAOTP({ mfaType: MFA_TYPES.PHONE, payload }));
        }
      }
    });

  const handleResend = async () => {
    try {
      setShowResend(false);
      setErrMsg(null);
      setFieldError('otp', null);
      const response = await dispatch(
        generateMFAOTP({ mfaType: MFA_TYPES.EMAIL, payload: { user_id: user && user.id } })
      ).unwrap();
      if (response && response.status) {
        setShowResend(true);
        resetForm();
      } else {
        resetForm();
        setShowResend(false);
        setErrMsg(response?.message || 'Something went wrong.');
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleRemember = (e) => {
    if (e.target.checked) {
      setDeviceVerify(e.target.checked);
    } else {
      setDeviceVerify(false);
      cookies.remove(`deviceAuth24Hr-${userID}`);
    }
  };

  return (
    <React.Fragment>
      <AuthHead
        title={data.title}
        description={
          <React.Fragment>
            {data.description}{' '}
            {data.hasReceiverDetails && <b className="cs-primary">{user && user.email}</b>}
          </React.Fragment>
        }
      />
      <div className="cs-auth-form">
        <div className="cs-otp">
          <Form onSubmit={handleSubmit}>
            <Row>
              <Col lg={12}>
                <OtpInput
                  value={values.otp}
                  onChange={handleOTPChange}
                  numInputs={6}
                  inputStyle="otp-txt"
                  containerStyle="otp-layout"
                  name="otp"
                  renderInput={(props) => <input {...props} />}
                />
                {submitCount > 0 && errors.otp && (
                  <span className="cs-light-body-text-m cs-danger">{errors.otp}</span>
                )}

                {forEmailOrPhone && (
                  <div className="cs-regular-h5">
                    <span className="auth-text-color-resend">
                      {' '}
                      Didn't receive the code? <Link onClick={handleResend}>Resend</Link>{' '}
                    </span>
                  </div>
                )}
              </Col>
              <Col lg={12}>
                <div className="auth-footer">
                  <Button type="submit" className="cs-btn-primary lg-btn cs-regular-h5 cs-ver-btn">
                    Verify account
                  </Button>
                  {showResend && forEmailOrPhone && (
                    <div className="cs-msg cs-regular-sub-heading-s cs-success">
                      <span className="icon cs-success">
                        <CapsyncIcon title="verification-outlined" size="18" />
                      </span>
                      <span className="txt">
                        We just sent you an email. Enter the 6-digit code.
                      </span>
                    </div>
                  )}
                  {errMsg && forEmailOrPhone && (
                    <div className="cs-msg cs-regular-sub-heading-s cs-danger">
                      <span className="icon">
                        <CapsyncIcon title="info-filled" />
                      </span>
                      <span className="txt">{errMsg}</span>
                    </div>
                  )}
                  <div>
                    <Form.Check
                      type={'checkbox'}
                      id={`default-checkbox`}
                      feedbackType="invalid"
                      className="cs-form-check-box"
                      checked={deviceVerify}
                      onChange={handleRemember}
                      aria-label="option 1"
                      name="terms"
                      label={
                        <span className="cs-light-body-text-m cursor-pointer d-flex">
                          Trust this device for 180 days
                        </span>
                      }
                    />
                  </div>

                  {/* {!forEmailOrPhone && (
                    <span className="cs-light-body-text-m">
                      MFA code can be located in your Authenticator app installed on your
                      smartphone.
                    </span>
                  )} */}
                </div>
              </Col>
            </Row>
          </Form>
        </div>
      </div>
    </React.Fragment>
  );
};

export default VerificationPin;
