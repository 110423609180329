import React from 'react';
import { Button, Image, Table } from 'react-bootstrap';
import NumberFormat from 'react-currency-format';
import PropTypes from 'prop-types';

// COMPONENT IMPORTS
import Modal from '../../../components/Modal';
import CapsyncIcon from '../../../components/CapsyncIcon';
import { plaidListingSequence } from '../../../constants/config';

// API
import { useInstituteData } from '../../../slices/plaidSlice';
import { useBrandDetails } from '../../../slices/brandingSlice';

/* ============================== ACCOUNT LIST MODAL ============================== */
const AccountListModal = ({
  isOpen,
  handleCategoryFormSubmit,
  setAddAccountStep,
  setIsAccountModelOpen,
  accounts
}) => {
  const brandDetails = useBrandDetails();
  const instituteData = useInstituteData();

  const currencyFormat = (num) => {
    if (num > 0) {
      return (
        '$' +
        Number(num)
          .toFixed(2)
          .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
      );
    } else {
      return (
        '-$' +
        Number(Math.abs(num))
          .toFixed(2)
          .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
      );
    }
  };

  const getAccountName = (accountItem) => {
    if (accountItem.data.categoryId) {
      switch (accountItem.data.categoryId) {
        case 1:
          return {
            accountName: accountItem.data.dataValues.creditor_name,
            balance: accountItem.data.dataValues.balance_due
          };

        case 2:
          return {
            accountName: accountItem.data.dataValues.creditor_name,
            balance: accountItem.data.dataValues.balance_due
          };

        case 5:
          return {
            accountName: accountItem.data.dataValues.creditor_name,
            balance: accountItem.data.dataValues.balance_due
          };

        case 6:
          return {
            accountName: accountItem.data.dataValues.description,
            balance: accountItem.data.dataValues.balance_due
          };

        case 19:
          return {
            accountName: accountItem.data.dataValues.creditor_name,
            balance: accountItem.data.dataValues.balance_due
          };

        case 8:
          return {
            accountName: accountItem.data.dataValues.institution,
            balance: accountItem.data.dataValues.balance
          };

        case 9:
          return {
            accountName: accountItem.data.dataValues.institution,
            balance: accountItem.data.dataValues.balance
          };

        case 10:
          return {
            accountName: accountItem.data.dataValues.institution,
            balance: accountItem.data.dataValues.balance
          };

        case 11:
          return {
            accountName: accountItem.data.dataValues.property_name,
            balance: accountItem.data.dataValues.balance
          };

        case 13:
          return {
            accountName: accountItem.data.dataValues.institution,
            balance: accountItem.data.dataValues.balance
          };

        default:
          return { accountName: 'N/A', balance: 0 };
      }
    } else {
      return { accountName: 'N/A', balance: 0 };
    }
  };

  const tableOfAccounts = (accountsdata, categoryNamesAndType) => {
    const tables = {};
    const tableData = {};

    accountsdata.forEach((item) => {
      const title = item.name.category_name;
      if (!tables[title]) {
        tables[title] = [];
      }
      tables[title].push(item);
    });

    categoryNamesAndType.forEach((itr) => {
      for (const key in tables) {
        if (itr.category_name == key) {
          tableData[key] = tables[key];
        }
      }
    });

    return Object.keys(tableData).map((tablesData, index) => {
      return (
        <Table responsive className="cs-table" key={'table' + index}>
          <thead className="cs-thead">
            <tr>
              <th className="cs-medium-sub-heading-xs cs-neutral-90" colSpan="3">
                {tablesData}
              </th>
            </tr>
          </thead>
          <tbody className="cs-tbody cs-regular-sub-heading-xs">
            {tables[tablesData] &&
              tables[tablesData].map((accountData, index) => {
                return (
                  <tr key={index}>
                    <td>
                      <div className="plaid-text">
                        {getAccountName(accountData).accountName}
                        {accountData.data.is_duplicate === true && (
                          <span className="plaid-icon cs-success">
                            <CapsyncIcon title="check-outlined" size="22" />
                          </span>
                        )}
                      </div>
                    </td>
                    <td>
                      <div className="dollar-value-gap">
                        <span> $ &nbsp;</span>
                        <NumberFormat
                          value={currencyFormat(getAccountName(accountData).balance)}
                          displayType={'text'}
                          thousandSeparator={true}
                        />
                      </div>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </Table>
      );
    });
  };

  return (
    <Modal
      show={isOpen}
      title="Add New Account"
      isCloseButton={false}
      handleClose={() => {
        setAddAccountStep('');
        setIsAccountModelOpen(false);
      }}
      className="cs-s-modal"
      body={
        <div className={`text-center plaid-callApi-prog-bar`}>
          <div className="plaid-head">
            <div className="log-out-popup">
              <div className="cs-bank-images">
                <div className="cs-common-logo">
                  <Image
                    src={
                      (brandDetails && brandDetails.small_logo_s3_url) ||
                      require('../../../assets/images/brand/cap-icon-logo.svg').default
                    }
                    alt="loadergif"
                    className="plaid-img-bank plaid-logo-circle"
                    width={'100'}
                  />
                </div>
                <Image
                  src={require('../../../assets/images/other/plaid-sync.svg').default}
                  alt="loadergif"
                  className="plaid-arrow-width"
                />
                <div className="cs-common-logo">
                  {instituteData?.ins_id && (
                    <Image
                      src={require(
                        `../../../assets/images/institution_logo/${instituteData.ins_logo}`
                      )}
                      alt="loadergif"
                      className="plaid-arrow-width"
                    />
                  )}
                </div>
              </div>
              <h3 className="cs-bank-text cs-semi-bold-h4 cs-neutral-100">{`You have successfully linked your ${instituteData.ins_name} account(s)`}</h3>
            </div>
            <div className="cs-accoutns-details cs-scroller">
              {accounts.filter((d) => d.name.type === 'Assets').length > 0 && (
                <div className="cs-accoutns-list assets-accoutns-list">
                  <div className="finance-data-container">
                    <span className="finance-heading heading-asset cs-medium-sub-heading-m cs-neutral-100">
                      Assets
                    </span>
                  </div>
                  {tableOfAccounts(
                    accounts.filter((d) => d.name.type === 'Assets'),
                    plaidListingSequence.filter((x) => x.type === 'Assets')
                  )}
                </div>
              )}
              {accounts.filter((d) => d.name.type === 'Liability').length > 0 && (
                <div className="cs-accoutns-list assets-accoutns-list">
                  <div className="finance-data-container">
                    <span className="finance-heading heading-liability cs-medium-sub-heading-m cs-neutral-100">
                      Liabilities
                    </span>
                  </div>
                  {tableOfAccounts(
                    accounts.filter((d) => d.name.type === 'Liability'),
                    plaidListingSequence.filter((x) => x.type === 'Liability')
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      }
      modalFooterClass={'plain-footer'}
      footerWithTag={
        <div className="cs-modal-btn">
          <div className="plaid-text cs-regular-sub-heading-s cs-neutral-100">
            <span className="plaid-icon cs-success">
              <CapsyncIcon title="check-outlined" size="18" />
            </span>
            Account(s) linked to CapSync
          </div>
          <Button
            onClick={handleCategoryFormSubmit}
            className="cs-btn-secondary md-btn cs-regular-h5">
            Continue
          </Button>
        </div>
      }
    />
  );
};

// PROPS TYPE
AccountListModal.propTypes = {
  isOpen: PropTypes.bool,
  handleCategoryFormSubmit: PropTypes.func,
  setAddAccountStep: PropTypes.func,
  setIsAccountModelOpen: PropTypes.func,
  accounts: PropTypes.array
};

export default AccountListModal;
