// import React from 'react';
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { Col, Form, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useOutletContext } from 'react-router-dom';

// COMPONENT IMPORTS
import { MFA_TYPES } from '../../../constants/system';
import { TOAST_MESSAGE } from '../../../constants/message';
import MFASetupCard from '../../../components/MFASetupCard';
import SkeletonMfa from '../../components/Skeleton/SkeletonMfa';
import GoogleQRModal from '../../../components/MFASetup/GoogleQRModal';
import PhoneInputModal from '../../../components/MFASetup/PhoneInputModal';
import MFAVerificationModal from '../../../components/MFASetup/MFAVerificationModal';

// API
import {
  generateMFAOTP,
  getLoginUserDetails,
  getUserDetails,
  resetMFA,
  useAccessUser
} from '../../../slices/authSlice';

/* ============================== MFA SETUP PAGE ============================== */
const MFASetup = () => {
  const dispatch = useDispatch();

  const { user } = useSelector((state) => state.auth);
  const accessUser = useAccessUser();
  const userId = user?.id;

  const { pathname } = useLocation();
  const isMySettings = pathname?.split('/')?.[1] === 'my-settings';

  const [showPhoneInputModal, setShowPhoneInputModal] = useState(false);
  const [showGooglQRModal, setShowGooglQRModal] = useState(false);
  const [showGooglePinModal, setShowGooglePinModal] = useState(false);
  const [showPhonePinModal, setShowPhonePinModal] = useState(false);

  const googleAlreadySetup = isMySettings
    ? accessUser?.loginUserDetails?.users_mfa_info.mfa_google
    : user.users_mfa_info.mfa_google;

  const phoneAlreadySetup = isMySettings
    ? accessUser?.loginUserDetails?.users_mfa_info.mfa_phone
    : user?.users_mfa_info?.mfa_phone;

  const handleAuthenticatorButtonClick = async () => {
    if (googleAlreadySetup) {
      const payload = { user_id: isMySettings ? accessUser?.loginUserDetails?.id : userId };
      await dispatch(resetMFA({ mfaType: MFA_TYPES.AUTHENTICATOR, payload })).then(() => {
        if (isMySettings) {
          dispatch(getLoginUserDetails(accessUser?.loginUserDetails?.id));
        } else {
          dispatch(getUserDetails(userId));
        }
        toast.success(TOAST_MESSAGE.MULTI_FACTOR_AUTHENTICATION_UPDATE_SUCCESS);
      });
    } else {
      setShowGooglQRModal(true);
    }
  };

  const handleMobileAuthButtonClick = async () => {
    if (phoneAlreadySetup) {
      const payload = { user_id: isMySettings ? accessUser?.loginUserDetails?.id : userId };
      dispatch(resetMFA({ mfaType: MFA_TYPES.PHONE, payload })).then(() => {
        if (isMySettings) {
          dispatch(getLoginUserDetails(accessUser?.loginUserDetails?.id));
        } else {
          dispatch(getUserDetails(userId));
        }
        toast.success(TOAST_MESSAGE.MULTI_FACTOR_AUTHENTICATION_UPDATE_SUCCESS);
      });
    } else {
      setShowPhoneInputModal(true);
    }
  };

  const handleGetCodeClick = async () => {
    dispatch(
      generateMFAOTP({
        mfaType: MFA_TYPES.PHONE,
        payload: { user_id: isMySettings ? accessUser?.loginUserDetails?.id : userId }
      })
    )
      .unwrap()
      .then(() => {
        setShowPhoneInputModal(false);
        setShowPhonePinModal(true);
      });
  };

  const handlePhoneMfaVerificationModalClose = () => {
    setShowPhonePinModal(false);
  };

  const handleGoogleMfaVerificationModalClose = () => {
    setShowGooglePinModal(false);
    if (isMySettings) {
      dispatch(getLoginUserDetails(accessUser?.loginUserDetails?.id));
    } else {
      dispatch(getUserDetails(userId));
    }
  };
  const [isLoader] = useOutletContext();

  return (
    <React.Fragment>
      <PhoneInputModal
        show={showPhoneInputModal}
        setShow={setShowPhoneInputModal}
        handleGetCodeClick={handleGetCodeClick}
      />
      <GoogleQRModal
        show={showGooglQRModal}
        setShow={setShowGooglQRModal}
        setShowGooglePinModal={setShowGooglePinModal}
        isMySettings={isMySettings}
      />
      <MFAVerificationModal
        modalState={showGooglePinModal}
        mfaType={MFA_TYPES.AUTHENTICATOR}
        handleModalClose={handleGoogleMfaVerificationModalClose}
        isMySettings={isMySettings}
      />
      <MFAVerificationModal
        modalState={showPhonePinModal}
        mfaType={MFA_TYPES.PHONE}
        handleModalClose={handlePhoneMfaVerificationModalClose}
        isMySettings={isMySettings}
      />
      <div className="cs-setting-page cs-mfa-setup-page">
        <div className="mfa-setup-page">
          <Form>
            <Row>
              <Col lg={8}>
                <div className="mfa-cards">
                  {isLoader ? (
                    <React.Fragment>
                      <SkeletonMfa /> <SkeletonMfa />
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <MFASetupCard
                        icon={'mobile-outlined'}
                        title={'Mobile verification'}
                        description={'Send a code sent to your phone via SMS'}
                        buttonName={phoneAlreadySetup ? 'Reset' : 'Setup'}
                        isButtonPrimary={!phoneAlreadySetup}
                        key={'1'}
                        onButtonClick={handleMobileAuthButtonClick}
                      />
                      <MFASetupCard
                        icon={'qr-code-outlined'}
                        title={'Authenticator app'}
                        description={'Connect your authenticator app'}
                        buttonName={googleAlreadySetup ? 'Reset' : 'Setup'}
                        isButtonPrimary={!googleAlreadySetup}
                        key={'2'}
                        onButtonClick={handleAuthenticatorButtonClick}
                      />
                    </React.Fragment>
                  )}
                </div>
              </Col>
            </Row>
          </Form>
        </div>
      </div>
    </React.Fragment>
  );
};

export default MFASetup;
