export const fileUploadJSON = (pfsLink, dropboxid, loader) => {
  return [
    {
      field: 'pfs_link',
      fieldType: 'input-with-button',
      fieldPlaceholder: 'Attach PFS',
      fieldSizeLG: 12,
      fieldSizeSM: 12,
      disabled: loader,
      fieldVisiblity: false,
      fieldVisibilityCondition:
        !pfsLink ||
          (pfsLink &&
            (!pfsLink.dropBoxID || pfsLink.dropBoxID === 0 || pfsLink.dropBoxID === dropboxid))
          ? true
          : false
    },
    {
      fieldType: 'and-divider',
      fieldSizeSM: 12,
      fieldVisiblity: false,
      fieldVisibilityCondition:
        !pfsLink ||
          (pfsLink &&
            (!pfsLink.dropBoxID || pfsLink.dropBoxID === 0 || pfsLink.dropBoxID === dropboxid))
          ? true
          : false
    },
    {
      field: 'data_obj',
      fieldType: 'dropdown-modal',
      fieldPlaceholder: 'Select Attached documents',
      fieldSizeLG: 12,
      fieldSizeSM: 12,
      disabled: loader,
      fieldVisiblity: true
    },
    {
      fieldType: 'or-divider',
      fieldSizeSM: 12,
      fieldVisiblity: true
    },
    {
      field: 'attachments',
      fieldType: 'input-uploadfile',
      fieldPrimaryLabel: 'Upload Files',
      fieldPlaceholder: 'Select Attached documents',
      fieldSizeLG: 12,
      fieldSizeSM: 12,
      disabled: loader,
      fieldVisiblity: true,
      showFilesList: true
    }
  ];
};

export const additionalDocumentJSON = (loader) => {
  return [
    {
      field: 'data_obj',
      fieldType: 'dropdown-modal',
      fieldPlaceholder: 'Select Attached documents',
      fieldSizeLG: 12,
      fieldSizeSM: 12,
      disabled: loader,
      fieldVisiblity: true
    },
    {
      fieldType: 'or-divider',
      fieldSizeSM: 12,
      fieldVisiblity: true
    },
    {
      field: 'attachments',
      fieldType: 'input-uploadfile-with-button',
      fieldLabel: 'Choose File',
      fieldPlaceholder: 'Choose File',
      fieldSizeLG: 12,
      fieldSizeSM: 12,
      disabled: loader,
      fieldVisiblity: true,
      showFilesList: true,
      multiple: true
    },
    {
      field: 'notes',
      fieldType: 'input-textarea',
      fieldLabel: 'Notes',
      fieldPlaceholder: 'Optional',
      fieldSizeLG: 12,
      fieldSizeSM: 12,
      disabled: loader,
      fieldVisiblity: true
    }
  ];
};
