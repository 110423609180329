import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { Col, Container, Row } from 'react-bootstrap';

// COMPONENT IMPORTS
import TabCardComponent from '../components/Array/TabCardComponent';
import CreateArrayUser from '../components/Array/CreateArrayUser';
import CapsyncLoader from '../../components/CapsyncLoader';

// API
import { useUser } from '../../slices/authSlice';
import {
  getArrayDetails,
  setCmLoadedScript,
  updateArrayData,
  updateArrayToken,
  useArrayDetails,
  useArrayId,
  useCmLoadedScript
} from '../../slices/arraySlice';

/* ============================== CREDIT MANAGER ============================== */
const CreditManager = () => {
  const user = useUser();
  const arrayDetails = useArrayDetails();
  const isCmLoadedScript = useCmLoadedScript();
  const arrayId = useArrayId();
  const dispatch = useDispatch();

  /* USE STATES */
  const [previewTabName, setPreviewTabName] = useState('credit-report');
  const [alertCount, setAlertCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [reFetchToken, setReFetchToken] = useState(false);

  useEffect(() => {
    window.addEventListener('array-event', function arrayEvent(arrayEvent) {
      const { tagName, event, metadata } = arrayEvent.detail;
      if (tagName === 'array-authentication-kba' && event === 'success') {
        dispatch(
          updateArrayData({
            array_id: arrayDetails.array_id,
            array_token: metadata['user-token'] || ''
          })
        );
      } else if (event === 'logout') {
        setLoading(true);
        setReFetchToken(true);
      }
    });
  }, []);

  useEffect(() => {
    if (reFetchToken) {
      dispatch(updateArrayData({ array_id: arrayDetails.array_id, array_token: null }));
      setLoading(false);
    }
  }, [reFetchToken]);

  useEffect(() => {
    if (!arrayDetails.array_token) {
      return;
    }
    const res = dispatch(
      updateArrayToken({
        user_id: user.id,
        array_token: arrayDetails.array_token
      })
    ).unwrap();
    if (res && res.code === 200) {
      dispatch(updateArrayData({ array_id: res.data.array_id, array_token: res.data.array_token }));
    }
  }, [arrayDetails]);

  const getAlertCount = async () => {
    try {
      const res = await axios.get(process.env.REACT_APP_ARRAY_ALERT_URL, {
        params: {
          userId: arrayDetails.array_id,
          count: 100,
          offset: 0,
          bureau: 'exp'
        },
        headers: { 'x-array-user-token': arrayDetails.array_token }
      });

      if (res && res.data) {
        setAlertCount(res.data.userAlerts.length || 0);
      }
    } catch (error) {
      console.log('error', error.message);
    }
  };

  useEffect(() => {
    if (arrayDetails.array_id && arrayDetails.array_token) {
      getAlertCount();
    }
  }, [arrayDetails]);

  useEffect(() => {
    if (loading || !arrayId) {
      return;
    }
    const crdElem = document.getElementById('wlp-credit-manager-details');
    if (!crdElem) {
      return;
    }
    const loadScript = (src) => {
      const script = document.createElement('script');
      script.src = src;
      crdElem.appendChild(script);
      return script;
    };
    if (!arrayDetails.array_token) {
      loadScript(
        `https://embed.array.io/cms/array-web-component.js?appKey=${process.env.REACT_APP_ARRAY_KEY}`
      );
      loadScript(
        `https://embed.array.io/cms/array-authentication-kba.js?appKey=${process.env.REACT_APP_ARRAY_KEY}`
      );
      dispatch(setCmLoadedScript(null));
    } else if (!isCmLoadedScript.includes(previewTabName)) {
      loadScript(
        `https://embed.array.io/cms/array-web-component.js?appKey=${process.env.REACT_APP_ARRAY_KEY}`
      );
      loadScript(
        `https://embed.array.io/cms/array-${previewTabName}.js?appKey=${process.env.REACT_APP_ARRAY_KEY}`
      );
      dispatch(setCmLoadedScript(previewTabName));
    }
  }, [previewTabName, arrayDetails, loading]);

  const tabArray = [
    {
      name: 'credit-report',
      title: 'Credit Report',
      description: 'Review your accounts',
      icon: 'credit-report-outlined'
    },
    {
      name: 'credit-score-factors',
      title: 'Score Factor',
      description: 'Understand your score',
      icon: 'credit-score-factors-outlined'
    },
    {
      name: 'credit-alerts',
      title: 'Credit Alert',
      description: `${alertCount} Alerts found`,
      icon: 'credit-alerts-outlined'
    },
    {
      name: 'credit-score-simulator',
      title: 'Score Simulator',
      description: 'Unlock your potential',
      icon: 'credit-score-simulator-outlined'
    },
    {
      name: 'credit-debt-analysis',
      title: 'Debt Analysis',
      description: 'Debt-to-income',
      icon: 'credit-debt-analysis-outlined'
    }
  ];

  const fetchArrayDetails = async () => {
    try {
      setLoading(true);
      await dispatch(getArrayDetails(user.id)).unwrap();
      setLoading(false);
    } catch (error) {
      console.log('error', error.message);
    }
  };

  useEffect(() => {
    fetchArrayDetails();
  }, []);

  const getSelectedTabName = () => {
    const tabName = tabArray.filter((val) => val.name === previewTabName);
    return tabName.length ? tabName[0].title : '';
  };

  const renderReport = (report_name) => {
    switch (report_name) {
      case 'credit-report':
        return (
          <array-credit-report
            appKey={process.env.REACT_APP_ARRAY_KEY}
            userToken={arrayDetails.array_token}
            sandbox={process.env.REACT_APP_MODE !== 'PROD'}
            defaultBureau="exp"
            hideBureauToggle="true"
            hidePageHeader="true"
            showLoadingMessage="true"
            creditreportlink="/credit-manager"
            identityprotectlink="/identity-protect"
            // hidePDFDownload="true"
          />
        );

      case 'credit-score-factors':
        return (
          <array-credit-score-factors
            appKey={process.env.REACT_APP_ARRAY_KEY}
            userToken={arrayDetails.array_token}
            sandbox={process.env.REACT_APP_MODE !== 'PROD'}
            defaultBureau="exp"
            hideBureauToggle="true"
            showLoadingMessage="true"
            creditreportlink="/credit-manager"
            identityprotectlink="/identity-protect"
          />
        );

      case 'credit-alerts':
        return (
          <array-credit-alerts
            appKey={process.env.REACT_APP_ARRAY_KEY}
            userToken={arrayDetails.array_token}
            sandbox={process.env.REACT_APP_MODE !== 'PROD'}
            bureau="exp"
            defaultBureau="exp"
            hideBureauToggle="true"
            showLoadingMessage="true"
            creditreportlink="/credit-manager"
            identityprotectlink="/identity-protect"
          />
        );

      case 'credit-score-simulator':
        return (
          <array-credit-score-simulator
            appKey={process.env.REACT_APP_ARRAY_KEY}
            userToken={arrayDetails.array_token}
            sandbox={process.env.REACT_APP_MODE !== 'PROD'}
            showLoadingMessage="true"
            creditreportlink="/credit-manager"
            identityprotectlink="/identity-protect"
          />
        );

      case 'credit-debt-analysis':
        return (
          <array-credit-debt-analysis
            appKey={process.env.REACT_APP_ARRAY_KEY}
            userToken={arrayDetails.array_token}
            sandbox={process.env.REACT_APP_MODE !== 'PROD'}
            showLoadingMessage="true"
            creditreportlink="/credit-manager"
            identityprotectlink="/identity-protect"
            hideBureauToggle="true"
          />
        );
      default:
        return;
    }
  };

  return (
    <section
      className="individual-main-section main-section credit-manager-details-main-section"
      id="wlp-credit-manager-details">
      <Container fluid>
        <Row>
          <Col lg={12}>
            {loading ? (
              <CapsyncLoader />
            ) : !arrayDetails.array_id && !arrayDetails.array_token ? (
              <CreateArrayUser />
            ) : !arrayDetails.array_token && arrayDetails.array_id ? (
              <div className="container-fluid">
                <array-authentication-kba
                  appKey={process.env.REACT_APP_ARRAY_KEY}
                  sandbox={process.env.REACT_APP_MODE !== 'PROD'}
                  userId={arrayDetails.array_id}
                  showResultPages="false"
                />
              </div>
            ) : (
              <div className="container-fluid">
                <div className="cs-section-topbar">
                  <div className="cs-title">
                    <h1 className="cs-semi-bold-h1 cs-neutral-100">{getSelectedTabName()}</h1>
                  </div>
                </div>
                <div className="cs-grid cs-array-row">
                  {tabArray.map((val, idx) => {
                    return (
                      <div className="category-card cs-qbo-card" key={idx}>
                        <TabCardComponent
                          onClick={() => {
                            setPreviewTabName(val.name);
                          }}
                          className={
                            val.name === previewTabName
                              ? 'cs-array-card card active-card'
                              : 'card cs-array-card'
                          }
                          description={val.description}
                          title={val.title}
                          img={val.icon}
                          selected={val.name === previewTabName}
                        />
                      </div>
                    );
                  })}
                </div>
                {renderReport(previewTabName)}
              </div>
            )}
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default CreditManager;
