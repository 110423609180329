import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

// COMPONENT IMPORTS
import CapsyncIcon from '../../components/CapsyncIcon';

/* ============================== AMORTIZATION BREADCRUMB ============================== */
const AmortizationBreadCrumb = ({ urlCheck, allData }) => {
  return (
    <div className="cs-breadcrumb">
      <Link to={window.location.pathname.includes('liabilities') ? '/liabilities' : '/assets'}>
        <span className="cs-light-body-text-m cs-neutral-80">
          {window.location.pathname.includes('assets') ? 'Assets' : 'Liabilities'}
        </span>
      </Link>
      <span className="cs-neutral-70">
        <CapsyncIcon title="chevron-right-outlined" size="12" />
      </span>
      <Link to={urlCheck.filter((x) => window.location.pathname.includes(x.category))[0].url}>
        <span className="cs-light-body-text-m cs-neutral-80">
          {urlCheck.filter((x) => window.location.pathname.includes(x.category))[0].title}
        </span>
      </Link>
      <span className="cs-neutral-70">
        <CapsyncIcon title="chevron-right-outlined" size="12" />
      </span>
      <span className="cs-light-body-text-m cs-neutral-100">
        {allData.creditor_name ? allData.creditor_name : allData.property_name}
      </span>
    </div>
  );
};

// PROPS TYPE
AmortizationBreadCrumb.propTypes = {
  urlCheck: PropTypes.array,
  allData: PropTypes.object
};

export default AmortizationBreadCrumb;
