import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Form } from 'react-bootstrap';
import HelloSign from 'hellosign-embedded';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import * as yup from 'yup';

// COMPONENT IMPORTS
import Modal from '../../components/Modal';
import HelloSignProgress from '../../components/HelloSignProgress';
import { emailFieldPFS, firstNameFieldPFS } from './validatePFS';
import { TOAST_MESSAGE } from '../../constants/message';
import IndividualPFS from './IndividualPFS';
import JointPFS from './JointPFS';

// API
import {
  completedSignatureList,
  pendingSignatureList,
  signatureGetPFSLink,
  signatureRequestAdd
} from '../../slices/requestSlice';
import { useToUserPermission } from '../../slices/authSlice';

/* ============================== SHARE PFS THIRD MODAL ============================== */
const SharePFSThirdModal = (props) => {
  const {
    showPFSThirdModal,
    setShowPFSThirdModal,
    selectValue,
    setSelectValue,
    sharePFSFirstModalSchema,
    isCheckedWealth,
    comments,
    setRadioValue
  } = props;

  const dispatch = useDispatch();

  const userPermission = useToUserPermission();
  const pfsAccessId = userPermission?.accessUserID || null;
  const { user } = useSelector((state) => state.auth);

  const [isLoading, setIsLoading] = useState(false);

  const initialValues = {
    name: '',
    email: ''
  };
  const validationSchema = yup.object().shape({
    email: emailFieldPFS(selectValue),
    name: firstNameFieldPFS(selectValue)
  });
  const onSubmit = async (values) => {
    const spaceIndex = sharePFSThirdModalSchema.values.name.indexOf(' ');
    const data = {
      created_by_id: pfsAccessId !== null ? +pfsAccessId : user?.id,
      created_from_id: user && user?.id,
      receiver_email: sharePFSFirstModalSchema.values.email,
      receiver_notes: sharePFSFirstModalSchema.values.notes,
      receiver_first_name: sharePFSFirstModalSchema.values.first_name,
      receiver_last_name: sharePFSFirstModalSchema.values.last_name,
      is_individual: selectValue === '1' ? true : false,
      is_return_to_me: pfsAccessId !== null && isCheckedWealth ? true : false,
      joint_user_email: sharePFSThirdModalSchema.values.email,
      joint_user_first_name:
        spaceIndex !== -1
          ? sharePFSThirdModalSchema.values.name.substring(0, spaceIndex)
          : sharePFSThirdModalSchema.values.name,
      joint_user_last_name:
        spaceIndex !== -1 ? sharePFSThirdModalSchema.values.name.substring(spaceIndex + 1) : '',
      comment: comments
    };
    setIsLoading(true);
    dispatch(signatureRequestAdd(data))
      .unwrap()
      .then(async (response) => {
        if (response.code === 200) {
          setIsLoading(false);
          setShowPFSThirdModal(false);
          sharePFSThirdModalSchema.resetForm();
          sharePFSFirstModalSchema.resetForm();
          setSelectValue('');
          setRadioValue({ num: '1', name: 'PFS Summary' });
          const data = {
            user_id: user && user.id
          };
          await dispatch(pendingSignatureList(data)).unwrap();
          await dispatch(completedSignatureList(data)).unwrap();
          const embeddedUrl = response.data.embedded_url;
          !embeddedUrl && toast.success(TOAST_MESSAGE.REQUEST_SENT_SUCCESS);
          const client = new HelloSign();
          if (embeddedUrl !== undefined) {
            if (process.env.REACT_APP_MODE === 'PROD') {
              client.open(embeddedUrl, {
                clientId: process.env.REACT_APP_HELLO_SIGN_CLIENT_ID
              });
            } else {
              client.open(embeddedUrl, {
                clientId: process.env.REACT_APP_HELLO_SIGN_CLIENT_ID,
                skipDomainVerification: true
              });
            }
            client.on('sign', async (event) => {
              dispatch(
                signatureGetPFSLink({ signature_request_id: response.data.sign_request_id })
              ).unwrap();
            });
          }
        }
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };
  const sharePFSThirdModalSchema = useFormik({
    initialValues,
    validationSchema,
    onSubmit
  });
  const handlePFSThirdCloseModal = () => {
    setShowPFSThirdModal(false);
    sharePFSThirdModalSchema.resetForm();
    sharePFSFirstModalSchema.resetForm();
    setSelectValue('');
  };

  useEffect(() => {
    if (selectValue === '1') {
      sharePFSThirdModalSchema.resetForm();
    }
  }, [selectValue]);

  const bodyElement = () => {
    return isLoading ? (
      <HelloSignProgress />
    ) : (
      <Form onSubmit={sharePFSThirdModalSchema.handleSubmit}>
        <IndividualPFS selectValue={selectValue} setSelectValue={setSelectValue} />
        {selectValue === '2' && <JointPFS sharePFSThirdModalSchema={sharePFSThirdModalSchema} />}

        <div className="cs-modal-btn">
          <Button
            variant="secondary"
            className="cs-btn-secondary md-btn cs-regular-h5"
            onClick={handlePFSThirdCloseModal}>
            Cancel
          </Button>
          <Button
            type="submit"
            variant="primary"
            className="cs-btn-primary md-btn cs-regular-h5"
            disabled={selectValue === ''}>
            Sign
          </Button>
        </div>
      </Form>
    );
  };

  return (
    <Modal
      show={showPFSThirdModal}
      isCloseButton={false}
      className="cs-xs-modal"
      title="Select PFS Type"
      body={bodyElement()}
    />
  );
};

// PROPS TYPE
SharePFSThirdModal.propTypes = {
  showPFSThirdModal: PropTypes.bool,
  setShowPFSThirdModal: PropTypes.func,
  selectValue: PropTypes.string,
  setSelectValue: PropTypes.func,
  sharePFSFirstModalSchema: PropTypes.object,
  isCheckedWealth: PropTypes.bool,
  comments: PropTypes.string,
  setRadioValue: PropTypes.func
};

export default SharePFSThirdModal;
