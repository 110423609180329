import React, { useEffect, useState } from 'react';
import { ProgressBar, Modal, Image } from 'react-bootstrap';
import PropTypes from 'prop-types';

// COMPONENT IMPORTS
// import CapsyncLoader from '../assets/images/capsync-loader.gif';
import CapFavicon from '../assets/images/brand/cap-icon-logo.svg';
import CommonSpinner from './CommonSpinner';

// API
import { useBrandDetails } from '../slices/brandingSlice';

/* ============================== HELLO SIGN PROGRESS ============================== */
const HelloSignProgress = ({ backdropClass = '' }) => {
  const [progress, setProgress] = useState(0);
  const brandDetails = useBrandDetails();
  useEffect(() => {
    const timer = setInterval(() => {
      setProgress((prev) => prev + 1);
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  return (
    <Modal
      show={true}
      centered
      className="cs-common-modal-overlay"
      backdropClassName={backdropClass}>
      <Modal.Body className="cs-sign-modal">
        <div className="text-center api-prog-bar sign-loader-area">
          <div className="text-center">
            {brandDetails?.is_use_load_image ? (
              <Image
                src={(brandDetails && brandDetails.small_logo_s3_url) || CapFavicon}
                className="w-25 spin-animation"
              />
            ) : (
              <CommonSpinner size={45} classParent="cs-primary" />
            )}
          </div>
          <div className="sign-head">
            <strong className="sign-prog-bar-heading cs-regular-sub-heading-s cs-neutral-100">
              {'Please Wait'}
            </strong>
          </div>
          <div className="cs-progress-bar">
            <ProgressBar now={(1 - 1 / progress) * 100} className="sign-load-bar" />
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

// PROPS TYPE
HelloSignProgress.propTypes = {
  backdropClass: PropTypes.string
};

export default HelloSignProgress;
