import { useEffect, useState } from 'react';
import * as XLSX from 'xlsx';
import axios from 'axios';
import { PDFDocument } from 'pdf-lib';
import { renderAsync } from 'docx-preview';

// COMPONENT IMPORTS
import { replaceLastOccurrence } from '../../utils/common';

export const defaultBreadcrumb = [
  {
    title: 'My Documents',
    id: 0,
    row_id: null,
    category_id: null,
    create_folder_allowed: false,
    upload_files_allowed: false,
    upload_folder_allowed: false,
    check_double_space: false
  }
];
export const defaultMoveBreadcrumb = [
  {
    title: 'My Documents',
    id: 0,
    is_movable: false,
    category_id: null,
    row_id: null
  }
];

export const headerConfig = {
  'My Documents': { key: 'updatedAt', text: 'Date Updated' },
  Archive: { key: 'archived_date', text: 'Archived Date' },
  Trash: { key: 'deletedAt', text: 'Trashed Date' }
};

export const MyDocumentSortingDetails = {
  name: { key: 'name', showIcon: false, title: 'Name', sortOrder: 'asc' },
  updated_by: { key: 'updated_by', showIcon: false, title: 'Modified By', sortOrder: 'asc' },
  updatedAt: { key: 'updatedAt', showIcon: true, title: 'Date Updated', sortOrder: 'desc' }
};

export const ArchiveSortingDetails = {
  name: { key: 'name', showIcon: false, title: 'Name', sortOrder: 'asc' },
  archived_date: { key: 'archived_date', showIcon: true, title: 'Date Updated', sortOrder: 'desc' }
};

export const TrashSortingDetails = {
  name: { key: 'name', showIcon: false, title: 'Name', sortOrder: 'asc' },
  deletedAt: { key: 'deletedAt', showIcon: true, title: 'Date Updated', sortOrder: 'desc' }
};

export const scannedFileElem = {
  modalOpen: false,
  isBigFile: false,
  details: null,
  isCategory: false
};

export const checkSorting = (
  moduleName = 'My Documents',
  breadcrumbDetails = [...defaultMoveBreadcrumb],
  parentId = null
) => {
  if (moduleName !== 'My Documents') {
    return true;
  }

  if (breadcrumbDetails.length < 2) {
    return false;
  }

  const lastBreadcrumb = breadcrumbDetails[breadcrumbDetails.length - 1];
  const secondBreadcrumb = breadcrumbDetails[1] || {};

  const hasValidLastBreadcrumb =
    lastBreadcrumb?.category_id !== null && lastBreadcrumb?.row_id !== null;

  const isSecondBreadcrumbOther = secondBreadcrumb?.title === 'Other';

  return (hasValidLastBreadcrumb || isSecondBreadcrumbOther) && parentId !== null;
};

export const fileManagementSortingData = (
  moduleName = 'My Documents',
  breadcrumbDetails = [...defaultMoveBreadcrumb],
  parentId = null,
  listData = [],
  sortingHeader
) => {
  const shouldSort = checkSorting(moduleName, breadcrumbDetails, parentId);

  if (shouldSort && listData.length > 0) {
    const sortingKey = Object.keys(sortingHeader).find((key) => sortingHeader[key].showIcon);

    if (sortingKey) {
      const { sortOrder } = sortingHeader[sortingKey];
      const sortedData = [...listData].sort((a, b) => {
        if (a[sortingKey] < b[sortingKey]) {
          return sortOrder === 'asc' ? -1 : 1;
        }
        if (a[sortingKey] > b[sortingKey]) {
          return sortOrder === 'asc' ? 1 : -1;
        }
        return 0;
      });

      return sortedData;
    }
  }
  return listData;
};

export const TrashFilterOptions = [
  { title: 'All', days: 90 },
  { title: 'Today', days: 1 },
  { title: 'Last 7 Days', days: 7 },
  { title: 'Last 30 Days', days: 30 },
  { title: 'Last 60 Days', days: 60 }
];

export const ArchiveFilterOptions = [
  { title: 'All', days: 90 },
  { title: 'Today', days: 1 },
  { title: 'Last 7 Days', days: 7 },
  { title: 'Last 30 Days', days: 30 }
];

export const useFileManagementClickOutside = (
  layoutRowRef,
  fmListRef,
  documentsRef,
  handleOutsideClick
) => {
  useEffect(() => {
    const handleClickOutside = (event) => {
      const isClickInsideElement = (target) => {
        const click =
          documentsRef &&
          documentsRef?.current &&
          documentsRef?.current?.find((data, index) => {
            const element = document?.getElementById(`grid-${index}`);
            return element && element?.contains(target);
          });

        return !click;
      };

      if (
        isClickInsideElement &&
        isClickInsideElement.length &&
        isClickInsideElement(event.target) &&
        layoutRowRef?.current &&
        !layoutRowRef?.current?.contains(event.target)
      ) {
        handleOutsideClick();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [fmListRef, layoutRowRef]);
};

export const onRowSingleClick = (
  e,
  data,
  setSelectedRecords,
  listData,
  lastSelected,
  setLastSelected
) => {
  e.preventDefault();
  const isShiftPressed = e.shiftKey;
  const isControlPressed = e.ctrlKey || e.metaKey;
  const isSelectAll = e.code === 'KeyA' && (e.ctrlKey || e.metaKey);

  if (isSelectAll) {
    setSelectedRecords(listData);
    return;
  }

  if (isControlPressed) {
    setSelectedRecords((prevRecords) => {
      const isSelected = prevRecords.some((record) => record.id === data.id);
      if (isSelected) {
        return prevRecords.filter((record) => record.id !== data.id);
      } else {
        return [...prevRecords, data];
      }
    });
  } else if (isShiftPressed && lastSelected) {
    const startIndex = listData.findIndex((record) => record.id === lastSelected.id);
    const endIndex = listData.findIndex((record) => record.id === data.id);

    if (startIndex > -1 && endIndex > -1) {
      const range = listData.slice(
        Math.min(startIndex, endIndex),
        Math.max(startIndex, endIndex) + 1
      );
      setSelectedRecords(range);
    }
  } else {
    setSelectedRecords((prevRecords) => {
      const isSelected = prevRecords.some((record) => record.id === data.id);
      if (isSelected) {
        return prevRecords.filter((record) => record.id !== data.id);
      } else {
        return [...prevRecords, data];
      }
    });
  }

  setLastSelected(data);
};

export const handleKeyDown = (e) => {
  if (e.code === 'KeyA' && (e.ctrlKey || e.metaKey)) {
    e.preventDefault();
    return true;
  } else {
    return false;
  }
};

export const loadingMessage = (records = [], type = '') => {
  const isMultiple = records.length > 1;
  const isFile = records.every((record) => record.attachment !== null);
  const isFolder = records.every((record) => record.attachment === null);

  const itemType = isFile ? 'File' : isFolder ? 'Folder' : 'Item';
  const itemTypePlural = isFile ? 'Files' : isFolder ? 'Folders' : 'Items';

  const action = type === 'delete' ? 'Deleting' : 'Archiving';
  const itemDescriptor = isMultiple ? `Multiple ${itemTypePlural}` : itemType;

  return `${action} ${itemDescriptor}`;
};

export const useFileContent = (file, fileType) => {
  const [state, setState] = useState({
    blobUrl: null,
    csvContent: null,
    txtContent: null,
    xlsxContent: {},
    sheetNames: [],
    selectedSheet: '',
    docxContent: null,
    pptPreview: null,
    loading: true
  });
  useEffect(() => {
    const fetchFileContent = async () => {
      setState((prevState) => ({ ...prevState, loading: true }));
      try {
        const response = await axios.get(file, {
          responseType: fileType === ('xlsx' || 'xls' || 'pdf') ? 'arraybuffer' : 'blob'
        });

        switch (fileType) {
          case 'image':
          case 'video':
          case 'audio': {
            const blobUrl = URL.createObjectURL(response.data);
            setState((prevState) => ({ ...prevState, blobUrl, loading: false }));
            break;
          }
          case 'csv': {
            const text = await response.data.text();
            setState((prevState) => ({ ...prevState, csvContent: text, loading: false }));
            break;
          }
          case 'xlsx' || 'xls': {
            const data = new Uint8Array(response.data);
            const workbook = XLSX.read(data, { type: 'array', cellDates: true });
            const sheetsData = workbook.SheetNames.reduce((acc, sheetName) => {
              const sheet = workbook.Sheets[sheetName];
              acc[sheetName] = XLSX.utils.sheet_to_json(sheet, {
                header: 1,
                defval: '',
                raw: true
              });
              return acc;
            }, {});
            setState((prevState) => ({
              ...prevState,
              xlsxContent: sheetsData,
              sheetNames: workbook.SheetNames,
              selectedSheet: workbook.SheetNames[0],
              loading: false
            }));
            break;
          }
          case 'pdf': {
            const fileData = response.data;
            const base64ToArrayBuffer = (base64) => {
              const binaryString = window.atob(base64);
              const len = binaryString.length;
              const bytes = new Uint8Array(len);
              for (let i = 0; i < len; i++) {
                bytes[i] = binaryString.charCodeAt(i);
              }
              return bytes.buffer;
            };
            let arrayBuffer;
            if (fileData instanceof Blob) {
              arrayBuffer = await fileData.arrayBuffer();
            } else if (typeof fileData === 'string') {
              arrayBuffer = base64ToArrayBuffer(fileData);
            } else {
              arrayBuffer = fileData;
            }
            const uint8Array = new Uint8Array(arrayBuffer);
            const pdfDoc = await PDFDocument.create();
            const downloadedPDF = await PDFDocument.load(uint8Array);
            const pageCount = downloadedPDF.getPageCount();

            for (let i = 0; i < pageCount; i++) {
              const [copiedPage] = await pdfDoc.copyPages(downloadedPDF, [i]);
              pdfDoc.addPage(copiedPage);
            }
            const pdfBytes = await pdfDoc.save();
            const blob = new Blob([pdfBytes], { type: 'application/pdf' });
            const blobUrl = URL.createObjectURL(blob);
            setState((prevState) => ({ ...prevState, blobUrl, loading: false }));
            break;
          }
          case 'docx': {
            const docxBlob = new Blob([response.data], {
              type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
            });
            setState((prevState) => ({ ...prevState, docxContent: docxBlob, loading: false }));
            break;
          }
          case 'document': {
            const pptPreview = `https://docs.google.com/gview?url=${encodeURIComponent(file)}&embedded=true`;
            setState((prevState) => ({ ...prevState, pptPreview: pptPreview, loading: false }));
            break;
          }
          case 'txt': {
            const text = await response.data.text();
            setState((prevState) => ({ ...prevState, txtContent: text, loading: false }));
            break;
          }
          default:
            throw new Error('Unsupported file type');
        }
      } catch (error) {
        console.error('Error fetching file content', error);
      }
    };

    if (file) {
      fetchFileContent();
    }
  }, [file, fileType]);

  useEffect(() => {
    return () => {
      if (state.blobUrl) {
        URL.revokeObjectURL(state.blobUrl);
      }
    };
  }, [state.blobUrl]);

  useEffect(() => {
    if (state.docxContent) {
      const container = document.getElementById('docx-preview-container');
      if (container) {
        renderAsync(state.docxContent, container);
      }
    }
  }, [state.docxContent]);

  return state;
};

export const updateFileName = (fileName, allFileNames, extension) => {
  const match = fileName.match(/ \((\d+)\)(?!.* \(\d+\))/);
  let newStr = fileName;

  if (match) {
    const number = parseInt(match[1]);
    let newCount = number + 1;
    do {
      newStr = replaceLastOccurrence(fileName, `(${number})`, `(${newCount})`);
      newCount++;
    } while (allFileNames.has(`${newStr}.${extension}`));
  } else {
    newStr = `${fileName} (1)`;
    while (allFileNames.has(`${newStr}.${extension}`)) {
      const numMatch = newStr.match(/ \((\d+)\)(?!.* \(\d+\))/);
      const number = parseInt(numMatch[1]);
      newStr = replaceLastOccurrence(newStr, `(${number})`, `(${number + 1})`);
    }
  }

  return newStr;
};

export const deleteMsg = (data) => {
  const isMultiple = data.length > 1;
  const isFile = data.every(({ attachment }) => attachment !== null);
  const isFolder = data.every(({ attachment }) => attachment === null);

  const itemType = isFile ? 'File' : isFolder ? 'Folder' : 'Item';
  const itemTypePlural = isFile ? 'Files' : isFolder ? 'Folders' : 'Items';

  return isMultiple
    ? `Your ${itemTypePlural} have been deleted successfully.`
    : `Your ${itemType} has been deleted successfully.`;
};
