import React, { useEffect, useState } from 'react';
import { Form, Row, Button } from 'react-bootstrap';
import NumberFormat from 'react-currency-format';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';

// COMPONENT IMPORTS
import Modal from './Modal';
import CapsyncIcon from './CapsyncIcon';
import CapsyncToolTip from './CapsyncToolTip';
import { setAddressString } from '../utils/common';
import { TOAST_MESSAGE } from '../constants/message';
import { ADD_ACCOUNT_MODALS } from '../constants/addAccount';
import Automobile from '../pages/assets/categoryForms/Automobile';
import RealEstate from '../pages/assets/categoryForms/RealEstate';
import MortgagesOnRealEstate from '../pages/liabilities/categoryForms/MortgagesOnRealEstate';
import InstallmentAccountAuto from '../pages/liabilities/categoryForms/InstallmentAccountAuto';

// API
import {
  getAllUnlinkedAutomobile,
  getAllUnlinkedInstallmentAuto,
  getAllUnlinkedMortgagesRealEstate,
  getAllUnlinkedRealEstate,
  getAssetsCategory,
  getLiabilitiesCategory,
  linkAutomobileInstallmentAccountAuto,
  linkRealEstateMortgageOnRealEstate,
  useCategoryDetail
} from '../slices/categorySlice';
import { useUserID } from '../slices/authSlice';

/* ============================== LINK MODAL ============================== */
const LinkModal = ({
  showLinkModal,
  updateMainCategory,
  updateSubCategory,
  openAnotherModal,
  setShowLinkModal,
  numDisplayType,
  descDisplayType,
  numType,
  descType,
  categoryRowId,
  linkModalAddress,
  linkAddressObj
}) => {
  const userId = useUserID();
  const categories = useCategoryDetail();
  const categoryId = categories?.category_id;
  const dispatch = useDispatch();
  const [addAccountStep, setAddAccountStep] = useState(ADD_ACCOUNT_MODALS.MAIN_MODAL);
  const [selectedId, setSelectedId] = useState('');
  const [listData, setListData] = useState([]);
  const [searchValue, setSearchValue] = useState('');

  const selectEntry = (params) => {
    setSelectedId(params);
  };

  const searchValueHandler = (e) => {
    setSearchValue(e.target.value);
  };
  const escapeRegExp = (string) => {
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
  };

  const searchFilter = (data) => {
    const escapedSearchValue = escapeRegExp(searchValue);
    const searchRegex = new RegExp(escapedSearchValue, 'i');
    if (typeof data[descType] === 'string') {
      return (
        data[descType].toLowerCase().includes(searchValue.toLowerCase()) ||
        data[numType].toString().match(searchRegex)
      );
    } else {
      return (
        setAddressString(data[descType]).toLowerCase().includes(searchValue.toLowerCase()) ||
        data[numType].toString().match(searchRegex)
      );
    }
  };

  const onHandleAddCategory = (category) => {
    setAddAccountStep(category);
  };

  const handleCategoryFormCancel = () => {
    setAddAccountStep(ADD_ACCOUNT_MODALS.ADD_MANUAL_ACCOUNT_MODAL);
  };

  const handleCategoryFormSubmit = () => setAddAccountStep(ADD_ACCOUNT_MODALS.ACCOUNT_LINKED);

  useEffect(() => {
    const payload = { userId: userId };

    const fetchUnlinkedData = (action, payload) => {
      dispatch(action(payload))
        .unwrap()
        .then((response) => {
          if (response.code === 200) {
            setListData(response.data);
          }
        })
        .catch((err) => {});
    };

    switch (updateSubCategory) {
      case 'mortgagesonrealestate':
        fetchUnlinkedData(getAllUnlinkedMortgagesRealEstate, payload);
        break;
      case 'installmentsauto':
        fetchUnlinkedData(getAllUnlinkedInstallmentAuto, payload);
        break;
      case 'automobile':
        fetchUnlinkedData(getAllUnlinkedAutomobile, payload);
        break;
      case 'realestate':
        fetchUnlinkedData(getAllUnlinkedRealEstate, payload);
        break;
      default:
        break;
    }
  }, [categories, addAccountStep]);

  const bodyElement = () => {
    return (
      <React.Fragment>
        <p className="cs-regular-sub-heading-m cs-light cs-neutral-80">
          Choose the {updateMainCategory} that you want to link
        </p>
        <div className="cs-search-bar auto-link-search">
          <Form.Group className="cs-form-group">
            <Form.Control
              type="text"
              placeholder="Search..."
              value={searchValue}
              onChange={searchValueHandler}
              className={searchValue && 'cs-input-field-active'}
            />
            <span className="input-field-icon cs-neutral-70">
              <CapsyncIcon title="search-outlined" size="18" />
            </span>
          </Form.Group>
        </div>
        <Row>
          <div className="link-table-heading">
            <span className="cs-regular-sub-heading-s cs-neutral-90">
              {descDisplayType || 'Description'}
            </span>
            <span className="cs-regular-sub-heading-s cs-neutral-90">
              {numDisplayType || 'Share Market Value'}
            </span>
          </div>
          <div className="auto-link-popup">
            {listData
              .filter((data) => searchFilter(data))
              .map((data, index) => (
                <div
                  className={`auto-selection-box ${selectedId === data.id ? 'selected-box' : ''}`}
                  key={index}
                  onClick={() => selectEntry(data.id)}>
                  {selectedId === data.id && ''}
                  <span className="cs-light-sub-heading-s cs-neutral-100">
                    {typeof data[descType] === 'string'
                      ? data[descType]
                      : setAddressString(data[descType])}
                  </span>
                  <div className="verified-value-icon">
                    <span
                      className={`cs-light-sub-heading-s dollar-value-gap cs-neutral-100 ${data.is_manual === true && 'value-align'}`}>
                      $&nbsp;
                      <NumberFormat
                        value={Number(data[numType]).toFixed(2)}
                        displayType={'text'}
                        thousandSeparator={true}
                      />
                    </span>
                    {data.is_manual === false && (
                      <span className="cs-success">
                        <CapsyncToolTip
                          Child={'verification-outlined'}
                          placement={'top'}
                          message={
                            updateSubCategory === 'automobile'
                              ? 'Verified by Vehicles Database'
                              : 'Verified by Attom Data'
                          }
                          type="icon"
                          size="16"
                        />
                      </span>
                    )}
                  </div>
                </div>
              ))}

            {listData.filter((data) => searchFilter(data)).length === 0 && (
              <span className="cs-common-no-record cs-light-body-text-l cs-neutral-100">
                No data found.
              </span>
            )}
          </div>
        </Row>
        <Button
          type="submit"
          className="cs-regular-h5 cs-btn-icon-tertiary add-another-asset"
          onClick={() => onHandleAddCategory(openAnotherModal)}>
          <span className="cs-primary">
            <CapsyncIcon title="add-filled" size="18" />
          </span>
          Add another {updateMainCategory}
        </Button>
      </React.Fragment>
    );
  };
  const handleLinkCategory = (selectedId) => {
    let payload = {
      user_id: userId
    };

    const linkPayloadAndDispatch = (payload, action) => {
      dispatch(action(payload))
        .unwrap()
        .then(async (response) => {
          if (response.code === 200) {
            setShowLinkModal(false);
            const payload = { categoryId: categoryId, userId: userId };
            if (categories.parent === 'assets') {
              await dispatch(getAssetsCategory(payload)).unwrap();
              toast.success(TOAST_MESSAGE.LINK_ASSET_SUCCESS);
            } else {
              await dispatch(getLiabilitiesCategory(payload)).unwrap();
              toast.success(TOAST_MESSAGE.LINK_LIABILITY_SUCCESS);
            }
          } else if (response.code === 500) {
            toast.error(response.message, 'error');
          }
        })
        .catch((err) => {
          toast.error(err.message, 'error');
        });
    };

    if (categories.parent === 'assets') {
      if (updateSubCategory === 'installmentsauto') {
        payload = {
          ...payload,
          installment_account_auto_id: selectedId,
          automobile_id: categoryRowId.id
        };
        linkPayloadAndDispatch(payload, linkAutomobileInstallmentAccountAuto);
      } else if (updateSubCategory === 'mortgagesonrealestate') {
        payload = {
          ...payload,
          mortgages_on_real_estate_id: selectedId,
          real_estate_id: categoryRowId.id
        };
        linkPayloadAndDispatch(payload, linkRealEstateMortgageOnRealEstate);
      }
    } else if (categories.parent === 'liabilities') {
      if (updateSubCategory === 'automobile') {
        payload = {
          ...payload,
          installment_account_auto_id: categoryRowId.id,
          automobile_id: selectedId
        };
        linkPayloadAndDispatch(payload, linkAutomobileInstallmentAccountAuto);
      } else if (updateSubCategory === 'realestate') {
        payload = {
          ...payload,
          mortgages_on_real_estate_id: categoryRowId.id,
          real_estate_id: selectedId
        };
        linkPayloadAndDispatch(payload, linkRealEstateMortgageOnRealEstate);
      }
    }
  };

  return (
    <React.Fragment>
      <Modal
        show={showLinkModal}
        isCloseButton={false}
        className="cs-md-modal"
        title={`Choose ${updateMainCategory}`}
        body={bodyElement()}
        saveButtonLabel="Link"
        handleOnSave={() => handleLinkCategory(selectedId)}
        cancelButtonLabel="Cancel"
        handleOnCancel={() => setShowLinkModal(false)}
        disabled={selectedId === ''}
      />
      {addAccountStep === ADD_ACCOUNT_MODALS.INSTALLMENT_ACCOUNT_AUTO && (
        <InstallmentAccountAuto
          isOpen={addAccountStep === ADD_ACCOUNT_MODALS.INSTALLMENT_ACCOUNT_AUTO}
          handleCancel={handleCategoryFormCancel}
          handleCategoryFormSubmit={handleCategoryFormSubmit}
        />
      )}
      {addAccountStep === ADD_ACCOUNT_MODALS.MORTGAGES_ON_REAL_ESTATE && (
        <MortgagesOnRealEstate
          isOpen={addAccountStep === ADD_ACCOUNT_MODALS.MORTGAGES_ON_REAL_ESTATE}
          handleCancel={handleCategoryFormCancel}
          handleCategoryFormSubmit={handleCategoryFormSubmit}
          linkModalAddress={linkModalAddress}
        />
      )}
      {addAccountStep === ADD_ACCOUNT_MODALS.AUTOMOBILES && (
        <Automobile
          isOpen={addAccountStep === ADD_ACCOUNT_MODALS.AUTOMOBILES}
          handleCancel={handleCategoryFormCancel}
          handleCategoryFormSubmit={handleCategoryFormSubmit}
        />
      )}
      {addAccountStep === ADD_ACCOUNT_MODALS.REAL_ESTATE && (
        <RealEstate
          isOpen={addAccountStep === ADD_ACCOUNT_MODALS.REAL_ESTATE}
          handleCancel={handleCategoryFormCancel}
          handleCategoryFormSubmit={handleCategoryFormSubmit}
          linkAddressObj={linkAddressObj}
        />
      )}
    </React.Fragment>
  );
};

// PROPS TYPE
LinkModal.propTypes = {
  showLinkModal: PropTypes.bool,
  setShowLinkModal: PropTypes.func,
  updateMainCategory: PropTypes.string,
  updateSubCategory: PropTypes.string,
  openAnotherModal: PropTypes.string,
  descDisplayType: PropTypes.string,
  numDisplayType: PropTypes.string,
  descType: PropTypes.string,
  numType: PropTypes.string,
  categoryRowId: PropTypes.object,
  linkModalAddress: PropTypes.object,
  linkAddressObj: PropTypes.object
};

export default LinkModal;
