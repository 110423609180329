import React, { useState } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Badge } from 'react-bootstrap';

// COMPONENT IMPORTS
import CollabDeleteModal from './CollabDeleteModal';
import CustomTable from '../../components/CustomTable';
import CollaborationOffCanvas from './CollaborationOffCanvas';
import CapsyncToolTip from '../../../components/CapsyncToolTip';

/* ============================== COLLABORATION TABLE ============================== */
const CollaborationTable = ({
  tab,
  data,
  setPaginationData,
  totalDataCount,
  fetchCollaborators,
  hasFullCollaborationAccess = false
}) => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [item, setItem] = useState({});
  const [activeRowIndex, setActiveRowIndex] = useState(-1);
  const handleDeleteButtonClick = (item) => {
    setShowDeleteModal(true);
    setItem(item);
  };

  const handleTableRowClick = (rowIndex) => {
    setActiveRowIndex(rowIndex);
  };
  const userRequestStatus = (item) => {
    return item?.status === '1'
      ? 'Pending'
      : item?.status === '2'
        ? 'Completed'
        : item?.status === '3'
          ? 'Rejected'
          : item?.status === '4'
            ? 'Deleted'
            : 'Unknown Status';
  };

  const COLUMNS = [
    {
      label: 'Name',
      renderCell: (item) => {
        return <span>{item.display_name}</span>;
      },
      width: 250
    },
    {
      label: 'Phone',
      renderCell: (item) => {
        return <span>{item.phone_no || '-'}</span>;
      },
      width: 250
    },
    {
      label: 'Email',
      renderCell: (item) => <span>{item.email}</span>,
      width: 340
    },
    { label: 'Role', renderCell: (item) => <span>{item.role}</span>, width: 250 },
    {
      label: 'Date',
      renderCell: (item) =>
        item.is_verified === true ? (
          <span>{moment(item.request_date).format('L')}</span>
        ) : (
          <Badge
            className={`cs-badge cs-regular-body-text-s ${item.is_verified === false ? 'cs-warning' : 'cs-danger'}`}>
            {/* {userRequestStatus(item)} */}
            Pending
          </Badge>
        ),
      width: 250
    }
  ];

  const createActionColumn = (handleDelete) => ({
    label: 'Action',
    renderCell: (item) => (
      <div className="cs-table-icons">
        <span
          onClick={(e) => {
            handleDelete(item);
            e.stopPropagation();
          }}
          className="cs-delete-btn cs-danger">
          <CapsyncToolTip
            Child={'delete-outlined'}
            placement={'top'}
            size="18"
            message={'Delete'}
            type="icon"
          />
        </span>
      </div>
    ),
    width: 250
  });

  if (hasFullCollaborationAccess) {
    COLUMNS.push(createActionColumn(handleDeleteButtonClick));
  }
  return (
    <React.Fragment>
      <CustomTable
        columns={COLUMNS}
        totalRecords={totalDataCount}
        data={data}
        isPaginate={true}
        onPaginationValueChange={setPaginationData}
        columnVisible={false}
        autoPageBackOnDelete={true}
        onRowClick={handleTableRowClick}
      />
      {hasFullCollaborationAccess && (
        <CollabDeleteModal
          show={showDeleteModal}
          setShow={setShowDeleteModal}
          item={item}
          fetchCollaborators={fetchCollaborators}
          setActiveRowIndex={setActiveRowIndex}
        />
      )}
      <CollaborationOffCanvas
        activeRowIndex={activeRowIndex}
        setActiveRowIndex={setActiveRowIndex}
        data={data}
        tab={tab}
        fetchCollaborators={fetchCollaborators}
        handleDeleteButtonClick={handleDeleteButtonClick}
        hasFullCollaborationAccess={hasFullCollaborationAccess}
        userRequestStatus={userRequestStatus}
      />
    </React.Fragment>
  );
};

// PROPS TYPE
CollaborationTable.propTypes = {
  data: PropTypes.array,
  tab: PropTypes.number,
  setPaginationData: PropTypes.func,
  totalDataCount: PropTypes.number,
  fetchCollaborators: PropTypes.func,
  hasFullCollaborationAccess: PropTypes.bool
};

export default CollaborationTable;
