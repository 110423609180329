import React, { useEffect } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

// COMPONENT IMPORTS
import Layout from '../pages/Layout';
import { socket } from '../config/Socket';

// API
import { authUserLogout, useAccessUser, useUserID } from '../slices/authSlice';

/* ============================== PROTECTED ROUTES ============================== */
const ProtectedRoutes = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const accessUser = useAccessUser();
  const userId = useUserID();

  useEffect(() => {
    const broadcastChannel = new BroadcastChannel('master_admin_accessing_other_client_account');
    const logOutChannel = new BroadcastChannel('logOut_from_same_tabs');
    broadcastChannel.onmessage = (event) => {
      if (event.data === 'accessAccount') {
        window.location.reload();
      }
    };
    logOutChannel.onmessage = (event) => {
      if (event.data === 'tab_logout') {
        dispatch(authUserLogout());
        if (accessUser) {
          socket.emit('exitAccess', {
            user_id: accessUser?.accessUserID,
            access_user_id: userId,
          });
          navigate('/');
        }
      }
    };
    return () => {
      broadcastChannel.close();
    };
  }, []);

  const { isAuthenticated } = useSelector((state) => state.auth);
  return isAuthenticated ? <Layout /> : <Navigate to="/" />;
};

export default ProtectedRoutes;
