import React from 'react';
import { Col, Row, Table } from 'react-bootstrap';
import Skeleton from 'react-loading-skeleton';

// COMPONENT IMPORTS
import SkeletonAmortizationTable from './SkeletonAmortizationTable';

/* ============================== SKELETON AMORTIZATION ============================== */
const SkeletonAmortization = () => {
  return (
    <React.Fragment>
      <div className="cs-section-topbar">
        <div className="amortization-skeleton-breadcrumb">
          <div className="skeleton-breadcrumb-1">
            {' '}
            <Skeleton width={50} />
          </div>
          <div className="skeleton-breadcrumb-2">
            {' '}
            <Skeleton width={20} />
          </div>
          <div className="skeleton-breadcrumb-3">
            <Skeleton width={150} />
          </div>
          <div className="skeleton-breadcrumb-4">
            {' '}
            <Skeleton width={20} />
          </div>
          <div className="skeleton-breadcrumb-5">
            <Skeleton width={40} />
          </div>
        </div>
        <div className="cs-title">
          <h1 className="default-semi-bold-h1 cs-neutral-100">
            <Skeleton width={200} />
          </h1>
        </div>
      </div>
      <div className="amortization_headingTable amortization-headingTable-skeleton">
        <Row>
          <Col lg={7}>
            <div className="amortization-header">
              <h2 className="default-regular-h2">
                <Skeleton width={200} />
              </h2>
              <span className="amortization-icon">
                <Skeleton width={20} />
              </span>
            </div>

            <div className="cs-amortization-table">
              <Table className="cs-table skeleton-table">
                <thead className="cs-thead">
                  <tr>
                    <th className="default-regular-body-text-m cs-neutral-100" colSpan={2}>
                      <Skeleton width={200} />
                    </th>
                  </tr>
                </thead>
                <tbody className="cs-tbody">
                  {Array(4)
                    .fill(0)
                    .map((item) => (
                      <tr key={item}>
                        <td>
                          <Skeleton width={50} />
                        </td>
                        <td>
                          <Skeleton width={50} />
                        </td>
                      </tr>
                    ))}
                </tbody>
              </Table>
            </div>
          </Col>
          <Col lg={5}>
            <div className="amortization-pie-chart pie-chart-container amortization-pie-chart-skeleton">
              <div className="chart-heading">
                <h2 className="default-semi-bold-h2">
                  {' '}
                  <Skeleton width={200} />
                </h2>
              </div>
              <div className="graph-pie-chart-main-container asset-pie-chart skeleton-chart-legend">
                <div className="graph-pie-chart-main-container asset-pie-chart">
                  <div className="skeleton-pie-chart-container">
                    <div className="skeleton-pie-chart for-des">
                      <Skeleton circle width={220} height={220} />
                    </div>
                    <div className="skeleton-pie-chart for-mob">
                      <Skeleton circle width={200} height={200} />
                    </div>
                    <div className="skeleton-chart-legend">
                      {Array(2)
                        .fill(0)
                        .map((item) => (
                          <div className="skeleton-pie-legends" key={item}>
                            {' '}
                            <Skeleton width={100} />
                          </div>
                        ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>
      <SkeletonAmortizationTable />
    </React.Fragment>
  );
};

export default SkeletonAmortization;
