import CryptoJS from 'crypto-js';

const encryptReact = (params) => {
  const fetchData = CryptoJS.AES.encrypt(params, process.env.REACT_APP_ENCRYPT_DECRYPT).toString();

  return fetchData;
};

const decryptReact = (params) => {
  const bytes = CryptoJS.AES.decrypt(params, process.env.REACT_APP_ENCRYPT_DECRYPT);
  const decryptedData = bytes.toString(CryptoJS.enc.Utf8);

  return decryptedData;
};

const decryptFilePassword = (encryptedPassword) => {
  try {
    let data = atob(encryptedPassword, 'base64').toString('ascii');
    data = atob(data, 'base64').toString('ascii');
    data = atob(data, 'base64').toString('ascii');
    return data;
  } catch (err) {
    console.log('err', err);
    return err;
  }
};

export { encryptReact, decryptReact, decryptFilePassword };
