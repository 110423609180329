import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

// COMPONENT IMPORTS
import CapsyncToolTip from '../../components/CapsyncToolTip';
import SubMethodPopup from './AllSubMethods/SubMethodPopup';

/* ============================== SUB METHOD LIST ============================== */
const SubMethodsList = ({ methodId }) => {
  let displayMethod = null;
  switch (methodId) {
    case 1:
      displayMethod = (
        <p className="readMore">
          A natural person with income exceeding $200,000 in each of the two most recent years or
          joint income with that person's spouse or{' '}
          <CapsyncToolTip
            Child={<u>spousal equivalent</u>}
            placement={'top'}
            message={
              'A cohabitant occupying a relationship generally equivalent to that of a spouse.'
            }
            type="text"
          />{' '}
          exceeding $300,000 for those years and has a reasonable expectation of reaching the same
          income level in the current year.
        </p>
      );
      break;
    case 2:
      displayMethod = (
        <p>
          A director, executive officer, or general partner of the issuer of the securities being
          offered or sold, or a director, executive officer, or general partner of a general partner
          of that issuer.
        </p>
      );
      break;
    case 3:
      displayMethod = (
        <div>
          <p>
            Any natural person whose individual net worth, or joint net worth with that person's
            spouse or{' '}
            <CapsyncToolTip
              Child={<u>spousal equivalent</u>}
              placement={'top'}
              message={
                'A cohabitant occupying a relationship generally equivalent to that of a spouse.'
              }
              type="text"
            />
            , exceeds $1,000,000 USD.
          </p>
          <span>For purposes of calculating net worth:</span>
          <span>
            {' '}
            <a onClick={(e) => handleSeeMore(e)} href="javascript:void(0);">
              See More
            </a>
          </span>
        </div>
      );
      break;
    case 3.1:
      displayMethod = (
        <div>
          <p className="row-spacing">
            Any natural person whose individual net worth, or joint net worth with that person's
            spouse or{' '}
            <CapsyncToolTip
              Child={<u>spousal equivalent</u>}
              placement={'top'}
              message={
                'A cohabitant occupying a relationship generally equivalent to that of a spouse.'
              }
              type="text"
            />
            , exceeds $1,000,000 USD.
          </p>
          <p className="cs-regular-sub-heading-m method-spacing">
            For purposes of calculating net worth:
          </p>
          <p className="cs-regular-sub-heading-m method-spacing">
            Joint net worth can be the aggregate net worth of the investor and spouse or spousal
            equivalent; assets need not be held jointly to be included in the calculation. Reliance
            on the joint net worth standard of this method does not require that the securities be
            purchased jointly.
          </p>
          <ul>
            <li className="cs-regular-sub-heading-m">
              The person's primary residence shall not be included as an asset;
            </li>
            <li className="cs-regular-sub-heading-m">
              Indebtedness that is secured by the person's primary residence, up to the estimated
              fair market value of the primary residence at the time of the sale of securities,
              shall not be included as a liability (except that if the amount of such indebtedness
              outstanding at the time of sale of securities exceeds the amount outstanding 60 days
              before such time, other than as a result of the acquisition of the primary residence,
              the amount of such excess shall be included as a liability); and
            </li>
            <li className="cs-regular-sub-heading-m">
              Indebtedness that is secured by the person's primary residence in excess of the
              estimated fair market value of the primary residence at the time of the sale of
              securities shall be included as a liability.
            </li>
          </ul>
        </div>
      );
      break;
    case 13:
      displayMethod = (
        <p>
          The investor has or can get a new letter, which is dated within the last three months,
          from a registered broker-dealer, an SEC-registered investment adviser, a licensed
          attorney, or a certified public accountant certifying the investor as an accredited
          investor.
        </p>
      );
      break;
    case 12:
      displayMethod = (
        <p>
          The investor has or can get a new letter, which is dated within the last three months,
          from a registered broker-dealer, an SEC-registered investment adviser, a licensed
          attorney, or a certified public accountant certifying the investor as an accredited
          investor.
        </p>
      );
      break;
    case 15:
      displayMethod = (
        <p className="readMore">
          A "Special Situation" which is designed to be a catch-all category for situations where an
          investor believes that it is so abundantly clear that they are an accredited investor that
          they don't need to provide the standard evidence that the regulations normally require for
          a proper verification. Please note that it doesn't{' '}
          <span>
            {' '}
            <a onClick={(e) => handleSeeMore(e)} href="javascript:void(0);">
              See More
            </a>
          </span>
        </p>
      );
      break;
    case 15.1:
      displayMethod = (
        <p className="readMore">
          A "Special Situation" which is designed to be a catch-all category for situations where an
          investor believes that it is so abundantly clear that they are an accredited investor that
          they don't need to provide the standard evidence that the regulations normally require for
          a proper verification.{' '}
          <span className="cs-neutral-90">
            Please note that it doesn't allow deviation from the legal definition of accredited
            investor; it only allows additional flexibility on how to prove it.
          </span>{' '}
          This &nbsp;
          <a
            href="https://www.investor.gov/introduction-investing/general-resources/news-alerts/alerts-bulletins/investor-bulletins/updated-3"
            target="_blank"
            rel="noreferrer"
            className="vi-link-color ">
            SEC bulletin
          </a>
          &nbsp; does a decent job describing most of the accredited investor categories.{' '}
          <b>
            Please contact{' '}
            <Link target="_blank" to="mailto:accreditation@capsync.com">
              accreditation@capsync.com
            </Link>{' '}
            before you select this method of verification.
          </b>
        </p>
      );
      break;
    case 37:
      displayMethod = (
        <p>
          A natural person holding General Securities Representative (Series 7), Private Securities
          Offerings Representative (Series 82), or Licensed Investment Adviser Representative
          (Series 65) registration in good standing.
        </p>
      );
      break;
    case 38:
      displayMethod = (
        <p>“Knowledgeable Employees” of a private fund, but only for investments in that fund.</p>
      );
      break;
    case 4:
      displayMethod = (
        <div>
          <span>
            Any bank as defined in section 3(a)(2) of the Securities Act of 1933 (the “Act”), or any
            savings and loan association or other institution as defined in section 3(a)(5)(A) of
            the Act whether acting in its individual or fiduciary capacity; any broker or dealer
            registered pursuant to section 15 of the Securities Exchange Act of 1934 that are
            accredited investors...
          </span>
          <span>
            {' '}
            <a onClick={(e) => handleSeeMore(e)} href="javascript:void(0);">
              See More
            </a>
          </span>
        </div>
      );
      break;
    case 4.1:
      displayMethod = (
        <p>
          Any bank as defined in section 3(a)(2) of the Securities Act of 1933 (the “Act”), or any
          savings and loan association or other institution as defined in section 3(a)(5)(A) of the
          Act whether acting in its individual or fiduciary capacity; any broker or dealer
          registered pursuant to section 15 of the Securities Exchange Act of 1934; any investment
          adviser registered pursuant to section 203 of the Investment Advisers Act of 1940 or
          registered pursuant to the laws of a state; any investment adviser relying on the
          exemption from registering with the Commission under section 203(l) or (m) of the
          Investment Advisers Act of 1940; any insurance company as defined in section 2(13) of the
          Act; any investment company registered under the Investment Company Act of 1940 or a
          business development company as defined in section 2(a)(48) of that act; any Small
          Business Investment Company licensed by the U.S. Small Business Administration under
          section 301(c) or (d) of the Small Business Investment Act of 1958; any Rural Business
          Investment Company as defined in section 384A of the Consolidated Farm and Rural
          Development Act; any plan established and maintained by a state, its political
          subdivisions, or any agency or instrumentality of a state or its political subdivisions,
          for the benefit of its employees, if such plan has total assets in excess of $5,000,000;
          any employee benefit plan within the meaning of the Employee Retirement Income Security
          Act of 1974 if the investment decision is made by a plan fiduciary, as defined in section
          3(21) of such act, which is either a bank, savings and loan association, insurance
          company, or registered investment adviser, or if the employee benefit plan has total
          assets in excess of $5,000,000 or, if a self-directed plan, with investment decisions made
          solely by persons that are accredited investors.
        </p>
      );
      break;
    case 5:
      displayMethod = (
        <p>
          A plan established and maintained by a state, its political subdivisions, or any agency or
          instrumentality of a state or its political subdivisions, for the benefit of its
          employees, if such plan has total assets in excess of $5,000,000.
        </p>
      );
      break;
    case 6:
      displayMethod = (
        <p>
          A private business development company as defined in section 202(a)(22) of the Investment
          Advisers Act of 1940.
        </p>
      );
      break;
    // case 36:
    //   displayMethod = (
    //     <p>
    //       “Knowledgeable Employees” of a private fund, but only for investments
    //       in that fund.
    //     </p>
    //   );
    //   break;
    case 7:
      displayMethod = (
        <p>
          Any organization described in section 501(c)(3) of the Internal Revenue Code, corporation,
          Massachusetts or similar business trust, partnership, or limited liability company, not
          formed for the specific purpose of acquiring the securities offered, with total assets in
          excess of $5,000,000. Most entities/companies choose this if they have over $5,000,000 in
          assets.
        </p>
      );
      break;
    case 9:
      displayMethod = <p>An entity in which all the equity owners are accredited investors.</p>;
      break;
    case 8:
      displayMethod = (
        <p>
          A trust, with total assets in excess of $5,000,000, not formed for the specific purpose of
          acquiring the securities offered, whose purchase is directed by a person who either alone
          or with his purchaser representative(s) has such knowledge and experience in financial and
          business matters that he is capable of evaluating the merits and risks of the prospective
          investment.
        </p>
      );
      break;
    case 10:
      displayMethod = (
        <div>
          <p>
            Any employee benefit plan within the meaning of the Employee Retirement Income Security
            Act of 1974 <b>AND</b>
          </p>
          <span>
            if the investment decision is made by a plan fiduciary, as defined in section 3(21) of
            such act, which is either a bank, savings and loan association, insurance company...
          </span>
          <span>
            {' '}
            <a onClick={(e) => handleSeeMore(e)} href="javascript:void(0);">
              See More
            </a>
          </span>
        </div>
      );
      break;
    case 10.1:
      displayMethod = (
        <div>
          <p className="row-spacing">
            Any employee benefit plan within the meaning of the Employee Retirement Income Security
            Act of 1974 <b>AND</b>
          </p>
          <p className="row-spacing">
            if the investment decision is made by a plan fiduciary, as defined in section 3(21) of
            such act, which is either a bank, savings and loan association, insurance company, or
            registered investment adviser,
          </p>
          <p className="row-spacing">
            OR if the employee benefit plan has total assets in excess of $5,000,000, <b>OR</b>
          </p>
          <p>
            if a self-directed plan, with investment decisions made solely by persons that are
            accredited investors
          </p>
        </div>
      );
      break;
    case 16:
      displayMethod = (
        <div>
          A "Special Situation" which is designed to be a catch-all category for situations where an
          investor believes that it is so abundantly clear that they are an accredited investor that
          they don't need to provide the standard evidence that the regulations normally require for
          a proper verification.{' '}
          <span className="cs-neutral-90">
            {' '}
            Please note that it doesn't allow deviation from the legal definition of accredited
            investor; it only allows additional flexibility on how to prove it.
          </span>{' '}
          This{' '}
          <a
            href="https://www.investor.gov/introduction-investing/general-resources/news-alerts/alerts-bulletins/investor-bulletins/updated-3"
            target="_blank"
            rel="noreferrer"
            className="vi-link-color ">
            SEC bulletin
          </a>
          &nbsp; does a decent job describing most of the accredited investor categories. Please
          contact{' '}
          <Link target="_blank" to="mailto:accreditation@capsync.com">
            accreditation@capsync.com
          </Link>{' '}
          before you select this method of verification.
        </div>
      );
      break;
    case 39:
      displayMethod = (
        <div>
          "Family Offices" as defined in rule 202(a)(11)(G)-1 under the Investment Advisers Act of
          1940 (17 CFR 275.202(a)(11)(G)-1) meeting the requirements below, and any “family client”
          of such family office whose prospective investment in the issuer is directed by such
          family office pursuant to paragraph (iii) below...{' '}
          <span>
            {' '}
            <a onClick={(e) => handleSeeMore(e)} href="javascript:void(0);">
              See More
            </a>
          </span>
        </div>
      );
      break;
    case 39.1:
      displayMethod = (
        <div>
          <p className="row-spacing">
            {' '}
            "Family Offices" as defined in rule 202(a)(11)(G)-1 under the Investment Advisers Act of
            1940 (17 CFR 275.202(a)(11)(G)-1) meeting the requirements below, and any “family
            client” of such family office whose prospective investment in the issuer is directed by
            such family office pursuant to paragraph (iii) below:
          </p>

          <p className="row-spacing">(i) With assets under management in excess of $5,000,000,</p>
          <p className="row-spacing">
            (ii) That is not formed for the specific purpose of acquiring the securities offered,
            and
          </p>
          <p>
            (iii) Whose prospective investment is directed by a person who has such knowledge and
            experience in financial and business matters that such family office is capable of
            evaluating the merits and risks of the prospective investment
          </p>
        </div>
      );
      break;
    case 42:
      displayMethod = (
        <p>
          Any entity, including Indian tribes, governmental bodies, funds, and entities organized
          under the laws of foreign countries, of a type not listed in the above methods, not formed
          for the specific purpose of acquiring the securities offered, owning{' '}
          <span title='"Investments" is defined in Rule 2a51-1(b) under the Investment Company Act.'>
            <u>investments</u>
          </span>
          &nbsp; in excess of $5,000,000.
        </p>
      );
      break;
    case 23:
      displayMethod = (
        <p>
          Is Investor a trust, with total assets in excess of $5,000,000 USD, not formed for the
          specific purpose of acquiring the securities offered, whose purchase is directed by a
          person who either alone or with his purchaser representative(s) has such knowledge and
          experience in financial and business matters that he is capable of evaluating the merits
          and risks of the prospective investment.
        </p>
      );
      break;
    case 24:
      displayMethod = <p>An entity in which all the equity owners are accredited investors.</p>;
      break;
    case 25:
      displayMethod = (
        <p>
          I have a special situation and believe that I can be reasonably verified as an accredited
          investor using principles based methods.
          <span className="cs-neutral-100">
            {' '}
            Please contact{' '}
            <Link className="cs-primary" target="_blank" to="mailto:accreditation@capsync.com">
              accreditation@capsync.com
            </Link>{' '}
            before you select this method of verification.
          </span>
        </p>
      );
      break;
    case 32:
      displayMethod = (
        <p>
          (i) A natural person who owns (either individually or jointly with such person’s spouse)
          not less than $5,000,000 USD in Net Investments.
        </p>
      );
      break;
    case 33:
      displayMethod = (
        <p>
          (ii) Any company that owns not less than $5,000,000 USD in Net Investments and that is
          owned directly or indirectly by or for 2 or more natural persons who are related as
          siblings or spouse (including former spouses), or direct lineal descendants by birth or
          adoption, spouses of such persons, the estates of such persons, or foundations, charitable
          organizations, or trusts established by or for the benefit of such persons.
        </p>
      );
      break;
    case 34:
      displayMethod = (
        <p>
          (iii) Any trust that is not covered by clause (ii), as to which the trustee or other
          person authorized to make decisions with respect to the trust, and each settlor or other
          person who has contributed assets to the trust, is a qualified purchaser under clause (i),
          (ii), (iv), or (v).
        </p>
      );
      break;
    case 35:
      displayMethod = (
        <p>
          (iv) A natural person or company (in the case of an entity, not formed for the specific
          purpose of investing in the investment), acting for its own account or the accounts of
          other qualified purchasers, who in the aggregate owns and invests on a discretionary
          basis, not less than $25,000,000 USD in Net Investments.
        </p>
      );
      break;
    case 36:
      displayMethod = (
        <p>
          (v) An entity in which all of the beneficial owners of the entity’s securities are
          “qualified purchasers” under clause (i), (ii), (iii), or (iv).
        </p>
      );
      break;
    case 27:
      displayMethod = (
        <p>
          A natural person who, or a company (including trusts, partnerships, and limited liability
          companies) that, immediately after entering into the contract has at least $1,000,000 USD
          under the management of the investment adviser
        </p>
      );
      break;
    case 28:
      displayMethod = (
        <p>
          A natural person who, or a company (including trusts, partnerships, and limited liability
          companies) that, at the time of completing this form has a net worth (together, in the
          case of a natural person, with assets held jointly with a spouse) of more than $2,100,000
          USD.
        </p>
      );
      break;
    case 29:
      displayMethod = (
        <p>
          A natural person who, or a company (including trusts, partnerships, and limited liability
          companies) that, at the time of completing this form is a qualified purchaser as defined
          in section 2(a)(51)(A) of the Investment Company Act of 1940 (15 U.S.C. 80a-2(a)(51)(A))
          at the time of completing this form.
        </p>
      );
      break;
    case 30:
      displayMethod = (
        <p>
          A natural person who at the time of completing this form is an executive officer,
          director, trustee, general partner, or person serving in a similar capacity, of the
          investment adviser.
        </p>
      );
      break;
    case 31:
      displayMethod = (
        <p>
          A natural person who at the time of completing this form is an employee of the investment
          adviser (other than an employee performing solely clerical, secretarial or administrative
          functions with regard to the investment adviser) who, in connection with his or her
          regular functions or duties, participates in the investment activities of such investment
          adviser, provided that such employee has been performing such functions and duties for or
          on behalf of the investment adviser, or substantially similar functions or duties for or
          on behalf of another company for at least 12 months.
        </p>
      );
      break;
    case 40:
      displayMethod = (
        <p>
          Any entity, including Indian tribes, governmental bodies, funds, and entities organized
          under the laws of foreign countries, of a type not listed in the above methods, not formed
          for the specific purpose of acquiring the securities offered, owning investments in excess
          of $5,000,000.
        </p>
      );
      break;
    case 14:
      displayMethod = (
        <p>
          The investor has or can get a new letter, which is dated within the last three months,
          from a registered broker-dealer, an SEC-registered investment adviser, a licensed
          attorney, or a certified public accountant certifying the investor as an accredited
          investor.
        </p>
      );
      break;
    case 20:
      displayMethod = (
        <p>
          I already have or can get a new letter, which is dated within the last three months, from
          a registered broker-dealer, an SEC-registered investment adviser, a licensed attorney, or
          a certified public accountant certifying the investor as an accredited investor.
        </p>
      );
      break;
    case 17:
      displayMethod = (
        <p>
          A natural person with income exceeding $200,000 in each of the two most recent years or
          joint income with that person's spouse or spousal equivalent exceeding $300,000 for those
          years and has a reasonable expectation of reaching the same income level in the current
          year.
        </p>
      );
      break;
    case 41:
      displayMethod = (
        <p>“Knowledgeable Employees” of a private fund, but only for investments in that fund.</p>
      );
      break;
    // case 42:
    //   displayMethod = (
    //     <p>
    //       A natural person holding General Securities Representative (Series 7), Private Securities
    //       Offerings Representative (Series 82), or Licensed Investment Adviser Representative
    //       (Series 65) registration in good standing.
    //     </p>
    //   );
    //   break;
    case 22:
      displayMethod = (
        <p>
          I already have or can get a new letter, which is dated within the last three months, from
          a registered broker-dealer, an SEC-registered investment adviser, a licensed attorney, or
          a certified public accountant certifying the investor as an accredited investor.
        </p>
      );
      break;
    case 21:
      displayMethod = (
        <p>
          I have a special situation and believe that I can be reasonably verified as an accredited
          investor using principles based methods.
          <span className="cs-neutral-100">
            {' '}
            Please contact{' '}
            <Link className="cs-primary" target="_blank" to="mailto:accreditation@capsync.com">
              accreditation@capsync.com
            </Link>{' '}
            before you select this method of verification.
          </span>
        </p>
      );
      break;
    case 26:
      displayMethod = <p>An entity in which all the equity owners are accredited investors.</p>;
      break;
    case 43:
      displayMethod = (
        <p>
          Are the two owners a married couple or{' '}
          <CapsyncToolTip
            Child={<u>spousal equivalent</u>}
            placement={'top'}
            width="250px"
            message={
              'A cohabitant occupying a relationship generally equivalent to that of a spouse.'
            }
            type="text"
          />
        </p>
      );
      break;
    case 44:
      displayMethod = (
        <p>
          A revocable trust where the grantor(s) is a single individual or are a married couple or
          spousal equivalent.
        </p>
      );
      break;
    case 45:
      displayMethod = <p>Any other revocable trust, or any irrevocable trust.</p>;
      break;
    case 46:
      displayMethod = (
        <p>
          IMPORTANT: Please proceed with verification as if the equity owners of the trust were the
          investors (except that you should keep the trust name as the name of the investor). In a
          revocable trust, typically, the grantors are the equity owners. In an irrevocable trust,
          determination of the equity owners can be complicated. Please contact{' '}
          <Link target="_blank" to="mailto:accreditation@capsync.com">
            accreditation@capsync.com
          </Link>{' '}
          for assistance.
        </p>
      );
      break;
  }

  const [showSeeMore, setShowSeeMore] = useState(false);

  const handleSeeMore = (e) => {
    e.preventDefault();
    setShowSeeMore(true);
  };

  let readMore = null;
  switch (methodId) {
    case 3:
      readMore = (
        <div>
          <p>
            Any natural person whose individual net worth, or joint net worth with that person's
            spouse or{' '}
            <CapsyncToolTip
              Child={<u>spousal equivalent</u>}
              placement={'top'}
              message={
                'A cohabitant occupying a relationship generally equivalent to that of a spouse.'
              }
              type="text"
            />
            , exceeds $1,000,000 USD.
          </p>
          <p className="cs-regular-sub-heading-m">For purposes of calculating net worth:</p>
          <p className="cs-regular-sub-heading-m">
            Joint net worth can be the aggregate net worth of the investor and spouse or spousal
            equivalent; assets need not be held jointly to be included in the calculation. Reliance
            on the joint net worth standard of this method does not require that the securities be
            purchased jointly.
          </p>
          <ul>
            <li className="cs-regular-sub-heading-m">
              The person's primary residence shall not be included as an asset;
            </li>
            <li className="cs-regular-sub-heading-m">
              Indebtedness that is secured by the person's primary residence, up to the estimated
              fair market value of the primary residence at the time of the sale of securities,
              shall not be included as a liability (except that if the amount of such indebtedness
              outstanding at the time of sale of securities exceeds the amount outstanding 60 days
              before such time, other than as a result of the acquisition of the primary residence,
              the amount of such excess shall be included as a liability); and
            </li>
            <li className="cs-regular-sub-heading-m">
              Indebtedness that is secured by the person's primary residence in excess of the
              estimated fair market value of the primary residence at the time of the sale of
              securities shall be included as a liability.
            </li>
          </ul>
        </div>
      );
      break;
    case 4:
      readMore = (
        <p>
          Any bank as defined in section 3(a)(2) of the Securities Act of 1933 (the “Act”), or any
          savings and loan association or other institution as defined in section 3(a)(5)(A) of the
          Act whether acting in its individual or fiduciary capacity; any broker or dealer
          registered pursuant to section 15 of the Securities Exchange Act of 1934; any investment
          adviser registered pursuant to section 203 of the Investment Advisers Act of 1940 or
          registered pursuant to the laws of a state; any investment adviser relying on the
          exemption from registering with the Commission under section 203(l) or (m) of the
          Investment Advisers Act of 1940; any insurance company as defined in section 2(13) of the
          Act; any investment company registered under the Investment Company Act of 1940 or a
          business development company as defined in section 2(a)(48) of that act; any Small
          Business Investment Company licensed by the U.S. Small Business Administration under
          section 301(c) or (d) of the Small Business Investment Act of 1958; any Rural Business
          Investment Company as defined in section 384A of the Consolidated Farm and Rural
          Development Act; any plan established and maintained by a state, its political
          subdivisions, or any agency or instrumentality of a state or its political subdivisions,
          for the benefit of its employees, if such plan has total assets in excess of $5,000,000;
          any employee benefit plan within the meaning of the Employee Retirement Income Security
          Act of 1974 if the investment decision is made by a plan fiduciary, as defined in section
          3(21) of such act, which is either a bank, savings and loan association, insurance
          company, or registered investment adviser, or if the employee benefit plan has total
          assets in excess of $5,000,000 or, if a self-directed plan, with investment decisions made
          solely by persons that are accredited investors.
        </p>
      );
      break;
    case 10:
      readMore = (
        <div>
          <p>
            Any employee benefit plan within the meaning of the Employee Retirement Income Security
            Act of 1974 <b>AND</b>
          </p>
          <p>
            if the investment decision is made by a plan fiduciary, as defined in section 3(21) of
            such act, which is either a bank, savings and loan association, insurance company, or
            registered investment adviser, <b>OR</b>
          </p>
          <p>
            if the employee benefit plan has total assets in excess of $5,000,000, <b>OR</b>
          </p>
          <p>
            if a self-directed plan, with investment decisions made solely by persons that are
            accredited investors
          </p>
        </div>
      );
      break;
    case 13:
      readMore = (
        <p className="readMore">
          A "Special Situation" which is designed to be a catch-all category for situations where an
          investor believes that it is so abundantly clear that they are an accredited investor that
          they don't need to provide the standard evidence that the regulations normally require for
          a proper verification. Please note that it doesn't allow deviation from the legal
          definition of accredited investor; it only allows additional flexibility on how to prove
          it. This
          <a
            href="https://www.investor.gov/introduction-investing/general-resources/news-alerts/alerts-bulletins/investor-bulletins/updated-3"
            target="_blank"
            rel="noreferrer"
            className="cs-neutral-90">
            <u> SEC bulletin</u>
          </a>
          &nbsp; does a decent job describing most of the accredited investor categories. Please
          contact{' '}
          <Link target="_blank" to="mailto:accreditation@capsync.com">
            accreditation@capsync.com
          </Link>{' '}
          before you select this method of verification.
        </p>
      );
      break;
    case 39:
      readMore = (
        <div>
          <p className="row-spacing">
            {' '}
            "Family Offices" as defined in rule 202(a)(11)(G)-1 under the Investment Advisers Act of
            1940 (17 CFR 275.202(a)(11)(G)-1) meeting the requirements below, and any “family
            client” of such family office whose prospective investment in the issuer is directed by
            such family office pursuant to paragraph (iii) below:
          </p>

          <p className="row-spacing">(i) With assets under management in excess of $5,000,000,</p>
          <p className="row-spacing">
            (ii) That is not formed for the specific purpose of acquiring the securities offered,
            and
          </p>
          <p>
            (iii) Whose prospective investment is directed by a person who has such knowledge and
            experience in financial and business matters that such family office is capable of
            evaluating the merits and risks of the prospective investment
          </p>
        </div>
      );
      break;
    case 15:
      readMore = (
        <p className="readMore">
          A "Special Situation" which is designed to be a catch-all category for situations where an
          investor believes that it is so abundantly clear that they are an accredited investor that
          they don't need to provide the standard evidence that the regulations normally require for
          a proper verification.{' '}
          <span className="cs-neutral-90">
            Please note that it doesn't allow deviation from the legal definition of accredited
            investor; it only allows additional flexibility on how to prove it.
          </span>{' '}
          This &nbsp;
          <a
            href="https://www.investor.gov/introduction-investing/general-resources/news-alerts/alerts-bulletins/investor-bulletins/updated-3"
            target="_blank"
            rel="noreferrer"
            className="vi-link-color ">
            SEC bulletin
          </a>
          &nbsp; does a decent job describing most of the accredited investor categories.{' '}
          <b>
            Please contact{' '}
            <Link target="_blank" to="mailto:accreditation@capsync.com">
              accreditation@capsync.com
            </Link>{' '}
            before you select this method of verification.
          </b>
        </p>
      );
      break;
  }

  return (
    <div>
      <div>{displayMethod}</div>
      <SubMethodPopup
        showSeeMore={showSeeMore}
        setShowSeeMore={setShowSeeMore}
        showMethod={readMore}
      />
    </div>
  );
};

// PROPS TYPE
SubMethodsList.propTypes = {
  methodId: PropTypes.number
};

export default SubMethodsList;
