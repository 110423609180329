import React from 'react';
import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';

// COMPONENT IMPORTS
import CapsyncIcon from '../CapsyncIcon';
import { VALIDATION_MESSAGE } from '../../constants/message';
import { validateDuplicateFileName } from '../../config/validateDuplicateFileName';
import InvestorFileUpload from '../../pages/accreditation/common/InvestorFileUpload';

/* ============================== FILE UPLOAD ============================== */
const FileUpload = (props) => {
  const {
    methodFormik,
    onHandleFileUpload,
    errorMessage,
    setErrorMessage,
    multiple = true,
    name = 'attachments',
    showFiles = false,
    errors,
    values,
    setFieldError,
    setFieldValue,
    disabled,
    label,
    dropboxID
  } = props;
  const setFiles = (data) => setFieldValue(name, data);
  const setErrorMsg = (err) => setFieldError(name, err);
  const onDropAccepted = (files) => {
    if (onHandleFileUpload) {
      if (!multiple && files.length !== 1) {
        setErrorMessage && setErrorMessage(VALIDATION_MESSAGE.FILE_ONLY_SINGLE_IMAGE_ALLOWED);
        return false;
      }
      onHandleFileUpload(files);
    }
    if (validateDuplicateFileName(files, values[name], setErrorMsg)) {
      const currentFiles = [...files, ...values[name]];
      setFiles(currentFiles);
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      'image/jpeg': ['.jpeg', '.jpg'],
      'image/png': ['.png']
    },
    onDropAccepted
  });

  return (
    <div className={`${disabled ? 'cs-disabled ' : ''}cs-upload-box`}>
      <label className="cs-regular-sub-heading-s cs-neutral-100 form-label">
        {label || 'Upload Files'}
      </label>
      <div {...getRootProps()} className="cs-upload-container">
        <input {...getInputProps()} name={name} />
        <span className="cs-primary upload-icon">
          <CapsyncIcon title="upload-files-filled" size="28" />
        </span>
        <p className="cs-regular-sub-heading-m cs-neutral-90">
          <span className="cs-primary">Browse</span>&nbsp;or drag file here
        </p>
      </div>
      {(errorMessage || (errors && errors[name])) && (
        <span className="cs-light-body-text-s cs-danger">{errorMessage || errors[name]}</span>
      )}
      {showFiles && (
        <InvestorFileUpload
          files={values[name]}
          setFiles={setFiles}
          disabled={disabled}
          methodFormik={methodFormik}
          dropboxID={dropboxID}
        />
      )}
    </div>
  );
};

// PROPS TYPE
FileUpload.propTypes = {
  onHandleFileUpload: PropTypes.func,
  errorMessage: PropTypes.string,
  setErrorMessage: PropTypes.func,
  multiple: PropTypes.bool,
  values: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  setFieldError: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  name: PropTypes.string,
  showFiles: PropTypes.bool,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  methodFormik: PropTypes.object,
  dropboxID: PropTypes.number
};

export default FileUpload;
