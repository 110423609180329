/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import { Col, Form, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

// COMPONENT IMPORTS
import { individualInvite } from '../../../slices/collaborationSlice';
import { TOAST_MESSAGE } from '../../../constants/message';
import InputBox from '../../../components/FormFields/InputBox';
import Modal from '../../../components/Modal';
import commonNames from '../../../config/commonNames';
import validationObj from '../../../validations/validationSchema';

/* ============================== ADD USER MODAL ============================== */
const AddUserModal = ({ show, setShow, fetchCollaborators }) => {
  const dispatch = useDispatch();

  const { accessUser, user } = useSelector((state) => state.auth);

  const permissionType = [
    {
      name: 'View Only',
      value: 'view',
      tooltipContent: 'Viewing privilege only, no editing privileges.'
    },
    {
      name: 'Standard',
      value: 'standard',
      tooltipContent: ' Viewing and limited editing privileges.'
    }
  ];

  const initialValues = {
    first_name: '',
    last_name: '',
    email: '',
    permission_type: permissionType[0].value
  };

  const {
    errors,
    values,
    handleChange,
    handleSubmit,
    resetForm,
    touched,
    setFieldValue,
    isSubmitting,
    setFieldError
  } = useFormik({
    initialValues,
    validationSchema: validationObj.addUserValidationSchema,
    onSubmit: async (values) => {
      const res = await dispatch(
        individualInvite({
          ...values,
          user_id: user?.id.toString(),
          professional_user_id: accessUser?.accessUserID.toString()
        })
      ).unwrap();
      if (res.code === 200) {
        setShow(false);
        resetForm();
        toast.success(TOAST_MESSAGE.USER_INVITE_SUCCESS);
        fetchCollaborators();
      } else {
        setFieldError('email', res.message);
        toast.error(TOAST_MESSAGE.USER_INVITE_FAILED);
      }
    }
  });

  const handleCancel = () => {
    setShow(false);
    resetForm();
  };

  const body = () => (
    <React.Fragment>
      <Row>
        <Col className="col" lg={6} sm={6}>
          <InputBox
            errors={errors}
            values={values}
            name={'first_name'}
            label={'First name'}
            onChange={handleChange}
            touched={touched}
          />
        </Col>
        <Col className="col" lg={6} sm={6}>
          <InputBox
            errors={errors}
            values={values}
            name={'last_name'}
            label={'Last name'}
            onChange={handleChange}
            touched={touched}
          />
        </Col>
      </Row>
      <Row>
        <Col lg={12} sm={12}>
          <InputBox
            errors={errors}
            values={values}
            name={'email'}
            label={'Email'}
            onChange={handleChange}
            touched={touched}
          />
        </Col>
      </Row>
      <Row>
        <Col lg={12} sm={12}>
          <Form.Group className="cs-form-group last-form-field" controlId="validationFormik03">
            <Form.Label>Permissions</Form.Label>
            <div className="collaboration-radio">
              {permissionType.map(({ value, name, tooltipContent }) => (
                <span key={value}>
                  <OverlayTrigger
                    key={'top'}
                    placement={'top'}
                    overlay={
                      <Tooltip id={`tooltip-top`} className="cs-tooltip cs-regular-body-text-m">
                        {tooltipContent}
                      </Tooltip>
                    }>
                    <label>
                      <input
                        type="radio"
                        name="permission_type"
                        checked={values['permission_type'] === value}
                        onChange={() => setFieldValue('permission_type', value)}
                        className="radio-button cs-light-body-text-m"
                      />
                      {name}
                    </label>
                  </OverlayTrigger>
                </span>
              ))}
            </div>
          </Form.Group>
        </Col>
      </Row>
    </React.Fragment>
  );

  return (
    <Modal
      body={body()}
      title={commonNames.AddNewUser}
      show={show}
      handleClose={() => setShow(false)}
      isCloseButton={false}
      className={'cs-md-modal collaboration-modal'}
      saveButtonLabel={commonNames.SendInvitation}
      cancelButtonLabel={commonNames.Cancel}
      handleOnCancel={handleCancel}
      handleOnSave={handleSubmit}
      disabled={isSubmitting}
    />
  );
};

// PROPS TYPE
AddUserModal.propTypes = {
  show: PropTypes.string,
  setShow: PropTypes.func,
  fetchCollaborators: PropTypes.func
};

export default AddUserModal;
