// BackEnd App url for White label
const BASE_URL = process.env.REACT_APP_API_URL + '/api/';

// STATUS
const STATUS = {
  PROGRESS: 'progress',
  SUCCESS: 'success',
  ERROR: 'error'
};

export default {
  BASE_URL,
  STATUS
};

export const AVATAR_LIST = [
  'https://wl-universal-local.s3.amazonaws.com/users/avatar/avatar_five.png',
  'https://wl-universal-local.s3.amazonaws.com/users/avatar/avatar_one.png',
  'https://wl-universal-local.s3.amazonaws.com/users/avatar/avatar_two.png',
  'https://wl-universal-local.s3.amazonaws.com/users/avatar/avatar_three.png',
  'https://wl-universal-local.s3.amazonaws.com/users/avatar/avatar_four.png'
];
export const amortizationHeader = [
  { title: 'Date', class: 'th-mob-width', width: '180' },
  {
    title: 'Principal',
    class: 'amortization-principal th-mob-width text-end amort-alignment',
    width: '255'
  },
  {
    title: '+',
    class: 'amortization-principal-icon th-mob-width',
    width: '26'
  },
  {
    title: 'Interest',
    class: 'amortization-interest th-mob-width text-end amort-alignment',
    width: '255'
  },
  { title: '=', class: 'amortization-interest-icon th-mob-width', width: '26' },
  { title: 'Payment', class: 'th-mob-width text-end', width: '255' },
  { title: 'Total Interest Paid', class: 'th-mob-width text-end', width: '240' },
  { title: 'Total Remaining Balance', class: 'th-mob-width text-end', width: '240' },
  { title: '', class: '', width: '107' }
];

export const quickBooksArr = [
  {
    title: 'Profit & Loss',
    type: 'profit_loss',
    img_name: 'profit-and-loss-icon'
  },
  {
    title: 'Balance Sheet',
    type: 'balance_sheet',
    img_name: 'balance-sheet-icon'
  },
  {
    title: 'Statement of Cash Flows',
    type: 'cash_flow',
    img_name: 'cash-flows-icon'
  },
  {
    title: 'Trial Balance',
    type: 'trial_balance',
    img_name: 'trial-balance-icon'
  }
];

export const quickBooksColumnFilterArr = ['Total', 'Days', 'Week', 'Month', 'Quarter', 'Year'];

export const quickBooksAnotherPeriodFilterArr = [
  'Previous Period (PP)',
  'Previous Year (PY)',
  'Year to Date (YTD)',
  'Previous Year to Date (PY YTD)'
];

export const quickBooksReportPeriodFilterArr = [
  'All Dates', // 0
  'Last Month', // 1
  'Last Quarter', // 2
  'Last Year', // 3
  'Month to Date', // 4
  'Quarter to Date', // 5
  'Year to Date', // 6
  'Custom' // 7
];

export const quickBooksMainLevelArr = [
  {
    title: 'Assets',
    url: '/assets'
  },
  {
    title: 'Businesses',
    url: `/assets/business`
  }
];

export const quickBooksAccountingMethodArr = ['Cash', 'Accrual'];

export const headingList = [
  {
    width: '300',
    title: 'Description',
    key: 'description',
    filter: false,
    draggable: false,
    visible: true
  },
  { width: '180', title: 'Type', key: 'type', filter: false, draggable: true, visible: true },
  { width: '120', title: 'Symbol', key: 'symbol', filter: false, draggable: true, visible: true },
  {
    width: '100',
    title: 'Currency',
    key: 'currency',
    filter: true,
    draggable: true,
    visible: true
  },
  { width: '140', title: 'Price', key: 'price', filter: false, draggable: true, visible: true },
  { width: '140', title: 'Value', key: 'value', filter: false, draggable: true, visible: true },
  {
    width: '140',
    title: 'Quantity',
    key: 'quantity',
    filter: false,
    draggable: true,
    visible: true
  },
  {
    width: '140',
    title: 'Market Value',
    key: 'market_value',
    filter: false,
    draggable: true,
    visible: true
  },
  {
    width: '160',
    title: 'Date Updated',
    key: 'date_updated',
    filter: false,
    draggable: false,
    visible: true
  }
];

export const customPortfolioHeader = [
  'Price',
  'Value',
  'Quantity',
  'Market Value',
  'Date Updated',
  'Currency'
];

export const plaidListingSequence = [
  { category_name: 'Accounts Payable', type: 'Liability' },
  { category_name: 'Notes Payable to Banks and Others', type: 'Liability' },
  { category_name: 'Installment Account (Other)', type: 'Liability' },
  { category_name: 'Unpaid Taxes', type: 'Liability' },
  { category_name: 'Mortgages on Real Estate', type: 'Liability' },
  { category_name: 'Other Liabilities', type: 'Liability' },
  { category_name: 'Cash on Hand & In Banks', type: 'Assets' },
  { category_name: 'Savings Accounts', type: 'Assets' },
  { category_name: 'IRA or Other Retirement Account', type: 'Assets' },
  { category_name: 'Accounts & Notes Receivable', type: 'Assets' },
  { category_name: 'Life Insurance', type: 'Assets' },
  {
    category_name: 'Non-Retirement Brokerage Accounts (Stocks & Bonds)',
    type: 'Assets'
  },
  { category_name: 'Businesses', type: 'Assets' },
  { category_name: 'Automobiles', type: 'Assets' },
  { category_name: 'Other Personal Property', type: 'Assets' },
  { category_name: 'Real Estate', type: 'Assets' },
  { category_name: 'Other Assets', type: 'Assets' },
  { category_name: 'Installment Account (Auto)', type: 'Liability' },
  { category_name: 'Loan(s) Against Life Insurance', type: 'Liability' }
];
