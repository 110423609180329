import React, { useEffect, useRef, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { useOutletContext } from 'react-router-dom';

// COMPONENT IMPORTS
import SkeletonFileManagement from '../../components/Skeleton/SkeletonFileManagement';
import SkeletonArchiveTrash from '../../components/Skeleton/SkeletonArchiveTrash';
import CapsyncLoader from '../../../components/CapsyncLoader';
import CapsyncIcon from '../../../components/CapsyncIcon';

import { fileDownload, getFileType } from '../../../utils/common';

import FileManagementHeaderSection from '../HeaderSection/FileManagementHeaderSection';
import FileManagementDeleteModal from '../FileManagementDeleteModal';
import DownloadConfirmationModal from '../DownloadConfirmationModal';
import UpgradeStorage from '../FooterSection/UpgradeStorage';
import FileFolderMoveModal from '../FileFolderMoveModal';
import UnsupportedFileModal from '../UnsupportedFileModal';
import MoveAlertModal from '../MoveAlertModal';
import LoaderModal from '../LoaderModal';
import FilePreview from '../FilePreview';
import GridView from '../GridView';
import ListView from '../ListView';
import {
  deleteMsg,
  fileManagementSortingData,
  loadingMessage,
  scannedFileElem,
  TrashSortingDetails,
  useFileManagementClickOutside
} from '../FileManagementCommonElem';

// HOOKS IMPORTS
import useDebounce from '../../../utils/Hooks/useDebounce';

// API
import {
  deleteTrashItem,
  emptyTrashItem,
  getStorageDetails,
  getTrashList,
  scanDownloadFile,
  searchTrashItem,
  useStorageDetails,
  useTrashListData
} from '../../../slices/fileManagementSlice';
import { useUser } from '../../../slices/authSlice';
import { downloadItem } from '../../../slices/downloadSlice';

/* ============================== TRASH ============================== */
const Trash = () => {
  /* REDUX DATA */
  const user = useUser();
  const trashListData = useTrashListData();
  const storageDetails = useStorageDetails();

  const dispatch = useDispatch();

  /* USE REFS */
  const fmListRef = useRef(null);
  const layoutRowRef = useRef(null);
  const documentsRef = useRef(null);

  /* USE STATES */
  const [listData, setListData] = useState([]);
  const [openDropdownId, setOpenDropdownId] = useState(null);
  const [selectedRecords, setSelectedRecords] = useState([]);
  const [filter, setFilter] = useState({ title: 'All', days: 90 });
  const [lastSelected, setLastSelected] = useState(null);
  const [selectedId, setSelectedId] = useState(null);
  const [fileStorageModal, setFileStorageModal] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [emptyTrash, setEmptyTrash] = useState(false);
  const [deleteRecord, setDeleteRecord] = useState([]);
  const [sortingHeader, setSortingHeader] = useState({ ...TrashSortingDetails });
  const [loading, setLoading] = useState(true);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [moveModalOpen, setMoveModalOpen] = useState(null);
  const [moveSelectedContent, setMoveSelectedContent] = useState([]);
  const [openDuplicateModalOpen, setOpenDuplicateModalOpen] = useState(false);
  const [existingMoveFiles, setExistingMoveFiles] = useState([]);
  const [isFilePreviewModalOpen, setIsFilePreviewModalOpen] = useState(false);
  const [filePreviewDetails, setFilePreviewDetails] = useState(null);
  const [unsupportedFileModalOpen, setUnsupportedFileModalOpen] = useState(false);
  const [scannedFileDetails, setScannedFileDetails] = useState({ ...scannedFileElem });
  const [isGridView, setIsGridView] = useState(
    JSON.parse(localStorage.getItem('wlp-file-management-view-type'))
  );

  // TRASH LIST FETCH
  const fetchTrashList = async () => {
    setLoading(true);
    try {
      const payload = {
        user_id: user.id,
        lastDays: filter.days
      };
      await dispatch(getTrashList(payload)).unwrap();
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log('error', error.message);
    }
  };

  // FETCH STORAGE
  const fetchStorageDetails = async () => {
    try {
      const payload = { user_id: user?.id };
      await dispatch(getStorageDetails(payload)).unwrap();
    } catch (error) {
      console.log('error', error.message);
    }
  };

  useEffect(() => {
    fetchTrashList();
  }, [filter]);

  useEffect(() => {
    const newData = fileManagementSortingData('Trash', [], null, trashListData, sortingHeader);
    documentsRef.current = newData;
    setListData(newData);
  }, [trashListData, sortingHeader]);

  /* OUT SIDE CLICK HANDLE */
  const handleOutsideClick = () => {
    setSelectedId(null);
    setSelectedRecords([]);
    setOpenDropdownId(null);
    setLastSelected(null);
  };

  useFileManagementClickOutside(layoutRowRef, fmListRef, documentsRef, handleOutsideClick);

  // SEARCH
  const handleSearch = useDebounce(async (value) => {
    try {
      let payload = {
        user_id: user.id,
        keyword: value
      };
      await dispatch(searchTrashItem(payload)).unwrap();
    } catch (error) {
      console.log('error', error.message);
    }
  }, 500);

  // DELETE
  const handleDelete = async () => {
    setDeleteLoading(true);
    setDeleteModalOpen(false);
    let IdsArray = [];
    deleteRecord.map((val) => IdsArray.push(val.id));
    try {
      let payload = {
        user_id: user.id,
        type: 'trash',
        Ids: [...IdsArray]
      };
      let response;
      if (emptyTrash) {
        response = await dispatch(emptyTrashItem(user.id)).unwrap();
      } else {
        response = await dispatch(deleteTrashItem(payload)).unwrap();
      }
      if (response.status) {
        toast.success(deleteMsg(deleteRecord));
        fetchTrashList();
        fetchStorageDetails();
      }
      setDeleteRecord([]);
      setDeleteLoading(false);
      setEmptyTrash(false);
    } catch (error) {
      setDeleteModalOpen(false);
      setDeleteRecord([]);
      setDeleteLoading(false);
      setEmptyTrash(false);
      console.log('error', error.message);
    }
  };

  // MOVE
  const handleMove = (data) => {
    setMoveModalOpen(true);
    setMoveSelectedContent([...data]);
  };

  // SCAN
  const handleScanDownloadFile = async (ids = []) => {
    try {
      const response = await dispatch(scanDownloadFile({ ids })).unwrap();
      return response?.data;
    } catch (error) {
      console.error('Error during scan download:', error);
    }
  };

  // DOWNLOAD & PROTECTED DOWNLOAD
  const handleFileDownload = async (data, is_protected = false) => {
    if (storageDetails?.used_space > storageDetails?.total_space) {
      setFileStorageModal(true);
      return;
    }

    const IdsArray = data.map((val) => val.id);
    const scanResponse = await handleScanDownloadFile(IdsArray);

    if (scanResponse?.pending_files?.length > 0) {
      toast.info('Scanning process in progress. Please try again later.');
      setSelectedRecords([]);
      return;
    }

    if (data.length === 1 && data[0].attachment && !is_protected) {
      if (scanResponse?.infected_files?.length > 0 || scanResponse?.not_scan_files?.length > 0) {
        setScannedFileDetails({
          modalOpen: true,
          isBigFile: scanResponse.not_scan_files?.length > 0,
          details: data
        });
      } else {
        fileDownload(data[0]);
      }
      return;
    }

    const infectedFileNames = scanResponse?.infected_files?.map((obj) => obj.name).join(', ') || '';
    const isSingleAttachment = data.length === 1 && !data[0].attachment;

    if (scanResponse?.infected_files?.length > 0) {
      if (isSingleAttachment) {
        toast.error(`${infectedFileNames} This file cannot be downloaded.`);
        setSelectedRecords([]);
        return;
      } else {
        toast.error(`${infectedFileNames} These files cannot be downloaded.`);
        IdsArray = IdsArray.filter(
          (id) => !scanResponse.infected_files.some((obj) => obj.id === id)
        );
      }
    }

    if (IdsArray.length === 0) {
      setSelectedRecords([]);
      return;
    }

    try {
      const payload = { user_id: user.id, id: IdsArray, is_protected: is_protected };
      await dispatch(downloadItem(payload)).unwrap();
    } catch (error) {
      console.error('Error during file download:', error.message);
    }
  };

  // DOUBLE CLICK
  const handleDoubleClick = (data) => {
    if (data.attachment) {
      const fileType = getFileType(data.name);
      setFilePreviewDetails(data);
      if (data.size <= process.env.REACT_APP_FILE_PREVIEW_SIZE_ALLOWED && fileType !== 'unknown') {
        setIsFilePreviewModalOpen(true);
      } else {
        setUnsupportedFileModalOpen(true);
      }
    }
  };

  const [isLoader] = useOutletContext();

  return (
    <React.Fragment>
      {isLoader ? (
        listData && listData.length === 0 ? (
          <SkeletonArchiveTrash />
        ) : (
          <SkeletonFileManagement
            isGridView={isGridView}
            breadcrumbDetails={[]}
            moduleName="Trash"
          />
        )
      ) : (
        <section className="individual-main-section main-section file-management-section trash-page">
          <Container fluid>
            <Row>
              <Col lg={12}>
                <FileManagementHeaderSection
                  moduleName="Trash"
                  breadcrumbDetails={[]}
                  isGridView={isGridView}
                  setIsGridView={setIsGridView}
                  setSelectedId={setSelectedId}
                  layoutRowRef={layoutRowRef}
                  selectedRecords={selectedRecords}
                  fetchList={fetchTrashList}
                  setSelectedRecords={setSelectedRecords}
                  handleSearch={handleSearch}
                  handleFileDownload={() => handleFileDownload(selectedRecords, false)}
                  handleProtectedFileDownload={() => handleFileDownload(selectedRecords, true)}
                  handleMove={() => handleMove(selectedRecords)}
                  handleDelete={() => {
                    setDeleteRecord(selectedRecords);
                    setDeleteModalOpen(true);
                  }}
                />
              </Col>
              {loading ? (
                <CapsyncLoader />
              ) : (
                <Col lg={12}>
                  <div className="cs-badge cs-info cs-trash">
                    <h4 className="cs-regular-h4">
                      <span className="cs-primary">
                        <CapsyncIcon title="info-outlined" size="22" />
                      </span>
                      Items in trash will be deleted forever after 90 days
                    </h4>
                    {listData.length > 0 && (
                      <span
                        className="cs-primary cs-regular-h5 cursor-pointer"
                        onClick={() => {
                          setEmptyTrash(true);
                          setDeleteRecord(listData);
                          setDeleteModalOpen(true);
                        }}>
                        Empty Trash
                      </span>
                    )}
                  </div>
                  {isGridView ? (
                    <GridView
                      moduleName="Trash"
                      data={listData}
                      selectedRecords={selectedRecords}
                      setSelectedRecords={setSelectedRecords}
                      openDropdownId={openDropdownId}
                      setOpenDropdownId={setOpenDropdownId}
                      setLastSelected={setLastSelected}
                      lastSelected={lastSelected}
                      isGridView={isGridView}
                      sortingHeader={sortingHeader}
                      setSortingHeader={setSortingHeader}
                      filter={filter}
                      setFilter={setFilter}
                      handleDelete={(value) => {
                        setDeleteRecord(value);
                        setDeleteModalOpen(true);
                      }}
                      handleMove={(data) => handleMove(data)}
                      handleFileDownload={(data) => handleFileDownload(data, false)}
                      handleProtectedFileDownload={(data) => handleFileDownload(data, true)}
                      handleDoubleClick={handleDoubleClick}
                    />
                  ) : (
                    <ListView
                      moduleName="Trash"
                      data={listData}
                      breadcrumbDetails={[]}
                      selectedRecords={selectedRecords}
                      setSelectedRecords={setSelectedRecords}
                      sortingHeader={sortingHeader}
                      fmListRef={fmListRef}
                      setSortingHeader={setSortingHeader}
                      selectedId={selectedId}
                      setSelectedId={setSelectedId}
                      setLastSelected={setLastSelected}
                      lastSelected={lastSelected}
                      filter={filter}
                      setFilter={setFilter}
                      handleDelete={(value) => {
                        setDeleteRecord(value);
                        setDeleteModalOpen(true);
                      }}
                      handleMove={(data) => handleMove(data)}
                      handleFileDownload={(data) => handleFileDownload(data, false)}
                      handleProtectedFileDownload={(data) => handleFileDownload(data, true)}
                      handleDoubleClick={handleDoubleClick}
                    />
                  )}
                </Col>
              )}
              <Col lg={12}>
                {!loading && (
                  <UpgradeStorage
                    setFileStorageModal={setFileStorageModal}
                    fileStorageModal={fileStorageModal}
                  />
                )}
              </Col>
            </Row>
          </Container>
        </section>
      )}
      {deleteModalOpen && (
        <FileManagementDeleteModal
          moduleName="Trash"
          showModal={deleteModalOpen}
          deleteRecord={deleteRecord}
          onCancelClick={() => {
            setDeleteModalOpen(false);
            setDeleteRecord([]);
          }}
          onConfirmDelete={() => handleDelete()}
        />
      )}
      {deleteLoading && (
        <LoaderModal loading={deleteLoading} message={loadingMessage(deleteRecord, 'delete')} />
      )}
      {moveModalOpen && (
        <FileFolderMoveModal
          moveModalOpen={moveModalOpen}
          moveSelectedContent={moveSelectedContent}
          setOpenDuplicateModalOpen={setOpenDuplicateModalOpen}
          setExistingMoveFiles={setExistingMoveFiles}
          closeModal={(callListApi = true) => {
            callListApi && fetchTrashList();
            setMoveModalOpen(false);
            setMoveSelectedContent([]);
          }}
        />
      )}
      {openDuplicateModalOpen && (
        <MoveAlertModal
          showModal={openDuplicateModalOpen}
          existingMoveFiles={existingMoveFiles}
          onCancelClick={() => {
            setOpenDuplicateModalOpen(false);
            setExistingMoveFiles([]);
          }}
        />
      )}
      {isFilePreviewModalOpen && (
        <FilePreview
          showModal={isFilePreviewModalOpen}
          fileDetails={filePreviewDetails}
          onCancelClick={() => {
            setIsFilePreviewModalOpen(false);
            setFilePreviewDetails(null);
          }}
        />
      )}
      {unsupportedFileModalOpen && (
        <UnsupportedFileModal
          showModal={unsupportedFileModalOpen}
          onCancelClick={() => {
            setUnsupportedFileModalOpen(false);
            setFilePreviewDetails(null);
          }}
          onDownloadClick={() => {
            fileDownload(filePreviewDetails);
            setUnsupportedFileModalOpen(false);
            setFilePreviewDetails(null);
          }}
        />
      )}
      {scannedFileDetails?.modalOpen && (
        <DownloadConfirmationModal
          scannedFileDetails={scannedFileDetails}
          onCancelClick={() => {
            setSelectedRecords([]);
            setScannedFileDetails({ ...scannedFileElem });
          }}
        />
      )}
    </React.Fragment>
  );
};

export default Trash;
