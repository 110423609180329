import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

// COMPONENT IMPORTS
import CapsyncIcon from '../../../components/CapsyncIcon';
import { handleDeleteMedia } from '../SubMethods/SubMethodsBasicFunctions';

/* ============================== INVESTOR FILE UPLOAD ============================== */
const InvestorFileUpload = ({ files, setFiles, methodFormik, dropboxID }) => {
  const dispatch = useDispatch();
  const { rowID } = useSelector((state) => state.accreditation);
  const { user } = useSelector((state) => state.auth);

  const removeFile = (deletedFile) => {
    const filteredArray = files.filter((file) => file.name != deletedFile);
    setFiles(filteredArray);
  };

  const fileSize = (iter) => {
    const fileInKB = (iter / 1024).toFixed(2);
    return fileInKB;
  };

  const fileHtml = (key, title, size, removeFn) => {
    return (
      <div className="cs-selected-files" key={key}>
        <span className="cs-neutral-60 cs-fileicon">
          <CapsyncIcon title="file-icon-outlined" size="22" />
        </span>

        <div className="cs-files-names">
          <p className="cs-regular-sub-heading-xs">
            <span>{title}</span>
          </p>
          {size && <p className="cs-light-body-text-s cs-neutral-80">{fileSize(size)} KB</p>}
        </div>
        <div className="cs-file-icon icons">
          <span className="cs-icon-badge-danger cs-danger cs-file-icon cs-icons" onClick={removeFn}>
            <CapsyncIcon title="delete-outlined" size="18" />
          </span>
        </div>
      </div>
    );
  };

  return (
    <div className="cs-files">
      <div className="cs-scroller">
        {files &&
          files.map((file, index) =>
            fileHtml(index, file.name, file.size, () => removeFile(file.name))
          )}
        {methodFormik?.values[`upload_drop_box_${dropboxID}`].map((item, index) =>
          fileHtml(item.original_file_name + index, item.original_file_name, null, () =>
            handleDeleteMedia(
              item.id,
              dispatch,
              user.id,
              methodFormik,
              item.upload_drop_box_id,
              rowID
            )
          )
        )}
      </div>
    </div>
  );
};

InvestorFileUpload.propTypes = {
  files: PropTypes.array,
  setFiles: PropTypes.func,
  methodFormik: PropTypes.object,
  dropboxID: PropTypes.number
};

export default InvestorFileUpload;
