import React from 'react';
import PropTypes from 'prop-types';

// COMPONENT IMPORTS
import Modal from './Modal';
import CapsyncIcon from './CapsyncIcon';

/* ============================== DELETE MODAL ============================== */
const DeleteModal = ({ show, handleCloseModel, handleDeleteData, loader, deleteMsg }) => {
  return (
    <Modal
      size="md"
      title="Are you sure?"
      show={show}
      className="cs-md-modal cs-delete-modal"
      handleClose={handleCloseModel}
      body={
        <div className="delete-modal-content">
          <span className="cs-icon">
            <CapsyncIcon title="delete-validation" size={100} className="action-icon" />
          </span>
          <p className="cs-light-body-text-m cs-neutral-90">
            {!deleteMsg || deleteMsg.title === ''
              ? 'Are you sure you want to delete this item? Once deleted, this action cannot be reversed.'
              : deleteMsg.title}
          </p>
        </div>
      }
      saveButtonLabel="Delete"
      handleOnSave={handleDeleteData}
      cancelButtonLabel="Cancel"
      handleOnCancel={handleCloseModel}
      isCloseButton={false}
      loading={loader}
    />
  );
};

// PROPS TYPE
DeleteModal.propTypes = {
  show: PropTypes.bool,
  handleCloseModel: PropTypes.func,
  handleDeleteData: PropTypes.func,
  loader: PropTypes.object,
  deleteMsg: PropTypes.object
};

export default DeleteModal;
