import React, { useState, useEffect } from 'react';
import { Col, Form, Row, Image } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import NumberFormat from 'react-currency-format';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import Select from 'react-select';
import moment from 'moment';
import * as yup from 'yup';

// COMPONENT IMPORTS
import {
  preventNegativeNumbers,
  preventSpecialCharacters,
  selectOptionsArr,
  yearType
} from '../../../utils/common';
import VinCarModal from './VincarModal';
import Modal from '../../../components/Modal';
import ConfirmEditModal from './ConfirmEditModal';
import CapsyncIcon from '../../../components/CapsyncIcon';
import { TOAST_MESSAGE } from '../../../constants/message';
import InputBox from '../../../components/FormFields/InputBox';
import { uniValidation } from '../../../constants/uniValidation';
import TextAreaBox from '../../../components/FormFields/TextAreaBox';
import DatePickerBox from '../../../components/FormFields/DatePickerBox';
import { descriptionField, marketValueField } from '../../../validations';
import InputNumberField from '../../../components/FormFields/InputNumberField';
import SaveORCancelButtons from '../../../components/Buttons/SaveORCancelButtons';
import CategoryFileUpload from '../../../components/FormFields/CategoryFileUpload';

// API
import {
  categoryAddRecords,
  categoryUpdateRecords,
  fetchMakeData,
  fetchModelData,
  getAssetsCategory,
  getAutomobileMarketValue,
  useCategoryDetail
} from '../../../slices/categorySlice';
import { useProgressUploadDetails } from '../../../slices/commonSlice';
import { useStorageDetails } from '../../../slices/fileManagementSlice';
import { getAssetsDashboard, getMainDashboard } from '../../../slices/dashboardSlice';

/* ============================== AUTOMOBILE ============================== */
const Automobile = ({ isOpen = false, handleCancel, handleCategoryFormSubmit, record }) => {
  const dispatch = useDispatch();

  const { user } = useSelector((state) => state.auth);
  const progressUploadDetails = useProgressUploadDetails();
  const storageDetails = useStorageDetails();
  const categories = useCategoryDetail();
  const categoryId = categories?.category_id;

  const [loading, setLoading] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(isOpen);
  const [isVehicleDBChecked, setIsVehicleDBChecked] = useState(true); // Check for Manual or API value
  const [showConfirmEditModal, setShowConfirmEditModal] = useState(false); // To keep or update edit data
  const [vehicleDBLoader, setVehicleDBLoader] = useState(false); // Loader for API value
  const [vehicleDBMarketValue, setVehicleDBMarketValue] = useState([]);
  const [showVinModal, setShowVinModal] = useState(false); // Vin modal for Trim & condition
  const [searchBy, setSearchBy] = useState('vin'); // VIN or YMM
  const [carDetailsVin, setCarDetailsVin] = useState({
    vin: '',
    trim: '',
    condition: '',
    market_value: '',
    description: ''
  });
  const [carDetailsYMM, setCarDetailsYMM] = useState({
    ymm: { year: 0, make: '', model: '' },
    trim: '',
    condition: '',
    market_value: '',
    description: ''
  });
  const [errorYmm, setErrorYmm] = useState(''); // Error for YMM
  const [vehicleDBError, setVehicleDBError] = useState(''); // API error
  const [makeType, setMakeType] = useState([]);
  const [modelType, setModelType] = useState([]);
  const [isMarketValueFetched, setIsMarketValueFetched] = useState(false);
  const [activeDropdown, setActiveDropdown] = useState(null);
  const [isMakeLoading, setIsMakeLoading] = useState(false);
  const [isModelLoading, setIsModelLoading] = useState(false);

  // YMM dropdown settings
  const stringYears = yearType.map((year) => String(year));
  const yearOptions = selectOptionsArr(stringYears);
  const makeOptions = selectOptionsArr(makeType);
  const modelOptions = selectOptionsArr(modelType);
  const handleFocus = (dropdown) => {
    setActiveDropdown(dropdown);
  };

  const handleDropdownBlur = () => {
    setActiveDropdown(null);
  };
  const colourStyles = {
    option: (styles, { isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isDisabled
          ? undefined
          : isSelected
            ? 'var(--primary-surface)'
            : isFocused
              ? 'var(--primary-surface)'
              : undefined,
        color: isSelected ? 'var(--primary-main)' : '#424242'
      };
    }
  };
  const [initialValues, setInitialValues] = useState({
    user_id: user?.id,
    description: '',
    creditor_name: '',
    market_value: '',
    payment: '',
    balance_due: '',
    vin: '',
    date_updated: moment().format(),
    notes: '',
    attachments: []
  });
  const [storageError, setStorageError] = useState('');

  useEffect(() => {
    if (record) {
      const searchBy = record?.vin !== '' || record?.api_provider === '' ? 'vin' : 'ymm';
      let conditionVal = '';
      let trimVal = '';
      setInitialValues({
        user_id: record?.user_id,
        description: record?.description,
        creditor_name: record?.creditor_name,
        market_value: record?.market_value && record?.market_value.toLocaleString('en-US'),
        payment: record?.payment && record?.payment.toLocaleString('en-US'),
        balance_due: record?.balance_due && record?.balance_due.toLocaleString('en-US'),
        vin: record?.api_provider === 'vehicleDB' ? record?.vin : '',
        date_updated: new Date(moment(record?.date_updated).format('MM/DD/YYYY')),
        notes: record?.notes
      });
      setIsVehicleDBChecked(record?.is_manual === false);
      setSearchBy(searchBy); // Set vin or ymm
      if (record?.api_provider === 'vehicleDB') {
        if (record?.trimtype_vin && record?.conditiontype_vin) {
          trimVal = record?.trimtype_vin;
          conditionVal = record?.conditiontype_vin;
        }

        if (record?.trimtype_ymm && record?.conditiontype_ymm) {
          trimVal = record?.trimtype_ymm;
          conditionVal = record?.conditiontype_ymm;
        }
        // setting all car details
        let vinData = { vin: '', trim: '', condition: '', market_value: '' };
        let ymmData = {
          ymm: { year: 0, make: '', model: '' },
          trim: '',
          condition: '',
          market_value: ''
        };
        if (searchBy === 'vin') {
          vinData = {
            vin: record?.vin ? record?.vin : '',
            trim: trimVal,
            condition: conditionVal,
            market_value: record?.api_value
          };
        } else {
          ymmData = {
            ymm:
              searchBy == 'ymm'
                ? {
                    year: record?.year,
                    make: record?.make,
                    model: record?.model
                  }
                : { year: 0, make: '', model: '' },
            trim: trimVal,
            condition: conditionVal,
            market_value: record?.api_value
          };
        }
        // Store data for VIN
        setCarDetailsVin(vinData);
        // store data for YMM
        setCarDetailsYMM(ymmData);
      }
    }
  }, [record]);

  const assetAutomobilesValidationSchema = yup.object().shape({
    description: descriptionField(),
    vin:
      searchBy === 'vin' &&
      isVehicleDBChecked &&
      yup
        .string()
        .required('Enter VIN number')
        .min(17, 'Enter a valid VIN number')
        .max(17, 'Enter a valid VIN number')
        .matches(/^[a-zA-Z0-9]+$/, '* This field cannot contain white space and special character'),
    creditor_name: yup.string().max(40, uniValidation.assets_and_liabilities.creditor_name.max),
    market_value: !isVehicleDBChecked && marketValueField(),
    payment: yup.string().optional(),
    balance_due: yup.string().optional()
  });

  const { values, handleSubmit, setFieldValue, errors, touched, setFieldError, resetForm } =
    useFormik({
      initialValues,
      enableReinitialize: true,
      validationSchema: assetAutomobilesValidationSchema,
      onSubmit: async (values) => {
        const trim = searchBy === 'ymm' ? carDetailsYMM.trim : carDetailsVin.trim;
        const condition = searchBy === 'ymm' ? carDetailsYMM.condition : carDetailsVin.condition;
        const marketValue =
          searchBy === 'ymm' ? carDetailsYMM.market_value : carDetailsVin.market_value;
        values.market_value = isVehicleDBChecked
          ? marketValue.toLocaleString('en-US')
          : values.market_value.toLocaleString('en-US');

        if (!record && isVehicleDBChecked) {
          values.api_value = marketValue;
        }

        if (isVehicleDBChecked && searchBy === 'ymm' && (year == '' || make == '' || model == '')) {
          setErrorYmm('Enter year, make, model');
          setLoading(false);
          return false;
        }
        if (values.market_value === 0 || values.market_value === '') {
          searchBy === 'vin' && setVehicleDBError('Please enter valid VIN number.');
          setShowVinModal(true);
          setLoading(false);
          return false;
        }
        // record && (values.id = record.id);
        values.user_id = user?.id;
        // values.pfs_access_id = id ? id : 0;
        values.is_manual = !isVehicleDBChecked; // Manual or API Value
        values.api_provider = isVehicleDBChecked ? 'vehicleDB' : '';

        if (carDetailsVin.vin !== '') {
          // vin db values for trim & condition
          values.trimtype_vin = trim;
          values.conditiontype_vin = condition;
          values.vin = carDetailsVin.vin;
          // reset ymm values
          values.trimtype_ymm = '';
          values.conditiontype_ymm = '';
          values.year = '';
          values.make = '';
          values.model = '';
        } else {
          // ymm db values for trim & condition
          values.trimtype_ymm = trim;
          values.conditiontype_ymm = condition;
          values.year = year;
          values.make = make;
          values.model = model;
          // reset vin vlaues
          values.vin = '';
          values.trimtype_vin = '';
          values.conditiontype_vin = '';
        }

        if (values.is_manual) {
          values.trimtype_ymm = '';
          values.conditiontype_ymm = '';
          values.year = '';
          values.make = '';
          values.model = '';
          values.trimtype_vin = '';
          values.conditiontype_vin = '';
          values.vin = '';
        }
        const formData = new FormData();
        const uploadedData = storageDetails?.used_space;
        const inProgressData = progressUploadDetails.length
          ? progressUploadDetails.reduce((acc, item) => acc + item.size, 0)
          : 0;

        const { attachments, ...dataObj } = values;
        !record && attachments.forEach((file) => formData.append('attachments', file));
        formData.append('data_obj', JSON.stringify(dataObj));
        formData.append('usedSpace', uploadedData + inProgressData);
        const data = {
          category: 15,
          mainCategory: 'assets',
          formData: formData
        };
        setLoading(true);
        if (!record) {
          dispatch(categoryAddRecords(data))
            .unwrap()
            .then(async (response) => {
              if (response.code === 500) {
                setFieldError('attachments', response?.message);
              }
              if (response.code === 200) {
                dispatch(getMainDashboard(user?.id));
                setIsOpenModal(false);
                handleCategoryFormSubmit();
                const payload = { categoryId: categoryId, userId: user?.id };
                categories.parent === 'assets' &&
                  (await dispatch(getAssetsCategory(payload)).unwrap());
                await dispatch(getAssetsDashboard({ userId: user?.id, orderId: 0 })).unwrap();
              }
              if (response.code === 500) {
                setStorageError(response.message);
              }
            })
            .catch((err) => {})
            .finally(() => {
              setLoading(false);
            });
        } else {
          dispatch(
            categoryUpdateRecords({
              category: 15,
              mainCategory: 'assets',
              rowId: record && record?.id,
              values
            })
          )
            .unwrap()
            .then(async (response) => {
              if (response.code === 200) {
                setIsOpenModal(false);
                handleCategoryFormSubmit();
                toast.success(TOAST_MESSAGE.RECORD_UPDATE_SUCCESS);
                const payload = { categoryId: categoryId, userId: user?.id };
                categories.parent === 'assets' &&
                  (await dispatch(getAssetsCategory(payload)).unwrap());
                await dispatch(getMainDashboard(user?.id)).unwrap();
              }
            })
            .catch((err) => {})
            .finally(() => {
              setLoading(false);
            });
        }
      }
    });

  const handleChange = (e) => {
    // setLoading(false); // unable form input
    setFieldValue(e.target.name, e.target.value);
    setVehicleDBError(''); // reset API value issue
    if (e.target.name === 'vin') {
      // Update VIN new data
      setCarDetailsVin((prevState) => ({
        ...prevState,
        vin: e.target.value,
        trim: '',
        condition: '',
        market_value: '',
        description: ''
      }));
      // Clear extisting data for YMM
      setCarDetailsYMM({
        ymm: { year: 0, make: '', model: '' },
        trim: '',
        condition: '',
        market_value: '',
        description: ''
      });
      // setFieldValue('description', '');
      setFieldValue('vin', e.target.value.trim().toUpperCase()); // Only UPPERCASE allowed
      // Call API for Market value fetch for VIN

      if (e.target.value.length === 17) {
        if (/^[a-zA-Z0-9]+$/.test(e.target.value))
          fetchAutomobileVehicleMarketValue({ vin: e.target.value.toUpperCase() });
      }
    }
  };

  const handleChangeVehicleDb = () => {
    // setLoading(false); // unable form input
    setErrorYmm(''); // reset YMM errors
    setVehicleDBError(''); // reset API value issue
    if (
      record &&
      record.id &&
      record.api_provider === 'vehicleDB' &&
      !isVehicleDBChecked === false
    ) {
      setShowConfirmEditModal(true);
    } else {
      setIsVehicleDBChecked((prevState) => !prevState);
    }
  };

  const handleCarSearchByChange = (e) => {
    // setLoading(false); // unable form input
    setErrorYmm(''); // reset YMM errors
    setVehicleDBError(''); // reset API value issue
    setSearchBy(e.target.value);
  };

  const handleChangeModel = (type, e) => {
    // setLoading(false); // unable form input
    setErrorYmm(''); // reset YMM errors
    setVehicleDBError(''); // reset API value issue
    const {
      ymm: { year, make }
    } = carDetailsYMM;
    // When year changed for YMM selection
    if (type === 'year') {
      setMakeType([]); // Clear makeType when year is changed
      setModelType([]);
      setCarDetailsYMM((prev) => ({
        ...prev,
        ymm: {
          ...prev.ymm,
          make: '',
          model: ''
        },
        trim: '',
        condition: '',
        market_value: ''
      }));
      // setFieldValue('description', '');
    } else if (type === 'make') {
      setModelType([]);
      setCarDetailsYMM((prev) => ({
        ...prev,
        ymm: {
          ...prev.ymm,
          model: ''
        },
        trim: '',
        condition: '',
        market_value: ''
      }));
      // setFieldValue('description', '');
    }
    setCarDetailsYMM((prev) => ({
      ...prev,
      ymm: { ...prev.ymm, [type]: e.value },
      trim: '',
      condition: '',
      market_value: '',
      description: ''
    }));

    // Clear esiting VIN data on change of YMM
    setCarDetailsVin({ vin: '', trim: '', condition: '', market_value: '', description: '' });

    if (type === 'model' && e.value !== '') {
      fetchAutomobileVehicleMarketValue({ year, make, model: e.value });
      setIsMarketValueFetched(true);
    }
  };

  const fetchMake = async (year) => {
    try {
      makeType.length === 0 && setIsMakeLoading(true);
      const response = await dispatch(fetchMakeData({ year })).unwrap();

      if (response.code === 200) {
        setMakeType(response.data.makes);
        setIsMakeLoading(false);
      } else {
        setIsMakeLoading(false);
      }
    } catch (err) {
      console.log(err);
      setIsMakeLoading(false);
    }
  };

  const fetchModel = async (year, make) => {
    try {
      modelType.length == 0 && setIsModelLoading(true);
      const response = await dispatch(fetchModelData({ year, make })).unwrap();
      if (response.code === 200) {
        setModelType(response.data.models);
        setIsModelLoading(false);
      } else {
        setIsModelLoading(false);
      }
    } catch (err) {
      console.log(err);
      setIsModelLoading(false);
    }
  };

  const fetchAutomobileVehicleMarketValue = (data) => {
    setVehicleDBLoader(true);
    dispatch(getAutomobileMarketValue(data))
      .unwrap()
      .then((response) => {
        setVehicleDBLoader(false);
        if (response.code === 200) {
          const {
            basic: { year, make, model },
            market_value
          } = response.data.data;
          setVehicleDBMarketValue(market_value.market_value_data);
          setFieldValue('description', `${year} ${make} ${model}`); // ymm display format
          // setDescriptionValue(`${year} ${make} ${model}`);//storing desc val
          setShowVinModal(true);
          if (market_value.market_value_data.length === 0) {
            setVehicleDBError(
              'Vehicle Databases cannot determine a market value for this car. Please enter a manual value.'
            );
          }
        } else {
          setVehicleDBMarketValue([]);
          setVehicleDBError(
            'Vehicle Databases cannot determine a market value for this car. Please enter a manual value.'
          );
        }
      });
  };

  const handleCancelTrimCondition = () => {
    setShowVinModal(false);
  };

  // Set trim & condition with market value
  const handleContinueTrimCondition = (carObj) => {
    const { trim, condition, market_value } = carObj;
    setShowVinModal(false);
    // setLoading(false);
    if (searchBy === 'ymm') {
      setCarDetailsYMM((prevState) => ({
        ...prevState,
        trim: trim,
        condition: condition,
        market_value: market_value
      }));
    } else {
      setCarDetailsVin((prevState) => ({
        ...prevState,
        trim: trim,
        condition: condition,
        market_value: market_value
      }));
    }

    setFieldValue('market_value', market_value);
  };

  const handleUpdateMarketValue = (isUpdate) => {
    // on user update market value in edit
    setShowConfirmEditModal(false);
    setIsVehicleDBChecked(!isUpdate);
  };
  useEffect(() => {
    const {
      ymm: { year, make }
    } = carDetailsYMM;

    // if (year !== 0 && make === '' ) {
    //     // Set make list options on year basis
    //     fetchMake(year);
    // }
    // if ( make !== '') {
    //     //Set model list options on make basis
    //     fetchModel(year, make);
    // }
    if (!isMarketValueFetched) {
      if (activeDropdown === 'make') {
        fetchMake(year);
      }
      if (activeDropdown === 'model') {
        fetchModel(year, make);
      }
    }

    // Reset the market value fetched state
    setIsMarketValueFetched(false);
  }, [activeDropdown]);
  const {
    ymm: { year, make, model }
  } = carDetailsYMM;
  const trim = searchBy === 'ymm' ? carDetailsYMM.trim : carDetailsVin.trim;
  const condition = searchBy === 'ymm' ? carDetailsYMM.condition : carDetailsVin.condition;
  const marketValue = searchBy === 'ymm' ? carDetailsYMM.market_value : carDetailsVin.market_value;

  const body = (
    <React.Fragment>
      <Form onSubmit={handleSubmit} encType="multipart/form-data">
        <Row>
          <Col lg={12} sm={12}>
            <div className="description-check">
              <InputBox
                label={'Description'}
                name={'description'}
                values={values}
                onChange={handleChange}
                errors={errors}
                touched={touched}
                type={'text'}
                onKeyDown={preventSpecialCharacters}
              />
              <div className="attom-data-lpd automobile-check">
                <div className="bottom-text-box">
                  <span className="checkboxREalestate">
                    <Form.Check
                      type="checkbox"
                      checked={isVehicleDBChecked}
                      onChange={handleChangeVehicleDb}
                      className="cs-form-check-box"
                    />
                    <div className="d-flex">
                      <p
                        className="attom-mt cursor-pointer cs-neutral-90 cs-regular-body-text-m"
                        onClick={handleChangeVehicleDb}>
                        Use Vehicle Databases to get the current market value of your car
                      </p>
                      <Image
                        src={require('../../../assets/images/other/vehicle-database.png')}
                        alt="Attomdata"
                        className="Attom-icon-img cursor-pointer"
                        onClick={handleChangeVehicleDb}
                        width={80}
                        height={14}
                      />
                    </div>
                  </span>
                </div>
                <p className="cs-light-body-text-m cs-neutral-70 text-start">
                  By selecting this box, you agree to the Vehicle Databases&nbsp;
                  <Link
                    className="cs-primary-main"
                    target="_blank"
                    to="https://vehicledatabases.com/terms-and-conditions">
                    Terms of Use.
                  </Link>
                </p>
              </div>
            </div>
          </Col>
          {isVehicleDBChecked && (
            <Col lg={12}>
              <div className="select-car-details-box">
                <h4 className="cs-semi-bold-h4 cs-neutral-90">Enter Car Details</h4>
                <div className="search-radio cs-regular-body-text-m">
                  <span className="cs-regular-body-text-m cs-neutral-100">Search</span>
                  <div className="automobile-responsive-radio">
                    <div className="cs-radio-btn">
                      <label>
                        <input
                          name="toggle"
                          type="radio"
                          value="vin"
                          checked={searchBy === 'vin'}
                          onChange={handleCarSearchByChange}
                          className="radio-button"
                        />
                        VIN
                      </label>
                    </div>
                    <div className="cs-radio-btn">
                      <label>
                        <input
                          name="toggle"
                          type="radio"
                          value="ymm"
                          checked={searchBy === 'ymm'}
                          onChange={handleCarSearchByChange}
                          className="radio-button"
                        />
                        Year, Make and Model
                      </label>
                    </div>
                  </div>
                </div>

                {searchBy === 'vin' && (
                  <Col lg={12} md={6}>
                    <Form.Group className="cs-form-group last-car-field">
                      <Form.Label>Vehicle Identification Number</Form.Label>
                      <Form.Control
                        type="text"
                        autoComplete="off"
                        // placeholder="Enter Car VIN number"
                        name="vin"
                        value={values.vin}
                        className={values.vin && 'cs-input-field-active'}
                        onChange={handleChange}
                        maxLength={17}
                        onKeyDown={(e) => !/^[0-9a-zA-Z]*$/.test(e.key) && e.preventDefault()}
                      />
                      {errors.vin && touched.vin && (
                        <span className="form-error-msg cs-light-body-text-s cs-danger">
                          {errors.vin}
                        </span>
                      )}
                      {!errors.vin && vehicleDBError && (
                        <span className="form-error-msg cs-light-body-text-s cs-danger">
                          {vehicleDBError}
                        </span>
                      )}
                    </Form.Group>
                  </Col>
                )}

                {searchBy === 'ymm' && (
                  <Row className="select-car-dropdown">
                    <Col lg={4} md={4}>
                      <Form.Group className="cs-form-group cs-regular-body-text-m">
                        <Form.Label>Car Year</Form.Label>
                        <Select
                          options={yearOptions}
                          value={
                            year !== 0
                              ? { value: year.toString(), label: year }
                              : { label: 'Choose year' }
                          }
                          onChange={(e) => handleChangeModel('year', e)}
                          styles={colourStyles}
                          isDisabled={vehicleDBLoader || isMakeLoading || isModelLoading}
                          onFocus={() => handleFocus('year')}
                          onBlur={handleDropdownBlur}
                        />
                        {errorYmm && (
                          <span className="form-error-msg cs-light-body-text-s cs-danger">
                            {errorYmm}
                          </span>
                        )}
                        {vehicleDBError && (
                          <span className="form-error-msg cs-light-body-text-s cs-danger">
                            {vehicleDBError}
                          </span>
                        )}
                      </Form.Group>
                    </Col>
                    <Col lg={4} md={4}>
                      <Form.Group className="cs-form-group cs-regular-body-text-m">
                        <Form.Label>Car Make</Form.Label>
                        <div className="automobile-dropdown-box">
                          <Select
                            options={makeOptions}
                            value={
                              make !== '' ? { value: make, label: make } : { label: 'Choose make' }
                            }
                            onChange={(e) => handleChangeModel('make', e)}
                            styles={colourStyles}
                            isDisabled={vehicleDBLoader || isModelLoading}
                            onFocus={() => handleFocus('make')}
                            onBlur={handleDropdownBlur}
                          />
                          {isMakeLoading === true && (
                            <span className="cs-common-spinner cs-primary">
                              <CapsyncIcon title="loading-outlined" size="18" />
                            </span>
                          )}
                        </div>
                      </Form.Group>
                    </Col>
                    <Col lg={4} md={4}>
                      <Form.Group className="cs-form-group cs-regular-body-text-m">
                        <Form.Label>Car Model</Form.Label>
                        <div className="automobile-dropdown-box">
                          <div className="cs-common-add-dropdown">
                            <Select
                              options={modelOptions}
                              value={
                                model !== ''
                                  ? { value: model, label: model }
                                  : { label: 'Choose model' }
                              }
                              onChange={(e) => handleChangeModel('model', e)}
                              styles={colourStyles}
                              isDisabled={vehicleDBLoader || isMakeLoading}
                              onFocus={() => handleFocus('model')}
                              onBlur={handleDropdownBlur}
                            />
                          </div>
                          {isModelLoading === true && (
                            <span className="cs-common-spinner cs-primary">
                              <CapsyncIcon title="loading-outlined" size="18" />
                            </span>
                          )}
                        </div>
                      </Form.Group>
                    </Col>
                  </Row>
                )}
              </div>
            </Col>
          )}
          {isVehicleDBChecked && (
            <React.Fragment>
              <Col xs={12} md={6}>
                <Form.Group className="cs-form-group cs-disabled">
                  <Form.Label>Car Trim</Form.Label>
                  <Form.Control
                    type="text"
                    autoComplete="off"
                    name="trim_name"
                    value={trim}
                    onChange={handleChange}
                    disabled={true}
                  />
                </Form.Group>
              </Col>
              <Col xs={12} md={6}>
                <Form.Group className="cs-form-group cs-disabled">
                  <Form.Label>Car Condition</Form.Label>
                  <Form.Control
                    type="text"
                    autoComplete="off"
                    name="condition_name"
                    value={condition}
                    onChange={handleChange}
                    disabled={true}
                  />
                </Form.Group>
              </Col>
              <Col xs={12} md={12}>
                <div className={'market-value-input'}>
                  <Form.Group className={`cs-disabled cs-form-group `} data="Market Value">
                    <div className="total-value-input">
                      <NumberFormat
                        thousandSeparator={true}
                        autoComplete="off"
                        placeholder=""
                        name="market_value"
                        onKeyDown={(e) => {
                          if (e.key === ' ' && e.target.selectionStart === 0) {
                            e.preventDefault();
                          }
                        }}
                        prefix="$ "
                        value={marketValue}
                        onChange={handleChange}
                        disabled={isVehicleDBChecked}
                        className={`text-md-end form-control ${
                          marketValue ? 'dollar-symbol cs-input-field-active' : ''
                        }`}
                      />
                      {marketValue == 0 && vehicleDBLoader && (
                        <span className="cs-common-spinner cs-primary">
                          <CapsyncIcon title="loading-outlined" size="22" />
                        </span>
                      )}
                    </div>

                    {isVehicleDBChecked && (
                      <div className="market-value-box cs-light-body-text-s cs-neutral-90">
                        Powered by{' '}
                        <Image
                          src={require('../../../assets/images/other/vehicle-database.png')}
                          alt="Vehicle Database Logo"
                        />
                      </div>
                    )}
                  </Form.Group>
                </div>
              </Col>
            </React.Fragment>
          )}
          <Col xs={12} md={6}>
            <InputBox
              label={'Name of Creditor'}
              name={'creditor_name'}
              values={values}
              onChange={handleChange}
              errors={errors}
              touched={touched}
              type={'text'}
              onKeyDown={preventSpecialCharacters}
              placeholder={'Optional'}
            />
          </Col>

          {!isVehicleDBChecked && (
            <Col xs={12} md={6}>
              <Form.Group className="cs-form-group">
                <Form.Label>Market Value</Form.Label>
                <NumberFormat
                  thousandSeparator={true}
                  autoComplete="off"
                  placeholder=""
                  name="market_value"
                  onKeyDown={(e) => {
                    if (e.key === ' ' && e.target.selectionStart === 0) {
                      e.preventDefault();
                    }
                  }}
                  value={values.market_value}
                  onChange={handleChange}
                  maxLength={12}
                  className={`${
                    values.market_value ? 'dollar-symbol cs-input-field-active' : ''
                  } text-md-end form-control`}
                  onPaste={(e) => {
                    const clipboardData = e.clipboardData || window.clipboardData;
                    const pastedText = clipboardData.getData('Text');
                    // Check if the pasted value is negative and prevent it if it is
                    if (parseFloat(pastedText) < 0) {
                      e.preventDefault();
                    }
                  }}
                  allowNegative={false}
                  decimalScale={2}
                />

                {errors.market_value && touched.market_value && (
                  <span className="form-error-msg cs-light-body-text-s cs-danger">
                    {errors.market_value}
                  </span>
                )}
              </Form.Group>
            </Col>
          )}
          <Col xs={12} md={6}>
            <InputNumberField
              label="Payment"
              name="payment"
              values={values}
              errors={errors}
              handleChange={handleChange}
              touched={touched}
              customActiveClass="text-md-end form-control"
              onPaste={preventNegativeNumbers}
              thousandSeparator={true}
              placeholder={'Optional'}
            />
          </Col>
          <Col xs={12} md={6}>
            <InputNumberField
              label="Balance Due"
              name="balance_due"
              values={values}
              errors={errors}
              handleChange={handleChange}
              touched={touched}
              customActiveClass="text-md-end form-control"
              onPaste={preventNegativeNumbers}
              thousandSeparator={true}
              placeholder={'Optional'}
            />
          </Col>
          <Col xs={12} md={6}>
            <DatePickerBox
              label={'Date Updated'}
              errors={errors}
              name={'date_updated'}
              values={values}
              setFieldValue={setFieldValue}
            />
          </Col>
          <Col xs={12} md={12}>
            <TextAreaBox
              handleChange={handleChange}
              name={'notes'}
              label={'Notes'}
              values={values}
              errors={errors}
              onChange={handleChange}
              placeholder={'Optional'}
            />
          </Col>
          {!record && (
            <CategoryFileUpload
              name={'attachments'}
              values={values}
              errors={errors}
              setFieldError={setFieldError}
              setFieldValue={setFieldValue}
              storageError={storageError}
            />
          )}
        </Row>

        <SaveORCancelButtons handleCancel={handleCancel} resetForm={resetForm} loading={loading} />
      </Form>
      <VinCarModal
        show={showVinModal && vehicleDBMarketValue.length > 0}
        marketData={vehicleDBMarketValue}
        carTrimValue={trim}
        carConditionValue={condition}
        onClickCancel={handleCancelTrimCondition}
        onClickContinue={handleContinueTrimCondition}
      />
      <ConfirmEditModal
        showModal={showConfirmEditModal}
        onUpdateMarketValue={handleUpdateMarketValue}
      />
    </React.Fragment>
  );

  return (
    <Modal
      parentClassName={'cap-dash-main-modal automobile-popup'}
      show={isOpenModal}
      isCloseButton={false}
      title={'Automobiles'}
      body={body}
    />
  );
};
Automobile.propTypes = {
  isOpen: PropTypes.bool,
  handleCancel: PropTypes.func,
  handleCategoryFormSubmit: PropTypes.func,
  record: PropTypes.object
};
export default Automobile;
