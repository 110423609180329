import React, { useEffect, useState } from 'react';
import { Button, Image } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

// COMPONENT IMPORTS
import Modal from '../../../components/Modal';
import { ADD_ACCOUNT_MODALS } from '../../../constants/addAccount';
import BankImages from '../../../assets/images/plaid_bank_images.svg';

// API
import { handlePlaidToken, useToken } from '../../../slices/plaidSlice';
import { generatePublicTokenLink } from '../../../services/plaid.service';

/* ============================== ADD ACCOUNT MAIN MODAL ============================== */
const AddAccountMainModal = ({
  setAddAccountStep,
  isOpen,
  setIsAccountModelOpen,
  isAddAccountFlowClosable,
  onHandleLinkAccountModelOpen,
  plaidReady,
  isLoader,
  setShowLogoutModal
}) => {
  const dispatch = useDispatch();
  const token = useToken();

  const [isPlaidLoader, setIsPlaidLoader] = useState(true);

  useEffect(() => {
    isOpen && generatePlaidLinkTokenFun();
  }, []);

  const generatePlaidLinkTokenFun = async () => {
    try {
      const res = await generatePublicTokenLink();
      if (res?.data) {
        setIsPlaidLoader(false);
        dispatch(handlePlaidToken(res?.data?.link_token || ''));
      } else {
        setIsPlaidLoader(false);
      }
    } catch (error) {
      setIsPlaidLoader(false);

      console.log('plaidLinkToken Error >> ', error.message);
    }
  };

  const handleModalClose = async () => {
    if (!isAddAccountFlowClosable) {
      setShowLogoutModal(true);
      return;
    }
    await setIsAccountModelOpen('');
  };

  return (
    <Modal
      show={true}
      title="Add New Account"
      isCloseButton={true}
      handleClose={() => handleModalClose()}
      className="cs-s-modal"
      isModalVisible={isOpen && !isPlaidLoader}
      isLoader={isLoader || isPlaidLoader}
      body={
        <div className="account-popup-setup1">
          <div className="add-account-head cs-regular-body-text-m cs-neutral-90">
            <Image
              className="plaid-banks-img"
              src={BankImages}
              width="343px"
              height="187px"
              alt="Bank Images"
            />
          </div>
          <div className="add-account-main-button">
            <Button
              className={`cs-btn-secondary lg-btn cs-regular-h5 btn ${!token && !plaidReady ? 'cs-disabled' : 'btn-primary'} `}
              onClick={onHandleLinkAccountModelOpen}
              disabled={!token && !plaidReady}>
              Link your account
            </Button>
            <div className="cs-divider">
              <span className="cs-regular-body-text-m cs-neutral-50">OR</span>
            </div>
            <Button
              className="cs-btn-secondary lg-btn cs-regular-h5 btn btn-primary"
              onClick={() => setAddAccountStep(ADD_ACCOUNT_MODALS.ADD_MANUAL_ACCOUNT_MODAL)}>
              Add manual account
            </Button>
          </div>
          <div className="plaid-add-popup-note">
            <span className="cs-regular-sub-heading-s cs-neutral-70">
              By continuing, you agree to CapSync's
            </span>
            <Link
              to="https://capsync.com/privacy-policy"
              target="_blank"
              className="cs-regular-h5 cs-neutral-100">
              Privacy Policy
            </Link>
          </div>
          <div className="plain-footer modal-footer"></div>
        </div>
      }
    />
  );
};

// PROPS TYPES
AddAccountMainModal.propTypes = {
  setAddAccountStep: PropTypes.func.isRequired,
  isOpen: PropTypes.bool,
  setIsAccountModelOpen: PropTypes.func,
  isAddAccountFlowClosable: PropTypes.bool,
  onHandleLinkAccountModelOpen: PropTypes.func,
  setShowLogoutModal: PropTypes.func,
  plaidReady: PropTypes.bool,
  isLoader: PropTypes.bool
};

export default AddAccountMainModal;
