import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// COMPONENT IMPORTS
import axios from '../config/api';
import CONFIG from '../constants/config';

// FIND ADDRESSES FROM GOOGLE API CALL
export const autoCompleteAddress = createAsyncThunk(
  'system/google/auto_search_address',
  async (data) => {
    const res = await axios.post(`google/auto_search_address`, data);
    return res;
  }
);

// GET SPECIFIC ADDRESS DATA FROM GOOGLE API CALL
export const placeSearch = createAsyncThunk('system/google/search_place', async (data) => {
  const res = await axios.post(`google/search_place`, data);
  return res;
});

// GET CITY AND STATE USING ZIPCODE FROM GOOGLE API CALL
export const zipCodeLookup = createAsyncThunk('system/google/search_by_zipcode', async (data) => {
  const res = await axios.post(`google/search_by_zipcode`, data);
  return res;
});

// INITIAL STATE
const initialState = {
  addressPredictions: {},
  addressPlaceSearch: {},
  addressZipCodeLookup: {},
  systemStatus: null
};

/* ============================== SYSTEM SLICE ============================== */
const systemSlice = createSlice({
  name: 'system',
  initialState,
  reducers: {
    resetAddressSlice: () => initialState
  },
  extraReducers: (builder) => {
    // SYSTEM API STATUS
    builder
      .addCase(autoCompleteAddress.fulfilled, (state, { payload }) => {
        const { status: loader, data } = payload;
        state.systemStatus = loader ? CONFIG.STATUS.SUCCESS : CONFIG.STATUS.PROGRESS;
        state.addressPredictions = data;
      })
      .addCase(autoCompleteAddress.rejected, (state, { payload }) => {
        const { status } = payload;
        state.systemStatus = !status ? CONFIG.STATUS.ERROR : null;
      })
      .addCase(placeSearch.fulfilled, (state, { payload }) => {
        const { status: loader, data } = payload;
        state.systemStatus = loader ? CONFIG.STATUS.SUCCESS : CONFIG.STATUS.PROGRESS;
        state.addressPlaceSearch = data;
      })
      .addCase(placeSearch.rejected, (state, { payload }) => {
        const { status } = payload;
        state.systemStatus = !status ? CONFIG.STATUS.ERROR : null;
      })
      .addCase(zipCodeLookup.fulfilled, (state, { payload }) => {
        const { status: loader, data } = payload;
        state.systemStatus = loader ? CONFIG.STATUS.SUCCESS : CONFIG.STATUS.PROGRESS;
        state.addressZipCodeLookup = data;
      })
      .addCase(zipCodeLookup.rejected, (state, { payload }) => {
        const { status } = payload;
        state.systemStatus = !status ? CONFIG.STATUS.ERROR : null;
      });
  }
});

export const { resetAddressSlice } = systemSlice.actions;

export default systemSlice.reducer;
