import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Form, Row } from 'react-bootstrap';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import moment from 'moment';

// COMPONENT IMPORTS
import {
  preventNegativeNumbers,
  preventSpace,
  preventSpecialCharacters
} from '../../../utils/common';
import Modal from '../../../components/Modal';
import { TOAST_MESSAGE } from '../../../constants/message';
import InputBox from '../../../components/FormFields/InputBox';
import validationObj from '../../../validations/validationSchema';
import TextAreaBox from '../../../components/FormFields/TextAreaBox';
import DatePickerBox from '../../../components/FormFields/DatePickerBox';
import PhoneInputField from '../../../components/FormFields/PhoneInputField';
import InputNumberField from '../../../components/FormFields/InputNumberField';
import SaveORCancelButtons from '../../../components/Buttons/SaveORCancelButtons';
import CategoryFileUpload from '../../../components/FormFields/CategoryFileUpload';

// API
import {
  categoryAddRecords,
  categoryUpdateRecords,
  getAssetsCategory,
  useCategoryDetail
} from '../../../slices/categorySlice';
import { getAssetsDashboard, getMainDashboard } from '../../../slices/dashboardSlice';
import { useProgressUploadDetails } from '../../../slices/commonSlice';
import { useStorageDetails } from '../../../slices/fileManagementSlice';

/* ============================== LIFE INSURANCE ============================== */
const LifeInsurance = ({ isOpen = false, handleCancel, handleCategoryFormSubmit, record }) => {
  const dispatch = useDispatch();

  const { user } = useSelector((state) => state.auth);
  const categories = useCategoryDetail();
  const categoryId = categories?.category_id;
  const progressUploadDetails = useProgressUploadDetails();
  const storageDetails = useStorageDetails();

  const [isOpenModal, setIsOpenModal] = useState(isOpen);
  const [loading, setLoading] = useState(false);
  const [initialValues, setInitialValues] = useState({
    user_id: user?.id,
    company: '',
    email: '',
    type: '',
    phone_number: '',
    beneficiary: '',
    cash_value: '',
    loan_amount: '',
    date_updated: moment().format(),
    attachments: []
  });
  const [loanAmountError, setLoanAmountError] = useState('');
  useEffect(() => {
    if (record) {
      setInitialValues({
        user_id: record?.user_id,
        company: record?.company,
        email: record?.email,
        phone_number: record?.phone_number,
        beneficiary: record?.beneficiary,
        cash_value: record?.cash_value && record?.cash_value.toLocaleString('en-US'),
        type: record?.type,
        loan_amount: record?.loan_amount && record?.loan_amount.toLocaleString('en-US'),
        date_updated: new Date(moment(record?.date_updated).format('MM/DD/YYYY')),
        notes: record?.notes == null ? '' : record?.notes
      });
    }
  }, [record]);
  const {
    values,
    handleSubmit,
    handleChange,
    setFieldValue,
    errors,
    touched,
    setFieldError,
    submitCount,
    resetForm
  } = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: validationObj.assetLifeinsuranceValidationSchema,
    onSubmit: async (values) => {
      if (
        Number(values.cash_value.replace(/,/g, '')) <=
        Number(values.loan_amount.toString().replace(/,/g, ''))
      ) {
        setLoanAmountError('The loan amount should not be greater or equal to cash value.');
        return;
      } else {
        setLoanAmountError('');
      }
      const formData = new FormData();
      const uploadedData = storageDetails?.used_space;
      const inProgressData = progressUploadDetails.length
        ? progressUploadDetails.reduce((acc, item) => acc + item.size, 0)
        : 0;

      const { attachments, ...dataObj } = values;
      !record && attachments.forEach((file) => formData.append('attachments', file));
      formData.append('data_obj', JSON.stringify(dataObj));
      formData.append('usedSpace', uploadedData + inProgressData);
      const data = {
        category: 12,
        mainCategory: 'assets',
        formData: formData
      };
      setLoading(true);
      if (!record) {
        dispatch(categoryAddRecords(data))
          .unwrap()
          .then(async (response) => {
            if (response.code === 500) {
              setFieldError('attachments', response?.message);
            }
            if (response.code === 200) {
              setIsOpenModal(false);
              handleCategoryFormSubmit();
              dispatch(getMainDashboard(user?.id));
              const payload = { categoryId: categoryId, userId: user?.id };
              categories.parent === 'assets' &&
                (await dispatch(getAssetsCategory(payload)).unwrap());
              await dispatch(getAssetsDashboard({ userId: user?.id, orderId: 0 })).unwrap();
            }
          })
          .catch((err) => {})
          .finally(() => {
            setLoading(false);
          });
      } else {
        dispatch(
          categoryUpdateRecords({
            category: 12,
            mainCategory: 'assets',
            rowId: record && record?.id,
            values: { ...values, usedSpace: uploadedData + inProgressData }
          })
        )
          .unwrap()
          .then(async (response) => {
            if (response.code === 500) {
              setFieldError('notes', response?.message);
            }
            if (response.code === 200) {
              setIsOpenModal(false);
              handleCategoryFormSubmit();
              toast.success(TOAST_MESSAGE.RECORD_UPDATE_SUCCESS);
              const payload = { categoryId: categoryId, userId: user?.id };
              categories.parent === 'assets' &&
                (await dispatch(getAssetsCategory(payload)).unwrap());
              await dispatch(getMainDashboard(user?.id)).unwrap();
            }
          })
          .catch((err) => {})
          .finally(() => {
            setLoading(false);
          });
      }
    }
  });

  const body = (
    <Form onSubmit={handleSubmit} encType="multipart/form-data">
      <Row>
        <Col lg={12} sm={12}>
          <InputBox
            label={'Institution'}
            name={'company'}
            values={values}
            onChange={handleChange}
            errors={errors}
            touched={touched}
            type={'text'}
            onKeyDown={preventSpecialCharacters}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={6}>
          <InputBox
            label={'Email'}
            name={'email'}
            values={values}
            onChange={handleChange}
            errors={errors}
            touched={touched}
            optional={true}
            type={'email'}
            onKeyDown={preventSpace}
            placeholder="Optional "
          />
        </Col>
        <Col md={6} xs={12}>
          <PhoneInputField
            label={'Phone Number (Optional)'}
            placeholder={'Optional'}
            name={'phone_number'}
            values={values}
            setFieldValue={setFieldValue}
            errors={errors}
            touched={touched}
            submitCount={submitCount}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={6}>
          <InputBox
            label={'Beneficiary'}
            name={'beneficiary'}
            values={values}
            onChange={handleChange}
            errors={errors}
            touched={touched}
            type={'text'}
            onKeyDown={preventSpecialCharacters}
          />
        </Col>
        <Col xs={12} md={6}>
          <InputNumberField
            label="Cash Value"
            name="cash_value"
            values={values}
            errors={errors}
            customActiveClass={'dollar-symbol text-md-end form-control'}
            handleChange={handleChange}
            touched={touched}
            onPaste={preventNegativeNumbers}
            thousandSeparator={true}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={6}>
          <InputBox
            label={'Type'}
            name={'type'}
            values={values}
            onChange={handleChange}
            errors={errors}
            touched={touched}
            type={'text'}
            placeholder="Optional"
            onKeyDown={preventSpecialCharacters}
          />
        </Col>
        <Col xs={12} md={6}>
          <InputNumberField
            label="Loan Balance"
            name="loan_amount"
            values={values}
            errors={errors}
            customActiveClass={'dollar-symbol text-md-end form-control'}
            handleChange={handleChange}
            touched={touched}
            onPaste={preventNegativeNumbers}
            thousandSeparator={true}
            placeholder="Optional"
            loanValidation={true}
            setLoanAmountError={setLoanAmountError}
            loanAmountError={loanAmountError}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={6}>
          <DatePickerBox
            label={'Date Updated'}
            errors={errors}
            name={'date_updated'}
            values={values}
            setFieldValue={setFieldValue}
          />
        </Col>
      </Row>
      <React.Fragment>
        <Row>
          <Col xs={12} md={12}>
            <TextAreaBox
              handleChange={handleChange}
              name={'notes'}
              label={'Notes'}
              values={values}
              errors={errors}
              onChange={handleChange}
              placeholder={'Optional'}
              isManual
            />
          </Col>
        </Row>

        {!record && (
          <CategoryFileUpload
            name={'attachments'}
            values={values}
            errors={errors}
            setFieldError={setFieldError}
            setFieldValue={setFieldValue}
          />
        )}
        <SaveORCancelButtons handleCancel={handleCancel} resetForm={resetForm} loading={loading} />
      </React.Fragment>
    </Form>
  );

  return <Modal show={isOpenModal} isCloseButton={false} title={'Life Insurance'} body={body} />;
};

// PROPS TYPE
LifeInsurance.propTypes = {
  isOpen: PropTypes.bool,
  handleCancel: PropTypes.func,
  handleCategoryFormSubmit: PropTypes.func,
  record: PropTypes.object
};

export default LifeInsurance;
