import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Image } from 'react-bootstrap';
import PropTypes from 'prop-types';
import QRCode from 'qrcode';

// COMPONENT IMPORTS
import CommonSpinner from './CommonSpinner';
import config from '../constants/config';

// API
import { useAccessUser } from '../slices/authSlice';

/* ============================== GOOGLE QR ============================== */
const GoogleQRCode = ({ width = 244, height = 244, isMySettings = false }) => {
  const { status } = useSelector((state) => state.auth);
  const { mfa, user } = useSelector((state) => state.auth);
  const accessUser = useAccessUser();

  const [qr, setQR] = useState();

  useEffect(() => {
    const otPathUrl = mfa && mfa?.otpauth_url;
    const userEmail = isMySettings ? accessUser?.loginUserDetails?.email : user?.email;
    const googleAuthUrl =
      'otpauth://totp/' + 'White Label Capsync' + ': ' + userEmail + '?secret=' + otPathUrl;

    QRCode.toDataURL(googleAuthUrl, (err, imageData) => {
      setQR(imageData);
    });
  }, [mfa && mfa?.otpauth_url]);

  return (
    <div className="qrcode">
      {status === config.STATUS.PROGRESS ? (
        <CommonSpinner classParent={'cs-primary m-auto'} size={'30'} />
      ) : (
        <Image src={qr} alt="Qr Code" width={width} height={height} />
      )}
    </div>
  );
};

// PROPS TYPE
GoogleQRCode.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  isMySettings: PropTypes.bool
};

export default GoogleQRCode;
