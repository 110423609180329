import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import secureLocalStorage from 'react-secure-storage';
import { useDispatch } from 'react-redux';

// COMPONENT IMPORTS
import { roles } from '../utils/common';

// API
import { setMFA } from '../slices/mfaSlice';
import {
  accessUserDetails,
  clientViewByProfessionalUser,
  setToUserPermission
} from '../slices/authSlice';

/* ============================== MASTER ADMIN REDIRECTION ============================== */
const MasterAdminRedirect = () => {
  const { professionalUserId, token, clientId, roleId } = useParams();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [redirect, setRedirect] = useState(null);

  const handlePageOnAdminAccess = () => {
    const broadcastChannel = new BroadcastChannel('master_admin_accessing_other_client_account');
    broadcastChannel.postMessage('accessAccount');
  };

  const getViewByProfessionalUserFun = async () => {
    secureLocalStorage.removeItem('token');
    localStorage.removeItem('id');
    localStorage.removeItem('accessBy');
    try {
      const payload = { token, clientId, professionalUserId };
      const response = await dispatch(clientViewByProfessionalUser(payload)).unwrap();
      if (response?.data) {
        setRedirect(response);
        const accessBy = {
          accessUserID: Number(professionalUserId),
          roleName: roles.master_admin_financial_advisor.name,
          roleID: roles.master_admin_financial_advisor.roleID,
          is_dashboard_available: true,
          loginUserDetails: null
        };
        const toUserPermission = {
          user_id: response?.data?.id,
          first_name: null,
          last_name: null,
          status: null,
          permission_type: 'standard',
          accessUserID: Number(professionalUserId),
          roleId: roleId
        };
        dispatch(accessUserDetails(accessBy));
        dispatch(setToUserPermission(toUserPermission));
        dispatch(setMFA({ varified: true }));
        secureLocalStorage.setItem('token', token);
        const stringAccessBy = JSON.stringify(accessBy);
        localStorage.setItem('accessBy', stringAccessBy);
        handlePageOnAdminAccess();
      } else {
        window.location.href = '/no-access';
      }
    } catch (error) {
      console.log('error :>> ', error);
    }
  };

  useEffect(() => {
    getViewByProfessionalUserFun();
  }, []);

  useEffect(() => {
    if (redirect?.data) {
      navigate('/dashboard');
    }
  }, [redirect]);

  return <div className="cs-redirect"></div>;
};
export default MasterAdminRedirect;
