import React, { useEffect, useRef } from 'react';
import ReactApexChart from 'react-apexcharts';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

/* ============================== LINE CHART ============================== */
const LineChart = ({ seriesArray, label, forDashboard = false }) => {
  const chartRef = useRef(null);
  const chartContainerRef = useRef(null);
  const isSidebarOpen = useSelector((state) => state.sidebar.isSidebarOpen);

  // const [showTooltip, setShowTooltip] = useState(false);

  const yAxisFormatter = function (number) {
    const absNumber = Math.abs(number);
    let suffix = '';
    let value = number;

    if (absNumber >= 1_000_000_000) {
      suffix = 'B';
      value = number / 1_000_000_000;
    } else if (absNumber >= 1_000_000) {
      suffix = 'M';
      value = number / 1_000_000;
    } else if (absNumber >= 1_000) {
      suffix = 'K';
      value = number / 1_000;
    }

    return value.toString() + suffix;
  };

  const options = {
    id: 'chartBarSeries5497',
    chart: {
      type: 'category',
      height: 500,
      offsetY: -8,
      offsetX: 0,
      toolbar: {
        show: false
      },
      zoom: {
        enabled: false
      },
      events: {
        legendClick: function () {
          // setShowTooltip(false);
        }
      }
    },
    fill: {
      type: 'gradient',
      gradient: {
        shadeIntensity: 0.9,
        opacityFrom: 0.05,
        opacityTo: 0.02
        // stops: [0, 90, 100]
      }
    },
    legend: {
      position: 'top',
      horizontalAlign: 'right',
      onItemClick: {
        toggleDataSeries: true
      },
      onItemHover: {
        highlightDataSeries: false
      },
      markers: {
        size: 4,
        shape: 'circle'
      }
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      curve: 'smooth',
      width: 1
    },
    colors:
      label &&
      (label === 'Assets' || label === 'AssetsCategory'
        ? ['var(--asset-chart)']
        : label === 'Liabilities' || label === 'LiabilitiesCategory'
          ? ['var(--liability-chart)']
          : ['var(--asset-chart)', 'var(--liability-chart)', 'var(--primary-main)']),
    // title: {
    //   text: 'Financial Summary',
    //   align: 'left',
    //   style: {
    //     fontSize: '14px'
    //   }
    // },
    xaxis: {
      type: 'category',
      axisBorder: {
        show: false
      },

      labels: {
        style: {
          fontFamily: 'var(--cs-font)',
          cssClass: 'cs-regular-h5',
          fontSize: '14px',
          fontWeight: '600',
          lineHeight: '20px',
          color: '#616161'
        },
        offsetY: 4,
        offsetX: 0
      },
      tooltip: {
        enabled: false
      },
      axisTicks: {
        show: true,
        borderType: 'solid',
        color: '#C2C2C2',
        height: 10,
        offsetX: 0,
        offsetY: 0
      }
    },
    yaxis: {
      show: true,
      type: '$',
      floating: false,
      labels: {
        style: {
          fontFamily: 'var(--cs-font)',
          cssClass: 'cs-regular-h5',
          fontSize: '14px',
          fontWeight: '600',
          lineHeight: '20px',
          color: '#616161'
        },
        offsetY: 0,
        offsetX: 7,
        align: 'left',
        formatter: yAxisFormatter
      },
      axisBorder: {
        show: false
      },
      axisTicks: {
        show: false
      },
      tickAmount: 7,
      title: {
        text: '$',
        rotate: 0,
        offsetX: label == 'Liabilities' ? 30 : label == 'Assets' ? 30 : 30,
        offsetY: label == 'Liabilities' ? -190 : label == 'Assets' ? -190 : -190,
        style: {
          fontFamily: 'var(--cs-font)',
          fontSize: '14px',
          fontWeight: '600',
          lineHeight: '20px',
          color: '#616161'
        }
      }
    },
    tooltip: {
      enabled: true,
      fixed: {
        enabled: false
      },
      x: {
        show: true,
        format: 'MMMM yyyy'
      },
      y: {
        formatter: function (value) {
          return value?.toString();
        }
      },
      custom: function ({ series, dataPointIndex, w }) {
        // setShowTooltip(true);
        let htmlString = `<div class="chart-custom-tooltip">`;
        seriesArray?.map((items, index) => {
          if (series[index][dataPointIndex] !== undefined) {
            htmlString += `
                  <div class=${label && label === 'Dashboard' && (items?.name == 'Assets' || (label && label === 'AssetsCategory') ? 'cs-asset' : items?.name == 'Liabilities' || (label && label === 'LiabilitiesCategory') ? 'cs-liability' : 'cs-primary')} >
                      <span>${items?.name} : </span>
                      <span>$ ${parseFloat(series[index][dataPointIndex].toFixed(2))}</span>
                  </div>`;
          }
        });

        // if (series[1][dataPointIndex] !== undefined) {
        //   htmlString += `
        //         <div class="cs-liability">
        //             <span>Liabilities : </span>
        //             <span>$ ${series[1][dataPointIndex]}</span>
        //         </div>`;
        // }

        // if (series[2][dataPointIndex] !== undefined) {
        //   htmlString += `
        //         <div class="cs-primary">
        //             <span>Net Worth : </span>
        //             <span>$ ${series[2][dataPointIndex]}</span>
        //         </div>`;
        // }

        htmlString += `</div>`;

        return htmlString;
      }
    },
    grid: {
      borderColor: '#EDEDED',
      yaxis: {
        lines: {
          offsetX: 30
        }
      },
      // row: {
      //   colors: '#EDEDED',
      //   opacity: 1
      // },
      padding: {
        left: 20
      }
    },
    markers: {
      size: 0,
      strokeColors: 'transparent',
      hover: {
        size: 4
      }
    },
    responsive: [
      {
        breakpoint: 1500,
        options: {
          chart: {
            width: '100%',
            height: 400
          },
          yaxis: {
            labels: {
              offsetX: 5,
              align: 'left',
              formatter: yAxisFormatter
            },
            title: {
              text: '$',
              rotate: 0,
              offsetX: label == 'Liabilities' ? 20 : label == 'Assets' ? 20 : 20,
              offsetY: label == 'Liabilities' ? -190 : label == 'Assets' ? -190 : -190,
              style: {
                fontFamily: 'Readex Pro',
                fontSize: '14px',
                fontWeight: '600',
                lineHeight: '20px',
                color: '#616161'
              }
            }
          }
        }
      },
      {
        breakpoint: 991,
        options: {
          chart: {
            width: '100%',
            height: 390
          },
          legend: {
            markers: {
              size: 3
            }
          },
          yaxis: {
            labels: {
              offsetX: 5,
              align: 'left',
              formatter: yAxisFormatter
            },
            title: {
              text: '$',
              rotate: 0,
              offsetX: label == 'Liabilities' ? 20 : label == 'Assets' ? 20 : 20,
              offsetY: label == 'Liabilities' ? -182 : label == 'Assets' ? -182 : -188,
              style: {
                fontFamily: 'Readex Pro',
                fontSize: '14px',
                fontWeight: '600',
                lineHeight: '20px',
                color: '#616161'
              }
            }
          }
        }
      },
      {
        breakpoint: 575,
        options: {
          chart: {
            width: '100%',
            height: 360
          },
          yaxis: {
            labels: {
              offsetX: 5,
              align: 'left',
              formatter: yAxisFormatter
            },
            title: {
              text: '$',
              rotate: 0,
              offsetX: label == 'Liabilities' ? 20 : label == 'Assets' ? 20 : 20,
              offsetY: label == 'Liabilities' ? -172 : label == 'Assets' ? -172 : -172,
              style: {
                fontFamily: 'Readex Pro',
                fontSize: '14px',
                fontWeight: '600',
                lineHeight: '20px',
                color: '#616161'
              }
            }
          }
        }
      }
    ]
  };

  useEffect(() => {
    const toggleSeriesDelay = setTimeout(() => {
      if (chartRef.current && chartRef.current.chart && forDashboard) {
        const chart = chartRef.current.chart;
        if (typeof chart.toggleSeries === 'function') {
          try {
            chart.toggleSeries('Assets');
            chart.toggleSeries('Liabilities');
          } catch (error) {
            console.error('Error toggling series:', error);
          }
        }
      }
    }, 100);

    return () => clearTimeout(toggleSeriesDelay);
  }, [chartRef, forDashboard]);

  // useEffect(() => {
  //   const chartElement = chartRef?.current?.chartRef?.current;

  //   if (chartElement) {
  //     if (showTooltip) {
  //       chartElement.classList.add('show-tooltip');
  //       chartElement.classList.remove('hide-tooltip');
  //     } else {
  //       chartElement.classList.add('hide-tooltip');
  //       chartElement.classList.remove('show-tooltip');
  //     }
  //   }
  // }, [showTooltip]);

  const updateChartDimensions = () => {
    if (chartRef.current && chartRef.current.chart) {
      const { offsetWidth } = chartContainerRef.current;
      chartRef.current.chart.updateOptions({
        chart: {
          width: offsetWidth
        }
      });
    }
  };

  useEffect(() => {
    setTimeout(() => {
      updateChartDimensions();
    }, 400);
  }, [isSidebarOpen]);

  return (
    <div ref={chartContainerRef} className="cs-w100 show-tooltip">
      <ReactApexChart
        options={options}
        series={seriesArray}
        type="area"
        height={390}
        ref={chartRef}
      />
    </div>
  );
};

// PROPS TYPE
LineChart.propTypes = {
  label: PropTypes.any,
  seriesArray: PropTypes.any,
  forDashboard: PropTypes.bool
};

export default LineChart;
