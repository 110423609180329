import * as React from 'react';

// COMPONENT IMPORTS
import Assets from '../pages/assets';
import Request from '../pages/Request';
import Settings from '../pages/settings';
import Dashboard from '../pages/dashboard';
import Liabilities from '../pages/liabilities';
import ProtectedRoutes from './ProtectedRoutes';
import Portfolio from '../pages/assets/Portfolio';
import Trash from '../pages/FileManagement/Trash';
import CreditManager from '../pages/CreditManager';
import Collaboration from '../pages/collaboration';
import Transaction from '../pages/assets/Transaction';
import Archive from '../pages/FileManagement/Archive';
import IdentityProtect from '../pages/IdentityProtect';
import MyDocument from '../pages/FileManagement/MyDocument';
import AssetsCategories from '../pages/assets/AssetsCategories';
import AmortizationMain from '../pages/Amortization/AmortizationMain';
import LiabilitiesCategories from '../pages/liabilities/LiabilitiesCategories';
import QuickBooksReports from '../pages/components/quickBooks/QuickBooksReports';

/* ============================== AUTH ROUTES ============================== */
const DashboardRoutes = {
  path: '',
  element: <ProtectedRoutes />,
  children: [
    { path: 'dashboard', element: <Dashboard /> },
    { path: 'assets', element: <Assets /> },
    { path: 'assets/:categories/portfolio/:mask_id/*', element: <Portfolio /> },
    { path: 'liabilities/:categories/portfolio/:mask_id/*', element: <Portfolio /> },
    { path: 'assets/:categories/transaction/:mask_id/*', element: <Transaction /> },
    { path: 'liabilities/:categories/transaction/:mask_id/*', element: <Transaction /> },
    { path: 'assets/:categories', element: <AssetsCategories /> },
    { path: 'assets/business/reports/:business_id', element: <QuickBooksReports /> },
    { path: 'liabilities', element: <Liabilities /> },
    { path: 'liabilities/:categories', element: <LiabilitiesCategories /> },
    { path: 'file-management', element: <MyDocument /> },
    { path: 'file-management/:folder_id', element: <MyDocument /> },
    { path: 'credit-manager', element: <CreditManager /> },
    { path: 'identity-protect', element: <IdentityProtect /> },
    { path: 'archive', element: <Archive /> },
    { path: 'trash', element: <Trash /> },
    { path: ':category/amortization/:categoryType/:row_id', element: <AmortizationMain /> },
    { path: 'settings/:tabName', element: <Settings /> },
    { path: 'my-settings/:tabName', element: <Settings /> },
    { path: 'request', element: <Request /> },
    { path: 'collaboration', element: <Collaboration /> }
  ]
};

export default DashboardRoutes;
