import React, { useEffect, useState } from 'react';
import { Col, Row, Form, Button } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { useFormik } from 'formik';
import * as yup from 'yup';

// COMPONENT IMPORTS
import useRemoveServerError from '../components/hooks/useRemoveServerError';
import InputBox from '../components/FormFields/InputBox';
import { TOAST_MESSAGE } from '../constants/message';
import CapsyncIcon from '../components/CapsyncIcon';
import { emailField } from '../validations';
import AuthHead from './AuthHead';

// API
import { userForgotPassword } from '../slices/authSlice';
import { useBrandDetails } from '../slices/brandingSlice';

/* ============================== FORGOT PASSWORD PAGE ============================== */
const ForgotPassword = (props) => {
  const dispatch = useDispatch();
  useRemoveServerError();

  const navigate = useNavigate();

  const { firm_id } = useBrandDetails();

  const [message, setMessage] = useState('');

  const { values, errors, touched, handleChange, handleSubmit } = useFormik({
    initialValues: {
      email: ''
    },
    validationSchema: yup.object().shape({
      email: emailField()
    }),
    onSubmit: async (values) => {
      const payload = Object.assign({}, values);
      payload['firm_id'] = firm_id.toString();
      const res = await dispatch(userForgotPassword(payload)).unwrap();
      if (res?.code === 200) {
        toast.success(TOAST_MESSAGE.FORGOT_PASSWORD_SUCCESS);
        navigate('/');
      } else {
        setMessage(res?.message);
      }
    }
  });

  useEffect(() => {
    setMessage('');
  }, [values]);
  return (
    <React.Fragment>
      <AuthHead
        title="Forgot Password"
        description="Don't worry! Enter your email address below and we will email you a link to reset your password."
      />
      <div className="cs-auth-form">
        <Form onSubmit={handleSubmit}>
          <Row>
            <Col lg={12}>
              <InputBox
                type="email"
                name="email"
                values={values}
                errors={errors}
                touched={touched}
                onChange={handleChange}
                placeholder="Enter email address"
              />
            </Col>
          </Row>

          {message && (
            <span className="cs-regular-body-text-m cs-danger">
              <CapsyncIcon title="declined-outlined" size="16" />
              {message}
            </span>
          )}
          <div className="auth-footer">
            <Button type="submit" className="cs-btn-primary lg-btn cs-regular-h5">
              Reset password
            </Button>
          </div>
        </Form>
        <Row>
          <Col>
            <span className="back-to-sign-in">
              <Link className="cs-btn-tertiary lg-btn cs-regular-h5" to="/">
                Back to sign in
              </Link>
            </span>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
};

export default ForgotPassword;
