export const dataTo = [
    {
        status: 2,
        type: 'Signature Request',
        to: 'John Doe',
        role: 'Individual',
        totalUser: 2,
        accpetedUser: 1,
        rejectedUser: 0,
        date: '2024-06-14',
        id: 1
    },
    {
        status: 2,
        type: 'Signature Request',
        to: 'Jane Smith',
        role: 'Individual',
        totalUser: 2,
        accpetedUser: 1,
        rejectedUser: 0,
        date: '2024-06-13',
        id: 2
    },
    {
        status: 3,
        type: 'Signature Request',
        to: 'Alice Johnson',
        role: 'Individual',
        totalUser: 1,
        accpetedUser: 1,
        rejectedUser: 0,
        date: '2024-06-12',
        id: 3
    },
    {
        status: 5,
        type: 'Signature Request',
        to: 'Bob Brown',
        role: 'Individual',
        totalUser: 2,
        accpetedUser: 0,
        rejectedUser: 2,
        date: '2024-06-11',
        id: 4
    },
    {
        status: 1,
        type: 'Signature Request',
        to: 'Charlie Davis',
        role: 'Individual',
        totalUser: 1,
        accpetedUser: 0,
        rejectedUser: 0,
        date: '2024-06-10',
        id: 5
    },
    {
        status: 4,
        type: 'Signature Request',
        to: 'Aman',
        role: 'Individual',
        totalUser: 1,
        accpetedUser: 0,
        rejectedUser: 1,
        date: '2024-06-10',
        id: 5
    }
];
export const dataFrom = [
    {
        status: 2,
        type: 'Signature Request',
        from: 'John Doe',
        role: 'Individual',
        totalUser: 2,
        accpetedUser: 1,
        rejectedUser: 0,
        date: '2024-06-14',
        id: 1
    },
    {
        status: 2,
        type: 'Signature Request',
        from: 'Jane Smith',
        role: 'Individual',
        totalUser: 2,
        accpetedUser: 1,
        rejectedUser: 0,
        date: '2024-06-13',
        id: 2
    },
    {
        status: 3,
        type: 'Signature Request',
        from: 'Jane Smith',
        role: 'Individual',
        totalUser: 1,
        accpetedUser: 1,
        rejectedUser: 0,
        date: '2024-06-13',
        id: 2
    },
    {
        status: 3,
        type: 'Signature Request',
        from: 'Aman Srivastava',
        role: 'Individual',
        totalUser: 2,
        accpetedUser: 2,
        rejectedUser: 0,
        date: '2024-06-13',
        id: 2
    },

];
export const accountAccess = [
    {
        status: "pending",
        type: 'Account Access Request',
        from: 'John Doe',
        role: 'Individual',
        permission: 'view Only',
        date: '2024-06-14',
        id: 1
    },
    {
        status: "completed",
        type: 'Account Access Request',
        from: 'Jane Smith',
        role: 'Individual',
        permission: 'view Only',
        date: '2024-06-13',
        id: 2
    },

];
export const ACCORDION_DATA = [
    {
        first_name: 'John',
        last_name: 'Doe',
        email: 'john.doe@example.com',
        phone_number: '555-1234',
        status: 'Completed',
        date: '2024-06-14'
    },
    {
        first_name: 'Jane',
        last_name: 'Smith',
        email: 'jane.smith@example.com',
        phone_number: '555-5678',
        status: 'Pending',
        date: '2024-06-13'
    }
];