import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

// COMPONENT IMPORTS
import axios from '../config/api';
import { setFileUploaded } from './commonSlice';
import { getStorageDetails } from './fileManagementSlice';

// INITIAL STATE
const initialState = {
  assetsDashboard: [],
  mainDashboard: {},
  liabilitiesDashboard: [],
  calculatedNetWorth: 0
}; // added calculatedNetWorth

export const getAssetsDashboard = createAsyncThunk(
  '/dashboard/assetsdashboard',
  async (payload, thunkAPI) => {
    const { userId, orderId } = payload;
    const res = await axios.get(`/dashboard/assets_dashboard/${userId}/${orderId}`);
    thunkAPI.dispatch(setFileUploaded(Math.random()));
    thunkAPI.dispatch(getStorageDetails({ user_id: userId })).unwrap();
    return res;
  }
);
export const getLiabilitiesDashboard = createAsyncThunk(
  '/dashboard/liabilitiesdashboard',
  async (payload, thunkAPI) => {
    const { userId, orderId } = payload;
    const res = await axios.get(`/dashboard/liabilities_dashboard/${userId}/${orderId}`);
    thunkAPI.dispatch(setFileUploaded(Math.random()));
    thunkAPI.dispatch(getStorageDetails({ user_id: userId })).unwrap();
    return res;
  }
);
export const getMainDashboard = createAsyncThunk('/dashboard/maindashboard', async (payload) => {
  const res = await axios.get(`/dashboard/dashboard/${payload}`);
  return res;
});

export const addUpdateContingentData = createAsyncThunk(
  '/dashboard/addUpdateContingentData',
  async (data) => {
    return await axios.post(
      `/liability/add_update_contingent_liabilities
`,
      data
    );
  }
);

/* ============================== DASHBOARD SLICE ============================== */
const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    resetDashboardSlice: () => initialState
  },
  extraReducers: (builder) => {
    builder.addCase(getAssetsDashboard.fulfilled, (state, action) => {
      state.assetsDashboard = action?.payload?.data;
    });
    builder.addCase(getLiabilitiesDashboard.fulfilled, (state, action) => {
      state.liabilitiesDashboard = action?.payload?.data;
    });
    builder.addCase(getMainDashboard.fulfilled, (state, action) => {
      state.mainDashboard = action?.payload?.data;
      state.calculatedNetWorth =
        Number(action?.payload?.data?.assets_all_total) -
        Number(action?.payload?.data?.liability_all_total);
    });
  }
});

export const { resetDashboardSlice } = dashboardSlice.actions;
export default dashboardSlice.reducer;

export const selectAssetsDashboard = (state) => state.dashboard.assetsDashboard;
export const useAssetsDashboard = () => {
  const assetsDashboard = useSelector(selectAssetsDashboard);
  return useMemo(() => assetsDashboard, [assetsDashboard]);
};
export const selectLiabilitieDashboard = (state) => state.dashboard.liabilitiesDashboard;
export const useLiabilitieDashboard = () => {
  const liabilitiesDashboard = useSelector(selectLiabilitieDashboard);
  return useMemo(() => liabilitiesDashboard, [liabilitiesDashboard]);
};

export const selectMainDashboard = (state) => state.dashboard.mainDashboard;
export const useMainDashboard = () => {
  const mainDashboard = useSelector(selectMainDashboard);
  return useMemo(() => mainDashboard, [mainDashboard]);
};
