import React, { useEffect, useRef, useState } from 'react';
import { Button, Container, Dropdown, Form, Image } from 'react-bootstrap';
import { useOutletContext, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import moment_timezone from 'moment-timezone';
import moment from 'moment/moment';

// COMPONENT IMPORTS
import SearchFilter from './SearchFilter';
import CustomTable from '../components/CustomTable';
import CapsyncIcon from '../../components/CapsyncIcon';
import CommonSpinner from '../../components/CommonSpinner';
import { useBrandDetails } from '../../slices/brandingSlice';
import CapsyncToolTip from '../../components/CapsyncToolTip';
import CapsyncBreadcrumb from '../../components/CapsyncBreadcrumb';
import CapFavicon from '../../assets/images/brand/cap-icon-logo.svg';
import defaultImg from '../../assets/images/institution_logo/ins_129570.svg';
import SkeletonTransactions from '../components/Skeleton/SkeletonTransactions';
import { useCategoryDetail, useCategoryTableDetails } from '../../slices/categorySlice';

// HOOKS IMPORTS
import useDebounce from '../../utils/Hooks/useDebounce';
import { decryptReact } from '../../utils/Hooks/EncryptDecryptReact';

// API
import {
  getTransactions,
  handleLoader,
  investmentTransactions,
  useLastSyncData,
  useLoader
} from '../../slices/plaidSlice';
import { useUserID } from '../../slices/authSlice';

/* ============================== TRANSACTIONS ============================== */
const Transaction = () => {
  const dispatch = useDispatch();

  const categories = useCategoryDetail();
  const categoriesData = useCategoryTableDetails();
  const userID = useUserID();
  const plaidMask = useParams().mask_id;
  const category = useParams().categories;
  const apiDataLimit = 1000;
  const brandDetails = useBrandDetails();
  const lastSyncData = useLastSyncData();
  const loader = useLoader();

  const [selectCustomFilter, setSelectCustomFilter] = useState('View All');
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [allTransactionsList, setAllTransactionsList] = useState([]);
  const [transactionsList, setTransactionsList] = useState([]);
  const [rowStartIdx, setRowStartIdx] = useState(0);
  const paginationDropdownItems = [10, 15, 20, 25, 30];
  const [rowsPerPage, setRowsPerPage] = useState(paginationDropdownItems[0]);
  const [totalRow, setTotalRow] = useState(0);
  const [filteredTransactions, setFilteredTransactions] = useState([]);
  const [transactionTypeFilterList, setTransactionTypeFilterList] = useState([]);
  const [transactionTypeFilterActives, setTransactionTypeFilterActives] = useState([]);
  const [search, setSearch] = useState('');
  const [sort, setSort] = useState({
    name: '',
    type: ''
  });
  const [transDate, setTransDate] = useState({
    start: '',
    end: ''
  });
  const [downloadButton, setDownloadButton] = useState(false);
  const [transactionTypeFilterModelShow, setTransactionTypeFilterModelShow] = useState(false);
  const [dropdownDisplay, setDropdownDisplay] = useState(rowsPerPage);
  const { transactionInsID } = useSelector((state) => state.category);
  const transactionTypeFilterDropdown = useRef(null);

  const closeOpenMenus = (e) => {
    if (
      transactionTypeFilterModelShow &&
      !!transactionTypeFilterDropdown.current &&
      !transactionTypeFilterDropdown.current.contains(e.target)
    ) {
      setTransactionTypeFilterModelShow(false);
    }
  };

  document.addEventListener('mousedown', closeOpenMenus);

  useEffect(() => {
    getAllTransactions(
      0,
      0,
      search,
      sort.name,
      sort.value,
      startDate,
      endDate,
      transactionTypeFilterActives
    );
    // fetchTransactionBreadCrumb();
  }, []);

  useEffect(() => {
    if (!transactionsList || !transactionsList.length) {
      setDownloadButton(true);
    } else {
      setDownloadButton(false);
    }
  }, [transactionsList]);

  useEffect(() => {
    checkTransactionDate();
  }, [transDate]);

  const checkTransactionDate = () => {
    if (transDate.end !== '' && transDate.start !== '') {
      const startDate = moment_timezone(transDate.start).tz('America/New_York').toDate();
      const endDate = moment_timezone(transDate.end).tz('America/New_York').toDate();
      setStartDate(startDate);
      setEndDate(endDate);
      getAllTransactions(
        0,
        0,
        search,
        sort.name,
        sort.value,
        startDate,
        endDate,
        transactionTypeFilterActives
      );
      setRowStartIdx(0);
    }
  };

  const fetchInsId = () => {
    const decryptedData = decryptReact(transactionInsID);
    return decryptedData;
  };

  const capitalizeName = (first) => {
    return first.substring(0, 1).toUpperCase() + first.substring(1, first.lengt);
  };

  const currencyFormat = (num) => {
    if (num > 0) {
      return (
        '$' +
        Number(num)
          .toFixed(2)
          .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
      );
    } else {
      return (
        '-$' +
        Number(Math.abs(num))
          .toFixed(2)
          .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
      );
    }
  };

  const balanceListing = (params, type) => {
    return (
      <span className="dollar-value-gap transaction_custom_column">
        <span className="dollar-margin-right"> $ &nbsp; </span>
        <span>{type ? currencyFormat(Math.abs(params)) : currencyFormat(params)}</span>
      </span>
    );
  };

  const transactionImg = (img) => {
    try {
      return (
        <img
          src={require(`../../assets/images/transaction_img/${img}`)}
          alt="loadergif"
          width={50}
          height={50}
        />
      );
    } catch (error) {
      return (
        <img src={defaultImg} alt="loadergif" className="plaid-img-anotherbank plaid-logo-circle" />
      );
    }
  };

  const descriptionListing = (src, description, type) => {
    return (
      <div className="transaction-flex">
        {type === 'description' ? (
          src.status ? (
            <img src={src.source} alt="" srcSet="" width={50} height={50} />
          ) : (
            transactionImg(src.source)
          )
        ) : (
          src && transactionImg(src)
        )}
        <div>
          {type === 'description' ? (
            <CapsyncToolTip
              Child={description.length > 35 ? description.slice(0, 35) + '...' : description}
              placement={'top'}
              message={description}
              type="text"
            />
          ) : (
            description
          )}
        </div>
      </div>
    );
  };

  const getsubtitle = () => {
    const institute = categoriesData.data.find((d) => d.plaid_mask == plaidMask);
    const subtitle = institute?.institution || institute?.creditor_name || institute?.description;
    return subtitle;
  };

  const assetsRouteKey = [
    'cashonhands',
    'savingaccount',
    'retirementaccount',
    'accountnotesreceviable',
    'lifeinsurance',
    'nonretirementbrokerage',
    'business',
    'automobile',
    'personalproperty',
    'realestate',
    'otherassets'
  ];

  const mainLevelArr = [
    {
      title: assetsRouteKey.includes(categories.path) ? 'Assets' : 'Liability',
      url: `/${categories.parent}`
    },
    {
      title: categories.category_name,
      url: `/${categories.parent}/${categories.path}`
    }
  ];

  const getAllTransactions = (
    offset,
    startId = 0,
    search_keyword = '',
    sort_name = '',
    sort_value = '',
    startDate,
    endDate,
    transactionTypeFilters
  ) => {
    if (['retirementaccount', 'nonretirementbrokerage'].includes(category)) {
      const params = {
        user_id: userID,
        ins_id: fetchInsId(),
        mask_id: plaidMask,
        offset: offset === 'last' ? allTransactionsList.length : offset,
        limit: offset === 'last' ? totalRow : apiDataLimit,
        search_keyword,
        column_name: sort_name,
        sort: sort_value
      };
      if (startDate && endDate) {
        params['start_date'] = startDate;
        params['end_date'] = endDate;
      }
      dispatch(investmentTransactions(params))
        .unwrap()
        .then((response) => {
          dispatch(handleLoader({ transactionListing: false }));
          if (response.code === 200) {
            if (offset === 0) {
              setAllTransactionsList([...response.data.transactions]);
              setTransactionsList(
                [...response.data.transactions].slice(startId, startId + rowsPerPage)
              );
            } else {
              setAllTransactionsList([...allTransactionsList, ...response.data.transactions]);
              setTransactionsList(
                [...allTransactionsList, ...response.data.transactions].slice(
                  startId,
                  startId + rowsPerPage
                )
              );
            }
            setTotalRow(response.data.total);
            setFilteredTransactions(response.data.transactions);
          }
        })
        .catch((err) => {
          dispatch(handleLoader({ transactionListing: false }));
          console.log(err);
          return false;
        });
    } else {
      const params = {
        user_id: userID,
        ins_id: fetchInsId(),
        mask_id: plaidMask,
        offset: offset === 'last' ? allTransactionsList.length : offset,
        limit: offset === 'last' ? totalRow : apiDataLimit,
        search_keyword,
        column_name: sort_name,
        sort: sort_value,
        transactionTypeFilters: transactionTypeFilters
      };
      if (startDate && endDate) {
        params['start_date'] = startDate;
        params['end_date'] = endDate;
      }
      dispatch(getTransactions(params))
        .unwrap()
        .then((response) => {
          dispatch(handleLoader({ transactionListing: false }));
          if (offset === 0) {
            setAllTransactionsList([...response.data.trasactions]);
            setTransactionsList(
              [...response.data.trasactions].slice(startId, startId + rowsPerPage)
            );
            setTransactionTypeFilterList(response.data.transactionTypeFilters);
          } else {
            setAllTransactionsList([...allTransactionsList, ...response.data.trasactions]);
            setTransactionsList(
              [...allTransactionsList, ...response.data.trasactions].slice(
                startId,
                startId + rowsPerPage
              )
            );
            setTransactionTypeFilterList(response.data.transactionTypeFilters);
          }

          // rowStartIdx
          setTotalRow(response.data.total);
          setFilteredTransactions(response.data.trasactions);
        })
        .catch((err) => {
          dispatch(handleLoader({ transactionListing: false }));
          console.log(err);
          return false;
        });
    }
  };

  const checkCustomFilter = (event) => {
    setSelectCustomFilter(event);

    if (event === 'Last Month') {
      const startDate = moment_timezone()
        .tz('America/New_York')
        .subtract(1, 'months')
        .startOf('month')
        .toDate();
      const endDate = moment_timezone()
        .tz('America/New_York')
        .subtract(1, 'months')
        .endOf('month')
        .toDate();
      setStartDate(startDate);
      setEndDate(endDate);
      getAllTransactions(
        0,
        0,
        search,
        sort.name,
        sort.value,
        startDate,
        endDate,
        transactionTypeFilterActives
      );
      setRowStartIdx(0);
    } else if (event === 'All data' || event === 'View All') {
      setStartDate(null);
      setEndDate(null);
      getAllTransactions(
        0,
        0,
        search,
        sort.name,
        sort.value,
        null,
        null,
        transactionTypeFilterActives
      );
      setRowStartIdx(0);
    } else if (event === 'Year to date') {
      const startDate = moment_timezone().tz('America/New_York').subtract(1, 'years').toDate();
      const endDate = moment_timezone().tz('America/New_York').toDate();
      setStartDate(startDate);
      setEndDate(endDate);
      getAllTransactions(
        0,
        0,
        search,
        sort.name,
        sort.value,
        startDate,
        endDate,
        transactionTypeFilterActives
      );
      setRowStartIdx(0);
    } else if (event === 'Last Quarter') {
      const startDate = moment_timezone()
        .tz('America/New_York')
        .subtract(3, 'months')
        .startOf('quarter')
        .toDate();
      const endDate = moment_timezone()
        .tz('America/New_York')
        .subtract(3, 'months')
        .endOf('quarter')
        .toDate();
      setStartDate(startDate);
      setEndDate(endDate);
      getAllTransactions(
        0,
        0,
        search,
        sort.name,
        sort.value,
        startDate,
        endDate,
        transactionTypeFilterActives
      );
      setRowStartIdx(0);
    }
  };

  const transactionDate = (params, type) => {
    type === 'start'
      ? setTransDate({ ...transDate, start: params })
      : setTransDate({ ...transDate, end: params });
  };

  const handleSearch = (params) => {
    setSearch(params);
    onHandleSearchData(params);
  };

  const onHandleSearchData = useDebounce((params) => {
    setTransactionTypeFilterActives([]);
    getAllTransactions(0, 0, params, sort.name, sort.value, startDate, endDate, []);
    setRowStartIdx(0);
  }, 700);

  const getIns = () => {
    const subtitle = categoriesData.data.find((d) => d.plaid_mask == plaidMask)?.institution;
    return subtitle;
  };

  const columnListing = () => {
    if (['retirementaccount', 'nonretirementbrokerage'].includes(category)) {
      return [
        {
          name: 'Date',
          renderCell: (tr_itr) => moment(tr_itr.date).utc().format('MM/DD/YYYY'),
          cell: (tr_itr) => moment(tr_itr.date).utc().format('MM/DD/YYYY'),
          sortable: true,
          width: 160,
          column_name: 'date'
        },
        {
          name: 'Subtype',
          renderCell: (tr_itr) => capitalizeName(tr_itr.subtype),
          sortable: true,
          width: 200,
          column_name: 'subtype'
        },
        {
          name: 'Description',
          renderCell: (tr_itr) => (
            <CapsyncToolTip
              Child={tr_itr.name.length > 44 ? tr_itr.name.slice(0, 44) + '...' : tr_itr.name}
              placement={'top'}
              message={tr_itr.name}
              type="text"
            />
          ),
          sortable: true,
          width: 700,
          column_name: 'description'
        },
        {
          name: 'Currency',
          renderCell: (tr_itr) => <span className="text-end">{tr_itr.currency}</span>,
          sortable: true,
          width: 160,
          column_name: 'currency',
          className: 'text-end'
        },
        {
          name: 'Quantity',
          renderCell: (tr_itr) => <span className="text-end">{tr_itr.quantity}</span>,
          sortable: true,
          width: 160,
          column_name: 'quantity',
          className: 'text-end'
        },
        {
          name: 'Price',
          renderCell: (tr_itr) => (
            <span className="text-end">{currencyFormat(tr_itr.price.toFixed(2))}</span>
          ),
          sortable: true,
          width: 200,
          column_name: 'price',
          className: 'text-end'
        },
        {
          name: 'Fees',
          renderCell: (tr_itr) => (
            <span className="text-end">{currencyFormat(tr_itr.fees.toFixed(2))}</span>
          ),
          sortable: true,
          width: 200,
          column_name: 'fees',
          className: 'text-end'
        },
        {
          name: 'Amount',
          renderCell: (tr_itr) => (
            <span className="text-end">{currencyFormat(tr_itr.amount.toFixed(2))}</span>
          ),
          cell: (tr_itr) => balanceListing(tr_itr.amount, true),
          sortable: true,
          width: 200,
          column_name: 'amount',
          className: 'text-end'
        }
      ];
    } else {
      return [
        {
          name: 'Date',
          renderCell: (tr_itr) => moment(tr_itr.date).utc().format('MM/DD/YYYY'),
          cell: (tr_itr) => moment(tr_itr.date).utc().format('MM/DD/YYYY'),
          sortable: true,
          width: 200,
          column_name: 'date'
        },
        {
          name: 'Description',
          renderCell: (tr_itr) => descriptionListing(tr_itr.img, tr_itr.name, 'description'),
          sortable: true,
          width: 600,
          column_name: 'name'
        },
        {
          name: 'Transaction Type',
          renderCell: (tr_itr) =>
            descriptionListing(
              tr_itr.transaction_type_img,
              tr_itr.transaction_type,
              'transaction_type'
            ),
          sortable: false,
          width: 300,
          column_name: 'trans_type',
          isFilter: true
        },
        {
          name: 'Amount',
          className: 'text-end',
          renderCell: (tr_itr) => (
            <span className={`text-end ${tr_itr.amount > 0 && 'cs-primary'}`}>
              {currencyFormat(tr_itr.amount.toFixed(2))}
            </span>
          ),
          sortable: false,
          width: 300,
          column_name: 'amount',
          header_class: 'text-end'
        },
        {
          name: `Balance`,
          className: 'text-end',
          renderCell: (tr_itr) => (
            <span className="text-end">{currencyFormat(tr_itr.final_balance.toFixed(2))}</span>
          ),
          sortable: true,
          width: 300,
          column_name: 'final_balance',
          header_class: 'text-end'
        }
      ];
    }
  };

  const allColumnsData = columnListing();

  const onSort = (column) => {
    if (!column.sortable) {
      return false;
    }
    if (sort && sort.name && sort.name === column.column_name) {
      if (sort.value === 'ASC') {
        setSort({
          ...sort,
          value: 'DESC'
        });
        getAllTransactions(
          0,
          0,
          search,
          sort.name,
          'DESC',
          startDate,
          endDate,
          transactionTypeFilterActives
        );
        setRowStartIdx(0);
      } else {
        setSort({
          name: '',
          value: ''
        });
        getAllTransactions(0, 0, search, '', '', startDate, endDate, transactionTypeFilterActives);
        setRowStartIdx(0);
      }
    } else {
      setSort({
        name: column.column_name,
        value: 'ASC'
      });
      getAllTransactions(
        0,
        0,
        search,
        column.column_name,
        'ASC',
        startDate,
        endDate,
        transactionTypeFilterActives
      );
      setRowStartIdx(0);
    }
  };

  const onHandleActiveTransactionTypeFilter = (key) => {
    if (transactionTypeFilterActives.includes(key)) {
      const updatedRowData = transactionTypeFilterActives.filter((data) => data !== key);
      setTransactionTypeFilterActives(updatedRowData);
      getAllTransactions(0, 0, search, sort.name, sort.value, startDate, endDate, updatedRowData);
      setRowStartIdx(0);
    } else {
      setTransactionTypeFilterActives([...transactionTypeFilterActives, key]);
      getAllTransactions(0, 0, search, sort.name, sort.value, startDate, endDate, [
        ...transactionTypeFilterActives,
        key
      ]);
      setRowStartIdx(0);
    }
  };

  const statusFilter = (
    <div className="wm-clientbill-filter transaction-filter">
      <Dropdown
        className="cs-form-dropdown"
        show={transactionTypeFilterModelShow}
        ref={transactionTypeFilterDropdown}>
        <Dropdown.Toggle
          onClick={() => setTransactionTypeFilterModelShow(!transactionTypeFilterModelShow)}>
          <span className="cs-neutral-100">
            <CapsyncIcon title="filter-filled" size="18" />
          </span>
        </Dropdown.Toggle>
        <Dropdown.Menu autoClose="inside">
          {transactionTypeFilterList.map((key, index) => (
            <Dropdown.Item
              onClick={() => onHandleActiveTransactionTypeFilter(key)}
              key={index.toString()}
              className={`${
                transactionTypeFilterActives.includes(key) ? ' client-status-active ' : ''
              } client-status-default`}>
              {transactionTypeFilterActives.includes(key) && (
                <span className={transactionTypeFilterActives.includes(key) ? '' : 'cs-primary'}>
                  <CapsyncIcon title="check-outlined" size="14" />
                </span>
              )}
              <Form.Check
                type="checkbox"
                className="cs-form-check-box"
                onChange={(e) => {
                  transactionTypeFilterActives.includes(key);
                }}
                checked={transactionTypeFilterActives.includes(key)}
                label={<span className="default-regular-body-text-m cs-neutral-90">{key}</span>}
              />
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );

  const handlePaginationDropdownClick = (dropdownValue) => {
    setDropdownDisplay(dropdownValue);
    setRowStartIdx(0);
    if (dropdownValue === 'All') {
      setRowsPerPage(filteredTransactions.length);
      return;
    }
    setTransactionsList(allTransactionsList.slice(0, 0 + dropdownValue));
    setRowsPerPage(dropdownValue);
  };

  const currentNumberOfRows = (rowStartIdx, rowsPerPage, lengthOfCompanies) => {
    if (rowStartIdx + rowsPerPage <= lengthOfCompanies) {
      return rowsPerPage;
    } else {
      return lengthOfCompanies - rowStartIdx;
    }
  };

  const handleFirstPageClick = () => {
    setTransactionsList(allTransactionsList.slice(0, 0 + rowsPerPage));
    setRowStartIdx(0);
    // Assuming your rowsPerPage is already set appropriately
  };

  const onPageChange = (idx, state) => {
    if (allTransactionsList.length >= idx + rowsPerPage) {
      if (state === 'next') {
        setTransactionsList(
          allTransactionsList.slice(idx + rowsPerPage, idx + rowsPerPage + rowsPerPage)
        );
      } else {
        setTransactionsList(allTransactionsList.slice(idx, idx + rowsPerPage));
      }
    } else {
      getAllTransactions(
        allTransactionsList.length,
        idx,
        search,
        sort.name,
        sort.value,
        startDate,
        endDate,
        transactionTypeFilterActives
      );
    }
    if (state === 'next') {
      setRowStartIdx(idx + rowsPerPage);
    } else {
      setRowStartIdx(idx);
    }
  };

  const handleLastPageClick = () => {
    const totalRows = totalRow;
    const lastPageStartIdx = Math.floor((totalRows - 1) / rowsPerPage) * rowsPerPage;
    if (allTransactionsList.length === totalRow) {
      setTransactionsList(
        allTransactionsList.slice(lastPageStartIdx, lastPageStartIdx + rowsPerPage)
      );
    } else {
      getAllTransactions(
        'last',
        lastPageStartIdx,
        search,
        sort.name,
        sort.value,
        startDate,
        endDate,
        transactionTypeFilterActives
      );
    }
    setRowStartIdx(lastPageStartIdx);
  };
  const [isLoader] = useOutletContext();

  console.log('subTitle', getsubtitle());

  return (
    <section className="transaction-main-section">
      <Container fluid>
        {isLoader ? (
          <SkeletonTransactions />
        ) : (
          <React.Fragment>
            <div className="cs-section-topbar">
              <CapsyncBreadcrumb
                mainTitle={assetsRouteKey.includes(categories.path) ? 'Assets' : 'Liability'}
                subTitle={getsubtitle()}
                multiLevelFlag="true"
                mainLevelArr={mainLevelArr}
              />
              <div className={`cs-title cs-break-title`}>
                <h1 className="cs-semi-bold-h1 cs-neutral-100">Transactions</h1>
                {lastSyncData && lastSyncData?.lastSyncData ? (
                  <span className="cs-regular-body-text-m cs-neutral-80">
                    {`Last sync: ${lastSyncData.lastSyncData}`}
                  </span>
                ) : (
                  ''
                )}
              </div>
            </div>

            {loader && loader.transactionListing ? (
              <div className="text-center api-prog-bar sign-loader-area">
                <div className="text-center">
                  {brandDetails?.is_use_load_image ? (
                    <Image
                      src={(brandDetails && brandDetails.small_logo_s3_url) || CapFavicon}
                      className="spin-animation"
                      width={120}
                      height={120}
                    />
                  ) : (
                    <CommonSpinner size={45} classParent="cs-primary" />
                  )}
                </div>
              </div>
            ) : (
              <React.Fragment>
                <SearchFilter
                  checkCustomFilter={checkCustomFilter}
                  transactionDate={transactionDate}
                  selectCustomFilter={selectCustomFilter}
                  startDate={startDate}
                  endDate={endDate}
                  mask_id={plaidMask}
                  transDate={transDate}
                  handleSearch={handleSearch}
                  search={search}
                  institute={getIns}
                  editAccessUser={true} // props.editAccessUser
                  isInvestTransactions={['retirementaccount', 'nonretirementbrokerage'].includes(
                    category
                  )}
                  downloadButton={downloadButton}
                  setDownloadButton={setDownloadButton}
                />
                {(transactionsList && transactionsList.length > 0) ||
                transactionTypeFilterActives.length ? (
                  <React.Fragment>
                    <div className="plaid-transactions-table">
                      <CustomTable
                        data={transactionsList}
                        columns={allColumnsData}
                        sortingColumnFunc={onSort}
                        transactionTHLabelElement={true}
                        search={search}
                        statusFilter={statusFilter}
                        sort={sort}
                        columnVisible={false}
                      />
                    </div>
                    <div className="cs-custom-pagination">
                      <div className="cs-common-add-dropdown">
                        <span className="cs-light-body-text-m cs-neutral-80">Rows per page:</span>
                        <Dropdown>
                          <Dropdown.Toggle className="cs-light-body-text-m cs-neutral-80">
                            {dropdownDisplay}
                            <CapsyncIcon title="chevron-down-outlined" size="12" />
                          </Dropdown.Toggle>
                          <Dropdown.Menu onSelect={(e) => console.log(e)}>
                            {paginationDropdownItems.map((noOfRowsPerPage, i) => {
                              return (
                                <Dropdown.Item
                                  key={i}
                                  className={
                                    noOfRowsPerPage === dropdownDisplay && 'cs-selected-dropdown'
                                  }
                                  onClick={() => handlePaginationDropdownClick(noOfRowsPerPage)}
                                  value={noOfRowsPerPage}>
                                  {noOfRowsPerPage}
                                </Dropdown.Item>
                              );
                            })}
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                      <div className="cs-light-body-text-m cs-neutral-80">
                        <p>
                          {rowStartIdx + 1} -{' '}
                          {rowStartIdx + currentNumberOfRows(rowStartIdx, rowsPerPage, totalRow)} of{' '}
                          {totalRow}
                        </p>
                      </div>
                      <div className="cs-pagination-arrow">
                        <Button
                          onClick={handleFirstPageClick}
                          disabled={rowStartIdx === 0}
                          className={rowStartIdx === 0 ? 'cs-neutral-50' : 'cs-neutral-100'}>
                          <CapsyncIcon title="chevron-left-skip-outlined" size="18" />
                        </Button>
                        <Button
                          onClick={() => onPageChange(rowStartIdx - rowsPerPage)}
                          disabled={rowStartIdx === 0}
                          className={rowStartIdx === 0 ? 'cs-neutral-50' : 'cs-neutral-100'}>
                          <CapsyncIcon title="chevron-left-outlined" size="18" />
                        </Button>
                        <Button
                          onClick={() => onPageChange(rowStartIdx + rowsPerPage)}
                          className={
                            !(rowStartIdx + rowsPerPage < totalRow)
                              ? 'cs-neutral-50'
                              : 'cs-neutral-100'
                          }
                          disabled={!(rowStartIdx + rowsPerPage < totalRow)}>
                          <CapsyncIcon title="chevron-right-outlined" size="18" />
                        </Button>
                        <Button
                          onClick={handleLastPageClick}
                          className={
                            !(rowStartIdx + rowsPerPage < totalRow)
                              ? 'cs-neutral-50'
                              : 'cs-neutral-100'
                          }
                          disabled={!(rowStartIdx + rowsPerPage < totalRow)}>
                          <CapsyncIcon title="chevron-right-skip-outlined" size="18" />
                        </Button>
                      </div>
                    </div>
                  </React.Fragment>
                ) : (
                  <span className="cs-common-no-record"> There are no records to display </span>
                )}
              </React.Fragment>
            )}
          </React.Fragment>
        )}
      </Container>
    </section>
  );
};

// Transaction.propTypes = {
// };

export default Transaction;
