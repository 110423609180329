import React, { useEffect, useState, useRef } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { Col, Container, Row, Form, Dropdown } from 'react-bootstrap';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import moment from 'moment';

// COMPONENT IMPORTS
import LineChart from '../components/LineChart';
import commonNames from '../../utils/commonNames';
import LinkModal from '../../components/LinkModal';
import CustomTable from '../components/CustomTable';
import ManageFile from '../../components/ManageFile';
import UnpaidTaxes from './categoryForms/UnpaidTaxes';
import DeleteModal from '../../components/DeleteModal';
import UnlinkModal from '../../components/UnlinkModal';
import CapsyncIcon from '../../components/CapsyncIcon';
import NotesPayable from './categoryForms/NotesPayable';
import CommonSpinner from '../../components/CommonSpinner';
import CapsyncToolTip from '../../components/CapsyncToolTip';
import AccountsPayable from './categoryForms/AccountsPayable';
import OtherLiabilities from './categoryForms/OtherLiabilities';
import { ADD_ACCOUNT_MODALS } from '../../constants/addAccount';
import UpgradeStorageModal from '../FileManagement/UpgradeStorageModal';
import { OTHER_MESSAGES, TOAST_MESSAGE } from '../../constants/message';
import { monthWiseQuarter, setAddressString } from '../../utils/common';
import CapsyncSectionHeader from '../../components/CapsyncSectionHeader';
import MortgagesOnRealEstate from './categoryForms/MortgagesOnRealEstate';
import InstallmentAccountAuto from './categoryForms/InstallmentAccountAuto';
import { scannedFileElem } from '../FileManagement/FileManagementCommonElem';
import InstallmentAccountOther from './categoryForms/InstallmentAccountOther';
import LoanAgainstLifeInsurance from './categoryForms/LoanAgainstLifeInsurance';
import DownloadConfirmationModal from '../FileManagement/DownloadConfirmationModal';
import SkeletonCategoryDashboard from '../components/Skeleton/SkeletonCategoryDashboard';

// HOOKS IMPORTS
import { encryptReact } from '../../utils/Hooks/EncryptDecryptReact';

// API
import {
  addColumnFilterData,
  deleteLiabilitiesCategory,
  getColumnFilterData,
  getLiabilitiesCategory,
  useCategoryTableDetails,
  useCategoryDetail,
  handleTransactionInsID
} from '../../slices/categorySlice';
import { handleAccountsData } from '../../slices/plaidSlice';
import { getMainDashboard } from '../../slices/dashboardSlice';
import { useToUserPermission, useUserID } from '../../slices/authSlice';
import { fetchAllPlaidAccountsData } from '../../services/plaid.service';

const LiabilitiesCategories = () => {
  const dispatch = useDispatch();
  const navigation = useNavigate();

  const categories = useCategoryDetail();
  const categoriesData = useCategoryTableDetails();
  const categoryId = categories?.category_id;
  const userPermission = useToUserPermission();
  const userId = useUserID();

  const dropdownRef = useRef(null);

  const [column, setColumn] = useState([]);
  const [openManageFile, setOpenManageFile] = useState(false);
  const [currentItemId, setCurrentItemId] = useState(null);
  const [record, setRecord] = useState({});
  const [addAccountStep, setAddAccountStep] = useState(ADD_ACCOUNT_MODALS.MAIN_MODAL);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [rowIdToDelete, setRowIdToDelete] = useState(null);
  const [categoryColumnsFilters, setCategoryColumnsFilters] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [fileStorageModal, setFileStorageModal] = useState(false);
  const [categoryRowId, setCategoryRowId] = useState({
    id: ''
  });
  const [showLinkModal, setShowLinkModal] = useState(false);
  const [showUnlinkModal, setShowUnlinkModal] = useState(false);
  const [linkAddressObj, setLinkAddressObj] = useState({});
  const [scannedFileDetails, setScannedFileDetails] = useState({ ...scannedFileElem });

  const getAssetsCategoryFun = async () => {
    try {
      const payload = { categoryId: categoryId, userId: userId };
      await dispatch(getLiabilitiesCategory(payload)).unwrap();
    } catch (error) {
      console.log('error :>> ', error);
    }
  };
  useEffect(() => {
    getAssetsCategoryFun();
    dispatch(handleTransactionInsID(''));
  }, []);

  //   let COLUMN = [
  //     {
  //       label: categories?.tabArray,
  //       renderCell: (item) => item.display_name,
  //       width: '210'
  //     }
  //   ];
  const handleManageFileClick = (itemId) => {
    setCurrentItemId(itemId);
    setOpenManageFile(true);
  };

  const handleDeleteRow = async (id) => {
    try {
      const data = {
        categoryId: categoryId,
        userId: userId,
        rowId: id
      };
      const response = await dispatch(deleteLiabilitiesCategory(data)).unwrap();
      if (response.code === 200) {
        getAssetsCategoryFun();
        setShowDeleteModal(false);
        toast.success(TOAST_MESSAGE.RECORD_DELETE_SUCCESS);
        const res = await fetchAllPlaidAccountsData(userId);
        if (res.code === 200) {
          dispatch(handleAccountsData(res.data));
        }
        dispatch(getMainDashboard(userId));
      }
    } catch (error) {
      console.log('error :>> ', error);
    }
  };
  const handleOpenDeleteModal = (id) => {
    setShowDeleteModal(true);
    setRowIdToDelete(id);
  };

  const handleUpdateRow = async (item) => {
    try {
      setRecord(item);
      setAddAccountStep(categories?.modal_key);
    } catch (error) {
      console.log('error :>> ', error);
    }
  };

  const handleCategoryFormCancel = () => {
    setAddAccountStep(ADD_ACCOUNT_MODALS.ADD_MANUAL_ACCOUNT_MODAL);
  };

  const handleCategoryFormSubmit = () => setAddAccountStep(ADD_ACCOUNT_MODALS.ACCOUNT_LINKED);

  const handleAmortizationClick = (item, title) => {
    if (title === 'Amortization Schedule') {
      navigation(`/${categories.parent}/amortization/${categories.path}/${item.id}`);
    }
  };
  const onLinkButtonClick = (id, address) => {
    if (address) {
      setLinkAddressObj(address);
    }
    setCategoryRowId({
      id
    });
    setShowLinkModal(true);
  };
  const onUnlinkButtonClick = (id) => {
    setCategoryRowId({
      id
    });
    setShowUnlinkModal(true);
  };

  const goToNavigate = (path, institution_id) => {
    const fetchData = encryptReact(institution_id);
    dispatch(handleTransactionInsID(fetchData));
    navigation(`${path}/${fetchData}`);
  };

  const handleClick = (title, institution_id, plaid_mask) => {
    if (title === commonNames.Portfolio) {
      goToNavigate(`portfolio/${plaid_mask}`, institution_id);
    } else if (title === commonNames.Transactions) {
      goToNavigate(`transaction/${plaid_mask}`, institution_id);
    }
  };

  const actionIcons = (item, val) => {
    const pathForPortfolio = ['retirementaccount', 'nonretirementbrokerage'];
    const pathForAmortizationTransaction = [
      'mortgagesonrealestate',
      'installmentsauto',
      'notespayabletobanksandothers'
    ];
    if (val.transaction_portfolio || !item.is_manual || val.isTransaction) {
      if (pathForPortfolio.includes(categories.path)) {
        return (
          <Dropdown>
            <Dropdown.Toggle variant="link" id="option-icons">
              <span className="icon cs-neutral-80">
                <CapsyncIcon title="option-horizontal-filled" size="18" />
              </span>
            </Dropdown.Toggle>
            <div className={categoriesData?.data?.length === 1 ? 'upper-dropdown' : ''}>
              <Dropdown.Menu>
                <Dropdown.Item
                  onClick={(e) =>
                    item.is_manual === false &&
                    handleClick(commonNames.Portfolio, item.institution_id, item.plaid_mask)
                  }
                  className={item.is_manual === false ? '' : 'cs-disabled'}
                  id={item.is_manual === false && `option-${commonNames.Portfolio.toLowerCase()}`}>
                  {commonNames.Portfolio}
                  {item.is_manual === true && (
                    <CapsyncToolTip
                      Child={'info-outlined'}
                      placement={'top'}
                      message={'Data not available for this account'}
                      type="icon"
                      size={22}
                    />
                  )}
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={(e) =>
                    item.is_manual === false &&
                    handleClick(commonNames.Transactions, item.institution_id, item.plaid_mask)
                  }
                  className={item.is_manual === false ? '' : 'disable-amortization cs-disabled'}
                  id={
                    item.is_manual === false && `option-${commonNames.Transactions.toLowerCase()}`
                  }>
                  {item.transaction_loader || item.is_transaction_loader ? (
                    <div className="ira-portfolio-tooltip">
                      <CapsyncToolTip
                        Child={
                          <React.Fragment>
                            <span>{commonNames.Transactions}</span>
                            <CommonSpinner classParent="cs-neutral-10 m-auto" size="20" />
                          </React.Fragment>
                        }
                        placement={'top'}
                        message={'We are fetching your data'}
                        type="text"
                        size={22}
                      />
                    </div>
                  ) : (
                    <React.Fragment>
                      {commonNames.Transactions}
                      {item.is_manual === true && (
                        <CapsyncToolTip
                          Child={'info-outlined'}
                          placement={'top'}
                          message={'Data not available for this account'}
                          type="icon"
                          size={22}
                        />
                      )}
                    </React.Fragment>
                  )}
                </Dropdown.Item>
              </Dropdown.Menu>
            </div>
          </Dropdown>
        );
      } else if (pathForAmortizationTransaction.includes(categories.path)) {
        return (
          <Dropdown>
            <Dropdown.Toggle variant="link" id="option-icons">
              <span className="icon cs-neutral-80">
                <CapsyncIcon title="option-horizontal-filled" size="18" />
              </span>
            </Dropdown.Toggle>
            <div className={categoriesData?.data?.length === 1 ? 'upper-dropdown' : ''}>
              <Dropdown.Menu>
                <Dropdown.Item
                  onClick={(e) =>
                    item.is_amortization_link_enable === true &&
                    handleAmortizationClick(item, 'Amortization Schedule')
                  }
                  className={item.is_amortization_link_enable === true ? '' : 'cs-disabled'}
                  id={
                    item.is_amortization_link_enable === true &&
                    `option-${'Amortization Schedule'.toLowerCase()}`
                  }>
                  {'Amortization Schedule'}
                  {item.is_amortization_link_enable === false && (
                    <CapsyncToolTip
                      Child={'info-outlined'}
                      placement={'top'}
                      message={'Data not available for this account'}
                      type="icon"
                      size={22}
                    />
                  )}
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={(e) =>
                    item.is_manual === false &&
                    handleClick(commonNames.Transactions, item.institution_id, item.plaid_mask)
                  }
                  className={item.is_manual === false ? '' : 'disable-amortization cs-disabled'}
                  id={
                    item.is_manual === false && `option-${commonNames.Transactions.toLowerCase()}`
                  }>
                  {item.transaction_loader || item.is_transaction_loader ? (
                    <div className="ira-portfolio-tooltip">
                      <CapsyncToolTip
                        Child={
                          <React.Fragment>
                            <span>{commonNames.Transactions}</span>
                            <CommonSpinner classParent="cs-neutral-10 m-auto" size="20" />
                          </React.Fragment>
                        }
                        placement={'top'}
                        message={'We are fetching your data'}
                        type="text"
                        size={22}
                      />
                    </div>
                  ) : (
                    <React.Fragment>
                      {commonNames.Transactions}
                      {item.is_manual === true && (
                        <CapsyncToolTip
                          Child={'info-outlined'}
                          placement={'top'}
                          message={'Data not available for this account'}
                          type="icon"
                          size={22}
                        />
                      )}
                    </React.Fragment>
                  )}
                </Dropdown.Item>
              </Dropdown.Menu>
            </div>
          </Dropdown>
        );
      } else {
        return (
          <div
            onClick={() =>
              item.is_manual === false &&
              !item.transaction_loader &&
              !item.is_transaction_loader &&
              handleClick(commonNames.Transactions, item.institution_id, item.plaid_mask)
            }
            className={
              item.is_manual || item.transaction_loader || item.is_transaction_loader
                ? 'common-tooltip-margin disable-amortization cs-disabled'
                : 'common-tooltip-margin last-td-content'
            }
            id="transaction">
            <p>{commonNames.Transactions}</p>
            {item.is_manual === false && !item.transaction_loader && !item.is_transaction_loader ? (
              <span className="cs-neutral-70">
                <CapsyncIcon title="chevron-right-outlined" size="18" />
              </span>
            ) : (
              <CapsyncToolTip
                Child={'info-outlined'}
                placement={'top'}
                message={'Data not available for this account'}
                type="icon"
                size={22}
              />
            )}
          </div>
        );
      }
    } else if (item.is_manual && val.is_amortization === true) {
      return (
        <div
          onClick={(e) =>
            item.is_amortization_link_enable &&
            handleAmortizationClick(item, 'Amortization Schedule')
          }
          className={`cursor-pointer common-tooltip-margin ${item.is_amortization_link_enable === false ? 'cs-disabled' : ''}`}>
          <p>{'Amortization Schedule'}</p>
          <span className="cs-neutral-70">
            {item.is_amortization_link_enable === true ? (
              <CapsyncIcon title="chevron-right-outlined" size="18" />
            ) : (
              <CapsyncToolTip
                Child={'info-outlined'}
                placement={'top'}
                message={'Data not available for this account'}
                type="icon"
                size={18}
              />
            )}
          </span>
        </div>
      );
    }
  };

  useEffect(() => {
    const COLUMN = [];
    categoryColumnsFilters?.forEach((val) => {
      if (val.visible) {
        COLUMN.push({
          label: val.title,
          renderCell: (item) => {
            switch (val.key) {
              case 'action':
                return (
                  <div className="other-action-td">
                    {(userPermission?.permission_type === 'standard' ||
                      !userPermission?.permission_type) && (
                      <div className="table-icons-container cs-file-icon">
                        {['installmentsauto', 'mortgagesonrealestate'].includes(
                          categories.path
                        ) && (
                          <span
                            className="cs-icon-badge-neutral-20 cs-neutral-80"
                            onClick={() => {
                              if (
                                item[
                                  categories.path === 'installmentsauto'
                                    ? 'asset_automobiles'
                                    : 'asset_real_estates'
                                ]
                              ) {
                                onUnlinkButtonClick(item.id);
                              } else {
                                onLinkButtonClick(item.id, item.property_address);
                              }
                            }}
                            // onClick={() => onLinkButtonClick(item.id)}
                          >
                            {item[
                              categories.path === 'installmentsauto'
                                ? 'asset_automobiles'
                                : 'asset_real_estates'
                            ] ? (
                              <CapsyncToolTip
                                Child={'unlink-outlined'}
                                placement={'top'}
                                message={'Unlink'}
                                type="icon"
                                size="18"
                              />
                            ) : (
                              <CapsyncToolTip
                                Child={'link-outlined'}
                                placement={'top'}
                                message={'Link'}
                                type="icon"
                                size="18"
                              />
                            )}
                          </span>
                        )}
                        <span
                          className="cs-icon-badge-neutral-20 cs-neutral-80"
                          id="file-icon"
                          onClick={() => handleManageFileClick(item.id)}>
                          <CapsyncToolTip
                            Child={'files-outlined'}
                            placement={'top'}
                            message={'Manage files'}
                            type="icon"
                            size="18"
                          />
                        </span>
                        <span
                          className="cs-icon-badge-neutral-20 cs-neutral-80"
                          id="edit-icon"
                          onClick={() => handleUpdateRow(item)}>
                          <CapsyncToolTip
                            Child={'edit-outlined'}
                            placement={'top'}
                            message={'Edit details'}
                            type="icon"
                            size="18"
                          />
                        </span>
                        <span
                          className="cs-icon-badge-danger cs-danger"
                          id="delete-icon"
                          onClick={() => handleOpenDeleteModal(item.id)}>
                          <CapsyncToolTip
                            Child={'delete-outlined'}
                            placement={'top'}
                            message={'Delete'}
                            type="icon"
                            size="18"
                          />
                        </span>
                      </div>
                    )}
                    <div className="action-box">
                      <div className="cs-common-add-dropdown plaid-transaction-mini-loader">
                        {actionIcons(item, val)}
                      </div>
                    </div>
                  </div>
                );

              case 'address':
                return <div>{setAddressString(JSON.parse(item[val.key]))}</div>;
              case 'date_updated':
                return (
                  <div className="common-tooltip-margin">
                    {moment(item[val.key]).format('MM/DD/YYYY')}
                  </div>
                );
              case 'maturity_date':
                return (
                  <div className="common-tooltip-margin">
                    {item[val.key] !== null ? moment(item[val.key]).format('MM/DD/YYYY') : '-'}
                  </div>
                );
              // case 'amortization': {
              //   const displayActions = [];
              //   displayActions.push({
              //     isAmortization: val.key,
              //     title: 'Amortization Schedule',
              //     isEnable: item.is_manual && item.is_amortization_link_enable
              //   });

              //   return (
              //     <div
              //       onClick={(e) =>
              //         displayActions[0].isEnable &&
              //         handleAmortizationClick(item, displayActions[0].title)
              //       }
              //       className={`cursor-pointer common-tooltip-margin ${displayActions[0].isEnable === false ? 'cs-disabled' : ''}`}>
              //       <p>{displayActions[0].title}</p>
              //       <span className="cs-neutral-70">
              //         {displayActions[0].isEnable === true ? (
              //           <CapsyncIcon title="chevron-right-outlined" size="18" />
              //         ) : (
              //           <CapsyncToolTip
              //             Child={'info-outlined'}
              //             placement={'top'}
              //             message={'Data not available for this account'}
              //             type="icon"
              //             size={18}
              //           />
              //         )}
              //       </span>
              //     </div>
              //   );
              // }
              case 'ownership':
              case 'apr_percentage':
                return <div>{`${item[val.key]} %`}</div>;
              case 'balance_due':
              case 'payment_monthly':
              case 'mortgage_balance':
              case 'minimum_payment':
              case 'payment':
              case 'loan_amount':
                return (
                  <div
                    className={`${typeof item[val.key] === 'number' ? 'verified-value-icon' : 'common-tooltip-margin'}`}>
                    <div className="dollar-value-gap">
                      {typeof item[val.key] === 'number'
                        ? `$ ${item[val.key].toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`
                        : item[val.key]}
                    </div>
                  </div>
                );
              default: {
                const shouldShowTooltip =
                  ['creditor_name', 'company', 'description'].includes(val.key) &&
                  item['is_manual'];
                return (
                  <div
                    className={`${typeof item[val.key] === 'number' ? 'verified-value-icon' : 'common-tooltip-margin'}`}>
                    {typeof item[val.key] === 'number'
                      ? `$ ${item[val.key].toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`
                      : item[val.key] !== ''
                        ? item[val.key]
                        : '-'}

                    {shouldShowTooltip ? (
                      <CapsyncToolTip
                        Child={'info-outlined'}
                        placement={'top'}
                        message={OTHER_MESSAGES.MANUAL_ENTRY}
                        type="icon"
                        size="18"
                      />
                    ) : null}

                    {['installmentsauto', 'mortgagesonrealestate'].includes(categories.path) &&
                    val.key === 'creditor_name' &&
                    item.asset_automobiles ? (
                      <span className="cs-primary">
                        <CapsyncToolTip
                          Child={'link-outlined'}
                          placement={'top'}
                          message={
                            'This ' +
                              (categories.parent || 'category') +
                              ' is linked with \t' +
                              item &&
                            item.asset_automobiles &&
                            item.asset_automobiles.description
                          }
                          type="icon"
                          size={22}
                        />
                      </span>
                    ) : item.asset_real_estates ? (
                      <span className="cs-primary">
                        <CapsyncToolTip
                          Child={'link-outlined'}
                          placement={'top'}
                          message={
                            'This ' +
                              (categories.parent || 'category') +
                              ' is linked with \t' +
                              item &&
                            item.asset_real_estates &&
                            setAddressString(JSON.parse(item.asset_real_estates.address))
                          }
                          type="icon"
                          size={22}
                        />
                      </span>
                    ) : (
                      ''
                    )}
                  </div>
                );
              }
            }
          },
          width: val.width,
          visible: val.visible,
          key: val.key,
          className: val.className,
          commonVariable: val.commonVariable,
          is_amortization: val.is_amortization || false,
          isTransaction: val.isTransaction || false,
          transaction_portfolio: val.transaction_portfolio || false
        });
      }
    });
    if (userPermission?.permission_type === 'view') {
      const idx = COLUMN.findIndex(
        (x) =>
          x.key === 'action' && !x.isTransaction && !x.transaction_portfolio && !x.is_amortization
      );
      const action_idx = COLUMN.findIndex(
        (x) =>
          x.key === 'action' && (x.isTransaction || x.transaction_portfolio || x.is_amortization)
      );

      const newColumnData =
        idx > -1
          ? COLUMN.filter((_, index) => index !== idx)
          : action_idx > -1
            ? COLUMN.map((col, index) => (index === action_idx ? { ...col, label: '' } : col))
            : COLUMN;
      setColumn(newColumnData);
    } else {
      setColumn(COLUMN);
    }
  }, [categoryColumnsFilters, categoriesData, userPermission]);

  const legendFooter = monthWiseQuarter();

  const series = [
    {
      name: categories?.category_name,
      data: [
        {
          x: legendFooter[0],
          y: categoriesData?.chart_data?.assetsQ1[categories?.key] || 0
        },
        {
          x: legendFooter[1],
          y: categoriesData?.chart_data?.assetsQ2[categories?.key] || 0
        },
        {
          x: legendFooter[2],
          y: categoriesData?.chart_data?.assetsQ3[categories?.key] || 0
        },
        {
          x: legendFooter[3],
          y: categoriesData?.chart_data?.assetsQ4[categories?.key] || 0
        }
      ]
    }
  ];

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleOnDragEnd = (result) => {
    if (!result.destination) return;
    const items = Array.from(categoryColumnsFilters);
    if (result.destination.index === items.length || !items[result.destination.index].draggable)
      return;
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    setCategoryColumnsFilters(items);
  };

  const handleCheckboxChange = (key) => {
    const updatedColumns = categoryColumnsFilters.map((column) =>
      column.key === key ? { ...column, visible: !column.visible } : column
    );
    setCategoryColumnsFilters(updatedColumns);
  };

  // Function to fetch column filters from the database
  const fetchColumnFiltersFromDB = async () => {
    try {
      const data = {
        mainCategory: `${categories.parent === 'liabilities' ? 'liability' : 'assets'}`,
        userId: userId,
        categoryName: categories?.path
      };
      const response = await dispatch(getColumnFilterData(data)).unwrap();
      if (response.code === 200 && response.data) {
        setCategoryColumnsFilters(response.data.columns_name);
      } else {
        // If no data from DB, use default tabArray
        setCategoryColumnsFilters(categories?.tabArray);
      }
    } catch (error) {
      console.error('Error fetching column filters:', error);
      // If error fetching from DB, use default tabArray
      setCategoryColumnsFilters(categories?.tabArray);
    }
  };

  // Function to add or update column filters in the database
  const addColumnFilterDataHandler = async () => {
    try {
      const payload = {
        user_id: userId,
        category_name: categories?.path,
        is_portfolio: false,
        row_id: 0,
        columns_name: categoryColumnsFilters,
        category_type: `${categories.parent === 'liabilities' ? 'liability' : 'assets'}`
      };
      await dispatch(addColumnFilterData(payload)).unwrap();
    } catch (error) {
      console.error('Error adding column filters:', error);
    }
  };

  useEffect(() => {
    if (categories && userId) {
      fetchColumnFiltersFromDB();
    }
  }, [categories]);

  useEffect(() => {
    if (categoryColumnsFilters.length > 0) {
      addColumnFilterDataHandler();
    }
  }, [categoryColumnsFilters]);
  const [isLoader] = useOutletContext();
  return (
    <section className="main-section">
      <Container fluid>
        {isLoader ? (
          <SkeletonCategoryDashboard />
        ) : (
          <React.Fragment>
            <div className="cs-section-topbar">
              <CapsyncSectionHeader
                subTitle={categories?.category_name}
                mainTitle={`Liabilities`}
                pageTitle={categories?.category_name}
                mainUrl={`../../${categories?.parent}`}
              />
            </div>
            <div className="liability-detailed-category">
              <div className="chart-container">
                <Row>
                  <Col lg={12}>
                    <div className="line-chart-container">
                      <div className="chart-heading">
                        <h2 className="cs-semi-bold-h2">
                          Summary
                          <span>&nbsp;</span>
                          <CapsyncToolTip
                            Child={'info-outlined'}
                            placement={window.innerWidth >= 991 ? 'right' : 'top'}
                            message={
                              'Only accounts with transaction data are included in the chart.'
                            }
                            type="icon"
                            size="20"
                          />
                        </h2>
                        <span className="cs-regular-body-text-m cs-neutral-70">
                          Last 12 months data as of last sync
                        </span>
                      </div>
                      <div className="graph-line-chart-main-container">
                        <LineChart
                          linechartData={categoriesData?.chart_data}
                          categories={categories}
                          seriesArray={series}
                          label="LiabilitiesCategory"
                        />
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
              {[
                'notespayabletobanksandothers',
                'installmentsauto',
                'installmentsaccount',
                'accountpayable',
                'mortgagesonrealestate',
                'lifeinsurance'
              ].includes(categories.path) && (
                <Row>
                  <Col>
                    <div className="category-manage-row">
                      <div className="cs-common-add-dropdown">
                        <div className="custom-dropdown" ref={dropdownRef}>
                          <button
                            className="cs-regular-h5 xl-btn cs-btn-icon-primary"
                            onClick={() => setIsOpen(!isOpen)}>
                            <span className="cs-neutral-10">
                              <CapsyncIcon title="filter-filled" size="18" />
                            </span>
                            Manage Columns
                          </button>
                          {isOpen && (
                            <div className="custom-dropdown-menu">
                              <DragDropContext onDragEnd={handleOnDragEnd}>
                                <Droppable droppableId="characters">
                                  {(provided) => (
                                    <div
                                      {...provided.droppableProps}
                                      ref={provided.innerRef}
                                      className="manage-columns-items">
                                      {categoryColumnsFilters.map((data, index) => (
                                        <Draggable
                                          key={data.key}
                                          draggableId={data.key}
                                          index={index}
                                          isDragDisabled={!data.draggable}>
                                          {(provided) => (
                                            <li
                                              {...provided.draggableProps}
                                              ref={provided.innerRef}
                                              key={index}
                                              className="manage-column-item">
                                              {data.key !== 'amortization' ? (
                                                <div className="cs-form-check-box">
                                                  <span
                                                    className={`${!data.draggable ? 'cs-disabled' : 'cs-neutral-100'}`}
                                                    {...provided.dragHandleProps}>
                                                    <CapsyncIcon
                                                      title="order-selector-filled"
                                                      size="18"
                                                    />
                                                  </span>
                                                  <Form.Check
                                                    inline
                                                    className={`cs-light-body-text-m cs-neutral-100 ${!data.filter && 'cs-disabled'}`}
                                                    label={data.title}
                                                    type="checkbox"
                                                    id={`inline-checkbox-${index}`}
                                                    disabled={!data.filter}
                                                    checked={data.visible}
                                                    onChange={() => handleCheckboxChange(data.key)}
                                                  />
                                                </div>
                                              ) : (
                                                ''
                                              )}
                                            </li>
                                          )}
                                        </Draggable>
                                      ))}
                                      {provided.placeholder}
                                    </div>
                                  )}
                                </Droppable>
                              </DragDropContext>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              )}

              <div>
                <Row>
                  <Col lg={12}>
                    <CustomTable data={categoriesData?.data} columns={column} show={true} />
                  </Col>
                </Row>
              </div>
            </div>
          </React.Fragment>
        )}
      </Container>
      {openManageFile && (
        <ManageFile
          userId={userId}
          currentItemId={currentItemId}
          setOpenManageFile={setOpenManageFile}
          setScannedFileDetails={setScannedFileDetails}
          setFileStorageModal={setFileStorageModal}
        />
      )}
      {showLinkModal && (
        <LinkModal
          showLinkModal={showLinkModal}
          updateMainCategory={'assets'}
          updateSubCategory={categories.path === 'installmentsauto' ? 'automobile' : 'realestate'}
          setShowLinkModal={setShowLinkModal}
          descDisplayType={categories.path === 'installmentsauto' ? 'Description' : 'Address'}
          numDisplayType={
            categories.path === 'installmentsauto' ? 'Market Value' : 'Share Market Value'
          }
          numType={categories.path === 'installmentsauto' ? 'market_value' : 'share_market_value'}
          descType={categories.path === 'installmentsauto' ? 'description' : 'address'}
          openAnotherModal={categories.path === 'installmentsauto' ? 'automobile' : 'realEstate'}
          categoryRowId={categoryRowId}
          linkAddressObj={linkAddressObj}
        />
      )}
      {showUnlinkModal && (
        <UnlinkModal
          showUnlinkModal={showUnlinkModal}
          setShowUnlinkModal={setShowUnlinkModal}
          fieldName={'Name of Creditor'}
          categoryRowId={categoryRowId}
          updateSubCategory={categories.path === 'installmentsauto' ? 'automobile' : 'realEstate'}
        />
      )}
      {showDeleteModal && (
        <DeleteModal
          show={showDeleteModal}
          setShowDeleteModal={setShowDeleteModal}
          handleDeleteData={() => handleDeleteRow(rowIdToDelete)}
          handleCloseModel={() => setShowDeleteModal(false)}
        />
      )}
      {addAccountStep === ADD_ACCOUNT_MODALS.ACCOUNT_PAYABLE && (
        <AccountsPayable
          isOpen={addAccountStep === ADD_ACCOUNT_MODALS.ACCOUNT_PAYABLE}
          handleCancel={handleCategoryFormCancel}
          record={record}
          handleCategoryFormSubmit={handleCategoryFormSubmit}
        />
      )}
      {addAccountStep === ADD_ACCOUNT_MODALS.NOTES_PAYABLE && (
        <NotesPayable
          isOpen={addAccountStep === ADD_ACCOUNT_MODALS.NOTES_PAYABLE}
          handleCancel={handleCategoryFormCancel}
          record={record}
          handleCategoryFormSubmit={handleCategoryFormSubmit}
        />
      )}
      {addAccountStep === ADD_ACCOUNT_MODALS.INSTALLMENT_ACCOUNT_AUTO && (
        <InstallmentAccountAuto
          isOpen={addAccountStep === ADD_ACCOUNT_MODALS.INSTALLMENT_ACCOUNT_AUTO}
          handleCancel={handleCategoryFormCancel}
          record={record}
          handleCategoryFormSubmit={handleCategoryFormSubmit}
        />
      )}
      {addAccountStep === ADD_ACCOUNT_MODALS.INSTALLMENT_ACCOUNT_OTHER && (
        <InstallmentAccountOther
          isOpen={addAccountStep === ADD_ACCOUNT_MODALS.INSTALLMENT_ACCOUNT_OTHER}
          record={record}
          handleCancel={handleCategoryFormCancel}
          handleCategoryFormSubmit={handleCategoryFormSubmit}
        />
      )}
      {addAccountStep === ADD_ACCOUNT_MODALS.LOAN_AGAINST_LIFE_INSURANCE && (
        <LoanAgainstLifeInsurance
          isOpen={addAccountStep === ADD_ACCOUNT_MODALS.LOAN_AGAINST_LIFE_INSURANCE}
          handleCancel={handleCategoryFormCancel}
          record={record}
          handleCategoryFormSubmit={handleCategoryFormSubmit}
        />
      )}
      {addAccountStep === ADD_ACCOUNT_MODALS.MORTGAGES_ON_REAL_ESTATE && (
        <MortgagesOnRealEstate
          isOpen={addAccountStep === ADD_ACCOUNT_MODALS.MORTGAGES_ON_REAL_ESTATE}
          handleCancel={handleCategoryFormCancel}
          record={record}
          handleCategoryFormSubmit={handleCategoryFormSubmit}
        />
      )}
      {addAccountStep === ADD_ACCOUNT_MODALS.UNPAID_TAXES && (
        <UnpaidTaxes
          isOpen={addAccountStep === ADD_ACCOUNT_MODALS.UNPAID_TAXES}
          handleCancel={handleCategoryFormCancel}
          record={record}
          handleCategoryFormSubmit={handleCategoryFormSubmit}
        />
      )}
      {addAccountStep === ADD_ACCOUNT_MODALS.OTHER_LIABILITIES && (
        <OtherLiabilities
          isOpen={addAccountStep === ADD_ACCOUNT_MODALS.OTHER_LIABILITIES}
          handleCancel={handleCategoryFormCancel}
          record={record}
          handleCategoryFormSubmit={handleCategoryFormSubmit}
        />
      )}
      {scannedFileDetails?.modalOpen && (
        <DownloadConfirmationModal
          scannedFileDetails={scannedFileDetails}
          onCancelClick={() => setScannedFileDetails({ ...scannedFileElem })}
        />
      )}
      {fileStorageModal && (
        <UpgradeStorageModal
          showModal={fileStorageModal}
          onCancelClick={() => setFileStorageModal(false)}
        />
      )}
    </section>
  );
};

export default LiabilitiesCategories;
