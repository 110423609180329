import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate, useOutletContext, useParams } from 'react-router-dom';
import { Col, Container, Row } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

// COMPONENT IMPORTS
import SkeletonFileManagement from '../../components/Skeleton/SkeletonFileManagement';
import CapsyncLoader from '../../../components/CapsyncLoader.jsx';

import FileManagementHeaderSection from '../HeaderSection/FileManagementHeaderSection';
import UpgradeStorage from '../FooterSection/UpgradeStorage';
import FileFolderMoveModal from '../FileFolderMoveModal.jsx';
import UnsupportedFileModal from '../UnsupportedFileModal.jsx';
import FileManagementDeleteModal from '../FileManagementDeleteModal.jsx';
import DownloadConfirmationModal from '../DownloadConfirmationModal.jsx';
import {
  defaultBreadcrumb,
  deleteMsg,
  fileManagementSortingData,
  loadingMessage,
  MyDocumentSortingDetails,
  scannedFileElem,
  updateFileName,
  useFileManagementClickOutside
} from '../FileManagementCommonElem';
import ListView from '../ListView';
import GridView from '../GridView';
import CreateFolder from '../CreateFolder';
import FolderUpload from '../FolderUpload.jsx';
import FileUpload from '../FileUpload.jsx';
import LoaderModal from '../LoaderModal.jsx';
import FilePreview from '../FilePreview.jsx';
import DropBoxUpload from '../DropBoxUpload.jsx';
import GoogleDriveUpload from '../GoogleDriveUpload.jsx';
import ReplaceFileModal from '../ReplaceFileModal.jsx';
import MoveAlertModal from '../MoveAlertModal.jsx';

import { fileDownload, getFileType, handleS3Upload } from '../../../utils/common.js';

// HOOKS IMPORTS
import { decryptReact, encryptReact } from '../../../utils/Hooks/EncryptDecryptReact';
import useDebounce from '../../../utils/Hooks/useDebounce';

// API
import {
  setProgressUploadDetails,
  useFileUploaded,
  useProgressUploadDetails
} from '../../../slices/commonSlice.js';
import {
  checkLifeInsuranceData,
  deleteFileToTrash,
  deleteTrashItem,
  getFilesData,
  getStorageDetails,
  moveToArchive,
  scanDownloadFile,
  searchMedia,
  uploadOtherFilesFolder,
  useFilesFoldersData,
  useStorageDetails
} from '../../../slices/fileManagementSlice.js';
import { useUser } from '../../../slices/authSlice.js';
import { getMasterCategory, useMasterCategory } from '../../../slices/categorySlice.js';
import { downloadItem } from '../../../slices/downloadSlice.js';

/* ============================== MY DOCUMENT ============================== */
const MyDocument = () => {
  /* PARAMS */
  let { folder_id } = useParams();

  const navigate = useNavigate();
  const dispatch = useDispatch();
  let location = useLocation();

  /* REDUX DATA */
  const user = useUser();
  const fileUpload = useFileUploaded();
  const filesFolders = useFilesFoldersData();
  const masterCategories = useMasterCategory();
  const progressUploadDetails = useProgressUploadDetails();
  const storageDetails = useStorageDetails();

  /* USE REFS */
  const fmListRef = useRef(null);
  const layoutRowRef = useRef(null);
  const documentsRef = useRef(null);
  const parentIdRef = useRef(null);
  const dropBoxUploadRef = useRef();
  const googleDriveUploadRef = useRef();

  /* USE STATES */
  const [parentId, setParentId] = useState(0);
  const [loading, setLoading] = useState(true);
  const [listData, setListData] = useState([]);
  const [selectedId, setSelectedId] = useState(null);
  const [lastSelected, setLastSelected] = useState(null);
  const [openDropdownId, setOpenDropdownId] = useState(null);
  const [searchedData, setSearchedData] = useState([]);
  const [selectedRecords, setSelectedRecords] = useState([]);
  const [fileStorageModal, setFileStorageModal] = useState(false);
  const [createFolderModalOpen, setCreateFolderModalOpen] = useState(false);
  const [categoryDetails, setCategoryDetails] = useState(null);
  const [fileUploadModalOpen, setFileUploadModalOpen] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [breadcrumbDetails, setBreadcrumbDetails] = useState([...defaultBreadcrumb]);
  const [sortingHeader, setSortingHeader] = useState({ ...MyDocumentSortingDetails });
  const [deleteRecord, setDeleteRecord] = useState([]);
  const [archiveRecord, setArchiveRecord] = useState([]);
  const [moveModalOpen, setMoveModalOpen] = useState(null);
  const [moveSelectedContent, setMoveSelectedContent] = useState([]);
  const [openDuplicateModalOpen, setOpenDuplicateModalOpen] = useState(false);
  const [existingMoveFiles, setExistingMoveFiles] = useState([]);
  const [uploadFolderModal, setUploadFolderModal] = useState(false);
  const [existingModal, setExistingModal] = useState(false);
  const [existingFiles, setExistingFiles] = useState([]);
  const [remainingFiles, setRemainingFiles] = useState([]);
  const [archiveLoading, setArchiveLoading] = useState(false);
  const [isFilePreviewModalOpen, setIsFilePreviewModalOpen] = useState(false);
  const [filePreviewDetails, setFilePreviewDetails] = useState(null);
  const [unsupportedFileModalOpen, setUnsupportedFileModalOpen] = useState(false);
  const [scannedFileDetails, setScannedFileDetails] = useState({ ...scannedFileElem });
  const [renameItemDetails, setRenameItemDetails] = useState({
    isRenameItem: false,
    itemDetails: null,
    listData: []
  });
  const [isGridView, setIsGridView] = useState(
    JSON.parse(localStorage.getItem('wlp-file-management-view-type'))
  );

  useEffect(() => {
    parentIdRef.current = { id: folder_id || null };
    getParentId();
  }, [folder_id]);

  // FIND PARENT ID
  const getParentId = () => {
    if (!parentIdRef.current.id) {
      setBreadcrumbDetails([...defaultBreadcrumb]);
      setParentId(0);
      return 0;
    }
    let encryptId = parentIdRef.current && parentIdRef.current.id.replace(/-/g, '/');
    const decryptedData = decryptReact(encryptId);
    setBreadcrumbDetails(JSON.parse(decryptedData));
    setParentId(JSON.parse(decryptedData)[JSON.parse(decryptedData).length - 1].id);
    return JSON.parse(decryptedData)[JSON.parse(decryptedData).length - 1].id;
  };

  // FETCH LIST
  const fetchDocumentsList = async (isShowLoader = true) => {
    setLoading(isShowLoader);
    const userID = user?.id;
    const parentID = getParentId();
    try {
      const payload = { user_id: userID, parent_id: parentID };
      await dispatch(getFilesData(payload)).unwrap();
      setSelectedRecords([]);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log('error :>> ', error);
    }
  };

  // FETCH STORAGE
  const fetchStorageDetails = async () => {
    try {
      const payload = { user_id: user?.id };
      await dispatch(getStorageDetails(payload)).unwrap();
    } catch (error) {
      console.log('error', error.message);
    }
  };

  useEffect(() => {
    fetchDocumentsList();
  }, [location.pathname, fileUpload]);

  useEffect(() => {
    const newData = fileManagementSortingData(
      'My Documents',
      breadcrumbDetails,
      parentId,
      filesFolders,
      sortingHeader
    );
    documentsRef.current = newData;
    setListData(newData);
  }, [filesFolders, sortingHeader]);

  // GET CATEGORIES
  const getAllCategories = async () => {
    try {
      await dispatch(getMasterCategory()).unwrap();
    } catch (error) {
      console.log('error', error.message);
    }
  };

  useEffect(() => {
    if (masterCategories?.length === 0) {
      getAllCategories();
    }
  }, [masterCategories]);

  useEffect(() => {
    let bDetails = breadcrumbDetails[breadcrumbDetails.length - 1];
    if (!!bDetails.row_id && !!bDetails.category_id && masterCategories?.length > 0) {
      let categoryData = masterCategories?.find((val) => val.id === bDetails.category_id);
      setCategoryDetails(categoryData);
    } else {
      setCategoryDetails(null);
    }
  }, [masterCategories, breadcrumbDetails]);

  /* OUT SIDE CLICK HANDLE */
  const handleOutsideClick = () => {
    setSelectedId(null);
    setSelectedRecords([]);
    setOpenDropdownId(null);
    setLastSelected(null);
  };

  useFileManagementClickOutside(layoutRowRef, fmListRef, documentsRef, handleOutsideClick);

  // DOUBLE CLICK
  const handleDoubleClick = async (data) => {
    if (data.attachment) {
      const fileType = getFileType(data.name);
      setFilePreviewDetails(data);
      if (data.size <= process.env.REACT_APP_FILE_PREVIEW_SIZE_ALLOWED && fileType !== 'unknown') {
        setIsFilePreviewModalOpen(true);
      } else {
        setUnsupportedFileModalOpen(true);
      }
    } else {
      let check_double_space = data.category_id === 20 ? true : false;
      if (data.category_id === 12 && !!data.category_primary_id) {
        let payload = {
          user_id: user?.id,
          record_id: data?.category_primary_id,
          cat_id: data.category_id
        };
        try {
          let res = await dispatch(checkLifeInsuranceData(payload)).unwrap();
          check_double_space = res?.data?.loan_amount;
        } catch (error) {
          console.log('err');
        }
      }

      let docs = [];
      docs = [
        ...breadcrumbDetails,
        {
          title: data.name,
          id: data.id,
          row_id: !!data.category_primary_id ? data.category_primary_id : null,
          category_id: !!data.category_id ? data.category_id : null,
          is_folder_create_allowed: data.is_folder_create_allowed || false,
          is_files_upload_allowed: data.is_files_upload_allowed || false,
          is_folder_upload_allowed: data.is_folder_upload_allowed || false,
          check_double_space
        }
      ];
      const docsJSON = JSON.stringify(docs);
      let encryptData = encryptReact(docsJSON);
      let encryptId = encryptData.replace(/\//g, '-');
      setBreadcrumbDetails(docs);
      setSelectedRecords([]);
      navigate(`/file-management/${encryptId}`);
    }
  };

  // SEARCH
  const handleSearch = useDebounce(async (value) => {
    try {
      const payload = {
        user_id: user?.id,
        keyword: value
      };
      const response = await dispatch(searchMedia(payload)).unwrap();
      setSearchedData(response?.data || []);
    } catch (error) {
      console.log('error', error?.message);
    }
  }, 500);

  // DELETE
  const handleDelete = async () => {
    setDeleteLoading(true);
    setDeleteModalOpen(false);
    let IdsArray = [];
    deleteRecord.map((val) => IdsArray.push(val.id));
    try {
      let payload = {
        ids: [...IdsArray]
      };
      const response = await dispatch(deleteFileToTrash(payload)).unwrap();
      if (response.status) {
        toast.success(deleteMsg(deleteRecord));
        fetchDocumentsList();
      }
      setDeleteRecord([]);
      setDeleteLoading(false);
    } catch (error) {
      setDeleteModalOpen(false);
      setDeleteRecord([]);
      setDeleteLoading(false);
      console.log('error', error.message);
    }
  };

  // ARCHIVE
  const handleArchive = async (value) => {
    setArchiveRecord(value);
    setArchiveLoading(true);
    let IdsArray = [];
    value.map((val) => IdsArray.push(val.id));
    try {
      const payload = {
        ids: [...IdsArray]
      };
      const response = await dispatch(moveToArchive(payload)).unwrap();
      if (response.status) {
        toast.success('Archived successfully.');
        fetchDocumentsList();
      }
      setArchiveLoading(false);
      setArchiveRecord([]);
    } catch (error) {
      setArchiveLoading(false);
      setArchiveRecord([]);
      console.log('error', error.message);
    }
  };

  // MOVE
  const handleMove = (data) => {
    setMoveModalOpen(true);
    setMoveSelectedContent([...data]);
  };

  // Collect all file names including those in progress
  const collectAllFileNames = () => {
    const allFileNames = new Set([
      ...existingFiles.map((file) => file.name.trim()),
      ...listData.map((file) => file.name.trim()),
      ...remainingFiles.map((file) => file.name.trim())
    ]);

    progressUploadDetails.forEach((detail) => {
      if (detail.parentId === parentId) {
        detail.files.forEach((file) => allFileNames.add(file.trim()));
      }
    });

    return allFileNames;
  };

  // Handle file upload and update state
  const handleFileUpload = async (files, uId, parentId) => {
    const totalFileSize = files.reduce((acc, file) => acc + file.size, 0);
    const fileNames = files.map((file) => file.name);

    dispatch(
      setProgressUploadDetails({
        id: uId,
        files: fileNames,
        parentId,
        size: totalFileSize,
        isDelete: false
      })
    );

    const { successfulUploads } = await handleS3Upload(files, user?.id, dispatch);
    setLoading(true);
    const data = successfulUploads.map((val) => ({
      name: val.file.name,
      size: val.file.size,
      original_file_name: val.file.name,
      attachment: val.result.Key
    }));

    const payload = {
      parent_id: parentId?.toString(),
      data,
      user_id: user?.id?.toString()
    };

    const res = await dispatch(uploadOtherFilesFolder({ payload, endPoint: 'files' })).unwrap();

    dispatch(setProgressUploadDetails({ id: uId, isDelete: true }));

    if (res && res.code === 200) {
      toast.success('Your file has been uploaded successfully.');
      fetchDocumentsList();
      fetchStorageDetails();
      setExistingFiles([]);
      setRemainingFiles([]);
      setCategoryDetails(null);
    } else {
      toast.error(res.message);
      setExistingFiles([]);
      setRemainingFiles([]);
      setCategoryDetails(null);
    }
  };

  // SUBMIT EXISTING
  const submitExisting = async (selectedUpload) => {
    const uId = Date.now();
    const allFileNames = collectAllFileNames();

    if (selectedUpload === 'keep_both') {
      setExistingModal(false);

      const newFiles = existingFiles.map((items) => {
        const fileName = items.name.trim();
        const extension = fileName.split(/\.(?=[^\.]+$)/)[1];
        const baseName = fileName.split(/\.(?=[^\.]+$)/)[0];
        const updatedName = updateFileName(baseName, allFileNames, extension);

        allFileNames.add(`${updatedName}.${extension}`);

        return new File([items], `${updatedName}.${extension}`, {
          type: items.type,
          lastModified: items.lastModified
        });
      });

      await handleFileUpload([...newFiles, ...remainingFiles], uId, parentId);
    } else {
      const deleteFileIds = listData
        .filter((docItem) =>
          existingFiles.some((existingItem) => docItem.name === existingItem.name)
        )
        .map((val) => val.id);

      if (deleteFileIds.length) {
        const deleteResponse = await dispatch(
          deleteTrashItem({
            user_id: user.id,
            type: 'trash',
            Ids: deleteFileIds
          })
        ).unwrap();

        if (deleteResponse) {
          fetchDocumentsList(false);
          setExistingModal(false);

          await handleFileUpload([...remainingFiles, ...existingFiles], uId, parentId);
        }
      }
    }
  };

  // SCAN
  const handleScanDownloadFile = async (ids = []) => {
    try {
      const response = await dispatch(scanDownloadFile({ ids })).unwrap();
      return response?.data;
    } catch (error) {
      console.error('Error during scan download:', error);
    }
  };

  // DOWNLOAD & PROTECTED DOWNLOAD
  const handleFileDownload = async (data, is_protected = false) => {
    if (storageDetails?.used_space > storageDetails?.total_space) {
      setFileStorageModal(true);
      return;
    }

    const IdsArray = data.map((val) => val.id);
    const scanResponse = await handleScanDownloadFile(IdsArray);

    if (scanResponse?.pending_files?.length > 0) {
      toast.info('Scanning process in progress. Please try again later.');
      setSelectedRecords([]);
      return;
    }

    if (data.length === 1 && data[0].attachment && !is_protected) {
      if (scanResponse?.infected_files?.length > 0 || scanResponse?.not_scan_files?.length > 0) {
        setScannedFileDetails({
          modalOpen: true,
          isBigFile: scanResponse.not_scan_files?.length > 0,
          details: data
        });
      } else {
        fileDownload(data[0]);
      }
      return;
    }

    const infectedFileNames = scanResponse?.infected_files?.map((obj) => obj.name).join(', ') || '';
    const isSingleAttachment = data.length === 1 && !data[0].attachment;

    if (scanResponse?.infected_files?.length > 0) {
      if (isSingleAttachment) {
        toast.error(`${infectedFileNames} This file cannot be downloaded.`);
        setSelectedRecords([]);
        return;
      } else {
        toast.error(`${infectedFileNames} These files cannot be downloaded.`);
        IdsArray = IdsArray.filter(
          (id) => !scanResponse.infected_files.some((obj) => obj.id === id)
        );
      }
    }

    if (IdsArray.length === 0) {
      setSelectedRecords([]);
      return;
    }

    try {
      const payload = { user_id: user.id, id: IdsArray, is_protected: is_protected };
      await dispatch(downloadItem(payload)).unwrap();
    } catch (error) {
      console.error('Error during file download:', error.message);
    }
  };

  // RENAME
  const handleRenameItem = (data) => {
    setCreateFolderModalOpen(true);
    setRenameItemDetails({
      ...renameItemDetails,
      isRenameItem: true,
      itemDetails: { ...data },
      listData: [...listData]
    });
  };

  // SEARCH
  const onSearchItemClick = async (data) => {
    if (data.attachment) {
      const fileType = getFileType(data.name);
      setFilePreviewDetails(data);
      if (data.size <= process.env.REACT_APP_FILE_PREVIEW_SIZE_ALLOWED && fileType !== 'unknown') {
        setIsFilePreviewModalOpen(true);
      } else {
        setUnsupportedFileModalOpen(true);
      }
    } else {
      if (data.path.length > 0) {
        let check_double_space = data?.category_id === 20 ? true : false;
        if (data?.category_id === 12 && !!data?.category_primary_id) {
          let payload = {
            user_id: user?.id,
            record_id: data?.category_primary_id,
            cat_id: data.category_id
          };
          try {
            let res = await dispatch(checkLifeInsuranceData(payload)).unwrap();
            check_double_space = res?.data?.loan_amount;
          } catch (error) {
            console.log('err');
          }
        }
        let pathData = data?.path?.map((val, index, array) => {
          return {
            ...val,
            row_id: !!val.category_primary_id ? val.category_primary_id : null,
            category_id: !!val.category_id ? val.category_id : null,
            check_double_space: index === array.length - 1 ? check_double_space : false
          };
        });
        let encryptData = encryptReact(JSON.stringify([...defaultBreadcrumb, ...pathData]));
        let encryptId = encryptData.replace(/\//g, '-');
        navigate(`/file-management/${encryptId}`);
      } else {
        navigate('file-management');
      }
    }
  };

  const [isLoader] = useOutletContext();

  return (
    <React.Fragment>
      {isLoader ? (
        <SkeletonFileManagement
          isGridView={isGridView}
          breadcrumbDetails={breadcrumbDetails}
          moduleName="My Documents"
        />
      ) : (
        <section className="individual-main-section main-section file-management-section">
          <Container fluid>
            <Row>
              <Col lg={12}>
                <FileManagementHeaderSection
                  moduleName="My Documents"
                  breadcrumbDetails={breadcrumbDetails}
                  isGridView={isGridView}
                  setIsGridView={setIsGridView}
                  setSelectedId={setSelectedId}
                  setSelectedRecords={setSelectedRecords}
                  handleUploadFolder={() => setUploadFolderModal(true)}
                  layoutRowRef={layoutRowRef}
                  selectedRecords={selectedRecords}
                  handleSearch={handleSearch}
                  setSearchedData={setSearchedData}
                  searchedData={searchedData}
                  handleCreateFolder={() => setCreateFolderModalOpen(true)}
                  handleFileUpload={() => setFileUploadModalOpen(true)}
                  handleArchive={() => handleArchive(selectedRecords)}
                  handleMove={() => handleMove(selectedRecords)}
                  handleFileDownload={() => handleFileDownload(selectedRecords, false)}
                  handleProtectedFileDownload={() => handleFileDownload(selectedRecords, true)}
                  onSearchItemClick={(data) => onSearchItemClick(data)}
                  handleDelete={() => {
                    setDeleteRecord(selectedRecords);
                    setDeleteModalOpen(true);
                  }}
                  handleDropboxClick={() => {
                    dropBoxUploadRef.current.click();
                  }}
                  handleGoogleDriveClick={() => {
                    googleDriveUploadRef.current.click();
                  }}
                />
              </Col>
              {loading ? (
                <CapsyncLoader />
              ) : (
                <Col lg={12}>
                  {isGridView ? (
                    <GridView
                      moduleName="My Documents"
                      data={listData}
                      parentId={parentId}
                      selectedRecords={selectedRecords}
                      setSelectedRecords={setSelectedRecords}
                      openDropdownId={openDropdownId}
                      setOpenDropdownId={setOpenDropdownId}
                      breadcrumbDetails={breadcrumbDetails}
                      lastSelected={lastSelected}
                      setLastSelected={setLastSelected}
                      isGridView={isGridView}
                      sortingHeader={sortingHeader}
                      setSortingHeader={setSortingHeader}
                      handleDoubleClick={handleDoubleClick}
                      handleDelete={(value) => {
                        setDeleteRecord(value);
                        setDeleteModalOpen(true);
                      }}
                      handleMove={(data) => handleMove(data)}
                      handleArchive={(data) => handleArchive(data)}
                      handleFileDownload={(data) => handleFileDownload(data, false)}
                      handleProtectedFileDownload={(data) => handleFileDownload(data, true)}
                      handleRenameItem={(data) => handleRenameItem(data)}
                    />
                  ) : (
                    <ListView
                      moduleName="My Documents"
                      data={listData}
                      parentId={parentId}
                      selectedRecords={selectedRecords}
                      breadcrumbDetails={breadcrumbDetails}
                      setSelectedRecords={setSelectedRecords}
                      sortingHeader={sortingHeader}
                      fmListRef={fmListRef}
                      setSortingHeader={setSortingHeader}
                      selectedId={selectedId}
                      setSelectedId={setSelectedId}
                      setLastSelected={setLastSelected}
                      lastSelected={lastSelected}
                      handleMove={(data) => handleMove(data)}
                      handleDoubleClick={handleDoubleClick}
                      handleDelete={(value) => {
                        setDeleteRecord(value);
                        setDeleteModalOpen(true);
                      }}
                      handleArchive={(data) => handleArchive(data)}
                      handleFileDownload={(data) => handleFileDownload(data, false)}
                      handleProtectedFileDownload={(data) => handleFileDownload(data, true)}
                      handleRenameItem={(data) => handleRenameItem(data)}
                    />
                  )}
                </Col>
              )}
              <Col lg={12}>
                {!loading && (
                  <UpgradeStorage
                    setFileStorageModal={setFileStorageModal}
                    fileStorageModal={fileStorageModal}
                  />
                )}
              </Col>
            </Row>
          </Container>
        </section>
      )}

      {createFolderModalOpen && (
        <CreateFolder
          showModal={createFolderModalOpen}
          renameItemDetails={renameItemDetails}
          parentId={parentId}
          onCancelClick={(isApiCall = true) => {
            isApiCall && fetchDocumentsList();
            setCreateFolderModalOpen(false);
            setRenameItemDetails({
              isRenameItem: false,
              itemDetails: null,
              listData: []
            });
          }}
        />
      )}
      {deleteModalOpen && (
        <FileManagementDeleteModal
          moduleName="My Documents"
          showModal={deleteModalOpen}
          deleteRecord={deleteRecord}
          onCancelClick={() => {
            setDeleteModalOpen(false);
            setDeleteRecord([]);
          }}
          onConfirmDelete={() => handleDelete()}
        />
      )}
      {moveModalOpen && (
        <FileFolderMoveModal
          category={breadcrumbDetails}
          moveModalOpen={moveModalOpen}
          fileParentId={parentId}
          moveSelectedContent={moveSelectedContent}
          setOpenDuplicateModalOpen={setOpenDuplicateModalOpen}
          setExistingMoveFiles={setExistingMoveFiles}
          closeModal={(callListApi = true) => {
            callListApi && fetchDocumentsList();
            setMoveModalOpen(false);
            setMoveSelectedContent([]);
          }}
        />
      )}
      {openDuplicateModalOpen && (
        <MoveAlertModal
          showModal={openDuplicateModalOpen}
          existingMoveFiles={existingMoveFiles}
          onCancelClick={() => {
            setOpenDuplicateModalOpen(false);
            setExistingMoveFiles([]);
          }}
        />
      )}
      {uploadFolderModal && (
        <FolderUpload
          closeModal={(callListApi = true) => {
            callListApi && fetchDocumentsList();
            callListApi && fetchStorageDetails();
          }}
          setUploadFolderModal={setUploadFolderModal}
          setFileStorageModal={setFileStorageModal}
          uploadFolderModal={uploadFolderModal}
          parentId={parentId}
          setFetchLoading={setLoading}
        />
      )}
      {existingModal && (
        <ReplaceFileModal
          showModal={existingModal}
          onSubmitCLick={submitExisting}
          filesDetails={existingFiles}
          closeModal={() => {
            setExistingModal(false);
            setRemainingFiles([]);
            setExistingFiles([]);
          }}
        />
      )}
      {fileUploadModalOpen && (
        <FileUpload
          showModal={fileUploadModalOpen}
          parentId={parentId}
          selectedFiles={selectedFiles}
          setSelectedFiles={setSelectedFiles}
          currentFolderDetails={breadcrumbDetails[breadcrumbDetails.length - 1]}
          categoryDetails={categoryDetails}
          setFileStorageModal={setFileStorageModal}
          setFetchLoading={setLoading}
          setRemainingFiles={setRemainingFiles}
          setExistingFiles={setExistingFiles}
          setExistingModal={setExistingModal}
          onCancelClick={(isApiCall = true) => {
            isApiCall && fetchDocumentsList();
            isApiCall && fetchStorageDetails();
            setFileUploadModalOpen(false);
          }}
        />
      )}
      {openDuplicateModalOpen && (
        <MoveAlertModal
          openDuplicateModalOpen={openDuplicateModalOpen}
          existingMoveFiles={existingMoveFiles}
          closeModal={() => setOpenDuplicateModalOpen(false)}
        />
      )}
      {(archiveLoading || deleteLoading) && (
        <LoaderModal
          loading={archiveLoading || deleteLoading}
          message={loadingMessage(
            archiveLoading ? archiveRecord : deleteRecord,
            archiveLoading ? 'archive' : 'delete'
          )}
        />
      )}
      <DropBoxUpload
        dropBoxUploadRef={dropBoxUploadRef}
        parentId={parentId}
        currentFolderDetails={breadcrumbDetails[breadcrumbDetails.length - 1]}
        setFileStorageModal={setFileStorageModal}
      />
      <GoogleDriveUpload
        googleDriveUploadRef={googleDriveUploadRef}
        parentId={parentId}
        currentFolderDetails={breadcrumbDetails[breadcrumbDetails.length - 1]}
      />
      {isFilePreviewModalOpen && (
        <FilePreview
          showModal={isFilePreviewModalOpen}
          fileDetails={filePreviewDetails}
          onCancelClick={() => {
            setIsFilePreviewModalOpen(false);
            setFilePreviewDetails(null);
          }}
        />
      )}
      {unsupportedFileModalOpen && (
        <UnsupportedFileModal
          showModal={unsupportedFileModalOpen}
          onCancelClick={() => {
            setUnsupportedFileModalOpen(false);
            setFilePreviewDetails(null);
          }}
          onDownloadClick={() => {
            fileDownload(filePreviewDetails);
            setUnsupportedFileModalOpen(false);
            setFilePreviewDetails(null);
          }}
        />
      )}
      {scannedFileDetails?.modalOpen && (
        <DownloadConfirmationModal
          scannedFileDetails={scannedFileDetails}
          onCancelClick={() => {
            setSelectedRecords([]);
            setScannedFileDetails({ ...scannedFileElem });
          }}
        />
      )}
    </React.Fragment>
  );
};

export default MyDocument;
