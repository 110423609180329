import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Form, Row } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { useFormik } from 'formik';
import PropTypes from 'prop-types';
import moment from 'moment';

// COMPONENT IMPORTS
import Modal from '../../../components/Modal';
import { TOAST_MESSAGE } from '../../../constants/message';
import InputBox from '../../../components/FormFields/InputBox';
import validationObj from '../../../validations/validationSchema';
import TextAreaBox from '../../../components/FormFields/TextAreaBox';
import { handleOwnershipValue } from '../../../config/handleOwnership';
import DatePickerBox from '../../../components/FormFields/DatePickerBox';
import InputNumberField from '../../../components/FormFields/InputNumberField';
import SaveORCancelButtons from '../../../components/Buttons/SaveORCancelButtons';
import CategoryFileUpload from '../../../components/FormFields/CategoryFileUpload';
import { preventNegativeNumbers, preventSpecialCharacters } from '../../../utils/common';

// HOOKS IMPORTS
import useShareValue from '../../../utils/Hooks/useShareValue';

// API
import {
  categoryAddRecords,
  categoryUpdateRecords,
  getAssetsCategory,
  useCategoryDetail
} from '../../../slices/categorySlice';
import { getAssetsDashboard, getMainDashboard } from '../../../slices/dashboardSlice';
import { useProgressUploadDetails } from '../../../slices/commonSlice';
import { useStorageDetails } from '../../../slices/fileManagementSlice';

/* ============================== NON RETIREMENT BROKERAGE ============================== */
const NonRetirementBrokerage = ({
  isOpen = false,
  handleCancel,
  handleCategoryFormSubmit,
  record
}) => {
  const dispatch = useDispatch();

  const { user } = useSelector((state) => state.auth);
  const categories = useCategoryDetail();
  const categoryId = categories?.category_id;
  const progressUploadDetails = useProgressUploadDetails();
  const storageDetails = useStorageDetails();

  const [isOpenModal, setIsOpenModal] = useState(isOpen);
  const [loading, setLoading] = useState(false);
  const [initialValues, setInitialValues] = useState({
    user_id: user?.id,
    institution: '',
    balance: '',
    ownership: '',
    date_updated: moment().format(),
    notes: '',
    attachments: []
  });
  useEffect(() => {
    if (record) {
      setInitialValues({
        user_id: record?.user_id,
        institution: record?.institution,
        balance: record?.balance && record?.balance.toLocaleString('en-US'),
        ownership: record?.ownership && record?.ownership.toLocaleString('en-US'),
        date_updated: new Date(moment(record?.date_updated).format('MM/DD/YYYY')),
        notes: record?.notes == null ? '' : record?.notes
      });
    }
  }, [record]);
  const {
    values,
    handleSubmit,
    handleChange,
    setFieldValue,
    errors,
    touched,
    setFieldError,
    resetForm
  } = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: validationObj.assetCashOnHandValidationSchema,
    onSubmit: async (values) => {
      const formData = new FormData();
      const uploadedData = storageDetails?.used_space;
      const inProgressData = progressUploadDetails.length
        ? progressUploadDetails.reduce((acc, item) => acc + item.size, 0)
        : 0;

      const { attachments, ...dataObj } = values;
      !record && attachments.forEach((file) => formData.append('attachments', file));
      formData.append('data_obj', JSON.stringify(dataObj));
      formData.append('usedSpace', uploadedData + inProgressData);

      const data = {
        category: 13,
        mainCategory: 'assets',
        formData: formData
      };
      setLoading(true);
      if (!record) {
        dispatch(categoryAddRecords(data))
          .unwrap()
          .then(async (response) => {
            if (response.code === 500) {
              setFieldError('attachments', response?.message);
            }
            if (response.code === 200) {
              setIsOpenModal(false);
              handleCategoryFormSubmit();
              dispatch(getMainDashboard(user?.id));
              const payload = { categoryId: categoryId, userId: user?.id };
              categories.parent === 'assets' &&
                (await dispatch(getAssetsCategory(payload)).unwrap());
              await dispatch(getAssetsDashboard({ userId: user?.id, orderId: 0 })).unwrap();
            }
          })
          .catch((err) => {})
          .finally(() => {
            setLoading(false);
          });
      } else {
        dispatch(
          categoryUpdateRecords({
            category: 13,
            mainCategory: 'assets',
            rowId: record && record?.id,
            values
          })
        )
          .unwrap()
          .then(async (response) => {
            if (response.code === 200) {
              setIsOpenModal(false);
              handleCategoryFormSubmit();
              toast.success(TOAST_MESSAGE.RECORD_UPDATE_SUCCESS);
              const payload = { categoryId: categoryId, userId: user?.id };
              categories.parent === 'assets' &&
                (await dispatch(getAssetsCategory(payload)).unwrap());
              await dispatch(getMainDashboard(user?.id)).unwrap();
            }
          })
          .catch((err) => {})
          .finally(() => {
            setLoading(false);
          });
      }
    }
  });

  useShareValue({ values, setFieldValue, fieldName: 'share_value' });
  const body = (
    <Form onSubmit={handleSubmit} encType="multipart/form-data">
      <Row>
        <Col lg={12} sm={12}>
          <InputBox
            label={'Institution'}
            name={'institution'}
            values={values}
            onChange={handleChange}
            errors={errors}
            touched={touched}
            type={'text'}
            onKeyDown={preventSpecialCharacters}
            disabled={record && record.is_manual === false}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={6}>
          <InputNumberField
            label="Balance"
            name="balance"
            values={values}
            errors={errors}
            handleChange={handleChange}
            touched={touched}
            customActiveClass="dollar-symbol text-md-end form-control"
            onPaste={preventNegativeNumbers}
            thousandSeparator={true}
          />
        </Col>
        <Col xs={12} md={6}>
          <InputNumberField
            label="Ownership (%)"
            name="ownership"
            values={values}
            errors={errors}
            handleChange={handleChange}
            touched={touched}
            onPaste={preventNegativeNumbers}
            thousandSeparator={true}
            maxLength={3}
            onKeyDown={handleOwnershipValue}
            customActiveClass={'percentage-symbol'}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={6}>
          <InputNumberField
            label="Share Value"
            name="share_value"
            values={values}
            errors={errors}
            handleChange={handleChange}
            touched={touched}
            customActiveClass="dollar-symbol text-md-end form-control"
            onPaste={preventNegativeNumbers}
            thousandSeparator={true}
            disabled={true}
          />
        </Col>
        <Col xs={12} md={6}>
          <DatePickerBox
            label={'Date Updated'}
            errors={errors}
            name={'date_updated'}
            values={values}
            setFieldValue={setFieldValue}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={12}>
          <TextAreaBox
            handleChange={handleChange}
            name={'notes'}
            label={'Notes'}
            values={values}
            errors={errors}
            onChange={handleChange}
            placeholder={'Optional'}
          />
        </Col>
      </Row>

      {!record && (
        <CategoryFileUpload
          name={'attachments'}
          values={values}
          errors={errors}
          setFieldError={setFieldError}
          setFieldValue={setFieldValue}
        />
      )}

      <SaveORCancelButtons handleCancel={handleCancel} resetForm={resetForm} loading={loading} />
    </Form>
  );

  return (
    <Modal
      show={isOpenModal}
      isCloseButton={false}
      title={'Non-Retirement Brokerage Accounts (Stocks & Bonds)'}
      body={body}
    />
  );
};

// PROPS TYPE
NonRetirementBrokerage.propTypes = {
  isOpen: PropTypes.bool,
  handleCancel: PropTypes.func,
  handleCategoryFormSubmit: PropTypes.func,
  record: PropTypes.object
};

export default NonRetirementBrokerage;
