export const VIEW_ONLY_PATHS = [
  'dashboard',
  'assets',
  'liabilities',
  'file-management',
  'archive',
  'trash',
  'settings'
];

export const SIDE_BAR_ITEMS_STANDARD = [
  {
    key: 1,
    title: 'Dashboard',
    path: '/dashboard',
    iconOutlined: 'dashboard-outlined',
    iconFilled: 'dashboard-filled',
    hasNotificationCount: false,
    keyName: 'dashboard'
  },
  {
    key: 2,
    title: 'Assets',
    path: '/assets',
    iconOutlined: 'asset-outlined',
    iconFilled: 'asset-filled',
    hasNotificationCount: false,
    keyName: 'assets'
  },
  {
    key: 3,
    title: 'Liabilities',
    path: '/liabilities',
    iconOutlined: 'liability-outlined',
    iconFilled: 'liability-filled',
    hasNotificationCount: false,
    keyName: 'liabilities'
  },
  {
    key: 4,
    title: 'File Management',
    path: '/file-management',
    iconOutlined: 'files-management-outlined',
    iconFilled: 'files-management-filled',
    hasNotificationCount: false,
    keyName: 'file-management',
    subItems: [
      {
        key: 5,
        title: 'My Documents',
        path: '/file-management',
        iconOutlined: 'folder-icon-outlined',
        iconFilled: 'folder-icon-filled',
        hasNotificationCount: false,
        keyName: 'file-management'
      },
      {
        key: 6,
        title: 'Archive',
        path: '/archive',
        iconOutlined: 'archive-outlined',
        iconFilled: 'archive-filled',
        hasNotificationCount: false,
        keyName: 'archive'
      },
      {
        key: 7,
        title: 'Trash',
        path: '/trash',
        iconOutlined: 'delete-outlined',
        iconFilled: 'delete-filled',
        hasNotificationCount: false,
        keyName: 'trash'
      }
    ]
  },
  {
    key: 8,
    title: 'Credit Manager',
    path: '/credit-manager',
    iconOutlined: 'credit-outlined',
    iconFilled: 'credit-filled',
    hasNotificationCount: false,
    keyName: 'credit-manager'
  },
  {
    key: 9,
    title: 'Identity Protect',
    path: '/identity-protect',
    iconOutlined: 'protect-outlined',
    iconFilled: 'protect-filled',
    hasNotificationCount: false,
    keyName: 'identity-protect'
  },
  {
    key: 10,
    title: 'Accreditation',
    path: '/verifyinvestor',
    iconOutlined: 'accreditation-outlined',
    iconFilled: 'accreditation-filled',
    hasNotificationCount: false,
    keyName: 'verifyinvestor'
  },
  {
    key: 11,
    title: 'Collaboration',
    path: '/collaboration',
    iconOutlined: 'user-multiple-outlined',
    iconFilled: 'user-multiple-filled',
    hasNotificationCount: false,
    keyName: 'collaboration'
  },
  {
    key: 12,
    title: 'Request',
    path: '/request',
    // key: 7,
    // title: 'request',
    // path: '/dashboard/request',
    iconOutlined: 'message-request-outlined',
    iconFilled: 'message-request-filled',
    hasNotificationCount: true,
    keyName: 'request'
  }
];

export const SIDE_BAR_ITEMS_VIEW_ONLY = [
  {
    key: 1,
    title: 'Dashboard',
    path: '/dashboard',
    iconOutlined: 'dashboard-outlined',
    iconFilled: 'dashboard-filled',
    hasNotificationCount: false,
    keyName: 'dashboard'
  },
  {
    key: 2,
    title: 'Assets',
    path: '/assets',
    iconOutlined: 'asset-outlined',
    iconFilled: 'asset-filled',
    hasNotificationCount: false,
    keyName: 'assets'
  },
  {
    key: 3,
    title: 'Liabilities',
    path: '/liabilities',
    iconOutlined: 'liability-outlined',
    iconFilled: 'liability-filled',
    hasNotificationCount: false,
    keyName: 'liabilities'
  },
  {
    key: 4,
    title: 'File Management',
    path: '/file-management',
    iconOutlined: 'files-management-outlined',
    iconFilled: 'files-management-filled',
    hasNotificationCount: false,
    keyName: 'file-management',
    subItems: [
      {
        key: 5,
        title: 'My Documents',
        path: '/file-management',
        iconOutlined: 'folder-icon-outlined',
        iconFilled: 'folder-icon-filled',
        hasNotificationCount: false,
        keyName: 'file-management'
      },
      {
        key: 6,
        title: 'Archive',
        path: '/archive',
        iconOutlined: 'archive-outlined',
        iconFilled: 'archive-filled',
        hasNotificationCount: false,
        keyName: 'archive'
      },
      {
        key: 7,
        title: 'Trash',
        path: '/trash',
        iconOutlined: 'delete-outlined',
        iconFilled: 'delete-filled',
        hasNotificationCount: false,
        keyName: 'trash'
      }
    ]
  }
];

export const SIDE_BAR_ITEMS_STANDARD_IND_TO_IND = [
  {
    key: 1,
    title: 'Dashboard',
    path: '/dashboard',
    iconOutlined: 'dashboard-outlined',
    iconFilled: 'dashboard-filled',
    hasNotificationCount: false,
    keyName: 'dashboard'
  },
  {
    key: 2,
    title: 'Assets',
    path: '/assets',
    iconOutlined: 'asset-outlined',
    iconFilled: 'asset-filled',
    hasNotificationCount: false,
    keyName: 'assets'
  },
  {
    key: 3,
    title: 'Liabilities',
    path: '/liabilities',
    iconOutlined: 'liability-outlined',
    iconFilled: 'liability-filled',
    hasNotificationCount: false,
    keyName: 'liabilities'
  },
  {
    key: 4,
    title: 'File Management',
    path: '/file-management',
    iconOutlined: 'files-management-outlined',
    iconFilled: 'files-management-filled',
    hasNotificationCount: false,
    keyName: 'file-management',
    subItems: [
      {
        key: 5,
        title: 'My Documents',
        path: '/file-management',
        iconOutlined: 'folder-icon-outlined',
        iconFilled: 'folder-icon-filled',
        hasNotificationCount: false,
        keyName: 'file-management'
      },
      {
        key: 6,
        title: 'Archive',
        path: '/archive',
        iconOutlined: 'archive-outlined',
        iconFilled: 'archive-filled',
        hasNotificationCount: false,
        keyName: 'archive'
      },
      {
        key: 7,
        title: 'Trash',
        path: '/trash',
        iconOutlined: 'delete-outlined',
        iconFilled: 'delete-filled',
        hasNotificationCount: false,
        keyName: 'trash'
      }
    ]
  },
  {
    key: 8,
    title: 'Credit Manager',
    path: '/credit-manager',
    iconOutlined: 'credit-outlined',
    iconFilled: 'credit-filled',
    hasNotificationCount: false,
    keyName: 'credit-manager'
  },
  {
    key: 9,
    title: 'Identity Protect',
    path: '/identity-protect',
    iconOutlined: 'protect-outlined',
    iconFilled: 'protect-filled',
    hasNotificationCount: false,
    keyName: 'identity-protect'
  },
  {
    key: 10,
    title: 'Accreditation',
    path: '/verifyinvestor',
    iconOutlined: 'accreditation-outlined',
    iconFilled: 'accreditation-filled',
    hasNotificationCount: false,
    keyName: 'verifyinvestor'
  },
  {
    key: 12,
    title: 'Request',
    path: '/request',
    // key: 7,
    // title: 'request',
    // path: '/dashboard/request',
    iconOutlined: 'message-request-outlined',
    iconFilled: 'message-request-filled',
    hasNotificationCount: true,
    keyName: 'request'
  }
];

// // Sidebar items
// export const SIDE_BAR_ITEMS_STANDARD = [
//   {
//     key: 1,
//     title: 'Dashboard',
//     path: '/dashboard',
//     iconOutlined: 'dashboard-outlined',
//     iconFilled: 'dashboard-filled',
//     hasNotificationCount: false,
//     keyName: 'dashboard'
//   },
//   {
//     key: 2,
//     title: 'Clients',
//     path: '/dashboard/clients',
//     iconOutlined: 'user-multiple-outlined',
//     iconFilled: 'user-multiple-filled',
//     hasNotificationCount: false,
//     keyName: 'clients',
//     subItems: [
//       {
//         key: 3,
//         title: 'Individual',
//         path: '/dashboard/individual',
//         iconOutlined: 'user-single-outlined',
//         iconFilled: 'user-single-filled',
//         hasNotificationCount: false,
//         keyName: 'individual',
//       },
//       {
//         key: 4,
//         title: 'Companies',
//         path: '/dashboard/company',
//         iconOutlined: 'company-outlined',
//         iconFilled: 'company-filled',
//         hasNotificationCount: false,
//         keyName: 'company',
//       }
//     ]
//   },
//   {
//     key: 5,
//     title: 'request',
//     path: '/dashboard/request',
//     iconOutlined: 'message-request-outlined',
//     iconFilled: 'message-request-filled',
//     hasNotificationCount: false,
//     keyName: 'request'
//   }
// ];
