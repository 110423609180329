import React, { useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';

// COMPONENT IMPORTS
import {
  ADD_ACCOUNT_MODALS,
  ASSET_CATEGORY_LISTING,
  CATEGORY,
  LIABILITY_CATEGORY_LISTING
} from '../../../constants/addAccount';
import Modal from '../../../components/Modal';
import CapsyncIcon from '../../../components/CapsyncIcon';
import ChooseCategoryDropdown from '../../../components/ChooseCategoryDropdown';

/* ============================== ADD MANUAL ACCOUNT ============================== */
const AddManualAccountModal = ({
  isOpen = true,
  setAddAccountStep,
  setIsAccountModelOpen,
  isAddAccountFlowClosable
}) => {
  const [isModalOpen, setIsModalOpen] = useState(isOpen);
  const [selectedCategory, setSelectedCategory] = useState(CATEGORY.NONE);

  const handleModalClose = () => {
    setIsModalOpen(false);
    setIsAccountModelOpen(false);
  };

  const body = (
    <div className="text-center">
      <Row>
        <Col lg={12}>
          <span className="cs-regular-h4 cs-neutral-90 text-center">
            You need to enter your transactions manually for this account
          </span>
          <div className="account-popup-setup2 cs-regular-sub-heading-m cs-neutral-90">
            <div
              className={`choose-account-category ${selectedCategory === CATEGORY.NONE && 'selected-category'}`}>
              <h4 className="cs-semi-bold-h4 cs-neutral-100">Choose manual account type</h4>
              <Button
                className={`cs-regular-h5 btn btn-primary
                ${selectedCategory === CATEGORY.ASSETS ? 'cs-btn-primary' : 'cs-btn-secondary'}
              `}
                onClick={() => setSelectedCategory(CATEGORY.ASSETS)}>
                {CATEGORY.ASSETS}
              </Button>
              <div className="cs-divider">
                <span className="cs-regular-body-text-m cs-neutral-50">OR</span>
              </div>
              <Button
                className={`cs-regular-h5 btn btn-primary
                ${selectedCategory === CATEGORY.LIABILITY ? 'cs-btn-primary' : 'cs-btn-secondary'}
              `}
                onClick={() => setSelectedCategory(CATEGORY.LIABILITY)}>
                {CATEGORY.LIABILITY}
              </Button>
            </div>
            {selectedCategory !== CATEGORY.NONE && (
              <div className="choose-category">
                <div className="category-dropdown">
                  <p className="cs-regular-sub-heading-s cs-neutral-100 text-start">
                    {`Which ${selectedCategory === CATEGORY.ASSETS ? 'asset' : 'liability'} class does this account belong to?`}
                  </p>
                  <ChooseCategoryDropdown
                    selectedCategory={selectedCategory}
                    list={
                      selectedCategory === CATEGORY.ASSETS
                        ? ASSET_CATEGORY_LISTING
                        : LIABILITY_CATEGORY_LISTING
                    }
                    setAddAccountStep={setAddAccountStep}
                  />
                </div>
                <div
                  className={`cs-${selectedCategory === CATEGORY.ASSETS ? 'asset' : 'liability'}`}>
                  <div className="card-icon">
                    <span className="cs-icon">
                      <CapsyncIcon
                        title={`cs-${selectedCategory === CATEGORY.ASSETS ? 'asset' : 'liability'}-icon`}
                        size="56"
                      />
                    </span>
                  </div>
                </div>
              </div>
            )}
          </div>
        </Col>
      </Row>
    </div>
  );

  return (
    <Modal
      body={body}
      show={isModalOpen}
      handleClose={handleModalClose}
      title={'Add Account'}
      hasBackButton={true}
      handleBackButtonClick={() => setAddAccountStep(ADD_ACCOUNT_MODALS.MAIN_MODAL)}
      className={'cs-s-modal'}
      isCloseButton={isAddAccountFlowClosable}
    />
  );
};

// PROPS TYPE
AddManualAccountModal.propTypes = {
  isOpen: PropTypes.bool,
  setIsAccountModelOpen: PropTypes.func,
  setAddAccountStep: PropTypes.func.isRequired,
  isAddAccountFlowClosable: PropTypes.bool
};

export default AddManualAccountModal;
