import React, { useEffect, useState } from 'react';
import { Button, Dropdown, Table } from 'react-bootstrap';
import PropTypes from 'prop-types';

// COMPONENT IMPORTS
import { totalBalance } from '../../utils/common';
import Searchbar from '../../components/Searchbar';
import CapsyncIcon from '../../components/CapsyncIcon';
import CategoryTableData from '../../components/CategoryTableData';

// API
import { useCategoryTableDetails, useCategoryDetail } from '../../slices/categorySlice';

const paginationDropdownOptions = [
  {
    label: '10',
    value: 10
  },
  {
    label: '15',
    value: 15
  },
  {
    label: '20',
    value: 20
  },
  {
    label: '25',
    value: 25
  },
  {
    label: '30',
    value: 30
  },
  {
    label: 'All',
    value: 'all'
  }
];

const currentNumberOfRows = (rowStartIdx, rowsPerPage, lengthOfCompanies) => {
  if (rowStartIdx + rowsPerPage <= lengthOfCompanies) {
    return rowsPerPage;
  } else {
    return lengthOfCompanies - rowStartIdx;
  }
};

/* ============================== TABLE ============================== */
const CustomTable = ({
  data = [],
  columns = [],
  isPaginate = false,
  totalRecords = 0,
  onPaginationValueChange,
  className = '',
  hasSearchBar = false,
  search = '',
  setSearch,
  setColumnDetails,
  fromIndividual,
  show = false,
  columnVisible = true,
  CustomHeaderComponent = null,
  autoPageBackOnDelete = false,
  onRowClick,
  sortingColumnFunc,
  transactionTHLabelElement = false,
  statusFilter,
  sort = false
}) => {
  const categories = useCategoryDetail();
  const categoriesData = useCategoryTableDetails();

  const [rowsPerPage, setRowsPerPage] = useState(paginationDropdownOptions[0]);
  const [rowStartIdx, setRowStartIdx] = useState(0);

  const visibleColumns = columns && columns.filter((column) => column.visible);

  useEffect(() => {
    if (fromIndividual) {
      setColumnDetails({
        rowsPerPage: rowsPerPage,
        rowStartIdx: rowStartIdx,
        setRowStartIdx: setRowStartIdx
      });
    }
  }, [rowsPerPage, rowStartIdx]);

  const onHandleChangeRowsPerPageValue = (option) => {
    setRowsPerPage(option);
    setRowStartIdx(0);
    onPaginationValueChange({
      page: 1,
      limit: option.value
    });
  };

  const onHandleChangePage = (index) => {
    setRowStartIdx(index);
    onPaginationValueChange({
      page: Math.floor(index / rowsPerPage.value) + 1,
      limit: rowsPerPage.value
    });
  };

  const handleTableSearch = (searchText) => {
    setSearch(searchText);
    setRowStartIdx(0);
    onPaginationValueChange({
      page: 1,
      limit: rowsPerPage.value
    });
  };

  useEffect(() => {
    if (autoPageBackOnDelete === true && data && data.length === 0 && rowStartIdx !== 0) {
      onHandleChangePage(rowStartIdx - rowsPerPage.value);
    }
  }, [data]);

  const getTotalCount = (category, selectedColumns) => {
    switch (true) {
      case selectedColumns === 'balance_due' &&
        [
          'notespayabletobanksandothers',
          'installmentsauto',
          'installmentsaccount',
          'unpaidtaxes',
          'otherliabilities',
          'accountpayable',
          'mortgagesonrealestate',
          'accountnotesreceivable'
        ].includes(category):
        return totalBalance(categoriesData?.data, 'balance_due');

      case selectedColumns === 'loan_amount' &&
        ['lifeinsurance'].includes(category) &&
        categories?.parent === 'liabilities':
        return totalBalance(categoriesData?.data, 'loan_amount');
      case selectedColumns === 'market_value' &&
        ['automobile', 'personalproperty', 'otherassets'].includes(category):
        return totalBalance(categoriesData?.data, 'market_value');
      case selectedColumns === 'share_value' &&
        ['cashonhands', 'savingaccount', 'nonretirementbrokerage'].includes(category):
        return totalBalance(categoriesData?.data, 'share_value');
      case selectedColumns === 'share_market_value' &&
        ['realestate', 'business'].includes(category):
        return totalBalance(categoriesData?.data, 'share_market_value');
      case selectedColumns === 'total_balance' && ['retirementaccount'].includes(category):
        return totalBalance(categoriesData?.data, 'total_balance');
      case selectedColumns === 'cash_value' && ['lifeinsurance'].includes(category):
        return totalBalance(categoriesData?.data, 'cash_value');
      default:
        return null;
    }
  };

  return (
    <React.Fragment>
      {hasSearchBar && (
        <div className="cs-tabs-flex">
          {CustomHeaderComponent && CustomHeaderComponent}
          <Searchbar placeholder={'Search'} search={search} setSearch={handleTableSearch} />
        </div>
      )}
      {data.length && (visibleColumns.length !== 0 || columns.length !== 0) ? (
        <React.Fragment>
          <div className="cs-table-widget">
            <Table className={`cs-table ${className} ${categories.tableClass}`}>
              <thead className="cs-thead">
                <tr>
                  {(columnVisible === false ? columns : visibleColumns)?.map((column, index) => (
                    <th
                      key={index}
                      width={column?.width}
                      className={column.className}
                      onClick={() => sort && sortingColumnFunc(column)}>
                      {transactionTHLabelElement ? (
                        <div className={`sorting-icon ${column.sortable && 'cursor-pointer'}`}>
                          {column.name}
                          {!search && column.isFilter && column.isFilter === true && statusFilter}
                          {sort && sort.name === column.column_name && (
                            <span className="cs-neutral-100">
                              <CapsyncIcon title="sort-outlined" size="20" />
                            </span>
                          )}
                        </div>
                      ) : (
                        column.label
                      )}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody className="cs-tbody">
                {data.map((row, rowIndex) => (
                  <tr
                    key={rowIndex}
                    onClick={() => onRowClick && onRowClick(rowIndex)}
                    className={onRowClick && 'cs-row-click'}>
                    {(columnVisible === false ? columns : visibleColumns)?.map(
                      (column, colIndex) => (
                        <td
                          key={`${rowIndex}-${colIndex}`}
                          width={column.width}
                          className={`${column.key === 'ownership' || column.key === 'apr_percentage' ? 'text-end' : column.commonVariable === 'commonVariable' ? 'share-value-td' : ''}`}>
                          {column.renderCell ? column.renderCell(row) : null}
                        </td>
                      )
                    )}
                  </tr>
                ))}
                {show === true && (
                  <tr
                    className={
                      categories?.parent === 'assets' ? ' cs-tfoot-assets' : 'cs-tfoot-liability'
                    }>
                    {visibleColumns &&
                      visibleColumns.map((data, index) => {
                        return (
                          <td
                            key={index}
                            className={`${index !== 0 ? 'text-end share-value-th' : ''}`}>
                            {index === 0 && 'Total'}
                            <CategoryTableData
                              type="number"
                              data={getTotalCount(categories?.path, data.key)}
                            />
                          </td>
                        );
                      })}
                  </tr>
                )}
              </tbody>
            </Table>
          </div>
          {isPaginate && (
            <div className="cs-export-row">
              {fromIndividual && (
                <span
                  className="cs-regular-body-text-m export-btn cs-primary cursor-pointer"
                  onClick={() => {
                    // toast.success('Data exported successfully');
                  }}>
                  <CapsyncIcon title="download-filled" size="14" /> Export Data
                </span>
              )}
              <div className="cs-custom-pagination">
                <div className="cs-common-add-dropdown">
                  <span className="cs-light-body-text-m cs-neutral-80">Rows per page:</span>
                  <Dropdown>
                    <Dropdown.Toggle className="cs-light-body-text-m cs-neutral-80">
                      {rowsPerPage.label} {/* Render the label property */}
                      <CapsyncIcon title="chevron-down-outlined" size="12" />
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      {paginationDropdownOptions.map((option) => {
                        const isSelected = option.label === rowsPerPage.label;
                        return (
                          <Dropdown.Item
                            key={option.value}
                            className={isSelected && 'cs-pagination-select'}
                            onClick={() => onHandleChangeRowsPerPageValue(option)}
                            value={option.value}>
                            {option.label}
                          </Dropdown.Item>
                        );
                      })}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
                <div className="cs-light-body-text-m cs-neutral-80">
                  <p>
                    {rowStartIdx + 1}-
                    {rowStartIdx +
                      currentNumberOfRows(rowStartIdx, rowsPerPage.value, totalRecords)}{' '}
                    of {totalRecords}
                  </p>
                </div>
                <div className="cs-pagination-arrow">
                  <Button
                    onClick={() => onHandleChangePage(0)}
                    className="cs-neutral-50"
                    disabled={rowStartIdx === 0}>
                    <CapsyncIcon title="chevron-left-skip-outlined" size="14" />
                  </Button>
                  <Button
                    onClick={() => onHandleChangePage(rowStartIdx - rowsPerPage.value)}
                    disabled={rowStartIdx === 0}
                    className="cs-neutral-50">
                    <CapsyncIcon title="chevron-left-outlined" size="14" />
                  </Button>
                  <Button
                    onClick={() => onHandleChangePage(rowStartIdx + rowsPerPage.value)}
                    disabled={!(rowStartIdx + rowsPerPage.value < totalRecords)}
                    className="cs-neutral-50">
                    <CapsyncIcon title="chevron-right-outlined" size="14" />
                  </Button>
                  <Button
                    onClick={() =>
                      onHandleChangePage(
                        Math.floor((totalRecords - 1) / rowsPerPage.value) * rowsPerPage.value
                      )
                    }
                    disabled={!(rowStartIdx + rowsPerPage.value < totalRecords)}
                    className="cs-neutral-50">
                    <CapsyncIcon title="chevron-right-skip-outlined" size="14" />
                  </Button>
                </div>
              </div>
            </div>
          )}
        </React.Fragment>
      ) : (
        <span className="cs-common-no-record cs-light-body-text-l cs-neutral-100">
          There are no records to display
        </span>
      )}
    </React.Fragment>
  );
};

// PROPS TYPE
CustomTable.propTypes = {
  data: PropTypes.array,
  columns: PropTypes.array,
  isPaginate: PropTypes.bool,
  totalRecords: PropTypes.number,
  onPaginationValueChange: PropTypes.func,
  className: PropTypes.string,
  hasSearchBar: PropTypes.bool,
  search: PropTypes.string,
  setSearch: PropTypes.func,
  setColumnDetails: PropTypes.func,
  fromIndividual: PropTypes.bool,
  show: PropTypes.bool,
  columnVisible: PropTypes.bool,
  CustomHeaderComponent: PropTypes.any,
  autoPageBackOnDelete: PropTypes.bool,
  onRowClick: PropTypes.func,
  sortingColumnFunc: PropTypes.func,
  transactionTHLabelElement: PropTypes.bool,
  statusFilter: PropTypes.any,
  sort: PropTypes.any
};

export default CustomTable;
