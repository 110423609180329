import * as yup from 'yup';
import { isValidPhoneNumber } from 'react-phone-number-input';

// COMPONENT IMPORTS
import { VALIDATION_MESSAGE } from '../constants/message';
import { uniValidation } from '../constants/uniValidation';
import { usAreaCodes } from '../constants/system';

export const emailField = ({ isRequired } = { isRequired: true }) => {
  let obj = yup
    .string()
    .matches(/^\S*$/, VALIDATION_MESSAGE.WHITESPACE)
    .test(
      'no-more-than-30-before-at',
      VALIDATION_MESSAGE.REQUIRED_THIRTY_CHARACTERS_BEFORE_AT_SIGN,
      (value) => (value ? value.split('@')[0].length <= 30 : true)
    )
    .test(
      'at-least-1-character-before-at',
      VALIDATION_MESSAGE.REQUIRED_ONE_CHARACTERS_BEFORE_AT_SIGN,
      (value) => (value ? value.split('@')[0].length > 0 : true)
    )
    .test('custom-email-format', VALIDATION_MESSAGE.INVALID_EMAIL, (value) => {
      return value ? /^[a-z0-9.]+([_+][a-z0-9.]+)*@[a-z0-9.-]+\.[a-z]{2,}$/i.test(value) : true;
    })
    .email(VALIDATION_MESSAGE.INVALID_EMAIL);

  if (!isRequired) {
    obj = obj.nullable();
  } else {
    obj = obj.required(VALIDATION_MESSAGE.EMAIL_REQUIRED);
  }

  return obj;
};

export const phoneField = ({ isRequired } = { isRequired: true }) => {
  let obj = yup.string().test('is-valid-phone', 'Enter a valid phone number', async (value) => {
    if (!value) return true;
    return (
      isValidPhoneNumber(value) && usAreaCodes.includes(Number(value.split('+1')[1].slice(0, 3)))
    );
  });

  if (!isRequired) {
    obj = obj.nullable();
  } else {
    obj = obj.required(VALIDATION_MESSAGE.PHONE_REQUIRED);
  }
  return obj;
};

export const balanceField = () =>
  yup
    .string()
    .required(uniValidation.assets_and_liabilities.balance.require)
    .test(
      'non-zero',
      uniValidation.assets_and_liabilities.balance.nonzero,
      (value) => Number(value.replace(/,/g, '')) !== 0
    );

export const passwordField = () =>
  yup
    .string()
    .required(VALIDATION_MESSAGE.PASSWORD_REQUIRED)
    .matches(/^\S*$/, VALIDATION_MESSAGE.NO_SPACE)
    .matches(/[0-9]/, VALIDATION_MESSAGE.USE_NUMBER)
    .matches(/[a-z]/, VALIDATION_MESSAGE.USER_LOWERCASE)
    .matches(/[A-Z]/, VALIDATION_MESSAGE.USER_UPPERCASE)
    .matches(/^(?=.*(_|[^\w])).+$/, VALIDATION_MESSAGE.USE_SYMBOL)
    .min(8, VALIDATION_MESSAGE.REQUIRED_EIGHT_OR_MORE_CHARACTERS);

export const firstNameField = () =>
  yup
    .string()
    .strict()
    .matches(/^\S(?:.*\S)?$/, VALIDATION_MESSAGE.NO_SPACE)
    .min(2, VALIDATION_MESSAGE.REQUIRED_TWO_CHARACTERS)
    .max(20, VALIDATION_MESSAGE.REQUIRED_TWENTY_CHARACTERS)
    .required(VALIDATION_MESSAGE.FIRST_NAME_REQUIRED);

export const lastNameField = () =>
  yup
    .string()
    .strict()
    .matches(/^\S(?:.*\S)?$/, VALIDATION_MESSAGE.NO_SPACE)
    .min(2, VALIDATION_MESSAGE.REQUIRED_TWO_CHARACTERS)
    .max(20, VALIDATION_MESSAGE.REQUIRED_TWENTY_CHARACTERS)
    .required(VALIDATION_MESSAGE.LAST_NAME_REQUIRED);

export const companyNameField = () =>
  yup
    .string()
    .strict()
    .matches(/^\S(?:.*\S)?$/, VALIDATION_MESSAGE.NO_SPACE)
    .min(2, VALIDATION_MESSAGE.REQUIRED_TWO_CHARACTERS)
    .max(30, VALIDATION_MESSAGE.REQUIRED_THIRTY_CHARACTERS)
    .required(VALIDATION_MESSAGE.COMPANY_NAME_REQUIRED);

export const crdNumberField = () =>
  yup
    .string()
    .strict()
    .matches(/^\S(?:.*\S)?$/, VALIDATION_MESSAGE.NO_SPACE)
    .min(2, VALIDATION_MESSAGE.REQUIRED_TWO_CHARACTERS)
    .max(20, VALIDATION_MESSAGE.REQUIRED_TWENTY_CHARACTERS)
    .required(VALIDATION_MESSAGE.CRD_NUMBER_REQUIRED);

export const confirmPasswordField = () =>
  yup
    .string()
    .matches(/^\S*$/, VALIDATION_MESSAGE.NO_SPACE)
    .required(VALIDATION_MESSAGE.CONFIRM_PASSWORD_REQUIRED)
    .oneOf([yup.ref('password'), null], VALIDATION_MESSAGE.PASSWORD_NOT_MATCH)
    .max(20, VALIDATION_MESSAGE.PASSWORD_NOT_MORE_TWENTY_CHARACTERS);

export const streetNameField = () =>
  yup
    .string()
    .required(uniValidation.street_name.require)
    .matches(/(?=.*?\d)/, uniValidation.street_name.valid)
    .matches(/[A-Za-z]{2}/, uniValidation.street_name.valid);

export const cityField = () =>
  yup.string().required(uniValidation.city.require).min(2, uniValidation.city.valid);

export const zipCodeField = () =>
  yup
    .string()
    .required(uniValidation.zip_code.require)
    .matches(/^[0-9]+$/, uniValidation.zip_code.valid)
    .min(5, uniValidation.zip_code.valid)
    .max(5, uniValidation.zip_code.valid);

export const textAreaField = (fieldName) =>
  yup
    .string()
    .required('Please enter' + fieldName)
    .matches(/^\S.*$/, VALIDATION_MESSAGE.WHITESPACE)
    .min(50, VALIDATION_MESSAGE.MUST_BE_FIFTY_CHARACTERS);

export const dateField = () => yup.date().required(VALIDATION_MESSAGE.DATE_REQUIRED);

export const otpValidationSchema = () =>
  yup.object({
    otp: yup
      .string()
      .required(VALIDATION_MESSAGE.OTP_IS_REQUIRED)
      .min(6, VALIDATION_MESSAGE.OPT_MIN_LENGTH_SIX)
  });

export const descriptionField = () =>
  yup
    .string()
    .max(100, uniValidation.assets_and_liabilities.description.max)
    .required(uniValidation.assets_and_liabilities.description.require);

export const institutionField = () =>
  yup
    .string()
    .required(uniValidation.assets_and_liabilities.institution.require)
    .max(30, uniValidation.assets_and_liabilities.institution.max);

export const ownershipField = () =>
  yup.number().required(uniValidation.assets_and_liabilities.ownership.require);

export const propertyNameField = () =>
  yup.string().max(100, 'Maximum 100 characters allowed').required('Enter property type');

export const marketValueField = () =>
  yup
    .string()
    .required(uniValidation.assets_and_liabilities.market_value.require)
    .max(14, 'Market value must be less than 11 characters long')
    .test(
      'non-zero',
      uniValidation.assets_and_liabilities.market_value.nonzero,
      (value) => Number(value.replace(/,/g, '')) !== 0
    )
    .test(
      'Is positive?',
      uniValidation.assets_and_liabilities.market_value.nonzero,
      (value) => value && !(Number(value.replace(/,/g, '')) < 0)
    );

export const cashValueField = () =>
  yup
    .string()
    .required('Enter cash value')
    .test(
      'non-zero',
      uniValidation.assets_and_liabilities.cash_value.nonzero,
      (value) => Number(value.replace(/,/g, '')) !== 0
    )
    .test(
      'Is positive?',
      uniValidation.assets_and_liabilities.cash_value.nonzero,
      (value) => value && !(Number(value.replace(/,/g, '')) < 0)
    );

export const beneficiaryField = () =>
  yup
    .string()
    .required(uniValidation.assets_and_liabilities.beneficiary.require)
    .max(40, uniValidation.assets_and_liabilities.beneficiary.max);

export const balanceDueField = () =>
  yup
    .string()
    .test(
      'Is positive?',
      uniValidation.assets_and_liabilities.balance_due.positive,
      (value) => value && !(Number(value.replace(/,/g, '')) < 0)
    )
    .test(
      'non-zero',
      'Balance due must be greater than zero',
      (value) => Number(value.replace(/,/g, '')) !== 0
    )
    .test('checkDecimal', uniValidation.assets_and_liabilities.balance_due.max, (value) =>
      /^(\d{1,3}(,\d{3})*(\.\d{0,2})?)?$/.test(value)
    )
    .required(uniValidation.assets_and_liabilities.balance_due.require);

export const creditorNameFieldManual = () =>
  yup.string().when('is_manual', {
    is: (is_manual) => !!is_manual,
    then: (schema) =>
      schema.required('Enter name of creditor').max(40, 'Maximum 40 characters allowed'),
    otherwise: (schema) => schema
  });

export const loanOnBalanceField = () =>
  yup
    .string()
    .test(
      'loan_on_balance',
      'Loan balance must be lower than the account balance',
      (value, context) => {
        if (value === null || value === undefined || value === '') {
          return true;
        }
        return (
          context.parent.balance &&
          context.parent.loan_on_balance &&
          Number(context.parent.balance.replace(/,/g, '')) > Number(value.replace(/,/g, ''))
        );
      }
    );

export const totalField = () =>
  yup
    .string()
    .test('non-zero', 'Total Balance must be greater than zero', (value) => Number(value) !== 0);

export const entityField = () =>
  yup
    .string()
    .max(100, uniValidation.assets_and_liabilities.entity_name.max)
    .required(uniValidation.assets_and_liabilities.entity_name.require);

export const industryField = () =>
  yup.string().max(40, uniValidation.assets_and_liabilities.industry.max);

export const loanTypeField = () =>
  yup
    .string()
    .matches(/^\S.*$/, 'Whitespace is not allowed')
    .max(40, uniValidation.assets_and_liabilities.loan_type.max);

export const loanPeriodField = () =>
  yup
    .number()
    .moreThan(0, uniValidation.assets_and_liabilities.loan_period.nonzero)
    .test(
      'LoanPeriodInYear',
      uniValidation.assets_and_liabilities.loan_period.years,
      (value, context) =>
        context.parent.frequency === 'Yearly' && value ? Number(value) <= 40 : true
    )
    .test(
      'LoanPeriodInMonth',
      uniValidation.assets_and_liabilities.loan_period.months,
      (value, context) =>
        context.parent.frequency === 'Monthly' && value ? Number(value) <= 480 : true
    )
    .required(uniValidation.assets_and_liabilities.loan_period.require);

export const aprPercentageField = () =>
  yup
    .number()
    .typeError('Enter a valid number')

    .test('is-valid-decimal', 'Maximum two digits are allowed after the decimal point', (value) => {
      if (value === undefined || value === null) {
        return true;
      }
      return /^\d+(\.\d{1,2})?$/.test(value);
    });

export const minimumPayment = () =>
  yup
    .string()
    .test('is-valid-decimal', 'Maximum two digits are allowed after the decimal point', (value) => {
      if (value === undefined || value === null) {
        return true;
      }
      return /^(\d{1,3}(,\d{3})*(\.\d{0,2})?)?$/.test(value);
    });

export const dateLoanField = () =>
  yup.date().when('is_manual', {
    is: (is_manual) => !!is_manual,
    then: (schema) => schema.required('Please select Loan date'),
    otherwise: (schema) => schema
  });

export const annualRateField = () =>
  yup
    .number()
    .typeError(uniValidation.assets_and_liabilities.annual_rate.number)
    .required(uniValidation.assets_and_liabilities.annual_rate.require);

export const paymentField = () =>
  yup
    .string()
    .test('non-zero', 'payment must be greater than zero', (value) => parseFloat(value) !== 0);

export const collateralField = () =>
  yup
    .string()
    .matches(/^\S.*$/, 'Whitespace is not allowed')
    .max(40, 'Maximum 40 characters allowed');

export const loanAmountFieldManual = () =>
  yup
    .string()
    .when('is_manual', {
      is: (is_manual) => !!is_manual,
      then: (schema) => schema.required('Enter loan amount')
    })
    .test(
      'Is positive?',
      'Loan amount must be greater than zero',
      (value) => value && !(Number(value.replace(/,/g, '')) < 0)
    )
    .test(
      'non-zero',
      'Loan amount must be greater than zero',
      (value) => Number(value.replace(/,/g, '')) !== 0
    );

export const balanceDueFieldManual = () =>
  yup
    .string()
    .when('is_manual', {
      is: (is_manual) => !!is_manual,
      then: (schema) => schema.required('Enter balance due'),
      otherwise: (schema) => schema
    })
    .test(
      'Is positive?',
      'Balance due must be greater than zero',
      (value) => value && !(Number(value.replace(/,/g, '')) < 0)
    )
    // .test(
    //   'checkDecimal',
    //   'Maximum two digits are allowed after the decimal point',
    //   (value) => /^(\d{1,3}(,\d{3})*(\.\d{0,2})?)?$/.test(value)

    // )
    .test(
      'non-zero',
      'Balance due must be greater than zero',
      (value) => Number(value.replace(/,/g, '')) !== 0
    );

export const loanAmountField = () =>
  yup
    .string()
    .test('is-valid-decimal', 'Maximum two digits are allowed after the decimal point', (value) => {
      if (value === undefined || value === null) {
        return true;
      }
      return /^(\d{1,3}(,\d{3})*(\.\d{0,2})?)?$/.test(value);
    })
    .required('Enter Loan Balance');

// ------------------------------------------------------------

export const institutionNameField = () =>
  yup
    .string()
    .required(uniValidation.assets_and_liabilities.institution.require)
    .max(30, uniValidation.assets_and_liabilities.institution.max);

export const debtorNameField = () =>
  yup
    .string()
    .matches(/^\S.*$/, uniValidation.assets_and_liabilities.property_name.require)
    .max(40, uniValidation.assets_and_liabilities.property_name.max)
    .required(uniValidation.assets_and_liabilities.property_name.require);

export const loanAmountFieldAsset = () =>
  yup
    .string()
    .test('is-valid-decimal', 'Maximum two digits are allowed after the decimal point', (value) => {
      if (value === undefined || value === null) {
        return true;
      }
      return /^(\d{1,3}(,\d{3})*(\.\d{0,2})?)?$/.test(value);
    });

export const legalNameField = () =>
  yup
    .string()
    .required(VALIDATION_MESSAGE.LEGAL_NAME_REQUIRED)
    .matches(/^\S.*$/, VALIDATION_MESSAGE.LEGAL_NAME_REQUIRED);

export const verificationTypeField = () =>
  yup.string().required(VALIDATION_MESSAGE.VERIFICATION_TYPE_REQUIRED);

export const methodNotesField = () => yup.string();

export const uploadDropBoxField2 = () => yup.array();

export const officersInvestmentDealField = () =>
  yup
    .string()
    .required(VALIDATION_MESSAGE.OFFICERS_INVESTMENT_OR_DEAL_IS_REQUIRED)
    .matches(/^\S.*$/, VALIDATION_MESSAGE.OFFICERS_INVESTMENT_OR_DEAL_IS_REQUIRED);

export const uploadDropBoxField = (requiredType, compareWithField, errorMsg, methodID) => {
  if (requiredType === 'oneIsRequired') {
    return yup.array().when(compareWithField, {
      is: (fieldVal) => {
        const finalValue = fieldVal && fieldVal.filter((item) => !item.is_signature_pdf);
        return fieldVal && finalValue.length === 0;
      },
      then: (schema) =>
        schema
          .test('empty-array', errorMsg, (value) => {
            const testValue = value && value.filter((item) => !item.is_signature_pdf);
            if (testValue && testValue.length > 0) {
              return true;
            }
            return false;
          })
          .required(errorMsg),
      otherwise: (schema) => schema.optional()
    });
  } else if (requiredType === 'singleAndRequired') {
    if (methodID === 28) {
      if (compareWithField === 'upload_drop_box_1') {
        return yup.array().when('investor_type', {
          is: (investor_type) => investor_type === 'Individual',
          then: (schema) =>
            schema
              .test('empty-array', errorMsg, (value) => {
                const testValue = value && value.filter((item) => !item.is_signature_pdf);
                if (value && testValue.length > 0) {
                  return true;
                }
                return false;
              })
              .required(errorMsg),
          otherwise: (schema) => schema.optional()
        });
      } else if (compareWithField === 'upload_drop_box_2') {
        return yup.array().when('investor_type', {
          is: (investor_type) => investor_type === 'Company',
          then: (schema) =>
            schema
              .test('empty-array', errorMsg, (value) => {
                const testValue = value && value.filter((item) => !item.is_signature_pdf);
                if (value && testValue.length > 0) {
                  return true;
                }
                return false;
              })
              .required(errorMsg),
          otherwise: (schema) => schema.optional()
        });
      }
    } else if (methodID === 3) {
      return yup.array().when('certify', {
        is: (checked) => !checked,
        then: (schema) =>
          schema
            .test('empty-array', errorMsg, (value) => {
              const testValue = value && value.filter((item) => !item.is_signature_pdf);
              if (value && testValue.length > 0) {
                return true;
              }
              return false;
            })
            .required(errorMsg),
        otherwise: (schema) => schema.optional()
      });
    } else {
      return yup
        .array()
        .test('empty-array', errorMsg, (value) => {
          if (!value || value.length === 0) {
            return false;
          }
          const testValue = value && value.some((item) => !item.is_signature_pdf);
          return testValue;
        })
        .required(errorMsg);
    }
  } else {
    return yup.array().optional();
  }
};

export const pfsLinkField = () => yup.string();

export const investorTypeField = () =>
  yup
    .string()
    .required(VALIDATION_MESSAGE.INVESTOR_TYPE_IS_REQUIRED)
    .test('is-not-empty', VALIDATION_MESSAGE.INVESTOR_TYPE_IS_REQUIRED, (value) => {
      if (value === '' || value === 'Select option') {
        return false;
      }
      return true;
    })
    .required(VALIDATION_MESSAGE.INVESTOR_TYPE_IS_REQUIRED);

export const investorMeetField = () =>
  yup.bool().when('investor_type', {
    is: (fieldVal) => fieldVal === 'Company',
    then: (schema) => schema.required(VALIDATION_MESSAGE.INVESTOR_MEET_IS_REQUIRED),
    otherwise: (schema) => schema.optional()
  });

export const methodCertifyField = (methodID) => {
  if (methodID === 28) {
    return yup.bool().when('investor_type', {
      is: (investor_type) => investor_type === 'Individual',
      then: (schema) =>
        schema
          .test(
            'is_true',
            'Upload a recent credit report, or certify that no credit report is available.',
            (value, context) => {
              if (!value) {
                const uploadBoxVal = context.parent.upload_drop_box_1.filter(
                  (item) => !item.is_signature_pdf
                );
                if (!context.parent.upload_drop_box_1 || uploadBoxVal.length === 0) {
                  return false;
                } else {
                  return true;
                }
              } else {
                return true;
              }
            }
          )
          .required(
            'Upload a recent credit report, or certify that no credit report is available.'
          ),
      otherwise: (schema) => schema.optional()
    });
  } else if (methodID === 33 || methodID === 34) {
    return yup
      .bool()
      .oneOf(
        [true],
        'Please check the box below to make the representation that the investor meets the requirements indicated'
      );
  } else {
    return yup.bool().when('upload_drop_box_1', {
      is: (fieldVal) => {
        const finalValue = fieldVal && fieldVal.filter((item) => !item.is_signature_pdf);
        return fieldVal && finalValue.length === 0;
      },
      then: (schema) =>
        schema.oneOf(
          [true],
          'Upload a recent credit report, or certify that no credit report is available.'
        ),
      otherwise: (schema) => schema.optional()
    });
  }
};

export const methodCompanyCheckboxField = (type) => {
  if (type === 'individual') {
    return yup.bool().when('investor_type', {
      is: (investor_type) => investor_type === 'Company',
      then: (schema) =>
        schema
          .test(
            'is_true',
            'Please check the box below to make the representation that the investor meets the requirements indicated.',
            (value) => {
              if (!value) {
                return false;
              } else {
                return true;
              }
            }
          )
          .required(
            'Please check the box below to make the representation that the investor meets the requirements indicated.'
          ),
      otherwise: (schema) => schema.optional()
    });
  } else if (type === 'liability') {
    return yup.bool().when('investor_type', {
      is: (investor_type) => investor_type === 'Company',
      then: (schema) =>
        schema
          .test(
            'is_true',
            'Check the box to indicate that you have disclosed all outstanding liabilities of the entity.',
            (value) => {
              if (!value) {
                return false;
              } else {
                return true;
              }
            }
          )
          .required(
            'Check the box to indicate that you have disclosed all outstanding liabilities of the entity.'
          ),
      otherwise: (schema) => schema.optional()
    });
  } else if (type === 'terms') {
    return yup.bool().when('investor_type', {
      is: (investor_type) => investor_type === 'Company',
      then: (schema) =>
        schema
          .test(
            'is_true',
            'Please check the box below to make the representation that the investor meets the requirements indicated.',
            (value) => {
              if (!value) {
                return false;
              } else {
                return true;
              }
            }
          )
          .required(
            'Please check the box below to make the representation that the investor meets the requirements indicated.'
          ),
      otherwise: (schema) => schema.optional()
    });
  } else {
    return yup.bool().optional();
  }
};

export const methodCRDNumberField = () =>
  yup
    .string()
    .test('len', 'Enter a valid CRD number', (val) => val.length === 7)
    .required(VALIDATION_MESSAGE.CRD_NUMBER_REQUIRED);

export const attachmentDocumentsField = () => yup.array().optional();

export const attachmentsField = () => yup.array().optional();

export const nameField = () => yup.string().optional();

export const ackNameField = () =>
  yup
    .string()
    .trim()
    .required(
      'Enter the investment name or deal name of the company of which you are a knowledgeable employee.'
    );

export const firstTermField = () => yup.bool().optional();

export const secondTermField = () =>
  yup.bool().oneOf([true], VALIDATION_MESSAGE.SECOND_TERM_REQUIRED);

export const incomeAmountField = () => yup.number().optional();

export const fileCurrPassField = () => yup.string().required(VALIDATION_MESSAGE.PASSWORD_REQUIRED);
// .matches(/^\S*$/, VALIDATION_MESSAGE.INCORRECT_PASSWORD)
// .matches(/[0-9]/, VALIDATION_MESSAGE.INCORRECT_PASSWORD)
// .matches(/[a-z]/, VALIDATION_MESSAGE.INCORRECT_PASSWORD)
// .matches(/[A-Z]/, VALIDATION_MESSAGE.INCORRECT_PASSWORD)
// .matches(/^(?=.*(_|[^\w])).+$/,VALIDATION_MESSAGE.INCORRECT_PASSWORD)
// .min(8,VALIDATION_MESSAGE.REQUIRED_EIGHT_OR_MORE_CHARACTERS)
// .max(20, VALIDATION_MESSAGE.PASSWORD_NOT_MORE_TWENTY_CHARACTERS)

export const fileNewPassField = () =>
  yup
    .string()
    .required(VALIDATION_MESSAGE.PASSWORD_REQUIRED)
    .matches(/^\S*$/, VALIDATION_MESSAGE.VALID_PASSWORD)
    .matches(/[0-9]/, VALIDATION_MESSAGE.VALID_PASSWORD)
    .matches(/[a-z]/, VALIDATION_MESSAGE.VALID_PASSWORD)
    .matches(/[A-Z]/, VALIDATION_MESSAGE.VALID_PASSWORD)
    .matches(/[^A-Za-z0-9]/, VALIDATION_MESSAGE.VALID_PASSWORD)
    .matches(/^(?=.*(_|[^\w])).+$/, VALIDATION_MESSAGE.VALID_PASSWORD)
    .notOneOf([yup.ref('current_password')], VALIDATION_MESSAGE.NEW_PASSWORD_MATCH)
    .min(8, VALIDATION_MESSAGE.VALID_PASSWORD)
    .max(20, VALIDATION_MESSAGE.VALID_PASSWORD);

export const fileConfirmPassField = () =>
  yup
    .string()
    .matches(/^\S*$/, VALIDATION_MESSAGE.VALID_PASSWORD)
    .required(VALIDATION_MESSAGE.CONFIRM_PASSWORD_REQUIRED)
    .oneOf([yup.ref('new_password'), null], VALIDATION_MESSAGE.PASSWORD_NOT_MATCH)
    .min(8, VALIDATION_MESSAGE.VALID_PASSWORD)
    .max(20, VALIDATION_MESSAGE.VALID_PASSWORD);

export const folderNameField = () =>
  yup
    .string()
    .trim()
    .required(VALIDATION_MESSAGE.NAME_REQUIRED)
    .test('is-not-only-spaces', uniValidation.file_name.require, (value) => {
      return value.trim() !== '';
    })
    // -------------------- specifically for validating special characters -------------------- //
    // .test('special-character-not-allowed', uniValidation.file_name.allowedFormatMsg, (value) => {
    //   const forbiddenRegex = /^[^\\\x80-\xff{}^%`'"<>\[\]~#|]+$/;
    //   return forbiddenRegex.test(value);
    // })
    // ------------------------------------------------------------------- //
    .max(230, 'Name must be 230 characters maximum');
