import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';

// COMPONENT IMPORTS
import Modal from '../../../components/Modal';
import CapsyncIcon from '../../../components/CapsyncIcon';

/* ============================== ACCOUNT LINKED ============================== */
const AccountLinked = ({ isOpen = false, handleNotNow, handleLinkAccountClick }) => {
  const body = (
    <div className="succes-smodal-body cs-regular-sub-heading-m">
      <div className={`account-success-img`}>
        <div className="card-icon">
          <span className="cs-icon cs-success">
            <CapsyncIcon title={`success-validation`} size="82" />
          </span>
        </div>
      </div>
      <p className="text-center cs-light-body-text-m cs-neutral-90">
        You've successfully linked your account. Link another account to your portfolio?
      </p>
      <div className="cs-modal-btn cs-center-btn cs-modal-action">
        <Button className="cs-btn-secondary md-btn cs-regular-h5 " onClick={handleNotNow}>
          Not now
        </Button>
        <Button className="cs-btn-primary md-btn cs-regular-h5" onClick={handleLinkAccountClick}>
          Link new account
        </Button>
      </div>
    </div>
  );
  return (
    <Modal
      show={isOpen}
      body={body}
      title={'Account(s) successfully linked'}
      isCloseButton={false}
      className={'cs-md-modal modal-cs-md-modal'}
    />
  );
};

// PROPS TYPE
AccountLinked.propTypes = {
  isOpen: PropTypes.bool,
  handleNotNow: PropTypes.func,
  handleLinkAccountClick: PropTypes.func
};

export default AccountLinked;
