import HelloSign from 'hellosign-embedded';
import { toast } from 'react-toastify';

// COMPONENT IMPORTS
import { initialValues } from './SubMethodsInitialValues';
import { VALIDATION_MESSAGE } from '../../../constants/message';

// API
import {
  deleteMedia,
  deletePFS,
  getMedia,
  getSignedPFSLink,
  handleModal,
  legalNameCheck,
  newSubmitRequest,
  setAttachments,
  setLoader,
  setPFSLink,
  signatureRequest,
  uploadMediaInLocal
} from '../../../slices/accreditationSlice';

export const handleAccreditationModals = (dispatch, key, value) => {
  dispatch(handleModal({ [key]: value }));
};

export const handleFileSubmit = async (
  values,
  formik,
  setOpenFileUploadPopUp,
  dispatch,
  pfsLink,
  methodFormik,
  dropboxID
) => {
  let status = false;

  if (methodFormik.values[`upload_drop_box_${dropboxID}`].length > 0) {
    status = true;
  }

  await dispatch(setLoader({ ['uploadFileModalLoader']: true }));
  if (values['attachments'].length > 0) {
    const formData = new FormData();
    for (let i = 0; i < values['attachments'].length; i++) {
      formData.append('attachments', values['attachments'][i]);
    }
    formData.append('data_obj', JSON.stringify(values['data_obj']));
    formData.append('users_data', JSON.stringify(values['users_data']));
    await dispatch(uploadMediaInLocal(formData))
      .unwrap()
      .then((resp) => {
        if (resp.code === 200) {
          getMediaList(dispatch, values.users_data.unique_id, methodFormik);
          dispatch(
            setPFSLink({ link: pfsLink.link, dropBoxID: values.users_data.upload_drop_box_id })
          );
          setOpenFileUploadPopUp(false);
          formik.resetForm(initialValues('fileUpload').initialValuesObj);
        } else {
          toast.error(resp.message);
        }
      })
      .catch((err) => {
        console.log('err', err);
      });
  } else if (status) {
    const formData = new FormData();
    formData.append('data_obj', JSON.stringify(values['data_obj']));
    formData.append('users_data', JSON.stringify(values['users_data']));
    await dispatch(uploadMediaInLocal(formData))
      .unwrap()
      .then((resp) => {
        if (resp.code === 200) {
          getMediaList(dispatch, values.users_data.unique_id, methodFormik);
          dispatch(
            setPFSLink({ link: pfsLink.link, dropBoxID: values.users_data.upload_drop_box_id })
          );
          setOpenFileUploadPopUp(false);
          formik.resetForm(initialValues('fileUpload').initialValuesObj);
        } else {
          toast.error(resp.message);
        }
      })
      .catch((err) => {
        console.log('err', err);
      });
  } else {
    formik.setFieldError('attachments', VALIDATION_MESSAGE.AT_LEAST_ONE_ATTACHMENTS_IS_REQUIRED);
  }
  await dispatch(setLoader({ ['uploadFileModalLoader']: false }));
};

export const handleDeleteMedia = (id, dispatch, userid, formik, dropBoxID, rowID) => {
  dispatch(setLoader({ ['deleteMediaLoader' + id]: true }));
  dispatch(deleteMedia({ id: id, user_id: userid }))
    .unwrap()
    .then((resp) => {
      if (resp.code === 200) {
        if (dropBoxID === 1) {
          const firstMedia = formik.values['upload_drop_box_1'];
          const pfsData = firstMedia.findIndex(
            (item) => item.is_signature_pdf === true && item.id === id
          );
          if (pfsData > -1) {
            dispatch(setPFSLink({ link: '', dropBoxID: 0 }));
            toast.success('Your Personal Financial Statement has been removed successfully.');
          } else {
            toast.success(resp.message);
          }
        } else if (dropBoxID === 2) {
          const secondMedia = formik.values['upload_drop_box_2'];
          const pfsData = secondMedia.findIndex(
            (item) => item.is_signature_pdf === true && item.id === id
          );
          if (pfsData > -1) {
            dispatch(setPFSLink({ link: '', dropBoxID: 0 }));
            toast.success('Your Personal Financial Statement has been removed successfully.');
          } else {
            toast.success(resp.message);
          }
        } else if (dropBoxID === 3) {
          const thirdMedia = formik.values['upload_drop_box_3'];
          const pfsData = thirdMedia.findIndex(
            (item) => item.is_signature_pdf === true && item.id === id
          );
          if (pfsData > -1) {
            dispatch(setPFSLink({ link: '', dropBoxID: 0 }));
            toast.success('Your Personal Financial Statement has been removed successfully.');
          } else {
            toast.success(resp.message);
          }
        }
        getMediaList(dispatch, rowID, formik);
      }
      dispatch(setLoader({ ['deleteMediaLoader' + id]: false }));
    })
    .catch((err) => {
      console.log('err', err);
      dispatch(setLoader({ ['deleteMediaLoader' + id]: false }));
    });
};

export const handleOpenSignModal = (dispatch, user, rowID, navigate) => {
  dispatch(setLoader({ pfsLoader: true }));
  const reqData = {
    email: user.email,
    user_id: user.id,
    unique_id: rowID
  };
  dispatch(signatureRequest(reqData))
    .unwrap()
    .then((response) => {
      if (response.code === 200) {
        dispatch(setLoader({ pfsLoader: false }));
        const signer2Url = response.data.embedded_url;
        if (signer2Url) {
          const client = new HelloSign();
          if (process.env.REACT_APP_MODE === 'PROD') {
            client.open(signer2Url, {
              clientId: process.env.REACT_APP_HELLO_SIGN_CLIENT_ID
            });
          } else {
            client.open(signer2Url, {
              clientId: process.env.REACT_APP_HELLO_SIGN_CLIENT_ID,
              skipDomainVerification: true
            });
          }
          client.on('sign', async (event) => {
            dispatch(
              getSignedPFSLink({
                signature_request_id: response.data.sign_request_id,
                is_accreditation: true
              })
            ).unwrap();
          });
        } else {
          toast.success('Your signature request has been sent successfully.');
          navigate('/verifyinvestor');
        }
      } else {
        dispatch(setLoader({ pfsLoader: false }));
      }
      handleAccreditationModals(dispatch, 'pfsLink', false);
    })
    .catch((err) => {
      dispatch(setLoader({ pfsLoader: false }));
      handleAccreditationModals(dispatch, 'pfsLink', false);
    });
};

export const deletePFSData = (dispatch, id, methodFormik) => {
  dispatch(setLoader({ deletePFSLoader: true }));
  dispatch(deletePFS({ unique_id: id }))
    .unwrap()
    .then((resp) => {
      if (resp.code === 200) {
        dispatch(setPFSLink({ link: '', dropBoxID: 0 }));
        toast.success('Your Personal Financial Statement has been removed successfully.');
        getMediaList(dispatch, id, methodFormik);
      } else {
        toast.error(resp.message);
      }
      dispatch(setLoader({ deletePFSLoader: true }));
    })
    .catch((err) => {
      console.log('err', err);
      dispatch(setLoader({ deletePFSLoader: true }));
    });
};

export const submitForReviewBtnClick = async (
  dispatch,
  values,
  rowID,
  methodID,
  setErrors,
  netWorth,
  setShowStripeForm,
  paymentDataFromListAPI,
  navigate
) => {
  if (methodID === 3 || methodID === 19) {
    if (!(netWorth >= 1000000)) {
      setErrors((errData) => ({
        ...errData,
        netWorthError: 'Net worth for this verification purpose should be more than $1,000,000 USD.'
      }));
      await dispatch(setLoader({ submitBtn: false }));
      return;
    }
  } else if (methodID === 28) {
    if (!(netWorth >= 2100000)) {
      setErrors((errData) => ({
        ...errData,
        netWorthError:
          'Your net worth for this verification purposes should exceed $2,100,000 USD, which is the required amount to meet the qualified client threshold.'
      }));
      await dispatch(setLoader({ submitBtn: false }));
      return;
    }
  }
  const legalnameReqData = {
    legal_name: values['legal_name'],
    notes_value: values['notes_value'],
    unique_id: rowID
  };
  await dispatch(legalNameCheck(legalnameReqData))
    .unwrap()
    .then((resp) => {
      if (resp.code === 200) {
        // if (paymentDataFromListAPI.transactionID !== '' && !paymentDataFromListAPI.requestSent) {
        const submitReqData = {
          unique_id: rowID
        };
        dispatch(newSubmitRequest(submitReqData))
          .unwrap()
          .then((response) => {
            if (response.code === 200) {
              setErrors({});
              toast.success(response.message);
              dispatch(setLoader({ submitBtn: false }));
              navigate("/verifyinvestor");
            } else {
              toast.error(response.message);
              dispatch(setLoader({ submitBtn: false }));
            }
          })
          .catch((err) => {
            console.log('err', err);
            dispatch(setLoader({ submitBtn: false }));
          });
        // }
        //   else {
        //   setShowStripeForm(true);
        // }
      } else {
        toast.error(resp.message);
        dispatch(setLoader({ submitBtn: false }));
      }
    })
    .catch((err) => {
      console.log('err', err);
      dispatch(setLoader({ submitBtn: false }));
    });

};

export const getMediaList = async (dispatch, rowID, formik) => {
  await dispatch(getMedia({ unique_id: rowID }))
    .unwrap()
    .then(async (resp) => {
      if (resp.code === 200) {
        dispatch(setAttachments(resp.data));
        const firstIDData = resp.data.filter((d) => d.upload_drop_box_id === 1);
        const secondIDData = resp.data.filter((d) => d.upload_drop_box_id === 2);
        const thirdIDData = resp.data.filter((d) => d.upload_drop_box_id === 3);
        await formik.setFieldValue('upload_drop_box_1', firstIDData);
        await formik.setFieldValue('upload_drop_box_2', secondIDData);
        await formik.setFieldValue('upload_drop_box_3', thirdIDData);
        const pfsSignedData = resp.data.filter((d) => d.is_signature_pdf) || [];
        if (pfsSignedData.length > 0) {
          dispatch(setPFSLink({ dropBoxID: pfsSignedData[0].upload_drop_box_id }));
        }
      } else {
        console.log('resp', resp);
      }
    })
    .catch((err) => {
      console.log('err', err);
    });
};
