import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Table } from 'react-bootstrap';

// COMPONENT IMPORTS
import CapsyncIcon from '../../components/CapsyncIcon';
import CategoryTableData from '../../components/CategoryTableData';
import { setAddressString, totalBalance } from '../../utils/common';

/* ============================== SHARE PFS CATEGORY TABLE ============================== */
const SharePFSCategoryTable = ({ data = [], className = '', columns = [], parent, category }) => {
  const getTotalCount = (selectedColumns) => {
    switch (true) {
      case selectedColumns === 'balance_due' && category.key !== 'asset_automobiles':
        return totalBalance(data, 'balance_due');
      case selectedColumns === 'loan_amount' && category.key === 'liability_life_insurances':
        return totalBalance(data, 'loan_amount');
      case selectedColumns === 'market_value' && category.key !== 'asset_real_estates':
        return totalBalance(data, 'market_value');
      case selectedColumns === 'share_value':
        return totalBalance(data, 'share_value');
      case selectedColumns === 'share_market_value':
        return totalBalance(data, 'share_market_value');
      case selectedColumns === 'total_balance':
        return totalBalance(data, 'total_balance');
      case selectedColumns === 'cash_value':
        return totalBalance(data, 'cash_value');
      default:
        return null;
    }
  };

  const renderCell = (row, key) => {
    switch (key) {
      case 'address':
        return <div>{row[key] && setAddressString(JSON.parse(row[key]))}</div>;
      case 'date_updated':
        return <div className="common-tooltip-margin">{moment(row[key]).format('MM/DD/YYYY')}</div>;
      case 'maturity_date':
        return <div className="common-tooltip-margin">{moment(row[key]).format('MM/DD/YYYY')}</div>;
      case 'ownership':
        return <div>{`${row[key]} %`}</div>;
      case 'balance_due':
      case 'payment_monthly':
      case 'balance':
      case 'minimum_payment':
      case 'payment':
      case 'loan_amount':
      case 'share_value':
      case 'loan_on_balance':
      case 'total_balance':
      case 'cash_value':
      case 'share_market_value':
      case 'annual_income':
      case 'market_value':
      case 'mortgage_balance':
        return (
          <div
            className={`${typeof row[key] === 'number' ? 'verified-value-icon' : 'common-tooltip-margin'}`}>
            <div className="dollar-value-gap">
              {typeof row[key] === 'number'
                ? `$ ${row[key].toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`
                : row[key]}
            </div>
          </div>
        );
      default:
        return row[key];
    }
  };
  const filteredColumns = columns.filter(
    (column) => column.title !== 'Actions' && column.key !== 'amortization'
  );
  const columnCount = filteredColumns && filteredColumns.length;
  return (
    <div className="cs-table-widget">
      <Table className={`cs-table cashonhand-table ${className}`}>
        <thead className="cs-thead">
          <tr>
            {columns &&
              columns?.map((column, i) => {
                return column.title !== 'Actions' && column.key !== 'amortization' ? (
                  <th
                    key={i}
                    width={column && column.pfsWidth}
                    className={`${column.pfsWidth == 0 ? 'd-none' : column?.className ? column?.className : ''}`}>
                    {column.title}
                  </th>
                ) : (
                  ''
                );
              })}
            <th>Attachments</th>
          </tr>
        </thead>
        <tbody className="cs-tbody">
          {data?.map((row, i) => (
            <tr key={i}>
              {columns &&
                columns?.map((column, j) => {
                  return column.title !== 'Actions' && column.key !== 'amortization' ? (
                    <td
                      key={i + j}
                      width={column.pfsWidth}
                      className={`${column.pfsWidth == 0 ? 'd-none' : column?.className ? column?.className : ''}`}>
                      {renderCell(row, column.key)}
                    </td>
                  ) : (
                    ''
                  );
                })}
              <td>
                <div className="table-icons-container cs-file-icon">
                  <span className="cs-icon-badge-neutral-20 cs-neutral-80">
                    <CapsyncIcon title="files-outlined" size="18" />
                  </span>
                </div>
              </td>
            </tr>
          ))}
          <tr className={parent === 'assets' ? 'cs-tfoot-assets' : 'cs-tfoot-liability'}>
            <td colSpan={columnCount - 2} className="text-start">
              Total
            </td>
            {filteredColumns.map((data, index) => {
              const total = getTotalCount(data.key);
              return total !== null ? (
                <td key={index} className="text-end">
                  <CategoryTableData type="pfsWorksheet" data={total} />
                </td>
              ) : null;
            })}
            <td colSpan={2}></td>
          </tr>
        </tbody>
      </Table>
    </div>
  );
};

// PROPS TYPE
SharePFSCategoryTable.propTypes = {
  data: PropTypes.array,
  className: PropTypes.string,
  columns: PropTypes.array,
  parent: PropTypes.string,
  category: PropTypes.object
};

export default SharePFSCategoryTable;
