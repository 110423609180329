import * as yup from 'yup';

// COMPONENT IMPORTS
import {
  ackNameField,
  attachmentDocumentsField,
  attachmentsField,
  firstTermField,
  incomeAmountField,
  investorMeetField,
  investorTypeField,
  legalNameField,
  methodCRDNumberField,
  methodCertifyField,
  methodCompanyCheckboxField,
  methodNotesField,
  nameField,
  officersInvestmentDealField,
  pfsLinkField,
  secondTermField,
  uploadDropBoxField
} from '../../../validations';
let validations = {};

/* ============================== SUB METHODS VALIDATIONS ============================== */
export const validationSchema = (methodID) => {
  switch (methodID) {
    case 1:
      validations = yup
        .object()
        .shape(
          {
            legal_name: legalNameField(),
            terms_1: firstTermField(),
            upload_drop_box_1: uploadDropBoxField(
              'oneIsRequired',
              'upload_drop_box_2',
              'Add recent W-2 forms, K-1 forms, 1099 forms, or alternative evidence.'
            ),
            upload_drop_box_2: uploadDropBoxField('oneIsRequired', 'upload_drop_box_1', ''),
            terms_2: secondTermField(),
            income_amount: incomeAmountField(),
            notes: methodNotesField()
          },
          [['upload_drop_box_1', 'upload_drop_box_2']]
        )
        .required();
      break;

    case 2:
      validations = yup
        .object()
        .shape({
          legal_name: legalNameField(),
          upload_drop_box_1: uploadDropBoxField(
            'singleAndRequired',
            'upload_drop_box_1',
            'Upload evidence of position claimed.'
          ),
          officers_investment_or_deal_name: officersInvestmentDealField(),
          notes: methodNotesField()
        })
        .required();
      break;

    case 3:
      validations = yup
        .object()
        .shape(
          {
            legal_name: legalNameField(),
            upload_drop_box_1: uploadDropBoxField(
              'singleAndRequired',
              'upload_drop_box_1',
              'Upload evidence of position claimed.',
              3
            ),
            certify: methodCertifyField(),
            notes: methodNotesField()
          },
          [['upload_drop_box_1', 'certify']]
        )
        .required();
      break;

    case 4:
      validations = yup.object().shape({
        legal_name: legalNameField(),
        upload_drop_box_1: uploadDropBoxField(
          'singleAndRequired',
          'upload_drop_box_1',
          'Select the attached document or upload the required files.'
        ),
        notes: methodNotesField()
      });
      break;

    case 5:
      validations = yup
        .object()
        .shape(
          {
            legal_name: legalNameField(),
            upload_drop_box_1: uploadDropBoxField(
              'oneIsRequired',
              'upload_drop_box_2',
              'Select the attached document or upload the required files.'
            ),
            upload_drop_box_2: uploadDropBoxField('oneIsRequired', 'upload_drop_box_1', ''),
            notes: methodNotesField()
          },
          [['upload_drop_box_1', 'upload_drop_box_2']]
        )
        .required();
      break;

    case 6:
      validations = yup.object().shape({
        legal_name: legalNameField(),
        upload_drop_box_1: uploadDropBoxField(
          'singleAndRequired',
          'upload_drop_box_1',
          'Select the attached document or upload the required files.'
        ),
        notes: methodNotesField()
      });
      break;

    case 7:
      validations = yup
        .object()
        .shape(
          {
            legal_name: legalNameField(),
            upload_drop_box_1: uploadDropBoxField(
              'oneIsRequired',
              'upload_drop_box_2',
              'Select the attached document or upload the required files.'
            ),
            upload_drop_box_2: uploadDropBoxField('oneIsRequired', 'upload_drop_box_1', ''),
            notes: methodNotesField()
          },
          [['upload_drop_box_1', 'upload_drop_box_2']]
        )
        .required();
      break;

    case 8:
      validations = yup
        .object()
        .shape(
          {
            legal_name: legalNameField(),
            upload_drop_box_1: uploadDropBoxField(
              'oneIsRequired',
              'upload_drop_box_2',
              'Select the attached document or upload the required files.'
            ),
            upload_drop_box_2: uploadDropBoxField('oneIsRequired', 'upload_drop_box_1', ''),
            notes: methodNotesField()
          },
          [['upload_drop_box_1', 'upload_drop_box_2']]
        )
        .required();
      break;

    case 9:
      validations = yup
        .object()
        .shape(
          {
            legal_name: legalNameField(),
            upload_drop_box_1: uploadDropBoxField(
              'singleAndRequired',
              'upload_drop_box_1',
              "Upload officer's certificate."
            ),
            upload_drop_box_2: uploadDropBoxField(
              'oneIsRequired',
              'upload_drop_box_3',
              'Upload verification letters for each entity owner.'
            ),
            upload_drop_box_3: uploadDropBoxField('oneIsRequired', 'upload_drop_box_2', ''),
            notes: methodNotesField()
          },
          [['upload_drop_box_2', 'upload_drop_box_3']]
        )
        .required();
      break;

    case 10:
      validations = yup
        .object()
        .shape(
          {
            legal_name: legalNameField(),
            upload_drop_box_1: uploadDropBoxField(
              'oneIsRequired',
              'upload_drop_box_2',
              'Select the attached document or upload the required files.'
            ),
            upload_drop_box_2: uploadDropBoxField('oneIsRequired', 'upload_drop_box_1', ''),
            notes: methodNotesField()
          },
          [['upload_drop_box_1', 'upload_drop_box_2']]
        )
        .required();
      break;

    case 13:
      validations = yup.object().shape({
        legal_name: legalNameField(),
        upload_drop_box_1: uploadDropBoxField(
          'singleAndRequired',
          'upload_drop_box_1',
          'Select the attached document or upload the required files.'
        ),
        notes: methodNotesField()
      });
      break;

    case 14:
      validations = yup.object().shape({
        legal_name: legalNameField(),
        upload_drop_box_1: uploadDropBoxField(
          'singleAndRequired',
          'upload_drop_box_1',
          'Select the attached document or upload the required files.'
        ),
        notes: methodNotesField()
      });
      break;

    case 15:
      validations = yup.object().shape({
        legal_name: legalNameField(),
        upload_drop_box_1: uploadDropBoxField(
          'singleAndRequired',
          'upload_drop_box_1',
          'Select the attached document or upload the required files.'
        ),
        notes: methodNotesField()
      });
      break;

    case 16:
      validations = yup.object().shape({
        legal_name: legalNameField(),
        upload_drop_box_1: uploadDropBoxField(
          'singleAndRequired',
          'upload_drop_box_1',
          'Select the attached document or upload the required files.'
        ),
        notes: methodNotesField()
      });
      break;

    case 17:
      validations = yup
        .object()
        .shape(
          {
            legal_name: legalNameField(),
            upload_drop_box_3: uploadDropBoxField(),
            terms_1: firstTermField(),
            upload_drop_box_1: uploadDropBoxField(
              'oneIsRequired',
              'upload_drop_box_2',
              'Add recent W-2 forms, K-1 forms, 1099 forms, or alternative evidence.'
            ),
            upload_drop_box_2: uploadDropBoxField('oneIsRequired', 'upload_drop_box_1', ''),
            terms_2: secondTermField(),
            income_amount: incomeAmountField(),
            notes: methodNotesField()
          },
          [['upload_drop_box_1', 'upload_drop_box_2']]
        )
        .required();
      break;

    case 18:
      validations = yup
        .object()
        .shape({
          legal_name: legalNameField(),
          upload_drop_box_2: uploadDropBoxField(),
          upload_drop_box_1: uploadDropBoxField(
            'singleAndRequired',
            'upload_drop_box_1',
            'Upload evidence of position claimed.'
          ),
          officers_investment_or_deal_name: officersInvestmentDealField(),
          notes: methodNotesField()
        })
        .required();
      break;

    case 19:
      validations = yup.object().shape(
        {
          legal_name: legalNameField(),
          upload_drop_box_2: uploadDropBoxField(),
          upload_drop_box_1: uploadDropBoxField(
            'singleAndRequired',
            'upload_drop_box_1',
            'Upload evidence of position claimed.',
            3
          ),
          certify: methodCertifyField(),
          notes: methodNotesField()
        },
        [['upload_drop_box_1', 'certify']]
      );
      break;

    case 21:
      validations = yup.object().shape({
        legal_name: legalNameField(),
        upload_drop_box_1: uploadDropBoxField(
          'singleAndRequired',
          'upload_drop_box_1',
          'Select the attached document or upload the required files.'
        ),
        notes: methodNotesField()
      });
      break;

    case 23:
      validations = yup
        .object()
        .shape(
          {
            legal_name: legalNameField(),
            upload_drop_box_1: uploadDropBoxField(
              'oneIsRequired',
              'upload_drop_box_2',
              'Select the attached document or upload the required files.'
            ),
            upload_drop_box_2: uploadDropBoxField('oneIsRequired', 'upload_drop_box_1', ''),
            notes: methodNotesField()
          },
          [['upload_drop_box_1', 'upload_drop_box_2']]
        )
        .required();
      break;

    case 24:
      validations = yup
        .object()
        .shape(
          {
            legal_name: legalNameField(),
            upload_drop_box_1: uploadDropBoxField(
              'singleAndRequired',
              'upload_drop_box_1',
              "Upload officer's certificate."
            ),
            upload_drop_box_2: uploadDropBoxField(
              'oneIsRequired',
              'upload_drop_box_3',
              'Upload verification letters for each entity owner.'
            ),
            upload_drop_box_3: uploadDropBoxField('oneIsRequired', 'upload_drop_box_2', ''),
            notes: methodNotesField()
          },
          [['upload_drop_box_2', 'upload_drop_box_3']]
        )
        .required();
      break;

    case 27:
      validations = yup
        .object()
        .shape(
          {
            legal_name: legalNameField(),
            investor_type: investorTypeField(),
            investor_meet_requirement: investorMeetField(),
            upload_drop_box_1: uploadDropBoxField(
              'oneIsRequired',
              'upload_drop_box_2',
              'Upload proof of fund ownership.'
            ),
            upload_drop_box_2: uploadDropBoxField('oneIsRequired', 'upload_drop_box_1', ''),
            notes: methodNotesField()
          },
          [['upload_drop_box_1', 'upload_drop_box_2']]
        )
        .required();
      break;

    case 28:
      validations = yup.object().shape({
        legal_name: legalNameField(),
        investor_type: investorTypeField(),
        upload_drop_box_1: uploadDropBoxField(
          'singleAndRequired',
          'upload_drop_box_1',
          'Select the attached document or upload the required files',
          28
        ),
        certify: methodCertifyField(28),
        company_checkbox: methodCompanyCheckboxField('individual'),
        liability: methodCompanyCheckboxField('liability'),
        notes: methodNotesField()
      });
      break;

    case 29:
      validations = yup.object().shape({
        legal_name: legalNameField(),
        investor_type: investorTypeField(),
        terms: methodCompanyCheckboxField('terms'),
        upload_drop_box_1: uploadDropBoxField(
          'singleAndRequired',
          'upload_drop_box_1',
          'Upload a qualified purchaser certificate.'
        ),
        upload_drop_box_2: uploadDropBoxField(
          'oneIsRequired',
          'upload_drop_box_1',
          'Select the attached document or upload the required files.'
        ),
        notes: methodNotesField()
      });
      break;

    case 30:
      validations = yup.object().shape({
        legal_name: legalNameField(),
        upload_drop_box_1: uploadDropBoxField(
          'singleAndRequired',
          'upload_drop_box_1',
          `Upload a plan statement, plan fiduciary, or officer's certificate.`
        ),
        upload_drop_box_2: uploadDropBoxField(),
        notes: methodNotesField()
      });
      break;

    case 31:
      validations = yup.object().shape({
        legal_name: legalNameField(),
        upload_drop_box_1: uploadDropBoxField(
          'singleAndRequired',
          'upload_drop_box_1',
          `Upload evidence of position claimed.`
        ),
        upload_drop_box_2: uploadDropBoxField(),
        notes: methodNotesField()
      });
      break;

    case 32:
      validations = yup.object().shape({
        legal_name: legalNameField(),
        upload_drop_box_1: uploadDropBoxField(
          'singleAndRequired',
          'upload_drop_box_1',
          `Upload proof of fund ownership.`
        ),
        upload_drop_box_2: uploadDropBoxField(),
        notes: methodNotesField()
      });
      break;

    case 33:
      validations = yup.object().shape({
        legal_name: legalNameField(),
        certify: methodCertifyField(33),
        upload_drop_box_1: uploadDropBoxField(
          'singleAndRequired',
          'upload_drop_box_1',
          `Upload proof of business ownership.`
        ),
        upload_drop_box_2: uploadDropBoxField(),
        notes: methodNotesField()
      });
      break;

    case 34:
      validations = yup.object().shape({
        legal_name: legalNameField(),
        certify: methodCertifyField(34),
        upload_drop_box_1: uploadDropBoxField(
          'singleAndRequired',
          'upload_drop_box_1',
          `Upload a qualified purchaser certificate.`
        ),
        upload_drop_box_2: uploadDropBoxField(),
        notes: methodNotesField()
      });
      break;

    case 35:
      validations = yup.object().shape({
        legal_name: legalNameField(),
        investor_type: investorTypeField(),
        certify: methodCompanyCheckboxField('terms'),
        upload_drop_box_1: uploadDropBoxField(
          'singleAndRequired',
          'upload_drop_box_1',
          `Upload proof of investments.`
        ),
        upload_drop_box_2: uploadDropBoxField(),
        upload_drop_box_3: uploadDropBoxField(),
        notes: methodNotesField()
      });
      break;

    case 36:
      validations = yup.object().shape({
        legal_name: legalNameField(),
        upload_drop_box_1: uploadDropBoxField(
          'singleAndRequired',
          'upload_drop_box_1',
          `Upload evidence of who all the beneficial owners of the entity are.`
        ),
        upload_drop_box_2: uploadDropBoxField(
          'singleAndRequired',
          'upload_drop_box_3',
          `Upload a qualified purchaser certificate for each entity owner.`
        ),
        upload_drop_box_3: uploadDropBoxField(),
        notes: methodNotesField()
      });
      break;

    case 37:
      validations = yup.object().shape({
        legal_name: legalNameField(),
        name: nameField(),
        crd_number: methodCRDNumberField(),
        firm_name: nameField(),
        upload_drop_box_1: uploadDropBoxField(
          'singleAndRequired',
          'upload_drop_box_1',
          `Select the attached document or upload the required files.`
        ),
        notes: methodNotesField()
      });
      break;

    case 38:
      validations = yup.object().shape({
        legal_name: legalNameField(),
        upload_drop_box_1: uploadDropBoxField(
          'singleAndRequired',
          'upload_drop_box_1',
          `Upload evidence of position claimed.`
        ),
        aknowledgeable_employees_investment_or_deal_name: ackNameField(),
        notes: methodNotesField()
      });
      break;

    case 39:
      validations = yup
        .object()
        .shape(
          {
            legal_name: legalNameField(),
            upload_drop_box_1: uploadDropBoxField(
              'oneIsRequired',
              'upload_drop_box_2',
              'Select the attached document or upload the required files.'
            ),
            upload_drop_box_2: uploadDropBoxField('oneIsRequired', 'upload_drop_box_1', ''),
            notes: methodNotesField()
          },
          [['upload_drop_box_1', 'upload_drop_box_2']]
        )
        .required();
      break;

    case 40:
      validations = yup
        .object()
        .shape(
          {
            legal_name: legalNameField(),
            upload_drop_box_1: uploadDropBoxField(
              'oneIsRequired',
              'upload_drop_box_2',
              'Select the attached document or upload the required files.'
            ),
            upload_drop_box_2: uploadDropBoxField('oneIsRequired', 'upload_drop_box_1', ''),
            notes: methodNotesField()
          },
          [['upload_drop_box_1', 'upload_drop_box_2']]
        )
        .required();
      break;

    case 41:
      validations = yup.object().shape({
        legal_name: legalNameField(),
        upload_drop_box_2: uploadDropBoxField(),
        upload_drop_box_1: uploadDropBoxField(
          'singleAndRequired',
          'upload_drop_box_1',
          `Upload evidence of position claimed.`
        ),
        aknowledgeable_employees_investment_or_deal_name: ackNameField(),
        notes: methodNotesField()
      });
      break;

    case 42:
      validations = yup
        .object()
        .shape(
          {
            legal_name: legalNameField(),
            upload_drop_box_2: uploadDropBoxField('oneIsRequired', 'upload_drop_box_1', ''),
            name: nameField(),
            crd_number: methodCRDNumberField(),
            upload_drop_box_1: uploadDropBoxField(
              'oneIsRequired',
              'upload_drop_box_2',
              'Select the attached document or upload the required files.'
            ),
            firm_name: nameField(),
            notes: methodNotesField()
          },
          [['upload_drop_box_1', 'upload_drop_box_2']]
        )
        .required();
      break;

    case 'fileUpload':
      validations = yup.object().shape({
        pfs_link: pfsLinkField(),
        data_obj: attachmentDocumentsField(),
        attachments: attachmentsField()
      });
      break;

    case 'additionalDocument':
      validations = yup.object().shape({
        data_obj: attachmentDocumentsField(),
        attachments: attachmentsField(),
        notes: methodNotesField()
      });
      break;

    default:
      break;
  }
  return { validations };
};
