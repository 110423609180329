import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// COMPONENT IMPORTS
import axios from '../config/api';
import axiosFormData from '../config/apiFormData';
import { showUploadProgress } from './commonSlice';

// File Management Fetch Files API
export const getFilesData = createAsyncThunk('file-management/getFiles', async (params) => {
  try {
    return await axios.get('file', { params });
  } catch (error) {
    console.log('error :>> ', error);
  }
});

// File Management Fetch storage details API
export const searchMedia = createAsyncThunk('file-management/search-media', async (params) => {
  try {
    return await axios.get('file/search-media', { params });
  } catch (error) {
    console.log('error :>> ', error);
  }
});

// File Management Create Folder API
export const createFolder = createAsyncThunk('file-management/getFiles', async (data) => {
  try {
    return await axios.post(`file/others/create`, data);
  } catch (error) {
    console.log('error :>> ', error);
  }
});

// File Management Upload Folder and Files in Others API
export const uploadOtherFilesFolder = createAsyncThunk(
  'file-management/uploadOtherFilesFolder',
  async ({ payload, endPoint }) => {
    try {
      return await axios.post(`file/others/${endPoint}`, payload);
    } catch (error) {
      console.log('error :>> ', error);
    }
  }
);

// File Management trash list API
export const getTrashList = createAsyncThunk('file-management/trashListing', async (params) => {
  try {
    return await axios.get(`file/trash_listing`, { params });
  } catch (error) {
    console.log('error :>> ', error);
  }
});

// File Management delete folder & file API
export const deleteTrashItem = createAsyncThunk('file-management/trash_delete', async (payload) => {
  try {
    const { user_id, ...rest } = payload;
    return await axios.post(`file/trash_delete/${user_id}`, rest);
  } catch (error) {
    console.log('error :>> ', error);
  }
});

// File Management trash clear API
export const emptyTrashItem = createAsyncThunk('file-management/empty_trash', async (user_id) => {
  try {
    return await axios.delete(`file/empty_trash/${user_id}`);
  } catch (error) {
    console.log('error :>> ', error);
  }
});

// File Management trash search API
export const searchTrashItem = createAsyncThunk('file-management/trash_search', async (params) => {
  try {
    return await axios.get(`file/search`, { params });
  } catch (error) {
    console.log('error :>> ', error);
  }
});

// File Management trash search API
export const searchArchiveItem = createAsyncThunk(
  'file-management/search_archive',
  async (params) => {
    try {
      return await axios.get(`file/search_archive`, { params });
    } catch (error) {
      console.log('error :>> ', error);
    }
  }
);

// File Management move files list API
export const getMoveList = createAsyncThunk('file-management/move_list', async (params) => {
  try {
    return await axios.get('file/move/list', { params });
  } catch (error) {
    console.log('error :>> ', error);
  }
});

// File Management move file API
export const moveTrashItem = createAsyncThunk('file-management/move_item', async (payload) => {
  try {
    return await axios.post('file/trash/move_item', payload);
  } catch (error) {
    console.log('error :>> ', error);
  }
});

// File Management move to archive API
export const moveToArchive = createAsyncThunk(
  'file-management/move_to_archive',
  async (payload) => {
    try {
      return await axios.post(`file/move_file_to_archive`, payload);
    } catch (error) {
      console.log('error :>> ', error);
    }
  }
);

// File Management Fetch archive list API
export const getArchiveList = createAsyncThunk(
  'file-management/archive_listing',
  async (params) => {
    try {
      return await axios.get('file/archive_listing', { params });
    } catch (error) {
      console.log('error :>> ', error);
    }
  }
);

// File Management Delete file API
export const deleteFileToTrash = createAsyncThunk(
  'file-management/delete_to_trash',
  async (payload) => {
    try {
      return await axios.post(`file/remove_files`, payload);
    } catch (error) {
      console.log('error :>> ', error);
    }
  }
);

// File Management Scan file API
export const scanDownloadFile = createAsyncThunk(
  'file-management/scan_download_file',
  async (payload) => {
    try {
      return await axios.post(`file/scan_download_file`, payload);
    } catch (error) {
      console.log('error :>> ', error);
    }
  }
);

// File Management Scan file API
export const fileFolderRename = createAsyncThunk('file-management/rename_file', async (payload) => {
  try {
    return await axios.post('file/rename', payload);
  } catch (error) {
    console.log('error :>> ', error);
  }
});

// File Management category file upload API
export const categoriesFileUpload = createAsyncThunk(
  'category/categories_file_upload',
  async (data, thunkAPI) => {
    const { formData, categoryId, categoryName, files, id } = data;

    const category_name = categoryName === 'asset' ? 'assets' : categoryName;

    const config = {
      onUploadProgress: (progressEvent) => {
        const total = progressEvent.progress * 100;
        thunkAPI.dispatch(
          showUploadProgress({
            files: [...files],
            progress: Math.floor(total),
            id: id,
            isDelete: false
          })
        );
      }
    };

    return await axiosFormData.post(`${category_name}/${categoryId}/media`, formData, config);
  }
);

// File Management Fetch storage details API
export const getStorageDetails = createAsyncThunk(
  'file-management/get-used-space',
  async (params) => {
    try {
      return await axios.get('file/get-used-space', { params });
    } catch (error) {
      console.log('error :>> ', error);
    }
  }
);

// File Management Fetch storage details API
export const checkLifeInsuranceData = createAsyncThunk(
  'file-management/assets_life_insurance',
  async (params) => {
    try {
      return await axios.get('file/assets_life_insurance', { params });
    } catch (error) {
      console.log('error :>> ', error);
    }
  }
);

// INITIAL STATE
const initialState = {
  filesFolders: [],
  trashListData: [],
  archiveListData: [],
  storageDetails: {
    used_space: 0,
    total_space: 0
  }
};

/* ============================== FILE MANAGEMENT SLICE ============================== */
export const fileManagementSlice = createSlice({
  name: 'fileManagement',
  initialState,
  reducers: {
    resetFileManagementSlice: () => initialState
  },
  extraReducers: (builder) => {
    builder.addCase(getFilesData.fulfilled, (state, action) => {
      state.filesFolders = action?.payload?.data;
    });
    builder.addCase(getTrashList.fulfilled, (state, action) => {
      state.trashListData = action?.payload?.data;
    });
    builder.addCase(searchTrashItem.fulfilled, (state, action) => {
      state.trashListData = action?.payload?.data;
    });
    builder.addCase(getArchiveList.fulfilled, (state, action) => {
      state.archiveListData = action?.payload?.data;
    });
    builder.addCase(searchArchiveItem.fulfilled, (state, action) => {
      state.archiveListData = action?.payload?.data;
    });
    builder.addCase(getStorageDetails.fulfilled, (state, action) => {
      state.storageDetails = action?.payload?.data;
    });
  }
});

export const { resetFileManagementSlice } = fileManagementSlice.actions;
export default fileManagementSlice.reducer;

export const selectFiles = (state) => state.fileManagement.filesFolders;
export const useFilesFoldersData = () => {
  const filesFolders = useSelector(selectFiles);
  return useMemo(() => filesFolders, [filesFolders]);
};

export const selectTrashList = (state) => state.fileManagement.trashListData;
export const useTrashListData = () => {
  const trashListData = useSelector(selectTrashList);
  return useMemo(() => trashListData, [trashListData]);
};

export const selectArchiveList = (state) => state.fileManagement.archiveListData;
export const useArchiveListData = () => {
  const archiveListData = useSelector(selectArchiveList);
  return useMemo(() => archiveListData, [archiveListData]);
};

export const selectStorageDetails = (state) => state.fileManagement.storageDetails;
export const useStorageDetails = () => {
  const storageDetails = useSelector(selectStorageDetails);
  return useMemo(() => storageDetails, [storageDetails]);
};
