import React, { useState } from 'react';
import Papa from 'papaparse';
import ReactPlayer from 'react-player';
import { Tab, Tabs } from 'react-bootstrap';
import PropTypes from 'prop-types';

// Image Preview Component
const ImagePreview = ({ src, alt }) => <img src={src} alt={alt} style={{ width: '100%' }} />;
ImagePreview.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired
};

// Video Preview Component
const VideoPreview = ({ url }) => (
  <ReactPlayer
    className="cs-videoplayer"
    url={url}
    controls
    config={{ file: { attributes: { controlsList: 'nodownload' } } }}
    width="auto"
    height="100%"
  />
);
VideoPreview.propTypes = {
  url: PropTypes.string.isRequired
};

// Audio Preview Component
const AudioPreview = ({ src }) => <audio controls src={src} style={{ width: '100%' }} />;
AudioPreview.propTypes = {
  src: PropTypes.string.isRequired
};

// PDF Preview Component
const PdfPreview = ({ src }) => (
  <iframe src={src} width="100%" height="600px" title="file" type="application/pdf" />
);
PdfPreview.propTypes = {
  src: PropTypes.string.isRequired
};

// CSV Preview Component
const CsvPreview = ({ csvData }) => {
  const [pageIndex, setPageIndex] = useState(0);
  const pageSize = 50;
  const parsedData = Papa.parse(csvData, {
    header: false,
    skipEmptyLines: true,
    transformHeader: (header) => header.trim()
  });
  const rows = parsedData.data;
  const currentPageRows = rows.slice(pageIndex * pageSize, (pageIndex + 1) * pageSize);

  return (
    <div className="table-preview-container">
      <div className="table-preview-row">
        <table className="cs-table">
          <tbody className="cs-tbody">
            {currentPageRows.map((row, rowIndex) => (
              <tr key={rowIndex}>
                {Object.values(row).map((value, cellIndex) => (
                  <td key={cellIndex}>{value}</td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="cs-modal-btn cs-center-btn">
        <button
          onClick={() => setPageIndex(pageIndex - 1)}
          disabled={pageIndex === 0}
          className="cs-btn-primary md-btn cs-regular-h5">
          Previous
        </button>
        <button
          onClick={() => setPageIndex(pageIndex + 1)}
          disabled={(pageIndex + 1) * pageSize >= rows.length}
          className="cs-btn-primary md-btn cs-regular-h5">
          Next
        </button>
      </div>
    </div>
  );
};
CsvPreview.propTypes = {
  csvData: PropTypes.string.isRequired
};

// XLSX Preview Component
const XlsxPreview = ({ xlsxData, selectedSheet, onSelectSheet }) => {
  if (!xlsxData[selectedSheet]) {
    return <span className="cs-regular-h4">No data in the selected sheet</span>;
  }

  const sheet = xlsxData[selectedSheet];
  if (sheet.length === 0) {
    return <span className="cs-regular-h4">No data in the selected sheet</span>;
  }

  const headers = sheet[0];
  const rows = sheet.slice(1);
  const formatDate = (value) => {
    if (value instanceof Date) {
      const correctedDate = new Date(value.getTime() + 24 * 60 * 60 * 1000);
      return correctedDate.toLocaleDateString(); // Customize the date format as needed
    }
    return value;
  };

  return (
    <div className="table-preview-container">
      <div className="cs-preview-xls">
        <div className="table-preview-row">
          <table className="cs-table">
            <thead className="cs-thead">
              <tr>
                {headers.map((header, index) => (
                  <th key={index}>{header}</th>
                ))}
              </tr>
            </thead>
            <tbody className="cs-tbody">
              {rows.map((row, rowIndex) => (
                <tr key={rowIndex}>
                  {row.map((cell, colIndex) => (
                    <td key={colIndex}>{formatDate(cell)}</td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <div className="cs-preview-tab">
        {Object.keys(xlsxData).length > 1 && (
          <Tabs
            defaultActiveKey={selectedSheet}
            id="sheet-tab"
            className="cs-regular-sub-heading-m"
            onSelect={onSelectSheet}>
            {Object.keys(xlsxData).map((sheetName, i) => (
              <Tab className="d-none" eventKey={sheetName} key={i} title={sheetName}>
                {sheetName}
              </Tab>
            ))}
          </Tabs>
        )}
      </div>
    </div>
  );
};
XlsxPreview.propTypes = {
  xlsxData: PropTypes.objectOf(PropTypes.array).isRequired,
  selectedSheet: PropTypes.string.isRequired,
  onSelectSheet: PropTypes.func.isRequired
};

// DOCX Preview Component
const DocxPreview = () => (
  <div id="docx-preview-container" style={{ height: '100%', overflow: 'auto' }} />
);

// Document Preview Component
const DocumentPreview = ({ url }) => (
  <iframe
    src={`https://docs.google.com/gview?url=${encodeURIComponent(url)}&embedded=true`}
    style={{ width: '100%' }}
    title="Document preview"
  />
);
DocumentPreview.propTypes = {
  url: PropTypes.string.isRequired
};

// TXT Preview Component
const TxtPreview = ({ content }) => (
  <div className="txt-preview">
    <pre>{content}</pre>
  </div>
);
TxtPreview.propTypes = {
  content: PropTypes.string.isRequired
};

// Main Component that renders different previews based on file type
const FileTypePreview = ({ fileType, fileDetails, details, selectedSheet, onSelectSheet }) => {
  switch (fileType) {
    case 'image':
      return <ImagePreview src={details.blobUrl} alt={fileDetails.name} />;
    case 'csv':
      return <CsvPreview csvData={details.csvContent} />;
    case 'video':
      return <VideoPreview url={details.blobUrl} />;
    case 'audio':
      return <AudioPreview src={details.blobUrl} />;
    case 'pdf':
      return <PdfPreview src={details.blobUrl} />;
    case 'xlsx':
      return (
        <XlsxPreview
          xlsxData={details.xlsxContent}
          selectedSheet={selectedSheet}
          onSelectSheet={onSelectSheet}
        />
      );
    case 'document':
      return <DocumentPreview url={fileDetails.attachment} />;
    case 'docx':
      return <DocxPreview />;
    case 'txt':
      return <TxtPreview content={details.txtContent} />;
    default:
      return <span className="default-regular-h4">Unsupported file type</span>;
  }
};

FileTypePreview.propTypes = {
  fileType: PropTypes.string,
  fileDetails: PropTypes.object,
  details: PropTypes.object,
  selectedSheet: PropTypes.string,
  onSelectSheet: PropTypes.func
};

export default FileTypePreview;
