import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import moment from 'moment';

// COMPONENT IMPORTS
import CapsyncIcon from '../../components/CapsyncIcon';

// API
import { readNotification } from '../../slices/notificationSlice';
import { useUser } from '../../slices/authSlice';

/* ============================== NOTIFICATIONS ============================== */
const Notifications = ({ notificationData, setRefreshNotification }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const user = useUser();
  const { savedAvatar } = useSelector((state) => state.auth);
  const unreadNotification = notificationData?.filter((item) => !item.read_status);

  const [isHovered, setIsHovered] = useState(-1);

  const handleNotification = (item, position) => {
    const notificationId =
      position !== 'markAllRead' ? [item.id.toString()] : item.map((item) => item.id.toString());
    if (!item.read_status) {
      dispatch(readNotification({ notification_id: notificationId, user_id: user.id.toString() }))
        .unwrap()
        .then((res) => {
          if (res.status) {
            setRefreshNotification(true);
            if (position === 'fromMsg') {
              linkNavigation(item);
            }
          }
        });
    }
  };
  const linkNavigation = (item) => {
    const routeMap = {
      add_invite: 'collaboration',
      remove_access_client: 'collaboration',
      delete_client: 'collaboration',
      transfer_to_clients: 'collaboration',
      transfer_clients: 'collaboration',
      re_assign: 'collaboration',
      collaboration_request: 'request',
      change_permission: 'request',
      transfer_to_collaboration: 'request',
      transfer_collaboration: 'request',
      remove_access: 'request',
      signature_request: 'request',
      add_profile_pic: 'settings'
    };
    const route = routeMap[item.req_type];

    if (route) {
      navigate(route);
    }
  };

  return (
    <div className="cs-notification">
      <div className="title">
        <div className="notification">
          <div className="notification-img">
            <span className="cs-neutral-100">
              <CapsyncIcon title="notification-outlined" size="22" />
            </span>
          </div>
          <span className="cs-medium-sub-heading-m  cs-neutral-100">Notifications</span>
        </div>
        {unreadNotification.length > 0 && (
          <div
            className="mark-as cursor-pointer"
            onClick={() => handleNotification(unreadNotification, 'markAllRead')}>
            <span className="cs-regular-sub-heading-s cs-primary" id="notify-markasread">
              Mark all as read
            </span>
          </div>
        )}
      </div>
      <div className="cs-scroller cs-notification-area">
        {unreadNotification?.length > 0 ? (
          unreadNotification?.map((item, index) => (
            <Link
              to={() => linkNavigation(item)}
              className="notification-link unread"
              key={index}
              onMouseEnter={() => setIsHovered(index)}
              onMouseLeave={() => setIsHovered(-1)}>
              <div className="notification-item">
                <div
                  className="notification-message cs-regular-sub-heading-s cs-neutral-90"
                  onClick={() => handleNotification(item, 'fromMsg')}>
                  <span className="cs-notification-badge" />
                  {item?.message}
                </div>
                {isHovered === index ? (
                  <span
                    className="cs-neutral-80"
                    onClick={() => handleNotification(item, 'fromIcon')}>
                    <CapsyncIcon title="close-outlined" size="18" />
                  </span>
                ) : (
                  <div className="notification-date-time cs-light-body-text-s cs-neutral-60">
                    {moment(item?.createdAt).format('MM/DD/YYYY')} <br />
                    {moment(item?.createdAt).format('hh:mm A')}
                  </div>
                )}
              </div>
            </Link>
          ))
        ) : (
          <div className="notification-link">
            <div className="notification-item">
              <div className="notification-message cs-regular-sub-heading-s cs-neutral-60">
                No pending notifications
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="title">
        <div className="notification">
          <div className="notification-img">
            <span className="cs-neutral-100">
              <CapsyncIcon title="todo-outlined" size="22" />
            </span>
          </div>
          <span className="cs-medium-sub-heading-m  cs-neutral-100">ToDos</span>
        </div>
      </div>
      {!savedAvatar ? (
        <Link
          to={'/settings/account-details'}
          className="notification-link unread action-required cursor-pointer">
          <div className="notification-item">
            <div className="notification-message cs-regular-sub-heading-s cs-neutral-90 ">
              <span className="cs-notification-badge" />
              Profile picture required.
            </div>
          </div>
        </Link>
      ) : (
        <div className="notification-link unread action-required">
          <div className="notification-item">
            <div className="notification-message cs-regular-sub-heading-s cs-neutral-50">
              No pending to-do's
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

// PROPS TYPE
Notifications.propTypes = {
  notificationData: PropTypes.array,
  setRefreshNotification: PropTypes.func
};

export default Notifications;
