import React from 'react';
import PropTypes from 'prop-types';

// COMPONENT IMPORTS
import Modal from './Modal';
import CapsyncIcon from './CapsyncIcon';

/* ============================== CHANGE PASSWORD & LOGOUT MODAL ============================== */
const ChangePasswordLogoutModal = ({
  showChangePasswordModal,
  setShowChangePasswordModal,
  handleContinue
}) => {
  const closeModal = () => setShowChangePasswordModal(false);

  const body = (
    <div className="cs-text-center">
      <span className="cs-success reset-pwd">
        <CapsyncIcon title="success-validation" size="84" />
      </span>
      <div className="cs-modal-text cs-light-body-text-m cs-neutral-90">
        Reset password link has been sent to your registration email address.
      </div>
    </div>
  );

  return (
    <Modal
      title={'Reset Password'}
      cancelButtonLabel={'Cancel'}
      saveButtonLabel={'Continue'}
      show={showChangePasswordModal}
      isCloseButton={false}
      className={'cs-md-modal'}
      body={body}
      handleOnCancel={closeModal}
      handleOnSave={handleContinue}
      modalFooterClass="cs-center-btn"
    />
  );
};

// PROPS TYPE
ChangePasswordLogoutModal.propTypes = {
  showChangePasswordModal: PropTypes.bool,
  setShowChangePasswordModal: PropTypes.func,
  handleContinue: PropTypes.func
};

export default ChangePasswordLogoutModal;
