import React, { useEffect } from 'react';
import { Button } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';

// COMPONENT IMPORTS
import { supportedExtensions } from '../../utils/common';
import { socket } from '../../config/Socket';

// API
import { showUploadProgress, useProgressUploadDetails } from '../../slices/commonSlice';
import { useAccessUser, useUser } from '../../slices/authSlice';
import { useStorageDetails } from '../../slices/fileManagementSlice';

/* ============================== DROPBOX UPLOAD ============================== */
const DropBoxUpload = (props) => {
  const { dropBoxUploadRef, parentId, currentFolderDetails, setFileStorageModal } = props;
  const dispatch = useDispatch();

  const progressUploadDetails = useProgressUploadDetails();
  const storageDetails = useStorageDetails();
  const accessUser = useAccessUser();
  const user = useUser();

  const openDropboxChooser = () => {
    const dropBoxOptions = {
      success: async (files) => {
        const uId = Date.now();
        const fileNames = [];
        let total_size = 0;

        for (let i = 0; i < files.length; i++) {
          fileNames.push(files[i].name);
          total_size += files[i].bytes;
        }

        if (currentFolderDetails.check_double_space) {
          total_size = total_size * 2;
        }

        const uploadedData = storageDetails?.used_space;
        const maxLimitData = storageDetails?.total_space;
        const inProgressData = progressUploadDetails.length
          ? progressUploadDetails.reduce((acc, item) => acc + item.size, 0)
          : 0;

        if (uploadedData + total_size + inProgressData > maxLimitData) {
          setFileStorageModal(true);
          return;
        }

        if (total_size <= process.env.REACT_APP_FILE_UPLOAD_SIZE_ALLOWED_FILE_MANAGEMENT) {
          const value = {
            files: files,
            parentId: parentId,
            userId: user.id,
            categoryId: currentFolderDetails?.category_id || undefined,
            categoryPrimaryId: currentFolderDetails?.row_id || undefined,
            progressId: uId,
            maxSpace: storageDetails?.total_space,
            usedSpace: storageDetails?.used_space + inProgressData,
            loginUserId: accessUser?.accessUserID || user.id,
            loginUserRole: accessUser?.roleID || user.user_role.id,
            isCategoryUpload: !!currentFolderDetails?.category_id && !!currentFolderDetails?.row_id,
            uploadType: 'dropbox'
          };
          try {
            socket.emit('driveUpload', value);
          } catch (error) {
            setTimeout(() => {
              dispatch(showUploadProgress({ id: uId, isDelete: true }));
            }, 1000);
            toast.error(error.message);
            console.error('File upload failed:', error);
          }
        } else {
          toast.error(
            'File size greater than ' +
              `${process.env.REACT_APP_FILE_UPLOAD_SIZE_ALLOWED_FILE_MANAGEMENT}` / 1048576 +
              ' mb not allowed'
          );
        }
      },
      cancel() {
        console.log('Chooser was cancelled');
      },
      linkType: 'direct', // 'preview' or 'direct'
      multiselect: true, // Allow multiple file selection
      extensions:
        !!currentFolderDetails?.category_id && !!currentFolderDetails?.row_id
          ? ['.pdf', '.doc', '.docx', '.png', '.jpeg', '.jpg']
          : [...supportedExtensions]
    };
    window.Dropbox.choose(dropBoxOptions);
  };

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://www.dropbox.com/static/api/2/dropins.js';
    script.async = true;
    script.setAttribute('id', 'dropboxjs'); //
    script.setAttribute('data-app-key', process.env.REACT_APP_DROPBOX_KEY);
    document.body.appendChild(script);
  }, []);

  return <Button ref={dropBoxUploadRef} className="d-none" onClick={() => openDropboxChooser()} />;
};

// PROPS TYPE
DropBoxUpload.propTypes = {
  dropBoxUploadRef: PropTypes.any,
  parentId: PropTypes.any,
  currentFolderDetails: PropTypes.object,
  setFileStorageModal: PropTypes.any
};

export default DropBoxUpload;
