// COMPONENT IMPORTS
import {
  ADD_ACCOUNT_MODALS,
  plaidUpdateExceptionInstitutesList
} from '../../../constants/addAccount';

// API
import {
  addAccounts,
  fetchAllPlaidAccountsData,
  generatePublicTokenLink,
  removePlaidInstitutesData,
  updateAccountData
} from '../../../services/plaid.service';
import {
  handleAccountsData,
  handleInstituteData,
  handleLoader,
  handleLoaderData,
  handlePlaidToken,
  handleTransactionLoaderData
} from '../../../slices/plaidSlice';

export const getPlaidAccountsData = async (dispatch, userId) => {
  const res = await fetchAllPlaidAccountsData(userId);
  if (res.code === 200) {
    dispatch(handleAccountsData(res.data));
  }
};

export const addAccountsSuccess = async (
  dispatch,
  public_token,
  metadata,
  setAddAccountStep,
  isUpdateMode,
  userId,
  setIsMainModalOpen
) => {
  const reqData = {
    user_id: userId,
    ins_id: metadata.institution.institution_id,
    accounts: metadata.accounts,
    public_token
  };
  if (isUpdateMode.current) {
    reqData['is_update'] = isUpdateMode.current;
  }
  const isAccountExist = await addAccounts({ ...reqData });

  if (isAccountExist.data) {
    getPlaidAccountsData(dispatch, userId);
  }
  if (isAccountExist?.data && isAccountExist?.data?.is_account_exist === true) {
    setIsMainModalOpen(true);
    setAddAccountStep(ADD_ACCOUNT_MODALS.UPDATE_MODAL);
    dispatch(handleInstituteData(isAccountExist.data.ins_data));
    return false;
  } else {
    dispatch(handleInstituteData(isAccountExist.data.ins_data));
    setAddAccountStep(ADD_ACCOUNT_MODALS.ACCOUNT_LIST_MODAL);
  }
  return isAccountExist;
  // setAddAccountStep(ADD_ACCOUNT_MODALS.MAIN_MODAL);
};

export const handlePlaidAccountUpdate = async (
  dispatch,
  ins_id,
  userID,
  btnClick,
  updateModeRef,
  syncErrorAccount
) => {
  await dispatch(handleLoader({ updateContinueLoader: true }));
  if (plaidUpdateExceptionInstitutesList.includes(ins_id) && !syncErrorAccount) {
    // not supported
    await removePlaidInstitutesData({
      ins_id,
      user_id: userID
    });

    // generate new plaid token
    const res = await generatePublicTokenLink();
    if (res) {
      dispatch(handlePlaidToken(res.data.link_token || ''));
      setTimeout(() => {
        btnClick.current.click();
      }, 2000);
    }
  } else {
    const res = await updateAccountData({
      ins_id,
      user_id: userID
    });
    if (res.code === 200) {
      updateModeRef.current = true;
      if (res.data.link_token !== '' || res.data.link_token !== null) {
        dispatch(handlePlaidToken(res.data.link_token));
        setTimeout(() => {
          btnClick.current.click();
        }, 2000);
      }
    }
  }
  setTimeout(async () => {
    await dispatch(handleLoader({ updateContinueLoader: false }));
  }, 2500);
};

export const getLoaderDetails = async (userId, dispatch) => {
  const reqBody = {
    user_id: userId
  };
  try {
    await dispatch(handleLoaderData(reqBody))
      .unwrap()
      .then((resp) => {
        if (resp.code === 200) {
          dispatch(handleTransactionLoaderData(resp.data));
        } else {
          dispatch(handleTransactionLoaderData([]));
        }
      });
  } catch (error) {
    console.log('error', error);
    dispatch(handleTransactionLoaderData([]));
  }
};
