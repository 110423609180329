// import ReactDOM from 'react-dom';
import React, { useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import '../assets/css/dashboard.css';
import { socket } from '../config/Socket';
import './components/Skeleton/skeleton.css';

const QuickBooksLayout = () => {
  const navigation = useNavigate();
  useEffect(() => {
    socket.on('quickbooks_redirect', (msg) => {
      if (window.localStorage.getItem('quickbooks_redirect')) {
        navigation(
          `/assets/business/reports/${window.localStorage.getItem('quickbooks_redirect')}`
        );
      }
    });
    return () => {
      socket.off('quickbooks_redirect');
    };
  }, []);
  return (
    <div className="cs-main-layout">
      <div className="cs-contentarea">
        <Outlet />
      </div>
    </div>
  );
};

export default QuickBooksLayout;
