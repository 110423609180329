import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { Form, FormLabel } from 'react-bootstrap';

/* ============================== SELECT BOX ============================== */
const SelectBox = (props) => {
  const {
    name,
    options,
    value,
    placeholder,
    className,
    errors,
    touched,
    label,
    handleChangeSelect,
    disabled,
    status,
    parentClassName = '',
    isSearchable = true
  } = props;

  const colorStyles = {
    option: (styles, { isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isDisabled
          ? undefined
          : isSelected
            ? 'var(--primary-surface)'
            : isFocused
              ? 'var(--primary-surface)'
              : undefined,
        color: isSelected ? 'var(--primary-main)' : '#424242'
      };
    }
  };
  return (
    <div className={`cs-dropdown select-dropdown ${parentClassName}`}>
      <Form.Group className="cs-form-group cs-regular-body-text-m">
        {label && <FormLabel>{label}</FormLabel>}
        {options && (
          <Select
            value={value}
            onChange={handleChangeSelect}
            options={options}
            placeholder={placeholder}
            name={name}
            className={'cs-regular-body-text-m ' + disabled ? ' cs-disabled ' : ' ' + className}
            styles={colorStyles}
            isDisabled={disabled}
            isSearchable={isSearchable}
          />
        )}
        {errors && touched && errors && errors[name] && touched[name] && (
          <span className="form-error-msg cs-light-body-text-s cs-danger">{errors[name]}</span>
        )}
        {status && status[name] && !errors[name] && (
          <span className="form-error-msg cs-light-body-text-s cs-danger">{status[name]}</span>
        )}
      </Form.Group>
    </div>
  );
};

// PROPS TYPE
SelectBox.propTypes = {
  name: PropTypes.string,
  options: PropTypes.array,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  errors: PropTypes.object,
  touched: PropTypes.object,
  handleChangeSelect: PropTypes.func,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  status: PropTypes.object,
  parentClassName: PropTypes.string,
  isSearchable: PropTypes.bool
};

export default SelectBox;
