import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

// COMPONENT IMPORTS
import StripeCheckOutForm from './StripeCheckOutForm';

// API
import { useUser } from '../../../slices/authSlice';
import { createPaymentIntent } from '../../../slices/paymentSlice';

/* ============================== STRIPE CHECKOUT ============================== */
const StripeCheckOut = (props) => {
  const { setShowStripeForm, accreditation_id, amount, setPaymentLoader } = props;

  const dispatch = useDispatch();

  const user = useUser();

  const [clientSecret, setClientSecret] = useState('');

  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);
  const appearance = {
    theme: 'stripe',
    variables: {
      colorPrimary: '#0024d4',
      colorBackground: '#f5f5f5',
      borderColor: '#c2c2c2',
      colorText: '#0a0a0a',
      colorDanger: '#e00000',
      gridRowSpacing: '24px',
      gridColumnSpacing: '20px',
      tabSpacing: '8px',
      colorTextPlaceholder: '#757575',
      tabIconColor: '#757575',
      tabIconHoverColor: '#0024d4',
      iconCardCvcColor: '#0024d4',
      borderRadius: '4px'
    },
    rules: {
      '.Tab': {
        border: '1px solid #c2c2c2',
        boxShadow: 'none'
      },

      '.Tab:hover': {
        color: 'var(--colorIconTabSelected)',
        borderColor: 'var(--colorIconTabSelected)',
        backgroundColor: '#eef1ff'
      },

      '.Tab:focus': {
        boxShadow: 'none'
      },

      '.Tab--selected': {
        borderColor: '#0024d4',
        backgroundColor: '#eef1ff',
        color: 'var(--colorIconTabSelected)',
        tabIconSelectedColor: '#0a0a0a',
        boxShadow: 'none'
      },
      '.Tab--selected:focus': {
        boxShadow: 'none'
      }
    }
  };
  const options = {
    clientSecret: clientSecret,
    appearance
  };

  // CALL CREATE PAYMENT INTENT
  useEffect(() => {
    dispatch(
      createPaymentIntent({
        user_id: user?.id,
        amount: amount ? amount : '',
        unique_id: accreditation_id ? accreditation_id : ''
      })
    )
      .unwrap()
      .then((response) => response.code === 200 && setClientSecret(response.data.client_secret))
      .catch((err) => console.log('err stripe checkout', err));
  }, []);

  return (
    clientSecret && (
      <Elements stripe={stripePromise} options={options}>
        <StripeCheckOutForm
          clientSecret={options.clientSecret}
          setShowStripeForm={setShowStripeForm}
          setPaymentLoader={setPaymentLoader}
        />
      </Elements>
    )
  );
};

// PROPS TYPE
StripeCheckOut.propTypes = {
  setShowStripeForm: PropTypes.bool,
  amount: PropTypes.string,
  accreditation_id: PropTypes.string,
  setPaymentLoader: PropTypes.bool
};

export default StripeCheckOut;
