import React, { useEffect, useRef, useState, useCallback } from 'react';
import { Navbar, Row, Col, Dropdown, Form, NavDropdown, Image, Button } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import secureLocalStorage from 'react-secure-storage';
import fileDownload from 'js-file-download';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import axios from 'axios';

// COMPONENT IMPORTS
import config from '../../constants/config';
import Notifications from './Notifications';
import { socket } from '../../config/Socket';
import AccessPfsImage from './SideBar/AccessPfsImage';
import CapsyncIcon from '../../components/CapsyncIcon';
import { TOAST_MESSAGE } from '../../constants/message';
import Avatar from '../../components/userProfile/Avatar';
import { decrypt, localObjectClear } from '../../utils/system';
import { clearPersisterStorage, roles } from '../../utils/common';
import MultipleUserDropDown from './SideBar/MultipleUserDropDown';
import CapFavicon from '../../assets/images/brand/cap-icon-logo.svg';
import CommonFilesForModal from '../SharePFSModals/CommonFilesForModal';
import LogoutConfirmationModal from '../../components/LogoutConfirmationModal';

// API
import {
  accessUserDetails,
  authUserLogout,
  clientViewByIndividual,
  clientViewByProfessionalUser,
  getUserDetails,
  profileViewList,
  setSignInUser,
  setToUserPermission,
  toAccessUserList,
  useAccessUser,
  useAccessUserIcon,
  useProfileViewUsers,
  useToAccessUser,
  useToUserPermission,
  useUserID
} from '../../slices/authSlice';
import { toggleSidebar } from '../../slices/sidebarSlice';
import { useBrandDetails } from '../../slices/brandingSlice';
import { signatureDeleteFiles } from '../../slices/requestSlice';
import { getNotificationList } from '../../slices/notificationSlice';

/* ============================== HEADER ============================== */
const Header = React.memo(function Header({ onAccountOpenModelOpen }) {
  const dispatch = useDispatch();
  const navigation = useNavigate();
  const path = useLocation();

  const userPermission = useToUserPermission();
  const accessUser = useAccessUser();
  const brandDetails = useBrandDetails();
  const toAccessUser = useToAccessUser();
  const userID = useUserID();
  const signInUser = decrypt(localStorage.getItem('id'));
  const accessUserIcon = useAccessUserIcon();
  const profileViewUsers = useProfileViewUsers();
  const isMySettings = path?.pathname?.split('/')?.[1] === 'my-settings';

  const { user } = useSelector((state) => state.auth);
  const { savedAvatar } = useSelector((state) => state.auth);

  const notificationRef = useRef(null);
  const iconRef = useRef(null);

  const [clientAccess, setClientAccess] = useState(toAccessUser);
  const [searchClient, setSearchClient] = useState('');
  const [showFirstModal, setShowFirstModal] = useState(false);
  const [showLogoutModal, setShowLogoutModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [openNotification, setOpenNotification] = useState(false);
  const [notificationData, setNotificationData] = useState([]);
  const [refreshNotification, setRefreshNotification] = useState(false);

  const handleToggleSidebar = () => dispatch(toggleSidebar());

  const trimedSrc =
    user &&
    user?.user_personal_details &&
    user?.user_personal_details?.profile_image_url &&
    user?.user_personal_details?.profile_image_url.replace(
      /(https:\/\/wl-universal-(local|dev|stage)\.s3\.amazonaws\.com|https:\/\/s3\.amazonaws\.com\/universal-clientportalx\.com)/,
      ''
    );

  const handlePageOnAdminAccess = () => {
    const broadcastChannel = new BroadcastChannel('logOut_from_same_tabs');
    broadcastChannel.postMessage('tab_logout');
  };

  const handleLogoutUser = () => {
    setShowLogoutModal(false);
    if (accessUser) {
      socket.emit('exitAccess', {
        user_id: accessUser?.accessUserID,
        access_user_id: userID
      });
    }
    dispatch(authUserLogout());
    clearPersisterStorage(dispatch);
    handlePageOnAdminAccess();
    navigation('/');
    localObjectClear();
  };

  const changeFirstModel = (params) => {
    setShowFirstModal(params);
    // setShowSharePFS(true);
    dispatch(signatureDeleteFiles({ user_id: user && user.id })).unwrap();
  };

  const handlePFSDownload = async () => {
    setIsLoading(true);
    axios({
      url: `${config.BASE_URL}dashboard/download_pfs/${user?.id}`,
      method: 'get',
      responseType: 'blob',
      headers: {
        Authorization: `Bearer ${secureLocalStorage.getItem('token')}`
      }
    })
      .then((response) => {
        if (response && response.status === 200) {
          fileDownload(response.data, 'PFS.pdf');
          toast.success(TOAST_MESSAGE.CLIENT_DATA_EXPORT_SUCCESS);
        } else {
          toast.error(TOAST_MESSAGE.CLIENT_DATA_EXPORT_ERROR);
        }
      })
      .catch((err) => {
        toast.error('PFS Download :' + err.message);
      })
      .finally(() => setIsLoading(false));
  };

  // ACCESS INDIVIDUAL OR PROFESSIONAL USER TO INDIVIDUAL USER ACCOUNT
  const switchUserFun = async (item) => {
    const id = item?.user_id;
    const permission = item?.permission_type;
    try {
      if (accessUser) {
        socket.emit('exitAccess', {
          user_id: accessUser?.accessUserID,
          access_user_id: userID
        });
      }
      // socket.emit('addAccess', {
      //   user_id: accessUser?.accessUserID || userID ,
      //   access_user_id: id,
      // });
      const token = secureLocalStorage.getItem('token');
      const payload = {
        token,
        clientId: id,
        professionalUserId:
          accessUser?.roleID == roles.master_admin_financial_advisor.roleID &&
          accessUser?.accessUserID
            ? accessUser?.accessUserID
            : signInUser
      };
      const accessBy = {
        accessUserID: Number(decrypt(localStorage.getItem('id'))),
        roleName: roles.individual.name,
        roleID: roles.individual.roleID,
        toUserPermission: permission,
        loginUserDetails: accessUser?.loginUserDetails
          ? accessUser?.loginUserDetails
          : user
            ? user
            : null,
        is_dashboard_available:
          accessUser?.is_dashboard_available === false
            ? accessUser?.is_dashboard_available
            : user?.is_dashboard_available
      };
      if (accessUser?.roleID != '5') {
        dispatch(accessUserDetails(accessBy));
      }
      const userResponse =
        accessUser?.roleID == '5'
          ? await dispatch(clientViewByProfessionalUser(payload)).unwrap()
          : await dispatch(clientViewByIndividual(payload)).unwrap();

      if (userResponse?.status) {
        window.location.href = '/dashboard';
      }
    } catch (error) {
      console.log('error :>> ', error);
    }
  };

  const getToAccessUserList = async () => {
    try {
      const response = await dispatch(
        toAccessUserList({
          user_id:
            accessUser?.roleID == '5' ? accessUser?.accessUserID : !accessUser ? userID : signInUser
        })
      ).unwrap();
      if (accessUser?.roleID != '5') {
        const accessUserDetails = {
          ...response?.data?.find((items) => items?.user_id == userID),
          accessUserID: accessUser?.accessUserID,
          roleId: roles.individual.roleID,
          roleName: roles.individual.name
        };
        dispatch(setToUserPermission(accessUserDetails));
      }
    } catch (error) {
      console.log('error :>> ', error);
    }
  };
  const profileViewUsersFunction = async () => {
    await dispatch(
      profileViewList({
        user_id: userID
      })
    ).unwrap();
  };
  useEffect(() => {
    if (accessUser && !accessUser?.is_dashboard_available) {
      socket.emit('addAccess', {
        user_id: accessUser?.accessUserID || userID,
        access_user_id: userID
      });
    }
    if (!accessUser) {
      profileViewUsersFunction();
    }
    getToAccessUserList();

    const handleAccountAccessList = () => {
      getToAccessUserList();
    };
    const handleProfileViews = (response) => {
      profileViewUsersFunction();
    };
    socket.on('account_access_list', handleAccountAccessList);
    socket.on('profile_views', handleProfileViews);

    return () => {
      socket.off('account_access_list', handleAccountAccessList);
      socket.off('profile_views', handleProfileViews);
    };
  }, []);

  // REDIRECT TO SIGN IN USER FUNCTION
  const redirectToLoginUser = async () => {
    dispatch(setToUserPermission(null));
    socket.emit('exitAccess', {
      user_id: accessUser?.accessUserID,
      access_user_id: userID
    });

    dispatch(accessUserDetails(null));
    try {
      const response = await dispatch(getUserDetails(signInUser)).unwrap();
      if (response?.status) {
        window.location.href = '/dashboard';
      }
    } catch (error) {
      console.log('error :>> ', error);
    }
  };

  useEffect(() => {
    const searchResult = toAccessUser?.filter((items) => {
      const name = `${items?.first_name.toLowerCase()} ${items?.last_name.toLowerCase()}`;
      return name.includes(searchClient.toLowerCase());
    });
    dispatch(setSignInUser());
    if (searchClient == '') {
      setClientAccess(toAccessUser);
    } else {
      setClientAccess(searchResult);
    }
  }, [searchClient, toAccessUser]);

  useEffect(() => {
    handleNotification();
    return () => {
      setRefreshNotification(false);
    };
  }, [refreshNotification, openNotification]);

  const handleNotification = () => {
    dispatch(getNotificationList(user?.id))
      .unwrap()
      .then((res) => {
        if (res.status) {
          setNotificationData(res.data);
        }
      });
  };

  useEffect(() => {
    if (user) {
      socket.on('send_notification', handleNotification);
    }
    return () => {
      socket.off('send_notification', handleNotification);
    };
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        notificationRef.current &&
        !notificationRef.current.contains(event.target) &&
        iconRef.current &&
        !iconRef.current.contains(event.target)
      ) {
        setOpenNotification(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [!!refreshNotification]);

  const multipleDisplayAvatar = useCallback(() => {
    const itr = profileViewUsers && profileViewUsers[0];
    return (
      <div className="cs-common-add-dropdown multiple-user-dropdown cs-list-options-dropdown">
        <Dropdown>
          <Dropdown.Toggle className="default-semi-bold-h5">
            <div className="active-user-profile">
              <div className="profile-image">
                <Avatar
                  className={`cs-avatar-md ${itr?.image?.includes('avatar_6') ? 'cs-avatar-text' : 'cs-avatar-img'} `}
                  src={itr?.image}
                  type="accessUser"
                  accessUserName={itr?.display_name}
                />
              </div>
              <span className="active-dot cs-success"></span>
            </div>
            <span className="cs-neutral-80 cs-icon">
              <CapsyncIcon title="chevron-down-outlined" size="14" />
            </span>
          </Dropdown.Toggle>
          <Dropdown.Menu className="cs-scroller">
            {accessUserIcon?.map((itr, i) => {
              return (
                <React.Fragment key={Date.now() + i}>
                  <MultipleUserDropDown itr={itr} />
                </React.Fragment>
              );
            })}
          </Dropdown.Menu>
        </Dropdown>
      </div>
    );
  }, [profileViewUsers]);

  const displayAvatars = useCallback(() => {
    return profileViewUsers?.map((itr, i) => {
      return (
        <React.Fragment key={Date.now() + i}>
          <div className="active-user-profile">
            <div className="cs-common-add-dropdown d-flex">
              <AccessPfsImage itr={itr} />
            </div>
          </div>
        </React.Fragment>
      );
    });
  }, [profileViewUsers]);

  return (
    <React.Fragment>
      {isLoading && (
        <div className="fixed-capsync-loader">
          <div className="cs-loader">
            <Image
              src={(brandDetails && brandDetails.small_logo_s3_url) || CapFavicon}
              className="spin-animation"
              alt="Capsync Loader"
              width={192}
              height={192}
            />
          </div>
        </div>
      )}
      <CommonFilesForModal showFirstModal={showFirstModal} setShowFirstModal={setShowFirstModal} />
      <div className="cs-header-widget">
        <Navbar className="cs-header">
          <div className="container-fluid">
            <Row>
              <Col>
                <div className="cs-menu-nav">
                  <div className="cs-header-left" onClick={handleToggleSidebar}>
                    <span className="hamburger-outlined cs-menu-toggle cs-icon" id="cs-menu-toggle">
                      <CapsyncIcon title="hamburger-outlined" size="22" />
                    </span>
                  </div>
                  <div className="cs-header-center cs-navbar-dropdown-btn dropdown">
                    <Dropdown>
                      <Dropdown.Toggle className="cs-pfs-search" id={'my-pfs-dropdown'}>
                        {accessUser ? user?.display_name : 'My Dashboard'}
                        <CapsyncIcon title="chevron-down-outlined" size="14" />
                      </Dropdown.Toggle>
                      <Dropdown.Menu className="cs-pfs-menu">
                        <Dropdown.Item
                          className={`${!accessUser || isMySettings ? 'cs-active-accounts' : ''} pfs-item`}
                          id="my-dashboard"
                          disabled={accessUser?.roleID === '5'}
                          onClick={() => {
                            accessUser && !accessUser?.is_dashboard_available
                              ? navigation('/my-settings/account-details')
                              : !accessUser ||
                                  accessUser?.roleID === '5' ||
                                  !accessUser?.is_dashboard_available
                                ? undefined
                                : redirectToLoginUser();
                          }}>
                          {accessUser && !accessUser?.is_dashboard_available ? (
                            <span className={`cs-light-body-text-s cs-neutral-100 `}>
                              My Settings
                            </span>
                          ) : (
                            <span className={`cs-light-body-text-s cs-neutral-100 `}>
                              My Dashboard &nbsp;
                              {accessUser?.roleID === '5' && `(${accessUser?.roleName})`}
                            </span>
                          )}
                        </Dropdown.Item>
                        <span className="cs-search-text cs-regular-sub-heading-s cs-neutral-60">
                          Shared accounts
                        </span>

                        <div className="cs-search-bar">
                          <Form.Group className="cs-form-group">
                            <Form.Control
                              type="text"
                              placeholder="Search account"
                              onChange={(e) => setSearchClient(e.target.value)}
                            />
                            <span className="input-field-icon cs-neutral-70">
                              <CapsyncIcon title="search-outlined" size="18" />
                            </span>
                          </Form.Group>
                        </div>
                        {clientAccess?.length > 0 ? (
                          clientAccess?.map((item) => {
                            const id = item?.user_id;
                            return (
                              <span
                                className={`${(accessUser?.roleID == '5' || accessUser?.roleID == '1') && id == userID && !isMySettings && 'cs-active-accounts'} cs-search-result cs-light-body-text-m cs-neutral-90`}
                                key={item}
                                onClick={() => {
                                  switchUserFun(item);
                                }}>
                                {`${item?.first_name} ${item?.last_name} `}
                                <span className="cs-neutral-70  cs-regular-body-text-xs">
                                  {item?.permission_type === 'standard'
                                    ? 'Standard'
                                    : item?.permission_type === 'view'
                                      ? 'View Only'
                                      : 'Standard'}
                                </span>
                              </span>
                            );
                          })
                        ) : (
                          <span className="cs-search-result cs-light-body-text-m cs-neutral-60 dropdown-no-record">
                            No shared accounts available
                          </span>
                        )}
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                  <div className="cs-header-right ms-auto navbar-wrapper navbar-nav">
                    {!accessUser && (
                      <div className="multiple-user-flow">
                        {profileViewUsers?.length > 3 ? multipleDisplayAvatar() : displayAvatars()}
                        <div className="d-none"></div>
                      </div>
                    )}
                    {!userPermission?.permission_type && (
                      <div className="cs-header-notificatins">
                        {/* <div className="cs-header-calender">
                        <span
                          className="cs-cursor-pointer calender-outlined cs-icon"
                          id="cs-header-calender">
                          <CapsyncIcon title="calender-outlined" size="22" />
                        </span>
                        <span className="cs-notification-badge"></span>
                      </div> */}
                        <NavDropdown
                          ref={notificationRef}
                          title={
                            <div
                              ref={iconRef}
                              onClick={() => setOpenNotification(!openNotification)}
                              className={
                                !savedAvatar &&
                                notificationData?.filter((item) => !item.read_status).length ===
                                  0 &&
                                'action-required'
                              }>
                              <span
                                className="cs-cursor-pointer cs-icon"
                                id="cs-header-notification">
                                <CapsyncIcon title="notification-outlined" size="22" />
                              </span>
                              {(!savedAvatar ||
                                notificationData?.filter((item) => !item.read_status).length >
                                  0) && <span span className="cs-notification-badge"></span>}
                            </div>
                          }
                          className="cs-header-dropdown nav-icons cs-header-notification-bell">
                          {openNotification && (
                            <Notifications
                              notificationData={notificationData}
                              setRefreshNotification={setRefreshNotification}
                            />
                          )}
                        </NavDropdown>
                      </div>
                    )}
                    <div className="cs-header-data-share">
                      <div className="cs-header-share">
                        <span
                          className="cs-cursor-pointer share-outlined cs-icon"
                          id="cs-header-calender"
                          onClick={() => changeFirstModel(true)}>
                          <CapsyncIcon title="share-outlined" size="22" />
                        </span>
                      </div>
                      <div className="cs-header-download" onClick={handlePFSDownload}>
                        <span
                          className="cs-cursor-pointer download-outlined cs-icon"
                          id="cs-header-notification">
                          <CapsyncIcon title="download-outlined" size="22" />
                        </span>
                      </div>
                    </div>
                    <div className="cs-header-profile">
                      <NavDropdown
                        title={
                          <React.Fragment>
                            <Avatar
                              className={`cs-avatar-xs ${trimedSrc === '/users/avatar/avatar_6.png' || user?.user_personal_details?.profile_image_url === null || user?.user_personal_details === null ? 'cs-avatar-text' : 'cs-avatar-img'}`}
                              src={`${user?.user_personal_details?.profile_image_url}`}
                            />
                            {/* <span className="cs-verified cs-icon">
                              <Image src={VerifiedIcon} width="14" height="14"></Image>
                            </span> */}
                          </React.Fragment>
                        }
                        className="cs-relative cs-dropdown-nav">
                        <NavDropdown.Item
                          className={`profile-item ${path.pathname.includes('/settings') && 'active'}`}
                          eventKey="link-11"
                          onClick={() => navigation('/settings/account-details')}>
                          <span className="settings-outlined cs-icon">
                            <CapsyncIcon
                              title={`${path.pathname.includes('/settings') ? 'settings-filled' : 'settings-outlined'}`}
                              size="22"
                            />
                          </span>
                          Settings
                        </NavDropdown.Item>
                        <NavDropdown.Item
                          className="profile-item"
                          eventKey="link-12"
                          onClick={() => setShowLogoutModal(true)}>
                          <span className="company-outlined cs-icon">
                            <CapsyncIcon title="logout-outlined" size="22" />
                          </span>
                          Log out
                        </NavDropdown.Item>
                      </NavDropdown>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </Navbar>
        <Button onClick={() => onAccountOpenModelOpen()} className="cs-neutral-10 mobile-add-icon">
          <CapsyncIcon title="add-filled" size="22" />
        </Button>
      </div>
      {showLogoutModal && (
        <LogoutConfirmationModal
          handleLogoutUser={handleLogoutUser}
          handleStayOnCapsync={() => setShowLogoutModal(false)}
          showLogoutModal={showLogoutModal}
        />
      )}
    </React.Fragment>
  );
});

// PROPS TYPE
Header.propTypes = {
  onAccountOpenModelOpen: PropTypes.func
};

export default Header;
