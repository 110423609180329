import { useMemo } from 'react';
import JSZip from 'jszip';
import { useSelector } from 'react-redux';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

// COMPONENT IMPORTS
import axios from '../config/api';
import axiosFormData from '../config/apiFormData';
import { getStorageDetails } from './fileManagementSlice';

// INITIAL STATE
const initialState = {
  assets: null,
  message: null,
  status: false,
  categoryDetails: {},
  categoryTableDetails: {},
  masterCategories: [],
  transactionInsID: ''
};

export const categoryFilesUpdate = createAsyncThunk(
  'category/categoryFilesUpdate',
  async (data) => {
    const { formData, categoryId, categoryName } = data;
    return await axiosFormData.post(`${categoryName}/${categoryId}/media`, formData);
  }
);

export const categoryFilesGet = createAsyncThunk('category/categoryFilesGet', async (data) => {
  const { row_id, user_id, categoryId, categoryName } = data;
  return await axios.get(`${categoryName}/${categoryId}/media_listing`, {
    params: { row_id, user_id }
  });
});

export const categoryFilesDownload = createAsyncThunk(
  'category/categoryFilesDownload',
  async (data) => {
    const response = await axios.post('assets/download_file', data);

    // Assuming the response contains the URL to the file
    const fileUrl = response.data[0];

    return { fileUrl, code: response.code };
  }
);

export const categoryAllFilesDownload = createAsyncThunk(
  'category/categoryFilesDownload',
  async (data) => {
    const response = await axios.post('assets/download_file', data);
    const imageUrls = response.data;
    const zip = new JSZip();

    const downloadPromises = imageUrls.map(async (imageUrl, index) => {
      const imageResponse = await fetch(imageUrl);
      const imageBlob = await imageResponse.blob();
      zip.file(`image_${index}.png`, imageBlob);
    });

    await Promise.all(downloadPromises);
    const zipContent = await zip.generateAsync({ type: 'blob' });

    return { zipContent, code: response.code };
  }
);

export const categoryFilesDelete = createAsyncThunk(
  'category/categoryFilesDelete',
  async (data) => {
    return await axios.delete('assets/delete_media', { data });
  }
);

export const getAutomobileMarketValue = createAsyncThunk(
  'category/getAutomobileMarketValue',
  async (data) => {
    return await axios.post(`/assets/get_automobile_market_value`, data);
  }
);

export const fetchMakeData = createAsyncThunk('/fetchMakeData', async (values) => {
  try {
    const response = await axios.post('/assets/asset_automobile_fetch_make', values);
    return response;
  } catch (error) {
    console.log(error);
  }
});

export const fetchModelData = createAsyncThunk('/fetchModelData', async (values) => {
  try {
    const response = await axios.post('/assets/asset_automobile_fetch_model', values);
    return response;
  } catch (error) {
    console.log(error);
  }
});

export const fetchRealEstatePropertyPrice = createAsyncThunk(
  'category/fetchRealEstatePropertyPrice',
  async (data) => {
    return await axios.post(`/assets/get_real_estate_market_value`, data);
  }
);

export const categoryAddRecords = createAsyncThunk('category/categoryAddRecords', async (data) => {
  const { formData, category, mainCategory } = data;
  return await axiosFormData.post(`${mainCategory}/${category}/add`, formData);
});

export const categoryUpdateRecords = createAsyncThunk(
  'category/categoryUpdateRecords',
  async (data, thunkAPI) => {
    const { values, category, mainCategory, rowId } = data;
    const response = await axios.put(`${mainCategory}/${rowId}/${category}/update`, values);
    thunkAPI.dispatch(getStorageDetails({ user_id: values?.user_id })).unwrap();
    return response;
  }
);

export const liabilityGetOneRecord = createAsyncThunk(
  'category/liabilityGetOneRecord',
  async (data) => {
    const { userId, category, mainCategory, rowId } = data;
    return await axios.get(`/${mainCategory}/${userId}/${category}/${rowId}/get_liability_by_id`);
  }
);

export const assetGetOneRecord = createAsyncThunk('category/assetGetOneRecord', async (data) => {
  const { userId, category, mainCategory, rowId } = data;
  return await axios.get(`/${mainCategory}/${userId}/${category}/${rowId}/get_assets_by_id`);
});

export const getMasterCategory = createAsyncThunk('category/get-master-category', async () => {
  return await axios.get(`/master_categories/get_master_categories`);
});

export const getAssetsCategory = createAsyncThunk(
  'category/get-assets-category',
  async (payload) => {
    const { categoryId, userId } = payload;
    return await axios.get(`/assets/${userId}/${categoryId}`);
  }
);

export const getLiabilitiesCategory = createAsyncThunk(
  'category/get-liabilities-category',
  async (payload) => {
    const { categoryId, userId } = payload;
    return await axios.get(`/liability/${userId}/${categoryId}/listing`);
  }
);

export const deleteAssetsCategory = createAsyncThunk(
  'category/deleteAssetsCategory',
  async (data) => {
    const { rowId, userId, categoryId } = data;
    return await axios.delete(`assets/${rowId}/${categoryId}/delete`, {
      data: { user_id: userId }
    });
  }
);

export const deleteLiabilitiesCategory = createAsyncThunk(
  'category/deleteLiabilitiesCategory',
  async (data) => {
    const { rowId, userId, categoryId } = data;
    return await axios.delete(`liability/${rowId}/${categoryId}/delete`, {
      data: { user_id: userId }
    });
  }
);

export const getAllAssetData = createAsyncThunk('category/getAllAssetData', async (payload) => {
  const { userId } = payload;
  return await axios.get(`/assets/${userId}/0`);
});

export const getAllLiabilityData = createAsyncThunk(
  'category/getAllLiabilityData',
  async (payload) => {
    const { userId } = payload;
    return await axios.get(`/liability/${userId}/0/listing`);
  }
);

export const getColumnFilterData = createAsyncThunk(
  'category/addColumnFilterData',
  async (payload) => {
    const { userId, categoryName, mainCategory } = payload;
    return await axios.get(
      `/column_filter_route/${userId}/${categoryName}/${mainCategory}/get_column_filter`
    );
  }
);

export const findPortfolioColumns = createAsyncThunk(
  'category/addColumnFilterData',
  async (payload) => {
    const { userId, categoryName, rowId } = payload;
    return await axios.get(
      `/column_filter_route/${userId}/${categoryName}/${rowId}/find_portfolio_columns`
    );
  }
);

export const addColumnFilterData = createAsyncThunk(
  'category/getColumnFilterData',
  async (data) => {
    return await axios.post(`/column_filter_route/add_update_column_filter`, data);
  }
);

export const getAllUnlinkedInstallmentAuto = createAsyncThunk(
  'category/getAllUnlinkedInstallmentAuto',
  async (payload) => {
    const { userId } = payload;
    return await axios.get(`/liability/get_installment_account_auto_unlink_list/${userId}`);
  }
);

export const getAllUnlinkedMortgagesRealEstate = createAsyncThunk(
  'category/getAllUnlinkedMortgagesRealEstate',
  async (payload) => {
    const { userId } = payload;
    return await axios.get(`/liability/get_mortgages_real_estate_unlink_list/${userId}`);
  }
);

export const getAllUnlinkedAutomobile = createAsyncThunk(
  'category/getAllUnlinkedAutomobile',
  async (payload) => {
    const { userId } = payload;
    return await axios.get(`/assets/get_automobile_unlink_list/${userId}`);
  }
);

export const getAllUnlinkedRealEstate = createAsyncThunk(
  'category/getAllUnlinkedRealEstate',
  async (payload) => {
    const { userId } = payload;
    return await axios.get(`/assets/get_real_estate_unlink_list/${userId}`);
  }
);

export const linkAutomobileInstallmentAccountAuto = createAsyncThunk(
  'category/linkAutomobileInstallmentAccountAuto',
  async (data) => {
    return await axios.put(`assets/link_automobile_and_installment_account_auto`, data);
  }
);

export const unLinkAutomobileInstallmentAccountAuto = createAsyncThunk(
  'category/unLinkAutomobileInstallmentAccountAuto',
  async (data) => {
    return await axios.put(`assets/unlink_automobile_and_installment_account_auto`, data);
  }
);

export const linkRealEstateMortgageOnRealEstate = createAsyncThunk(
  'category/linkRealEstateMortgageOnRealEstate',
  async (data) => {
    return await axios.put(`assets/link_real_state_and_mortgages_on_real_estate`, data);
  }
);

export const unLinkRealEstateMortgageOnRealEstate = createAsyncThunk(
  'category/unLinkRealEstateMortgageOnRealEstate',
  async (data) => {
    return await axios.put(`assets/unlink_real_state_and_mortgages_on_real_estate`, data);
  }
);

/* ============================== CATEGORY SLICE ============================== */
const categorySlice = createSlice({
  name: 'category',
  initialState,
  reducers: {
    resetCategorySlice: () => initialState,
    setCategoryDetails(state, action) {
      state.categoryDetails = action.payload;
    },
    handleTransactionInsID(state, action) {
      state.transactionInsID = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getMasterCategory.fulfilled, (state, action) => {
      state.masterCategories = action?.payload.data;
    });
    builder.addCase(getAssetsCategory.fulfilled, (state, action) => {
      state.categoryTableDetails = action?.payload.data;
    });
    builder.addCase(getLiabilitiesCategory.fulfilled, (state, action) => {
      state.categoryTableDetails = action?.payload.data;
    });
    builder.addCase(categoryAddRecords.fulfilled, (state, { payload }) => {
      console.log('state: ', state);
      console.log('payload: ', payload);
    });
  }
});
export const { setCategoryDetails, resetCategorySlice, handleTransactionInsID } =
  categorySlice.actions;
export default categorySlice.reducer;

const selectAssets = (state) => state.category.categoryDetails;
export const useCategoryDetail = () => {
  const categoryDetails = useSelector(selectAssets);
  return useMemo(() => categoryDetails, [categoryDetails]);
};

const selectMasterCategories = (state) => state.category.masterCategories;
export const useMasterCategory = () => {
  const masterCategories = useSelector(selectMasterCategories);
  return useMemo(() => masterCategories, [masterCategories]);
};
const selectCategoryTableDetails = (state) => state.category.categoryTableDetails;
export const useCategoryTableDetails = () => {
  const categoryTableDetails = useSelector(selectCategoryTableDetails);
  return useMemo(() => categoryTableDetails, [categoryTableDetails]);
};
