import { useEffect, useMemo } from 'react';
import { addTimePeriod } from '../common';

/**
 * Custom hook to compute the maturity date based on loan details.
 * Also updates the 'maturity_date' field in the form.
 * @param {Object} values - The object containing loan details.
 * @param {Function} setFieldValue - The function to set form field values.
 * @return {value} -  maturity_date
 */
function useMaturityDate({ values, setFieldValue }) {
  const maturityDate = useMemo(() => {
    if (values.date_loan && values.loan_period && values.frequency) {
      return addTimePeriod(values.date_loan, values.loan_period, values.frequency);
    }
    return '';
  }, [values.date_loan, values.loan_period, values.frequency]);

  useEffect(() => {
    setFieldValue('maturity_date', maturityDate);
  }, [maturityDate, setFieldValue]);

  return maturityDate;
}

export default useMaturityDate;
