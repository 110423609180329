import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';

import { Provider } from 'react-redux';
import { SkeletonTheme } from 'react-loading-skeleton';
import { ErrorBoundary } from 'react-error-boundary';
import './assets/css/variables.css';
import App from './App';
import { persistor, store } from './store';
import { PersistGate } from 'redux-persist/integration/react';
import FallBack from './components/FallBack';

// CSS IMPORTS
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';
import 'react-toastify/dist/ReactToastify.css';
import 'react-phone-number-input/style.css';
import 'react-loading-skeleton/dist/skeleton.css';

import './assets/css/style.css';
import './assets/css/responsive.css';

const checkAndSetPass = () => {
  const output = window.localStorage.getItem('tempPass');
  const webPass = process.env.REACT_APP_WEB_PASSWORD;

  if (output === null && process.env.REACT_APP_WEB_PASSWORD !== 'app_login') {
    const disPass = window.prompt('Enter password:');
    if (disPass === webPass) {
      window.localStorage.setItem('tempPass', webPass);
      return true;
    } else {
      return false;
    }
  }
  return true;
};

const root = ReactDOM.createRoot(document.getElementById('root'));
const errorHandler = (error, errorInfo) => {
  console.log('error:- ', error);
  console.log('errorInfo:- ', errorInfo);
};
root.render(
  // <React.StrictMode>
  <ErrorBoundary FallbackComponent={FallBack} onError={errorHandler}>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        {checkAndSetPass() ? (
          <SkeletonTheme baseColor="#E0E0E0" highlightColor="#c2c2c2">
            <App />{' '}
          </SkeletonTheme>
        ) : (
          <FallBack />
        )}
      </PersistGate>
    </Provider>
  </ErrorBoundary>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
