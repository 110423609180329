import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import PropTypes from 'prop-types';

/* ============================== SUB METHOD ============================== */
const SubMethodPopup = (props) => {
  const { showSeeMore, setShowSeeMore, showMethod } = props;

  return (
    <Modal
      show={showSeeMore}
      onHide={!showSeeMore}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      dialogClassName="cs-lg-modal">
      <Modal.Header>
        <Modal.Title className="cs-semi-bold-h3 ">Verify account investor</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="accredited-content-box cs-regular-sub-heading-m cs-neutral-90">
          {showMethod}
        </div>
      </Modal.Body>
      <Modal.Footer className="cs-modal-btn">
        <Button
          onClick={() => setShowSeeMore(false)}
          className="cs-btn-secondary md-btn cs-regular-h5">
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

// PROPS TYPE
SubMethodPopup.propTypes = {
  showSeeMore: PropTypes.bool,
  setShowSeeMore: PropTypes.func,
  showMethod: PropTypes.element
};

export default SubMethodPopup;
