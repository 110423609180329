import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

// COMPONENT IMPORTS
import axios from '../config/api';
import axiosFormData from '../config/apiFormData';

export const signatureRequestAdd = createAsyncThunk('request/signatureRequestAdd', async (data) => {
  // const { formData } = data
  return await axios.post(`sign/add_signature`, data);
});

export const signatureUploadFiles = createAsyncThunk(
  'request/signatureUploadFiles',
  async (data) => {
    const { formData } = data;
    return await axiosFormData.post(`sign/upload_media`, formData);
  }
);

export const signatureGetFiles = createAsyncThunk('request/signatureGetFiles', async (data) => {
  return await axios.get(`sign/media`, {
    params: data
  });
});

export const signatureDeleteFiles = createAsyncThunk(
  'request/signatureDeleteFiles',
  async (data) => {
    return await axios.delete(`sign/media`, { data });
  }
);

export const signatureGetPFSLink = createAsyncThunk('request/signatureGetPFSLink', async (data) => {
  return await axios.get(`sign/get_pfs`, {
    params: data
  });
});

export const pendingSignatureList = createAsyncThunk(
  'request/pendingSignatureList',
  async (data) => {
    const { user_id } = data;
    return await axios.get(`/request/signature_list/${user_id}`, {
      params: {
        listType: 'pending'
      }
    });
  }
);

export const completedSignatureList = createAsyncThunk(
  'request/completedSignatureList',
  async (data) => {
    const { user_id } = data;
    return await axios.get(`/request/signature_list/${user_id}`, {
      params: {
        listType: 'completed'
      }
    });
  }
);

// Share Completed Signature
export const shareCompletedSignature = createAsyncThunk('shareCompletedSignature', async (body) => {
  return await axios.post('sign/share', body);
});

// Signature Reminder
export const signatureReminder = createAsyncThunk('signatureReminder', async (params) => {
  return await axios.post('sign/reminder', params);
});

// Cancel Signature
export const cancelSignature = createAsyncThunk('cancelSignature', async (params) => {
  return await axios.post('sign/cancel', params);
});

// account access request
export const accountAccessApproveDecline = createAsyncThunk(
  'request/accountAccessApproveDecline',
  async (data) => {
    const { pfsId, payload } = data;
    const res = await axios.put(`client/access/permission/${pfsId}`, payload);
    return res;
  }
);

export const generateSignatureUrl = createAsyncThunk('generateSignatureUrl', async (params) => {
  return await axios.post('sign/generate_signature_url', params);
});

// INITIAL STATE
const initialState = {
  pendingSignatureRequest: {},
  completedSignatureRequest: {}
};

function sortedSignatureToList(data) {
  const newArr = [...data];
  for (let i = 0; i < newArr.length; i++) {
    const element = newArr[i];
    const store = {
      storeFirstUser: [],
      storeNonFirstUser: []
    };
    element.signature_users.forEach((itr) => {
      if (itr.is_first_user) {
        store.storeFirstUser.push(itr);
      } else {
        store.storeNonFirstUser.push(itr);
      }
    });
    newArr[i] = {
      ...element,
      signature_users: [...store.storeFirstUser, ...store.storeNonFirstUser]
    };
  }
  return newArr;
}

/* ============================== REQUEST SLICE ============================== */
export const requestSlice = createSlice({
  name: 'request',
  initialState,
  reducers: {
    resetRequestSlice: () => initialState
  },
  extraReducers: (builder) => {
    builder
      .addCase(pendingSignatureList.fulfilled, (state, action) => {
        action.payload.data.signatureToList = sortedSignatureToList(
          action.payload.data.signatureToList
        );
        state.pendingSignatureRequest = action.payload.data;
      })
      .addCase(completedSignatureList.fulfilled, (state, action) => {
        action.payload.data.signatureToList = sortedSignatureToList(
          action.payload.data.signatureToList
        );
        state.completedSignatureRequest = action.payload.data;
      });
  }
});

export const { resetRequestSlice } = requestSlice.actions;
export default requestSlice.reducer;
