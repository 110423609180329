import React from 'react';
import PropTypes from 'prop-types';
import { Dropdown, Form } from 'react-bootstrap';

// COMPONENT IMPORTS
import CapsyncIcon from './CapsyncIcon';
import { CATEGORY } from '../constants/addAccount';

/* ============================== CATEGORY DROPDOWN ============================== */
const ChooseCategoryDropdown = ({ selectedCategory, label = '', list = [], setAddAccountStep }) => {
  return (
    <Form.Group className="cs-form-dropdown">
      <Form.Label>{label}</Form.Label>
      <Dropdown>
        <Dropdown.Toggle>
          {`Choose ${selectedCategory === CATEGORY.ASSETS ? 'asset' : 'liability'} class`}
          <span className="cs-neutral-90 cs-dropdown-icon">
            <CapsyncIcon title="chevron-down-outlined" size="18" />
          </span>
        </Dropdown.Toggle>
        <Dropdown.Menu className="cs-scroller">
          {list.map((DATA) => (
            <Dropdown.Item key={DATA.KEY_NAME} onClick={() => setAddAccountStep(DATA.KEY_NAME)}>
              {DATA.TITLE}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    </Form.Group>
  );
};

// PROPS TYPE
ChooseCategoryDropdown.propTypes = {
  label: PropTypes.string,
  selectedCategory: PropTypes.string,
  list: PropTypes.array,
  setAddAccountStep: PropTypes.func
};

export default ChooseCategoryDropdown;
