import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';

/* ============================== TEXTAREA BOX ============================== */
const TextAreaBox = ({
  label,
  name,
  values,
  onChange,
  disabled = false,
  errors,
  touched,
  placeholder = '',
  isManual = false,
  ...props
}) => {
  return (
    <Form.Group className={`cs-form-group cs-form-textarea${disabled ? ' cs-disabled' : ''}`}>
      {props.methodField && props.fieldPrimaryLabel && (
        <Form.Label>
          <span className="cs-regular-sub-heading-m cs-neutral-90">{props.fieldPrimaryLabel}</span>{' '}
          {props.highlightedInLineLabel && (
            <span className="cs-regular-sub-heading-m cs-danger">
              {props.highlightedInLineLabel}
            </span>
          )}
        </Form.Label>
      )}
      {label && <Form.Label>{label}</Form.Label>}
      <textarea
        autoComplete="off"
        type="text"
        name={name}
        value={values[name]}
        className={values[name] ? 'cs-textarea cs-input-field-active' : 'cs-textarea'}
        onChange={onChange}
        disabled={disabled}
        placeholder={placeholder}></textarea>
      {!isManual && errors && touched && errors[name] && touched[name] && (
        <span className="form-error-msg cs-light-body-text-s cs-danger">{errors[name]}</span>
      )}
      {isManual && errors && errors[name] && (
        <span className="form-error-msg cs-light-body-text-s cs-danger">{errors[name]}</span>
      )}
    </Form.Group>
  );
};

// PROPS TYPE
TextAreaBox.propTypes = {
  name: PropTypes.string,
  values: PropTypes.object,
  errors: PropTypes.object,
  onChange: PropTypes.func,
  label: PropTypes.string,
  touched: PropTypes.object,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  highlightedInLineLabel: PropTypes.string,
  methodField: PropTypes.bool,
  isManual: PropTypes.bool,
  fieldPrimaryLabel: PropTypes.string
};

export default TextAreaBox;
