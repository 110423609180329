import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Form, Row } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { useFormik } from 'formik';
import PropTypes from 'prop-types';
import moment from 'moment';

// COMPONENT IMPORTS
import Modal from '../../../components/Modal';
import { TOAST_MESSAGE } from '../../../constants/message';
import InputBox from '../../../components/FormFields/InputBox';
import validationObj from '../../../validations/validationSchema';
import TextAreaBox from '../../../components/FormFields/TextAreaBox';
import DatePickerBox from '../../../components/FormFields/DatePickerBox';
import InputNumberField from '../../../components/FormFields/InputNumberField';
import SaveORCancelButtons from '../../../components/Buttons/SaveORCancelButtons';
import CategoryFileUpload from '../../../components/FormFields/CategoryFileUpload';
import { preventNegativeNumbers, preventSpecialCharacters } from '../../../utils/common';

// API
import {
  categoryAddRecords,
  categoryUpdateRecords,
  getLiabilitiesCategory,
  useCategoryDetail
} from '../../../slices/categorySlice';
import { useProgressUploadDetails } from '../../../slices/commonSlice';
import { useStorageDetails } from '../../../slices/fileManagementSlice';
import { getLiabilitiesDashboard, getMainDashboard } from '../../../slices/dashboardSlice';

/* ============================== OTHER LIABILITIES ============================== */
const OtherLiabilities = ({ isOpen = false, handleCancel, handleCategoryFormSubmit, record }) => {
  const dispatch = useDispatch();

  const { user } = useSelector((state) => state.auth);
  const progressUploadDetails = useProgressUploadDetails();
  const storageDetails = useStorageDetails();
  const categories = useCategoryDetail();
  const categoryId = categories?.category_id;

  const [loading, setLoading] = useState(false);
  const [initialValues, setInitialValues] = useState({
    user_id: user?.id,
    description: '',
    balance_due: '',
    date_updated: moment().format(),
    notes: '',
    attachments: []
  });

  useEffect(() => {
    if (record) {
      setInitialValues({
        user_id: record.user_id,
        description: record.description,
        balance_due: record.balance_due && record.balance_due.toLocaleString('en-US'),
        date_updated: new Date(moment(record.date_updated).format('MM/DD/YYYY')),
        notes: record.notes == null ? '' : record.notes
      });
    }
  }, [record]);

  const {
    values,
    handleSubmit,
    handleChange,
    setFieldValue,
    errors,
    touched,
    setFieldError,
    resetForm
  } = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: validationObj.otherLiabilitiesValidationSchema,
    onSubmit: async (values) => {
      const formData = new FormData();
      const uploadedData = storageDetails?.used_space;
      const inProgressData = progressUploadDetails.length
        ? progressUploadDetails.reduce((acc, item) => acc + item.size, 0)
        : 0;

      const { attachments, ...dataObj } = values;
      !record && attachments.forEach((file) => formData.append('attachments', file));
      formData.append('data_obj', JSON.stringify(dataObj));
      formData.append('usedSpace', uploadedData + inProgressData);

      const data = {
        category: 6,
        mainCategory: 'liability',
        formData: formData
      };

      setLoading(true);
      if (!record) {
        dispatch(categoryAddRecords(data))
          .unwrap()
          .then(async (response) => {
            if (response.code === 500) {
              setFieldError('attachments', response?.message);
            }
            if (response.code === 200) {
              handleCategoryFormSubmit();
              dispatch(getMainDashboard(user?.id));
              const payload = { categoryId: categoryId, userId: user?.id };
              categories.parent === 'liabilities' &&
                (await dispatch(getLiabilitiesCategory(payload)).unwrap());
              await dispatch(getLiabilitiesDashboard({ userId: user?.id, orderId: 0 })).unwrap();
            }
          })
          .catch((err) => {})
          .finally(() => {
            setLoading(false);
          });
      } else {
        dispatch(
          categoryUpdateRecords({
            category: 6,
            mainCategory: 'liability',
            rowId: record && record?.id,
            values
          })
        )
          .unwrap()
          .then(async (response) => {
            if (response.code === 200) {
              handleCategoryFormSubmit();
              toast.success(TOAST_MESSAGE.RECORD_UPDATE_SUCCESS);
              dispatch(getMainDashboard(user?.id));
              const payload = { categoryId: categoryId, userId: user?.id };
              categories.parent === 'liabilities' &&
                (await dispatch(getLiabilitiesCategory(payload)).unwrap());
              await dispatch(getMainDashboard(user?.id)).unwrap();
            }
          })
          .catch((err) => {})
          .finally(() => {
            setLoading(false);
          });
      }
    }
  });

  const body = (
    <Form onSubmit={handleSubmit} encType="multipart/form-data">
      <Row>
        <Col lg={12} sm={12}>
          <InputBox
            label={'Description'}
            name={'description'}
            values={values}
            onChange={handleChange}
            errors={errors}
            touched={touched}
            type={'text'}
            onKeyDown={preventSpecialCharacters}
            disabled={record && record.is_manual === false}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={6}>
          <InputNumberField
            label="Balance Due"
            name="balance_due"
            values={values}
            errors={errors}
            handleChange={handleChange}
            touched={touched}
            customActiveClass="dollar-symbol text-md-end form-control"
            onPaste={preventNegativeNumbers}
            thousandSeparator={true}
          />
        </Col>
        <Col xs={12} md={6}>
          <DatePickerBox
            label={'Date Updated'}
            errors={errors}
            name={'date_updated'}
            values={values}
            setFieldValue={setFieldValue}
          />
        </Col>
      </Row>
      <React.Fragment>
        <Row>
          <Col xs={12} md={12}>
            <TextAreaBox
              handleChange={handleChange}
              name={'notes'}
              label={'Notes'}
              values={values}
              errors={errors}
              onChange={handleChange}
              placeholder={'Optional'}
            />
          </Col>
        </Row>

        {!record && (
          <CategoryFileUpload
            name={'attachments'}
            values={values}
            errors={errors}
            setFieldError={setFieldError}
            setFieldValue={setFieldValue}
          />
        )}

        <SaveORCancelButtons handleCancel={handleCancel} resetForm={resetForm} loading={loading} />
      </React.Fragment>
    </Form>
  );

  return <Modal show={isOpen} title={'Other Liabilities'} body={body} isCloseButton={false} />;
};

// PROPS TYPE
OtherLiabilities.propTypes = {
  isOpen: PropTypes.bool,
  record: PropTypes.object,
  handleCancel: PropTypes.func.isRequired,
  handleCategoryFormSubmit: PropTypes.func.isRequired
};

export default OtherLiabilities;
