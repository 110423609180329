import React, { useEffect, useState } from 'react';
import { Form, Row, Col, Button, Dropdown } from 'react-bootstrap';
import PropTypes from 'prop-types';

// COMPONENT IMPORTS
import CapsyncIcon from '../../../components/CapsyncIcon';
import Modal from '../../../components/Modal';

/* ============================== VIN CAR MODAL ============================== */
const VinCarModal = (props) => {
  const { onClickContinue, onClickCancel, marketData, show } = props;

  const [carCondition, setCarCondition] = useState('');
  const [carTrim, setCarTrim] = useState('');
  const [carReturnObj, setCarReturnObj] = useState({ trim: '', condition: '', market_value: 0 });
  const [isContinue, setIsContinue] = useState(true);

  useEffect(() => {
    return () => {
      setCarReturnObj({ trim: '', condition: '', market_value: 0 });
    };
  }, []);

  useEffect(() => {
    if (carCondition !== '' && carTrim !== '') {
      setIsContinue(false);
    }
  }, [carCondition, carTrim]);

  useEffect(() => {
    setCarCondition('');
    setCarTrim('');
    setIsContinue(true);
  }, [show]);

  const handleChange = (event, type) => {
    if (type === 'condition') {
      setCarReturnObj((prevState) => ({
        ...prevState,
        market_value: event['Dealer Retail'].slice(1),
        condition: event.Condition
      }));
      setCarCondition(event.Condition);
    } else {
      setCarReturnObj((prevState) => ({
        ...prevState,
        trim: event
      }));
      setCarTrim(event);
    }

    if (carCondition !== '' && carTrim !== '') {
      setIsContinue(false);
    }
  };

  const handleContinue = () => {
    onClickContinue(carReturnObj);
  };

  let conditionsListArr = marketData.length == 1 ? marketData[0]['market value'] : [];
  const marketValueList = marketData.find((market, index) => carTrim === market.trim);
  if (marketValueList) {
    conditionsListArr = marketValueList['market value'];
  }
  const bodyElement = () => {
    return (
      <React.Fragment>
        <Row>
          <Col lg={12}>
            <Form.Group className="cs-form-dropdown">
              <Form.Label>Trim</Form.Label>

              <Dropdown className="trim-dropdown-styling">
                <Dropdown.Toggle>
                  <span className="trim-content">{carTrim === '' ? 'Select trim' : carTrim}</span>
                  <span className="cs-neutral-90">
                    <CapsyncIcon title="chevron-down-outlined" size="18" />
                  </span>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {marketData.map((trimItr) => (
                    <Dropdown.Item
                      key={trimItr.trim}
                      className={
                        trimItr.trim == carTrim
                          ? 'cs-selected-dropdown '
                          : 'cs-stroke-default-tab cs-tabs-height'
                      }
                      onClick={() => handleChange(trimItr.trim, 'trim')}>
                      {trimItr.trim}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            </Form.Group>
          </Col>
          <Col lg={12}>
            <Form.Group className="cs-form-dropdown last-condition-field">
              <Form.Label>Condition</Form.Label>
              <Dropdown>
                <Dropdown.Toggle>
                  {carCondition === '' ? 'Select condition' : carCondition}
                  <span className="cs-neutral-90">
                    <CapsyncIcon title="chevron-down-outlined" size="18" />
                  </span>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {conditionsListArr.length !== 0 &&
                    conditionsListArr.map((value, index) => (
                      <Dropdown.Item
                        key={index}
                        onClick={() => handleChange(value, 'condition')}
                        className={
                          value.Condition == carCondition
                            ? 'cs-selected-dropdown'
                            : 'cs-regular-sub-heading-s'
                        }>
                        {value.Condition}
                      </Dropdown.Item>
                    ))}
                </Dropdown.Menu>
              </Dropdown>
            </Form.Group>
          </Col>
        </Row>
        <div className="cs-modal-btn">
          <Button className="cs-btn-secondary md-btn cs-regular-h5" onClick={() => onClickCancel()}>
            Cancel
          </Button>
          <Button
            className="cs-btn-primary md-btn cs-regular-h5"
            onClick={handleContinue}
            disabled={isContinue}>
            Continue
          </Button>
        </div>
      </React.Fragment>
    );
  };
  return (
    <Modal
      show={show}
      isCloseButton={false}
      parentClassName="cs-common-modal-overlay"
      title="Select car's TRIM and condition"
      body={bodyElement()}
      centered
      className="cs-md-modal"
    />
  );
};

// PROPS TYPE
VinCarModal.propTypes = {
  show: PropTypes.bool,
  onClickCancel: PropTypes.func,
  onClickContinue: PropTypes.func,
  marketData: PropTypes.array
};

export default VinCarModal;
