import React, { useEffect, useRef, useState } from 'react';
import { usePlaidLink } from 'react-plaid-link';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

// COMPONENT IMPORTS
import AccountListModal from './AccountListModal';
import AddAccountMainModal from './AddAccountMainModal';
import Savings from '../../assets/categoryForms/Savings';
import AddManualAccountModal from './AddManualAccountModal';
import RealEstate from '../../assets/categoryForms/RealEstate';
import Automobile from '../../assets/categoryForms/Automobile';
import Businesses from '../../assets/categoryForms/Businesses';
import OtherAsset from '../../assets/categoryForms/OtherAsset';
import CashOnHands from '../../assets/categoryForms/CashOnHands';
import { ADD_ACCOUNT_MODALS } from '../../../constants/addAccount';
import LifeInsurance from '../../assets/categoryForms/LifeInsurance';
import UnpaidTaxes from '../../liabilities/categoryForms/UnpaidTaxes';
import NotesPayable from '../../liabilities/categoryForms/NotesPayable';
import NotesReceivable from '../../assets/categoryForms/NotesReceivable';
import OtherRetirement from '../../assets/categoryForms/OtherRetirement';
import AccountLinked from '../../liabilities/categoryForms/AccountLinked';
import PlaidUpdateConfirmationModal from './PlaidUpdateConfirmationModal';
import PersonalProperty from '../../assets/categoryForms/PersonalProperty';
import AccountsPayable from '../../liabilities/categoryForms/AccountsPayable';
import OtherLiabilities from '../../liabilities/categoryForms/OtherLiabilities';
import { addAccountsSuccess, handlePlaidAccountUpdate } from './PlaidCommonFunctions';
import NonRetirementBrokerage from '../../assets/categoryForms/NonRetirementBrokerage';
import MortgagesOnRealEstate from '../../liabilities/categoryForms/MortgagesOnRealEstate';
import InstallmentAccountAuto from '../../liabilities/categoryForms/InstallmentAccountAuto';
import InstallmentAccountOther from '../../liabilities/categoryForms/InstallmentAccountOther';
import LoanAgainstLifeInsurance from '../../liabilities/categoryForms/LoanAgainstLifeInsurance';

// API
import {
  handleLoader,
  handlePlaidToken,
  resetInstituteData,
  resetLoader,
  useToken
} from '../../../slices/plaidSlice';
import {
  getAssetsDashboard,
  getLiabilitiesDashboard,
  getMainDashboard
} from '../../../slices/dashboardSlice';
import {
  getAssetsCategory,
  getLiabilitiesCategory,
  useCategoryDetail
} from '../../../slices/categorySlice';
import { useUserID } from '../../../slices/authSlice';

/* ============================== ADD ACCOUNT MODAL ============================== */
const AddAccountModal = ({
  setIsAccountModelOpen,
  setShowLogoutModal,
  isAddAccountFlowClosable = true,
  insId = null
}) => {
  const [addAccountStep, setAddAccountStep] = useState(ADD_ACCOUNT_MODALS.MAIN_MODAL);
  const [isMainModalOpen, setIsMainModalOpen] = useState(true);
  const [isLoader, setIsLoader] = useState(false);
  const handleCategoryFormCancel = () =>
    setAddAccountStep(ADD_ACCOUNT_MODALS.ADD_MANUAL_ACCOUNT_MODAL);
  const handleCategoryFormSubmit = () => {
    dispatch(handlePlaidToken(''));
    if (insId) {
      setIsAccountModelOpen('');
    } else {
      setAddAccountStep(ADD_ACCOUNT_MODALS.ACCOUNT_LINKED);
    }
    setIsLoader(false);
  };

  const token = useToken();
  const dispatch = useDispatch();
  const btnClick = useRef(null);
  const updateModeRef = useRef(null);
  const categories = useCategoryDetail();
  const categoryId = categories?.category_id;

  const userId = useUserID();

  const [accounts, setAccounts] = useState([]);

  const config = {
    token: token,
    onSuccess: async (public_token, metadata) => {
      setIsLoader(true);
      const response = await addAccountsSuccess(
        dispatch,
        public_token,
        metadata,
        setAddAccountStep,
        updateModeRef,
        userId,
        setIsMainModalOpen
      );
      if (response.code === 200) {
        setAccounts(response.data.accounts);
        setAddAccountStep(ADD_ACCOUNT_MODALS.ACCOUNT_LIST_MODAL);
        const payload = { categoryId: categoryId, userId: userId };
        dispatch(getMainDashboard(userId));
        if (categories.parent === 'assets') {
          await dispatch(getAssetsCategory(payload)).unwrap();
        } else if (categories.parent === 'liabilities') {
          await dispatch(getLiabilitiesCategory(payload)).unwrap();
        }
        await dispatch(getAssetsDashboard({ userId: userId, orderId: 0 })).unwrap();
        await dispatch(getLiabilitiesDashboard({ userId: userId, orderId: 0 })).unwrap();
      }
    },
    onExit: (err, metadata) => {
      console.log('onExit err', err);
      console.log('onExit metadata', metadata);
      setIsMainModalOpen(true);
      if (insId) {
        setIsAccountModelOpen('');
      } else {
        setAddAccountStep(ADD_ACCOUNT_MODALS.MAIN_MODAL);
      }
    }
  };
  const { open, ready } = usePlaidLink(config);
  const onHandleLinkAccountModelOpen = async () => {
    setIsMainModalOpen(false);
    await open();
    setIsLoader(false);
  };

  useEffect(() => {
    dispatch(resetInstituteData());
    dispatch(resetLoader());
    dispatch(handlePlaidToken(''));

    if (insId) {
      setIsMainModalOpen(false);
      setIsLoader(true);
      handleSyncErrorAccount();
    }
  }, []);

  const handleSyncErrorAccount = async () => {
    await dispatch(handleLoader({ updateContinueLoader: true }));
    handlePlaidAccountUpdate(dispatch, insId, userId, btnClick, updateModeRef, true);
  };

  const handleComponent = () => {
    switch (addAccountStep) {
      case ADD_ACCOUNT_MODALS.MAIN_MODAL:
        return (
          <AddAccountMainModal
            setAddAccountStep={setAddAccountStep}
            isOpen={insId ? false : isMainModalOpen}
            setIsAccountModelOpen={setIsAccountModelOpen}
            isAddAccountFlowClosable={isAddAccountFlowClosable}
            onHandleLinkAccountModelOpen={onHandleLinkAccountModelOpen}
            plaidReady={ready}
            isLoader={isLoader}
            setShowLogoutModal={setShowLogoutModal}
          />
        );
      case ADD_ACCOUNT_MODALS.UPDATE_MODAL:
        return (
          <PlaidUpdateConfirmationModal
            setAddAccountStep={setAddAccountStep}
            setIsAccountModelOpen={setIsAccountModelOpen}
            isOpen={isMainModalOpen}
            btnClick={btnClick}
            updateModeRef={updateModeRef}
          />
        );
      case ADD_ACCOUNT_MODALS.ACCOUNT_LIST_MODAL:
        return (
          <AccountListModal
            accounts={accounts}
            isOpen={addAccountStep === ADD_ACCOUNT_MODALS.ACCOUNT_LIST_MODAL}
            setAddAccountStep={setAddAccountStep}
            setIsAccountModelOpen={setIsAccountModelOpen}
            handleCategoryFormSubmit={handleCategoryFormSubmit}
          />
        );

      // ----------------------------------------------------------------------------
      case ADD_ACCOUNT_MODALS.ADD_MANUAL_ACCOUNT_MODAL:
        return (
          <AddManualAccountModal
            isOpen={addAccountStep === ADD_ACCOUNT_MODALS.ADD_MANUAL_ACCOUNT_MODAL}
            setAddAccountStep={setAddAccountStep}
            setIsAccountModelOpen={setIsAccountModelOpen}
            isAddAccountFlowClosable={isAddAccountFlowClosable}
          />
        );
      case ADD_ACCOUNT_MODALS.CASH_ON_HANDS:
        return (
          <CashOnHands
            isOpen={addAccountStep === ADD_ACCOUNT_MODALS.CASH_ON_HANDS}
            handleCancel={handleCategoryFormCancel}
            handleCategoryFormSubmit={handleCategoryFormSubmit}
          />
        );
      case ADD_ACCOUNT_MODALS.SAVINGS:
        return (
          <Savings
            isOpen={addAccountStep === ADD_ACCOUNT_MODALS.SAVINGS}
            handleCancel={handleCategoryFormCancel}
            handleCategoryFormSubmit={handleCategoryFormSubmit}
          />
        );
      case ADD_ACCOUNT_MODALS.OTHER_RETIREMENT:
        return (
          <OtherRetirement
            isOpen={addAccountStep === ADD_ACCOUNT_MODALS.OTHER_RETIREMENT}
            handleCancel={handleCategoryFormCancel}
            handleCategoryFormSubmit={handleCategoryFormSubmit}
          />
        );
      case ADD_ACCOUNT_MODALS.ACCOUNT_NOTES_RECEIVABLES:
        return (
          <NotesReceivable
            isOpen={addAccountStep === ADD_ACCOUNT_MODALS.ACCOUNT_NOTES_RECEIVABLES}
            handleCancel={handleCategoryFormCancel}
            handleCategoryFormSubmit={handleCategoryFormSubmit}
          />
        );
      case ADD_ACCOUNT_MODALS.LIFE_INSURANCE:
        return (
          <LifeInsurance
            isOpen={addAccountStep === ADD_ACCOUNT_MODALS.LIFE_INSURANCE}
            handleCancel={handleCategoryFormCancel}
            handleCategoryFormSubmit={handleCategoryFormSubmit}
          />
        );
      case ADD_ACCOUNT_MODALS.NON_RETIREMENT_BROKERAGE:
        return (
          <NonRetirementBrokerage
            isOpen={addAccountStep === ADD_ACCOUNT_MODALS.NON_RETIREMENT_BROKERAGE}
            handleCancel={handleCategoryFormCancel}
            handleCategoryFormSubmit={handleCategoryFormSubmit}
          />
        );
      case ADD_ACCOUNT_MODALS.REAL_ESTATE:
        return (
          <RealEstate
            isOpen={addAccountStep === ADD_ACCOUNT_MODALS.REAL_ESTATE}
            handleCancel={handleCategoryFormCancel}
            handleCategoryFormSubmit={handleCategoryFormSubmit}
          />
        );
      case ADD_ACCOUNT_MODALS.AUTOMOBILES:
        return (
          <Automobile
            isOpen={addAccountStep === ADD_ACCOUNT_MODALS.AUTOMOBILES}
            handleCancel={handleCategoryFormCancel}
            handleCategoryFormSubmit={handleCategoryFormSubmit}
          />
        );
      case ADD_ACCOUNT_MODALS.BUSINESSES:
        return (
          <Businesses
            isOpen={addAccountStep === ADD_ACCOUNT_MODALS.BUSINESSES}
            handleCancel={handleCategoryFormCancel}
            handleCategoryFormSubmit={handleCategoryFormSubmit}
          />
        );
      case ADD_ACCOUNT_MODALS.OTHER_PERSONAL_PROPERTY:
        return (
          <PersonalProperty
            isOpen={addAccountStep === ADD_ACCOUNT_MODALS.OTHER_PERSONAL_PROPERTY}
            handleCancel={handleCategoryFormCancel}
            handleCategoryFormSubmit={handleCategoryFormSubmit}
          />
        );
      case ADD_ACCOUNT_MODALS.OTHER_ASSETS:
        return (
          <OtherAsset
            isOpen={addAccountStep === ADD_ACCOUNT_MODALS.OTHER_ASSETS}
            handleCancel={handleCategoryFormCancel}
            handleCategoryFormSubmit={handleCategoryFormSubmit}
          />
        );

      // ----------------------------------------------------------------------------
      case ADD_ACCOUNT_MODALS.ACCOUNT_PAYABLE:
        return (
          <AccountsPayable
            isOpen={addAccountStep === ADD_ACCOUNT_MODALS.ACCOUNT_PAYABLE}
            handleCancel={handleCategoryFormCancel}
            handleCategoryFormSubmit={handleCategoryFormSubmit}
          />
        );
      case ADD_ACCOUNT_MODALS.NOTES_PAYABLE:
        return (
          <NotesPayable
            isOpen={addAccountStep === ADD_ACCOUNT_MODALS.NOTES_PAYABLE}
            handleCancel={handleCategoryFormCancel}
            handleCategoryFormSubmit={handleCategoryFormSubmit}
          />
        );
      case ADD_ACCOUNT_MODALS.INSTALLMENT_ACCOUNT_AUTO:
        return (
          <InstallmentAccountAuto
            isOpen={addAccountStep === ADD_ACCOUNT_MODALS.INSTALLMENT_ACCOUNT_AUTO}
            handleCancel={handleCategoryFormCancel}
            handleCategoryFormSubmit={handleCategoryFormSubmit}
          />
        );
      case ADD_ACCOUNT_MODALS.INSTALLMENT_ACCOUNT_OTHER:
        return (
          <InstallmentAccountOther
            isOpen={addAccountStep === ADD_ACCOUNT_MODALS.INSTALLMENT_ACCOUNT_OTHER}
            handleCancel={handleCategoryFormCancel}
            handleCategoryFormSubmit={handleCategoryFormSubmit}
          />
        );
      case ADD_ACCOUNT_MODALS.LOAN_AGAINST_LIFE_INSURANCE:
        return (
          <LoanAgainstLifeInsurance
            isOpen={addAccountStep === ADD_ACCOUNT_MODALS.LOAN_AGAINST_LIFE_INSURANCE}
            handleCancel={handleCategoryFormCancel}
            handleCategoryFormSubmit={handleCategoryFormSubmit}
          />
        );
      case ADD_ACCOUNT_MODALS.MORTGAGES_ON_REAL_ESTATE:
        return (
          <MortgagesOnRealEstate
            isOpen={addAccountStep === ADD_ACCOUNT_MODALS.MORTGAGES_ON_REAL_ESTATE}
            handleCancel={handleCategoryFormCancel}
            handleCategoryFormSubmit={handleCategoryFormSubmit}
          />
        );
      case ADD_ACCOUNT_MODALS.UNPAID_TAXES:
        return (
          <UnpaidTaxes
            isOpen={addAccountStep === ADD_ACCOUNT_MODALS.UNPAID_TAXES}
            handleCancel={handleCategoryFormCancel}
            handleCategoryFormSubmit={handleCategoryFormSubmit}
          />
        );
      case ADD_ACCOUNT_MODALS.OTHER_LIABILITIES:
        return (
          <OtherLiabilities
            isOpen={addAccountStep === ADD_ACCOUNT_MODALS.OTHER_LIABILITIES}
            handleCancel={handleCategoryFormCancel}
            handleCategoryFormSubmit={handleCategoryFormSubmit}
          />
        );
      case ADD_ACCOUNT_MODALS.ACCOUNT_LINKED:
        return (
          <AccountLinked
            isOpen={addAccountStep === ADD_ACCOUNT_MODALS.ACCOUNT_LINKED}
            handleNotNow={() => setIsAccountModelOpen('')}
            handleLinkAccountClick={() => {
              setIsMainModalOpen(true);
              setAddAccountStep(ADD_ACCOUNT_MODALS.MAIN_MODAL);
            }}
          />
        );
    }
  };
  return (
    <React.Fragment>
      <button style={{ display: 'none' }} ref={btnClick} onClick={onHandleLinkAccountModelOpen} />
      {handleComponent()}
    </React.Fragment>
  );
};

// PROPS TYPE
AddAccountModal.propTypes = {
  setIsAccountModelOpen: PropTypes.func,
  setShowLogoutModal: PropTypes.func,
  isAddAccountFlowClosable: PropTypes.bool,
  insId: PropTypes.string
};

export default AddAccountModal;
