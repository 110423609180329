import React from 'react';
import PropTypes from 'prop-types';
import { Image, Container, Row, Col, Button } from 'react-bootstrap';

// COMPONENT IMPORTS
import CapsyncLogo from '../assets/images/brand/capsync-logo.svg';

/* ============================== FALL BACK ============================== */
const FallBack = ({ error }) => {
  const redirectToSignIn = () => {
    window.location.href = '/';
  };

  return (
    <Container>
      <div className="page404">
        <Row>
          <Col lg={12}>
            <div className="error-container">
              <Image
                width="132"
                src={CapsyncLogo}
                className="capsync-logo"
                alt="Capsync Logo"
                onClick={redirectToSignIn}
              />
              <div className="error-image">
                <Image src={require('../assets/images/404-image.png')} className="pagenotfound" />
              </div>
              <div className="error-content">
                <h1 className="cs-semi-bold-h1 cs-neutral-100">Page Not Found</h1>
                <p className="cs-medium-sub-heading-l cs-neutral-80">
                  We're sorry, the page you requested could not be found please go back to the
                  homepage
                </p>
                <span className="back-to-sign-in">
                  <Button
                    onClick={redirectToSignIn}
                    className="cs-btn-tertiary lg-btn cs-regular-h5">
                    Back to Sign In
                  </Button>
                </span>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </Container>
  );
};

// PROPS TYPE
FallBack.propTypes = {
  error: PropTypes.object
};

export default FallBack;
