import React from 'react';
import PropTypes from 'prop-types';
import { Button, Modal, ModalFooter } from 'react-bootstrap';

// COMPONENT IMPORTS
import CapsyncIcon from '../../components/CapsyncIcon';

/* ============================== CONFIRM EDIT PAGE ============================== */
const ConfirmEditProfile = ({ showConfirmModal, setShowConfirmModal, setMfaOptionModal }) => {
  const handleSubmit = () => {
    setShowConfirmModal(false);
    setMfaOptionModal(true);
  };

  return (
    <Modal
      id="confirm-edit-profile"
      show={showConfirmModal}
      onHide={() => setShowConfirmModal(false)}
      backdrop="static"
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      dialogClassName="cs-md-modal cs-delete-modal">
      <Modal.Header>
        <Modal.Title className="cs-semi-bold-h3 cs-neutral-100">Are you Sure?</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="delete-modal-content">
          <span className="cs-icon">
            <CapsyncIcon title="error-validation" size="100" />
          </span>
          <p className="cs-light-body-text-m cs-neutral-90">
            Are you sure you want to update phone number?
          </p>
        </div>
      </Modal.Body>
      <ModalFooter className="cs-modal-btn cs-center-btn">
        <Button
          className="cs-btn-secondary md-btn cs-regular-h5 btn btn-primary"
          type="button"
          onClick={() => {
            setShowConfirmModal(false);
          }}>
          Cancel
        </Button>
        <Button
          type="submit"
          className="cs-btn-primary md-btn cs-regular-h5 btn btn-primary"
          onClick={handleSubmit}>
          Continue
        </Button>
      </ModalFooter>
    </Modal>
  );
};

// PROPS TYPE
ConfirmEditProfile.propTypes = {
  showConfirmModal: PropTypes.bool,
  setMfaOptionModal: PropTypes.func.isRequired,
  setShowConfirmModal: PropTypes.func.isRequired
};

export default ConfirmEditProfile;
