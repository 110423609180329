import React from 'react';
import PropTypes from 'prop-types';
import { isMobile } from 'react-device-detect';
import { Dropdown, DropdownButton, NavDropdown } from 'react-bootstrap';

// COMPONENT IMPORTS
import CapsyncIcon from '../../components/CapsyncIcon';

// API
import { useToUserPermission } from '../../slices/authSlice';

/* ============================== ACTION ICON ============================== */
const ActionIcons = (props) => {
  const {
    selectedRecords = [],
    moduleName,
    value,
    handleMove,
    parentId = null,
    isGridOptions = false,
    openDropdownId = null,
    setOpenDropdownId,
    selectedId,
    setSelectedId,
    handleDelete,
    handleFileDownload,
    // handleProtectedFileDownload,
    handleArchive,
    handleRenameItem
  } = props;

  const userPermission = useToUserPermission();

  const checkIsIconVisible = (key, checkViewOnlyPermission = false) => {
    const isKeyPresent = Boolean(value[key]);

    if (key === 'is_downloadable' && userPermission?.permission_type === 'view') {
      return isKeyPresent;
    }

    const hasPermission = checkViewOnlyPermission
      ? userPermission?.permission_type !== 'view'
      : true;

    return isKeyPresent && hasPermission;
  };

  const setProtectedDownloadOutside = (key) => {
    const isValidParentId = moduleName === 'My Documents' ? parentId !== null : true;
    return (
      (userPermission?.permission_type === 'view' || !value.is_archive_allowed) &&
      Boolean(value[key]) &&
      isValidParentId
    );
  };

  const checkAllPermissions = () => {
    return (
      value.is_downloadable ||
      value.is_movable ||
      value.is_archive_allowed ||
      value.is_delete_allowed ||
      value.is_rename_allowed
    );
  };

  if (
    selectedRecords.length ||
    (moduleName !== 'My Documents' && userPermission?.permission_type === 'view')
  ) {
    return true;
  }

  return isGridOptions ? (
    checkAllPermissions() && (
      <DropdownButton
        key={value.id}
        id={`dropdown-grid-${value.id}`}
        show={openDropdownId === value.id}
        onClick={(e) => {
          e.stopPropagation();
          setOpenDropdownId((prevId) => (prevId === value.id ? null : value.id));
        }}
        title={
          <span className="cs-neutral-80 cursor-pointer">
            <CapsyncIcon title="option-horizontal-filled" size="22" />
          </span>
        }
        className="cs-settings-dropdown">
        {checkIsIconVisible('is_downloadable') && (
          <Dropdown.Item eventKey="1" onClick={() => handleFileDownload([value])}>
            <span>
              <CapsyncIcon title="download-outlined" size="22" />
            </span>
            <span>Download</span>
          </Dropdown.Item>
        )}
        {/* {checkIsIconVisible('is_downloadable') && (
          <Dropdown.Item eventKey="2" onClick={() => handleProtectedFileDownload([value])}>
            <span>
              <CapsyncIcon title="protect-download-outlined" size="22" />
            </span>
            <span>Encrypt Download</span>
          </Dropdown.Item>
        )} */}
        {checkIsIconVisible('is_rename_allowed', true) && (
          <Dropdown.Item eventKey="3" onClick={() => handleRenameItem(value)}>
            <span>
              <CapsyncIcon title="edit-image-outlined" size="22" />
            </span>
            <span>Rename</span>
          </Dropdown.Item>
        )}
        {checkIsIconVisible('is_movable', true) && (
          <Dropdown.Item eventKey="4" onClick={() => handleMove([value])}>
            <span>
              <CapsyncIcon title="move-outlined" size="22" />
            </span>
            <span>Move</span>
          </Dropdown.Item>
        )}
        {checkIsIconVisible('is_archive_allowed', true) && (
          <Dropdown.Item eventKey="5" onClick={() => handleArchive([value])}>
            <span>
              <CapsyncIcon title="archive-outlined" size="22" />
            </span>
            <span>Archive</span>
          </Dropdown.Item>
        )}
        {checkIsIconVisible('is_delete_allowed', true) && (
          <Dropdown.Item eventKey="6" onClick={() => handleDelete([value])}>
            <span>
              <CapsyncIcon title="delete-outlined" size="22" />
            </span>
            <span>Delete</span>
          </Dropdown.Item>
        )}
      </DropdownButton>
    )
  ) : (
    <div className={`cs-table-icon ${isMobile ? '' : 'fm-table-icon'}`}>
      {checkIsIconVisible('is_downloadable') && (
        <span
          className={`${isMobile ? 'cs-neutral-80-mobile' : 'cs-neutral-80'}`}
          title="Download"
          onMouseEnter={() => setSelectedId(null)}
          onClick={(e) => {
            e.stopPropagation();
            handleFileDownload([value]);
          }}>
          <CapsyncIcon title="download-outlined" size="18" />
        </span>
      )}
      {/* {setProtectedDownloadOutside('is_downloadable') && (
        <span
          className={`${isMobile ? 'cs-neutral-80-mobile' : 'cs-neutral-80'}`}
          title="Protect Download"
          onMouseEnter={() => setSelectedId(null)}
          onClick={(e) => {
            e.stopPropagation();
            handleProtectedFileDownload([value]);
          }}>
          <CapsyncIcon title="protect-download-outlined" size="18" />
        </span>
      )} */}
      {checkIsIconVisible('is_rename_allowed', true) && (
        <span
          className={`${isMobile ? 'cs-neutral-80-mobile' : 'cs-neutral-80'}`}
          title="Rename"
          onMouseEnter={() => setSelectedId(null)}
          onClick={(e) => {
            e.stopPropagation();
            handleRenameItem(value);
          }}>
          <CapsyncIcon title="edit-image-outlined" size="18" />
        </span>
      )}
      {checkIsIconVisible('is_movable', true) && (
        <span
          className={`${isMobile ? 'cs-neutral-80-mobile' : 'cs-neutral-80'}`}
          title="Move"
          onClick={(e) => {
            e.stopPropagation();
            handleMove([value]);
          }}
          onMouseEnter={() => setSelectedId(null)}>
          <CapsyncIcon title="move-outlined" size="18" />
        </span>
      )}
      {checkIsIconVisible('is_delete_allowed', true) && (
        <span
          className={`${isMobile ? 'cs-neutral-80-mobile cs-danger' : 'cs-danger'}`}
          title="Delete"
          onMouseEnter={() => setSelectedId(null)}
          onClick={(e) => {
            e.stopPropagation();
            handleDelete([value]);
          }}>
          <CapsyncIcon title="delete-outlined" size="18" />
        </span>
      )}
      {!setProtectedDownloadOutside('is_downloadable') &&
        checkIsIconVisible('is_downloadable') &&
        checkIsIconVisible('is_archive_allowed', true) && (
          <div className={`${isMobile ? 'cs-neutral-80-mobile' : 'cs-neutral-80'}`}>
            <NavDropdown
              title={
                <span className="cs-neutral-80 cursor-pointer" title="More Action">
                  <CapsyncIcon title="option-horizontal-filled" size="18" />
                </span>
              }
              className="cs-settings-dropdown"
              show={value.id === selectedId}
              onClick={(e) => {
                e.stopPropagation();
                setSelectedId((prevId) => (prevId === value.id ? null : value.id));
              }}>
              {checkIsIconVisible('is_archive_allowed', true) && (
                <NavDropdown.Item
                  title="Archive"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleArchive([value]);
                  }}>
                  <CapsyncIcon title="archive-outlined" size="22" />
                  <span className="cs-light-body-text-m drop-icon">Archive</span>
                </NavDropdown.Item>
              )}
              {/* {checkIsIconVisible('is_downloadable') && (
                <NavDropdown.Item
                  title="Protect Download"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleProtectedFileDownload([value]);
                  }}>
                  <CapsyncIcon title="protect-download-outlined" size="22" />
                  <span className="cs-light-body-text-m drop-icon">Encrypted Download</span>
                </NavDropdown.Item>
              )} */}
            </NavDropdown>
          </div>
        )}
    </div>
  );
};

// PROPS TYPE
ActionIcons.propTypes = {
  selectedRecords: PropTypes.array,
  moduleName: PropTypes.string,
  value: PropTypes.any,
  isGridOptions: PropTypes.bool,
  parentId: PropTypes.any,
  openDropdownId: PropTypes.number,
  setOpenDropdownId: PropTypes.any,
  selectedId: PropTypes.any,
  setSelectedId: PropTypes.any,
  handleDelete: PropTypes.func,
  handleMove: PropTypes.func,
  handleFileDownload: PropTypes.func,
  // handleProtectedFileDownload: PropTypes.func,
  handleArchive: PropTypes.func,
  handleRenameItem: PropTypes.func
};

export default ActionIcons;
