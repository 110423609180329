import React from 'react';
import PropTypes from 'prop-types';

// COMPONENT IMPORTS
import Avatar from '../../../components/userProfile/Avatar';

/* ============================== MULTIPLE USER DROPDOWN ============================== */
const MultipleUserDropDown = ({ itr }) => {
  return (
    <div className="multiple-user-list">
      <div className="active-user-profile">
        <div className="multiple-user-item">
          <div className="profile-image">
            <Avatar
              className={`cs-avatar-md ${itr?.image?.includes('avatar_6') ? 'cs-avatar-text' : 'cs-avatar-img'} `}
              src={itr?.image}
              type="accessUser"
              accessUserName={itr?.display_name}
            />
          </div>
          <span className="active-dot cs-success"></span>
          <div className="profile-info">
            <span className="cs-regular-sub-heading-s cs-neutral-90">{itr?.display_name}</span>
            <span className="cs-regular-body-text-s cs-neutral-60">{itr?.role}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

// PROPS TYPE
MultipleUserDropDown.propTypes = {
  itr: PropTypes.object
};

export default MultipleUserDropDown;
