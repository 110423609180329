import React from 'react';
import PropTypes from 'prop-types';
import { Dropdown, Form, FormLabel } from 'react-bootstrap';

// COMPONENT IMPORTS
import CapsyncIcon from '../CapsyncIcon';

/* ============================== DROPDOWN ============================== */
const CommonDropdown = ({
  label,
  placeholder,
  errors,
  name,
  values,
  touched,
  setFieldValue,
  extrLabel,
  options,
  optionsLabelKey,
  optionValueKey,
  optionExtraLabelKey,
  optionExtraValueKey,
  disabled
}) => {
  return (
    <Form.Group className={`cs-form-group${disabled ? ' cs-disabled' : ''}`}>
      {label && <FormLabel>{label}</FormLabel>}
      <div className="cs-company-selector">
        <Dropdown className="cs-form-dropdown">
          <Dropdown.Toggle
            className="dropdown-toggle"
            variant="secondary"
            id="dropdown-basic-accredited">
            {!values[name] ||
            values[name] === '' ||
            values[name] === 'Select option' ||
            values[name] === undefined ||
            values[name] === null ? (
              <React.Fragment>
                <span className="cs-ellipsis">{placeholder}</span>
                <span className="input-field-icon cs-neutral-90">
                  <CapsyncIcon title="chevron-down-outlined" size="18" />
                </span>
              </React.Fragment>
            ) : (
              <div className="cs-content-title cs-light-body-text-m">
                <span className="title cs-neutral-70">
                  {options.find((data) => data[optionValueKey] === values[name])?.[optionsLabelKey]}
                </span>
                {optionExtraLabelKey && optionExtraLabelKey !== '' && (
                  <div className="dollar-value-width cs-semi-bold-h5">
                    {extrLabel && extrLabel !== '' && <span>{extrLabel}</span>}
                    <span>
                      {
                        options.find((data) => data[optionValueKey] === values[name])?.[
                          optionExtraLabelKey
                        ]
                      }
                    </span>
                  </div>
                )}
                <span className="input-field-icon cs-neutral-90">
                  <CapsyncIcon title="chevron-down-outlined" size="18" />
                </span>
              </div>
            )}
          </Dropdown.Toggle>
          <Dropdown.Menu className="dropdown-menu">
            {options &&
              options.length > 0 &&
              options.map((item, i) => (
                <Dropdown.Item
                  className={
                    item[optionValueKey] === values[name]
                      ? 'dropdown-item dropdown-item-active cs-regular-sub-heading-s '
                      : 'cs-regular-sub-heading-m '
                  }
                  key={'dropdownitem' + i}
                  onClick={() => {
                    setFieldValue(name, item[optionValueKey]);
                    optionExtraValueKey &&
                      optionExtraValueKey !== '' &&
                      setFieldValue(optionExtraValueKey, item[optionExtraValueKey]);
                  }}>
                  <span className="title">{item[optionsLabelKey]}</span>
                  {optionExtraLabelKey && optionExtraLabelKey !== '' && (
                    <div className="dollar-value-width cs-semi-bold-h5">
                      {extrLabel && extrLabel !== '' && <span>{extrLabel}</span>}
                      <span>{item[optionExtraLabelKey]}</span>
                    </div>
                  )}
                </Dropdown.Item>
              ))}
          </Dropdown.Menu>
        </Dropdown>
        {errors && touched && errors[name] && touched[name] && (
          <span className="form-error-msg cs-light-body-text-m cs-danger">{errors[name]}</span>
        )}
      </div>
    </Form.Group>
  );
};

// PROPS TYPE
CommonDropdown.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  errors: PropTypes.object,
  name: PropTypes.string,
  values: PropTypes.object,
  touched: PropTypes.object,
  extrLabel: PropTypes.string,
  options: PropTypes.array,
  optionsLabelKey: PropTypes.string,
  optionValueKey: PropTypes.string,
  optionExtraLabelKey: PropTypes.string,
  optionExtraValueKey: PropTypes.string,
  disabled: PropTypes.bool,
  setFieldValue: PropTypes.func
};

export default CommonDropdown;
