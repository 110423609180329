import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';

// COMPONENT IMPORTS
import CommonSpinner from '../../components/CommonSpinner';

/* ============================== SAVE & CANCEL BUTTONS ============================== */
const SaveORCancelButtons = ({ resetForm, handleCancel, loading = false, disabled }) => {
  const handleCancelButtonClick = () => {
    resetForm();
    handleCancel();
  };
  return (
    <div className="cs-modal-btn">
      <Button className="cs-btn-secondary md-btn cs-regular-h5" onClick={handleCancelButtonClick}>
        Cancel
      </Button>
      <Button
        type="submit"
        className="cs-btn-primary md-btn cs-regular-h5"
        disabled={loading || disabled}>
        Save{' '}
        <CommonSpinner classParent={`${loading ? 'cs-neutral-10 m-auto' : 'd-none'}`} size="24" />
      </Button>
    </div>
  );
};

// PROPS TYPE
SaveORCancelButtons.propTypes = {
  resetForm: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  disabled: PropTypes.bool
};

export default SaveORCancelButtons;
