import React, { useEffect, useState } from 'react';
import axios from 'axios';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { PDFDocument } from 'pdf-lib';
import { useOutletContext } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

// COMPONENT IMPORTS
import OffCanvas from './Offcanvas';
import PFSPreviewModal from './PFSPreviewModal';
import { ACCORDION_DATA } from './dummyRequestData';
import Pagination from '../../components/Pagination';
import SignatureRequestTo from './SignatureRequestTo';
import SignatureRequestFrom from './SignatureRequestFrom';
import CancelSignatureModal from './CancelSignatureModal';
import ShareCompletedPFSModal from './ShareCompletedPFSModal';
import SkeletonRequest from '../components/Skeleton/SkeletonRequest';
import { emailField, firstNameField, lastNameField } from '../../validations';

// API
import { completedSignatureList, pendingSignatureList } from '../../slices/requestSlice';

// CSS IMPORTS
import '../components/Skeleton/skeleton.css';

/* ============================== REQUEST PAGE ============================== */
const Request = () => {
  const dispatch = useDispatch();

  const { user } = useSelector((state) => state.auth);
  const { pendingSignatureRequest, completedSignatureRequest } = useSelector(
    (state) => state.request
  );

  const radioName = [
    { name: 'Pending', value: 'pending' },
    { name: 'Completed', value: 'completed' }
  ];
  const pendingToSignatureRequest =
    pendingSignatureRequest && pendingSignatureRequest.signatureToList;
  const completedToSignatureRequest =
    completedSignatureRequest && completedSignatureRequest.signatureToList;
  const pendingFromSignatureRequest =
    pendingSignatureRequest && pendingSignatureRequest.signatureFromList;
  const completedFromSignatureRequest =
    completedSignatureRequest && completedSignatureRequest.signatureFromList;

  // const pendingAccountAccessRequest =
  //   pendingSignatureRequest && pendingSignatureRequest.accountAccessList;
  // const completedAccountAccessRequest =
  //   completedSignatureRequest && completedSignatureRequest.accountAccessList;
  const [tab, setTab] = useState(radioName[0].value);
  const [show, setShow] = useState(false);
  const [rowStartIdxSignatureFrom, setRowStartIdxSignatureFrom] = useState(0);
  const [rowStartIdxSignatureTo, setRowStartIdxSignatureTo] = useState(0);
  // const [rowStartIdxAccountAccess, setRowStartIdxAccountAccess] = useState(0);
  const paginationDropdownItems = [10, 15, 20, 25, 30];
  const [rowsPerPageSignatureFrom, setRowsPerPageSignatureFrom] = useState(
    paginationDropdownItems[0]
  );
  const [rowsPerPageSignatureTo, setRowsPerPageSignatureTo] = useState(paginationDropdownItems[0]);
  // const [rowsPerPageAccountAccess, setRowsPerPageAccountAccess] = useState(
  //   paginationDropdownItems[0]
  // );
  const [cancelRequestModal, setCancelRequestModal] = useState(false);
  const [showShareModal, setShowShareModal] = useState(false);
  const [showPreviewModal, setShowPreviewModal] = useState(false);
  const [accordionItemDetails, setAccordionItemDetails] = useState({});
  const [canvasDetails, setCanvasDetails] = useState({});
  const [rowData, setRowData] = useState({});
  const [URL, setURL] = useState(''); // eslint-disable-line
  const [entireObjDetails, setEntireObjDetails] = useState({});

  // const accountAcessData = accountAccess.filter((obj) => obj.status === tab);
  // const accountAcessData = accountAccess.filter(
  //   (obj) =>
  //     (tab === 'pending' && [1, 2].includes(obj.status)) ||
  //     (tab === 'completed' && ![1, 2].includes(obj.status))
  // );

  const initialValues = {
    first_name: '',
    last_name: '',
    email: '',
    notes: ''
  };
  const validationSchema = yup.object().shape({
    email: emailField(),
    first_name: firstNameField(),
    last_name: lastNameField()
  });
  const onSubmit = async (values) => {
    // setShowPFSSecondModal(true);
    setShowShareModal(false);
    setShowPreviewModal(true);
    if (rowData.sign_pfs_link) {
      fetchUrl(rowData.sign_pfs_link);
    }
  };

  const sharePFSModalSchema = useFormik({
    initialValues,
    validationSchema,
    onSubmit
  });
  const handleSignatureRequest = async () => {
    const data = {
      user_id: user && user.id
    };
    await dispatch(pendingSignatureList(data)).unwrap();
    await dispatch(completedSignatureList(data)).unwrap();
  };
  useEffect(() => {
    handleSignatureRequest();
  }, []);

  const fetchUrl = async (values) => {
    if (values) {
      try {
        const username = process.env.REACT_APP_HELLO_SIGN_USER_NAME;
        const password = process.env.REACT_APP_HELLO_SIGN_PASSWORD;

        const token = btoa(`${username}:${password}`);
        const headers = { Authorization: `Basic ${token}` };
        const response = await axios.get(values, {
          headers,
          responseType: 'arraybuffer'
        });
        if (response.status === 200) {
          const fileData = response.data;

          const pdfDoc = await PDFDocument.create();
          const downloadedPDF = await PDFDocument.load(fileData);
          const pageCount = downloadedPDF.getPageCount();

          for (let i = 0; i < pageCount; i++) {
            const [copiedPage] = await pdfDoc.copyPages(downloadedPDF, [i]);
            pdfDoc.addPage(copiedPage);
          }

          const pdfBytes = await pdfDoc.save();
          const blob = new Blob([pdfBytes], { type: 'application/pdf' });
          const url = window.URL.createObjectURL(blob);
          setURL(url);
        } else {
          console.error('Failed to fetch PDF data. Server responded with status:', response.status);
        }
      } catch (error) {
        console.error('Error fetching PDF:', error);
      }
    }
  };
  const [isLoader] = useOutletContext();
  return (
    <section className="main-section request-main-section">
      <div className="container-fluid">
        {isLoader ? (
          <SkeletonRequest />
        ) : (
          <React.Fragment>
            <div className="cs-section-topbar">
              <div className="cs-dashboard-title">
                <h1 className="cs-semi-bold-h1 cs-neutral-100">Requests</h1>
              </div>
            </div>
            <div className="cs-company-individual">
              <div className="cs-tabs-flex">
                <div className="cs-tabs">
                  {radioName.map((radioTab, id) => {
                    return (
                      <button
                        key={id}
                        type="button"
                        onClick={() => setTab(radioTab.value)}
                        className={`cs-stroke-default-tab cs-regular-h5 btn btn-primary${tab === radioTab.value ? ' cs-stroke-active' : ''}`}>
                        {radioTab.name}
                      </button>
                    );
                  })}
                </div>
              </div>
            </div>
            <div className="cs-request-table">
              <h4 className="cs-medium-sub-heading-m cs-font-color-sub-title">
                Signature request received
              </h4>

              <SignatureRequestFrom
                setShow={setShow}
                ACCORDION_DATA={ACCORDION_DATA}
                data={
                  tab === 'pending' ? pendingFromSignatureRequest : completedFromSignatureRequest
                }
                rowsPerPage={rowsPerPageSignatureFrom}
                rowStartIdx={rowStartIdxSignatureFrom}
                setShowShareModal={setShowShareModal}
                tab={tab}
                setAccordionItemDetails={setAccordionItemDetails}
                setCanvasDetails={setCanvasDetails}
                setRowData={setRowData}
                rowData={rowData}
                setEntireObjDetails={setEntireObjDetails}
                entireObjDetails={entireObjDetails}
                canvasDetails={canvasDetails}
              />

              {((tab === 'pending' &&
                pendingFromSignatureRequest &&
                pendingFromSignatureRequest.length > 0) ||
                (tab === 'completed' &&
                  completedFromSignatureRequest &&
                  completedFromSignatureRequest.length > 0)) && (
                <Pagination
                  rowStartIdx={rowStartIdxSignatureFrom}
                  setRowStartIdx={setRowStartIdxSignatureFrom}
                  rowsPerPage={rowsPerPageSignatureFrom}
                  setRowsPerPage={setRowsPerPageSignatureFrom}
                  paginationDropdownItems={paginationDropdownItems}
                  paginationList={
                    tab === 'pending' ? pendingFromSignatureRequest : completedFromSignatureRequest
                  }
                />
              )}
            </div>
            <div className="cs-request-table">
              <h4 className="cs-medium-sub-heading-m cs-font-color-sub-title">
                Signature request sent
              </h4>

              <SignatureRequestTo
                setShow={setShow}
                ACCORDION_DATA={ACCORDION_DATA}
                data={tab === 'pending' ? pendingToSignatureRequest : completedToSignatureRequest}
                rowsPerPage={rowsPerPageSignatureTo}
                rowStartIdx={rowStartIdxSignatureTo}
                setShowShareModal={setShowShareModal}
                tab={tab}
                setAccordionItemDetails={setAccordionItemDetails}
                setCanvasDetails={setCanvasDetails}
                setRowData={setRowData}
                rowData={rowData}
                setEntireObjDetails={setEntireObjDetails}
                entireObjDetails={entireObjDetails}
                canvasDetails={canvasDetails}
              />

              {((tab === 'pending' &&
                pendingToSignatureRequest &&
                pendingToSignatureRequest.length > 0) ||
                (tab === 'completed' &&
                  completedToSignatureRequest &&
                  completedToSignatureRequest.length > 0)) && (
                <Pagination
                  rowStartIdx={rowStartIdxSignatureTo}
                  setRowStartIdx={setRowStartIdxSignatureTo}
                  rowsPerPage={rowsPerPageSignatureTo}
                  setRowsPerPage={setRowsPerPageSignatureTo}
                  paginationDropdownItems={paginationDropdownItems}
                  paginationList={
                    tab === 'pending' ? pendingToSignatureRequest : completedToSignatureRequest
                  }
                />
              )}
            </div>
            {/* <div className="cs-request-table">
                <h4 className="cs-medium-sub-heading-m cs-font-color-sub-title">Account Access</h4>

                <AccountAccess
                  setShow={setShow}
                  ACCORDION_DATA={ACCORDION_DATA}
                  data={tab === 'pending' ? pendingAccountAccessRequest : completedAccountAccessRequest}
                  rowsPerPage={rowsPerPageAccountAccess}
                  rowStartIdx={rowStartIdxAccountAccess}
                  setShowShareModal={setShowShareModal}
                  tab={tab}
                  setCanvasDetails={setCanvasDetails}
                  setRowData={setRowData}
                />
                {((tab === 'pending' &&
                  pendingAccountAccessRequest &&
                  pendingAccountAccessRequest.length > 0) ||
                  (tab === 'completed' &&
                    completedAccountAccessRequest &&
                    completedAccountAccessRequest.length > 0)) && (
                  <Pagination
                    rowStartIdx={rowStartIdxAccountAccess}
                    setRowStartIdx={setRowStartIdxAccountAccess}
                    rowsPerPage={rowsPerPageAccountAccess}
                    setRowsPerPage={setRowsPerPageAccountAccess}
                    paginationDropdownItems={paginationDropdownItems}
                    paginationList={
                      tab === 'pending' ? pendingAccountAccessRequest : completedAccountAccessRequest
                    }
                  />
                )}
            </div> */}
          </React.Fragment>
        )}
      </div>
      <OffCanvas
        setShow={setShow}
        show={show}
        setShowCancelRequestModal={setCancelRequestModal}
        accordionItemDetails={accordionItemDetails}
        canvasDetails={canvasDetails}
        entireObjDetails={entireObjDetails}
        rowData={rowData}
        tab={tab}
      />
      <CancelSignatureModal
        cancelRequestModal={cancelRequestModal}
        setShowCancelRequestModal={setCancelRequestModal}
        rowData={rowData}
      />
      <ShareCompletedPFSModal
        showShareModal={showShareModal}
        sharePFSModalSchema={sharePFSModalSchema}
        setShowShareModal={setShowShareModal}
      />
      <PFSPreviewModal
        showPreviewModal={showPreviewModal}
        setShowPreviewModal={setShowPreviewModal}
        sharePFSModalSchema={sharePFSModalSchema}
        URL={URL}
        setURL={setURL}
        rowData={rowData}
      />
    </section>
  );
};

export default Request;
