/* eslint-disable no-useless-escape */
export const uniValidation = {
  email: {
    validEmail: 'Enter a valid email address',
    required: 'Enter email address',
    spaces: 'Email cannot contain spaces',
    allowedFormat: /^[a-z0-9]+([_+.][a-z0-9]+)*@[a-z0-9.-]+\.[a-z]{2,}$/i
  },
  password: {
    validPass: 'Incorrect password',
    require: 'Enter password',
    number: 'Password must contain a number',
    lowercase: 'Password must contain a lowercase letter',
    uppercase: 'Password must contain an uppercase letter',
    symbol: 'Password must contain a symbol',
    max: 'Password cannot exceed 20 characters',
    min: 'Password must contain at least 8 characters',
    spaces: 'Password cannot contain spaces',
    common: 'Enter a valid password'
  },
  confirm_password: {
    validPass: 'Incorrect password',
    require: 'Confirm password',
    match: 'Passwords do not match',
    number: 'Password must contain a number',
    lowercase: 'Password must contain a lowercase letter',
    uppercase: 'Password must contain an uppercase letter',
    symbol: 'Use a symbol',
    max: 'Password cannot exceed 20 characters',
    min: 'Password must contain at least 8 characters',
    spaces: 'Password cannot contain spaces',
    common: 'Enter a valid password'
  },
  first_name: {
    max: 'Maximum 40 characters allowed',
    require: 'Enter first name'
  },
  last_name: {
    max: 'Maximum 40 characters allowed',
    require: 'Enter last name'
  },
  phone_number: {
    valid: 'Enter a valid phone number',
    require: 'Enter phone number'
  },
  otp: {
    require: 'Enter code',
    valid: 'Enter a valid code'
  },
  street_name: {
    require: 'Enter street address',
    valid: 'Enter a valid address'
  },
  city: {
    valid: 'Enter a valid city',
    require: 'Enter city',
    notMatch: 'City entered does not match the Zip code entered'
  },
  state: {
    notMatch: 'State entered does not match the Zip code entered'
  },
  zip_code: {
    valid: 'Enter a valid ZIP code',
    require: 'Enter ZIP code'
  },
  file_name: {
    require: 'Enter name',
    allowedFormatMsg: 'Some special or ASCII characters are not allowed in file name'
  },
  assets_and_liabilities: {
    institution: {
      require: 'Enter institution',
      min: 'Institution name must be at least 2 characters',
      max: 'Maximum 40 characters allowed'
    },
    balance: {
      require: 'Enter balance',
      nonzero: 'Balance must be greater than zero',
      twoDigits: 'Maximum two digits are allowed after the decimal point'
    },
    ownership: {
      require: 'Enter ownership (%)'
    },
    property_name: {
      require: 'Enter name of debtor',
      whitespace: 'Avoid leading or trailing whitespace',
      min: 'Debtor name must be at least 2 characters',
      max: 'Maximum 40 characters allowed'
    },
    loan_amount: {
      require: 'Enter loan amount',
      greater: 'Loan amount must be greater than zero'
    },
    loan_period: {
      require: 'Enter loan term',
      years: 'Loan Term must be less then 40 years',
      months: 'Loan term must be less than 480 months',
      nonzero: 'Loan term must be greater than zero'
    },
    annual_rate: {
      require: 'Enter Interest rate',
      number: 'Enter numeric values only',
      valid: 'Enter a valid number',
      nonzero: 'Interest rate must be greater than zero'
    },
    balance_due: {
      require: 'Enter balance due',
      number: 'Enter numeric values only',
      positive: 'Balance due must be greater than zero',
      nonzero: 'Balance due must be greater than zero',
      max: 'Maximum two digits are allowed after the decimal point'
    },
    loan_type: {
      max: 'Maximum 40 characters allowed'
    },
    company: {
      require: 'Enter institution ',
      min: 'Company name must be at least 2 characters',
      max: 'Maximum 40 characters allowed'
    },
    beneficiary: {
      require: 'Enter beneficiary',
      max: 'Maximum 40 characters allowed'
    },
    cash_value: {
      require: 'Enter cash value',
      nonzero: 'Cash value must be greater than zero',
      max: 'Maximum two digits are allowed after the decimal point'
    },
    description: {
      require: 'Enter description',
      max: 'Maximum 100 characters allowed',
      min: 'Description must be at least 2 characters'
    },
    creditor_name: {
      min: 'Creditor name must be at least 2 characters',
      max: 'Maximum 40 characters allowed',
      require: 'Enter name of creditor'
    },
    market_value: {
      require: 'Enter market value',
      nonzero: 'Market value must be greater than zero',
      max: 'Maximum two digits are allowed after the decimal point'
    },
    vin: {
      valid: 'Enter a valid VIN number',
      require: 'Enter VIN number'
    },
    entity_name: {
      require: 'Enter entity name',
      min: 'Entity name must be at least 2 characters',
      max: 'Maximum 40 characters allowed'
    },
    industry: {
      min: 'Industry name must be at least 2 characters',
      max: 'Maximum 40 characters allowed',
      require: 'Enter industry'
    },
    collateral: {
      max: 'Maximum 40 characters allowed',
      min: 'Collateral name must be at least 2 characters',
      require: 'Enter collateral name'
    }
  }
};
