import React from 'react';
import PropTypes from 'prop-types';
import { Modal as BootstrapModal, Button } from 'react-bootstrap';

// COMPONENT IMPORTS
import CapsyncIcon from './CapsyncIcon';
import CommonSpinner from './CommonSpinner';

/* ============================== MODAL ============================== */
const Modal = (props) => {
  const {
    show = false,
    title = '',
    handleClose,
    body,
    size = 'md',
    handleOnSave,
    saveButtonLabel = '',
    cancelButtonLabel = '',
    handleOnCancel,
    disabled = false,
    className = 'cs-lg-modal',
    parentClassName = '',
    backdropClass = '',
    isCloseButton = true,
    modalFooterClass = '',
    hasBackButton = false,
    handleBackButtonClick,
    loading = false,
    btnSize = 'md-btn',
    modalFixedClass = true,
    isModalVisible = true,
    isLoader = false,
    footerWithTag,
    form
  } = props;

  const handleCloseMainModal = () => {
    if (handleClose) {
      handleClose();
    }
  };

  const handleBack = () => {
    if (handleBackButtonClick) {
      handleBackButtonClick();
    }
  };

  return (
    <BootstrapModal
      show={show}
      onHide={handleClose}
      size={size}
      className={`cs-modalbox ${!isModalVisible ? 'cs-modal-spinner ' : ''} ${parentClassName ? parentClassName : ''}`}
      dialogClassName={className}
      backdropClassName={backdropClass}
      backdrop="static"
      centered>
      {isModalVisible ? (
        <React.Fragment>
          <BootstrapModal.Header closeButton={isCloseButton}>
            {hasBackButton && (
              <span className="cs-neutral-70 cs-previous-btn" onClick={handleBack}>
                <CapsyncIcon title="chevron-left-outlined" size="24" />
              </span>
            )}
            <BootstrapModal.Title className="cs-semi-bold-h3 cs-neutral-100">
              {title}
            </BootstrapModal.Title>
            {isCloseButton && (
              <span className="cs-neutral-70 cs-close-btn" onClick={handleCloseMainModal}>
                <CapsyncIcon title="close-outlined" size="20" />
              </span>
            )}
          </BootstrapModal.Header>
          {body && <BootstrapModal.Body>{body}</BootstrapModal.Body>}
          {footerWithTag && (
            <BootstrapModal.Footer className={modalFooterClass}>
              {footerWithTag}
            </BootstrapModal.Footer>
          )}
          {(saveButtonLabel || cancelButtonLabel) && (
            <BootstrapModal.Footer
              className={`${modalFixedClass ? 'cs-modal-btn ' : ''}${modalFooterClass}`}>
              {cancelButtonLabel && (
                <Button
                  variant="secondary"
                  className={`cs-btn-secondary cs-regular-h5 ${btnSize}`}
                  disabled={loading ? true : false}
                  onClick={handleOnCancel}>
                  {cancelButtonLabel}
                </Button>
              )}

              <Button
                form={form}
                variant="primary"
                type="submit"
                className={`cs-btn-primary cs-regular-h5 ${btnSize}`}
                disabled={loading ? true : disabled}
                onClick={handleOnSave}>
                {loading && (
                  <CommonSpinner
                    classParent={`${loading ? 'cs-neutral-10 m-auto' : 'd-none'}`}
                    size="24"
                  />
                )}
                {saveButtonLabel}
              </Button>
            </BootstrapModal.Footer>
          )}
        </React.Fragment>
      ) : (
        isLoader && <CommonSpinner />
      )}
    </BootstrapModal>
  );
};

// PROPS TYPE
Modal.propTypes = {
  show: PropTypes.bool,
  title: PropTypes.any,
  handleClose: PropTypes.func,
  body: PropTypes.element,
  handleOnSave: PropTypes.func,
  saveButtonLabel: PropTypes.string,
  cancelButtonLabel: PropTypes.string,
  handleOnCancel: PropTypes.func,
  className: PropTypes.string,
  parentClassName: PropTypes.string,
  backdropClass: PropTypes.string,
  isCloseButton: PropTypes.bool,
  disabled: PropTypes.bool,
  modalFooterClass: PropTypes.string,
  size: PropTypes.string,
  hasBackButton: PropTypes.bool,
  handleBackButtonClick: PropTypes.func,
  loading: PropTypes.bool,
  btnSize: PropTypes.string,
  modalFixedClass: PropTypes.bool,
  isModalVisible: PropTypes.bool,
  isLoader: PropTypes.bool,
  footerWithTag: PropTypes.element,
  form: PropTypes.any
};

export default Modal;
