import React from 'react';
import Skeleton from 'react-loading-skeleton';

// COMPONENT IMPORTS
import SkeletonCategoryTable from './SkeletonCategoryTable';

/* ============================== SKELETON MANAGE USER ============================== */
const SkeletonManageUser = () => {
  return (
    <div className="cs-section-topbar">
      <div className="skeleton-breadcrumb">
        <div className="skeleton-breadcrumb-1">
          {' '}
          <Skeleton width={50} />
        </div>
        <div className="skeleton-breadcrumb-2">
          {' '}
          <Skeleton width={20} />
        </div>
        <div className="skeleton-breadcrumb-3">
          <Skeleton width={50} />
        </div>
      </div>
      <div className="cs-title">
        <h1 className="default-semi-bold-h1 cs-neutral-100">
          <Skeleton width={200} />
        </h1>
      </div>
      <div className="skeleton-manage-container for-des">
        <div className="skeleton-tabs-container">
          <h1 className="skeleton-tab-1">
            <Skeleton width={200} />
          </h1>
          <h1 className="skeleton-tab-2">
            <Skeleton width={200} />
          </h1>
        </div>
      </div>
      <div className="skeleton-manage-container for-mob">
        <div>
          <Skeleton width="100%" height={30} />
        </div>
        <div className="skeleton-tabs-container">
          {Array(2)
            .fill(0)
            .map((item) => (
              <h1 className="skeleton-tab-1" key={item}>
                <Skeleton width={140} height={30} />
              </h1>
            ))}
        </div>
      </div>

      <SkeletonCategoryTable tableData={[1, 2, 3]} />
    </div>
  );
};

export default SkeletonManageUser;
