import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../config/api';

export const getAuditLogs = createAsyncThunk(
  'activity_logs',
  async ({ userId, roleId, search = '', page = 1, limit = 10 }) => {
    const isPaginate = limit === 'all' ? false : true;
    const res = await axios.get(`activity_logs/${userId}/${roleId}`, {
      params: { search, page, limit, isPaginate }
    });
    return res;
  }
);

// INITIAL STATE
const initialState = {
  auditLogs: {}
};

/* ============================== AUDIT LOG SLICE ============================== */
export const auditLogsSlice = createSlice({
  name: 'auditLogs',
  initialState,
  reducers: {
    resetAuditLogsSlice: () => initialState
  },
  extraReducers: (builder) => {
    builder.addCase(getAuditLogs.fulfilled, (state, { payload }) => {
      const { data } = payload;
      state.auditLogs = data;
    });
  }
});

export const { resetAuditLogsSlice } = auditLogsSlice.actions;
const { reducer } = auditLogsSlice;
export default reducer;
