import React, { useEffect } from 'react';
import { Col } from 'react-bootstrap';
import PropTypes from 'prop-types';

// COMPONENT IMPORTS
import CapsyncIcon from '../CapsyncIcon';

/* ============================== MFA OPTIONS ============================== */
const MFAOption = ({
  title,
  description,
  icon,
  id,
  value,
  callback,
  type,
  selectedType,
  setSelectedType,
  delay = 0
}) => {
  const handleOptionSelect = () => {
    setSelectedType(type);
    setTimeout(() => callback(type), [delay]);
  };

  useEffect(() => {
    setSelectedType('');
  }, []);

  return (
    <Col lg={12}>
      <div className="mfa-box cursor-pointer" onClick={handleOptionSelect}>
        <div className="mfa-inner">
          <div className="cs-radio-btn mfa-options">
            <input
              type="radio"
              name="mfOption"
              id={id}
              value={value}
              className="radio-button"
              checked={selectedType === type}
              onChange={handleOptionSelect}
            />
          </div>
          <div className="mfa-options">
            <h4 className="cs-neutral-90 cs-regular-h4">{title}</h4>
            <span className="cs-regular-h6 cs-neutral-70">{description}</span>
          </div>
        </div>
        <span className="cs-neutral-80">
          {' '}
          <CapsyncIcon title={icon} size="22" />
        </span>
      </div>
    </Col>
  );
};

// PROPS TYPE
MFAOption.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  callback: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  selectedType: PropTypes.string.isRequired,
  setSelectedType: PropTypes.func.isRequired,
  delay: PropTypes.number
};

export default MFAOption;
