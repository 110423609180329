import React, { useEffect, useState } from 'react';
import { Col, Row, Form, Button } from 'react-bootstrap';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import PhoneInput from 'react-phone-number-input';

import InputBox from '../../components/FormFields/InputBox';
import useRemoveServerError from '../../components/hooks/useRemoveServerError';
import {
  confirmPasswordField,
  emailField,
  firstNameField,
  lastNameField,
  passwordField,
  phoneField
} from '../../validations';
import AuthHead from '../AuthHead';

// Sign In API calls
import {
  clearUserVerified,
  emailVerification,
  resetForm,
  useUserVerifiedDetails,
  userSignUp
} from '../../slices/authSlice';
import PasswordRequirement from '../../components/PasswordRequirement';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

const SignUp = () => {
  const { token } = useParams();
  useRemoveServerError();
  const { message } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const userVerifiedDetails = useUserVerifiedDetails();
  const dispatch = useDispatch();
  const [showPwdRequirements, setShowPwdRequirements] = useState(false);

  const { values, errors, touched, handleChange, handleSubmit, setFieldValue, setValues } =
    useFormik({
      initialValues: {
        first_name: '',
        last_name: '',
        email: '',
        phone_no: '',
        password: '',
        confirm_password: ''
      },
      validationSchema: yup.object().shape({
        first_name: firstNameField(),
        last_name: lastNameField(),
        email: emailField(),
        phone_no: phoneField(),
        password: passwordField(),
        confirm_password: confirmPasswordField()
      }),
      onSubmit: async (values) => {
        try {
          const payload = {
            password: values.password,
            confirm_password: values.confirm_password,
            phone_no: values.phone_no
          };
          const response = await dispatch(
            userSignUp({ id: userVerifiedDetails && userVerifiedDetails.user_id, payload })
          ).unwrap();
          if (response.status) {
            navigate('/');
            clearUserVerified();
          }
        } catch (e) {
          console.log('e', e);
        }
      }
    });

  const handleInputChange = (e) => {
    dispatch(resetForm());
    handleChange(e);
  };

  const handleCursorPosition = (e) => {
    const input = e.target;
    const countryCodeLength = '+1 '.length;

    if (input.selectionStart <= countryCodeLength) {
      input.setSelectionRange(countryCodeLength, countryCodeLength);
    }
  };

  const onUserVerification = async () => {
    try {
      dispatch(resetForm());
      const response = await dispatch(emailVerification(token)).unwrap();
      if (response?.status) {
        toast.success(response?.message);
        if (response?.data?.is_profile_set) {
          navigate('/');
        }
      } else {
        toast.error(response?.message);
        navigate('/');
      }
    } catch (error) {
      console.log('error');
    }
  };

  useEffect(() => {
    if (userVerifiedDetails) {
      setValues({
        ...values,
        first_name: userVerifiedDetails.first_name,
        last_name: userVerifiedDetails.last_name,
        email: userVerifiedDetails.email
      });
    }
  }, [userVerifiedDetails]);

  useEffect(() => {
    onUserVerification();
  }, [token]);

  return (
    <React.Fragment>
      <AuthHead title="Sign up" />
      <div className="cs-auth-form">
        <Form onSubmit={handleSubmit}>
          <Row>
            <Col lg={6}>
              <InputBox
                name="first_name"
                placeholder="Enter first name"
                values={values}
                errors={errors}
                touched={touched}
                onChange={(e) => handleInputChange(e)}
                disabled
              />
            </Col>
            <Col lg={6}>
              <InputBox
                name="last_name"
                placeholder="Enter last name"
                values={values}
                errors={errors}
                touched={touched}
                onChange={(e) => handleInputChange(e)}
                disabled
              />
            </Col>
          </Row>
          <Row>
            <Col lg={6}>
              <InputBox
                name="email"
                type="email"
                placeholder="Enter email"
                values={values}
                errors={errors}
                touched={touched}
                onChange={(e) => handleInputChange(e)}
                disabled
              />
            </Col>
            <Col lg={6}>
              <Form.Group className="cs-form-group">
                <PhoneInput
                  limitMaxLength={15}
                  international
                  countryCallingCodeEditable={false}
                  placeholder="Phone Number"
                  name="phone_no"
                  withCountryCallingCode={false}
                  value={values.phone_no}
                  className="form-control"
                  onChange={(e) => {
                    dispatch(resetForm());
                    setFieldValue('phone_no', e !== undefined ? e : '');
                  }}
                  defaultCountry="US"
                  countries={['US']}
                  addInternationalOption={false}
                  autoComplete="off"
                  numberInputProps={{ onSelect: handleCursorPosition }}
                />
                {errors.phone_no && touched.phone_no ? (
                  <span className="form-error-msg cs-light-body-text-s cs-danger">
                    {errors.phone_no}
                  </span>
                ) : null}
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              <InputBox
                name="password"
                type="password"
                placeholder="Enter password"
                values={values}
                errors={errors}
                touched={touched}
                onChange={(e) => handleInputChange(e)}
                onBlur={() => setShowPwdRequirements(false)}
                onFocus={() => setShowPwdRequirements(true)}
                isSignUp={showPwdRequirements}
              />
              {showPwdRequirements && <PasswordRequirement password={values.password} />}
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              <div className="last-form-field resp-last-field">
                <InputBox
                  name="confirm_password"
                  type="password"
                  placeholder="Enter Confirm password"
                  values={values}
                  errors={errors}
                  touched={touched}
                  onChange={(e) => handleInputChange(e)}
                />
              </div>
            </Col>
          </Row>
          {/* API ERROR MESSAGE DISPLAY */}
          <Row>
            <Col className="text-start">
              <span className="cs-light-body-text-m cs-danger">{message}</span>
            </Col>
          </Row>

          <Row>
            <Col>
              <div className="auth-footer">
                <Button type="submit" className="cs-btn-primary lg-btn cs-regular-h5">
                  Sign up
                </Button>
              </div>
            </Col>
          </Row>
        </Form>
      </div>
    </React.Fragment>
  );
};

export default SignUp;
