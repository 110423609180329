import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useOutletContext } from 'react-router-dom';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useFormik } from 'formik';

// COMPONENT IMPORTS
import {
  AttachmentModal,
  CancelConfirmationModal,
  PFSLinkModal,
  UploadFileModal
} from './SubMethodsFormModal';
import {
  getMediaList,
  handleAccreditationModals,
  handleFileSubmit,
  submitForReviewBtnClick
} from './SubMethodsBasicFunctions';
import { fileUploadJSON } from '../ModalJSON';
import FieldsHTML from '../common/FieldsHTML';
import { socket } from '../../../config/Socket';
import { subMethodFormJSON } from './SubMethodFormJSON';
import { initialValues } from './SubMethodsInitialValues';
import { subMethodsPageTitle } from './SubMethodsPageTitle';
import CommonSpinner from '../../../components/CommonSpinner';
import { validationSchema } from './SubMethodsValidationSchema';
import CapsyncBreadcrumb from '../../../components/CapsyncBreadcrumb';
import HelloSignProgress from '../../../components/HelloSignProgress';
import SkeletonViSubMethods from '../../components/Skeleton/SkeletonVISubMethods';
import SkeletonAllSubMethods from '../../components/Skeleton/SkeletonAllSubMethods';
import PaymentFailed from '../../../components/PaymentSubscription/Payment/PaymentFailed';
import StripeCheckOut from '../../../components/PaymentSubscription/Payment/StripeCheckOut';
import PaymentProgressBar from '../../../components/PaymentSubscription/Payment/PaymentProgressBar';
import PaymentSuccessfull from '../../../components/PaymentSubscription/Payment/PaymentSuccessfull';

// API
import {
  getMethodDataByUniqueID,
  resetLoader,
  resetModal,
  setAttachments,
  setInvestorType,
  setLoader,
  setMethodName,
  setPFSLink,
  setRowID
} from '../../../slices/accreditationSlice';

/* ============================== SUB METHODS VIEW ============================== */
const SubMethodsView = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { initMethodName, subMethod, pfsLink, modal, loader, rowID, investorType } = useSelector(
    (state) => state.accreditation
  );
  const { calculatedNetWorth } = useSelector((state) => state.dashboard);
  const { user } = useSelector((state) => state.auth);
  const titleOfPage = subMethodsPageTitle(subMethod);

  const [showStripeForm, setShowStripeForm] = useState(false);
  const [amount, setAmount] = useState();
  const [paymentLoader, setPaymentLoader] = useState(false);
  const [showPaymentSuccessfull, setShowPaymentSuccessfull] = useState(false);
  const [showPaymentFailed, setShowPaymentFailed] = useState(false);
  const [failureReason, setFailureReason] = useState('');
  const [visitorID, setVisitorID] = useState(0);
  const [dropboxID, setDropboxID] = useState(0);
  const [errors, setErrors] = useState({});
  const [value, setValue] = useState({});
  const [paymentDataFromListAPI, setPaymentDataFromListAPI] = useState({
    transactionID: '',
    requestSent: false
  });

  useEffect(() => {
    dispatch(resetModal());
    dispatch(resetLoader());
    dispatch(setAttachments([]));
    dispatch(
      setPFSLink({
        link: '',
        dropBoxID: 0
      })
    );
    if (subMethod === 0 || subMethod === null || subMethod === '') {
      if (rowID === '' || rowID === null) {
        navigate('/verifyinvestor');
      } else {
        navigate('/verifyinvestor/verification-type');
      }
    } else {
      usersMethodDataByUniqueID();
      getMediaList(dispatch, rowID, formik);
    }
  }, []);

  const pageTitle =
    initMethodName === 'individuals' ||
    initMethodName === 'entities' ||
    initMethodName === 'entitiestwoowner' ||
    initMethodName === 'trusts' ||
    initMethodName === 'showtrustmethods' ||
    initMethodName === 'changemethodtono' ||
    initMethodName === 'changemethodtoopenthree' ||
    initMethodName === 'showindividualmethod'
      ? 'Accredited Investor'
      : initMethodName === 'qualifiedclient'
        ? 'Qualified Client'
        : 'Qualified Purchaser';

  const pageMainHeader =
    initMethodName === 'individuals' ||
    initMethodName === 'entities' ||
    initMethodName === 'entitiestwoowner' ||
    initMethodName === 'trusts' ||
    initMethodName === 'showtrustmethods' ||
    initMethodName === 'changemethodtono' ||
    initMethodName === 'changemethodtoopenthree' ||
    initMethodName === 'showindividualmethod'
      ? 'Accredited Investor Verification'
      : initMethodName === 'qualifiedclient'
        ? 'Qualified Client Verification'
        : 'Qualified Purchaser Verification';

  const mainLevelArr = [
    {
      title: 'Investor Accreditation',
      url: '/verifyinvestor'
    },
    {
      title: pageTitle,
      url: `/verifyinvestor/verification-type`
    }
  ];

  // 0 = Plan Fiduciary && Officer's Certificate
  // 1 = Plan Statement && Officer's Certificate
  // 2 = Plan Fiduciary && Plan Statement
  const [labelChange, setLabelChange] = useState(0);

  const methodID =
    subMethod === 13 || subMethod === 20
      ? 13
      : subMethod === 14 || subMethod === 22
        ? 14
        : subMethod === 21 || subMethod === 25
          ? 21
          : subMethod;

  const methodContent = subMethodFormJSON(
    methodID,
    labelChange,
    setLabelChange,
    errors,
    value,
    loader && loader['submitBtn']
  );
  const methodInitialValues = initialValues(methodID);
  const methodValidationSchema = validationSchema(methodID);

  const formik = useFormik({
    initialValues: methodInitialValues.initialValuesObj,
    validationSchema: methodValidationSchema.validations,
    onSubmit: async (values) => {
      await dispatch(setLoader({ submitBtn: true }));
      let notesValTxt = '';
      notesValTxt = values['notes'] ? 'Note:' + values['notes'] + ', ' : '';
      if (subMethod === 1) {
        if (values['income_amount']) {
          notesValTxt += `The amount of income I(we) received for the previous year is: $${values['income_amount']}, `;
        }
        if (values['terms_1']) {
          notesValTxt += `The investor is presenting information showing joint income with a spouse or spousal equivalent.: ${values['terms_1']}`;
        }
        if (values['terms_2']) {
          notesValTxt += `I (or my spouse/spousal equivalent and I, as applicable) have a reasonable expectation of reaching the income level necessary to qualify as an accredited investor during the current year.: ${values['terms_2']}`;
        }
      }
      if (subMethod === 2) {
        if (values['officers_investment_or_deal_name']) {
          notesValTxt += `The investment name or deal name of the company where I am an officer is: ${values['officers_investment_or_deal_name']},  `;
        }
      }
      if (subMethod === 38) {
        if (values['aknowledgeable_employees_investment_or_deal_name']) {
          notesValTxt += `The investment name or deal name of the company where I am knowledgeable employee is: ${values['aknowledgeable_employees_investment_or_deal_name']},  `;
        }
      }
      if (subMethod === 37 || subMethod === 42) {
        if (values['name']) {
          notesValTxt += `Name : ${values['name']},  `;
        }
        if (values['crd_number']) {
          notesValTxt += `CRD Number : ${values['crd_number']},  `;
        }
        if (values['firm_name']) {
          notesValTxt += `Firm name : ${values['firm_name']},  `;
        }
      }
      if (subMethod === 27 || subMethod === 28 || subMethod === 29 || subMethod === 35) {
        if (values['investor_type'] !== 'Select option') {
          notesValTxt += `The investor is investing as :  ${values['investor_type']},  `;
        }
        if (values['terms'] && (subMethod === 27 || subMethod === 29)) {
          notesValTxt += `Declaration: Investor Accepts that it is not an investment company and is not an entity that would be an investment company but for the exemption offered under Section 3(c)(1) of the Investment Company Act.  `;
        }
        if (values['company_checkbox'] && subMethod === 28) {
          notesValTxt += `Declaration: Investor Accepts that it is not an investment company and is not an entity that would be an investment company but for the exemption offered under Section 3(c)(1) of the Investment Company Act.  `;
        }
        if (values['certify'] && (subMethod === 35 || subMethod === 28)) {
          notesValTxt += `Declaration: ${values['legal_name']} certifies that the investor meets the requirements indicated. `;
        }
        if (values['liability']) {
          notesValTxt += `Declaration: ${values['legal_name']} declares that all outstanding liabilities of the entity have been disclosed. `;
        }
      }
      if (subMethod === 3 || subMethod === 19) {
        if (values['certify']) {
          notesValTxt += `Declaration: ${values['legal_name']} certify that no credit report is available. `;
        }
      }
      if (subMethod === 33 || subMethod === 34) {
        if (values['terms']) {
          notesValTxt += `Declaration:  ${values['legal_name']} certifies that the investor meets the requirements indicated. `;
        }
      }
      notesValTxt += `Investor Type :  ${investorType.length !== 0 && investorType !== 'Select option' ? investorType : 'None or N/A'},  `;
      values['notes_value'] = notesValTxt;
      await submitForReviewBtnClick(
        dispatch,
        values,
        rowID,
        subMethod,
        setErrors,
        calculatedNetWorth,
        setShowStripeForm,
        paymentDataFromListAPI,
        navigate
      );
    }
  });

  useEffect(() => {
    setValue(formik.values);
  }, [formik.values]);

  const [openFileUploadPopUp, setOpenFileUploadPopUp] = useState(false);

  const handleFileUploadPopup = (value) => {
    uploadFileFormik.resetForm(initialValues('fileUpload').initialValuesObj);
    setOpenFileUploadPopUp(value);
  };

  const uploadFileFormik = useFormik({
    initialValues: initialValues('fileUpload').initialValuesObj,
    validationSchema: validationSchema('fileUpload').validations,
    onSubmit: async (values) => {
      values.users_data = { unique_id: rowID, upload_drop_box_id: dropboxID, user_id: user.id };
      handleFileSubmit(
        values,
        uploadFileFormik,
        handleFileUploadPopup,
        dispatch,
        pfsLink,
        formik,
        dropboxID
      );
    }
  });

  const usersMethodDataByUniqueID = async () => {
    await dispatch(getMethodDataByUniqueID({ unique_id: rowID }))
      .unwrap()
      .then((response) => {
        if (response.code === 200) {
          if (
            response.data.transaction_id !== '' &&
            response.data.transaction_id !== null &&
            response.data.is_request_sent
          ) {
            navigate('/verifyinvestor');
          } else {
            setAmount(response?.data?.amount);
            setPaymentDataFromListAPI({
              transactionID: response.data.transaction_id,
              requestSent: response.data.is_request_sent
            });
            setVisitorID(response.data.visitors_id);
            formik.setFieldValue('legal_name', response.data.legal_name);
            dispatch(setPFSLink({ link: response.data.sign_pfs_link }));
            dispatch(setInvestorType(response.data.investor_type || 'Select option'));
          }
        } else if (response.code === 500) {
          toast.warning(response.message);
          navigate('/verifyinvestor');
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const changeVerificationMethod = () => {
    dispatch(setMethodName(initMethodName));
    dispatch(setRowID(rowID));
    navigate(`/verifyinvestor/verification-type`);
  };

  const disableSubmitBtn = () => {
    let disableBtn = false;
    const pfsDatacontatins = [
      formik.values.upload_drop_box_1 &&
        !formik.values.upload_drop_box_1.some((d) => d.is_signature_pdf),
      formik.values.upload_drop_box_2 &&
        !formik.values.upload_drop_box_2.some((d) => d.is_signature_pdf),
      formik.values.upload_drop_box_3 &&
        !formik.values.upload_drop_box_3.some((d) => d.is_signature_pdf)
    ];
    if (loader.submitBtn) {
      disableBtn = true;
    } else if (pfsDatacontatins.every((value) => value)) {
      disableBtn = true;
    } else {
      disableBtn = false;
    }
    return disableBtn;
  };

  let latestPaymentStatus = null;
  useEffect(() => {
    socket.on('accreditation_payment_status', (payment) => {
      setTimeout(() => {
        if (payment.status === true) {
          if (payment.unique_id === rowID) {
            latestPaymentStatus = payment.vi_status;
            toast.success(latestPaymentStatus);
            setShowPaymentSuccessfull(true);
            setPaymentLoader(false);
          }
        } else {
          setPaymentLoader(false);
          setShowPaymentFailed(true);
          latestPaymentStatus = payment.vi_status;
          setFailureReason(latestPaymentStatus);
        }
      }, 1000);
    });
    return () => {
      socket.off('accreditation_payment_status');
    };
  }, []);
  const [isLoader] = useOutletContext();

  return (
    <React.Fragment>
      <section className="accreditation-main-section accreditation-inner-section main-section">
        <Container fluid>
          {isLoader ? (
            <SkeletonAllSubMethods />
          ) : (
            <React.Fragment>
              <div className="cs-section-topbar financial-advisor-topbar">
                <div className="client-topbar">
                  <div className="cs-breadcrumb">
                    <CapsyncBreadcrumb
                      mainLevelArr={mainLevelArr}
                      mainTitle="Investor Accreditation"
                      mainUrl="/verifyinvestor"
                      multiLevelFlag="true"
                      subTitle={titleOfPage.title}
                    />
                  </div>
                  <div className="cs-title">
                    <h1 className="cs-semi-bold-h1 cs-neutral-100">{pageMainHeader}</h1>
                  </div>
                </div>
              </div>
              <div className="method-section">
                <Form>
                  {methodContent.formJSON.length > 0 &&
                    methodContent.formJSON.map((item, index) => {
                      if (item.fieldVisiblity || item.fieldVisibilityCondition) {
                        if (item.contentType === 'description') {
                          return (
                            <React.Fragment key={index.toString()}>{item.content}</React.Fragment>
                          );
                        } else if (item.contentType === 'divider') {
                          return (
                            <React.Fragment key={index.toString()}>{item.content}</React.Fragment>
                          );
                        } else if (item.contentType === 'form') {
                          if (
                            item.fieldType === 'input-uploadfile' ||
                            (item.fieldType === 'input-checkbox' && item.field !== 'terms_2') ||
                            item.field === 'officers_investment_or_deal_name' ||
                            item.field === 'aknowledgeable_employees_investment_or_deal_name'
                          ) {
                            return (
                              <React.Fragment key={index.toString()}>
                                <FieldsHTML
                                  formik={formik}
                                  fieldJSON={[item]}
                                  uploadPopupOpen={() => handleFileUploadPopup(true)}
                                  setDropboxID={setDropboxID}
                                />
                              </React.Fragment>
                            );
                          } else if (item.field === 'crd_number') {
                            return (
                              <div className="row-spacing crd-row" key={index.toString()}>
                                <FieldsHTML
                                  formik={formik}
                                  fieldJSON={[item]}
                                  uploadPopupOpen={() => handleFileUploadPopup(true)}
                                  setDropboxID={setDropboxID}
                                />
                              </div>
                            );
                          } else if (item.field === 'firm_name') {
                            return (
                              <div className="row-spacing crd-row firm-row" key={index.toString()}>
                                <FieldsHTML
                                  formik={formik}
                                  fieldJSON={[item]}
                                  uploadPopupOpen={() => handleFileUploadPopup(true)}
                                  setDropboxID={setDropboxID}
                                />
                              </div>
                            );
                          } else if (item.field === 'name') {
                            return (
                              <div className="row-spacing rowRemoveMargin" key={index.toString()}>
                                <FieldsHTML
                                  formik={formik}
                                  fieldJSON={[item]}
                                  uploadPopupOpen={() => handleFileUploadPopup(true)}
                                  setDropboxID={setDropboxID}
                                />
                              </div>
                            );
                          } else {
                            return (
                              <div className="row-spacing" key={index.toString()}>
                                <FieldsHTML
                                  formik={formik}
                                  fieldJSON={[item]}
                                  uploadPopupOpen={() => handleFileUploadPopup(true)}
                                  setDropboxID={setDropboxID}
                                />
                              </div>
                            );
                          }
                        }
                      }
                    })}
                  <div className="cs-divider"></div>
                  <p className="cs-medium-sub-heading-m cs-danger">
                    Kindly note the verification letters are generally good for 90 days per the SEC
                    rules.
                  </p>
                </Form>
              </div>
            </React.Fragment>
          )}
        </Container>
        {isLoader ? (
          <SkeletonViSubMethods />
        ) : (
          <div className="accreditation-footer">
            <Container fluid>
              <Row>
                <Col lg={6}>
                  <p className="cs-medium-sub-heading-m  cs-neutral-80">
                    If you are not{' '}
                    {initMethodName === 'qualifiedpurchaser'
                      ? 'a qualified purchaser'
                      : initMethodName === 'qualifiedclient'
                        ? 'a qualified client'
                        : 'an accredited investor'}{' '}
                    or wish to cancel this verification request,
                    <Link
                      className="cs-primary"
                      onClick={() =>
                        handleAccreditationModals(dispatch, 'methodCancelConfirmationModal', true)
                      }>
                      &nbsp;click here
                    </Link>
                  </p>
                </Col>
                <Col lg={6}>
                  <div className="accreditation-btn-group">
                    <Button
                      className="cs-btn-secondary cs-regular-h5"
                      disabled={loader && loader['submitBtn']}
                      onClick={() => {
                        changeVerificationMethod();
                      }}>
                      Change Verification Method
                    </Button>
                    <Button
                      className="lg-btn cs-btn-primary cs-regular-h5"
                      disabled={disableSubmitBtn()}
                      onClick={() => formik.handleSubmit()}>
                      {loader && loader['submitBtn'] && (
                        <CommonSpinner
                          classParent={`${loader && loader['submitBtn'] ? ' cs-neutral-10 m-auto' : ' d-none'}`}
                          size="20"
                        />
                      )}
                      Submit for Review
                    </Button>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        )}
      </section>
      <UploadFileModal
        openModalState={openFileUploadPopUp}
        openModalFunc={setOpenFileUploadPopUp}
        formik={uploadFileFormik}
        fieldJSON={fileUploadJSON(pfsLink, dropboxID, loader && loader['uploadFileModalLoader'])}
        handleFileUploadPopup={handleFileUploadPopup}
        loading={loader && loader['uploadFileModalLoader']}
        methodFormik={formik}
        dropboxID={dropboxID}
      />
      {modal && <PFSLinkModal show={modal.pfsLink} visitorID={visitorID} />}
      {modal && <AttachmentModal show={modal.attachmentDocument} formik={uploadFileFormik} />}
      {modal && <CancelConfirmationModal show={modal.methodCancelConfirmationModal} />}
      {loader && loader.pfsLoader && <HelloSignProgress backdropClass="overlap-none" />}
      {showStripeForm && (
        <StripeCheckOut
          setShowStripeForm={setShowStripeForm}
          accreditation_id={rowID}
          amount={amount}
          setPaymentLoader={setPaymentLoader}
        />
      )}
      {paymentLoader && <PaymentProgressBar />}
      <PaymentSuccessfull showPaymentSuccessfull={showPaymentSuccessfull} />
      <PaymentFailed
        showPaymentFailed={showPaymentFailed}
        failureReason={failureReason}
        setShowPaymentFailed={setShowPaymentFailed}
      />
    </React.Fragment>
  );
};
export default SubMethodsView;
