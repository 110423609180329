import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useOutletContext } from 'react-router-dom';
import { Button, Form, Row, Col } from 'react-bootstrap';
import { useFormik } from 'formik';

// COMPONENT IMPORTS
import EditProfile from '../EditProfile';
import { localObjectClear } from '../../../utils/system';
import CapsyncIcon from '../../../components/CapsyncIcon';
import Avatar from '../../../components/userProfile/Avatar';
import { clearPersisterStorage } from '../../../utils/common';
import InputBox from '../../../components/FormFields/InputBox';
import UpdateAvatar from '../../components/UserProfile/UpdateAvatar';
import SkeletonAccount from '../../components/Skeleton/SkeletonAccount';
import ChangePasswordLogoutModal from '../../../components/ChangePasswordLogoutModal';

// API
import {
  authUserLogout,
  useAccessUser,
  userForgotPassword,
  useToUserPermission
} from '../../../slices/authSlice';

/* ============================== ACCOUNT DETAILS PAGE ============================== */
const AccountDetails = () => {
  const dispatch = useDispatch();
  const navigation = useNavigate();
  const userPermission = useToUserPermission();
  const accessUser = useAccessUser();

  const { user } = useSelector((state) => state.auth);

  const { pathname } = useLocation();
  const isMySettings = pathname?.split('/')?.[1] === 'my-settings';

  const imgURL =
    user &&
    user?.user_personal_details &&
    user?.user_personal_details?.profile_image_url &&
    user?.user_personal_details?.profile_image_url?.replace(
      /(https:\/\/wl-universal-(local|dev|stage)\.s3\.amazonaws\.com|https:\/\/s3\.amazonaws\.com\/universal-clientportalx\.com)/,
      ''
    );

  const [editProfileOpen, setEditProfileOpen] = useState(false);
  const [selectedAvatar, setSelectedAvatar] = useState(imgURL);
  const [isAvatarModalOpen, setIsAvatarModalOpen] = useState(false);
  const [showChangePasswordModal, setShowChangePasswordModal] = useState(false);

  const selectedAvatarFromDb = user?.user_personal_details?.profile_image_url ?? null;

  const pfsAccessId = userPermission?.accessUserID || null;

  const address =
    user && user.user_personal_details && JSON.parse(user?.user_personal_details?.address);

  const [initialValues, setInitialValues] = useState({
    first_name: '',
    last_name: '',
    firm_name: '',
    email: '',
    crd_number: '',
    street_name: '',
    phone_no: '',
    city: '',
    state: '',
    zip_code: '',
    country: 'USA'
  });

  useEffect(() => {
    if (isMySettings) {
      const addressDetails =
        accessUser?.loginUserDetails &&
        JSON.parse(accessUser?.loginUserDetails?.user_personal_details?.address);
      setInitialValues({
        first_name: accessUser?.loginUserDetails?.first_name,
        firm_name: accessUser?.loginUserDetails?.firm?.firm_name,
        last_name: accessUser?.loginUserDetails?.last_name,
        email: accessUser?.loginUserDetails?.email,
        phone_no: accessUser?.loginUserDetails?.phone_no,
        crd_number: accessUser?.loginUserDetails?.firm?.crd_number,
        street_name: `${addressDetails?.line1}, ${addressDetails?.state}, ${addressDetails?.city}, ${addressDetails?.country}, ${addressDetails?.postal_code}`,
        city: addressDetails?.city,
        state: addressDetails?.state,
        country: addressDetails?.country,
        zip_code: addressDetails?.postal_code
      });
    } else {
      setInitialValues({
        first_name: user?.first_name,
        firm_name: user?.firm?.firm_name,
        last_name: user?.last_name,
        email: user?.email,
        phone_no: user?.phone_no,
        crd_number: user?.firm?.crd_number,
        street_name: `${address?.line1}, ${address?.state}, ${address?.city}, ${address?.country}, ${address?.postal_code}`,
        city: address?.city,
        state: address?.state,
        country: address?.country,
        zip_code: address?.postal_code
      });
    }
  }, [user, pathname, isMySettings, accessUser]);

  const { values, errors, touched, handleChange, handleSubmit } = useFormik({
    initialValues,
    enableReinitialize: true
  });

  const handleLogoutUser = () => {
    dispatch(authUserLogout());
    clearPersisterStorage(dispatch);
    navigation('/');
    localObjectClear();
  };

  const handleChangePasswordClick = () => {
    dispatch(
      userForgotPassword({
        email: isMySettings ? accessUser?.loginUserDetails?.email : user?.email,
        firm_id: isMySettings
          ? accessUser?.loginUserDetails?.firm_id?.toString()
          : user?.firm_id?.toString()
      })
    )
      .unwrap()
      .then((response) => {
        if (response.code === 200) setShowChangePasswordModal(true);
      })
      .catch((error) => console.error(error));
  };
  const [isLoader] = useOutletContext();

  const cleanProfileImageUrl = (url) => {
    return url?.replace(
      /(https:\/\/wl-universal-(local|dev|stage)\.s3\.amazonaws\.com|https:\/\/s3\.amazonaws\.com\/universal-clientportalx\.com)/,
      ''
    );
  };

  const trimmedSrc = isMySettings
    ? cleanProfileImageUrl(accessUser?.loginUserDetails?.user_personal_details?.profile_image_url)
    : user?.user_personal_details?.profile_image_url &&
      cleanProfileImageUrl(user.user_personal_details.profile_image_url);

  const profileImageUrl = isMySettings
    ? accessUser?.loginUserDetails?.user_personal_details?.profile_image_url
    : user?.user_personal_details?.profile_image_url;

  const isAvatarText =
    trimmedSrc === '/users/avatar/avatar_6.png' ||
    (isMySettings
      ? profileImageUrl === null
      : user?.user_personal_details?.profile_image_url === null);

  return isLoader ? (
    <SkeletonAccount />
  ) : (
    <React.Fragment>
      <div className="account-details-page">
        <Row>
          <Col lg={9}>
            <div className="cs-setting-page">
              <div className="cs-account-details">
                <div className="cs-profile">
                  <div className="cs-profile-update">
                    <Avatar
                      className={`cs-avatar-md ${isAvatarText ? 'cs-avatar-text' : 'cs-avatar-img'}`}
                      src={profileImageUrl}
                    />
                    {(isMySettings || (!pfsAccessId && !userPermission?.permission_type)) && (
                      <span
                        // className="cursor-pointer edit-image-filled cs-neutral-10"
                        className="cursor-pointer edit-image-filled"
                        onClick={() => setIsAvatarModalOpen(true)}>
                        <CapsyncIcon title="edit-image-filled" size="14" />
                      </span>
                    )}
                  </div>
                  <div className="cs-profile-details">
                    <h2 className="cs-semi-bold-h2 cs-neutral-100 cs-relative">
                      {isMySettings
                        ? accessUser?.loginUserDetails?.display_name
                        : user?.display_name}
                      <span className="verified-filled cs-icon">
                        {/* <Image src={VerifiedIcon} width="14" height="14"></Image> */}
                      </span>
                    </h2>
                    <h4 className="cs-regular-h4 cs-neutral-80">
                      {isMySettings ? accessUser?.loginUserDetails?.email : user?.email}
                    </h4>
                  </div>
                </div>
                <div className="cs-profile-form">
                  <div className="cs-profile-action">
                    <h3 className="cs-regular-h3 cs-neutral-100">Personal information</h3>
                    {(isMySettings || (!pfsAccessId && !userPermission?.permission_type)) && (
                      <Button
                        variant="primary"
                        className="cs-btn-primary sm-btn cs-regular-h5"
                        onClick={() => setEditProfileOpen(true)}>
                        Edit
                      </Button>
                    )}
                  </div>
                  <Form onSubmit={handleSubmit} className="personal-inform-form">
                    <Row>
                      <Col className="col" lg={6} md={6} sm={6}>
                        <InputBox
                          label="First name"
                          name="first_name"
                          placeholder="Enter first name"
                          values={values}
                          errors={errors}
                          touched={touched}
                          disabled={true}
                          onChange={handleChange}
                        />
                      </Col>
                      <Col className="col" lg={6} md={6} sm={6}>
                        <InputBox
                          label="Last name"
                          name="last_name"
                          placeholder="Enter last name"
                          values={values}
                          errors={errors}
                          touched={touched}
                          disabled={true}
                          onChange={handleChange}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={6} md={6} sm={6}>
                        <InputBox
                          label="Email"
                          name="email"
                          placeholder="Enter email address"
                          values={values}
                          errors={errors}
                          touched={touched}
                          disabled={true}
                          onChange={handleChange}
                        />
                      </Col>
                      <Col lg={6} md={6} sm={6}>
                        <InputBox
                          label="Phone number"
                          name="phone_no"
                          placeholder="Enter phone Number"
                          values={values}
                          errors={errors}
                          touched={touched}
                          disabled={true}
                          onChange={handleChange}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col md={12}>
                        <InputBox
                          label="Address"
                          name="street_name"
                          placeholder="Enter Address"
                          values={values}
                          errors={errors}
                          touched={touched}
                          disabled={true}
                          onChange={handleChange}
                        />
                      </Col>
                    </Row>
                    {(isMySettings || (!pfsAccessId && !userPermission?.permission_type)) && (
                      <div className="account-change-password">
                        <span
                          className="cs-regular-h5 cs-prFimary cursor-pointer cs-primary"
                          id="reset-password"
                          onClick={handleChangePasswordClick}>
                          Change password
                        </span>
                        <Button
                          variant="primary"
                          className="cs-btn-primary sm-btn cs-regular-h5"
                          onClick={() => setEditProfileOpen(true)}>
                          Edit
                        </Button>
                      </div>
                    )}
                  </Form>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>
      <EditProfile
        editProfileOpen={editProfileOpen}
        setEditProfileOpen={setEditProfileOpen}
        isMySettings={isMySettings}
      />
      <UpdateAvatar
        isAvatarModalOpen={isAvatarModalOpen}
        setIsAvatarModalOpen={setIsAvatarModalOpen}
        setSelectedAvatar={setSelectedAvatar}
        handleAvatarSelected={setSelectedAvatar}
        selectedAvatar={selectedAvatar}
        selectedAvatarFromDb={selectedAvatarFromDb}
        isMySettings={isMySettings}
      />
      <ChangePasswordLogoutModal
        showChangePasswordModal={showChangePasswordModal}
        setShowChangePasswordModal={setShowChangePasswordModal}
        handleContinue={handleLogoutUser}
      />
    </React.Fragment>
  );
};

export default AccountDetails;
