import React, { useState } from 'react';
import { Accordion, Table, Badge, Row, Col } from 'react-bootstrap';
import fileDownload from 'js-file-download';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { PDFDocument } from 'pdf-lib';
import PropTypes from 'prop-types';
import moment from 'moment';
import axios from 'axios';

// COMPONENT IMPORTS
import CustomTable from '../components/CustomTable';
import CapsyncIcon from '../../components/CapsyncIcon';
import { TOAST_MESSAGE } from '../../constants/message';
import UserProgressIndicator from './UserProgressIndicator';
import CapsyncToolTip from '../../components/CapsyncToolTip';

// API
import { signatureReminder } from '../../slices/requestSlice';

/* ============================== SIGNATURE REQUEST FROM ============================== */
const SignatureRequestFrom = ({
  setShow,
  data,
  rowStartIdx,
  rowsPerPage,
  setShowShareModal,
  tab,
  setAccordionItemDetails,
  setCanvasDetails,
  setRowData,
  rowData,
  setEntireObjDetails,
  entireObjDetails,
  canvasDetails
}) => {
  const dispatch = useDispatch();

  const [activeRow, setActiveRow] = useState(-1);
  const [isLoading, setIsLoading] = useState(false);

  const handleDetailButton = (item) => {
    // setCanvasDetails(item);
    setCanvasDetails({ type: 'signatureFrom', ...item });
    setShow(true);
    setRowData({ type: 'signatureFrom', ...item });
  };

  const handleReminder = (item) => {
    setCanvasDetails(item);
    const signatureID = !item.signature_users
      ? item.signature_id.toString()
      : item.signature_users[0].signature_id.toString();
    const userID = !item.signature_users
      ? item.id.toString()
      : item.signature_users[0].id.toString();

    const status = !item.signature_users ? item.status : item.signature_users[0].status;
    setIsLoading(true);
    if (status === 1) {
      dispatch(
        signatureReminder({
          signature_id: signatureID,
          signature_user_id: userID
        })
      ).then((res) => {
        setIsLoading(false);
        if (res.payload.status) {
          toast.success(res.payload.message);
        } else {
          toast.error(res.payload.message);
        }
      });
    }
  };

  const handleExportData = async (item) => {
    const randomNumber = Math.floor(Math.random() * 9000) + 1000;
    const values = item?.sign_pfs_link;
    if (values) {
      try {
        const username = process.env.REACT_APP_HELLO_SIGN_USER_NAME;
        const password = process.env.REACT_APP_HELLO_SIGN_PASSWORD;

        const token = btoa(`${username}:${password}`);
        const headers = { Authorization: `Basic ${token}` };
        const response = await axios.get(values, {
          headers,
          responseType: 'arraybuffer'
        });
        if (response.status === 200) {
          const fileData = response.data;

          const pdfDoc = await PDFDocument.create();
          const downloadedPDF = await PDFDocument.load(fileData);
          const pageCount = downloadedPDF.getPageCount();

          for (let i = 0; i < pageCount; i++) {
            const [copiedPage] = await pdfDoc.copyPages(downloadedPDF, [i]);
            pdfDoc.addPage(copiedPage);
          }
          const pdfBytes = await pdfDoc.save();
          const blob = new Blob([pdfBytes], { type: 'application/pdf' });
          fileDownload(blob, `PFS${randomNumber}.pdf`);
          toast.success(TOAST_MESSAGE.SIGNATURE_DOWNLOAD_SUCCESS);
          // const url = window.URL.createObjectURL(blob);
          // setURL(url);
        } else {
          console.error('Failed to fetch PDF data. Server responded with status:', response.status);
        }
      } catch (error) {
        console.error('Error fetching PDF:', error);
      }
    }
  };

  const columns = [
    {
      label: <span className="request-heading-icons">Request</span>,
      renderCell: (item) => 'Signature Request',
      width: 248
    },
    {
      label: <span className="request-heading-icons">From</span>,
      renderCell: (item) => item.created_by_name,
      width: 248
    },
    {
      label: <span className="request-heading-icons">Role</span>,
      renderCell: (item) => 'Individual',
      width: 248
    },
    {
      label: <span className="request-heading-icons">Request Date</span>,
      renderCell: (item) => moment(item.createdAt).format('MM/DD/YYYY'),
      width: 248
    },
    {
      label: 'Status',
      renderCell: (item) => {
        const acceptedUser = item?.signature_users?.filter((i) => i.status === 2).length;
        const rejectedUser = item?.signature_users?.filter((i) => i.status === 3).length;
        // const { totalUser, accpetedUser, rejectedUser } = item;
        return (
          <UserProgressIndicator
            totalUser={item?.signature_users?.length}
            noOfUserAccpeted={acceptedUser}
            noOfUserDeclined={rejectedUser}
          />
        );
      },
      width: 320
    },

    {
      label: 'Actions',
      renderCell: (item) => (
        <div
          className={`cs-table-icons ${item?.signature_users?.length > 1 && tab === 'pending' && 'acc-icon-end'}`}>
          <div className="cs-table-icons">
            {tab === 'pending' &&
              item.signature_users.length < 2 &&
              (isLoading && item.id === canvasDetails?.id ? (
                <span className={'cs-neutral-80'}>
                  <CapsyncIcon title="loading-outlined" size="18" />
                </span>
              ) : (
                <span className="cs-neutral-80" onClick={() => handleReminder(item)}>
                  <CapsyncToolTip
                    Child={'reminder-outlined'}
                    placement={'top'}
                    size="18"
                    message={'Reminder'}
                    type="icon"
                  />
                </span>
              ))}

            {tab === 'completed' && (
              <div className="acc-icon-start">
                <span
                  className={`cs-neutral-80 ${item.signature_status === 5 || item.signature_status === 4 ? 'cs-disabled' : ''}`}
                  onClick={(e) => {
                    if (item.signature_status !== 5 && item.signature_status !== 4) {
                      handleSharePFSModal(item);
                    }
                    e.stopPropagation();
                  }}>
                  <CapsyncToolTip
                    Child={'share-outlined'}
                    placement={'top'}
                    size="18"
                    message={'Share'}
                    type="icon"
                  />
                </span>
                <span
                  className={`cs-neutral-80 ${item.signature_status === 5 || item.signature_status === 4 ? 'cs-disabled' : ''}`}
                  onClick={(e) => {
                    if (item.signature_status !== 5 && item.signature_status !== 4) {
                      handleExportData(item);
                    }
                    e.stopPropagation();
                  }}>
                  <CapsyncToolTip
                    Child={'download-outlined'}
                    placement={'top'}
                    size="18"
                    message={'Download'}
                    type="icon"
                  />
                </span>
              </div>
            )}
          </div>

          {item?.signature_users?.length === 2 && activeRow === item.id ? (
            <span className="cs-neutral-80 req-accordion-icon">
              <CapsyncIcon title="chevron-up-outlined" size="18" />
            </span>
          ) : item?.signature_users?.length === 1 ? (
            <div
              onClick={() => {
                handleDetailButton(item);
                setEntireObjDetails(item);
                setRowData({ type: 'signatureFrom', ...item });
              }}
              className="req-details">
              <span className="cs-regular-body-text-l cs-neutral-90">Details</span>
              <span className="cs-neutral-80">
                <CapsyncIcon title="chevron-right-outlined" size="18" />
              </span>
            </div>
          ) : (
            <span
              className="cs-neutral-80 req-accordion-icon"
              onClick={() => setEntireObjDetails(item)}>
              <CapsyncIcon title="chevron-down-outlined" size="18" />
            </span>
          )}
        </div>
      ),
      width: 260
    }
  ];

  const handleSharePFSModal = (item) => {
    setShowShareModal(true);
    setRowData(item);
  };

  const statusBadge = (item) => {
    const statusMap = {
      1: 'Pending',
      2: 'Completed',
      3: 'Declined'
    };
    return statusMap[item.status];
  };

  const accordionTableData = [
    {
      label: 'First Name',
      renderCell: (item) => (item.first_name ? item.first_name : '-'),
      width: 220
    },
    {
      label: 'Last Name',
      renderCell: (item) => (item.last_name ? item.last_name : '-'),
      width: 220
    },
    {
      label: 'Email',
      renderCell: (item) => item.email,
      width: 220
    },
    {
      label: 'Phone Number',
      renderCell: (item) => (item.phone ? item.phone : '-'),
      width: 220
    },
    {
      label: 'Status',
      renderCell: (item) => {
        const { status } = item;
        return (
          <Badge
            className={`cs-badge cs-regular-body-text-s ${status === 1 ? 'cs-warning' : status === 2 ? 'cs-success' : 'cs-danger'}`}>
            {statusBadge(item)}
          </Badge>
        );
      },
      width: 220
    },
    {
      label: 'Date',
      renderCell: (item) => moment(item.createdAt).format('MM/DD/YYYY'),
      width: 220
    },
    {
      label: `${tab === 'pending' ? 'Action' : ''}`,
      renderCell: (item) => {
        const firstUser = entireObjDetails?.signature_users.find((user) => user.is_first_user);
        return (
          <div className="req-accordion-table-icons">
            <div className="cs-table-icons">
              {tab === 'pending' &&
                (isLoading && item.id === canvasDetails?.id ? (
                  <span className={'cs-neutral-80'}>
                    <CapsyncIcon title="loading-outlined" size="18" />
                  </span>
                ) : (
                  <span
                    className={`cs-neutral-80 ${(!item.is_first_user && firstUser?.status === 1) || item.status === 2 ? 'cs-disabled' : ''}`}
                    onClick={() => {
                      if (
                        !((!item.is_first_user && firstUser?.status === 1) || item.status === 2)
                      ) {
                        handleReminder(item);
                      }
                    }}>
                    <CapsyncToolTip
                      Child={'reminder-outlined'}
                      placement={'top'}
                      size="18"
                      message={'Reminder'}
                      type="icon"
                    />
                  </span>
                ))}
            </div>
            <div className="req-details" onClick={() => handleDetailButton(item)}>
              <span className="cs-regular-body-text-l cs-neutral-90">Details</span>
              <span className="cs-neutral-80">
                <CapsyncIcon title="chevron-right-outlined" size="18" />
              </span>
            </div>
          </div>
        );
      },
      width: 224
    }
  ];

  return (
    <Row>
      <Col>
        {data?.length === 0 ? (
          <span className="cs-common-no-record cs-light-body-text-l cs-neutral-100">
            There are no records to display
          </span>
        ) : (
          <div className="cs-req-table">
            <Table className={`cs-table`}>
              <thead className="cs-thead">
                <tr>
                  {columns.map((column, i) => (
                    <th key={i} width={column && column.width}>
                      {column.label === 'Actions' && tab === 'pending' ? '' : column.label}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody className="cs-tbody">
                {data &&
                  data?.length !== 0 &&
                  data.slice(rowStartIdx, rowStartIdx + rowsPerPage).map((row, rowIndex) => {
                    return (
                      <React.Fragment key={rowIndex}>
                        <tr
                          className={
                            (activeRow === row.id &&
                              row?.signature_users.length > 1 &&
                              'open-accordion') ||
                            (row?.signature_users.length > 1 && 'closed-accordion')
                          }
                          onClick={() =>
                            setActiveRow((prevRowIdx) => (prevRowIdx === row.id ? -1 : row.id))
                          }>
                          {columns.map((column, columnIndex) => (
                            <td
                              key={columnIndex}
                              onClick={() => {
                                setEntireObjDetails(row);
                                setRowData({ type: 'signatureFrom', ...row });
                              }}>
                              {column.renderCell(row)}
                            </td>
                          ))}
                        </tr>
                        {activeRow === row.id && row?.signature_users.length > 1 && (
                          <tr>
                            <td colSpan={accordionTableData.length} className="req-acc-data">
                              <div className="cs-req-acc-table">
                                <Accordion defaultActiveKey={row.id}>
                                  <Accordion.Item
                                    eventKey={row.id}
                                    onClick={() => {
                                      setAccordionItemDetails(row);
                                      setEntireObjDetails(row); // Set details on accordion item click
                                    }}>
                                    <CustomTable
                                      columns={accordionTableData}
                                      data={row?.signature_users}
                                      columnVisible={false}
                                    />
                                  </Accordion.Item>
                                </Accordion>
                              </div>
                            </td>
                          </tr>
                        )}
                      </React.Fragment>
                    );
                  })}
              </tbody>
            </Table>
          </div>
        )}
      </Col>
    </Row>
  );
};

SignatureRequestFrom.propTypes = {
  setShow: PropTypes.func,
  data: PropTypes.array,
  rowStartIdx: PropTypes.number,
  rowsPerPage: PropTypes.number,
  setShowShareModal: PropTypes.func,
  tab: PropTypes.string,
  setAccordionItemDetails: PropTypes.func,
  setCanvasDetails: PropTypes.func,
  setRowData: PropTypes.func,
  rowData: PropTypes.object,
  entireObjDetails: PropTypes.object,
  setEntireObjDetails: PropTypes.func,
  canvasDetails: PropTypes.object
};
export default SignatureRequestFrom;
