import React, { useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Col, Row, Form, Button } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { useFormik } from 'formik';
import * as yup from 'yup';

// COMPONENT IMPORTS
import { confirmPasswordField, passwordField } from '../validations';
import PasswordRequirement from '../components/PasswordRequirement';
import InputBox from '../components/FormFields/InputBox';
import { TOAST_MESSAGE } from '../constants/message';
import CapsyncIcon from '../components/CapsyncIcon';
import AuthHead from './AuthHead';

// API
import { userChangePassword } from '../slices/authSlice';

/* ============================== RESET PASSWORD PAGE ============================== */
const ResetPassword = () => {
  const dispatch = useDispatch();
  const navigation = useNavigate();
  const param = useParams();

  const [isLoading, setIsLoading] = useState(false);
  const [formError, setFormError] = useState('');
  const [showPwdRequirements, setShowPwdRequirements] = useState(false);

  const { values, errors, touched, handleChange, handleSubmit } = useFormik({
    initialValues: {
      password: '',
      confirm_password: '',
      reset_password_token: param?.resetPasswordToken
    },
    validationSchema: yup.object().shape({
      password: passwordField(),
      confirm_password: confirmPasswordField()
    }),
    onSubmit: (values) => {
      setIsLoading(true);
      dispatch(userChangePassword(values))
        .unwrap()
        .then((response) => {
          if (response?.code !== 200) setFormError(response?.message || 'Somthing went wrong');
          else {
            toast.success(TOAST_MESSAGE.RESET_PASSWORD_SUCCESS);
            setTimeout(() => {
              navigation('/');
            }, 1500);
          }
        })
        .finally(() => setIsLoading(false));
    }
  });

  return (
    <React.Fragment>
      <AuthHead title="Reset Password" />
      <div className="cs-auth-form">
        <Form onSubmit={handleSubmit}>
          <Row>
            <Col lg={12}>
              <InputBox
                name="password"
                type="password"
                placeholder="New Password"
                values={values}
                errors={errors}
                touched={touched}
                onChange={handleChange}
                onBlur={() => setShowPwdRequirements(false)}
                onFocus={() => setShowPwdRequirements(true)}
              />
            </Col>
          </Row>
          {showPwdRequirements && <PasswordRequirement password={values.password} />}
          <Row>
            <Col lg={12}>
              <InputBox
                name="confirm_password"
                type="password"
                placeholder="Enter Confirm password"
                values={values}
                errors={errors}
                touched={touched}
                onChange={handleChange}
              />
            </Col>
          </Row>

          {formError && (
            <span className={`cs-light-body-text-m cs-danger`}>
              <CapsyncIcon title="declined-outlined" size="16" />
              &nbsp;
              <span className={`cs-light-body-text-m cs-danger`}> {formError}</span>
            </span>
          )}

          <div className="auth-footer">
            <Button
              type="submit"
              className="cs-btn-primary lg-btn cs-regular-h5"
              disabled={isLoading}>
              Reset password
            </Button>
          </div>
          <span className="back-to-sign-in">
            <Link className="cs-btn-tertiary lg-btn cs-regular-h5" to="/">
              Back to sign in
            </Link>
          </span>
        </Form>
      </div>
    </React.Fragment>
  );
};

export default ResetPassword;
