import { useMemo } from 'react';
import Cookies from 'universal-cookie';
import { useSelector } from 'react-redux';
import secureLocalStorage from 'react-secure-storage';
import { createSlice, createAsyncThunk, createAction } from '@reduxjs/toolkit';

// COMPONENT IMPORTS
import axios from '../config/api';
import { setMFA } from './mfaSlice';
import CONFIG from '../constants/config';
import { decrypt, encrypt } from '../utils/system';
import { VALIDATION_MESSAGE } from '../constants/message';

// USERS SIGN-IN API CALL
export const userSignIn = createAsyncThunk('auth/userSignIn', async (data) => {
  return await axios.post(`users/client_signin`, data);
});

// USER FORGOT PASSWORD
export const userForgotPassword = createAsyncThunk('auth/userForgotPassword', async (data) => {
  return await axios.post(`users/forgot_password/request`, data);
});

// USER CHANGE PASSWORD
export const userChangePassword = createAsyncThunk('auth/userChangePassword', async (data) => {
  return await axios.post(`users/forgot_password`, data);
});

// CLEAR FORM AFTER SUBMIT
export const resetForm = createAction('auth/resetForm');

// SET MFA true
export const setMFATrue = createAction('auth/mfa-success');
export const setUserAuthentication = createAction('auth/user-auth-success');

// LOG OUT USER AND CLEAR USER DETAILS
export const authUserLogout = createAction('auth/logout');

// MFA API CALLS
export const generateMFAOTP = createAsyncThunk('/users/users_mfa', async ({ mfaType, payload }) => {
  const res = await axios.post(`users/users_mfa/${mfaType}`, payload);
  return res;
});

export const googleMFASetup = createAsyncThunk(
  '/users/mfa_otp_verify/mfa_google_setup',
  async (payload) => {
    const res = await axios.put(`users/mfa_otp_verify/mfa_google_setup`, payload);
    return res;
  }
);

// Reset MFA
export const resetMFA = createAsyncThunk(
  '/users/mfa_otp_verify/mfa_google_setup',
  async ({ mfaType, payload }) => {
    const res = await axios.put(`users/reset_mfa/${mfaType}`, payload);
    return res;
  }
);

export const verifyMFAOTP = createAsyncThunk(
  '/users/mfa_otp_verify',
  async ({ mfaType, payload }) => {
    const res = await axios.post(`users/mfa_otp_verify/${mfaType}`, payload);
    return res;
  }
);

// GET USER DETAILS
export const getUserDetails = createAsyncThunk('/users/getuser', async (id) => {
  const res = await axios.get(`users/get_user_details_by_id/${id}`);
  return res;
});

// GET LOGIN USER DETAILS
export const getLoginUserDetails = createAsyncThunk('/users/get_login_user', async (id) => {
  const res = await axios.get(`users/get_user_details_by_id/${id}`);
  return res;
});

// UPDATE USER DETAILS
export const updateUserDetails = createAsyncThunk('/users/updateuser', async (payload) => {
  const res = await axios.put(`users/update_user`, payload);
  return res;
});

// USER EMAIL VERIFICATION
export const emailVerification = createAsyncThunk('auth/verify_user', async (token) => {
  const res = await axios.get(`users/verify_user/${token}`);
  return res;
});

// USER CHANGE PROFILE IMAGE
export const userChangeProfileImage = createAsyncThunk(
  'auth/userChangeProfileImage',
  async (data) => {
    const res = await axios.put(`users/upload_profile`, data);
    return res;
  }
);

// USER CHANGE AVATAR
export const userChangeAvatar = createAsyncThunk('auth/userChangeAvatar', async (data) => {
  const res = await axios.put(`users/set_avatar`, data);
  return res;
});

export const otherFileChangePassword = createAsyncThunk(
  'file-encryption/changeOtherPassword',
  async (data, { rejectWithValue }) => {
    const res = await axios.put(`users/settings/file_encryption/other_pfs_file`, data);
    if (res.code === 200) {
      return res;
    } else {
      return rejectWithValue(res.message || '');
    }
  }
);
/** File Encryption Change password API */
export const fileChangePassword = createAsyncThunk(
  'file-encryption/changePassword',
  async (data, { rejectWithValue }) => {
    const res = await axios.put(`users/settings/file_encryption/pfs_file`, data);
    if (res.code === 200) {
      return res;
    } else {
      return rejectWithValue(res.message || '');
    }
  }
);

// GET ACCESS UERR LIST
export const toAccessUserList = createAsyncThunk(
  'account-access/toAccessUserList',
  async (params, { rejectWithValue }) => {
    try {
      const res = await axios.get(`client/account/access-list`, { params });
      if (res.code === 200) {
        return res;
      } else {
        return rejectWithValue(res.message || '');
      }
    } catch (error) {
      console.log('error :>> ', error);
    }
  }
);

// PROFILE VIEW USERS API
export const profileViewList = createAsyncThunk(
  'account-access/profile-view-list',
  async (params, { rejectWithValue }) => {
    try {
      const res = await axios.get(`profile/views`, { params });
      if (res.code === 200) {
        return res;
      } else {
        return rejectWithValue(res.message || '');
      }
    } catch (error) {
      console.log('error :>> ', error);
    }
  }
);
export const clientViewByProfessionalUser = createAsyncThunk(
  'client/clientViewByProfessionalUser',
  async (payload, { rejectWithValue }) => {
    try {
      const BASE_URL = process.env.REACT_APP_API_URL + '/api/';
      const { token, clientId, professionalUserId } = payload;
      const config = { headers: { Authorization: `Bearer ${token}` } };
      const response = await axios.get(
        `${BASE_URL}client/account/view?user_id=${clientId}&professional_user_id=${professionalUserId}`,
        config
      );
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const clientViewByIndividual = createAsyncThunk(
  'client/clientViewByIndividual',
  async (payload, { rejectWithValue }) => {
    try {
      const BASE_URL = process.env.REACT_APP_API_URL + '/api/';
      const { token, clientId, professionalUserId } = payload;
      const config = { headers: { Authorization: `Bearer ${token}` } };
      const response = await axios.get(
        `${BASE_URL}client/account/view?user_id=${clientId}&professional_user_id=${professionalUserId}`,
        config
      );
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// USER SIGNUP API
export const userSignUp = createAsyncThunk('/users/signup', async (data) => {
  const { id, payload } = data;
  const res = await axios.put(`users/profile_set/${id}`, payload);
  return res;
});

// CLEAR USER VERIFIED
export const clearUserVerified = createAsyncThunk('users/clearUserVerified');

// INITIAL STATE
const initialState = {
  user: {},
  status: null,
  message: '',
  token: null,
  isAuthenticated: false,
  verfiedToken: null,
  userVerifiedDetails: null,
  accessUser: null,
  tabs: null,
  signInUser: null,
  toUserPermission: null,
  toAccessUser: null,
  profileViewUsers: null,
  accessUserIcon: null
};

/* ============================== AUTH SLICE ============================== */
export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    resetAuthSlice: () => initialState,
    setRemoveMessage: (state) => {
      state.message = '';
    },
    setVerfiedToken: (state, action) => {
      state.verfiedToken = action.payload;
    },
    setSavedAvatar: (state, action) => {
      state.savedAvatar = action.payload;
    },
    setProfissionalUsertoClient: (state, action) => {
      state.user = action.payload;
    },
    accessUserDetails: (state, action) => {
      state.accessUser = action.payload;
    },
    setTabCount: (state, action) => {
      state.tabs = action.payload;
    },
    setToUserPermission: (state, action) => {
      state.toUserPermission = action.payload;
    },
    setSignInUser: (state) => {
      state.signInUser = decrypt(localStorage.getItem('id'));
    },
    setAccessUserIcon: (state, action) => {
      state.accessUserIcon = action.payload;
    }
  },
  extraReducers: (builder) => {
    // SIGN UP API CASES
    builder
      .addCase(userSignIn.fulfilled, (state, { payload }) => {
        const { data: { loginUserData = {}, token } = {}, status, message } = payload;
        state.status = status ? CONFIG.STATUS.SUCCESS : CONFIG.STATUS.ERROR;
        if (status) {
          const cookie = new Cookies();
          state.isAuthenticated = !!cookie.get(`wlpdeviceAuth24Hr-${loginUserData?.id}`);
          state.user = loginUserData;
          state.token = token;
          state.savedAvatar =
            loginUserData &&
            loginUserData.user_personal_details &&
            loginUserData.user_personal_details.profile_image_url;
          secureLocalStorage.setItem('token', token);
          localStorage.setItem('id', encrypt(loginUserData?.id?.toString()));
        } else {
          state.message = message;
        }
      })
      .addCase(clientViewByProfessionalUser.fulfilled, (state, { payload }) => {
        const { status, message } = payload;
        state.status = status ? CONFIG.STATUS.SUCCESS : CONFIG.STATUS.ERROR;
        state.message = message;
        if (status) {
          state.isAuthenticated = true;
          state.user = payload?.data;
          state.savedAvatar = payload?.data?.user_personal_details?.profile_image_url;
          localStorage.setItem('id', encrypt(payload?.data?.id?.toString()));
        }
      })
      .addCase(clientViewByIndividual.fulfilled, (state, { payload }) => {
        const { status, message } = payload;
        state.status = status ? CONFIG.STATUS.SUCCESS : CONFIG.STATUS.ERROR;
        state.message = message;
        if (status) {
          state.isAuthenticated = true;
          state.user = payload?.data;
          state.savedAvatar = payload?.data?.user_personal_details?.profile_image_url;
        }
      })
      .addCase(userSignIn.rejected, (state, { payload }) => {
        const { status } = payload;
        state.status = !status ? CONFIG.STATUS.ERROR : null;
      })
      .addCase(userForgotPassword.fulfilled, (state, { payload }) => {
        const { status, message } = payload;
        state.status = status ? CONFIG.STATUS.SUCCESS : CONFIG.STATUS.ERROR;
        state.message = message;
      })
      .addCase(userForgotPassword.rejected, (state) => {
        state.status = CONFIG.STATUS.ERROR;
      })
      .addCase(userChangePassword.fulfilled, (state, { payload }) => {
        const { status, message } = payload;
        state.status = status ? CONFIG.STATUS.SUCCESS : CONFIG.STATUS.ERROR;
        state.message = message;
      })
      .addCase(userChangePassword.rejected, (state) => {
        state.status = CONFIG.STATUS.ERROR;
      })
      .addCase(resetForm, (state) => ({ ...state, message: '', status: null }))
      .addCase(setMFATrue, (state) => ({ ...state, mfa: { verified: true } }))
      .addCase(setUserAuthentication, (state, payload) => {
        state.isAuthenticated = payload ? payload.payload : !state.isAuthenticated;
      })
      .addCase(authUserLogout, (state) => ({ ...initialState }))
      .addCase(generateMFAOTP.pending, (state, action) => {
        state.status = CONFIG.STATUS.PROGRESS;
      })
      .addCase(generateMFAOTP.fulfilled, (state, action) => {
        state.status = CONFIG.STATUS.SUCCESS;
        const payload = action.payload;
        const otpAuthUrl =
          payload.data &&
          payload.data.res_data &&
          payload.data.res_data.otpauth_url &&
          payload.data.res_data.otpauth_url.toString();
        state.mfa = { ...state.mfa, otpauth_url: otpAuthUrl };
      })
      .addCase(verifyMFAOTP.fulfilled, (state, action) => {
        state.mfa = { ...state.mfa, ...action.payload };
      })
      .addCase(verifyMFAOTP.rejected, (state, action) => {
        state.mfa.code = 500;
        state.mfa.validation = VALIDATION_MESSAGE.INTERNAL_SERVER_ERROR;
      })
      .addCase(setMFA, (state, action) => {
        state.mfa = action.payload;
      })
      .addCase(getUserDetails.fulfilled, (state, action) => {
        state.user = action?.payload?.data;
      })
      .addCase(emailVerification.fulfilled, (state, { payload }) => {
        state.userVerifiedDetails = payload?.data;
      })
      .addCase(userChangeAvatar.fulfilled, (state, { payload }) => {
        state.user.savedAvatar =
          state &&
          state.user_personal_details &&
          state.user_personal_details.data.profile_image_url;
      })
      .addCase(otherFileChangePassword.fulfilled, (state, action) => {
        const { message } = action.payload;
        state.status = CONFIG.STATUS.SUCCESS;
        state.message = message;
      })
      .addCase(otherFileChangePassword.rejected, (state, action) => {
        state.status = CONFIG.STATUS.ERROR;
        state.message = action.payload;
      })
      .addCase(fileChangePassword.fulfilled, (state, action) => {
        const { message } = action.payload;
        state.status = CONFIG.STATUS.SUCCESS;
        state.message = message;
      })
      .addCase(fileChangePassword.rejected, (state, { payload }) => {
        state.status = CONFIG.STATUS.ERROR;
        state.message = payload;
      })
      .addCase(userSignUp.fulfilled, (state, { payload }) => {
        const { status, message } = payload;
        state.status = status ? CONFIG.STATUS.SUCCESS : CONFIG.STATUS.ERROR;
        state.userVerifiedDetails.is_profile_set = true;
        state.message = message;
      })
      .addCase(toAccessUserList.fulfilled, (state, action) => {
        state.toAccessUser = action?.payload?.data;
      })
      .addCase(profileViewList.fulfilled, (state, action) => {
        state.profileViewUsers = action?.payload?.data;
      })
      .addCase(userSignUp.rejected, (state, payload) => {
        const { status, message } = payload;
        state.status = !status ? CONFIG.STATUS.ERROR : null;
        state.message = message;
      })
      .addCase(clearUserVerified.fulfilled, (state) => ({ ...state, userVerifiedDetails: {} }))
      .addCase(getLoginUserDetails.fulfilled, (state, action) => {
        state.accessUser = {
          ...state.accessUser,
          loginUserDetails: action?.payload?.data
        };
      });
  }
});

const { reducer } = authSlice;
export const {
  setRemoveMessage,
  setVerfiedToken,
  accessUserDetails,
  setToken,
  setTabCount,
  setSavedAvatar,
  setToUserPermission,
  setSignInUser,
  setProfissionalUsertoClient,
  resetAuthSlice,
  setAccessUserIcon
} = authSlice.actions;
export default reducer;

export const selectUserid = (state) => state.auth.user?.id;
export const useUserID = () => {
  const userId = useSelector(selectUserid);
  return useMemo(() => userId, [userId]);
};
export const selectUser = (state) => state.auth.user;
export const useUser = () => {
  const user = useSelector(selectUser);
  return useMemo(() => user, [user]);
};
export const selectVerfiedUserDetails = (state) => state.auth.userVerifiedDetails;
export const useUserVerifiedDetails = () => {
  const userVerifiedDetails = useSelector(selectVerfiedUserDetails);
  return useMemo(() => userVerifiedDetails, [userVerifiedDetails]);
};

export const selectServerMessage = (state) => state.auth.message;
export const useServerMessage = () => {
  const message = useSelector(selectServerMessage);
  return useMemo(() => message, [message]);
};
export const selectAccessUser = (state) => state.auth.accessUser;
export const useAccessUser = () => {
  const accessUser = useSelector(selectAccessUser);
  return useMemo(() => accessUser, [accessUser]);
};
export const selectTabs = (state) => state.auth.tabs;
export const useOpentabs = () => {
  const tabs = useSelector(selectTabs);
  return useMemo(() => tabs, [tabs]);
};
export const selectSignInUser = (state) => state.auth.signInUser;
export const useSignInUser = () => {
  const signInUser = useSelector(selectSignInUser);
  return useMemo(() => signInUser, [signInUser]);
};
export const selectToAccessUser = (state) => state.auth.toAccessUser;
export const useToAccessUser = () => {
  const toAccessUser = useSelector(selectToAccessUser);
  return useMemo(() => toAccessUser, [toAccessUser]);
};
export const selectToUserPermission = (state) => state.auth.toUserPermission;
export const useToUserPermission = () => {
  const toUserPermission = useSelector(selectToUserPermission);
  return useMemo(() => toUserPermission, [toUserPermission]);
};
export const selectAccessUserIcon = (state) => state.auth.accessUserIcon;
export const useAccessUserIcon = () => {
  const accessUserIcon = useSelector(selectAccessUserIcon);
  return useMemo(() => accessUserIcon, [accessUserIcon]);
};
export const selectProfileViewUsers = (state) => state.auth.profileViewUsers;
export const useProfileViewUsers = () => {
  const profileViewUsers = useSelector(selectProfileViewUsers);
  return useMemo(() => profileViewUsers, [profileViewUsers]);
};
