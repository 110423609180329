import * as yup from 'yup';

// COMPONENT IMPORTS
import { VALIDATION_MESSAGE } from '../../constants/message';

export const emailFieldPFS = (selectValue) => {
  return selectValue === '2'
    ? yup
        .string()
        .matches(/^\S*$/, VALIDATION_MESSAGE.WHITESPACE)
        .required(VALIDATION_MESSAGE.EMAIL_REQUIRED)
        .test(
          'no-more-than-30-before-at',
          VALIDATION_MESSAGE.REQUIRED_THIRTY_CHARACTERS_BEFORE_AT_SIGN,
          (value) => value.split('@')[0].length <= 30
        )
        .test(
          'at-least-1-character-before-at',
          VALIDATION_MESSAGE.REQUIRED_ONE_CHARACTERS_BEFORE_AT_SIGN,
          (value) => value.split('@')[0].length > 0
        )
        .test('custom-email-format', VALIDATION_MESSAGE.INVALID_EMAIL, (value) => {
          return /^[a-z0-9.]+([_+][a-z0-9.]+)*@[a-z0-9.-]+\.[a-z]{2,}$/i.test(value);
        })
        .test('no-capital-letters', VALIDATION_MESSAGE.EMAIL_NOT_UPPERCASE, (value) => {
          return !/[A-Z]/.test(value);
        })
        .email(VALIDATION_MESSAGE.INVALID_EMAIL)
    : '';
};

export const firstNameFieldPFS = (selectValue) => {
  return selectValue === '2'
    ? yup
        .string()
        .strict()
        .matches(/^\S(?:.*\S)?$/, VALIDATION_MESSAGE.NO_SPACE)
        .min(2, VALIDATION_MESSAGE.REQUIRED_TWO_CHARACTERS)
        .max(20, VALIDATION_MESSAGE.REQUIRED_TWENTY_CHARACTERS)
        .required(VALIDATION_MESSAGE.FIRST_NAME_REQUIRED)
    : '';
};
