import { createAsyncThunk } from '@reduxjs/toolkit';

// COMPONENT IMPORTS
import axios from '../config/api';

export const createPaymentIntent = createAsyncThunk('payment/createPaymentIntent', async (data) => {
  return await axios.post(`payment/create_paymentIntent`, data);
});

export const billingHistory = createAsyncThunk(
  'payment/billingHistory',
  async ({ userID, userRole, params }) => {
    return await axios.get(`/payment/get_payment_history/${userID}/${userRole}`, { params });
  }
);
