import React, { useState, useEffect } from 'react';
import { Container, Table, Row, Col, Accordion } from 'react-bootstrap';
import { Link, useNavigate, useOutletContext } from 'react-router-dom';
import PropTypes from 'prop-types';
import moment from 'moment';

// COMPONENT IMPORTS
import Donut from '../components/Donut';
import { amortizationHeader } from '../../constants/config';
import CapsyncToolTip from '../../components/CapsyncToolTip';
import AmortizationBreadCrumb from './AmortizationBreadCrumb';
import CategoryTableData from '../../components/CategoryTableData';
import SkeletonAmortization from '../components/Skeleton/SkeletonAmortization';

// API
import { useCategoryDetail } from '../../slices/categorySlice';
import { useLastSyncData } from '../../slices/plaidSlice';

/* ============================== AMORTIZATION ============================== */
const Amortization = ({
  allData,
  TotalInterest,
  groupedData,
  handleOpen,
  payFrequency,
  setAddAccountStep
}) => {
  const navigation = useNavigate();
  const categories = useCategoryDetail();
  const lastSyncData = useLastSyncData();
  const hasData = Object.keys(groupedData).length > 0;
  const [urlCheck, setUrlCheck] = useState(
    [...Array(5)].map(() => {
      return { url: '', category: '', title: '' };
    })
  );
  useEffect(() => {
    setUrlCheck([
      {
        url: '/liabilities/installmentsaccount',
        category: 'installmentsaccount',
        title: 'Installment Account (Other)'
      },
      {
        url: '/liabilities/installmentsauto',
        category: 'installmentsauto',
        title: 'Installment Account (Auto)'
      },
      {
        url: '/liabilities/notespayabletobanksandothers',
        category: 'notespayabletobanksandothers',
        title: 'Notes Payable to Banks and Others'
      },
      {
        url: '/assets/accountnotesreceivable',
        category: 'accountnotesreceivable',
        title: 'Accounts & Notes Receivable'
      },
      {
        url: '/liabilities/mortgagesonrealestate',
        category: 'mortgagesonrealestate',
        title: 'Mortgages on Real Estate'
      }
    ]);
  }, [categories]);
  const formattedDate =
    allData && allData.date_Loan
      ? moment(allData.date_Loan).format('MM/DD/YY')
      : moment(allData.loanStarting_date).format('MM/DD/YY');

  let amortizationDonutValue;
  if (
    typeof TotalInterest === 'number' &&
    !isNaN(TotalInterest) &&
    typeof allData.loan_amount === 'number' &&
    !isNaN(allData.loan_amount)
  ) {
    amortizationDonutValue = [TotalInterest, allData.loan_amount];
  }
  const amortizationDonutHeader = [`Interest`, `Principal `];
  const amortizationPieChartColors = ['#EA780E', '#0024D4'];
  const total =
    amortizationDonutValue && amortizationDonutValue.reduce((sum, value) => sum + value, 0);

  useEffect(() => {
    if (allData && allData.is_amortization_link_enable === false) {
      navigation(urlCheck.filter((x) => window.location.pathname.includes(x.category))[0].url);
    }
  }, [allData]);

  const [isLoader] = useOutletContext();

  return (
    <Container fluid>
      {isLoader ? (
        <SkeletonAmortization />
      ) : (
        <React.Fragment>
          <div className="cs-section-topbar">
            <AmortizationBreadCrumb urlCheck={urlCheck} allData={allData} />
            <div className="cs-title">
              <h1 className="cs-semi-bold-h1 cs-neutral-100">Amortization Schedule</h1>
              <span className="cs-regular-body-text-m cs-neutral-80">
                {lastSyncData && lastSyncData.lastSyncData
                  ? `Last sync: ${lastSyncData.lastSyncData}`
                  : ''}
              </span>
            </div>
          </div>
          <div className="amortization_headingTable">
            <Row>
              <Col lg={7}>
                <div className="amortization-header">
                  <h2 className="cs-regular-h2 cs-neutral-100">
                    Loan from{' '}
                    {allData.property_name
                      ? allData.property_name && allData.property_name.length > 18
                        ? allData.property_name.slice(0, 18) + '...'
                        : allData.property_name
                      : allData.creditor_name && allData.creditor_name.length > 18
                        ? allData.creditor_name.slice(0, 18) + '...'
                        : allData.creditor_name}
                  </h2>
                  {/* {editAccessUser && ( */}
                  <span className="amortization-icon">
                    <Link onClick={() => setAddAccountStep(categories?.modal_key)}>
                      <span className="cs-primary-main">
                        <CapsyncToolTip
                          Child={'edit-outlined'}
                          placement={'top'}
                          size="22"
                          message={'Edit Details'}
                          type="icon"
                        />
                      </span>
                    </Link>
                  </span>
                  {/* // )} */}
                </div>

                <div className="cs-amortization-table">
                  <Table className="cs-table">
                    <thead className="cs-thead">
                      <tr>
                        <th className="cs-regular-body-text-m cs-neutral-100" colSpan={2}>
                          Loan Values
                        </th>
                      </tr>
                    </thead>
                    <tbody className="cs-tbody">
                      <tr>
                        <td width="500">
                          <h6 className="cs-light-body-text-m cs-neutral-90">Loan Amount</h6>
                        </td>
                        <td width="200">
                          <div className="cs-light-body-text-m cs-neutral-100">
                            <span>$ </span>
                            <span>{Number(allData.loan_amount).toLocaleString()}</span>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <h6 className="cs-light-body-text-m cs-neutral-90">Interest Rate (%)</h6>
                        </td>
                        <td>
                          <div className="cs-light-body-text-m cs-neutral-100">
                            <span>{allData.annual_rate}</span>
                            <span> % </span>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <h6 className="cs-light-body-text-m cs-neutral-90">
                            Loan Term ({payFrequency === 'Monthly' ? 'Months' : 'Years'})
                          </h6>
                        </td>

                        <td>
                          <span className="cs-light-body-text-m cs-neutral-100">
                            {allData.loan_period}
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <h6 className="cs-light-body-text-m cs-neutral-90">Loan Date</h6>
                        </td>
                        <td>
                          <span className="cs-light-body-text-m cs-neutral-100">
                            {formattedDate}
                          </span>
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
              </Col>

              <Col lg={5}>
                <div className="donut-chart-box amortization-donut-box">
                  <div className="chart-heading">
                    <h2 className="cs-semi-bold-h2">Amortization</h2>
                  </div>
                  {amortizationDonutValue && amortizationDonutValue.length === 0 ? (
                    <span className="cs-common-no-record cs-light-body-text-l cs-neutral-100">
                      No data found
                    </span>
                  ) : (
                    <Donut
                      header={amortizationDonutHeader}
                      value={amortizationDonutValue}
                      label="Amortization"
                      total={total}
                      fillColors={amortizationPieChartColors}
                      width={250}
                      height={250}
                      position="right"
                    />
                  )}
                </div>
              </Col>
            </Row>
          </div>
          {/* Amortization Table */}
          <div className="amortization-table-container">
            <Row>
              <Col lg={12}>
                <div className="assets-listing amortization-main-listing">
                  {hasData ? (
                    <Table className="cs-table">
                      <thead id="table-head-details" className="cs-thead commonTable-heading">
                        <tr>
                          {amortizationHeader.map((itr) => {
                            return (
                              <th width={itr.width} className={itr.class} key={itr.title}>
                                <span>{itr.title}</span>
                              </th>
                            );
                          })}
                        </tr>
                      </thead>
                      <tbody className="cs-tbody">
                        {Object.entries(groupedData).map(([year, rows]) => {
                          let acc = 0;
                          let int = 0;
                          let pay = 0;
                          let tot = 0;
                          let princi = 0;
                          rows.forEach((element) => {
                            if (element.principalAmount > -1) {
                              acc += element.total;
                              int += element.Interest;
                              pay += element.monthlyPayement;
                              tot = element.Total;
                              if (element.principalAmount < 0) {
                                princi = 0;
                              } else {
                                princi = element.principalAmount;
                              }
                            }
                          });

                          return (
                            <tr key={year}>
                              <td className="accordion-table-collapse accordion-after" colSpan={9}>
                                <Accordion key={year}>
                                  {tot > 0 && (
                                    <Accordion.Item eventKey={year}>
                                      <Accordion.Header onClick={handleOpen}>
                                        <Table className="cs-table">
                                          <tbody className="cs-tbody cs-regular-sub-heading-m">
                                            <tr>
                                              <td width="180">
                                                <span className="mob-width text-end">
                                                  <CategoryTableData type={'string'} data={year} />
                                                </span>
                                              </td>

                                              <td
                                                width="255"
                                                className="amortization-principal text-end amort-alignment">
                                                <span className="mob-width text-end">
                                                  <CategoryTableData
                                                    type={'amortization_number'}
                                                    data={acc.toLocaleString(undefined, {
                                                      maximumFractionDigits: 2,
                                                      minimumFractionDigits: 2
                                                    })}
                                                  />
                                                </span>
                                              </td>

                                              <td
                                                width="26"
                                                className="amortization-principal-icon"></td>

                                              <td
                                                width="255"
                                                className="amortization-interest text-end amort-alignment">
                                                <span>
                                                  <CategoryTableData
                                                    type={'amortization_number'}
                                                    data={int.toLocaleString(undefined, {
                                                      maximumFractionDigits: 2,
                                                      minimumFractionDigits: 2
                                                    })}
                                                  />
                                                </span>
                                              </td>

                                              <td
                                                width="26"
                                                className="amortization-interest-icon"></td>

                                              <td width="255" className="text-end">
                                                <span className="mob-width text-end">
                                                  <CategoryTableData
                                                    type={'amortization_number'}
                                                    data={pay.toLocaleString(undefined, {
                                                      maximumFractionDigits: 2,
                                                      minimumFractionDigits: 2
                                                    })}
                                                  />
                                                </span>
                                              </td>

                                              <td width="240" className="text-end">
                                                <span className="mob-width text-end">
                                                  <CategoryTableData
                                                    type={'amortization_number'}
                                                    data={tot.toLocaleString(undefined, {
                                                      maximumFractionDigits: 2,
                                                      minimumFractionDigits: 2
                                                    })}
                                                  />
                                                </span>
                                              </td>

                                              <td width="240" className="text-end">
                                                <span className="mob-width text-end">
                                                  <CategoryTableData
                                                    type={'amortization_number'}
                                                    data={princi.toLocaleString(undefined, {
                                                      maximumFractionDigits: 2,
                                                      minimumFractionDigits: 2
                                                    })}
                                                  />
                                                </span>
                                              </td>

                                              <td width="107"></td>
                                            </tr>
                                          </tbody>
                                        </Table>
                                      </Accordion.Header>
                                      <Accordion.Body>
                                        <Table className="cs-table">
                                          <tbody className="cs-tbody">
                                            {rows.map(function (row, index) {
                                              if (row.principalAmount < -1) {
                                                return '';
                                              } else {
                                                return (
                                                  <tr key={index}>
                                                    <td width="180">
                                                      <span>
                                                        {row.dayloop.slice(0, 3) + ', ' + year}
                                                      </span>
                                                    </td>

                                                    <td
                                                      width="257"
                                                      className="amortization-principal text-end amort-alignment">
                                                      <span>
                                                        <CategoryTableData
                                                          type={'amortization_number'}
                                                          data={row.total.toLocaleString(
                                                            undefined,
                                                            {
                                                              maximumFractionDigits: 2,
                                                              minimumFractionDigits: 2
                                                            }
                                                          )}
                                                          // class="dollar-value-gap"
                                                        />
                                                      </span>
                                                    </td>

                                                    <td
                                                      width="26"
                                                      className="amortization-principal-icon"></td>

                                                    <td
                                                      width="255"
                                                      className="amortization-interest text-end amort-alignment">
                                                      <span className="mob-width text-end">
                                                        <CategoryTableData
                                                          type={'amortization_number'}
                                                          data={row.Interest.toLocaleString(
                                                            undefined,
                                                            {
                                                              maximumFractionDigits: 2,
                                                              minimumFractionDigits: 2
                                                            }
                                                          )}
                                                        />
                                                      </span>
                                                    </td>

                                                    <td
                                                      width="26"
                                                      className="amortization-interest-icon"></td>

                                                    <td width="255" className="text-end">
                                                      <span className="mob-width text-end">
                                                        <CategoryTableData
                                                          type={'amortization_number'}
                                                          data={row.monthlyPayement.toLocaleString(
                                                            undefined,
                                                            {
                                                              maximumFractionDigits: 2,
                                                              minimumFractionDigits: 2
                                                            }
                                                          )}
                                                          // class="dollar-value-gap"
                                                        />
                                                      </span>
                                                    </td>

                                                    <td width="240" className="text-end">
                                                      <span className="mob-width text-end">
                                                        <CategoryTableData
                                                          type={'amortization_number'}
                                                          data={row.Total.toLocaleString(
                                                            undefined,
                                                            {
                                                              maximumFractionDigits: 2,
                                                              minimumFractionDigits: 2
                                                            }
                                                          )}
                                                          // class="dollar-value-gap"
                                                        />
                                                      </span>
                                                    </td>

                                                    {row.principalAmount < 0 ? (
                                                      <td width="240" className="text-end">
                                                        <span className="mob-width text-end">
                                                          <CategoryTableData
                                                            type={'amortization_number'}
                                                            data="0"
                                                            // class="dollar-value-gap"
                                                          />
                                                        </span>
                                                      </td>
                                                    ) : (
                                                      <td width="240" className="text-end">
                                                        <span className="mob-width text-end">
                                                          <CategoryTableData
                                                            type={'amortization_number'}
                                                            data={row.principalAmount.toLocaleString(
                                                              undefined,
                                                              {
                                                                maximumFractionDigits: 2,
                                                                minimumFractionDigits: 2
                                                              }
                                                            )}
                                                            // class="dollar-value-gap"
                                                          />
                                                        </span>
                                                      </td>
                                                    )}
                                                    <td width="110"></td>
                                                  </tr>
                                                );
                                              }
                                            })}
                                          </tbody>
                                        </Table>
                                      </Accordion.Body>
                                    </Accordion.Item>
                                  )}
                                </Accordion>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                  ) : (
                    <span>There are no records to display </span>
                  )}
                </div>
              </Col>
            </Row>
          </div>
        </React.Fragment>
      )}
    </Container>
  );
};

// PROPS TYPE
Amortization.propTypes = {
  allData: PropTypes.object,
  TotalInterest: PropTypes.value,
  groupedData: PropTypes.object,
  handleOpen: PropTypes.func,
  payFrequency: PropTypes.string,
  setAddAccountStep: PropTypes.func
};

export default Amortization;
