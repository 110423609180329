import React, { useEffect } from 'react';
import Skeleton from 'react-loading-skeleton';
import { Container, Dropdown } from 'react-bootstrap';
import { useLocation, useNavigate, useOutletContext } from 'react-router-dom';

// COMPONENT IMPORTS
import MFASetup from './Tabs/MFASetup';
import AuditLogs from './Tabs/AuditLogs';
// import BillingHistory from './Tabs/BillingHistory';
import AccountDetails from './Tabs/AccountDetails';
import CapsyncIcon from '../../components/CapsyncIcon';
import CapsyncBreadcrumb from '../../components/CapsyncBreadcrumb';

// API
import { useToUserPermission } from '../../slices/authSlice';

/* ============================== SETTINGS PAGE ============================== */
const Settings = () => {
  const accessSettingPath = ['account-details', 'audit-logs'];
  const navigate = useNavigate();

  const [isLoader] = useOutletContext();
  const userPermission = useToUserPermission();

  const location = useLocation();
  const isMySettings = location?.pathname?.split('/')?.[1] === 'my-settings';

  const { pathname } = useLocation();
  const pathArray = location.pathname.split('/');
  const activeTabPath = pathArray[pathArray.length - 1];

  const navigateTab = (tab) => {
    if (activeTabPath === tab.path) return;
    const currBasePath = pathArray.slice(1, -1);
    navigate(`/${currBasePath.join('/')}/${tab.path}`);
  };

  useEffect(() => {
    if (userPermission) {
      const currentSettingPath = pathname.split('/')[2];
      if (accessSettingPath.includes(currentSettingPath)) {
        return;
      } else {
        navigate('/dashboard');
      }
    }
  }, []);

  const TAB_LIST_ITEMS = [
    {
      label: 'Account Details',
      isShow: true,
      element: <AccountDetails />,
      path: 'account-details'
    },
    // {
    //   label: 'File Encryption',
    //   isShow: isMySettings || !userPermission?.permission_type,
    //   element: <FileEncryption />,
    //   path: 'file-encryption'
    // },
    {
      label: 'MFA Setup',
      isShow: isMySettings || !userPermission?.permission_type,
      element: <MFASetup />,
      path: 'mfa-setup'
    },
    // {
    //   label: 'Billing History',
    //   isShow:
    //     isMySettings ||
    //     (userPermission?.permission_type == 'standard' || !userPermission?.permission_type
    //       ? true
    //       : false),
    //   element: <BillingHistory />,
    //   path: 'billing-history'
    // },
    { label: 'Audit Logs', isShow: true, element: <AuditLogs />, path: 'audit-logs' }
  ];

  const tabContentRender = () => {
    const tab = TAB_LIST_ITEMS.find((tab) => tab.path === activeTabPath);
    if (tab) return tab.element;
    return null;
  };

  return (
    <section className={`settings-main-section ${activeTabPath}-section`}>
      <div className="container-fluid">
        {isLoader ? (
          <div className="cs-section-topbar">
            <div className="skeleton-breadcrumb">
              <div className="skeleton-breadcrumb-1">
                {' '}
                <Skeleton width={50} />
              </div>
              <div className="skeleton-breadcrumb-2">
                {' '}
                <Skeleton width={20} />
              </div>
              <div className="skeleton-breadcrumb-3">
                <Skeleton width={50} />
              </div>
            </div>
            <div className="cs-title">
              <h1 className="default-semi-bold-h1 cs-neutral-100">
                <Skeleton width={200} />
              </h1>
            </div>
          </div>
        ) : (
          <div className="cs-section-topbar">
            <div className="cs-breadcrumb">
              <CapsyncBreadcrumb
                mainTitle={`Dashboard`}
                subTitle={isMySettings ? `My Settings` : `Settings`}
                mainUrl={'../../dashboard/'}
              />
            </div>
            <div className="cs-dashboard-title">
              <h1 className="cs-semi-bold-h1 cs-neutral-100">
                {isMySettings ? 'My Settings' : 'Settings'}
              </h1>
            </div>
          </div>
        )}

        {isLoader ? (
          <div className="setting-mobile-dropdown for-mob">
            <Skeleton width="100" height={30} />
          </div>
        ) : (
          <div className="setting-mobile-dropdown">
            <Dropdown>
              <Dropdown.Toggle className="cs-regular-body-text-l">
                {TAB_LIST_ITEMS.find((item) => item.path === activeTabPath).label}
                <span className="cs-neutral-80">
                  <CapsyncIcon title="chevron-down-outlined" size="16" />
                </span>
              </Dropdown.Toggle>

              <Dropdown.Menu>
                {TAB_LIST_ITEMS.map((tab) => {
                  if (!tab.isShow) return null;

                  return (
                    <Dropdown.Item
                      key={tab.label}
                      onClick={() => navigateTab(tab)}
                      active={activeTabPath === tab.path}
                      className="cs-regular-body-text-l cs-neutral-90">
                      {tab.label}
                    </Dropdown.Item>
                  );
                })}
              </Dropdown.Menu>
            </Dropdown>
          </div>
        )}

        <div className="card">
          <div className="settings-body">
            {isLoader ? (
              <div className="for-des">
                <div className="skeleton-account-tabs">
                  <p className="skeleton-account-tab">
                    {' '}
                    <Skeleton width={100} />
                  </p>
                  <p className="skeleton-account-tab">
                    {' '}
                    <Skeleton width={100} />
                  </p>
                  <p className="skeleton-account-tab">
                    {' '}
                    <Skeleton width={80} />
                  </p>
                  <p className="skeleton-account-tab">
                    {' '}
                    <Skeleton width={150} />
                  </p>
                  <p className="skeleton-account-tab">
                    {' '}
                    <Skeleton width={100} />
                  </p>
                </div>
              </div>
            ) : (
              <div className="settings-tab-btn cs-tabs">
                {TAB_LIST_ITEMS.map((tab) => {
                  if (!tab.isShow) return null;
                  return (
                    <button
                      type="button"
                      key={tab.label}
                      onClick={() => navigateTab(tab)}
                      className={`cs-stroke-bottom-tab cs-regular-h5 btn btn-primary ${activeTabPath === tab.path && 'cs-stroke-bottom-active'}`}>
                      {tab.label}
                    </button>
                  );
                })}
              </div>
            )}
            <Container fluid>
              {/* <TabContentRender /> */}
              {tabContentRender()}
            </Container>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Settings;
