import React, { useEffect } from 'react';
import { Col, Row, Form, Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import secureLocalStorage from 'react-secure-storage';
import { Link } from 'react-router-dom';
import { useFormik } from 'formik';
import * as yup from 'yup';

// COMPONENT IMPORTS
import useRemoveServerError from '../../components/hooks/useRemoveServerError';
import { emailField, passwordField } from '../../validations';
import InputBox from '../../components/FormFields/InputBox';
import { getDomainName } from '../../utils/common';
import AuthHead from '../AuthHead';

// API
import { authUserLogout, resetForm, userSignIn, useUserID } from '../../slices/authSlice';
import { handleLastSync, handleLastSyncData } from '../../slices/plaidSlice';
import { useBrandDetails } from '../../slices/brandingSlice';

/* ============================== SIGN IN PAGE ============================== */
const SignIn = () => {
  const dispatch = useDispatch();

  const token = secureLocalStorage.getItem('token');
  useRemoveServerError();

  const { message } = useSelector((state) => state.auth);
  const { firm_id } = useBrandDetails();
  const user_id = useUserID();

  const { values, errors, touched, handleChange, handleSubmit } = useFormik({
    initialValues: {
      email: '',
      password: '',
      domain_name: getDomainName()
    },
    validationSchema: yup.object().shape({
      email: emailField(),
      password: passwordField()
    }),
    onSubmit: async (values) => {
      const payload = Object.assign({}, values);
      payload['firm_id'] = firm_id.toString();
      payload['domain_name'] = payload['domain_name'] + process.env.REACT_APP_COMMAN_URL;
      try {
        await dispatch(userSignIn(payload)).unwrap();
      } catch (e) {
        console.log('e', e);
      }
    }
  });

  useEffect(() => {
    if (token && token !== null) {
      dispatch(handleLastSyncData(user_id))
        .unwrap()
        .then((resp) => {
          if (resp.code === 200) {
            dispatch(handleLastSync(resp.data));
          } else {
            dispatch(handleLastSync({}));
          }
        });
    }
  }, [user_id]);

  useEffect(() => {
    if (!token) dispatch(authUserLogout());
    if (message !== '') {
      dispatch(resetForm());
    }
  }, [values]);

  return (
    <React.Fragment>
      <AuthHead title="Sign in" />
      <div className="cs-auth-form">
        <Form onSubmit={handleSubmit}>
          <Row>
            <Col lg={12}>
              <InputBox
                type="email"
                name="email"
                values={values}
                errors={errors}
                touched={touched}
                onChange={handleChange}
                placeholder="Enter email address"
              />
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              <div className="last-form-field">
                <InputBox
                  name="password"
                  type="password"
                  placeholder="Enter password"
                  values={values}
                  errors={errors}
                  touched={touched}
                  onChange={handleChange}
                />
              </div>
            </Col>
          </Row>
          {/* API ERROR MESSAGE DISPLAY */}
          <Row>
            <Col className="text-start">
              <span className="cs-light-body-text-m cs-danger">{message}</span>
            </Col>
          </Row>

          <Row>
            <Col className="text-end">
              <Link className="cs-primary cs-regular-sub-heading-m" to="/forgot-password">
                {' '}
                Forgot password?
              </Link>
            </Col>
          </Row>

          <Row>
            <Col>
              <div className="auth-footer">
                <Button type="submit" className="cs-btn-primary lg-btn cs-regular-h5">
                  {' '}
                  Sign in
                </Button>
              </div>
            </Col>
          </Row>
        </Form>
      </div>
    </React.Fragment>
  );
};

export default SignIn;
