import React, { useEffect, useState } from 'react';
import { Button, Col, Dropdown, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

// COMPONENT IMPORTS
import CapsyncIcon from '../../components/CapsyncIcon';
import CapsyncBreadcrumb from '../../components/CapsyncBreadcrumb';
import CapsyncSectionHeader from '../../components/CapsyncSectionHeader';

// API
import {
  getMethodDataByUniqueID,
  setInvestorType,
  setLoader,
  setMethodName,
  setRowID,
  updateInvestorType
} from '../../slices/accreditationSlice';

/* ============================== COMMON METHOD HEADER ============================== */
const CommonMethodsHeader = () => {
  const investorTypeArray = [
    'Angel',
    'EB-5',
    'Friends & Family',
    'Financial Institution',
    'Insurance',
    'Private Equity',
    'Private Investor',
    'Venture Capital',
    'Other',
    'None or N/A'
  ];

  const [selectInverstorType, setSelectInverstorType] = useState(
    investorTypeArray[investorTypeArray.length - 1]
  );

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { initMethodName, rowID, loader } = useSelector((state) => state.accreditation);

  const usersMethodDataByUniqueID = () => {
    dispatch(getMethodDataByUniqueID({ unique_id: rowID }))
      .unwrap()
      .then((response) => {
        if (response.code === 200) {
          setSelectInverstorType(response.data.investor_type || selectInverstorType);
          dispatch(setInvestorType(response.data.investor_type || selectInverstorType));
        } else if (response.code === 500) {
          toast.warning(response.message);
          navigate('/verifyinvestor');
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    dispatch(setInvestorType(selectInverstorType));
    usersMethodDataByUniqueID();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const checkInvestorType = async (param) => {
    await dispatch(setLoader({ investorTypeLoader: true }));
    setSelectInverstorType(param);
    await dispatch(updateInvestorType({ investor_type: param, unique_id: rowID }))
      .unwrap()
      .then((response) => {
        toast.success(response.message);
        if (response.code === 200) {
          dispatch(setInvestorType(param));
        }
      })
      .catch((err) => {
        console.log(err);
      });
    await dispatch(setLoader({ investorTypeLoader: false }));
  };

  const changeStatusTab = (tabName) => {
    if (tabName === 'Individual') {
      dispatch(setMethodName('individuals'));
      dispatch(setRowID(rowID));
      navigate(`/verifyinvestor/verification-type`);
    } else if (tabName === 'Entity') {
      dispatch(setMethodName('entities'));
      dispatch(setRowID(rowID));
      navigate(`/verifyinvestor/verification-type`);
    } else {
      dispatch(setMethodName('trusts'));
      dispatch(setRowID(rowID));
      navigate(`/verifyinvestor/verification-type`);
    }
  };

  const pageTitle =
    initMethodName === 'individuals' ||
    initMethodName === 'entities' ||
    initMethodName === 'entitiestwoowner' ||
    initMethodName === 'trusts' ||
    initMethodName === 'showtrustmethods' ||
    initMethodName === 'changemethodtono' ||
    initMethodName === 'changemethodtoopenthree' ||
    initMethodName === 'showindividualmethod'
      ? 'Accredited Investor'
      : initMethodName === 'qualifiedclient'
        ? 'Qualified Client'
        : 'Qualified Purchaser';

  return (
    <React.Fragment>
      <div className="cs-section-topbar financial-advisor-topbar">
        <div className="client-topbar">
          <div className="cs-breadcrumb">
            <CapsyncBreadcrumb
              mainTitle="Investor Accreditation"
              mainUrl="/verifyinvestor"
              subTitle={pageTitle}
            />
          </div>
          <CapsyncSectionHeader pageTitle={pageTitle} hasLastSync={false} />
        </div>
      </div>
      <div className="accredited-common-box">
        <div className="accredited-title">
          <h3 className="cs-semi-bold-h3 cs-neutral-90">
            {initMethodName === 'individuals' ||
            initMethodName === 'entities' ||
            initMethodName === 'entitiestwoowner' ||
            initMethodName === 'trusts' ||
            initMethodName === 'showtrustmethods' ||
            initMethodName === 'changemethodtono' ||
            initMethodName === 'changemethodtoopenthree' ||
            initMethodName === 'showindividualmethod'
              ? 'Accredited Investor Verification Methods'
              : initMethodName === 'qualifiedclient'
                ? 'Qualified Client Verification Methods'
                : 'Qualified Purchaser Verification Methods'}
          </h3>
          <h4 className="cs-regular-h4 cs-neutral-80">
            What type of investor are you? This helps us understand who is using our system. You may
            choose None or N/A if you prefer.
          </h4>
        </div>
        <div className="investor-type-row">
          <Row>
            <Col lg={3}>
              <span className="cs-regular-sub-heading-s">Select Investor type</span>
              <div className="investor-type-dropdown">
                <Dropdown
                  className={`dropdown cs-form-dropdown${loader && loader.investorTypeLoader ? ' cs-disabled' : ''}`}>
                  <Dropdown.Toggle
                    variant="secondary"
                    id="dropdown-basic-accredited"
                    className="dropdown-toggle cs-regular-sub-heading-s">
                    {selectInverstorType}
                    <span className="input-field-icon">
                      <CapsyncIcon title="chevron-down-outlined" size="16" />
                    </span>
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    {investorTypeArray.map((item) => (
                      <Dropdown.Item
                        className={
                          item === selectInverstorType
                            ? 'dropdown-item dropdown-item-active cs-regular-sub-heading-s'
                            : 'dropdown-item cs-regular-sub-heading-s'
                        }
                        key={item}
                        onClick={() => checkInvestorType(item)}>
                        {item}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </Col>
          </Row>
        </div>
        <div className="accredited-tabbing-row cs-regular-body-text-l cs-neutral-80">
          <h3 className="cs-semi-bold-h3 cs-neutral-90">How are you investing?</h3>
          {(initMethodName === 'individuals' ||
            initMethodName === 'entities' ||
            initMethodName === 'entitiestwoowner' ||
            initMethodName === 'trusts' ||
            initMethodName === 'showtrustmethods' ||
            initMethodName === 'changemethodtono' ||
            initMethodName === 'changemethodtoopenthree' ||
            initMethodName === 'showindividualmethod') && (
            <p>
              Click Individual/Joint if you are investing in your own personal name or if you are
              investing with your spouse, but in your own personal names (such as John and Jane
              Doe). If you're investing through an entity or trust, click Entity or Trust, as
              appropriate. If you are investing through an IRA, click the appropriate category
              depending on whether your IRA is set up with your direct names or through an entity or
              trust.
            </p>
          )}
          {(initMethodName === 'individuals' ||
            initMethodName === 'entities' ||
            initMethodName === 'entitiestwoowner' ||
            initMethodName === 'trusts' ||
            initMethodName === 'showtrustmethods' ||
            initMethodName === 'changemethodtono' ||
            initMethodName === 'changemethodtoopenthree' ||
            initMethodName === 'showindividualmethod') && (
            <div className="cs-tabs">
              <Button
                onClick={() => changeStatusTab('Individual')}
                className={
                  initMethodName === 'individuals'
                    ? 'cs-stroke-default-tab cs-stroke-active cs-regular-h5'
                    : 'cs-stroke-default-tab cs-regular-h5'
                }>
                As an Individual/Joint
              </Button>
              <Button
                onClick={() => changeStatusTab('Entity')}
                className={
                  initMethodName === 'entities' || initMethodName === 'entitiestwoowner'
                    ? 'cs-stroke-default-tab cs-stroke-active cs-regular-h5'
                    : 'cs-stroke-default-tab cs-regular-h5'
                }>
                As an Entity
              </Button>
              <Button
                onClick={() => changeStatusTab('Trust')}
                className={
                  initMethodName === 'trusts' ||
                  initMethodName === 'showtrustmethods' ||
                  initMethodName === 'changemethodtono' ||
                  initMethodName === 'changemethodtoopenthree' ||
                  initMethodName === 'showindividualmethod'
                    ? 'cs-stroke-default-tab cs-stroke-active cs-regular-h5'
                    : 'cs-stroke-default-tab cs-regular-h5'
                }>
                As a Trust
              </Button>
            </div>
          )}

          <p>
            Choose the applicable method Of verification below. You certify that you qualify as{' '}
            {initMethodName === 'qualifiedpurchaser'
              ? 'a qualified purchaser'
              : initMethodName === 'qualifiedclient'
                ? 'a qualified client'
                : 'an accredited investor'}{' '}
            using the method you select.
          </p>
        </div>
      </div>
    </React.Fragment>
  );
};

export default CommonMethodsHeader;
