import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';

// COMPONENT IMPORTS
import Amortization from './Amortization';
import { calculateAmortization } from '../../utils/common';
import { ADD_ACCOUNT_MODALS } from '../../constants/addAccount';
import NotesPayable from '../liabilities/categoryForms/NotesPayable';
import NotesReceivable from '../assets/categoryForms/NotesReceivable';
import MortgagesOnRealEstate from '../liabilities/categoryForms/MortgagesOnRealEstate';
import InstallmentAccountAuto from '../liabilities/categoryForms/InstallmentAccountAuto';
import InstallmentAccountOther from '../liabilities/categoryForms/InstallmentAccountOther';

// API
import {
  assetGetOneRecord,
  liabilityGetOneRecord,
  useCategoryDetail
} from '../../slices/categorySlice';
import { useUserID } from '../../slices/authSlice';

/* ============================== AMORTIZATION ============================== */
const AmortizationMain = () => {
  const dispatch = useDispatch();
  const { row_id } = useParams();

  const categories = useCategoryDetail();
  const userID = useUserID();

  const [allData, setAllData] = useState({});
  const [show, setShow] = useState(false);
  const [addAccountStep, setAddAccountStep] = useState(ADD_ACCOUNT_MODALS.MAIN_MODAL);
  const [record, setRecord] = useState({});
  const [refreshData, setRefreshData] = useState(false);

  useEffect(() => {
    const data = {
      mainCategory: `${categories.parent === 'liabilities' ? 'liability' : 'assets'}`,
      rowId: row_id,
      userId: userID,
      category: categories && categories.category_id
    };
    if (categories.parent === 'liabilities') {
      dispatch(liabilityGetOneRecord(data))
        .unwrap()
        .then((response) => {
          if (response.code === 200) {
            setAllData(response && response.data[0]);
            setRecord(response && response.data[0]);
          }
        })
        .catch((err) => {});
    } else {
      dispatch(assetGetOneRecord(data))
        .unwrap()
        .then((response) => {
          if (response.code === 200) {
            setAllData(response && response.data[0]);
            setRecord(response && response.data[0]);
          }
        })
        .catch((err) => {});
    }
  }, [categories, refreshData]);

  const handleOpen = () => {
    setShow(!show); // Toggle accordion
  };
  // --------------------------------------------------------------------------------------------------------------
  // Amortization table calculations started here

  const startdate = allData.date_loan ? allData.date_loan : allData.loanStarting_date;
  const enddate = allData.maturity_date;
  const TotalBalance = allData.loan_amount;
  const payFrequency = allData.frequency ? allData.frequency : allData.frequencyTime;
  const principall = allData.loan_amount; // Principal amount
  const interestRate = allData.annual_rate; // 5% interest rate
  const time =
    payFrequency == 'Monthly' || payFrequency == 'monthly'
      ? allData.loan_period
      : allData.loan_period * 12; // Time in years
  const paymentsPerYear = 12;

  const monthlyPaymentt = calculateAmortization(
    principall,
    interestRate,
    time,
    paymentsPerYear,
    payFrequency
  );

  const simpleInterest = monthlyPaymentt * time - TotalBalance;

  const TotalPaidAmount = allData.payment;
  const TotalInterest = TotalPaidAmount - TotalBalance;

  const amortizationTable = [];
  const interRate = allData.annual_rate / 100;
  let remainingAmount = allData.loan_amount + simpleInterest;

  const start = new Date(startdate);

  start.setDate(1);
  const end = new Date(enddate);

  let principal = allData.loan_amount;
  const prinicipalpayementt = monthlyPaymentt - principal * (interRate / 12);

  const interestt = monthlyPaymentt - prinicipalpayementt;

  let loop = new Date(start);
  let interesttPaid = interestt - interestt;

  let month = 0;
  while (remainingAmount > 0 && month < time * 12 && loop <= end) {
    const newDate = loop.setMonth(loop.getMonth() + 1);

    loop = new Date(newDate);

    const monthlyInterest = remainingAmount * (time / 12);
    const monthlyPayement = Math.min((allData.loan_amount + simpleInterest) / time);

    remainingAmount -= monthlyPayement;

    const prinicipalpayement = monthlyPayement - principal * (interRate / 12);
    principal -= prinicipalpayement;

    const interest = monthlyPayement - prinicipalpayement;
    interesttPaid += interest;

    const amortizationRow = {
      Total: interesttPaid,
      month: month + 1,
      dayloop: loop.toDateString().split(' ').slice(1).join(' '),
      total: prinicipalpayement,
      Interest: interest,
      principalAmount: principal,
      interestPaid: monthlyInterest,
      monthlyPayement: monthlyPayement,
      remainingAmount: remainingAmount
    };

    amortizationTable.push(amortizationRow);
    month++;
  }

  // Amortization table calculation ends here

  // Group the data by year
  const groupedData = amortizationTable.reduce((acc, row) => {
    const year = new Date(row.dayloop).getFullYear();

    if (!acc[year]) {
      acc[year] = [];
    }
    acc[year].push(row);
    return acc;
  }, {});

  const handleCategoryFormCancel = () => {
    setAddAccountStep(ADD_ACCOUNT_MODALS.ADD_MANUAL_ACCOUNT_MODAL);
  };
  const handleCategoryFormSubmit = () => {
    setAddAccountStep(ADD_ACCOUNT_MODALS.ACCOUNT_LINKED);
    setRefreshData((prev) => !prev);
  };
  return (
    <section className="main-section amortizationSection">
      <Amortization
        allData={allData}
        groupedData={groupedData}
        simpleInterest={simpleInterest}
        TotalInterest={TotalInterest}
        TotalPaidAmount={TotalPaidAmount}
        handleOpen={handleOpen}
        payFrequency={payFrequency}
        setAddAccountStep={setAddAccountStep}
      />
      {addAccountStep === ADD_ACCOUNT_MODALS.ACCOUNT_NOTES_RECEIVABLES && (
        <NotesReceivable
          isOpen={addAccountStep === ADD_ACCOUNT_MODALS.ACCOUNT_NOTES_RECEIVABLES}
          record={record}
          handleCancel={handleCategoryFormCancel}
          handleCategoryFormSubmit={handleCategoryFormSubmit}
        />
      )}
      {addAccountStep === ADD_ACCOUNT_MODALS.NOTES_PAYABLE && (
        <NotesPayable
          isOpen={addAccountStep === ADD_ACCOUNT_MODALS.NOTES_PAYABLE}
          record={record}
          handleCategoryFormSubmit={handleCategoryFormSubmit}
          handleCancel={handleCategoryFormCancel}
        />
      )}
      {addAccountStep === ADD_ACCOUNT_MODALS.INSTALLMENT_ACCOUNT_AUTO && (
        <InstallmentAccountAuto
          isOpen={addAccountStep === ADD_ACCOUNT_MODALS.INSTALLMENT_ACCOUNT_AUTO}
          record={record}
          handleCategoryFormSubmit={handleCategoryFormSubmit}
          handleCancel={handleCategoryFormCancel}
        />
      )}
      {addAccountStep === ADD_ACCOUNT_MODALS.INSTALLMENT_ACCOUNT_OTHER && (
        <InstallmentAccountOther
          isOpen={addAccountStep === ADD_ACCOUNT_MODALS.INSTALLMENT_ACCOUNT_OTHER}
          record={record}
          handleCategoryFormSubmit={handleCategoryFormSubmit}
          handleCancel={handleCategoryFormCancel}
        />
      )}
      {addAccountStep === ADD_ACCOUNT_MODALS.MORTGAGES_ON_REAL_ESTATE && (
        <MortgagesOnRealEstate
          isOpen={addAccountStep === ADD_ACCOUNT_MODALS.MORTGAGES_ON_REAL_ESTATE}
          record={record}
          handleCategoryFormSubmit={handleCategoryFormSubmit}
          handleCancel={handleCategoryFormCancel}
        />
      )}
    </section>
  );
};

export default AmortizationMain;
