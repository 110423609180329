import { useMemo, useEffect } from 'react';

// COMPONENT IMPORTS
import { checkValueType } from '../common';

/* ============================== TOTAL BALANCE ============================== */
const useTotalBalance = ({ values, setFieldValue }) => {
  const totalBalance = useMemo(() => {
    if (values.balance) {
      return parseFloat(
        checkValueType(values.balance) - checkValueType(values.loan_on_balance)
      ).toFixed(2);
    } else {
      return '';
    }
  }, [values.balance, values.loan_on_balance]);

  useEffect(() => {
    setFieldValue('total_balance', totalBalance);
  }, [totalBalance, setFieldValue]);

  return totalBalance;
};

export default useTotalBalance;
