const commonNames = {
  Portfolio: "Portfolio",
  Assets: "Assets",
  Dashboard: "Dashboard",
  Liabilities: "Liabilities",
  ContingentLiabilities: "Contingent Liabilities",
  AsEndorser: "As Endorser or Co-Maker",
  LegalClaims: "Legal Claims & Judgments",
  Attachments: "Attachments",
  Attachment: "Attachment",
  Accreditation: "Accreditation",
  Requests: "Requests",
  CashOnHands: "Cash On Hands & In Banks",
  SavingsAccount: "Savings Accounts",
  OtherRetirement: "IRA or Other Retirement Account",
  NotesReceivable: "Accounts & Notes Receivable",
  AccountPayable: "Accounts Payable",
  UnpaidTaxes: "Unpaid Taxes",
  OtherLiabilities: "Other Liabilities",
  mortgageOnRealestate: "Mortgages on Real Estate",
  LifeInsurance: "Life Insurance",
  NotesPayable: "Notes Payable to Banks and Others",
  instllmentAccAuto: "Installment Account (Auto)",
  installmentAccountOther: "Installment Account (Other)",
  NonRetirementBrokerage: "Non-Retirement Brokerage Accounts (Stocks & Bonds)",
  RealEstate: "Real Estate",
  Automobile: "Automobiles",
  Businesses: "Businesses",
  PersonalProperty: "Other Personal Property",
  PropertyName: "Property Type",
  OtherAssets: "Other Assets",
  Category: "Category",
  Balance: "Market value",
  BalanceDue: "Balance",
  LoanOnBalance: "Loan on Balance",
  BalanceAs: "Date Updated",
  OriBalance: "Orig. Balance",
  Loans: "Loans",
  LoanAmount: "Loan Amount",
  CashValue: "Cash Value",
  Type: "Type",
  AddurAccount: "Add Your Account",
  Institution: "Institution",
  NameOFDebtor_Property: "Name of Debtor",
  MaturityDate: "Maturity Date",
  Payment: "Payment",
  MonthlyPayment: "Monthly Payment",
  MinimumPayment: "Minimum Payment",
  Frequency: "Frequency",
  ShareValue: "Share Value",
  ShareMarketValue: "Share Market Value",
  MarketValue: "Market Value",
  Ownership_per: "Ownership %",
  Actions: "Actions",
  Transactions: "Transactions",
  Reports: "Reports",
  Add_Account: "Add Account",
  Company: "Institution",
  Insured: "Insured",
  Beneficiary: "Beneficiary",
  FaceValue: "Face Value",
  AdvancedSearch: " Advanced Search",
  Select: "Select",
  NetWorth: "Net Worth",
  TotalAssets: "Total Assets",
  TotalLiabilities: "Total Liabilities",
  Total: "Total",
  TotalBalance: "Total Balance",
  TotalValue: "Total Value",
  AmortizationSchedule: "Amortization Schedule",
  EntityName: "Entity Name",
  Industry: "Industry",
  Description: "Description",
  PropertyType: "Property Type",
  AssetType: "Asset Type",
  NameOfCreditor: "Name of Creditor",
  Address: "Address",
  AnnualIncome: "Annual Income",
  NameOfCompany: "Name of Creditor",
  Collateral: "Collateral",
  DateUpdated: "Date Updated",
  UpdatedDate: "Date Updated",
  PurchaseDate: "Purchase Date",
  TaxYear: "Tax Year",
  offer_percentage: "Save 15%",
  APRPercentage: 'APR %',
  BalanceLoan: 'Balance',
  MortgageBalance: 'Mortgage Balance',
  MortgageBalanceDue: 'Balance Due',
  Collaboration: "Collaboration",
  ActivityHistory: "Activity History",
  Individuals: "Individuals",
  Companies: "Companies",
  CreditManager: 'Credit Manager',
  IdentityProtect: 'Identity Protect'
};

export default commonNames;
