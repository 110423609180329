import React from 'react';

// COMPONENT IMPORTS
import AuthRoutes from './AuthRoutes';
import RedirectUser from './RedirectUser';
import DashboardRoutes from './DashboardRoutes';
import NotFound from '../components/404NotFound';
import AccreditationRoutes from './AccreditationRoutes';
import QuickBooksRoutes from './QuickBooksRoutes';

/* ============================== APP ROUTES ============================== */
const AppRoutes = [
  {
    path: '/',
    children: [AuthRoutes, DashboardRoutes, QuickBooksRoutes, AccreditationRoutes, RedirectUser]
  },
  {
    path: '*',
    element: <NotFound />
  }
];

export default AppRoutes;
