import React, { useEffect, useRef, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { useOutletContext, useParams } from 'react-router-dom';
import { Col, Container, Form, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import Slider from 'react-slick';

// COMPONENT IMPORTS
import LineChart from '../components/LineChart';
import Donut from '../../pages/components/Donut';
import { useUserID } from '../../slices/authSlice';
import { headingList } from '../../constants/config';
import SecuritiesHoldings from './SecuritiesHoldings';
import CapsyncIcon from '../../components/CapsyncIcon';
import CapsyncToolTip from '../../components/CapsyncToolTip';
import CapsyncBreadcrumb from '../../components/CapsyncBreadcrumb';
import SkeletonStocksPortfolio from '../components/Skeleton/SkeletonStocksPortfolio';

// HOOKS IMPORTS
import { decryptReact } from '../../utils/Hooks/EncryptDecryptReact';

// API
import {
  addColumnFilterData,
  findPortfolioColumns,
  useCategoryDetail,
  useCategoryTableDetails
} from '../../slices/categorySlice';
import { getHoldingSecurity, useLastSyncData } from '../../slices/plaidSlice';

/* ============================== PORTFOLIO ============================== */
const Portfolio = () => {
  const dispatch = useDispatch();

  const categories = useCategoryDetail();
  const categoriesData = useCategoryTableDetails();
  const lastSyncData = useLastSyncData();
  const plaidMask = useParams().mask_id;
  const userId = useUserID();

  const { transactionInsID } = useSelector((state) => state.category);

  const dropdownRef = useRef(null);

  const [listingData, setListingData] = useState([]);
  const [lineChartData, setLineChartData] = useState([]);
  const [pieChartData, setPieChartData] = useState({});
  const [categoryHeaders, setCategoryHeaders] = useState([...headingList]);
  const [manageColumns, setManageColumns] = useState(false);

  const fetchInstitutionId = () => {
    const decryptedData = decryptReact(transactionInsID);
    return decryptedData;
  };

  const categoryArr = [
    {
      category: 'otherRetirement',
      url: 'retirementaccount',
      title: 'IRA or Other Retirement Account',
      summaryAllocation: 'retirementaccount'
    },
    {
      category: 'nonRetirementBrokerage',
      url: 'nonretirementbrokerage',
      title: 'Non-Retirement Brokerage Accounts (Stocks & Bonds)',
      summaryAllocation: 'nonretirementbrokerage'
    }
  ];

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setManageColumns(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    getListOfHoldingSecurity();
  }, []);

  useEffect(() => {
    if (categories && userId) {
      fetchColumnFiltersFromDB();
    }
  }, [categories]);

  const fetchColumnFiltersFromDB = async () => {
    try {
      const data = {
        userId: userId,
        categoryName: categories?.path + '_portfolio',
        rowId: Number(window.localStorage.getItem('portfolio_row_id'))
      };
      const response = await dispatch(findPortfolioColumns(data)).unwrap();
      if (response.code === 200 && response.data) {
        setCategoryHeaders(response.data.columns_name);
      } else {
        setCategoryHeaders([...headingList]);
      }
    } catch (error) {
      console.error('Error fetching column filters:', error);
    }
  };

  const getListOfHoldingSecurity = async () => {
    const reqBody = {
      user_id: userId,
      ins_id: fetchInstitutionId(),
      mask_id: plaidMask
    };
    dispatch(getHoldingSecurity(reqBody))
      .unwrap()
      .then((resp) => {
        try {
          if (resp.code === 200) {
            setListingData(resp.data.holdings_securities);
            setLineChartData([
              {
                name: 'Assets',
                data: resp.data.lineChartData.map((data) => ({
                  x: data.name,
                  y: data.Assets
                }))
              }
            ]);

            setPieChartData({
              headers: resp.data.pieChartData.map((data) => data.name),
              values: resp.data.pieChartData.map((data) => data.value),
              total: resp.data.pieChartData.reduce((total, data) => data.value + total, 0)
            });
          } else {
            toast.error(resp.message);
            console.log('resp', resp);
          }
        } catch (error) {
          console.log('error', error);
          toast.error(error.message);
        }
      });
  };

  const fetchCatData = categoryArr.find((x) => x.url === categories.path);

  const assetsRouteKey = [
    'cashonhands',
    'savingaccount',
    'retirementaccount',
    'accountnotesreceviable',
    'lifeinsurance',
    'nonretirementbrokerage',
    'business',
    'automobile',
    'personalproperty',
    'realestate',
    'otherassets'
  ];

  const chart_carousel = {
    dots: true,
    infinite: false,
    speed: 500,
    draggable: true,
    slidesToShow: 1,
    slidesToScroll: 1
  };

  const mainLevelArr = [
    {
      title: assetsRouteKey.includes(categories.path) ? 'Assets' : 'Liability',
      url: `/${categories.parent}`
    },
    {
      title: fetchCatData.title,
      url: `/${categories.parent}/${fetchCatData.url}`
    }
  ];

  const getsubtitle = () => {
    const subtitle = categoriesData.data.find((d) => d.plaid_mask == plaidMask)?.institution;
    return subtitle;
  };

  const handleManageColumns = () => {
    setManageColumns(!manageColumns);
  };

  const handleOnDragEnd = (data) => {
    if (!data.destination) {
      return;
    }
    if ([0, categoryHeaders.length - 1].includes(data.destination.index)) {
      return;
    }
    const fetchTitle = [...categoryHeaders];
    const removedElement = fetchTitle.splice(data.source.index, 1);
    fetchTitle.splice(data.destination.index, 0, ...removedElement);
    setCategoryHeaders(fetchTitle);
    const body = {
      user_id: userId,
      row_id: Number(window.localStorage.getItem('portfolio_row_id')),
      is_portfolio: true,
      category_name: `${categories.path}_portfolio`,
      columns_name: fetchTitle,
      category_type: categories.parent
    };
    dispatch(addColumnFilterData(body));
  };

  const handleCheckboxChange = (data) => {
    const store = [...categoryHeaders];
    const newArr = store.map((x, index) => (index === data ? { ...x, visible: !x.visible } : x));
    setCategoryHeaders(newArr);
    const body = {
      user_id: userId,
      row_id: Number(window.localStorage.getItem('portfolio_row_id')),
      is_portfolio: true,
      category_name: `${categories.path}_portfolio`,
      columns_name: newArr,
      category_type: categories.parent
    };
    dispatch(addColumnFilterData(body));
  };
  const [isLoader] = useOutletContext();

  return (
    <section className="main-section transaction-main-section portfolio-section">
      <Container fluid>
        {isLoader ? (
          <SkeletonStocksPortfolio />
        ) : (
          <React.Fragment>
            <div className="cs-section-topbar">
              <CapsyncBreadcrumb
                mainTitle={assetsRouteKey.includes(categories.path) ? 'Assets' : 'Liability'}
                subTitle={getsubtitle()}
                multiLevelFlag="true"
                mainLevelArr={mainLevelArr}
              />
              <div className={`cs-title cs-break-title`}>
                <h1 className="cs-semi-bold-h1 cs-neutral-100">Portfolio</h1>
                {lastSyncData && lastSyncData?.lastSyncData ? (
                  <span className="cs-regular-body-text-m cs-neutral-80">
                    {`Last sync: ${lastSyncData.lastSyncData}`}
                  </span>
                ) : (
                  ''
                )}
              </div>
            </div>

            <div className="for-des">
              <div className="chart-container">
                <Row>
                  <Col lg={4}>
                    <div className="donut-chart-box">
                      <div className="chart-heading">
                        <h2 className="cs-semi-bold-h2">Portfolio Allocation</h2>
                      </div>
                      {!pieChartData.headers ? (
                        <span className="cs-common-no-record cs-light-body-text-l cs-neutral-100">
                          There are no records to display
                        </span>
                      ) : (
                        <Donut
                          label="Portfolio"
                          total={pieChartData?.total || 0}
                          header={pieChartData.headers}
                          value={pieChartData.values}
                          fillColors={['#67C59C', '#71718C', '#EA780E', '#0024D4', '#05A660']}
                        />
                      )}
                    </div>
                  </Col>
                  <Col lg={8}>
                    <div className="line-chart-container portfolio-chart ">
                      <div className="chart-heading">
                        <h2 className="cs-semi-bold-h2">
                          Summary
                          <span>&nbsp;</span>
                          <CapsyncToolTip
                            Child={'info-outlined'}
                            placement={window.innerWidth >= 991 ? 'right' : 'top'}
                            message={
                              'Only accounts with transaction data are included in the chart.'
                            }
                            type="icon"
                            size="20"
                          />
                        </h2>
                        <span className="cs-regular-body-text-m cs-neutral-70">
                          Last 12 months data as of last sync
                        </span>
                      </div>
                      <div className="graph-line-chart-main-container">
                        <LineChart seriesArray={lineChartData} label="AssetsCategory" />
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>

            <div className="for-mob cs-common-carousel">
              <Slider {...chart_carousel}>
                <Col lg={6}>
                  <div className="new-pie-chart">
                    <div className="donut-chart-box">
                      <div className="chart-heading">
                        <h2 className="cs-semi-bold-h2">Portfolio Allocation</h2>
                      </div>
                      {!pieChartData.headers ? (
                        <span className="cs-common-no-record cs-light-body-text-l cs-neutral-100">
                          There are no records to display
                        </span>
                      ) : (
                        <Donut
                          label="Portfolio"
                          total={pieChartData?.total || 0}
                          header={pieChartData.headers}
                          value={pieChartData.values}
                          fillColors={['#67C59C', '#71718C', '#EA780E', '#0024D4', '#05A660']}
                        />
                      )}
                    </div>
                  </div>
                </Col>
                <Col lg={6}>
                  <div className="line-chart-container">
                    <div className="chart-heading">
                      <h2 className="cs-semi-bold-h2">
                        Summary
                        <span>&nbsp;</span>
                        <CapsyncToolTip
                          Child={'info-outlined'}
                          placement={window.innerWidth >= 991 ? 'right' : 'top'}
                          message={'Only accounts with transaction data are included in the chart.'}
                          type="icon"
                          size="20"
                        />
                      </h2>
                      <span className="cs-regular-body-text-m cs-neutral-70">
                        Last 12 months data as of last sync
                      </span>
                    </div>
                    <div className="graph-line-chart-main-container">
                      <LineChart
                        seriesArray={lineChartData}
                        label="AssetsCategory"
                        offsetY={'-8'}
                        offsetX={'0'}
                      />
                    </div>
                  </div>
                </Col>
              </Slider>
            </div>

            <Row>
              <Col>
                <div className="category-manage-row">
                  <div className="cs-common-add-dropdown">
                    <div className="custom-dropdown" ref={dropdownRef}>
                      <button
                        className="cs-regular-h5 xl-btn cs-btn-icon-primary"
                        onClick={() => handleManageColumns()}>
                        <span className="cs-neutral-10">
                          <CapsyncIcon title="filter-filled" size="18" />
                        </span>
                        Manage Columns
                      </button>
                      {manageColumns && (
                        <div className="custom-dropdown-menu">
                          <DragDropContext onDragEnd={handleOnDragEnd}>
                            <Droppable droppableId="characters-1">
                              {(provided) => (
                                <div
                                  {...provided.droppableProps}
                                  ref={provided.innerRef}
                                  className="manage-columns-items">
                                  {categoryHeaders.map((itr, i) => {
                                    return (
                                      <Draggable
                                        key={itr.title}
                                        draggableId={itr.key}
                                        index={i}
                                        isDragDisabled={!itr.draggable}>
                                        {(provided) => (
                                          <li
                                            {...provided.draggableProps}
                                            className="manage-column-item"
                                            ref={provided.innerRef}
                                            key={i}>
                                            <div className="cs-form-check-box">
                                              <span
                                                className={`${!itr.draggable ? 'cs-disabled' : 'cs-neutral-100'}`}
                                                {...provided.dragHandleProps}>
                                                <CapsyncIcon
                                                  title="order-selector-filled"
                                                  size="18"
                                                />
                                              </span>
                                              <Form.Check
                                                inline
                                                className={`cs-light-body-text-m cs-neutral-100 ${!itr.filter && 'cs-disabled'}`}
                                                label={itr.title}
                                                type="checkbox"
                                                id={`inline-checkbox-${i}`}
                                                disabled={!itr.filter}
                                                checked={itr.visible}
                                                onChange={() => handleCheckboxChange(i)}
                                              />
                                            </div>
                                          </li>
                                        )}
                                      </Draggable>
                                    );
                                  })}
                                  {provided.placeholder}
                                </div>
                              )}
                            </Droppable>
                          </DragDropContext>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
            <div>
              <Row>
                <Col lg={12}>
                  <SecuritiesHoldings
                    securitiesHoldings={listingData}
                    headingState={categoryHeaders
                      .filter((x) => x.visible === true)
                      .map((x) => x.title)}
                  />
                </Col>
              </Row>
            </div>
          </React.Fragment>
        )}
      </Container>
    </section>
  );
};

export default Portfolio;
