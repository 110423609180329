import { useMemo, useEffect } from 'react';

// COMPONENT IMPORTS
import { calculatePaymentPeriod } from '../common';

/* ============================== PAYMENT ============================== */
const usePayment = ({ values, setFieldValue, monthlyPayment = false }) => {
  const payment = useMemo(() => {
    if (values.loan_amount && values.annual_rate && values.annual_rate > 0 && values.loan_period) {
      const payment = calculatePaymentPeriod(
        values.loan_amount,
        values.annual_rate,
        values.frequency === 'Yearly' ? values.loan_period : values.loan_period / 12,
        12
      );
      return (
        monthlyPayment
          ? payment / (values.frequency === 'Yearly' ? values.loan_period * 12 : values.loan_period)
          : payment
      ).toString();
    } else {
      return values.loan_amount.toString();
    }
  }, [values.loan_amount, values.annual_rate, values.frequency, values.loan_period]);

  useEffect(() => {
    setFieldValue('payment', payment);
  }, [payment, setFieldValue]);

  return payment;
};

export default usePayment;
