import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';

// COMPONENT IMPORTS
import Modal from '../../components/Modal';

// API
import {
  cancelSignature,
  completedSignatureList,
  pendingSignatureList
} from '../../slices/requestSlice';

/* ============================== CANCEL SIGNATURE MODAL ============================== */
const CancelSignatureModal = ({ cancelRequestModal, setShowCancelRequestModal, rowData }) => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);

  const [declineMessage, setDeclineMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleDeclineMessage = (e) => {
    setDeclineMessage(e.target.value);
  };

  const handleCancelRequest = () => {
    setIsLoading(true);
    dispatch(
      cancelSignature({
        signature_id: rowData?.signature_users
          ? rowData.signature_users[0].signature_id.toString()
          : rowData.signature_id.toString(),
        cancel_by: user.email,
        reason: declineMessage ? declineMessage : '-'
      })
    ).then(async (res) => {
      if (res.payload.status) {
        const data = {
          user_id: user && user.id
        };
        setIsLoading(false);
        await dispatch(pendingSignatureList(data)).unwrap();
        await dispatch(completedSignatureList(data)).unwrap();
        setShowCancelRequestModal(false);
        toast.success(res.payload.message);
      } else {
        setIsLoading(false);
        toast.error(res.payload.message);
      }
    });
  };

  const bodyElement = () => {
    return (
      <div className="decline-signature-request">
        <Form.Group className="cs-form-group">
          <Form.Label className="cs-regular-sub-heading-xs cs-neutral-100">
            {`${rowData.type === 'signatureTo' ? 'Reason for cancelling (optional)' : 'Reason for declining (optional)'}`}
          </Form.Label>

          <textarea
            autoComplete="off"
            type="text"
            className={declineMessage ? 'cs-input-field-active cs-textarea' : 'cs-textarea'}
            onChange={(e) => handleDeclineMessage(e)}></textarea>
        </Form.Group>
      </div>
    );
  };

  return (
    <Modal
      show={cancelRequestModal}
      isCloseButton={false}
      className="cs-md-modal"
      title={`${rowData.type === 'signatureTo' ? 'Cancel signature request' : 'Decline signature request'}`}
      body={bodyElement()}
      saveButtonLabel="Submit"
      handleOnSave={handleCancelRequest}
      cancelButtonLabel="Cancel"
      handleOnCancel={() => setShowCancelRequestModal(false)}
      disabled={isLoading}
    />
  );
};

// PROPS TYPE
CancelSignatureModal.propTypes = {
  cancelRequestModal: PropTypes.bool,
  setShowCancelRequestModal: PropTypes.func,
  rowData: PropTypes.object
};

export default CancelSignatureModal;
