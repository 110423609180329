export const assetsRouts = [
  {
    parent: 'assets',
    key: 'cash_on_hands',
    path: 'cashonhands',
    category_id: 8,
    category_name: 'Cash on Hand & In Banks',
    modal_key: 'cashOnHandAndInBank',
    tableClass: 'cashonhand-table',
    fill: '#007241',
    labelColor: '#FFFFFF',
    tabArray: [
      {
        title: 'Institution',
        key: 'institution',
        width: '420',
        pfsWidth: '300',
        draggable: false,
        filter: false,
        visible: true
      },
      {
        title: 'Balance',
        key: 'balance',
        width: '220',
        pfsWidth: '162',
        className: 'text-end',
        draggable: false,
        filter: false,
        visible: true
      },
      {
        title: 'Ownership %',
        key: 'ownership',
        width: '220',
        pfsWidth: '120',
        className: 'text-end',
        draggable: false,
        filter: false,
        visible: true
      },
      {
        title: 'Share value',
        key: 'share_value',
        width: '220',
        pfsWidth: '162',
        className: 'text-end share-value-th',
        draggable: false,
        filter: false,
        visible: true,
        commonVariable: 'commonVariable'
      },
      {
        title: 'Date Updated',
        key: 'date_updated',
        width: '220',
        pfsWidth: '126',
        draggable: false,
        filter: false,
        visible: true
      },
      {
        title: 'Actions',
        key: 'action',
        width: '220',
        pfsWidth: '111',
        draggable: false,
        filter: false,
        visible: true,
        isTransaction: true
      }
    ]
  },
  {
    parent: 'assets',
    key: 'savings_accounts',
    path: 'savingaccount',
    category_id: 9,
    category_name: 'Savings Accounts',
    modal_key: 'savingsAccounts',
    tableClass: 'savingaccount-table',
    fill: '#007F48',
    labelColor: '#FFFFFF',
    tabArray: [
      {
        title: 'Institution',
        key: 'institution',
        width: '420',
        pfsWidth: '300',
        draggable: false,
        filter: false,
        visible: true
      },
      {
        title: 'Balance',
        key: 'balance',
        width: '220',
        pfsWidth: '162',
        draggable: false,
        filter: false,
        visible: true,
        className: 'text-end'
      },
      {
        title: 'Ownership %',
        key: 'ownership',
        width: '220',
        pfsWidth: '120',
        draggable: false,
        filter: false,
        visible: true,
        className: 'text-end'
      },
      {
        title: 'Share value',
        key: 'share_value',
        width: '220',
        pfsWidth: '162',
        draggable: false,
        filter: false,
        visible: true,
        className: 'text-end share-value-th',
        commonVariable: 'commonVariable'
      },
      {
        title: 'Date Updated',
        key: 'date_updated',
        width: '220',
        pfsWidth: '126',
        draggable: false,
        filter: false,
        visible: true
      },
      {
        title: 'Actions',
        key: 'action',
        width: '284',
        pfsWidth: '111',
        draggable: false,
        filter: false,
        visible: true,
        isTransaction: true
      }
    ]
  },
  {
    parent: 'assets',
    key: 'asset_ira_other_retirement_accounts',
    path: 'retirementaccount',
    category_id: 10,
    category_name: 'IRA or Other Retirement Account',
    modal_key: 'iraOrOtherRetirementAccount',
    tableClass: 'retirementaccount-table',
    fill: '#1A8C5A',
    labelColor: '#FFFFFF',
    tabArray: [
      {
        title: 'Institution',
        key: 'institution',
        width: '300',
        pfsWidth: '300',
        draggable: false,
        filter: false,
        visible: true
      },
      {
        title: 'Type',
        key: 'type',
        width: '180',
        pfsWidth: '200',
        draggable: true,
        filter: false,
        visible: true
      },
      {
        title: 'Balance',
        key: 'balance',
        width: '220',
        pfsWidth: '162',
        draggable: true,
        filter: true,
        visible: true,
        className: 'text-end'
      },
      {
        title: 'Loan on Balance',
        key: 'loan_on_balance',
        width: '220',
        pfsWidth: '162',
        draggable: true,
        filter: true,
        visible: true,
        className: 'text-end'
      },
      {
        title: 'Total Balance',
        key: 'total_balance',
        width: '220',
        pfsWidth: '162',
        draggable: true,
        filter: false,
        visible: true,
        className: 'text-end share-value-th',
        commonVariable: 'commonVariable'
      },
      {
        title: 'Date Updated',
        key: 'date_updated',
        width: '240',
        pfsWidth: '126',
        draggable: true,
        filter: false,
        visible: true
      },
      {
        title: 'Actions',
        key: 'action',
        width: '284',
        pfsWidth: '111',
        draggable: false,
        filter: false,
        visible: true,
        transaction_portfolio: true
      }
    ]
  },
  {
    parent: 'assets',
    key: 'asset_account_notes_receivables',
    path: 'accountnotesreceivable',
    category_id: 11,
    category_name: 'Accounts & Notes Receivable',
    modal_key: 'accountAndNotesRecivable',
    tableClass: 'accountnotesreceviable-table',
    fill: '#33996D',
    labelColor: '#FFFFFF',
    tabArray: [
      {
        title: 'Name of Debtor',
        key: 'property_name',
        width: '300',
        pfsWidth: '300',
        draggable: false,
        filter: false,
        visible: true
      },
      {
        title: 'Loan Amount',
        key: 'loan_amount',
        width: '220',
        pfsWidth: '162',
        draggable: true,
        filter: false,
        visible: true,
        className: 'text-end'
      },
      {
        title: 'Payment',
        key: 'payment',
        width: '220',
        pfsWidth: '162',
        draggable: true,
        filter: true,
        visible: true,
        className: 'text-end'
      },
      {
        title: 'Frequency',
        key: 'frequency',
        width: '150',
        pfsWidth: '104',
        draggable: true,
        filter: true,
        visible: true
      },
      {
        title: 'Balance Due',
        key: 'balance_due',
        width: '220',
        pfsWidth: '162',
        draggable: true,
        filter: false,
        visible: true,
        className: 'text-end share-value-th',
        commonVariable: 'commonVariable'
      },
      {
        title: 'Date Updated',
        key: 'date_updated',
        width: '200',
        pfsWidth: '126',
        draggable: true,
        filter: false,
        visible: true
      },
      {
        title: 'Actions',
        key: 'action',
        width: '284',
        pfsWidth: '111',
        draggable: false,
        filter: false,
        visible: true,
        is_amortization: true
      }
    ]
  },
  {
    parent: 'assets',
    key: 'asset_life_insurances',
    path: 'lifeinsurance',
    category_id: 12,
    category_name: 'Life Insurance',
    modal_key: 'lifeInsurance',
    tableClass: 'lifeinsurance-table',
    fill: '#4DA57F',
    labelColor: '#FFFFFF',
    tabArray: [
      {
        title: 'Institution',
        key: 'company',
        width: '340',
        pfsWidth: '300',
        draggable: false,
        filter: false,
        visible: true
      },
      {
        title: 'Loan Amount',
        key: 'loan_amount',
        width: '300',
        pfsWidth: '162',
        draggable: true,
        filter: false,
        visible: true,
        className: 'text-end share-value-th',
        commonVariable: 'commonVariable'
      },
      {
        title: 'Beneficiary',
        key: 'beneficiary',
        width: '300',
        pfsWidth: '180',
        draggable: true,
        filter: true,
        visible: true
      },
      {
        title: 'Cash Value',
        key: 'cash_value',
        width: '300',
        pfsWidth: '162',
        draggable: true,
        filter: false,
        visible: true,
        className: 'text-end share-value-th',
        commonVariable: 'commonVariable'
      },
      {
        title: 'Date Updated',
        key: 'date_updated',
        width: '200',
        pfsWidth: '126',
        draggable: true,
        filter: false,
        visible: true
      },
      {
        title: 'Actions',
        key: 'action',
        width: '284',
        pfsWidth: '111',
        draggable: false,
        filter: false,
        visible: true
      }
    ]
  },
  {
    parent: 'assets',
    key: 'asset_non_retirement_brokerage_accounts',
    path: 'nonretirementbrokerage',
    category_id: 13,
    category_name: 'Non-Retirement Brokerage Accounts (Stocks & Bonds)',
    modal_key: 'nonRetirementBrokerageAccount',
    tableClass: 'nonretirementbrokerage-table',
    fill: '#66B291',
    labelColor: '#FFFFFF',
    tabArray: [
      {
        title: 'Institution',
        key: 'institution',
        width: '420',
        pfsWidth: '300',
        draggable: false,
        filter: false,
        visible: true
      },
      {
        title: 'Balance',
        key: 'balance',
        width: '220',
        pfsWidth: '162',
        draggable: false,
        filter: false,
        visible: true,
        className: 'text-end'
      },
      {
        title: 'Ownership %',
        key: 'ownership',
        width: '220',
        pfsWidth: '120',
        draggable: false,
        filter: false,
        visible: true,
        className: 'text-end'
      },
      {
        title: 'Share value',
        key: 'share_value',
        width: '220',
        pfsWidth: '162',
        draggable: false,
        filter: false,
        visible: true,
        className: 'text-end share-value-th',
        commonVariable: 'commonVariable'
      },
      {
        title: 'Date Updated',
        key: 'date_updated',
        width: '220',
        pfsWidth: '126',
        draggable: false,
        filter: false,
        visible: true
      },
      {
        title: 'Actions',
        key: 'action',
        width: '284',
        pfsWidth: '111',
        draggable: false,
        filter: false,
        visible: true,
        transaction_portfolio: true
      }
    ]
  },
  {
    parent: 'assets',
    key: 'asset_real_estates',
    path: 'realestate',
    category_id: 17,
    category_name: 'Real Estate',
    modal_key: 'realEstate',
    tableClass: 'realestate-table',
    fill: '#80BFA4',
    labelColor: '#FFFFFF',
    tabArray: [
      {
        title: 'Address',
        key: 'address',
        width: '359',
        pfsWidth: '520',
        draggable: false,
        filter: false,
        visible: true
      },
      {
        title: 'Market Value',
        key: 'market_value',
        width: '220',
        pfsWidth: '162',
        draggable: true,
        filter: false,
        visible: true,
        className: 'text-end'
      },
      {
        title: 'Ownership %',
        key: 'ownership',
        width: '200',
        pfsWidth: '120',
        draggable: true,
        filter: false,
        visible: true,
        className: 'text-end'
      },
      {
        title: 'Annual Income',
        key: 'annual_income',
        width: '220',
        pfsWidth: '162',
        draggable: true,
        filter: true,
        visible: true,
        className: 'text-end'
      },
      {
        title: 'Share Market Value',
        key: 'share_market_value',
        width: '220',
        pfsWidth: '162',
        draggable: true,
        filter: false,
        visible: true,
        className: 'text-end share-value-th',
        commonVariable: 'commonVariable'
      },
      {
        title: 'Date Updated',
        key: 'date_updated',
        width: '204',
        pfsWidth: '126',
        draggable: true,
        filter: false,
        visible: true
      },
      {
        title: 'Actions',
        key: 'action',
        width: '284',
        pfsWidth: '111',
        draggable: false,
        filter: false,
        visible: true
      }
    ]
  },
  {
    parent: 'assets',
    key: 'asset_automobiles',
    path: 'automobile',
    category_id: 15,
    category_name: 'Automobiles',
    modal_key: 'automobile',
    tableClass: 'automobile-table',
    fill: '#99CCB6',
    labelColor: '#000000',
    tabArray: [
      {
        title: 'Description',
        key: 'description',
        width: '340',
        pfsWidth: '300',
        draggable: false,
        filter: false,
        visible: true
      },
      {
        title: 'Name of Creditor',
        key: 'creditor_name',
        width: '260',
        pfsWidth: '200',
        draggable: true,
        filter: false,
        visible: true
      },
      {
        title: 'Balance Due',
        key: 'balance_due',
        width: '204',
        pfsWidth: '162',
        draggable: true,
        filter: false,
        visible: true,
        className: 'text-end'
      },
      {
        title: 'Payment',
        key: 'payment',
        width: '220',
        pfsWidth: '162',
        draggable: true,
        filter: true,
        visible: true,
        className: 'text-end'
      },
      {
        title: 'Market Value',
        key: 'market_value',
        width: '220',
        pfsWidth: '162',
        draggable: true,
        filter: false,
        visible: true,
        className: 'text-end share-value-th',
        commonVariable: 'commonVariable'
      },
      {
        title: 'Date Updated',
        key: 'date_updated',
        width: '220',
        pfsWidth: '126',
        draggable: true,
        filter: false,
        visible: true
      },
      {
        title: 'Actions',
        key: 'action',
        width: '284',
        pfsWidth: '111',
        draggable: false,
        filter: false,
        visible: true
      }
    ]
  },
  {
    parent: 'assets',
    key: 'asset_businesses',
    path: 'business',
    category_id: 14,
    category_name: 'Businesses',
    modal_key: 'businesses',
    tableClass: 'business-table',
    fill: '#B3D9C8',
    labelColor: '#000000',
    tabArray: [
      {
        title: 'Entity Name',
        key: 'entity_name',
        width: '350',
        pfsWidth: '300',
        draggable: false,
        filter: false,
        visible: true
      },
      {
        title: 'Industry',
        key: 'industry',
        width: '220',
        pfsWidth: '200',
        draggable: true,
        filter: true,
        visible: true
      },
      {
        title: 'Market Value',
        key: 'balance',
        width: '350',
        pfsWidth: '162',
        draggable: true,
        filter: false,
        visible: true,
        className: 'text-end'
      },
      {
        title: 'Ownership %',
        key: 'ownership',
        width: '200',
        pfsWidth: '120',
        draggable: true,
        filter: false,
        visible: true,
        className: 'text-end'
      },
      {
        title: 'Share Market Value',
        key: 'share_market_value',
        width: '220',
        pfsWidth: '162',
        draggable: true,
        filter: false,
        visible: true,
        className: 'text-end share-value-th',
        commonVariable: 'commonVariable'
      },
      {
        title: 'Date Updated',
        key: 'date_updated',
        width: '220',
        pfsWidth: '126',
        draggable: true,
        filter: false,
        visible: true
      },
      {
        title: 'Actions',
        key: 'action',
        width: '284',
        pfsWidth: '111',
        draggable: false,
        filter: false,
        visible: true
      },
      {
        title: '',
        key: 'report',
        width: '210',
        pfsWidth: '0',
        draggable: false,
        filter: false,
        visible: true
      }
    ]
  },
  {
    parent: 'assets',
    key: 'asset_other_personal_properties',
    path: 'personalproperty',
    category_id: 16,
    category_name: 'Other Personal Property',
    modal_key: 'otherPersonalProperties',
    tableClass: 'personalproperty-table',
    fill: '#CCE5DA',
    labelColor: '#000000',
    tabArray: [
      {
        title: 'Description',
        key: 'description',
        width: '732',
        pfsWidth: '300',
        draggable: false,
        filter: false,
        visible: true
      },
      {
        title: 'Property Type',
        key: 'property_name',
        width: '280',
        pfsWidth: '200',
        draggable: false,
        filter: false,
        visible: true
      },
      {
        title: 'Market Value',
        key: 'market_value',
        width: '220',
        pfsWidth: '162',
        draggable: false,
        filter: false,
        visible: true,
        className: 'text-end share-value-th',
        commonVariable: 'commonVariable'
      },
      {
        title: 'Date Updated',
        key: 'date_updated',
        width: '220',
        pfsWidth: '126',
        draggable: false,
        filter: false,
        visible: true
      },
      {
        title: 'Actions',
        key: 'action',
        width: '284',
        pfsWidth: '111',
        draggable: false,
        filter: false,
        visible: true
      }
    ]
  },
  {
    parent: 'assets',
    key: 'asset_other_assets',
    path: 'otherassets',
    category_id: 18,
    category_name: 'Other Assets',
    modal_key: 'otherAssets',
    tableClass: 'otherassets-table',
    fill: '#E6F2ED',
    labelColor: '#000000',
    tabArray: [
      {
        title: 'Description',
        key: 'description',
        width: '710',
        pfsWidth: '300',
        draggable: false,
        filter: false,
        visible: true
      },
      {
        title: 'Asset Type',
        key: 'asset_type',
        width: '280',
        pfsWidth: '200',
        draggable: false,
        filter: false,
        visible: true
      },
      {
        title: 'Market Value',
        key: 'market_value',
        width: '220',
        pfsWidth: '162',
        draggable: false,
        filter: false,
        visible: true,
        className: 'text-end share-value-th',
        commonVariable: 'commonVariable'
      },
      {
        title: 'Date Updated',
        key: 'date_updated',
        width: '220',
        pfsWidth: '126',
        draggable: false,
        filter: false,
        visible: true
      },
      {
        title: 'Actions',
        key: 'action',
        width: '284',
        pfsWidth: '111',
        draggable: false,
        filter: false,
        visible: true
      }
    ]
  }
];
export const liabilitiesRouts = [
  {
    parent: 'liabilities',
    key: 'liability_accounts_payables',
    path: 'accountpayable',
    category_id: 1,
    category_name: 'Accounts Payable',
    modal_key: 'accountPayable',
    tableClass: 'accountpayable-table',
    fill: '#CE3030',
    labelColor: '#FFFFFF',
    tabArray: [
      {
        title: 'Name of Creditor',
        key: 'creditor_name',
        width: '300',
        pfsWidth: '300',
        draggable: false,
        filter: false,
        visible: true
      },
      {
        title: 'Type',
        key: 'type',
        width: '180',
        pfsWidth: '200',
        draggable: true,
        filter: false,
        visible: true
      },
      {
        title: 'APR %',
        key: 'apr_percentage',
        width: '220',
        pfsWidth: '120',
        draggable: true,
        filter: true,
        visible: true,
        className: 'text-end'
      },
      {
        title: 'Minimum Payment',
        key: 'minimum_payment',
        width: '220',
        pfsWidth: '162',
        draggable: true,
        filter: true,
        visible: true,
        className: 'text-end'
      },
      {
        title: 'Balance Due',
        key: 'balance_due',
        width: '220',
        pfsWidth: '162',
        draggable: true,
        filter: false,
        visible: true,
        className: 'text-end share-value-th',
        commonVariable: 'commonVariable'
      },
      {
        title: 'Date Updated',
        key: 'date_updated',
        width: '180',
        pfsWidth: '126',
        draggable: true,
        filter: false,
        visible: true
      },
      {
        title: 'Actions',
        key: 'action',
        width: '284',
        pfsWidth: '111',
        draggable: false,
        filter: false,
        visible: true,
        isTransaction: true
      }
    ]
  },
  {
    parent: 'liabilities',
    key: 'liability_notes_payable_to_bank_and_others',
    path: 'notespayabletobanksandothers',
    category_id: 2,
    category_name: 'Notes Payable to Banks and Others',
    modal_key: 'notesPayableToBankAndOther',
    tableClass: 'notespayabletobanksandothers-table',
    fill: '#E53535',
    labelColor: '#FFFFFF',
    tabArray: [
      {
        title: 'Name of Creditor',
        key: 'creditor_name',
        width: '231',
        pfsWidth: '220',
        draggable: false,
        filter: false,
        visible: true
      },
      {
        title: 'Maturity Date',
        key: 'maturity_date',
        width: '220',
        pfsWidth: '126',
        draggable: true,
        filter: false,
        visible: true
      },
      {
        title: 'Loan Amount',
        key: 'loan_amount',
        width: '220',
        pfsWidth: '162',
        draggable: true,
        filter: true,
        visible: true,
        className: 'text-end'
      },
      {
        title: 'Payment',
        key: 'payment',
        width: '220',
        pfsWidth: '162',
        draggable: true,
        filter: true,
        visible: true,
        className: 'text-end'
      },
      {
        title: 'Balance Due',
        key: 'balance_due',
        width: '220',
        pfsWidth: '162',
        draggable: true,
        filter: false,
        visible: true,
        className: 'text-end share-value-th',
        commonVariable: 'commonVariable'
      },
      {
        title: 'Date Updated',
        key: 'date_updated',
        width: '220',
        pfsWidth: '126',
        draggable: true,
        filter: false,
        visible: true
      },
      {
        title: 'Actions',
        key: 'action',
        width: '284',
        pfsWidth: '111',
        draggable: false,
        filter: false,
        visible: true,
        is_amortization: true,
        transaction_portfolio: true
      }
      // {
      //   title: '',
      //   key: 'amortization',
      //   width: '240',
      //   draggable: false,
      //   filter: false,
      //   visible: true
      // }
    ]
  },
  {
    parent: 'liabilities',
    key: 'liability_installment_accounts_auto',
    path: 'installmentsauto',
    category_id: 19,
    category_name: 'Installment Account (Auto)',
    modal_key: 'installmentAccountAuto',
    tableClass: 'installmentsauto-table',
    fill: '#EA5D5D',
    labelColor: '#FFFFFF',
    tabArray: [
      {
        title: 'Name of Creditor',
        key: 'creditor_name',
        width: '314',
        pfsWidth: '280',
        draggable: false,
        filter: false,
        visible: true
      },
      {
        title: 'Maturity Date',
        key: 'maturity_date',
        width: '180',
        pfsWidth: '126',
        draggable: true,
        filter: false,
        visible: true
      },
      {
        title: 'Loan Amount',
        key: 'loan_amount',
        width: '220',
        pfsWidth: '162',
        draggable: true,
        filter: true,
        visible: true,
        className: 'text-end share-value-th',
        commonVariable: 'commonVariable'
      },
      {
        title: 'Payment',
        key: 'payment',
        width: '220',
        pfsWidth: '162',
        draggable: true,
        filter: true,
        visible: true,
        className: 'text-end'
      },
      {
        title: 'Balance Due',
        key: 'balance_due',
        width: '220',
        pfsWidth: '162',
        draggable: true,
        filter: false,
        visible: true,
        className: 'text-end share-value-th',
        commonVariable: 'commonVariable'
      },
      {
        title: 'Date Updated',
        key: 'date_updated',
        width: '180',
        pfsWidth: '126',
        draggable: true,
        filter: false,
        visible: true
      },
      {
        title: 'Actions',
        key: 'action',
        width: '',
        pfsWidth: '111',
        draggable: false,
        filter: false,
        visible: true,
        is_amortization: true,
        transaction_portfolio: true
      }
      // {
      //   title: '',
      //   key: 'amortization',
      //   width: '240',
      //   draggable: false,
      //   filter: false,
      //   visible: true
      // }
    ]
  },
  {
    parent: 'liabilities',
    key: 'liability_installment_accounts_other',
    path: 'installmentsaccount',
    category_id: 3,
    category_name: 'Installment Account (Other)',
    modal_key: 'installmentAccountOther',
    tableClass: 'installmentsaccount-table',
    fill: '#ED7272',
    labelColor: '#FFFFFF',
    tabArray: [
      {
        title: 'Name of Creditor',
        key: 'creditor_name',
        width: '314',
        pfsWidth: '220',
        draggable: false,
        filter: false,
        visible: true
      },
      {
        title: 'Maturity Date',
        key: 'maturity_date',
        width: '180',
        pfsWidth: '126',
        draggable: true,
        filter: false,
        visible: true
      },
      {
        title: 'Loan Amount',
        key: 'loan_amount',
        width: '220',
        pfsWidth: '162',
        draggable: true,
        filter: true,
        visible: true,
        className: 'text-end share-value-th',
        commonVariable: 'commonVariable'
      },
      {
        title: 'Payment',
        key: 'payment',
        width: '220',
        pfsWidth: '162',
        draggable: true,
        filter: true,
        visible: true,
        className: 'text-end'
      },
      {
        title: 'Balance Due',
        key: 'balance_due',
        width: '220',
        pfsWidth: '162',
        draggable: true,
        filter: false,
        visible: true,
        className: 'text-end share-value-th',
        commonVariable: 'commonVariable'
      },
      {
        title: 'Date Updated',
        key: 'date_updated',
        width: '180',
        pfsWidth: '126',
        draggable: true,
        filter: false,
        visible: true
      },
      {
        title: 'Actions',
        key: 'action',
        width: '284',
        pfsWidth: '111',
        draggable: false,
        filter: false,
        visible: true,
        is_amortization: true
      }
      // {
      //   title: '',
      //   key: 'amortization',
      //   width: '240',
      //   draggable: false,
      //   filter: false,
      //   visible: true
      // }
    ]
  },
  {
    parent: 'liabilities',
    key: 'liability_life_insurances',
    path: 'lifeinsurance',
    category_id: 20,
    category_name: 'Loan(s) Against Life Insurance',
    modal_key: 'loanAgainstLifeInsurance',
    tableClass: 'lifeinsurance-table',
    fill: '#F29A9A',
    labelColor: '#FFFFFF',
    tabArray: [
      {
        title: 'Institution',
        key: 'company',
        width: '620',
        pfsWidth: '300',
        draggable: false,
        filter: false,
        visible: true
      },
      {
        title: 'Beneficiary',
        key: 'beneficiary',
        width: '300',
        pfsWidth: '180',
        draggable: true,
        filter: true,
        visible: true
      },
      {
        title: 'Balance Due',
        key: 'loan_amount',
        width: '300',
        pfsWidth: '162',
        draggable: true,
        filter: false,
        visible: true,
        className: 'text-end share-value-th',
        commonVariable: 'commonVariable'
      },
      {
        title: 'Date Updated',
        key: 'date_updated',
        width: '220',
        pfsWidth: '126',
        draggable: true,
        filter: false,
        visible: true
      },
      {
        title: 'Actions',
        key: 'action',
        width: '284',
        pfsWidth: '111',
        draggable: false,
        filter: false,
        visible: true
      }
    ]
  },
  {
    parent: 'liabilities',
    key: 'liability_mortgages_real_estate',
    path: 'mortgagesonrealestate',
    category_id: 5,
    category_name: 'Mortgages on Real Estate',
    modal_key: 'mortgagesOnRealEstate',
    tableClass: 'mortgagesonrealestate-table',
    fill: '#F7C2C2',
    labelColor: '#000000',
    tabArray: [
      {
        title: 'Name of Creditor',
        key: 'creditor_name',
        width: '316',
        pfsWidth: '220',
        draggable: false,
        filter: false,
        visible: true
      },
      {
        title: 'Maturity Date',
        key: 'maturity_date',
        width: '180',
        pfsWidth: '126',
        draggable: true,
        filter: false,
        visible: true
      },
      {
        title: 'Mortgage Balance',
        key: 'mortgage_balance',
        width: '220',
        pfsWidth: '162',
        draggable: true,
        filter: false,
        visible: true,
        className: 'text-end'
      },
      {
        title: 'Ownership %',
        key: 'ownership',
        width: '220',
        pfsWidth: '120',
        draggable: true,
        filter: false,
        visible: true,
        className: 'text-end'
      },
      {
        title: 'Monthly Payment',
        key: 'payment_monthly',
        width: '220',
        pfsWidth: '162',
        draggable: true,
        filter: true,
        visible: true,
        className: 'text-end'
      },
      {
        title: 'Balance Due',
        key: 'balance_due',
        width: '220',
        pfsWidth: '162',
        draggable: true,
        filter: false,
        visible: true,
        className: 'text-end share-value-th',
        commonVariable: 'commonVariable'
      },
      {
        title: 'Date Updated',
        key: 'date_updated',
        width: '180',
        pfsWidth: '126',
        draggable: true,
        filter: false,
        visible: true
      },
      {
        title: 'Actions',
        key: 'action',
        width: '284',
        pfsWidth: '111',
        draggable: false,
        filter: false,
        visible: true,
        is_amortization: true,
        transaction_portfolio: true
      }
      // {
      //   title: '',
      //   key: 'amortization',
      //   width: '320',
      //   draggable: false,
      //   filter: false,
      //   visible: true
      // }
    ]
  },
  {
    parent: 'liabilities',
    key: 'liability_unpaid_taxes',
    path: 'unpaidtaxes',
    category_id: 4,
    category_name: 'Unpaid Taxes',
    modal_key: 'unpaidTaxes',
    tableClass: 'unpaidtaxes-table',
    fill: '#FAD7D7',
    labelColor: '#000000',
    tabArray: [
      {
        title: 'Description',
        key: 'description',
        width: '780',
        pfsWidth: '300',
        draggable: false,
        filter: false,
        visible: true
      },
      {
        title: 'Tax Year',
        key: 'year',
        width: '220',
        pfsWidth: '88',
        draggable: false,
        filter: false,
        visible: true
      },
      {
        title: 'Balance Due',
        key: 'balance_due',
        width: '220',
        pfsWidth: '162',
        draggable: false,
        filter: false,
        visible: true,
        className: 'text-end share-value-th',
        commonVariable: 'commonVariable'
      },
      {
        title: 'Date Updated',
        key: 'date_updated',
        width: '220',
        pfsWidth: '126',
        draggable: false,
        filter: false,
        visible: true
      },
      {
        title: 'Actions',
        key: 'action',
        width: '284',
        pfsWidth: '111',
        draggable: false,
        filter: false,
        visible: true
      }
    ]
  },
  {
    parent: 'liabilities',
    key: 'liability_other_liabilities',
    path: 'otherliabilities',
    category_id: 6,
    category_name: 'Other Liabilities',
    modal_key: 'otherLiabilities',
    tableClass: 'otherliabilities-table',
    fill: '#FCEBEB',
    labelColor: '#000000',
    tabArray: [
      {
        title: 'Description',
        key: 'description',
        width: '900',
        pfsWidth: '300',
        draggable: false,
        filter: false,
        visible: true
      },
      {
        title: 'Balance Due',
        key: 'balance_due',
        width: '220',
        pfsWidth: '162',
        draggable: false,
        filter: false,
        visible: true,
        className: 'text-end share-value-th',
        commonVariable: 'commonVariable'
      },
      {
        title: 'Date Updated',
        key: 'date_updated',
        width: '220',
        pfsWidth: '126',
        draggable: false,
        filter: false,
        visible: true
      },
      {
        title: 'Actions',
        key: 'action',
        width: '284',
        pfsWidth: '111',
        draggable: false,
        filter: false,
        visible: true,
        isTransaction: true
      }
    ]
  }
];
