import React, { useState } from 'react';
import { Row, Form, Col } from 'react-bootstrap';
import { useDropzone } from 'react-dropzone';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

// COMPONENT IMPORTS
import Modal from '../../components/Modal';
import CapsyncIcon from '../../components/CapsyncIcon';
import { handleS3FolderUpload, supportedExtensions } from '../../utils/common';
import CapsyncToolTip from '../../components/CapsyncToolTip';

// API
import {
  setProgressUploadDetails,
  showUploadProgress,
  useProgressUploadDetails
} from '../../slices/commonSlice';
import { uploadOtherFilesFolder, useStorageDetails } from '../../slices/fileManagementSlice';
import { useUser } from '../../slices/authSlice';

/* ============================== FOLDER UPLOAD ============================== */
const FolderUpload = ({
  closeModal,
  uploadFolderModal,
  parentId,
  setFileStorageModal,
  setUploadFolderModal,
  setFetchLoading
}) => {
  const dispatch = useDispatch();

  const user = useUser();
  const progressUploadDetails = useProgressUploadDetails();
  const storageDetails = useStorageDetails();

  const [loading, setLoading] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [selectedFolders, setSelectedFolders] = useState([]);
  const [selectedFolderList, setSelectedFolderList] = useState([]);
  const [uploadedFolder, setUploadedFolder] = useState([]);
  const [folderError, setFolderError] = useState(null);

  const showStarNumber = (params) => {
    let newContent = '';
    newContent += params;
    if (newContent.length > 35) {
      return newContent.substring(0, 35) + '...';
    } else {
      return newContent;
    }
  };

  const validateFolder = async (uploadedFolders, existingFolders) => {
    if (!uploadedFolders || uploadedFolders.length === 0) {
      setFolderError('Please select a folder with files.');
      return false;
    }
    const allFiles = await traverseFolder(uploadedFolders);
    if (allFiles.length === 0) {
      setFolderError('The selected folder is empty.');
      return false;
    }

    let totalSize = 0;
    let totalExistingFolderSize = 0;

    const maxSizeAllowed =
      process.env.REACT_APP_FILE_UPLOAD_SIZE_ALLOWED_FILE_MANAGEMENT || 209715200; // Default to 200MB if env variable is not set
    const maxSizeBytes = parseInt(maxSizeAllowed);

    for (const existingFolder of existingFolders) {
      totalExistingFolderSize += existingFolder.size;
    }

    for (const entry of uploadedFolders) {
      totalSize += entry.size;
    }

    const totalFolderSize = totalSize + totalExistingFolderSize;

    if (totalFolderSize > maxSizeBytes) {
      const maxSizeMB = maxSizeBytes / (1024 * 1024);
      setFolderError(`Total folder size greater than ${maxSizeMB} MB not allowed`);
      return false;
    }

    for (const entry of uploadedFolders) {
      const fileName = entry.name.toLowerCase();
      const extension = fileName.substring(fileName.lastIndexOf('.'));
      if (!supportedExtensions.includes(extension)) {
        setFolderError('Format not supported');
        return false;
      }
    }
    return true;
  };

  const getFolderSize = (folder_name) => {
    if (uploadedFolder.length > 0) {
      const filteredFolders = uploadedFolder.filter((folder) => folder.name === folder_name);
      let size = 0;
      filteredFolders.forEach((folder) => (size += folder.size));
      return size;
    } else {
      return 0;
    }
  };

  const getFolderName = (foldersArray) => {
    const selectedFolderArray = foldersArray.map((val) => {
      const folder_name =
        val.webkitRelativePath !== '' ? val.webkitRelativePath.split('/') : val.path.split('/');
      return {
        ...val,
        id: val.id,
        name: val.webkitRelativePath !== '' ? folder_name[0] : folder_name[1],
        size: val.size
      };
    });
    setUploadedFolder(selectedFolderArray);
    const uniqueNames = {};
    const newSelectedFolder = selectedFolderArray.filter((obj) => {
      if (!uniqueNames[obj.id]) {
        uniqueNames[obj.id] = true;
        return true;
      }
      return false;
    });
    setSelectedFolderList(newSelectedFolder);
    setUploading(false);
  };

  const onFolderUpload = async (event) => {
    try {
      setFolderError(null);
      setUploading(true);
      const uploadedFolders = event.target.files;
      if (await validateFolder(uploadedFolders, selectedFolders)) {
        setFolderError(null);
        const folder = [...uploadedFolders].map((val) => {
          return Object.assign(val, {
            id: selectedFolders.length > 0 ? selectedFolders[0].id + 1 : 0
          });
        });
        setSelectedFolders([...folder, ...selectedFolders]);
        getFolderName([...folder, ...selectedFolders]);
      } else {
        setUploading(false);
      }
    } catch (error) {
      console.log('error :>> ', error);
    }
  };

  const traverseFolder = async (folder) => {
    const files = [];
    const traverse = async (entry) => {
      if (entry.isDirectory) {
        const entries = await entry.createReader().readEntries();
        for (const e of entries) {
          await traverse(e);
        }
      } else {
        files.push(entry);
      }
    };
    await traverse(folder);
    return files;
  };

  const findRelativePath = (files, currentIndex) => {
    const finalArray = [];

    const currentDirectories = [...new Set(files.map((file) => file.path[currentIndex]))];

    for (let i = 0; i < files.length; i++) {
      if (
        currentDirectories.includes(files[i].path[files[i].path.length - 2]) &&
        currentIndex === files[i].path.length - 2
      ) {
        const index = finalArray.findIndex(
          (file) => file.name === files[i].path[files[i].path.length - 2]
        );

        if (index > -1) {
          finalArray[index] = {
            ...finalArray[index],
            data: [...finalArray[index].data, files[i]]
          };
        } else {
          finalArray.push({
            name: files[i].path[files[i].path.length - 2],
            original_file_name: files[i].path[files[i].path.length - 2],
            size: 0,
            attachment: null,
            data: [files[i]],
            excludeData: []
          });
        }
      } else {
        const index = finalArray.findIndex((file) => file.name === files[i].path[currentIndex]);
        if (index > -1) {
          finalArray[index] = {
            ...finalArray[index],
            excludeData: [...finalArray[index].excludeData, files[i]]
          };
        } else {
          finalArray.push({
            name: files[i].path[currentIndex],
            original_file_name: files[i].path[currentIndex],
            size: 0,
            attachment: null,
            data: [],
            excludeData: [files[i]]
          });
        }
      }
    }

    for (let i = 0; i < finalArray.length; i++) {
      if (finalArray[i].excludeData.length) {
        finalArray[i] = {
          ...finalArray[i],
          data: [
            ...finalArray[i].data,
            ...findRelativePath(finalArray[i].excludeData, ++currentIndex)
          ]
        };
      }
    }

    return finalArray;
  };

  const processSelectedFiles = (fileArray) => {
    const tempFileArray = fileArray.map((file, index) => {
      let filePath =
        file.file.webkitRelativePath !== '' ? file.file.webkitRelativePath : file.file.path;
      if (filePath.startsWith('/')) {
        filePath = filePath.slice(1);
      }

      return {
        name: file.file.name,
        original_file_name: file.file.name,
        size: file.file.size,
        attachment: file.result.Key,
        path: filePath.split('/')
      };
    });

    return findRelativePath(tempFileArray, 0);
  };

  const handleFolderSave = async () => {
    const uId = Date.now();
    try {
      setLoading(true);

      const totalFileSize =
        selectedFolders && selectedFolders.reduce((acc, item) => acc + item.size, 0);
      const uploadedData = storageDetails?.used_space;
      const maxLimitData = storageDetails?.total_space;

      const inProgressData =
        Array.isArray(progressUploadDetails) && progressUploadDetails.length
          ? progressUploadDetails.reduce((acc, item) => acc + item?.size, 0)
          : 0;
      if (uploadedData + totalFileSize + inProgressData > maxLimitData) {
        closeModal(false);
        setUploadFolderModal(false);
        setFileStorageModal(true);
        setSelectedFolders([]);
        setSelectedFolderList([]);
        setUploadedFolder([]);
        setLoading(false);
        return;
      }

      dispatch(
        setProgressUploadDetails({
          id: uId,
          size: totalFileSize,
          isDelete: false
        })
      );

      const allFolders = [...selectedFolders];
      closeModal(false);
      setUploadFolderModal(false);
      setSelectedFolders([]);
      setSelectedFolderList([]);
      setUploadedFolder([]);
      setLoading(false);
      const { successfulUploads } = await handleS3FolderUpload(allFolders, user?.id, dispatch);
      setFetchLoading(true);
      const data = await processSelectedFiles(successfulUploads);
      const payload = {
        parent_id: parentId.toString(),
        data,
        user_id: user?.id.toString()
      };
      const res = await dispatch(uploadOtherFilesFolder({ payload, endPoint: 'folder' })).unwrap();
      dispatch(
        setProgressUploadDetails({
          id: uId,
          isDelete: true
        })
      );

      if (res && res.code === 200) {
        // await dispatch(get_user_storage({ id })).unwrap();
        setLoading(false);
        closeModal();
        setSelectedFolders([]);
        setSelectedFolderList([]);
        setUploadedFolder([]);
      } else {
        closeModal(false);
        setFetchLoading(false);
        setSelectedFolders([]);
        setSelectedFolderList([]);
        setUploadedFolder([]);
        setLoading(false);
        setFolderError(res.message);
        toast.error(res.message);
      }
    } catch (error) {
      console.log('error :>> ', error);
      closeModal(false);
      setSelectedFolders([]);
      setSelectedFolderList([]);
      setUploadedFolder([]);
      setLoading(false);
      setFetchLoading(false);
      dispatch(
        setProgressUploadDetails({
          id: uId,
          isDelete: true
        })
      );

      setTimeout(() => {
        dispatch(showUploadProgress({ id: uId, isDelete: true }));
      }, 1000);
      toast.error(error.message);
    }
  };

  const checkIsFolder = (acceptedFolders) => {
    for (const file of acceptedFolders) {
      if (file.path && !file.path.includes('/')) {
        setFolderError('Files not allowed');
        return false;
      }
    }
    setFolderError(null);
    return true;
  };

  const onDrop = async (acceptedFolders) => {
    setFolderError(null);
    setUploading(true);
    if (checkIsFolder(acceptedFolders)) {
      if (await validateFolder(acceptedFolders, selectedFolders)) {
        setFolderError(null);
        const folder = [...acceptedFolders].map((val) => {
          return Object.assign(val, {
            id: selectedFolders.length > 0 ? selectedFolders[0].id + 1 : 0
          });
        });
        folder.reverse();
        setSelectedFolders([...folder, ...selectedFolders]);
        getFolderName([...folder, ...selectedFolders]);
      }
    } else {
      setUploading(false);
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    directory: true, // Enable directory upload
    noClick: true
  });

  const removeFolder = (id) => {
    const updatedFolders = [...selectedFolders].filter((x) => x.id !== id);
    setSelectedFolders(updatedFolders);
    getFolderName([...updatedFolders]);
  };

  const body = (
    <React.Fragment>
      <Row>
        <Col>
          <Form.Label className="cs-regular-sub-heading-xs cs-neutral-100">
            Upload folder
          </Form.Label>
        </Col>
      </Row>
      <div className="manage-profile-upload">
        <Row>
          <Col>
            <div className="manage-file-container">
              <label htmlFor="folderInput" style={{ width: '100%' }}>
                <input
                  disabled={loading}
                  id="folderInput"
                  type="file"
                  webkitdirectory=""
                  onChange={onFolderUpload}
                  style={{ display: 'none', width: '100%', height: '100%' }}
                />
                <div
                  {...getRootProps({})}
                  className={`cs-upload-files cursor-pointer ${
                    loading && 'cs-neutral-60 disabled'
                  }`}>
                  <input
                    {...getInputProps()}
                    webkitdirectory=""
                    name="attachments"
                    disabled={loading}
                  />
                  <span className="cs-primary">
                    <CapsyncIcon title="upload-files-filled" size="28" />
                  </span>
                  <p className="cs-regular-sub-heading-s">
                    <span>Browse</span>&nbsp;or drag folder here
                  </p>
                </div>
              </label>
              <span className="form-error-msg cs-light-body-text-s cs-danger">{folderError}</span>
            </div>
          </Col>
        </Row>
        <div className="cs-files">
          <div className="cs-scroller">
            {selectedFolderList[0] !== '' &&
              selectedFolderList.map((iter, index) => {
                return (
                  <div className="cs-selected-files" key={index}>
                    <div className="cs-fileicon cs-neutral-60">
                      <CapsyncToolTip
                        Child="folder-icon-outlined"
                        placement={'top'}
                        type="icon"
                        size="22"
                        message={iter.name.replaceAll('_', ' ')}
                      />
                    </div>
                    <div className="cs-files-names">
                      <span className="download-filename cs-regular-sub-heading-s cs-neutral-100">
                        {showStarNumber(iter.name)}
                      </span>
                      <span className="download-filename cs-light-sub-heading-s cs-last-date cs-neutral-80">
                        {getFolderSize(iter.name) / 1000} Kb
                      </span>
                    </div>

                    <div className="cs-file-icon icons">
                      {uploading ? (
                        <span className="cs-common-spinner cs-primary">
                          <CapsyncIcon title="loading-outlined" size="22" />
                        </span>
                      ) : (
                        <span
                          className="cs-icon-badge-danger cs-danger"
                          onClick={() => removeFolder(iter.id)}>
                          <CapsyncIcon title="delete-outlined" size="18" />
                        </span>
                      )}
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    </React.Fragment>
  );

  return (
    <Modal
      title="Upload folder"
      cancelButtonLabel="Cancel"
      saveButtonLabel="Save"
      show={uploadFolderModal}
      disabled={selectedFolderList.length === 0}
      parentClassName="manage-files file-management-modal file-common-modal"
      isCloseButton={false}
      handleOnSave={() => handleFolderSave()}
      className="cs-md-modal"
      body={body}
      handleOnCancel={() => {
        closeModal(false);
        setUploadFolderModal(false);
        setSelectedFolders([]);
        setFolderError(null);
        setSelectedFolderList([]);
        setUploadedFolder([]);
      }}
    />
  );
};

// PROPS TYPE
FolderUpload.propTypes = {
  closeModal: PropTypes.func,
  setFileStorageModal: PropTypes.any,
  setUploadFolderModal: PropTypes.any,
  setFetchLoading: PropTypes.any,
  uploadFolderModal: PropTypes.bool,
  parentId: PropTypes.any
};

export default FolderUpload;
