import React from 'react';
import Skeleton from 'react-loading-skeleton';
import { Col, Container, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';

// COMPONENT IMPORTS
import SkeletonCategoryTable from './SkeletonCategoryTable';

/* ============================== SKELETON FILE MANAGEMENT ============================== */
const SkeletonFileManagement = ({ isGridView = false, breadcrumbDetails = [], moduleName }) => {
  return (
    <section className="individual-main-section main-section">
      <Container fluid>
        <div className="fm-skeleton">
          <div className="cs-section-topbar">
            <Row>
              <Col>
                {breadcrumbDetails && breadcrumbDetails.length > 1 ? (
                  <div className="skeleton-breadcrumb">
                    <div className="skeleton-breadcrumb-1">
                      <Skeleton width={50} />
                    </div>
                    <div className="skeleton-breadcrumb-2">
                      <Skeleton width={20} />
                    </div>
                    <div className="skeleton-breadcrumb-3">
                      <Skeleton width={50} />
                    </div>
                  </div>
                ) : null}
                <div className="skeleton-tabs-container">
                  <h2 className="skeleton-tab-1">
                    <Skeleton width={200} />
                  </h2>
                </div>
              </Col>
              <Col>
                <h2 className="skeleton-tab-1">
                  <Skeleton width="100%" />
                </h2>
              </Col>
              <Col>
                <div className="fm-skelton-button">
                  <span>
                    <Skeleton width={30} height={30} />
                  </span>
                  {moduleName === 'My Documents' && (
                    <span>
                      <Skeleton width={120} height={30} />
                    </span>
                  )}
                </div>
              </Col>
            </Row>

            <div className="skeleton-manage-container for-mob">
              <div>
                <Skeleton width={100} height={20} />
              </div>
              <div>
                <Skeleton width="100%" height={30} />
              </div>
              <div className="skeleton-new-btn">
                <span>
                  <Skeleton width={30} height={30} />
                </span>
                <span>
                  <Skeleton width={30} height={30} />
                </span>
              </div>
            </div>
            {isGridView ? (
              <div className="cs-grid-view">
                {Array(3)
                  .fill(null)
                  .map((_, index) => {
                    return (
                      <div className="cs-box" key={index}>
                        <Skeleton width="100%" height={window.innerWidth <= 575 ? 100 : 176} />
                        <div className="file-info file-management-dropdown">
                          <div className="file-title">
                            <span className="cs-neutral-60">
                              <Skeleton width={20} height={20} />
                            </span>
                            <h4 className="cs-regular-body-text-l cs-neutral-100">
                              <Skeleton width={80} height={20} />
                            </h4>
                          </div>
                          <div className="cs-settings-dropdown dropdown">
                            <Skeleton width={15} height={20} />
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </div>
            ) : (
              <SkeletonCategoryTable tableData={[1, 2, 3]} />
            )}
          </div>

          <div className="skeleton-upgrade for-des">
            <Row>
              <Col lg={12}>
                <Skeleton width={200} height={20} />
              </Col>
            </Row>
          </div>
          <div className="skeleton-upgrade for-mob">
            <Row>
              <Col lg={12}>
                <Skeleton width="100%" height={20} />
              </Col>
            </Row>
          </div>
        </div>
      </Container>
    </section>
  );
};

// PROPS TYPE
SkeletonFileManagement.propTypes = {
  isGridView: PropTypes.bool,
  breadcrumbDetails: PropTypes.array,
  moduleName: PropTypes.string
};

export default SkeletonFileManagement;
