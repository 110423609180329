import React, { useEffect, useState } from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { Link, useNavigate, useOutletContext } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

// COMPONENT IMPORTS
import Modal from '../../../components/Modal';
import SubMethodsList from '../SubMethodsList';
import { stepOneMethods } from './MethodOfTypes';
import WarningModalVI from '../Modals/WarningModalVI';
import commonNames from '../../../config/commonNames';
import CommonMethodsHeader from '../CommonMethodsHeader';
import ConfirmationModel from '../Modals/ConfirmationModel';
import CommonSpinner from '../../../components/CommonSpinner';
import SkeletonMethodsDashboard from '../../components/Skeleton/SkeletonMethodsDashboard';

// API
import {
  setLoader,
  setMethodName,
  setPFSLink,
  setRowID,
  setSubMethod,
  updateMethodID
} from '../../../slices/accreditationSlice';

/* ============================== METHOD VIEW ============================== */
const MethodsView = () => {
  const { initMethodName, rowID, loader } = useSelector((state) => state.accreditation);

  const methodData = stepOneMethods(initMethodName);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showWarning, setShowWarning] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const handleConfirmationModal = () => {
    setShowConfirmation(true);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(setPFSLink({ link: '', dropBoxID: 0 }));
    if (rowID === '' || rowID === null) {
      navigate('/verifyinvestor');
    }
  }, []);

  const goToSubMethods = async (type, methodID, indicator = '', loaderSlug = '') => {
    const methodBtnLoader = 'methodBtnLoader' + methodID + loaderSlug;
    await dispatch(setLoader({ [methodBtnLoader]: true }));
    await dispatch(updateMethodID({ unique_id: rowID, method_id: methodID }))
      .unwrap()
      .then(async (resp) => {
        if (resp.code === 200) {
          if (indicator === '') {
            dispatch(setRowID(rowID));
            dispatch(setSubMethod(methodID));
            await dispatch(setLoader({ [methodBtnLoader]: false }));
            await dispatch(setMethodName(type));
            navigate(`/verifyinvestor/verification-type/method`);
          } else if (indicator === 'yes') {
            dispatch(setRowID(rowID));
            dispatch(setSubMethod(methodID));
            await dispatch(setLoader({ [methodBtnLoader]: false }));
            await dispatch(setMethodName(type));
            navigate(`/verifyinvestor/verification-type/method`);
          } else if (indicator === 'no') {
            dispatch(setLoader({ [methodBtnLoader]: false }));
            dispatch(setMethodName('changemethodtono'));
          } else if (indicator === 'openthree') {
            dispatch(setLoader({ [methodBtnLoader]: false }));
            dispatch(setMethodName('changemethodtoopenthree'));
          }
        }
      })
      .catch((err) => {
        console.log('err', err);
      });
  };

  const handlePopup = () => {
    setShowPopup(true);
  };

  const handleClickEntity = () => {
    dispatch(setMethodName('entitiestwoowner'));
    navigate(`/verifyinvestor/verification-type`);
    setShowPopup(false);
  };

  const yesButton = (type) => {
    dispatch(setMethodName(type));
    dispatch(setRowID(rowID));
    navigate(`/verifyinvestor/verification-type`);
  };

  const noButton = () => {
    goToSubMethods('entities', 9, '', 'no');
  };

  const changeStoreVariable = (type) => {
    dispatch(setMethodName(type));
    navigate(`/verifyinvestor/verification-type`);
  };

  const handleRevocableTrust = () => {
    dispatch(setMethodName('showindividualmethod'));
  };

  const isloadingTrue = Object.keys(loader).some(
    (key) => key.includes('methodBtnLoader') && loader[key]
  );
  const [isLoader] = useOutletContext();

  return (
    <React.Fragment>
      <section className="accreditation-main-section accreditation-inner-section main-section">
        <Container fluid>
          {isLoader ? (
            <SkeletonMethodsDashboard
              investorType={
                methodData.typeForSubMethod === 'qualifiedpurchaser' ? '' : 'individualMethod'
              }
            />
          ) : (
            <React.Fragment>
              <CommonMethodsHeader />
              <div className="accredited-common-box">
                <div className="verification-method-box">
                  <div className="accredited-method">
                    <Row>
                      <Col lg={12}>
                        <span className="cs-neutral-100 cs-medium-sub-heading-m">
                          Verification Method
                        </span>
                      </Col>
                    </Row>
                  </div>
                  {methodData?.methods?.length > 0 &&
                    methodData?.methods?.map((methodid, index) => {
                      return (
                        <React.Fragment key={index.toString()}>
                          <div className="vi-innerArea">
                            <Row>
                              <Col
                                xl={!methodData.noBtn.includes(methodid) && 11}
                                md={!methodData.noBtn.includes(methodid) && 9}
                                lg={methodData.noBtn.includes(methodid) && 12}>
                                <div className="cs-regular-sub-heading-m cs-neutral-90">
                                  {' '}
                                  <SubMethodsList methodId={methodid} />
                                </div>
                              </Col>
                              <Col xl={1} md={3}>
                                {methodData.differentBtnName.length > 0 ? (
                                  methodData.differentBtnName.map((btndata, i) => {
                                    if (btndata.formethod === methodid) {
                                      return (
                                        <Button
                                          key={i.toString() + index.toString()}
                                          className={`cs-btn-${btndata.btnstyle}${btndata.bottomSpace ? ' trust-btn' : ''} sm-btn cs-regular-h5${isloadingTrue ? ' cs-disabled' : ''}`}
                                          disabled={isloadingTrue}
                                          onClick={() => {
                                            if (btndata.btnfunc === 'yesButton') {
                                              yesButton(btndata.gotomethodtype);
                                            } else if (btndata.btnfunc === 'noButton') {
                                              noButton();
                                            } else if (
                                              btndata.btnfunc === 'gotomethod' &&
                                              btndata.formethod === methodid
                                            ) {
                                              goToSubMethods(
                                                btndata.gotomethodtype,
                                                btndata.gotomethodfunctionmethod
                                                  ? btndata.gotomethodfunctionmethod
                                                  : methodid,
                                                btndata.btnfunctionindicator,
                                                btndata.loaderSlug
                                              );
                                            } else if (btndata.btnfunc === 'changestorevalue') {
                                              changeStoreVariable(btndata.storevalue);
                                            } else if (
                                              btndata.btnfunc === 'handlerevoc' &&
                                              btndata.formethod === methodid
                                            ) {
                                              handleRevocableTrust();
                                            } else if (
                                              btndata.btnfunc === 'showwarning' &&
                                              btndata.formethod === methodid
                                            ) {
                                              if (
                                                methodData.warningModal.length > 0 &&
                                                methodData.warningModal.findIndex(
                                                  (d) => d.warningMethod !== methodid
                                                ) > -1
                                              ) {
                                                const method_id = methodData.warningModal.find(
                                                  (item) => item.warningMethod !== methodid
                                                ).warningMethod;
                                                setShowWarning({
                                                  ...showWarning,
                                                  [method_id]: true
                                                });
                                              } else {
                                                setShowWarning({
                                                  ...showWarning,
                                                  [methodid]: true
                                                });
                                              }
                                            }
                                          }}>
                                          {loader &&
                                            loader[
                                              `methodBtnLoader${
                                                btndata.btnfunc === 'noButton'
                                                  ? 9
                                                  : btndata.gotomethodfunctionmethod
                                                    ? btndata.gotomethodfunctionmethod
                                                    : methodid
                                              }${btndata.loaderSlug || ''}`
                                            ]}
                                          {btndata.btnname}
                                        </Button>
                                      );
                                    }
                                  })
                                ) : !methodData.noBtn.includes(methodid) ? (
                                  <Button
                                    className={`${methodData.dangerBtnMethod.includes(methodid) ? 'cs-btn-danger' : 'cs-btn-primary'} sm-btn cs-regular-h5${isloadingTrue ? ' cs-disabled' : ''}`}
                                    disabled={isloadingTrue}
                                    onClick={() => {
                                      if (
                                        methodData.warningModal.length > 0 &&
                                        methodData.warningModal.findIndex(
                                          (d) => d.warningMethod === methodid
                                        ) > -1
                                      ) {
                                        setShowWarning({ ...showWarning, [methodid]: true });
                                      } else if (
                                        methodData.warningModal.length > 0 &&
                                        methodData.warningModal.findIndex(
                                          (d) => d.warningMethod === 16
                                        ) > -1 &&
                                        methodid === 15
                                      ) {
                                        setShowWarning({ ...showWarning, [16]: true });
                                      } else if (
                                        methodData.customFunctionMethod.includes(methodid)
                                      ) {
                                        handlePopup(true);
                                      } else {
                                        goToSubMethods(methodData?.typeForSubMethod, methodid);
                                      }
                                    }}>
                                    {/* {loader && loader['methodBtnLoader' + methodid] && (
                                  <CommonSpinner
                                    classParent={`icon${loader && loader['methodBtnLoader' + methodid] ? ' cs-neutral-10 m-auto' : ' d-none'}`}
                                    size="20"
                                  />
                                )} */}
                                    {commonNames.Select}
                                  </Button>
                                ) : null}
                              </Col>
                            </Row>
                          </div>
                          {methodData?.methods?.length - 1 !== index && (
                            <div className="cs-divider">
                              <span className="cs-regular-body-text-m cs-neutral-50">OR</span>
                            </div>
                          )}
                        </React.Fragment>
                      );
                    })}
                </div>
                <p className="cs-regular-sub-heading-m cs-neutral-80">
                  {methodData.cancelverificationLine}, &nbsp;
                  <Link onClick={handleConfirmationModal} className="vi-link-color">
                    click here
                  </Link>
                </p>
              </div>
            </React.Fragment>
          )}
        </Container>
      </section>

      {methodData.warningModal?.length > 0 &&
        methodData.warningModal?.map((warningData, i) => {
          return (
            <WarningModalVI
              key={i}
              showWarning={showWarning[warningData.warningMethod]}
              onClose={() => setShowWarning({ ...showWarning, [warningData.warningMethod]: false })}
              onButtonClick={() => goToSubMethods(warningData.type, warningData.warningMethod)}
              loading={loader && loader['methodBtnLoader' + warningData.warningMethod]}
            />
          );
        })}
      <Modal
        show={showPopup}
        isCloseButton={false}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        className="cs-md-modal"
        title="Verification Method"
        body={
          <div className="delete-modal-content">
            <p className="cs-regular-h3">How many equity owners are there?</p>
            <Button
              className="cs-btn-secondary xl-btn cs-regular-h5"
              onClick={() => {
                dispatch(setMethodName('individuals'));
                dispatch(setRowID(rowID));
                setShowPopup(false);
                navigate(`/verifyinvestor/verification-type`);
              }}>
              1 Owner (who is a natural person)
            </Button>
            <div className="cs-divider">
              <span className="cs-regular-body-text-m cs-neutral-50">OR</span>
            </div>
            <Button
              className="cs-btn-secondary xl-btn cs-regular-h5"
              onClick={() => goToSubMethods('entities', 9)}>
              {loader && loader['methodBtnLoader' + 9] && (
                <CommonSpinner
                  // classParent={`cs-table-menu-icon icon${loader && loader['methodBtnLoader' + 9] ? ' cs-primary m-auto' : ' d-none'}`}
                  classParent="cs-primary"
                  size="20"
                />
              )}
              1 Owner (That is an entity)
            </Button>
            <div className="cs-divider">
              <span className="cs-regular-body-text-m cs-neutral-50">OR</span>
            </div>
            <Button
              className="cs-btn-secondary xl-btn cs-regular-h5"
              onClick={() => handleClickEntity()}>
              2 Owners
            </Button>
            <div className="cs-divider">
              <span className="cs-regular-body-text-m cs-neutral-50">OR</span>
            </div>
            <Button
              className="cs-btn-secondary xl-btn cs-regular-h5"
              onClick={() => goToSubMethods('entities', 9)}>
              {loader && loader['methodBtnLoader' + 9] && (
                <CommonSpinner
                  classParent="cs-primary"
                  // classParent={`cs-table-menu-icon icon${loader && loader['methodBtnLoader' + 9] ? ' cs-primary m-auto' : ' d-none'}`}
                  size="20"
                />
              )}
              More than 2 Owners
            </Button>
            <div className="cs-divider">
              <span className="cs-regular-body-text-m cs-neutral-50">OR</span>
            </div>
            <Button
              className="cs-btn-secondary xl-btn cs-regular-h5"
              onClick={() => setShowPopup(false)}>
              Cancel
            </Button>
          </div>
        }
      />
      <ConfirmationModel
        setShowConfirmation={setShowConfirmation}
        showConfirmation={showConfirmation}
      />
    </React.Fragment>
  );
};
export default MethodsView;
