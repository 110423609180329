import React from 'react';
import { isMobile } from 'react-device-detect';
import PropTypes from 'prop-types';

// COMPONENT IMPORTS
import CapsyncIcon from '../../components/CapsyncIcon';
import ActionIcons from './ActionIcons';
import { handleKeyDown, onRowSingleClick } from './FileManagementCommonElem';
import SortingAndFilteringHeader from './SortingAndFilteringHeader';
import { getFileIcon } from '../../utils/common';

/* ============================== GRID VIEW ============================== */
const GridView = (props) => {
  const {
    data = [],
    selectedRecords,
    moduleName = 'My Documents',
    breadcrumbDetails = [],
    parentId,
    sortingHeader,
    handleMove,
    setSortingHeader,
    openDropdownId,
    setOpenDropdownId,
    setSelectedRecords,
    setLastSelected,
    lastSelected,
    isGridView,
    handleDoubleClick,
    filter,
    setFilter,
    handleDelete,
    handleFileDownload,
    handleProtectedFileDownload,
    handleArchive,
    handleRenameItem
  } = props;

  const checkSelectedRecord = (id) => selectedRecords.some((e) => e.id === id);

  return (
    <React.Fragment>
      <SortingAndFilteringHeader
        isGridView={isGridView}
        moduleName={moduleName}
        breadcrumbDetails={breadcrumbDetails}
        parentId={parentId}
        sortingHeader={sortingHeader}
        setSortingHeader={setSortingHeader}
        filter={filter}
        setFilter={setFilter}
      />
      {data.length > 0 ? (
        <div
          className="cs-grid-view"
          onKeyDownCapture={(e) => handleKeyDown(e) && setSelectedRecords(data)}
          tabIndex="0">
          {data.map((value, index) => {
            const extension = value?.name?.split(/\.([^.]+)$/)?.[1]?.toLowerCase();
            return (
              <div
                key={index}
                className={checkSelectedRecord(value.id) ? 'cs-box selected' : 'cs-box'}
                onClick={(event) => {
                  isMobile
                    ? handleDoubleClick(value)
                    : onRowSingleClick(
                        event,
                        value,
                        setSelectedRecords,
                        data,
                        lastSelected,
                        setLastSelected
                      );
                }}
                onDoubleClick={() => !isMobile && handleDoubleClick(value)}
                id={'grid-' + index}>
                <div className="file-preview">
                  {value.thumbnail ? (
                    <img src={value.thumbnail} width={600} height={400} alt="Image Alt" />
                  ) : value.attachment ? (
                    <span>{getFileIcon(extension, 72)}</span>
                  ) : (
                    <span className="cs-neutral-60">
                      <CapsyncIcon title="folder-icon-filled" size="72" />
                    </span>
                  )}
                </div>
                <div className="file-info file-management-dropdown">
                  <div className="file-title">
                    {value.attachment ? (
                      <span>{getFileIcon(extension, 22)}</span>
                    ) : (
                      <span className="cs-neutral-60">
                        <CapsyncIcon title="folder-icon-filled" size="22" />
                      </span>
                    )}
                    <h4 className="cs-regular-body-text-l cs-neutral-100" title={value.name}>
                      {value.name}
                    </h4>
                  </div>
                  <ActionIcons
                    isGridOptions={true}
                    value={value}
                    parentId={parentId}
                    moduleName={moduleName}
                    selectedRecords={selectedRecords}
                    openDropdownId={openDropdownId}
                    setOpenDropdownId={setOpenDropdownId}
                    handleDelete={handleDelete}
                    handleMove={handleMove}
                    handleFileDownload={handleFileDownload}
                    handleProtectedFileDownload={handleProtectedFileDownload}
                    handleArchive={handleArchive}
                    handleRenameItem={handleRenameItem}
                  />
                </div>
              </div>
            );
          })}
        </div>
      ) : (
        <div
          className={`manage-file-container ${moduleName === 'My Documents' && 'gird-container'}`}>
          <label className="cs-upload-files cs-regular-h4">
            We don't have any active records to display
          </label>
        </div>
      )}
    </React.Fragment>
  );
};

// PROPS TYPES
GridView.propTypes = {
  data: PropTypes.array,
  selectedRecords: PropTypes.array,
  moduleName: PropTypes.string,
  openDropdownId: PropTypes.number,
  breadcrumbDetails: PropTypes.array,
  parentId: PropTypes.any,
  sortingHeader: PropTypes.object,
  setSortingHeader: PropTypes.any,
  setOpenDropdownId: PropTypes.any,
  setLastSelected: PropTypes.any,
  lastSelected: PropTypes.any,
  handleMove: PropTypes.func,
  setSelectedRecords: PropTypes.any,
  isGridView: PropTypes.bool,
  handleDoubleClick: PropTypes.func,
  filter: PropTypes.object,
  setFilter: PropTypes.any,
  handleDelete: PropTypes.func,
  handleFileDownload: PropTypes.func,
  handleProtectedFileDownload: PropTypes.func,
  handleArchive: PropTypes.func,
  handleRenameItem: PropTypes.func
};

export default GridView;
