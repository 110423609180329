import React, { useState } from 'react';
import { Button, Col, Dropdown, Form, NavDropdown, Row, Image } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

// COMPONENT IMPORTS
import FileManagementBreadcrumb from './FileManagementBreadcrumb';
import CapsyncIcon from '../../../components/CapsyncIcon';

// HOOKS IMPORTS
import { encryptReact } from '../../../utils/Hooks/EncryptDecryptReact';

// ICON IMPORTS
import GoogleDrive from '../../../assets/images/other/GoogleDrive.png';
import DropBox from '../../../assets/images/other/DropBox.png';
import FileManagementActionIcon from './FileManagementActionIcon';

// API
import { useToUserPermission } from '../../../slices/authSlice';

/* ============================== FM HEADER SECTION ============================== */
const FileManagementHeaderSection = (props) => {
  const {
    moduleName,
    breadcrumbDetails,
    searchedData,
    setSearchedData,
    handleSearch,
    fetchList,
    onSearchItemClick,
    setSelectedId,
    selectedRecords = [],
    layoutRowRef,
    handleFileDownload,
    // handleProtectedFileDownload,
    handleMove,
    handleArchive,
    isGridView,
    handleCreateFolder,
    handleFileUpload,
    handleUploadFolder,
    handleGoogleDriveClick,
    handleDropboxClick,
    setIsGridView,
    handleDelete,
    setSelectedRecords
  } = props;

  const navigate = useNavigate();

  const userPermission = useToUserPermission();

  const [searchValue, setSearchValue] = useState('');

  const uploadDropdownItems = [
    {
      title: 'New Folder',
      icon: { title: 'new-folder-', size: 22 },
      click: handleCreateFolder,
      is_permission: 'is_folder_create_allowed'
    },
    {
      title: 'File Upload',
      icon: { title: 'files-', size: 22 },
      click: handleFileUpload,
      is_permission: 'is_files_upload_allowed'
    },
    {
      title: 'Folder Upload',
      icon: { title: 'folder-icon-', size: 22 },
      click: handleUploadFolder,
      is_permission: 'is_folder_upload_allowed'
    },
    {
      title: 'Import',
      isDivider: true
    },
    {
      title: 'Google Drive',
      image: GoogleDrive,
      imageSize: { width: 24, height: 24 },
      is_permission: 'is_files_upload_allowed',
      click: handleGoogleDriveClick
    },
    {
      title: 'Dropbox',
      image: DropBox,
      imageSize: { width: 24, height: 24 },
      click: handleDropboxClick,
      is_permission: 'is_files_upload_allowed'
    }
    // {
    //   title: "OneDrive",
    //   image: OneDrive,
    //   imageSize: { width: 24, height: 24 },
    //   is_permission: "upload_files_allowed",
    // },
  ];

  const actionIcons = [
    {
      key: 'is_downloadable',
      className: 'cs-neutral-80',
      title: 'Download',
      icon: 'download-outlined',
      click: handleFileDownload,
      checkViewOnlyPermission: false,
      availableInModules: ['My Documents', 'Archive', 'Trash']
    },
    // {
    //   key: 'is_downloadable',
    //   className: 'cs-neutral-80',
    //   title: 'Protect Download',
    //   icon: 'protect-download-outlined',
    //   click: handleProtectedFileDownload,
    //   checkViewOnlyPermission: false,
    //   availableInModules: ['My Documents', 'Archive', 'Trash']
    // },
    {
      key: 'is_movable',
      className: 'cs-neutral-80',
      title: 'Move',
      icon: 'move-outlined',
      click: handleMove,
      checkViewOnlyPermission: true,
      availableInModules: ['My Documents', 'Archive', 'Trash']
    },
    {
      key: 'is_archive_allowed',
      className: 'cs-neutral-80',
      title: 'Archive',
      icon: 'archive-outlined',
      click: handleArchive,
      checkViewOnlyPermission: true,
      availableInModules: ['My Documents']
    },
    {
      key: 'is_delete_allowed',
      className: 'cs-danger',
      title: 'Delete',
      icon: 'delete-outlined',
      click: handleDelete,
      checkViewOnlyPermission: true,
      availableInModules: ['My Documents', 'Archive', 'Trash']
    }
  ];

  const checkPermissions = () => {
    return selectedRecords.length && selectedRecords.length > 0
      ? selectedRecords.every(
          (e) =>
            e.is_downloadable ||
            e.is_movable ||
            e.is_archive_allowed ||
            e.is_delete_allowed ||
            e.is_rename_allowed
        )
      : false;
  };

  const breadcrumbChange = (items) => {
    const data = [...breadcrumbDetails];
    const idx = data.findIndex((val) => val.id === items.id);
    const final_data = data.slice(0, idx + 1);
    const docsJSON = JSON.stringify(final_data);
    if (final_data.length > 1) {
      const encryptData = encryptReact(docsJSON);
      const encryptId = encryptData.replace(/\//g, '-');
      navigate(`/file-management/${encryptId}`);
    } else {
      navigate(`/file-management`);
    }
  };

  return (
    <div className="cs-section-topbar">
      <Row>
        <Col>
          <div className="cs-section-title">
            <div className="cs-breadcrumb">
              {breadcrumbDetails.length > 1 && (
                <FileManagementBreadcrumb
                  breadcrumbDetails={breadcrumbDetails}
                  onClick={(idx) => breadcrumbChange(idx)}
                />
              )}
            </div>
            <h1 className="cs-semi-bold-h1 cs-neutral-100">
              {breadcrumbDetails.length === 0
                ? moduleName
                : breadcrumbDetails[breadcrumbDetails.length - 1].title}
            </h1>
          </div>
        </Col>
        <Col>
          <div className="cs-search-bar global-search-box">
            <Dropdown className="cs-form-dropdown">
              <Dropdown.Toggle>
                <Form.Control
                  type="text"
                  className={searchValue && 'cs-input-field-active'}
                  value={searchValue}
                  onChange={(e) => {
                    setSearchValue(e.target.value);
                    handleSearch(e.target.value);
                  }}
                  placeholder="Search"
                />
                {searchValue === '' ? (
                  <span className="input-field-icon cs-neutral-70">
                    <CapsyncIcon title="search-outlined" size="18" />
                  </span>
                ) : (
                  <span
                    className="input-field-icon cs-neutral-70"
                    onClick={() => {
                      setSearchValue('');
                      moduleName !== 'My Documents' ? fetchList() : setSearchedData([]);
                    }}>
                    <CapsyncIcon title="close-outlined" size="14" />
                  </span>
                )}
              </Dropdown.Toggle>
              {moduleName === 'My Documents' && searchedData && searchedData.length ? (
                <Dropdown.Menu className="cs-scroller cs-setup-dropdown">
                  {searchedData.map((data, i) => (
                    <Dropdown.Item
                      key={i}
                      onClick={() => {
                        onSearchItemClick(data);
                      }}
                      className="google-map">
                      <span className="cs-neutral-80">
                        <CapsyncIcon
                          title={data.attachment ? 'files-outlined' : 'folder-icon-filled'}
                          size="18"
                        />
                      </span>
                      <span>{data.name || data.attachment}</span>
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              ) : null}
            </Dropdown>
          </div>
        </Col>
        <Col>
          <div
            className="layout-row"
            ref={layoutRowRef}
            onClick={() => {
              setSelectedId(null);
            }}>
            <div className="layout-box">
              {/* For laptop screen start */}
              {checkPermissions() ? (
                <div className="cs-lp-screen file-management-dropdown">
                  <NavDropdown
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                    title={
                      <span className="cs-neutral-80 cursor-pointer" title="More Action">
                        <CapsyncIcon title="option-horizontal-filled" size="18" />
                      </span>
                    }
                    className="cs-settings-dropdown">
                    {actionIcons.map((val, index) => {
                      if (
                        val.checkViewOnlyPermission &&
                        userPermission?.permission_type === 'view'
                      ) {
                        return false;
                      }
                      return (
                        <React.Fragment key={index}>
                          <FileManagementActionIcon
                            moduleName={moduleName}
                            isDropdown={true}
                            action={val}
                            selectedRecords={selectedRecords}
                          />
                        </React.Fragment>
                      );
                    })}
                  </NavDropdown>
                </div>
              ) : null}
              {/* For laptop screen end */}

              {checkPermissions() ? (
                <div className="for-des">
                  <div className="cs-action-btn">
                    <span
                      className="cs-primary"
                      title="Close"
                      onClick={() => setSelectedRecords([])}>
                      <CapsyncIcon title="close-outlined" size="14" />
                    </span>
                    {actionIcons.map((val, index) => {
                      if (
                        val.checkViewOnlyPermission &&
                        userPermission?.permission_type === 'view'
                      ) {
                        return false;
                      }
                      return (
                        <React.Fragment key={index}>
                          <FileManagementActionIcon
                            moduleName={moduleName}
                            isDropdown={false}
                            action={val}
                            selectedRecords={selectedRecords}
                          />
                        </React.Fragment>
                      );
                    })}
                  </div>
                </div>
              ) : null}
              <div
                className="layout-btn cursor-pointer"
                onClick={() => {
                  setIsGridView(!isGridView);
                  localStorage.setItem(
                    'wlp-file-management-view-type',
                    JSON.stringify(!isGridView)
                  );
                }}
                title={!isGridView ? 'Grid View' : 'List View'}>
                <span className="grid-view cs-neutral-80">
                  <CapsyncIcon
                    title={isGridView ? 'list-view-outlined' : 'icon-view-outlined'}
                    size="18"
                  />
                </span>
              </div>
            </div>
            {moduleName === 'My Documents' && (
              <Dropdown className="file-management-dropdown file-management-btn cs-settings-dropdown">
                <Dropdown.Toggle>
                  <Button
                    className="dropbtn cs-regular-h5 cs-btn-icon-primary"
                    disabled={
                      userPermission?.permission_type === 'view'
                        ? true
                        : !breadcrumbDetails[breadcrumbDetails.length - 1]
                            .is_folder_create_allowed &&
                          !breadcrumbDetails[breadcrumbDetails.length - 1].is_files_upload_allowed
                    }>
                    <span className="cs-neutral-10">
                      <CapsyncIcon title="add-filled" size="18" />
                    </span>
                    New
                  </Button>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {uploadDropdownItems.map((value, index) => {
                    if (value.isDivider) {
                      return (
                        <div key={index} className="cs-divider">
                          <span className="cs-neutral-50 cs-light-body-text-xs">{value.title}</span>
                        </div>
                      );
                    }
                    return (
                      <Dropdown.Item
                        key={index}
                        id={index}
                        tabIndex={index}
                        onClick={value.click || null}
                        className={`${
                          !breadcrumbDetails[breadcrumbDetails.length - 1][value.is_permission] &&
                          'd-none'
                        }`}
                        disabled={
                          !breadcrumbDetails[breadcrumbDetails.length - 1][value.is_permission]
                        }>
                        <div>
                          {value.icon && (
                            <React.Fragment>
                              <span className="filled-icon">
                                <CapsyncIcon
                                  title={value.icon.title + 'filled'}
                                  size={value.icon.size}
                                />
                              </span>
                              <span className="outlined-icon">
                                <CapsyncIcon
                                  title={value.icon.title + 'outlined'}
                                  size={value.icon.size}
                                />
                              </span>
                            </React.Fragment>
                          )}
                          {value.image && (
                            <span>
                              <Image
                                width={value.imageSize.width}
                                height={value.imageSize.height}
                                src={value.image}
                                alt={value.title}
                              />
                            </span>
                          )}
                        </div>
                        {value.title}
                      </Dropdown.Item>
                    );
                  })}
                </Dropdown.Menu>
              </Dropdown>
            )}
          </div>
        </Col>
      </Row>
    </div>
  );
};

// PROPS TYPE
FileManagementHeaderSection.propTypes = {
  moduleName: PropTypes.string,
  breadcrumbDetails: PropTypes.array,
  handleSearch: PropTypes.func,
  searchedData: PropTypes.any,
  fetchList: PropTypes.func,
  setSearchedData: PropTypes.any,
  selectedRecords: PropTypes.array,
  setSelectedId: PropTypes.any,
  layoutRowRef: PropTypes.any,
  handleFileDownload: PropTypes.any,
  // handleProtectedFileDownload: PropTypes.any,
  handleMove: PropTypes.any,
  handleArchive: PropTypes.any,
  isGridView: PropTypes.any,
  handleCreateFolder: PropTypes.func,
  handleFileUpload: PropTypes.func,
  handleUploadFolder: PropTypes.func,
  handleGoogleDriveClick: PropTypes.func,
  handleDropboxClick: PropTypes.func,
  setIsGridView: PropTypes.func,
  setSelectedRecords: PropTypes.func,
  handleDelete: PropTypes.func,
  onSearchItemClick: PropTypes.func
};

export default FileManagementHeaderSection;
