import { useEffect, useMemo } from 'react';

/**
 * Custom hook to calculate and update the balance due based on mortgage balance and ownership percentage.
 * @param {object} values - The values object containing mortgage_balance and ownership.
 * @param {function} options.setFieldValue - The function to set field value in the form.
 * @return {void}
 */
function useBalanceDue({ values, setFieldValue }) {
  const balanceDue = useMemo(() => {
    if (values.mortgage_balance && values.ownership) {
      return (
        (parseFloat(values.mortgage_balance.toString().replace(/,/g, '')) *
          parseFloat(values.ownership)) /
        100
      ).toString();
    }
    return '0';
  }, [values.mortgage_balance, values.ownership]);

  useEffect(() => {
    setFieldValue('balance_due', balanceDue);
  }, [balanceDue, setFieldValue]);

}

export default useBalanceDue;
