import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useOutletContext } from 'react-router-dom';
import { Container } from 'react-bootstrap';

// COMPONENT IMPORTS
import Tabs from '../../components/Tabs';
import commonNames from '../../config/commonNames';
import AddUserModal from './components/AddUserModal';
import AddUserButton from './components/AddUserButton';
import CollaborationTable from './components/CollaborationTable';
import CapsyncSectionHeader from '../../components/CapsyncSectionHeader';
import SkeletonManageUser from '../components/Skeleton/SkeletonManageUser';

// API
import { useUserID } from '../../slices/authSlice';
import { getCollaborationListing } from '../../slices/collaborationSlice';

/* ============================== COLLABORATION ============================== */
const Collaboration = () => {
  const defaultPaginationData = {
    page: 1,
    limit: 10
  };

  const dispatch = useDispatch();
  const { accessUser } = useSelector((state) => state.auth);
  const { individual, professionalUser, individualPagination, professionalUserPagination } =
    useSelector((state) => state.collaboration);

  const userID = useUserID();

  const [tab, setTab] = useState(0);
  const [paginationData, setPaginationData] = useState(defaultPaginationData);
  const [showModal, setShowModal] = useState(false);

  const hasFullCollaborationAccess = !!accessUser?.accessUserID;
  const data = tab === 0 ? individual : professionalUser;
  const totalDataCount =
    tab === 0
      ? individualPagination.itemCount || individual.length
      : professionalUserPagination.itemCount || professionalUser.length;

  const tabName = [
    { value: 0, name: commonNames.Individual },
    { value: 1, name: commonNames.Professional }
  ];

  const handleShowModal = () => setShowModal(true);
  const handleHideModal = () => setShowModal(false);
  const fetchCollaborators = () => dispatch(getCollaborationListing({ userID, ...paginationData }));

  useEffect(() => {
    fetchCollaborators();
  }, [paginationData]);

  useEffect(() => {
    setPaginationData(defaultPaginationData);
  }, [tab]);
  const [isLoader] = useOutletContext();

  return (
    <section className="main-section collaboration-main-section">
      <Container fluid>
        {isLoader ? (
          <SkeletonManageUser />
        ) : (
          <React.Fragment>
            <CapsyncSectionHeader
              mainTitle={commonNames.Dashboard}
              subTitle={commonNames.Collaboration}
              pageTitle={commonNames.Collaboration}
              mainUrl="/dashboard"
              hasLastSync={false}
            />
            <div className="cs-tabs-flex">
              <Tabs tab={tab} setTab={setTab} tabName={tabName} />
              {hasFullCollaborationAccess && <AddUserButton onClick={handleShowModal} />}
            </div>
            {hasFullCollaborationAccess && (
              <AddUserModal
                show={showModal}
                setShow={handleHideModal}
                fetchCollaborators={fetchCollaborators}
              />
            )}
            <CollaborationTable
              tab={tab}
              data={data}
              setPaginationData={setPaginationData}
              totalDataCount={totalDataCount}
              fetchCollaborators={fetchCollaborators}
              hasFullCollaborationAccess={hasFullCollaborationAccess}
            />
          </React.Fragment>
        )}
      </Container>
    </section>
  );
};

export default Collaboration;
