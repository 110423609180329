import commonNames from '../../../config/commonNames';

let typeForSubMethod = '';
let dangerBtnMethod = [];
let noBtn = [];
let methods = [];
let warningModal = [];
let cancelverificationLine = '';
let customFunctionMethod = [];
let differentBtnName = [];

export const stepOneMethods = (type) => {
  switch (type) {
    case 'individuals':
      {
        typeForSubMethod = 'individuals';
        dangerBtnMethod = [3, 15];
        noBtn = [];
        methods = [13, 1, 2, 3, 37, 38, 15];
        warningModal = [{ type: 'individuals', warningMethod: 15 }];
        cancelverificationLine =
          'If you are not an accredited investor or wish to cancel this verification request';
        customFunctionMethod = [];
        differentBtnName = [];
      }
      break;
    case 'qualifiedclient':
      {
        typeForSubMethod = 'qualifiedclient';
        dangerBtnMethod = [];
        noBtn = [];
        methods = [27, 28, 29, 30, 31];
        warningModal = [];
        cancelverificationLine =
          'If you are not a qualified client or wish to cancel this verification request';
        customFunctionMethod = [];
        differentBtnName = [];
      }
      // code block
      break;
    case 'qualifiedpurchaser':
      {
        typeForSubMethod = 'qualifiedpurchaser';
        dangerBtnMethod = [];
        noBtn = [];
        methods = [32, 33, 34, 35, 36];
        warningModal = [];
        cancelverificationLine =
          'If you are not a qualified purchaser or wish to cancel this verification request';
        customFunctionMethod = [];
        differentBtnName = [];
      }
      // code block
      break;
    case 'entities':
      {
        typeForSubMethod = 'entities';
        dangerBtnMethod = [15];
        noBtn = [];
        methods = [14, 4, 5, 6, 7, 8, 26, 10, 39, 40, 15];
        warningModal = [{ type: 'entities', warningMethod: 16 }];
        cancelverificationLine =
          'If you are not an accredited investor or wish to cancel this verification request';
        customFunctionMethod = [26];
        differentBtnName = [];
      }
      break;
    case 'entitiestwoowner':
      {
        typeForSubMethod = 'entitiestwoowner';
        dangerBtnMethod = [];
        noBtn = [];
        methods = [43];
        warningModal = [];
        cancelverificationLine =
          'If you are not an accredited investor or wish to cancel this verification request';
        customFunctionMethod = [];
        differentBtnName = [
          {
            btnname: 'Yes',
            btnstyle: 'primary',
            btnfunc: 'yesButton',
            gotomethodtype: 'individuals',
            formethod: 43,
            bottomSpace: true
          },
          { btnname: 'No', btnstyle: 'danger', formethod: 43, btnfunc: 'noButton' }
        ];
      }
      // code block
      break;
    case 'trusts':
      {
        typeForSubMethod = 'trusts';
        dangerBtnMethod = [];
        noBtn = [];
        methods = [22];
        warningModal = [];
        cancelverificationLine =
          'If you are not an accredited investor or wish to cancel this verification request';
        customFunctionMethod = [];
        differentBtnName = [
          {
            btnname: 'Yes',
            btnstyle: 'primary',
            btnfunc: 'gotomethod',
            gotomethodtype: 'trusts',
            btnfunctionindicator: 'yes',
            formethod: 22,
            loaderSlug: 'yes',
            bottomSpace: true
          },
          {
            btnname: 'No',
            btnstyle: 'danger',
            btnfunc: 'changestorevalue',
            storevalue: 'showtrustmethods',
            formethod: 22,
            loaderSlug: 'no'
          }
        ];
      }
      break;
    case 'showtrustmethods':
      {
        typeForSubMethod = 'showtrustmethods';
        dangerBtnMethod = [];
        noBtn = [];
        methods = [23];
        warningModal = [];
        cancelverificationLine =
          'If you are not an accredited investor or wish to cancel this verification request';
        customFunctionMethod = [];
        differentBtnName = [
          {
            btnname: 'Yes',
            btnstyle: 'primary',
            btnfunc: 'gotomethod',
            gotomethodtype: 'trusts',
            btnfunctionindicator: 'yes',
            formethod: 23,
            loaderSlug: 'yes',
            bottomSpace: true
          },
          {
            btnname: 'No',
            btnstyle: 'danger',
            gotomethodtype: 'trusts',
            btnfunc: 'gotomethod',
            btnfunctionindicator: 'no',
            formethod: 23,
            loaderSlug: 'no'
          }
        ];
      }
      break;
    case 'changemethodtono':
      {
        typeForSubMethod = 'changemethodtono';
        dangerBtnMethod = [44, 45];
        noBtn = [];
        methods = [44, 45];
        warningModal = [];
        cancelverificationLine =
          'If you are not an accredited investor or wish to cancel this verification request';
        customFunctionMethod = [];
        differentBtnName = [
          {
            btnname: commonNames.Select,
            btnstyle: 'danger',
            btnfunc: 'handlerevoc',
            formethod: 44
          },
          {
            btnname: commonNames.Select,
            btnstyle: 'danger',
            btnfunc: 'gotomethod',
            btnfunctionindicator: 'openthree',
            formethod: 45
          }
        ];
      }
      break;
    case 'changemethodtoopenthree':
      {
        typeForSubMethod = 'changemethodtoopenthree';
        dangerBtnMethod = [13, 24, 25];
        noBtn = [46];
        methods = [46, 13, 24, 25];
        warningModal = [{ type: 'entities', warningMethod: 25 }];
        cancelverificationLine =
          'If you are not an accredited investor or wish to cancel this verification request';
        customFunctionMethod = [];
        differentBtnName = [
          {
            btnname: commonNames.Select,
            btnstyle: 'danger',
            btnfunc: 'gotomethod',
            gotomethodtype: 'trusts',
            btnfunctionindicator: 'yes',
            formethod: 13,
            gotomethodfunctionmethod: 22
          },
          {
            btnname: commonNames.Select,
            btnstyle: 'danger',
            btnfunc: 'gotomethod',
            gotomethodtype: 'trusts',
            btnfunctionindicator: 'yes',
            formethod: 24
          },
          {
            btnname: commonNames.Select,
            btnstyle: 'danger',
            btnfunc: 'showwarning',
            formethod: 25
          }
        ];
      }
      break;
    case 'showindividualmethod':
      {
        typeForSubMethod = 'showindividualmethod';
        dangerBtnMethod = [13, 1, 2, 3];
        noBtn = [46];
        methods = [46, 13, 1, 2, 3, 37, 38, 25];
        warningModal = [{ type: 'entities', warningMethod: 21 }];
        cancelverificationLine =
          'If you are not an accredited investor or wish to cancel this verification request';
        customFunctionMethod = [];
        differentBtnName = [
          {
            btnname: commonNames.Select,
            btnstyle: 'danger',
            btnfunc: 'gotomethod',
            gotomethodtype: 'trusts',
            btnfunctionindicator: 'yes',
            formethod: 13,
            gotomethodfunctionmethod: 20
          },
          {
            btnname: commonNames.Select,
            btnstyle: 'danger',
            btnfunc: 'gotomethod',
            gotomethodtype: 'trusts',
            btnfunctionindicator: 'yes',
            formethod: 1,
            gotomethodfunctionmethod: 17
          },
          {
            btnname: commonNames.Select,
            btnstyle: 'danger',
            btnfunc: 'gotomethod',
            gotomethodtype: 'trusts',
            btnfunctionindicator: 'yes',
            formethod: 2,
            gotomethodfunctionmethod: 18
          },
          {
            btnname: commonNames.Select,
            btnstyle: 'danger',
            btnfunc: 'gotomethod',
            gotomethodtype: 'trusts',
            btnfunctionindicator: 'yes',
            formethod: 3,
            gotomethodfunctionmethod: 19
          },
          {
            btnname: commonNames.Select,
            btnstyle: 'primary',
            btnfunc: 'gotomethod',
            gotomethodtype: 'trusts',
            btnfunctionindicator: 'yes',
            formethod: 37,
            gotomethodfunctionmethod: 42
          },
          {
            btnname: commonNames.Select,
            btnstyle: 'primary',
            btnfunc: 'gotomethod',
            gotomethodtype: 'trusts',
            btnfunctionindicator: 'yes',
            formethod: 38,
            gotomethodfunctionmethod: 41
          },
          {
            btnname: commonNames.Select,
            btnstyle: 'primary',
            btnfunc: 'showwarning',
            formethod: 25,
            gotomethodfunctionmethod: 21
          }
        ];
      }
      break;
  }
  return {
    typeForSubMethod,
    dangerBtnMethod,
    noBtn,
    methods,
    warningModal,
    cancelverificationLine,
    customFunctionMethod,
    differentBtnName
  };
};
