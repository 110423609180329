import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

// COMPONENT IMPORTS
import axios from '../config/api';

export const getArrayDetails = createAsyncThunk('array/find-array-details', async (id) => {
  return await axios.get(`/array/find/${id}`);
});

export const updateArrayDetails = createAsyncThunk(
  'array/update-array-details',
  async (payload) => {
    return await axios.post('/array/update', payload);
  }
);

export const updateArrayToken = createAsyncThunk('array/update-array-token', async (payload) => {
  return await axios.post('/array/token_update', payload);
});

// INITIAL STATE
const initialState = {
  arrayDetails: { array_id: null, array_token: null },
  isIdpLoadedScript: false,
  isCmLoadedScript: [],
  arrayId: null
};

/* ============================== ARRAY SLICE ============================== */

const arraySlice = createSlice({
  name: 'array',
  initialState,
  reducers: {
    resetArraySlice: () => initialState,
    updateArrayData: (state, action) => {
      state.arrayDetails = action.payload;
    },
    setIdpLoadedScript: (state, action) => {
      state.isIdpLoadedScript = action.payload;
    },
    setArrayId: (state, action) => {
      state.arrayId = action.payload;
    },
    setCmLoadedScript: (state, action) => {
      const scriptName = action.payload;
      if (!scriptName) {
        state.isCmLoadedScript = [];
      } else {
        const index = state.isCmLoadedScript.findIndex((item) => item === scriptName);
        if (index === -1) {
          state.isCmLoadedScript.push(scriptName);
        } else {
          state.isCmLoadedScript[index] = scriptName;
        }
      }
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getArrayDetails.fulfilled, (state, { payload }) => {
        if (payload.data !== null) {
          state.arrayId = payload.data.array_id || null;
          state.arrayDetails = {
            array_id: payload.data.array_id || null,
            array_token: payload.data.array_token || null
          };
        } else {
          state.arrayDetails = { array_id: null, array_token: null };
          state.arrayId = null;
        }
      })
      .addCase(getArrayDetails.rejected, (state) => {
        state.arrayDetails = { array_id: null, array_token: null };
      });
  }
});
export const {
  updateArrayData,
  setIdpLoadedScript,
  setCmLoadedScript,
  setArrayId,
  resetArraySlice
} = arraySlice.actions;
export default arraySlice.reducer;

const selectArrayDetails = (state) => state.array.arrayDetails;
export const useArrayDetails = () => {
  const arrayDetails = useSelector(selectArrayDetails);
  return useMemo(() => arrayDetails, [arrayDetails]);
};

const selectIdpLoadedScript = (state) => state.array.isIdpLoadedScript;
export const useIdpLoadedScript = () => {
  const isIdpLoadedScript = useSelector(selectIdpLoadedScript);
  return useMemo(() => isIdpLoadedScript, [isIdpLoadedScript]);
};

const selectCmLoadedScript = (state) => state.array.isCmLoadedScript;
export const useCmLoadedScript = () => {
  const isCmLoadedScript = useSelector(selectCmLoadedScript);
  return useMemo(() => isCmLoadedScript, [isCmLoadedScript]);
};

const selectArrayId = (state) => state.array.arrayId;
export const useArrayId = () => {
  const arrayId = useSelector(selectArrayId);
  return useMemo(() => arrayId, [arrayId]);
};
