import React, { useState } from 'react';
import { Button, Dropdown, OverlayTrigger, Table, Tooltip } from 'react-bootstrap';
import PropTypes from 'prop-types';
import moment from 'moment';

// COMPONENT IMPORTS
import commonNames from '../../utils/commonNames';
import CapsyncIcon from '../../components/CapsyncIcon';
import CapsyncToolTip from '../../components/CapsyncToolTip';
import { customPortfolioHeader } from '../../constants/config';

const currencyFormat = (num) => {
  if (num > 0) {
    return (
      // '$' +
      ' ' +
      Number(num)
        .toFixed(2)
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
    );
  } else {
    return (
      // '-$' +
      ' ' +
      Number(Math.abs(num))
        .toFixed(2)
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
    );
  }
};

const dateUpdated = (params) => {
  return (
    <OverlayTrigger
      key={'top'}
      placement={'top'}
      overlay={
        <Tooltip style={{ maxWidth: '300px' }} className={'accredited-investor-tooltip'}>
          {params}
        </Tooltip>
      }>
      <span>-</span>
    </OverlayTrigger>
  );
};

const capitalizeName = (first) => {
  return first.substring(0, 1).toUpperCase() + first.substring(1, first.lengt);
};

const totalBalance = (external_api, type) => {
  const totalExternalApi =
    external_api && external_api.reduce((prev, curr) => prev + curr[type], 0);
  return totalExternalApi;
};

/* ============================== SECURITY HOLDINGS ============================== */
const SecuritiesHoldings = ({ securitiesHoldings, headingState }) => {
  const paginationDropdownItems = [10, 15, 20, 25, 30];
  const [rowStartIdx, setRowStartIdx] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(paginationDropdownItems[0]);
  const [dropdownDisplay, setDropdownDisplay] = useState(rowsPerPage);

  const handlePaginationDropdownClick = (dropdownValue) => {
    setDropdownDisplay(dropdownValue);
    setRowStartIdx(0);
    if (dropdownValue === 'All') {
      setRowsPerPage(securitiesHoldings.length);
      return;
    }
    setRowsPerPage(dropdownValue);
  };

  const currentNumberOfRows = (rowStartIdx, rowsPerPage, lengthOfCompanies) => {
    if (rowStartIdx + rowsPerPage <= lengthOfCompanies) {
      return rowsPerPage;
    } else {
      return lengthOfCompanies - rowStartIdx;
    }
  };

  const handleFirstPageClick = () => {
    setRowStartIdx(0);
    // Assuming your rowsPerPage is already set appropriately
  };
  const handleLastPageClick = () => {
    const totalRows = securitiesHoldings.length;
    const lastPageStartIdx = Math.floor((totalRows - 1) / rowsPerPage) * rowsPerPage;
    setRowStartIdx(lastPageStartIdx);
  };

  const columnData = {
    Description: {
      selector: (tr_itr, key) => (
        <td key={key}>
          <CapsyncToolTip
            Child={tr_itr.name.length > 25 ? tr_itr.name.slice(0, 25) + '...' : tr_itr.name}
            placement={'top'}
            message={tr_itr.name}
            type="text"
          />
        </td>
      ),
      footer: (key) => <td key={key}>{commonNames.Total}</td>
    },
    Type: {
      selector: (tr_itr, key) => <td key={key}>{capitalizeName(tr_itr.type)}</td>
    },
    Symbol: {
      selector: (tr_itr, key) => <td key={key}>{tr_itr.ticker_symbol}</td>
    },
    Currency: {
      selector: (tr_itr, key) => (
        <td key={key} className="text-end">
          {tr_itr.currency}
        </td>
      )
    },
    Price: {
      selector: (tr_itr, key) => (
        <td key={key} className="text-end">
          {tr_itr.cost_basis ? '$' + currencyFormat(parseFloat(tr_itr.cost_basis).toFixed(2)) : '-'}
        </td>
      )
    },
    Value: {
      selector: (tr_itr, key) => (
        <td key={key} className="text-end">
          {tr_itr.price ? '$' + currencyFormat(parseFloat(tr_itr.price).toFixed(2)) : '-'}
        </td>
      )
    },
    Quantity: {
      selector: (tr_itr, key) => (
        <td key={key} className="text-end">
          {tr_itr.quantity ? currencyFormat(parseFloat(tr_itr.quantity)) : '-'}
        </td>
      )
    },
    'Market Value': {
      selector: (tr_itr, key) => (
        <td key={key} className="text-end">
          {tr_itr.market_value
            ? '$' +
              currencyFormat(tr_itr.market_value && parseFloat(tr_itr.market_value).toFixed(2))
            : '-'}
        </td>
      ),
      footer: (key, allDatas) => (
        <td key={key} className="text-end">
          ${currencyFormat(totalBalance(allDatas, 'market_value')?.toFixed(2))}
        </td>
      )
    },
    'Date Updated': {
      selector: (tr_itr, key) => (
        <td key={key} className="text-end">
          {tr_itr.date_updated === '-'
            ? dateUpdated('data not available')
            : moment(tr_itr.date_updated).format('MM/DD/YYYY')}
        </td>
      )
    }
  };

  return (securitiesHoldings && securitiesHoldings.length === 0) ||
    (headingState && headingState.length === 0) ? (
    <span className="cs-common-no-record">There are no records to display</span>
  ) : (
    <div className="cs-data-table portfolio-data-table">
      {/* Custom table */}
      <div className="category-cs-table-container">
        <Table className="cs-table">
          <thead className="cs-thead">
            <tr>
              {Array.isArray(headingState) &&
                headingState.map((items, index) => {
                  return (
                    <React.Fragment key={index}>
                      <th
                        className={`default-medium-sub-heading-m ${customPortfolioHeader.includes(items) ? 'text-end' : null}`}
                        width={`${items == 'Description' ? '300' : items == 'Type' ? '180' : items == 'Symbol' ? '120' : items == 'Price' ? '140' : items == 'Value' ? '140' : items == 'Market Value' ? '140' : items === 'Date Updated' ? '160' : items === 'Quantity' ? '140' : items === 'Currency' ? '100' : ''}`}>
                        {items}
                      </th>
                    </React.Fragment>
                  );
                })}
            </tr>
          </thead>
          <tbody className="cs-tbody default-regular-sub-heading-m">
            {Array.isArray(securitiesHoldings) &&
              securitiesHoldings
                .slice(rowStartIdx, rowStartIdx + rowsPerPage)
                .map((items, index) => {
                  return (
                    <tr key={index}>
                      {Array.isArray(headingState) &&
                        headingState.map((head, i) => {
                          return columnData[head] ? columnData[head].selector(items, i) : null;
                        })}
                    </tr>
                  );
                })}
            <tr className="cs-tfoot-assets default-medium-sub-heading-m">
              {Array.isArray(headingState) &&
                headingState.map((head, i) => {
                  return (
                    <React.Fragment key={i}>
                      {columnData[head] && columnData[head].footer ? (
                        columnData[head].footer(i, securitiesHoldings)
                      ) : (
                        <td></td>
                      )}
                    </React.Fragment>
                  );
                })}
            </tr>
          </tbody>
        </Table>
      </div>
      {securitiesHoldings && securitiesHoldings.length > 0 && (
        <div className="cs-custom-pagination">
          <div className="cs-common-add-dropdown">
            <span className="cs-light-body-text-m cs-neutral-80">Rows per page:</span>
            <Dropdown>
              <Dropdown.Toggle className="cs-light-body-text-m cs-neutral-80">
                {dropdownDisplay}
                <CapsyncIcon title="chevron-down-outlined" size="12" />
              </Dropdown.Toggle>

              <Dropdown.Menu onSelect={(e) => console.log(e)}>
                {paginationDropdownItems.map((noOfRowsPerPage, index) => {
                  return (
                    <Dropdown.Item
                      className={noOfRowsPerPage === dropdownDisplay && 'cs-selected-dropdown'}
                      key={index.toString()}
                      onClick={() => handlePaginationDropdownClick(noOfRowsPerPage)}
                      value={noOfRowsPerPage}>
                      {noOfRowsPerPage}
                    </Dropdown.Item>
                  );
                })}
                <Dropdown.Item
                  onClick={() => handlePaginationDropdownClick('All')}
                  className={'All' === dropdownDisplay && 'cs-selected-dropdown'}>
                  All
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
          <div className="cs-light-body-text-m cs-neutral-80">
            <p>
              {rowStartIdx + 1} -{' '}
              {rowStartIdx +
                currentNumberOfRows(rowStartIdx, rowsPerPage, securitiesHoldings.length)}{' '}
              of {securitiesHoldings.length}
            </p>
          </div>
          <div className="cs-pagination-arrow">
            <Button
              onClick={handleFirstPageClick}
              disabled={rowStartIdx === 0}
              className={rowStartIdx === 0 ? 'cs-neutral-50' : 'cs-neutral-100'}>
              <CapsyncIcon title="chevron-left-skip-outlined" size="18" />
            </Button>
            <Button
              onClick={() => setRowStartIdx(rowStartIdx - rowsPerPage)}
              disabled={rowStartIdx === 0}
              className={rowStartIdx === 0 ? 'cs-neutral-50' : 'cs-neutral-100'}>
              <CapsyncIcon title="chevron-left-outlined" size="18" />
            </Button>
            <Button
              onClick={() => setRowStartIdx(rowStartIdx + rowsPerPage)}
              className={
                !(rowStartIdx + rowsPerPage < securitiesHoldings.length)
                  ? 'cs-neutral-50'
                  : 'cs-neutral-100'
              }
              disabled={!(rowStartIdx + rowsPerPage < securitiesHoldings.length)}>
              <CapsyncIcon title="chevron-right-outlined" size="18" />
            </Button>
            <Button
              onClick={handleLastPageClick}
              className={
                !(rowStartIdx + rowsPerPage < securitiesHoldings.length)
                  ? 'cs-neutral-50'
                  : 'cs-neutral-100'
              }
              disabled={!(rowStartIdx + rowsPerPage < securitiesHoldings.length)}>
              <CapsyncIcon title="chevron-right-skip-outlined" size="18" />
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

// PROPS TYPES
SecuritiesHoldings.propTypes = {
  securitiesHoldings: PropTypes.array,
  headingState: PropTypes.array
};

export default SecuritiesHoldings;
