import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-bootstrap';

// COMPONENT IMPORTS
import InputBox from '../../components/FormFields/InputBox';

/* ============================== JOINT PFS ============================== */
const JointPFS = (props) => {
  const { sharePFSThirdModalSchema } = props;
  return (
    <Row>
      <Col lg={12}>
        <InputBox
          label="Enter name of cosigner"
          name="name"
          values={sharePFSThirdModalSchema.values}
          errors={sharePFSThirdModalSchema.errors}
          touched={sharePFSThirdModalSchema.touched}
          disabled={false}
          onChange={sharePFSThirdModalSchema.handleChange}
        />
      </Col>
      <Col lg={12}>
        <InputBox
          label="Enter email of cosigner"
          name="email"
          values={sharePFSThirdModalSchema.values}
          errors={sharePFSThirdModalSchema.errors}
          touched={sharePFSThirdModalSchema.touched}
          disabled={false}
          onChange={sharePFSThirdModalSchema.handleChange}
        />
      </Col>
    </Row>
  );
};

// PROPS TYPE
JointPFS.propTypes = {
  sharePFSThirdModalSchema: PropTypes.obj
};

export default JointPFS;
