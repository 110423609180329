import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

// COMPONENT IMPORTS
import CapsyncIcon from './CapsyncIcon';

/* ============================== BREADCRUMBS ============================== */
const CapsyncBreadcrumb = ({ mainTitle, subTitle, mainUrl, multiLevelFlag, mainLevelArr }) => {
  return (
    <div className="cs-breadcrumb">
      {mainTitle && (
        <React.Fragment>
          {multiLevelFlag === 'true' ? (
            mainLevelArr &&
            mainLevelArr.map((items, index) => {
              return (
                <React.Fragment key={index}>
                  <Link key={items} to={items.url} className="cs-light-body-text-m cs-neutral-80">
                    {items.title}
                  </Link>
                  <span className="cs-neutral-70">
                    <CapsyncIcon title="chevron-right-outlined" size="12" />
                  </span>
                </React.Fragment>
              );
            })
          ) : (
            <React.Fragment>
              <Link to={mainUrl} className="cs-light-body-text-m cs-neutral-80">
                {mainTitle}
              </Link>
              <span className="cs-neutral-70">
                <CapsyncIcon title="chevron-right-outlined" size="12" />
              </span>
            </React.Fragment>
          )}
          <span className="cs-light-body-text-m cs-neutral-100">{subTitle}</span>
        </React.Fragment>
      )}
    </div>
  );
};

// PROPS TYPE
CapsyncBreadcrumb.propTypes = {
  mainTitle: PropTypes.string,
  subTitle: PropTypes.string,
  mainUrl: PropTypes.string,
  multiLevelFlag: PropTypes.string,
  mainLevelArr: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired
    })
  )
};

export default CapsyncBreadcrumb;
