import React from 'react';
import { Col, Row } from 'react-bootstrap';
import Skeleton from 'react-loading-skeleton';

/* ============================== SKELETON ALL SUB METHODS ============================== */
const SkeletonAllSubMethods = () => {
  return (
    <React.Fragment>
      <div className="cs-section-topbar for-des">
        <div className="amortization-skeleton-breadcrumb">
          <div className="skeleton-breadcrumb-1">
            {' '}
            <Skeleton width={100} />
          </div>
          <div className="skeleton-breadcrumb-2">
            {' '}
            <Skeleton width={20} />
          </div>
          <div className="skeleton-breadcrumb-3">
            <Skeleton width={100} />
          </div>
          <div className="skeleton-breadcrumb-4">
            {' '}
            <Skeleton width={20} />
          </div>
          <div className="skeleton-breadcrumb-5">
            <Skeleton width={100} />
          </div>
        </div>
        <div className="cs-title">
          <h1 className="default-semi-bold-h1 cs-neutral-100">
            <Skeleton width={200} />
          </h1>
        </div>
      </div>
      <div className="cs-section-topbar for-mob">
        <div className="amortization-skeleton-breadcrumb">
          <div className="skeleton-breadcrumb-1">
            {' '}
            <Skeleton width={70} />
          </div>
          <div className="skeleton-breadcrumb-2">
            {' '}
            <Skeleton width={20} />
          </div>
          <div className="skeleton-breadcrumb-3">
            <Skeleton width={70} />
          </div>
          <div className="skeleton-breadcrumb-4">
            {' '}
            <Skeleton width={20} />
          </div>
          <div className="skeleton-breadcrumb-5">
            <Skeleton width={70} />
          </div>
        </div>
        <div className="cs-title">
          <h1 className="default-semi-bold-h1 cs-neutral-100">
            <Skeleton width={150} />
          </h1>
        </div>
      </div>
      <div className="method-section">
        <div className="default-regular-body-text-l cs-neutral-70">
          {' '}
          <Skeleton height={95} />
        </div>
        {/* <div className="cs-divider"><Skeleton /></div> */}
        <div className="cs-divider"></div>
        <div className="accreditation-common-tooltip">
          <p>
            <span className="default-regular-body-text-l cs-neutral-70">
              {' '}
              <Skeleton />
            </span>{' '}
            <br />
            <span className="default-regular-body-text-l cs-danger">
              {' '}
              <Skeleton />
            </span>
          </p>
          <u className="default-regular-body-text-l cs-neutral-90">
            <Skeleton width={150} />
          </u>
        </div>
        <div className="row-spacing">
          <Row>
            <Col lg={5}>
              <div className="cs-form-group">
                <span className="default-regular-sub-heading-s cs-neutral-90">
                  <Skeleton />{' '}
                </span>
                <span>
                  <Skeleton height={40} />
                </span>
              </div>
            </Col>
          </Row>
          {/* <div className="cs-divider"><Skeleton /></div> */}
          <div className="cs-divider"></div>
        </div>
        <p>
          <Skeleton />
        </p>
        <Row>
          <Col lg={12}>
            <div>
              <Skeleton height={95} />
            </div>
          </Col>
        </Row>
        {/* <div className="cs-divider"><Skeleton /></div> */}
        <div className="cs-divider"></div>
        <div className="row-spacing">
          <span className="default-regular-body-text-l cs-neutral-90">
            {' '}
            <Skeleton width={200} />{' '}
          </span>
          <p>
            {' '}
            <Skeleton />{' '}
          </p>
        </div>
        <div className="cs-form-group">
          <Skeleton height={95} />{' '}
        </div>
        {/* <div className="cs-divider"><Skeleton /></div> */}
        <div className="cs-divider"></div>
        <p>
          {' '}
          <Skeleton />
        </p>
      </div>
    </React.Fragment>
  );
};

export default SkeletonAllSubMethods;
