export const validateDuplicateFileName = (newFiles, existingFiles, setError) => {
  const maxFileSize = process.env.REACT_APP_FILE_UPLOAD_SIZE_ALLOWED_FILE_MANAGEMENT;
  const supportedExtensions = ['.pdf', '.png', '.jpg', '.jpeg'];
  let totalNewFileSize = 0;
  let totalExistingFileSize = 0;

  for (const newFile of newFiles) {
    for (const existingFile of existingFiles) {
      if (
        (newFile.name && newFile.name === existingFile.name) ||
        (newFile.name && newFile.name === existingFile.original_file_name) ||
        newFile === existingFile.original_file_name ||
        (existingFile.attachments && existingFile.attachments.includes(newFile.name)) ||
        (existingFile.files && newFile.name === existingFile.files.name)
      ) {
        setError('A file with this name already exists');
        return false;
      }
      totalExistingFileSize += existingFile.size;
    }

    // check file extension
    const fileExtension = newFile.name.slice(((newFile.name.lastIndexOf('.') - 1) >>> 0) + 2);
    const notAllowedExtensionHandling =
      fileExtension === '' || fileExtension === null || fileExtension === undefined;
    if (
      notAllowedExtensionHandling ||
      !supportedExtensions.includes(`.${fileExtension.toLowerCase()}`)
    ) {
      setError('Format not supported');
      return false;
    }

    // Check for maximum file size
    if (newFile.size && newFile.size > maxFileSize) {
      setError(`This file exceeds maximum file size limit (${maxFileSize / 1048576} MB)`);
      return false;
    }

    totalNewFileSize += newFile.size;
  }

  if (totalNewFileSize + totalExistingFileSize > maxFileSize) {
    setError(`This file exceeds maximum file size limit (${maxFileSize / 1048576} MB)`);
    return false;
  }

  setError('');
  return true;
};
