import React from 'react';
import { NavDropdown } from 'react-bootstrap';
import PropTypes from 'prop-types';

// COMPONENT IMPORTS
import CapsyncIcon from '../../../components/CapsyncIcon';

/* ============================== FM ACTION ICON ============================== */
const FileManagementActionIcon = (props) => {
  const { action, selectedRecords, isDropdown = false, moduleName } = props;

  const { key, title, icon, click, availableInModules, className } = action;

  const checkIndividualPermissions = (permission_type) => {
    return selectedRecords.length > 0
      ? selectedRecords.every((e) => e[permission_type] === true)
      : false;
  };

  if (!checkIndividualPermissions(key) || !availableInModules.includes(moduleName)) {
    return false;
  }
  return isDropdown ? (
    <NavDropdown.Item
      title={title}
      onClick={(e) => {
        click(selectedRecords);
      }}>
      <span>
        <CapsyncIcon title={icon} size="22" />
      </span>
      {title}
    </NavDropdown.Item>
  ) : (
    <span
      className={className}
      title={title}
      onClick={(e) => {
        e.stopPropagation();
        click(selectedRecords);
      }}>
      <CapsyncIcon title={icon} size="18" />
    </span>
  );
};

// PROPS TYPE
FileManagementActionIcon.propTypes = {
  action: PropTypes.any,
  selectedRecords: PropTypes.array,
  isDropdown: PropTypes.bool,
  moduleName: PropTypes.string
};

export default FileManagementActionIcon;
