import { createAsyncThunk } from '@reduxjs/toolkit';

// COMPONENT IMPORTS
import axios from '../config/api';

export const quickBooksAuthentication = createAsyncThunk('/quickbooks/auth', async (values) => {
  try {
    const response = await axios.get('/quickbooks/auth', {
      params: values
    });
    return response;
  } catch (error) {
    console.log('error', error);
  }
});

export const quickBooksDataAdd = createAsyncThunk('/quickbooks/add', async (values) => {
  try {
    const response = await axios.post('/quickbooks/add', values);
    return response.data;
  } catch (error) {
    console.log('error', error);
  }
});

export const refreshTokenPresent = createAsyncThunk('/quickbooks/tokenpresent', async (values) => {
  try {
    const response = await axios.post('/quickbooks/tokenpresent', values);
    console.log(response);
    return response.data;
  } catch (error) {
    console.log('error', error);
  }
});

export const quickBooksFetchCategoryData = createAsyncThunk(
  '/quickbooks/reports',
  async (values) => {
    try {
      const response = await axios.post('/quickbooks/reports', values);
      return response;
    } catch (error) {
      console.log('error', error);
    }
  }
);

export const quickBooksDisconnectReport = createAsyncThunk(
  '/quickbooks/disconnect/reports',
  async (values) => {
    try {
      const response = await axios.post('/quickbooks/disconnect/reports', values);
      return response.data;
    } catch (error) {
      console.log('error', error);
    }
  }
);

export const quickBooksReportFilter = createAsyncThunk('/quickbooks/report/run', async (values) => {
  try {
    const response = await axios.post('/quickbooks/report/run', values);
    return response;
  } catch (error) {
    console.log('error', error);
  }
});

export const quickBooksFetchReportFilter = createAsyncThunk(
  '/quickbooks/report/filters',
  async (values) => {
    try {
      const response = await axios.post('/quickbooks/report/filters', values);
      return response;
    } catch (error) {
      console.log('error', error);
    }
  }
);

export const quickBooksShareReport = createAsyncThunk(
  '/quickbooks/report/share',
  async (values) => {
    try {
      const response = await axios.post('/quickbooks/report/share', values);
      return response;
    } catch (error) {
      console.log('error', error);
    }
  }
);
