import React from 'react';
import { Table } from 'react-bootstrap';
import Skeleton from 'react-loading-skeleton';
import PropTypes from 'prop-types';

/* ============================== SKELETON FINANCE TABLE ============================== */
const SkeletonFinanceTable = (props) => {
  const { data, type } = props;

  return (
    <div className="finance-data-container">
      <div className="finance-data skeleton-finance-data">
        <div className="default-semi-bold-h3 cs-neutral-100 finance-heading skeleton-finance-heading">
          <Skeleton width={70} />
        </div>
        <div className="for-des">
          <Table className="skeleton-table">
            <thead className="finance-thead">
              <tr>
                <th className="default-medium-sub-heading-l skeleton-table-first-th">
                  {' '}
                  <Skeleton width={80} />
                </th>
                <th className="default-medium-sub-heading-l">
                  {' '}
                  <Skeleton width={80} />
                </th>
              </tr>
            </thead>
            <tbody className="finance-tbody default-regular-sub-heading-m">
              {data.map((category, index) => {
                return (
                  <tr key={index}>
                    <td>
                      <Skeleton />
                    </td>
                    <td>
                      <Skeleton />
                    </td>
                    <td className="go-next-page-div">
                      <Skeleton width={30} />
                    </td>
                  </tr>
                );
              })}
              <tr className="default-medium-sub-heading-m ">
                <td className="style-tfoot">
                  {' '}
                  <Skeleton width={150} />
                </td>
                <td>
                  <Skeleton />
                </td>
                <td className="go-next-page-div style-tfoot"></td>
              </tr>
            </tbody>
          </Table>
        </div>
        <div className="for-mob">
          <Table className="skeleton-table">
            <thead className="finance-thead">
              <tr>
                <th className="default-medium-sub-heading-l skeleton-table-first-th">
                  {' '}
                  <Skeleton width={50} />
                </th>
                <th className="default-medium-sub-heading-l">
                  {' '}
                  <Skeleton width={50} />
                </th>
              </tr>
            </thead>
            <tbody className="finance-tbody default-regular-sub-heading-m">
              {data.map((category, index) => {
                return (
                  <tr key={index}>
                    <td>
                      <Skeleton />
                    </td>
                    <td>
                      <Skeleton />
                    </td>
                  </tr>
                );
              })}
              <tr className="default-medium-sub-heading-m ">
                <td className="style-tfoot">
                  <Skeleton width={100} />
                </td>
                <td>
                  <Skeleton />
                </td>
                <td className="go-next-page-div style-tfoot"></td>
              </tr>
            </tbody>
          </Table>
        </div>
      </div>
      {type === 'Liabilities' && (
        <div className="contingent-finance-data skeleton-finance-data">
          <h3 className="heading default-semi-bold-h3 cs-neutral-100">
            <Skeleton width={70} />
            <span className="default-light-body-text-s">
              <Skeleton width={70} />
            </span>
          </h3>
          <Table className="skeleton-table">
            <thead className="finance-thead">
              <tr>
                <th className="default-medium-sub-heading-l skeleton-table-first-th">
                  {' '}
                  <Skeleton width={80} />
                </th>
                <th className="default-medium-sub-heading-l">
                  {' '}
                  <Skeleton width={80} />
                </th>
                <th className="default-medium-sub-heading-l"> </th>
              </tr>
            </thead>
            <tbody className="contingent-tbody default-regular-sub-heading-m">
              <tr>
                <td>
                  {' '}
                  <Skeleton />
                </td>
                <td>
                  <Skeleton />
                </td>
                <td className="go-next-page-div"></td>
              </tr>
              <tr className="legal-contingent-row">
                <td>
                  {' '}
                  <Skeleton />
                </td>
                <td>
                  <Skeleton />
                </td>
                <td className="go-next-page-div"></td>
              </tr>
            </tbody>
          </Table>
        </div>
      )}
    </div>
  );
};

// PROPS TYPE
SkeletonFinanceTable.propTypes = {
  type: PropTypes.string,
  data: PropTypes.array
};

export default SkeletonFinanceTable;
