import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

// COMPONENT IMPORTS
import { dateFormat } from '../../../utils/common';
import CustomTable from '../../components/CustomTable';
import StaticPagination from '../common/StaticPagination';
import CapsyncIcon from '../../../components/CapsyncIcon';
import CapsyncToolTip from '../../../components/CapsyncToolTip';

// API
import {
  downloadApprovedCertificate,
  findForCertificate,
  getRequestList,
  setLoader
} from '../../../slices/accreditationSlice';

/* ============================== COMPLETED STATUS ============================== */
const Completed = () => {
  const dispatch = useDispatch();

  const { user } = useSelector((state) => state.auth);
  const { data = [] } = useSelector((state) => state.accreditation);

  const [rowStartIdx, setRowStartIdx] = useState(0);
  const paginationDropdownItems = [10, 15, 20, 25, 30];
  const [rowsPerPage, setRowsPerPage] = useState(paginationDropdownItems[0]);
  const [dropdownDisplay, setDropdownDisplay] = useState(rowsPerPage);

  useEffect(() => {
    dispatch(setLoader({ listingLoading: true }));
    getList();
  }, []);

  const getList = () => {
    const data = {
      user_id: user.id
    };
    dispatch(getRequestList(data))
      .unwrap()
      .then(() => {
        dispatch(setLoader({ listingLoading: false }));
      });
  };

  const downloadCertificate = (id) => {
    if (id) {
      dispatch(setLoader({ cerificateDownloadLoading: true }));
      dispatch(findForCertificate({ unique_id: id }))
        .unwrap()
        .then((resp) => {
          if (resp.code === 200) {
            if (resp.data.response_id) {
              dispatch(
                downloadApprovedCertificate({ uid: user.id, response_id: resp.data.response_id })
              )
                .unwrap()
                .then((response) => {
                  if (response.data) {
                    const a = document.createElement('a');
                    a.href = response.data;
                    document.body.appendChild(a);
                    a.click();
                    document.body.removeChild(a);
                  }
                  toast.success('Certificate downloaded successfully.');
                })
                .catch((error) => {
                  console.log('error', error);
                  toast.error(error.message);
                });
            }
          }
          dispatch(setLoader({ certificateDownloadLoading: false }));
        })
        .catch((err) => {
          console.log('err', err);
          dispatch(setLoader({ certificateDownloadLoading: false }));
        });
    }
  };

  const COLUMNS = [
    {
      label: 'Legal name',
      width: '263',
      visible: true,
      renderCell: (item) => item.legal_name
    },
    {
      label: 'Requested',
      width: '163',
      visible: true,
      renderCell: (item) => dateFormat(item.createdAt)
    },
    {
      label: 'Type',
      width: '263',
      visible: true,
      renderCell: (item) => item.verification_type
    },
    {
      label: 'Status',
      width: '263',
      visible: true,
      renderCell: (item) => (
        <div className="cs-progress-indicator">
          <div className="cs-indicator cs-success">
            <span>
              <CapsyncToolTip
                Child={'information-outlined'}
                placement={'top'}
                message={'Information received'}
                type="icon"
                size="22"
              />
              <span className="cs-indicator-line"></span>
            </span>
          </div>

          <div className="cs-indicator cs-success">
            <span>
              <CapsyncToolTip
                Child={'review-outlined'}
                placement={'top'}
                message={'Review done'}
                type="icon"
                size="22"
              />
            </span>
            {/* <span className="cs-indicator-line"></span> */}
            <span
              className={`cs-indicator-line ${item.request_status === 3 ? 'cs-danger' : ''}`}></span>
          </div>
          {item.request_status === 3 ? (
            <div className="cs-indicator cs-danger">
              <span>
                <CapsyncToolTip
                  Child={'declined-outlined'}
                  placement={'top'}
                  message={'Rejected'}
                  type="icon"
                  size="22"
                />
              </span>
            </div>
          ) : (
            <div className="cs-indicator cs-success">
              <span>
                <CapsyncToolTip
                  Child={'verification-outlined'}
                  placement={'top'}
                  message={'Completed'}
                  type="icon"
                  size="22"
                />
              </span>
            </div>
          )}
        </div>
      )
    },
    {
      label: 'Reference',
      width: '220',
      visible: true,
      renderCell: (item) =>
        item.reference && item.reference !== '' && item.reference !== null ? item.reference : '-'
    },
    {
      label: 'Expires',
      width: '189',
      visible: true,
      renderCell: (item) =>
        item.request_expires_at && item.request_expires_at !== null
          ? dateFormat(item.request_expires_at)
          : '-'
    },
    {
      label: 'Actions',
      width: '223',
      visible: true,
      renderCell: (item) => (
        <div className="cs-table-icons cs-neutral-80">
          <span
            className={`cursor-pointer svg-icon ${item.request_status === 3 ? 'cs-disabled' : ''}`}
            onClick={() => {
              if (item.request_status !== 3) {
                downloadCertificate(item.unique_id);
              }
            }}>
            <CapsyncIcon title="files-outlined" size="18" />
          </span>
        </div>
      )
    }
  ];

  return (
    <div className="individual-table">
      <CustomTable
        columns={COLUMNS}
        data={data.completed.slice(rowStartIdx, rowStartIdx + rowsPerPage)}
        isPaginate={false}
        fromIndividual={false}
      />
      {data.completed.length > 0 && (
        <StaticPagination
          dropdownDisplay={dropdownDisplay}
          paginationDropdownItems={paginationDropdownItems}
          rowStartIdx={rowStartIdx}
          rowsPerPage={rowsPerPage}
          dataLength={data.completed.length}
          setRowsPerPage={setRowsPerPage}
          setRowStartIdx={setRowStartIdx}
          setDropdownDisplay={setDropdownDisplay}
        />
      )}
    </div>
  );
};
export default Completed;
