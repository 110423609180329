import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// COMPONENT IMPORTS
import axios from '../config/api';

// Signature LIST API CALL
export const getNotificationList = createAsyncThunk('getNotificationList', async (id) => {
  const res = await axios.get(`notification/${id}`);
  if (res.code === 200) {
    return res;
  } else {
    return res;
  }
});
// Read Notification
export const readNotification = createAsyncThunk('readNotification', async (data) => {
  const res = await axios.post('notification/read', data);
  if (res.code === 200) {
    return res;
  } else {
    return res;
  }
});

// INITIAL STATE
const initialState = {
  message: '',
  status: null,
  notificationRecord: {}
};

/* ============================== NOTIFICATION SLICE ============================== */
export const notificationSlice = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    resetNotificationSlice: () => initialState
  },
  extraReducers: (builder) => {
    builder.addCase(getNotificationList.fulfilled, (state, { payload }) => {
      const { data } = payload;
      state.notificationRecord = data?.records;
    });
  }
});

export const { resetNotificationSlice } = notificationSlice.actions;
export default notificationSlice.reducer;
