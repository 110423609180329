import React, { useRef, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import PropTypes from 'prop-types';

// COMPONENT IMPORTS
import CapsyncIcon from '../CapsyncIcon';
import UploadFileList from './UploadFileList';
import { validateDuplicateFileName } from '../../config/validateDuplicateFileName';

/* ============================== FILE UPLOAD INPUT ============================== */
const FileUploadWithBtn = ({
  errors,
  values,
  touched,
  setFieldError,
  setFieldValue,
  disabled,
  label,
  name,
  multiple = true,
  showFiles = false,
  placeholder = 'Choose File'
}) => {
  const [files, setFiles] = useState([]);
  const setFileValue = (data) => setFieldValue(name, data);
  const setErrorMsg = (err) => setFieldError(name, err);
  const inputRef = useRef('');

  const handleOnChange = (fileValue) => {
    if (validateDuplicateFileName(fileValue, values[name], setErrorMsg)) {
      setFiles(fileValue);
    }
  };

  const handleUpload = () => {
    if (files.length > 0) {
      const totalFiles = [...files, ...values[name]];
      setFieldValue(name, totalFiles);
      setFiles([]);
      inputRef.current.value = '';
    }
  };

  return (
    <div className="cs-upload-box">
      <div className="cs-upload-files-box">
        <Form.Group className="cs-form-group">
          <div className="cs-choose-file">
            <div
              className="cs-choose-file-box"
              title={
                files.length > 0
                  ? files.length > 1
                    ? `${files.length} files`
                    : files[0].name
                  : label
              }>
              <Form.Control
                type="file"
                multiple={multiple}
                onChange={(e) => handleOnChange(e.target.files)}
                placeholder={placeholder}
                disabled={disabled}
                id={name}
                ref={inputRef}
                // value={files}
                className={files.length > 0 && 'cs-input-field-active'}
              />
              <span className="cs-icon cs-neutral-10">
                <CapsyncIcon title="upload-outlined" size="18" />
              </span>
            </div>
          </div>
        </Form.Group>
        <Button
          className="cs-btn-primary sm-btn cs-regular-h5"
          onClick={!disabled && handleUpload}
          disabled={files.length === 0 || errors[name]}>
          Upload
        </Button>
      </div>
      {touched && errors && errors[name] && (
        <span className="cs-light-body-text-s cs-danger">{errors[name]}</span>
      )}
      {showFiles && (
        <UploadFileList files={values[name]} setFiles={setFileValue} disabled={disabled} />
      )}
    </div>
  );
};

// PROPS TYPE
FileUploadWithBtn.propTypes = {
  errors: PropTypes.object,
  values: PropTypes.object,
  touched: PropTypes.object,
  setFieldError: PropTypes.func,
  setFieldValue: PropTypes.func,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  name: PropTypes.string,
  multiple: PropTypes.bool,
  showFiles: PropTypes.bool,
  placeholder: PropTypes.string
};

export default FileUploadWithBtn;
