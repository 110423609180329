import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { useFormik } from 'formik';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import DatePicker from 'react-date-picker';
import { Col, Form, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

// COMPONENT IMPORTS
import {
  loanTermType,
  preventNegativeNumbers,
  preventSpace,
  preventSpecialCharacters,
  setAddressString
} from '../../../utils/common';
import Modal from '../../../components/Modal';
import CapsyncIcon from '../../../components/CapsyncIcon';
import { TOAST_MESSAGE } from '../../../constants/message';
import AddressSearch from '../../../components/AddressSearch';
import InputBox from '../../../components/FormFields/InputBox';
import validationObj from '../../../validations/validationSchema';
import TextAreaBox from '../../../components/FormFields/TextAreaBox';
import { handleOwnershipValue } from '../../../config/handleOwnership';
import MaturityDate from '../../../components/FormFields/MaturityDate';
import DatePickerBox from '../../../components/FormFields/DatePickerBox';
import PhoneInputField from '../../../components/FormFields/PhoneInputField';
import DropdownInputBox from '../../../components/FormFields/DropdownInputBox';
import InputNumberField from '../../../components/FormFields/InputNumberField';
import SaveORCancelButtons from '../../../components/Buttons/SaveORCancelButtons';
import CategoryFileUpload from '../../../components/FormFields/CategoryFileUpload';
import AmortizationCheckbox from '../../../components/FormFields/AmortizationCheckbox';

// HOOKS IMPORTS
import usePayment from '../../../utils/Hooks/usePayment';
import useBalanceDue from '../../../utils/Hooks/useBalanceDue';
import useMaturityDate from '../../../utils/Hooks/useMaturityDate';

// API
import {
  categoryAddRecords,
  categoryUpdateRecords,
  getLiabilitiesCategory,
  useCategoryDetail
} from '../../../slices/categorySlice';
import { useProgressUploadDetails } from '../../../slices/commonSlice';
import { useStorageDetails } from '../../../slices/fileManagementSlice';
import { getLiabilitiesDashboard, getMainDashboard } from '../../../slices/dashboardSlice';

/* ============================== MORTGAGES ON REAL ESTATE ============================== */
const MortgagesOnRealEstate = ({
  isOpen = false,
  handleCancel,
  handleCategoryFormSubmit,
  record,
  linkModalAddress
}) => {
  const dispatch = useDispatch();

  const { user } = useSelector((state) => state.auth);
  const progressUploadDetails = useProgressUploadDetails();
  const storageDetails = useStorageDetails();
  const categories = useCategoryDetail();
  const categoryId = categories?.category_id;

  const [loading, setLoading] = useState(false);
  const [addressObj, setAddressObj] = useState({
    line1: '',
    line2: '',
    city: '',
    state: '',
    country: '',
    postal_code: ''
  });
  const [initialValues, setInitialValues] = useState({
    user_id: user?.id,
    property_address: '',
    creditor_name: '',
    creditor_email: '',
    creditor_phonenumber: '',
    loan_amount: '',
    ownership: '',
    date_loan: moment().format(),
    loan_period: '',
    frequency: loanTermType[0],
    annual_rate: '',
    maturity_date: '',
    payment: '',
    balance_due: '',
    mortgage_balance: '',
    date_updated: moment().format(),
    loan_type: '',
    collateral: '',
    notes: '',
    is_manual_address: false,
    city: '',
    street_name: '',
    zip_code: '',
    is_amortization_link_enable: false,
    attachments: [],
    is_manual: true
  });

  useEffect(() => {
    if (record) {
      setInitialValues({
        user_id: record.user_id || '',
        creditor_name: record.creditor_name || '',
        creditor_email: record.creditor_email || '',
        creditor_phonenumber: record.creditor_phonenumber || '',
        property_address: record.property_address
          ? setAddressString(JSON.parse(record.property_address))
          : '',
        loan_amount: (record.loan_amount && record.loan_amount.toLocaleString('en-US')) || '',
        date_loan: record.date_loan
          ? new Date(moment(record.date_loan).format('MM/DD/YYYY'))
          : record && record.api_provider !== ''
            ? ''
            : moment().format(),
        annual_rate: (record.annual_rate && record.annual_rate.toLocaleString('en-US')) || '',

        ownership: record.ownership && record.ownership.toLocaleString('en-US'),
        loan_period: (record.loan_period && record.loan_period.toLocaleString('en-US')) || '',
        frequency: record.frequency || '',
        collateral: record.collateral || '',
        balance_due: (record.balance_due && record.balance_due.toLocaleString('en-US')) || '',
        mortgage_balance:
          (record.mortgage_balance && record.mortgage_balance.toLocaleString('en-US')) || '',
        date_updated: record.date_updated
          ? new Date(moment(record.date_updated).format('MM/DD/YYYY'))
          : moment().format(),
        loan_type: record.loan_type || '',
        notes: record.notes || '',
        is_manual: record.is_manual || '',
        city: record.property_address && JSON.parse(record.property_address).city,
        state: record.property_address && JSON.parse(record.property_address).state,
        zip_code: record.property_address && JSON.parse(record.property_address).postal_code,
        street_name: record.property_address && JSON.parse(record.property_address).line1,
        is_amortization_link_enable: record.is_amortization_link_enable
      });
      record.property_address && setAddressObj(JSON.parse(record.property_address));
      // setAmortization(record.is_amortization_link_enable || false);
      // record.property_address && setAddress(setAddressString(record.property_address));
      // record.creditor_phonenumber && setPhoneNo(record.creditor_phonenumber);
      // Manual Address
      // setIsManualAddress(record.is_manual_address);
    }
  }, [record]);

  useEffect(() => {
    if (linkModalAddress) {
      setAddressObj(JSON.parse(linkModalAddress));
      setFieldValue('property_address', setAddressString(JSON.parse(linkModalAddress)));
      setFieldValue('city', JSON.parse(linkModalAddress).city);
      setFieldValue('state', JSON.parse(linkModalAddress).state);
      setFieldValue('street_name', JSON.parse(linkModalAddress).line1);
      setFieldValue('zip_code', JSON.parse(linkModalAddress).postal_code);
    }
  }, [linkModalAddress]);
  const {
    values,
    handleSubmit,
    handleChange,
    setFieldValue,
    errors,
    touched,
    setFieldError,
    setStatus,
    status,
    setFieldTouched,
    resetForm,
    submitCount
  } = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: validationObj.liabilityRealEstateMortgageValidationSchema,
    onSubmit: async (values) => {
      values.property_address = addressObj;
      const formData = new FormData();
      delete values.is_manual;
      delete values.is_manual_address;
      delete values.city;
      delete values.street_name;
      delete values.zip_code;
      delete values.state;
      delete values.country;
      const { attachments, ...dataObj } = values;
      const uploadedData = storageDetails?.used_space;
      const inProgressData = progressUploadDetails.length
        ? progressUploadDetails.reduce((acc, item) => acc + item.size, 0)
        : 0;

      !record && attachments.forEach((file) => formData.append('attachments', file));
      formData.append('data_obj', JSON.stringify({ ...dataObj, property_address: addressObj }));
      formData.append('usedSpace', uploadedData + inProgressData);

      const data = {
        category: 5,
        mainCategory: 'liability',
        formData: formData
      };

      setLoading(true);
      if (!record) {
        dispatch(categoryAddRecords(data))
          .unwrap()
          .then(async (response) => {
            if (response.code === 500) {
              setFieldError('attachments', response?.message);
            }
            if (response.code === 200) {
              handleCategoryFormSubmit();
              dispatch(getMainDashboard(user?.id));
              const payload = { categoryId: categoryId, userId: user?.id };
              categories.parent === 'liabilities' &&
                (await dispatch(getLiabilitiesCategory(payload)).unwrap());
              await dispatch(getLiabilitiesDashboard({ userId: user?.id, orderId: 0 })).unwrap();
            }
          })
          .catch((err) => {})
          .finally(() => {
            setLoading(false);
          });
      } else {
        dispatch(
          categoryUpdateRecords({
            category: 5,
            mainCategory: 'liability',
            rowId: record && record?.id,
            values
          })
        )
          .unwrap()
          .then(async (response) => {
            if (response.code === 200) {
              handleCategoryFormSubmit();
              toast.success(TOAST_MESSAGE.RECORD_UPDATE_SUCCESS);
              const payload = { categoryId: categoryId, userId: user?.id };
              categories.parent === 'liabilities' &&
                (await dispatch(getLiabilitiesCategory(payload)).unwrap());
              await dispatch(getMainDashboard(user?.id)).unwrap();
            }
          })
          .catch((err) => {})
          .finally(() => {
            setLoading(false);
          });
      }
    }
  });
  useBalanceDue({ values, setFieldValue });

  useMaturityDate({ values, setFieldValue });

  usePayment({ values, setFieldValue, monthlyPayment: true });

  const body = (
    <Form onSubmit={handleSubmit} encType="multipart/form-data">
      <Row>
        <Col lg={12} sm={12}>
          <InputBox
            label={'Name of Creditor'}
            name={'creditor_name'}
            values={values}
            onChange={handleChange}
            errors={errors}
            touched={touched}
            type={'text'}
            onKeyDown={preventSpecialCharacters}
            disabled={record && record.is_manual === false}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={6}>
          <InputBox
            label={'Email'}
            name={'creditor_email'}
            values={values}
            onChange={handleChange}
            errors={errors}
            touched={touched}
            optional={true}
            type={'email'}
            onKeyDown={preventSpace}
            placeholder="Optional "
          />
        </Col>
        <Col lg={6} md={6}>
          <PhoneInputField
            label={'Phone Number (Optional)'}
            placeholder={'Optional'}
            name={'creditor_phonenumber'}
            values={values}
            setFieldValue={setFieldValue}
            errors={errors}
            touched={touched}
            submitCount={submitCount}
          />
        </Col>
      </Row>
      <Row>
        <Col lg={12} sm={12}>
          <AddressSearch
            errors={errors}
            touched={touched}
            values={values}
            handleChange={handleChange}
            setFieldValue={setFieldValue}
            setAddressObj={setAddressObj}
            setStatus={setStatus}
            status={status}
            addressObj={addressObj}
            isCountryField={false}
            setFieldTouched={setFieldTouched}
            clearAddressOnUnmount={true}
            label={'Property Address'}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={6}>
          <InputNumberField
            label="Loan Amount"
            name="loan_amount"
            values={values}
            handleChange={handleChange}
            errors={errors}
            touched={touched}
            customActiveClass={'dollar-symbol text-md-end form-control'}
            thousandSeparator={true}
          />
        </Col>
        <Col xs={12} md={6}>
          <Form.Group className={'cs-form-group'}>
            <Form.Label>Loan Date</Form.Label>
            <DatePicker
              dayPlaceholder="DD"
              monthPlaceholder="MM"
              yearPlaceholder="YYYY"
              name="date_loan"
              value={values.date_loan}
              onChange={(e) => {
                setFieldValue('date_loan', e);
              }}
              clearIcon={null}
              className={
                values.date_loan
                  ? 'cs-input-field-active select-date-range cs-date-picker-calender'
                  : 'cs-date-picker-calender'
              }
              calendarIcon={
                <span className="cs-neutral-90">
                  <CapsyncIcon title="calender-outlined" size="18" />
                </span>
              }
              format="MM/dd/y"
              maxDate={new Date()}
            />
            {errors.date_loan && touched.date_loan ? (
              <span className="form-error-msg cs-light-body-text-s cs-danger">
                {errors.date_loan}
              </span>
            ) : null}
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={6}>
          <DropdownInputBox
            errors={errors}
            label={'Loan Term'}
            handleChange={handleChange}
            maxLength={3}
            name={'loan_period'}
            dropdownName={'frequency'}
            touched={touched}
            values={values}
            setFieldValue={setFieldValue}
          />
        </Col>
        <Col xs={12} md={6}>
          <InputNumberField
            label="Interest Rate (%)"
            name="annual_rate"
            values={values}
            handleChange={handleChange}
            errors={errors}
            touched={touched}
            customActiveClass={'percentage-symbol'}
            maxLength={5}
            onPaste={preventNegativeNumbers}
            onKeyDown={handleOwnershipValue}
          />
        </Col>
      </Row>

      <Row>
        <Col xs={12} md={6}>
          <MaturityDate values={values} setFieldValue={setFieldValue} errors={errors} />
        </Col>
        <Col xs={12} md={6}>
          <InputNumberField
            label="Ownership (%)"
            name="ownership"
            values={values}
            handleChange={handleChange}
            errors={errors}
            touched={touched}
            customActiveClass={'percentage-symbol'}
            maxLength={5}
            onPaste={preventNegativeNumbers}
            onKeyDown={handleOwnershipValue}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={6}>
          <InputNumberField
            label="Monthly Payment"
            name="payment"
            values={values}
            errors={errors}
            handleChange={handleChange}
            touched={touched}
            customActiveClass="dollar-symbol text-md-end form-control"
            onPaste={preventNegativeNumbers}
            thousandSeparator={true}
            disabled={true}
          />
        </Col>
        <Col xs={12} md={6}>
          <InputNumberField
            label="Mortgage Balance"
            name="mortgage_balance"
            values={values}
            errors={errors}
            handleChange={handleChange}
            touched={touched}
            customActiveClass="dollar-symbol text-md-end form-control"
            onPaste={preventNegativeNumbers}
            thousandSeparator={true}
            disabled={record && record.is_manual === false}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={6}>
          <DatePickerBox
            label={'Date Updated'}
            errors={errors}
            name={'date_updated'}
            values={values}
            setFieldValue={setFieldValue}
            disabled={record && record.is_manual === false}
          />
        </Col>
        <Col xs={12} md={6}>
          <InputBox
            label={'Type'}
            name={'loan_type'}
            onChange={handleChange}
            values={values}
            errors={errors}
            touched={touched}
            type={'text'}
            placeholder="Optional"
            onKeyDown={preventSpecialCharacters}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={6}>
          <InputBox
            label={'Collateral'}
            name={'collateral'}
            values={values}
            onChange={handleChange}
            errors={errors}
            touched={touched}
            type={'text'}
            placeholder="Optional"
            onKeyDown={preventSpecialCharacters}
          />
        </Col>
        <Col xs={12} md={6}>
          <InputNumberField
            label="Balance Due"
            name="balance_due"
            values={values}
            errors={errors}
            handleChange={handleChange}
            touched={touched}
            onPaste={preventNegativeNumbers}
            thousandSeparator={true}
            disabled={true}
            customActiveClass="text-md-end"
          />
        </Col>
      </Row>
      {(!record || record.is_manual === true) && (
        <Row>
          <Col xs={12} md={12}>
            <AmortizationCheckbox
              isChecked={values['is_amortization_link_enable']}
              setIsChecked={(value) => setFieldValue('is_amortization_link_enable', value)}
            />
          </Col>
        </Row>
      )}

      <Row>
        <Col xs={12} md={12}>
          <TextAreaBox
            handleChange={handleChange}
            name={'notes'}
            label={'Notes'}
            values={values}
            errors={errors}
            onChange={handleChange}
            placeholder={'Optional'}
          />
        </Col>
      </Row>
      {!record && (
        <CategoryFileUpload
          name={'attachments'}
          values={values}
          errors={errors}
          setFieldError={setFieldError}
          setFieldValue={setFieldValue}
        />
      )}

      <SaveORCancelButtons handleCancel={handleCancel} resetForm={resetForm} loading={loading} />
    </Form>
  );

  return (
    <Modal show={isOpen} title={'Mortgages on Real Estate'} body={body} isCloseButton={false} />
  );
};

// PROPS TYPE
MortgagesOnRealEstate.propTypes = {
  isOpen: PropTypes.bool,
  handleCancel: PropTypes.func.isRequired,
  handleCategoryFormSubmit: PropTypes.func,
  record: PropTypes.object,
  linkModalAddress: PropTypes.object
};

export default MortgagesOnRealEstate;
