import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

// COMPONENT IMPORTS
import axios from '../config/api';

// INITIAL STATE
const initialState = {
  loader: {},
  instituteData: {},
  token: '',
  accountsData: [],
  transactionLoaderData: [],
  lastSyncData: {}
};

export const getHoldingSecurity = createAsyncThunk('plaid/getHoldingSecurity', async (data) => {
  return await axios.post(`/plaid/get-holding-securities`, data);
});

export const investmentTransactions = createAsyncThunk(
  'plaid/investmentTransactions',
  async (data) => {
    return await axios.post(`/plaid/get-investment-transactions`, data);
  }
);

export const getTransactions = createAsyncThunk('plaid/getTransactions', async (data) => {
  return await axios.post(`/plaid/get-transactions`, data);
});

export const handleSyncAccount = createAsyncThunk('plaid/handleSyncAccount', async (data) => {
  return await axios.post(`plaid/sync-account`, data);
});

export const handleLastSyncData = createAsyncThunk('plaid/handleLastSyncData', async (data) => {
  return await axios.get(`plaid/get-last-sync-date/${data}`);
});

export const handleLoaderData = createAsyncThunk('plaid/handleLoaderData', async (data) => {
  return await axios.post(`plaid/get-loader-details`, data);
});

/* ============================== PLAID SLICE ============================== */
export const plaidSlice = createSlice({
  name: 'plaid',
  initialState,
  reducers: {
    resetPlaidSlice: () => initialState,
    handleLoader(state, action) {
      state.loader = { ...state.loader, ...action.payload };
    },
    resetLoader(state) {
      state.loader = {};
    },
    handleInstituteData(state, action) {
      state.instituteData = { ...state.instituteData, ...action.payload };
    },
    resetInstituteData(state) {
      state.instituteData = {};
    },
    handlePlaidToken(state, action) {
      state.token = action.payload;
    },
    handleAccountsData(state, action) {
      state.accountsData = action.payload;
    },
    handleTransactionLoaderData(state, action) {
      state.transactionLoaderData = action.payload;
    },
    handleLastSync(state, action) {
      state.lastSyncData = action.payload;
    }
  }
});

export const {
  handleLoader,
  resetLoader,
  handleInstituteData,
  resetInstituteData,
  handlePlaidToken,
  handleAccountsData,
  resetPlaidSlice,
  handleTransactionLoaderData,
  handleLastSync
} = plaidSlice.actions;

export default plaidSlice.reducer;

export const selectLoader = (state) => state.plaid.loader;
export const useLoader = () => {
  const loader = useSelector(selectLoader);
  return useMemo(() => loader, [loader]);
};

export const selectInstituteData = (state) => state.plaid.instituteData;
export const useInstituteData = () => {
  const insData = useSelector(selectInstituteData);
  return useMemo(() => insData, [insData]);
};

export const selectToken = (state) => state.plaid.token;
export const useToken = () => {
  const tokenData = useSelector(selectToken);
  return useMemo(() => tokenData, [tokenData]);
};

export const selectAccountsData = (state) => state.plaid.accountsData;
export const useAccountsData = () => {
  const accData = useSelector(selectAccountsData);
  return useMemo(() => accData, [accData]);
};

export const selectTransactionLoaderData = (state) => state.plaid.transactionLoaderData;
export const useTransactionLoaderData = () => {
  const transLoaderData = useSelector(selectTransactionLoaderData);
  return useMemo(() => transLoaderData, [transLoaderData]);
};

export const selectLastSyncData = (state) => state.plaid.lastSyncData;
export const useLastSyncData = () => {
  const lastSyncData = useSelector(selectLastSyncData);
  return useMemo(() => lastSyncData, [lastSyncData]);
};
