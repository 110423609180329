import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';

// COMPONENT IMPORTS
import CapsyncIcon from '../../components/CapsyncIcon';

// API
import { setCategoryDetails } from '../../slices/categorySlice';

/* ============================== DASHBOARD TABLE ============================== */
const DashboardTable = ({ name, data, total, isSharePFS = false }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleClick = (items) => {
    if (isSharePFS === false) {
      dispatch(setCategoryDetails(items));
      navigate(`../${items?.parent}/${items?.path}`);
    }
  };

  return (
    <div className="finance-data-container">
      <div className="finance-data">
        <Link to={`${name == 'Assets' ? '../assets' : '../liabilities'}`}>
          <div
            className={`finance-heading ${name == 'Assets' ? 'heading-asset' : 'heading-liability'} `}>
            <span className="cs-semi-bold-h3 cs-neutral-100 ">{name}</span>
            <span className={`cs-icon  ${name == 'Assets' ? 'cs-asset' : 'cs-liability'} `}>
              <CapsyncIcon title="redirect-outlined" size="20px" />
            </span>
          </div>
        </Link>
        <Table>
          <thead className="finance-thead">
            <tr>
              <th width="486" className="cs-medium-sub-heading-l">
                Category
              </th>
              <th className="cs-medium-sub-heading-l">Balance</th>
            </tr>
          </thead>
          <tbody className="finance-tbody cs-light-body-text-l cs-neutral-90">
            {data?.map((items, index) => {
              return (
                <tr className="cursor-pointer" onClick={() => handleClick(items)} key={index}>
                  <td width="486">{items?.category_name}</td>
                  <td width="180">
                    <div className="dollar-value-gap">
                      <span>$&nbsp;</span>
                      <span className="dash-bal-padding">
                        {items?.value ? items?.value?.toLocaleString() : '0.00'}
                      </span>
                    </div>
                  </td>
                  <td width="50" className="go-next-page-div">
                    <CapsyncIcon title="chevron-right-outlined" size="16" />
                  </td>
                </tr>
              );
            })}

            {/* tfoot start */}
            <tr className="cs-medium-sub-heading-m dashboard-tfoot">
              <td className="style-tfoot"> {`Total ${name}`} </td>
              <td>
                <div className="dollar-value-gap">
                  <span>$&nbsp;</span>
                  <span className="dash-bal-padding">{total?.toLocaleString()}</span>
                </div>
              </td>
              <td className="go-next-page-div style-tfoot"></td>
            </tr>
            {/* tfoot end */}
          </tbody>
        </Table>
      </div>
    </div>
  );
};

// PROPS TYPE
DashboardTable.propTypes = {
  name: PropTypes.any,
  data: PropTypes.any,
  total: PropTypes.any,
  isSharePFS: PropTypes.bool
};

export default DashboardTable;
