import React from 'react';

/* ============================== NO ACCESS PAGE ============================== */
const NoAccess = () => {
  return (
    <h1 className="container h-100">
      <div className="d-flex align-items-center justify-content-center text-bg-danger ">
        Admins do not have access to use client accounts.
      </div>
    </h1>
  );
};

export default NoAccess;
