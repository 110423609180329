import React from 'react';
import PropTypes from 'prop-types';

/* ============================== AUTHENTICATION HEADER PAGE ============================== */
const AuthHead = (props) => {
  const { title, description } = props;

  return (
    <div className="cs-auth-header">
      {title && (
        <div className="cs-title">
          <h1 className="cs-semi-bold-h1">{title}</h1>
        </div>
      )}
      {description && (
        <p className="cs-regular-body-text-m cs-neutral-90 text-center">{description}</p>
      )}
    </div>
  );
};

// PROPS TYPE
AuthHead.propTypes = {
  title: PropTypes.node.isRequired,
  description: PropTypes.string
};

export default AuthHead;
