import * as React from 'react';
import ProtectedQuickBooksRoutes from './ProtectedQuickBooksRoutes';
import QuickBooksFetchData from '../pages/components/quickBooks/QuickBooksFetchData';

const QuickBooksRoutes = {
  path: '',
  element: <ProtectedQuickBooksRoutes />,
  children: [
    { path: 'quickbooks/:accesstoken/:realmid/:refresh_token', element: <QuickBooksFetchData /> }
  ]
};

export default QuickBooksRoutes;
