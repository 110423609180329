import React from 'react';
import PropTypes from 'prop-types';

// COMPONENT IMPORTS
import CapsyncBreadcrumb from './CapsyncBreadcrumb';

// API
import { useLastSyncData } from '../slices/plaidSlice';

/* ============================== HEADER SECTION ============================== */
const CapsyncSectionHeader = ({ pageTitle, mainTitle, subTitle, mainUrl, hasLastSync = true }) => {
  const lastSyncData = useLastSyncData();
  return (
    <React.Fragment>
      {mainTitle && (
        <div className="cs-breadcrumb">
          <CapsyncBreadcrumb mainTitle={mainTitle} subTitle={subTitle} mainUrl={mainUrl} />
        </div>
      )}
      <div
        className={`cs-title ${pageTitle === 'Non-Retirement Brokerage Accounts (Stocks & Bonds)' ? 'cs-break-title' : ''}`}>
        {pageTitle && <h1 className="cs-semi-bold-h1 cs-neutral-100">{pageTitle}</h1>}
        {hasLastSync && lastSyncData && lastSyncData?.lastSyncData ? (
          <span className="cs-regular-body-text-m cs-neutral-80">
            {`Last sync: ${lastSyncData.lastSyncData}`}
          </span>
        ) : (
          ''
        )}
      </div>
    </React.Fragment>
  );
};

// PROPS TYPE
CapsyncSectionHeader.propTypes = {
  pageTitle: PropTypes.string,
  mainTitle: PropTypes.string,
  subTitle: PropTypes.string,
  mainUrl: PropTypes.string,
  hasLastSync: PropTypes.bool
};

export default CapsyncSectionHeader;
