import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Col, Form, Row } from 'react-bootstrap';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import DatePicker from 'react-date-picker';
import { useFormik } from 'formik';
import * as yup from 'yup';
import axios from 'axios';

// COMPONENT IMPORTS
import Modal from '../../../components/Modal';
import CapsyncIcon from '../../../components/CapsyncIcon';
import InputBox from '../../../components/FormFields/InputBox';

// API
import { useUser } from '../../../slices/authSlice';
import { setArrayId, updateArrayData, updateArrayDetails } from '../../../slices/arraySlice';

/* ============================== CREATE ARRAY USER ============================== */
const CreateArrayUser = () => {
  const user = useUser();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  const validationSchema = yup.object().shape({
    birth_date: yup.string().required('Please select date of birth'),
    social_security_number: yup
      .string()
      .min(9, 'ssn number should be 9 characters')
      .max(9, 'ssn number should be 9 characters')
      .required('Please enter ssn number')
  });

  const setEnrollment = async (array_id) => {
    try {
      const payload = {
        clientKey: array_id,
        enrollmentCode: [
          'idpFinancialThresholdMonitoring',
          'idpBundle1Insurance1mmRestoreBundleMonitoring'
        ]
      };
      const headers = {
        accept: 'application/json',
        'x-array-server-token': process.env.REACT_APP_ARRAY_TOKEN,
        'content-type': 'application/json'
      };
      const response = await axios.post(process.env.REACT_APP_ARRAY_MONITORING_URL, payload, {
        headers
      });
      console.log('response', response);
    } catch (error) {
      console.log('error', error.message);
    }
  };

  const onSubmit = async (values) => {
    try {
      setLoading(true);
      const payload = {
        ...values,
        birth_date: moment(new Date(values.birth_date)).format('YYYY-MM-DD'),
        user_id: user.id
      };
      const response = await dispatch(updateArrayDetails(payload)).unwrap();
      if (response.code === 200) {
        setEnrollment(response.data.array_id);
        dispatch(updateArrayData({ array_id: response.data.array_id, array_token: null }));
        dispatch(setArrayId(response.data.array_id));
      }
      setLoading(false);
    } catch (error) {
      console.log('error', error.message);
      setLoading(false);
    }
  };

  const { values, errors, touched, setValues, handleSubmit, setFieldValue } = useFormik({
    initialValues: { birth_date: '', social_security_number: '' },
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit
  });

  const body = (
    <div>
      <Form onSubmit={handleSubmit} id="array-details">
        <Row>
          <Col lg={6}>
            <InputBox
              className="last-form-field"
              name="social_security_number"
              label="SSN Number"
              placeholder="SSN Number"
              values={values}
              errors={errors}
              touched={touched}
              onChange={(e) => {
                const filteredVal = e.target.value.replace(/[^\d]/g, '');
                if (filteredVal.length <= 9) {
                  setValues({
                    ...values,
                    social_security_number: filteredVal
                  });
                }
              }}
            />
          </Col>
          <Col lg={6}>
            <Form.Group className="cs-form-group last-form-field">
              <Form.Label>Date of birth</Form.Label>
              <DatePicker
                dayPlaceholder="DD"
                monthPlaceholder="MM"
                yearPlaceholder="YYYY"
                name="birth_date"
                value={values.birth_date}
                onChange={(e) => {
                  setFieldValue('birth_date', e);
                }}
                // onBlur={handleBlur}
                clearIcon={null}
                className={
                  values.birth_date
                    ? 'cs-input-field-active select-date-range cs-date-picker-calender'
                    : 'cs-date-picker-calender'
                }
                calendarIcon={
                  <span className="cs-neutral-90">
                    <CapsyncIcon title="calender-outlined" size="18" />
                  </span>
                }
                format="MM/dd/y"
                maxDate={new Date()}
              />
              {errors.birth_date && touched.birth_date ? (
                <span className="form-error-msg default-light-body-text-s cs-danger">
                  {errors.birth_date}
                </span>
              ) : null}
            </Form.Group>
          </Col>
        </Row>
      </Form>
    </div>
  );

  return (
    <Modal
      title="Add User Details"
      form="array-details"
      size="lg"
      className="cs-lg-modal user-details-modal"
      cancelButtonLabel="Cancel"
      saveButtonLabel="Add"
      show={true}
      isCloseButton={false}
      body={body}
      handleOnCancel={() => navigate('/dashboard')}
      modalFooterClass="cs-modal-btn modal-footer"
      disabled={loading || !values.social_security_number || !values.birth_date}
    />
  );
};

export default CreateArrayUser;
