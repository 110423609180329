import React from 'react';
import { ReactSVG } from 'react-svg';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';

// COMPONENT IMPORTS
import Image from './Image';
import notFoundImage from '../assets/images/404-image.svg';

// API
import { useBrandDetails } from '../slices/brandingSlice';

/* ============================== 404 PAGE ============================== */
const NotFound = () => {
  const brandDetails = useBrandDetails();
  return (
    <Container>
      <div className="page404">
        <Row>
          <Col lg={12}>
            <div className="error-container">
              <div className="cs-error-image">
                <Link to="/">
                  <Image
                    width="132"
                    src={brandDetails && brandDetails.primary_logo_s3_url}
                    alt="brand-logo"
                    className="capsync-logo"
                  />
                </Link>
              </div>
              <div className="error-image">
                <ReactSVG src={notFoundImage} />
              </div>
              <div className="error-content">
                <h1 className="cs-semi-bold-h1 cs-neutral-100">Page Not Found</h1>
                <p className="cs-medium-sub-heading-l  cs-neutral-80">
                  We're sorry, the page you requested could not be found please go back to the
                  homepage
                </p>
                <span className="back-to-sign-in">
                  <Link to="/" className="cs-btn-tertiary lg-btn cs-regular-h5">
                    Back to Sign In
                  </Link>
                </span>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </Container>
  );
};

export default NotFound;
