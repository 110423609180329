export const ADD_ACCOUNT_MODALS = {
  NONE: '',
  MAIN_MODAL: 'main-modal',
  UPDATE_MODAL: 'update-modal',
  ACCOUNT_LIST_MODAL: 'account-list-modal',
  ADD_MANUAL_ACCOUNT_MODAL: 'add-manual-account-modal',
  ACCOUNT_PAYABLE: 'accountPayable',
  NOTES_PAYABLE: 'notesPayableToBankAndOther',
  INSTALLMENT_ACCOUNT_AUTO: 'installmentAccountAuto',
  INSTALLMENT_ACCOUNT_OTHER: 'installmentAccountOther',
  LOAN_AGAINST_LIFE_INSURANCE: 'loanAgainstLifeInsurance',
  MORTGAGES_ON_REAL_ESTATE: 'mortgagesOnRealEstate',
  UNPAID_TAXES: 'unpaidTaxes',
  OTHER_LIABILITIES: 'otherLiabilities',
  CASH_ON_HANDS: 'cashOnHandAndInBank',
  SAVINGS: 'savingsAccounts',
  OTHER_RETIREMENT: 'iraOrOtherRetirementAccount',
  ACCOUNT_NOTES_RECEIVABLES: 'accountAndNotesRecivable',
  LIFE_INSURANCE: 'lifeInsurance',
  NON_RETIREMENT_BROKERAGE: 'nonRetirementBrokerageAccount',
  REAL_ESTATE: 'realEstate',
  AUTOMOBILES: 'automobile',
  BUSINESSES: 'businesses',
  OTHER_PERSONAL_PROPERTY: 'otherPersonalProperties',
  OTHER_ASSETS: 'otherAssets',
  ACCOUNT_LINKED: 'accountLinked'
};

export const plaidUpdateExceptionInstitutesList = ["ins_56", "ins_11"];

export const CATEGORY = {
  ASSETS: 'Assets',
  LIABILITY: 'Liabilities',
  NONE: ''
};

export const LIABILITY_CATEGORY_LISTING = [
  {
    TITLE: 'Accounts Payable',
    KEY_NAME: 'accountPayable'
  },
  {
    TITLE: 'Notes Payable to Banks and Others',
    KEY_NAME: 'notesPayableToBankAndOther'
  },
  {
    TITLE: 'Installment Account (Auto)',
    KEY_NAME: 'installmentAccountAuto'
  },
  {
    TITLE: 'Installment Account (Other)',
    KEY_NAME: 'installmentAccountOther'
  },
  {
    TITLE: 'Loan(s) Against Life Insurance',
    KEY_NAME: 'loanAgainstLifeInsurance'
  },
  {
    TITLE: 'Mortgages on Real Estate',
    KEY_NAME: 'mortgagesOnRealEstate'
  },
  {
    TITLE: 'Unpaid Taxes',
    KEY_NAME: 'unpaidTaxes'
  },
  {
    TITLE: 'Other Liabilities',
    KEY_NAME: 'otherLiabilities'
  }
];

export const ASSET_CATEGORY_LISTING = [
  {
    TITLE: 'Cash on Hand & In Banks',
    KEY_NAME: 'cashOnHandAndInBank'
  },
  {
    TITLE: 'Savings Accounts',
    KEY_NAME: 'savingsAccounts'
  },
  {
    TITLE: 'IRA or Other Retirement Account',
    KEY_NAME: 'iraOrOtherRetirementAccount'
  },
  {
    TITLE: 'Accounts & Notes Receivable',
    KEY_NAME: 'accountAndNotesRecivable'
  },
  {
    TITLE: 'Life Insurance',
    KEY_NAME: 'lifeInsurance'
  },
  {
    TITLE: 'Non-Retirement Brokerage Accounts (Stocks & Bonds)',
    KEY_NAME: 'nonRetirementBrokerageAccount'
  },
  {
    TITLE: 'Real Estate',
    KEY_NAME: 'realEstate'
  },
  {
    TITLE: 'Automobiles',
    KEY_NAME: 'automobile'
  },
  {
    TITLE: 'Businesses',
    KEY_NAME: 'businesses'
  },
  {
    TITLE: 'Other Personal Property',
    KEY_NAME: 'otherPersonalProperties'
  },
  {
    TITLE: 'Other Assets',
    KEY_NAME: 'otherAssets'
  }
];
