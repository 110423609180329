import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

// COMPONENT IMPORTS
import { handleDeleteMedia } from '../../pages/accreditation/SubMethods/SubMethodsBasicFunctions';
import CapsyncIcon from '../CapsyncIcon';

/* ============================== FILE UPLOAD ============================== */
const MethodFileUpload = (props) => {
  const {
    methodField,
    fieldPrimaryLabel,
    fieldSecondaryLabel,
    highlightedInLineLabel,
    linkInLineLabel,
    linkOfLabel,
    linkInNewTab,
    uploadPopupOpen,
    setDropboxID,
    dropBoxId,
    values,
    errors,
    touched,
    name,
    disabled,
    formik,
    fieldLabelWithTag
  } = props;

  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const { loader, rowID } = useSelector((state) => state.accreditation);

  return (
    <Form.Group className={`cs-position-relative${disabled ? ' cs-disabled' : ''}`}>
      {methodField && fieldLabelWithTag && <Form.Label>{fieldLabelWithTag}</Form.Label>}
      {methodField && (fieldPrimaryLabel || linkInLineLabel || fieldSecondaryLabel) && (
        <Form.Label>
          <span className="cs-regular-sub-heading-m cs-neutral-100">{fieldPrimaryLabel}</span>{' '}
          {fieldSecondaryLabel && (
            <span className="cs-regular-sub-heading-m cs-neutral-70 cs-eg">
              &nbsp;
              {fieldSecondaryLabel}
            </span>
          )}{' '}
          {highlightedInLineLabel && (
            <span className="cs-regular-sub-heading-m cs-danger">{highlightedInLineLabel}</span>
          )}
          {linkInLineLabel && (
            <span className="cs-regular-sub-heading-m cs-neutral-90">
              <Link to={linkOfLabel} target={linkInNewTab ? '_blank' : '_self'}>
                <span className="cs-link cs-neutral-100">{linkInLineLabel}</span>
              </Link>
            </span>
          )}
        </Form.Label>
      )}
      <div
        className="cs-upload-files"
        onClick={() => {
          !disabled && uploadPopupOpen();
          setDropboxID(dropBoxId);
        }}>
        <span className="cs-primary">
          <CapsyncIcon title="upload-files-filled" size="28" />
        </span>
        <span className="cs-regular-sub-heading-m">
          <span className="cs-primary">Browse&nbsp;</span> or drag file here
        </span>
        <p className="cs-regular-sub-heading-xs">Support PDF, PNG, JPG and JPEG Files</p>
      </div>
      {touched && errors && touched[name] && errors[name] && (
        <span className="form-error-msg cs-light-body-text-s cs-danger">{errors[name]}</span>
      )}
      <div className="attachment-files cs-files">
        {values[name].length > 0 &&
          values[name]
            .filter((item) => item.upload_drop_box_id === dropBoxId)
            .map((item, index) => {
              return (
                <div className={`cs-selected-files${disabled ? ' cs-disabled' : ''}`} key={index}>
                  <div className="cs-fileicon cs-neutral-100">
                    <span className="cs-icon cs-neutral-10">
                      <CapsyncIcon title="file-icon-outlined" size="16" />
                    </span>
                  </div>
                  <div className="cs-files-names">
                    <span className="download-filename cs-regular-sub-heading-s cs-neutral-100">
                      {item.is_signature_pdf
                        ? `${user.display_name}'s PFS`
                        : item.original_file_name}
                    </span>
                  </div>
                  <div className="cs-file-icon icons">
                    {loader && loader['deleteMediaLoader' + item.id] ? (
                      <span className="cs-common-spinner cs-danger">
                        <CapsyncIcon title="loading-outlined" size="18" />
                      </span>
                    ) : (
                      <span
                        className={`cs-icon-badge-danger cs-danger cursor-pointer${disabled ? ' cs-disabled' : ''}`}
                        onClick={() =>
                          !disabled &&
                          handleDeleteMedia(
                            item.id,
                            dispatch,
                            user.id,
                            formik,
                            item.upload_drop_box_id,
                            rowID
                          )
                        }>
                        <CapsyncIcon title="delete-outlined" size="18" />
                      </span>
                    )}
                  </div>
                </div>
              );
            })}
      </div>
    </Form.Group>
  );
};

// PROPS TYPE
MethodFileUpload.propTypes = {
  methodField: PropTypes.bool,
  fieldPrimaryLabel: PropTypes.string,
  highlightedInLineLabel: PropTypes.string,
  linkInLineLabel: PropTypes.string,
  linkOfLabel: PropTypes.string,
  linkInNewTab: PropTypes.bool,
  uploadPopupOpen: PropTypes.func,
  setDropboxID: PropTypes.func,
  dropBoxId: PropTypes.number,
  values: PropTypes.object,
  errors: PropTypes.object,
  touched: PropTypes.object,
  name: PropTypes.string,
  disabled: PropTypes.bool,
  fieldSecondaryLabel: PropTypes.string,
  formik: PropTypes.func,
  fieldLabelWithTag: PropTypes.object
};

export default MethodFileUpload;
