import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import Skeleton from 'react-loading-skeleton';

/* ============================== SKELETON ARCHIVE & TRASH ============================== */
const SkeletonArchiveTrash = () => {
  return (
    <section className="individual-main-section main-section  trash-page">
      <Container fluid>
        <div className="fm-trash-skeleton">
          <div className="skeleton-manage-container for-des">
            <Row>
              <Col>
                <div className="skeleton-tabs-container">
                  <h2 className="skeleton-tab-1">
                    <Skeleton width={200} />
                  </h2>
                </div>
              </Col>
              <Col>
                <h2 className="skeleton-tab-1">
                  <Skeleton width="100%" />
                </h2>
              </Col>
              <Col>
                <span>
                  <Skeleton width={30} height={30} />
                </span>
              </Col>
            </Row>
          </div>

          <div className="skeleton-manage-container for-mob">
            <div>
              <Skeleton width={100} height={20} />
            </div>
            <div>
              <Skeleton width="100%" height={30} />
            </div>
            <div className="skeleton-new-btn">
              <span>
                <Skeleton width={30} height={30} />
              </span>
            </div>
          </div>
          <div className="skeleton-no-record">
            <span className="no-record-box">
              <Skeleton width="100%" height="72vh" />
            </span>
            <div className="skeleton-upgrade for-des">
              <Row>
                <Col lg={12}>
                  <Skeleton width={200} height={20} />
                </Col>
              </Row>
            </div>
            <div className="skeleton-upgrade for-mob">
              <Row>
                <Col lg={12}>
                  <Skeleton width="100%" height={20} />
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </Container>
    </section>
  );
};

export default SkeletonArchiveTrash;
