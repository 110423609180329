import React from 'react';
import PropTypes from 'prop-types';
import { Dropdown, Form } from 'react-bootstrap';

// COMPONENT IMPORTS
import CapsyncIcon from '../CapsyncIcon';
import { loanTermType } from '../../utils/common';
import InputNumberField from './InputNumberField';

/* ============================== DROPDOWN INPUT ============================== */
const DropdownInputBox = ({
  label,
  errors,
  handleChange,
  name,
  values,
  touched,
  maxLength,
  dropdownName,
  setFieldValue
}) => {
  return (
    <Form.Group className="cs-form-group">
      <Form.Label>{label}</Form.Label>
      <div className="loan-term-box">
        <div className="loan-term-input">
          <InputNumberField
            errors={errors}
            handleChange={handleChange}
            name={name}
            values={values}
            touched={touched}
            maxLength={maxLength}
          />
        </div>
        <div className="loan-term-dropdown cs-common-add-dropdown">
          <Dropdown>
            <Dropdown.Toggle>
              {values.frequency || 'Choose Term'}
              <span className="cs-neutral-90">
                <CapsyncIcon title="chevron-down-outlined" size="18" />
              </span>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {loanTermType.map((dateItr) => (
                <Dropdown.Item
                  className={dateItr === values.frequency ? 'cs-selected-dropdown' : ''}
                  key={dateItr}
                  onClick={() => setFieldValue(dropdownName, dateItr)}>
                  {dateItr}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
    </Form.Group>
  );
};

// PROPS TYPE
DropdownInputBox.propTypes = {
  label: PropTypes.string,
  errors: PropTypes.object,
  handleChange: PropTypes.func,
  name: PropTypes.string,
  values: PropTypes.object,
  touched: PropTypes.object,
  maxLength: PropTypes.number,
  dropdownName: PropTypes.string,
  setFieldValue: PropTypes.func
};

export default DropdownInputBox;
