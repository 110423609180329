import * as yup from 'yup';
import { legalNameField, verificationTypeField } from '.';

const newRequestVerificationSchema = yup.object().shape({
    legal_name: legalNameField(),
    verification_type: verificationTypeField()
});

export {
    newRequestVerificationSchema,
}