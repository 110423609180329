import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Accordion, Button } from 'react-bootstrap';

// COMPONENT IMPORTS
import CapsyncIcon from '../../../components/CapsyncIcon';
import AddAccountModal from '../AddAccountModal';

// API
import { useAccountsData } from '../../../slices/plaidSlice';

/* ============================== PLAID ACCOUNT LIST ============================== */
const PlaidAccountsList = ({ isSidebarOpen, handleToggleSidebar }) => {
  const accountsData = useAccountsData();

  const [updateInsId, setUpdateInsId] = useState(null);
  const [openBank, setOpenBank] = useState(false);

  const isErrorAccountExist = accountsData.find((account) => account.institution.is_error);

  return (
    accountsData &&
    accountsData.length > 0 &&
    (!isErrorAccountExist || isSidebarOpen) && (
      <div className="sidebar-add-account">
        <div className={`banking-validations ${isErrorAccountExist ? '' : 'banking-sync-data'}`}>
          <div
            className="banking-error"
            id="banking"
            onClick={() => {
              isSidebarOpen && setOpenBank(!openBank);
              !isSidebarOpen && handleToggleSidebar();
            }}>
            <div>
              <div className="banking-icon">
                {isErrorAccountExist ? (
                  <span className="cs-danger">
                    <CapsyncIcon title="error-validation-filled" size="14" />
                  </span>
                ) : (
                  <span className="cs-primary">
                    <CapsyncIcon title="verification-filled" size="14" />
                  </span>
                )}
              </div>
              {isSidebarOpen && <h5 className="cs-regular-h5 cs-neutral-90">Accounts</h5>}
            </div>
            {isSidebarOpen && (
              <span className="cs-neutral-90">
                <CapsyncIcon
                  title={openBank ? 'chevron-up-outlined' : 'chevron-down-outlined'}
                  size="14"
                />
              </span>
            )}
          </div>
          {isSidebarOpen && openBank && (
            <div className="open-banking-messages">
              <div className="warning-message">
                <Accordion>
                  {accountsData.map((data, index) => {
                    return (
                      <Accordion.Item id="banking-account" eventKey={index} key={index}>
                        <Accordion.Header>
                          <div className="content-icon">
                            <span className="cs-neutral-80">
                              <CapsyncIcon title="chevron-down-filled" size="10" />
                            </span>
                            <p className="cs-light-body-text-m cs-neutral-70">
                              {data.institution.ins_name}
                            </p>
                          </div>
                          <div className="cs-sync-btn">
                            {data.institution.is_error ? (
                              <Button
                                className="cs-primary"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setUpdateInsId(data.institution.ins_id);
                                }}>
                                <CapsyncIcon title="sync-outlined" size="14" />
                              </Button>
                            ) : (
                              <span className="cs-success">
                                <CapsyncIcon title="check-outlined" size="14" />
                              </span>
                            )}
                          </div>
                        </Accordion.Header>
                        <div>
                          <Accordion.Body>
                            {data.accounts &&
                              data.accounts.length > 0 &&
                              data.accounts.map((accData, i) => {
                                return (
                                  <div
                                    className="user-data-value cs-light-body-text-m cs-neutral-70"
                                    key={i.toString()}>
                                    <p>{accData.account_name}</p>
                                    <div className="dollar-value-gap">
                                      <span>$&nbsp;</span>
                                      <span>{accData.balance.toFixed(2)}</span>
                                    </div>
                                  </div>
                                );
                              })}
                          </Accordion.Body>
                        </div>
                      </Accordion.Item>
                    );
                  })}
                </Accordion>
              </div>
            </div>
          )}
        </div>
        {updateInsId && (
          <AddAccountModal setIsAccountModelOpen={() => setUpdateInsId(null)} insId={updateInsId} />
        )}
      </div>
    )
  );
};

PlaidAccountsList.propTypes = {
  isSidebarOpen: PropTypes.bool,
  handleToggleSidebar: PropTypes.func
};

export default PlaidAccountsList;
