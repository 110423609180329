import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// COMPONENT IMPORTS
import axios from '../config/api';

export const individualInvite = createAsyncThunk('client/individual/invite', async (data) => {
  return await axios.post(`client/individual/invite`, data);
});

export const resendInviteVerification = createAsyncThunk(
  'auth/resendEmailVerification',
  async (data) => {
    const res = await axios.post(`users/resend_verification_email`, data);
    return res;
  }
);

export const getCollaborationListing = createAsyncThunk(
  'collaboration/access/list',
  async ({ page, limit, userID }) => {
    return await axios.get(`collaboration/access/list/${userID}`, {
      params: {
        page,
        limit,
        isPaginate: limit !== 'all'
      }
    });
  }
);

export const updateOrRemoveCollaborator = createAsyncThunk(
  '/client/access/permission',
  async ({ id, data }) => {
    return await axios.put(`/client/access/permission/${id}`, {
      ...data
    });
  }
);

// INITIAL STATE
const initialState = {
  individualPagination: {
    page: 0,
    totalPage: 0,
    itemCount: 0,
    limit: 0
  },
  individual: [],
  professionalUserPagination: {
    page: 0,
    totalPage: 0,
    itemCount: 0,
    limit: 0
  },
  professionalUser: []
};

/* ============================== COLLABORATION SLICE ============================== */
export const collaborationSlice = createSlice({
  name: 'collaboration',
  initialState,
  reducers: {
    resetCollaborationSlice: () => initialState
  },
  extraReducers: (builder) => {
    builder.addCase(getCollaborationListing.fulfilled, (state, { payload }) => {
      const { data } = payload;
      Object.assign(state, data);
    });
  }
});

export const { resetCollaborationSlice } = collaborationSlice.actions;

const { reducer } = collaborationSlice;
export default reducer;
