import React from 'react';
import PropTypes from 'prop-types';
import { Dropdown } from 'react-bootstrap';

// COMPONENT IMPORTS
import Avatar from '../../../components/userProfile/Avatar';

/* ============================== ACCESS PFS IMAGE ============================== */
const AccessPfsImage = ({ itr }) => {
  return (
    <Dropdown>
      <Dropdown.Toggle>
        <div className="profile-image">
          <div className="multiple-user-avatar">
            <Avatar
              className={`cs-avatar-md  cs-avatar-img `}
              src={itr?.image}
              type="accessUser"
              accessUserName={itr?.display_name}
            />
          </div>
        </div>
        <span className="active-dot cs-success"></span>
      </Dropdown.Toggle>
      <Dropdown.Menu>
        <div className="profile-info">
          <span className="cs-regular-body-text-m cs-neutral-90">{itr?.display_name}</span>
          <span className="cs-regular-body-text-s cs-neutral-60">{itr?.role}</span>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
};

// PROPS TYPE
AccessPfsImage.propTypes = {
  itr: PropTypes.object
};

export default AccessPfsImage;
