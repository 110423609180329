import { useMemo, useEffect } from 'react';

/* ============================== SHARE VALUE ============================== */
const useShareValue = ({ values, setFieldValue, fieldName }) => {
  const share_value = useMemo(() => {
    if (values.balance && values.ownership) {
      return parseFloat((values.balance.replace(/,/g, '') * values.ownership) / 100).toFixed(2);
    } else if (values.market_value && values.ownership) {
      const marketValue =
        typeof values.market_value === 'string'
          ? values.market_value.replace(/,/g, '')
          : values.market_value;
      return parseFloat((marketValue * values.ownership) / 100).toFixed(2);
    } else {
      return '';
    }
  }, [values.balance, values.ownership, values.market_value]);

  useEffect(() => {
    setFieldValue(fieldName, share_value);
  }, [share_value, setFieldValue, fieldName]);

  return share_value;
};

export default useShareValue;
