import React from 'react';

// COMPONENT IMPORTS
import ProtectedRoutes from './ProtectedRoutes';
import AccreditationIndex from '../pages/accreditation/AccreditationIndex';
import MethodsView from '../pages/accreditation/MainMethods/MethodsView';
import SubMethodsView from '../pages/accreditation/SubMethods/SubMethodsView';

/* ============================== ACCREDITATION ROUTES ============================== */
const AccreditationRoutes = {
  path: 'verifyinvestor',
  element: <ProtectedRoutes />,
  children: [
    { path: '', element: <AccreditationIndex /> },
    { path: 'verification-type', element: <MethodsView /> },
    { path: 'verification-type/method', element: <SubMethodsView /> }
  ]
};
export default AccreditationRoutes;
