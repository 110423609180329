import React, { useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import * as yup from 'yup';

// COMPONENT IMPORTS
import SharePFSFirstModal from './SharePFSFirstModal';
import SharePFSThirdModal from './SharePFSThirdModal';
import { emailField, firstNameField, lastNameField } from '../../validations';
import SharePFSSecondMainModal from './sharePFSSecondModal/SharePFSSecondMainModal';

// API
import { signatureGetFiles, signatureUploadFiles } from '../../slices/requestSlice';

// CSS IMPORTS
import '../SharePFSModals/sharePFSModal.css';

/* ============================== COMMON SHARE PFS MODAL ============================== */
const CommonFilesForModal = (props) => {
  const { showFirstModal, setShowFirstModal } = props;
  const inputRef = useRef('');
  const { user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const [showPFSSecondModal, setShowPFSSecondModal] = useState(false);
  const [showPFSThirdModal, setShowPFSThirdModal] = useState(false);
  const [selectValue, setSelectValue] = useState('');
  const [isCheckedWealth, setIsCheckedWealth] = useState(true);
  const [radioValue, setRadioValue] = useState({
    num: '1',
    name: 'PFS Summary'
  });
  const [documentName, setDocumentName] = useState('');
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [duplicateFileError, setDuplicateFileError] = useState('');
  const [documentError, setDocumentError] = useState('');

  const [comments, setComments] = useState('');
  const initialValues = {
    first_name: '',
    last_name: '',
    email: '',
    notes: ''
  };
  const validationSchema = yup.object().shape({
    email: emailField(),
    first_name: firstNameField(),
    last_name: lastNameField()
  });
  const onSubmit = async (values) => {
    setShowPFSSecondModal(true);
    setShowFirstModal(false);
  };
  const sharePFSFirstModalSchema = useFormik({
    initialValues,
    validationSchema,
    onSubmit
  });
  const handleCheckboxChange = () => {
    if (isCheckedWealth === false) {
      setIsCheckedWealth(true);
    } else {
      setIsCheckedWealth(false);
    }
  };
  const handleDocumentNameChange = (e) => {
    setDocumentName(e.target.value);
    setDocumentError('');
  };

  const validateDuplicateFileName = (newFiles, originalFiles) => {
    const maxFileSize = 10 * 1024 * 1024; // 10MB in bytes
    const supportedExtensions = ['.pdf', '.png', '.jpg', '.jpeg'];
    const originalFileNames =
      originalFiles && originalFiles.map((file) => file.document_name || file.file.name);

    for (const newFile of newFiles) {
      if (originalFileNames && originalFileNames.includes(newFile.name)) {
        setDuplicateFileError('A file with this name already exists');
        return false;
      }
      // Check for maximum file size
      if (newFile.size && newFile.size > maxFileSize) {
        setDuplicateFileError('This file exceeds maximum file size limit (10MB)');
        return false;
      }
      // Check file extension
      const fileExtension = newFile.name.slice(((newFile.name.lastIndexOf('.') - 1) >>> 0) + 2);
      const notAllowedExtensionHandling =
        fileExtension === '' || fileExtension === null || fileExtension === undefined;
      if (
        notAllowedExtensionHandling ||
        !supportedExtensions.includes(`.${fileExtension.toLowerCase()}`)
      ) {
        setDuplicateFileError('Format not supported');
        return false;
      }
    }
    setDuplicateFileError('');
    return true;
  };

  const handleFileChange = (e) => {
    const files = Array.from(e.target.files);
    if (validateDuplicateFileName(files, uploadedFiles)) {
      setSelectedFiles(files);
    } else {
      setSelectedFiles([]);
    }
  };
  // const generateUniqueId = () => {
  //   const timestamp = Math.floor(Date.now() / 10000); // Shorter timestamp (seconds since epoch)
  //   const randomNum = Math.floor(Math.random() * 1000); // Random number between 0 and 99999
  //   return timestamp * 10 + randomNum;
  // };
  const handleUploadAttachment = () => {
    const filesToUpload = selectedFiles.map((file) => ({
      file,
      document_name: documentName || file.name
      // id: generateUniqueId()
    }));

    const formData = new FormData();
    formData.append('user_id', user && user.id);
    formData.append('document_name', documentName);
    formData.append('attachment', filesToUpload && filesToUpload[0] && filesToUpload[0].file);

    const newData = {
      formData: formData
    };
    dispatch(signatureUploadFiles(newData))
      .unwrap()
      .then((response) => {
        if (response.code === 500 && response.message === 'document exists with the same name') {
          setDocumentError('Document exists with the same name');
        }
        dispatch(signatureGetFiles({ user_id: user && user.id }))
          .unwrap()
          .then((response) => {
            if (response.code === 200) {
              setUploadedFiles(response.data);
            }
          });
      })
      .catch((err) => {
        console.log('error', err);
      });
    // setUploadedFiles((prevFiles) => [...prevFiles, ...filesToUpload]);
    inputRef.current.value = '';
    setSelectedFiles([]);
    setDocumentName('');
  };

  return (
    <React.Fragment>
      {showFirstModal && (
        <SharePFSFirstModal
          showFirstModal={showFirstModal}
          setShowFirstModal={setShowFirstModal}
          sharePFSFirstModalSchema={sharePFSFirstModalSchema}
          setShowPFSSecondModal={setShowPFSSecondModal}
          isCheckedWealth={isCheckedWealth}
          handleCheckboxChange={handleCheckboxChange}
        />
      )}
      {showPFSSecondModal && (
        <SharePFSSecondMainModal
          showPFSSecondModal={showPFSSecondModal}
          setShowPFSSecondModal={setShowPFSSecondModal}
          setShowPFSThirdModal={setShowPFSThirdModal}
          sharePFSFirstModalSchema={sharePFSFirstModalSchema}
          radioValue={radioValue}
          setRadioValue={setRadioValue}
          handleFileChange={handleFileChange}
          handleDocumentNameChange={handleDocumentNameChange}
          uploadedFiles={uploadedFiles}
          handleUploadAttachment={handleUploadAttachment}
          documentName={documentName}
          inputRef={inputRef}
          duplicateFileError={duplicateFileError}
          setUploadedFiles={setUploadedFiles}
          setComments={setComments}
          comments={comments}
          documentError={documentError}
          selectedFiles={selectedFiles}
        />
      )}

      {showPFSThirdModal && (
        <SharePFSThirdModal
          showPFSThirdModal={showPFSThirdModal}
          setShowPFSThirdModal={setShowPFSThirdModal}
          setSelectValue={setSelectValue}
          selectValue={selectValue}
          sharePFSFirstModalSchema={sharePFSFirstModalSchema}
          isCheckedWealth={isCheckedWealth}
          comments={comments}
          setRadioValue={setRadioValue}
        />
      )}
    </React.Fragment>
  );
};

// PROPS TYPE
CommonFilesForModal.propTypes = {
  showFirstModal: PropTypes.bool,
  setShowFirstModal: PropTypes.func
};

export default CommonFilesForModal;
