import React from 'react';
import Skeleton from 'react-loading-skeleton';

/* ============================== SKELETON PIE CHART ============================== */
const PieChartSkeleton = () => {
  return (
    <div>
      <div className="chart-heading">
        <h2 className="default-semi-bold-h2 for-des">
          <Skeleton width={200} />
        </h2>
        <h2 className="default-semi-bold-h2 for-mob">
          <Skeleton width={150} />
        </h2>
      </div>
      <div className="graph-pie-chart-main-container asset-pie-chart">
        <div className="skeleton-pie-legend">
          <div className="skeleton-pie-chart-container">
            <div className="skeleton-pie-chart for-des">
              <Skeleton circle width={250} height={250} />
            </div>
            <div className="skeleton-pie-chart for-mob">
              <Skeleton circle width={190} height={190} />
            </div>
          </div>
        </div>
        <div className="skeleton-networth-container">
          <h2 className="skeleton-networth">
            <Skeleton width={200} />
          </h2>
        </div>
      </div>
    </div>
  );
};

export default PieChartSkeleton;
