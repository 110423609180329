import React from 'react';
import { Button } from 'react-bootstrap';
import PropTypes from 'prop-types';

// COMPONENT IMPORTS
import CapsyncIcon from '../../../components/CapsyncIcon';

/* ============================== ADD USER BUTTON ============================== */
const AddUserButton = ({ onClick }) => {
  return (
    <Button className="cs-btn-icon-primary lg-btn cs-regular-h5" onClick={onClick}>
      <span className="cs-neutral-10 cs-icon">
        <CapsyncIcon title="add-filled" size="18" />
      </span>
      <span className="cs-nav-text">Add User</span>
    </Button>
  );
};

// PROPS TYPE
AddUserButton.propTypes = {
  onClick: PropTypes.func
};

export default AddUserButton;
