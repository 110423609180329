import React from 'react';
import Skeleton from 'react-loading-skeleton';

/* ============================== SKELETON HEADER ============================== */
const SkeletonCapsyncHeader = () => {
  return (
    <div className="cs-section-topbar">
      <div className="cs-title">
        <h1>
          <Skeleton width={200} height={30} />
        </h1>
      </div>
    </div>
  );
};

export default SkeletonCapsyncHeader;
