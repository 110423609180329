import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Col, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

// COMPONENT IMPORTS
import useRemoveServerError from '../components/hooks/useRemoveServerError';
import GoogleQRCode from '../components/GoogleQRCode';
import AuthHead from './AuthHead';

// HOOKS IMPORTS
import useAuthRedirection from '../utils/Hooks/useAuthRedirection';

// API
import { googleMFASetup } from '../slices/authSlice';

/* ============================== GOOGLE QR SETUP ============================== */
const GoogleSetupQR = () => {
  const dispatch = useDispatch();
  const navigation = useNavigate();

  useRemoveServerError();
  useAuthRedirection();

  const { user } = useSelector((state) => state.auth);

  const handleNextClick = () => {
    dispatch(googleMFASetup({ user_id: user && user.id }));
    navigation('/verification-pin');
  };

  return (
    <React.Fragment>
      <div className="cs-common-qr">
        <AuthHead
          title="Authenticator app verification"
          description="Scan the below QR code with your authenticator app."
        />
      </div>
      <div className="cs-auth-form">
        <Row>
          <Col>
            <div className="auth-footer">
              <GoogleQRCode />
              <Button
                type="submit"
                className="cs-btn-primary lg-btn cs-regular-h5"
                onClick={handleNextClick}>
                Next
              </Button>
            </div>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
};
export default GoogleSetupQR;
